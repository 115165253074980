import React, { useEffect, useState } from 'react';
import { fadeIn} from './animationValues'
import LeagueImport from './LeagueImport';
import SelectPlatform from './SelectPlatform';
import UserOnboarded from './UserOnboarded';
import { motion } from "framer-motion/dist/framer-motion"
import queryString from 'query-string';
import OnboardingArenaLineup from './OnboardingArenaLineup';
import OnboardingWallet from './OnboardingWallet';
import { useHistory } from 'react-router-dom';
import store from '../../store';
import OnboardingTeamAvatar from './OnboardingTeamAvatar';
import SelectSport from './SelectSport';
import { SAVE_APPLICATION_STATUS } from '../../apis/Sidebar/LeagueAPI';
import { ARENA } from '../../helpers/arena_sports_mapping';
import { sportFromStorage } from '../../helpers/Arena/ArenaHelper';
import Welcome from './Welcome';

const NewOnboardingBody = (props) => {
	const { step, updateStep, hasLeague, Colors, forMob } = props;
	const { onboardBySport } = store.getState().leagueJoin

	const [selectedPlatform, setSelectedPlatform] = useState()
	const [leagueId, setLeagueId] = useState()
	const [ teamId, setTeamId ] = useState()
	const [ sport, setSport ] = useState("NFL")
	
	// HACK : Make Sport Dynamic
	// const sport = "NBA"
	const history = useHistory()

	useEffect(() => {
		// WE CANNOT SEND MULTI PARAMS IN REDIRECT URI THUS CAN'T RECEICE IN LOCATION SEARCH OBJECT
		// WE ARE GETTING A KEY platform=yahoo-NBA and we need to split it
        const parsed = queryString.parse(location.search)
		var splitParams = parsed?.state?.split(",")

		if(parsed?.sport && !parsed?.platform){
			setSport(parsed.sport)
			updateStep("sportSelected")
		}
        else if(splitParams?.length == 1){
			// MEANS IT IS INLUDED SPORT
			setSelectedPlatform(parsed?.platform)
			// THIS WILL GIVE US SPORT
			setSport(splitParams[0].split("=")[1])
            updateStep("platformSelected")
        }
		else if(parsed?.platform && parsed?.sport){
			// FOR ESPN ONLY
			setSport(parsed.sport)
			setSelectedPlatform(parsed.platform)
            updateStep("platformSelected")
			// NOT SURE WHY SALMAN DID THIS?
			// history.push("/new-user-onboarding")
		}else if(parsed?.platform){
			// LET'S TRY TO FIND SPORT FROM LOCAL STORAGE
			sportFromStorage().then((sp) => {
				setSport(sp)
				setSelectedPlatform(parsed.platform)
				updateStep("platformSelected")
			}).catch(() => {
				setSelectedPlatform(parsed.platform)
				updateStep("platformSelected")
			})
			// NOT SURE WHY SALMAN DID THIS?
			// history.push("/new-user-onboarding")
		}
    }, [])

	const selectSport = (sport) => {
		setSport(sport)
		updateStep("sportSelected")
            // switch (sport) {
            //     case 'NFL':
            //         setColors(nflColors);
            //         break;
            //     case 'MLB':
            //         setColors(mlbColors);
            //         break;
            //     case 'NBA':
            //         setColors(nbaColors);
            //         break;
            //     default:
            //         break;
            // }
	}

	const successCallback = () => {
		if(hasLeague && onboardBySport[sport]){
			store.dispatch({type: "SET_IMPORT_SUCCESS", payload: true})
			// ALTERNATE : Disable/Enable Arena Redirect
			history.push("/newhome")
			// history.push("/dashboard")
			// updateStep("avatarSelected")
		}else{
			// FIRST TIME // NEW USER COMING IN
			updateStep("lineupSelected")
		}
	}

	const importLeagueSuccess = (importedLeagueId) => {
		setLeagueId(importedLeagueId)
		updateStep("leagueImported")
		store.dispatch({type: "FORCE_REFRESH_ARENA_DATA", payload: true})
		store.dispatch({type: "SAVE_ARENA_SPORT", sport: sport})
		// ALSO NEED TO SWITCH EXPERIENCE
		store.dispatch({ type: SAVE_APPLICATION_STATUS, app_status: ARENA.EXPERIENCE_FROM_SPORT[sport] });
	}

	const setLineupTeamId = (teamId) => {
		setTeamId(teamId)
	}

	const getAnimation = () => {
		if(forMob){
			return step != "default" ? 'show' :'hide'
		}else{
			return "show"
		}
	}

	return (
		<motion.div
			className="d-flex flex-column flex1 scrollHidden mt-5"
			// style={{ marginTop: "4rem"}}
			animate={getAnimation()}
			transition={{ duration: 0.5 }}
			initial={false}
			variants={fadeIn}>
			<Welcome {...props} />
			<SelectSport {...props} handleSportSelect={selectSport} />
			<SelectPlatform setSelectedPlatform={setSelectedPlatform} {...props} sport={sport} />
			{/* <LeagueImport selectedPlatform={selectedPlatform} {...props} setLeagueId={setLeagueId} successCallback={successCallback} /> */}
			<LeagueImport selectedPlatform={selectedPlatform} {...props} setLeagueId={setLeagueId} successCallback={importLeagueSuccess} sport={sport}/>
			<OnboardingTeamAvatar {...props} leagueId={leagueId} successCallback={successCallback} teamId={teamId} hasLeague={hasLeague} />
			{/* <OnboardingArenaLineup leagueId={leagueId} setLineupTeamId={setLineupTeamId} {...props} sport={sport} /> */}
			<OnboardingWallet {...props}/>
			<UserOnboarded {...props} />
		</motion.div>
	);
};

export default NewOnboardingBody;
