import React, { useState } from 'react'
import { Button } from 'reactstrap';
import { setupAvatar } from '../../../apis/Matchmaker/ArenaSettings/ArenaSettings';
import { updateDataInUsers } from '../../../helpers/Arena/ArenaHelper';
// import { useColors } from '../../../helpers/CustomHooks/useColors';
import { arenaBackIcon, arenaLineupIcon, arenaUploadAvatarIcon, arenaUploadedAvatarIcon } from '../../../helpers/icons';
import ErrorText from '../../ErrorText';
import { Colors } from '../../../helpers/colors';
const TeamAvatar = (props) => {

    // const Colors = useColors();

    const {onSuccess, teamId, leagueId, setUploading, sentFromOnboarding, activeView, fromSettings} = props
    const [ view, setView ] = useState(activeView || 'default')
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

  const handleImage = (e) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      setError(null)
      setImage(e.target.files[0]);
      setImageUrl(reader.result);
    }
    reader.readAsDataURL(e.target.files[0]);
  };

    // UPLOAD IMAGE TO SERVER
    const handleUpdateImage = () => {
        setLoading(true)
        setUploading(true)
        if(image != null){
        const formData = new FormData();
        formData.append('image', image);
        if(teamId) {
            formData.append('teamId', teamId);
        }
        if(leagueId) {
            formData.append('leagueId', leagueId);
        }
        setupAvatar(formData).then((response) => {
            updateDataInUsers({
            teamImage: image
            })
            setLoading(false)
            setUploading(false)
            onSuccess(imageUrl)
        }).catch(err => {
            console.log(err)
            setError("Something went wrong, please try again later.");
            setLoading(false);
            setUploading(false)
        })
        }else{
        setError("Please pick an image")
        setLoading(false)
        setUploading(false)
        }
    };

    return (
        {
            'default': (
                <div className="d-flex flex-column flex1">
                {
                    !sentFromOnboarding &&
                    <>
                        <div className="pt-2" style={{ aspect: 1, width: 100, height: 100 }}>
                            {arenaLineupIcon()}
                        </div>
                        <h1 className="bold flex-grow-1" style={{ color: Colors.colorPrimary }}>
                            Please set <br /> your team avatar
                        </h1>
                        <p>
                            Oops!!😬 We noticed you’ve not set your team avatar. Please <br /> do for a better Arena experience for everyone.
                        </p>
                    </>
                }
                    
                    <div className="d-flex justify-content-between align-items-center px-1">
                        <p className='m-0' style={{ cursor: 'pointer', color: Colors.colorPrimary }} onClick={onSuccess} >
                            Skip
                        </p>
                        <Button
                            style={{
                                color: Colors.colorPrimary,
                                backgroundColor: Colors.colorPrimaryTransparent,
                                border: `1px solid ${Colors.colorPrimary}`,
                                padding: '6px 23px 6px 23px'
                            }}
                            onClick={()=> setView("uploadAvatar")}
                            >
                            Upload
                        </Button>
                    </div>
                    { error && <ErrorText customStyles={{textAlign: "center", marginBottom: 20}} msg={error} /> }
                </div>
            ),
            'uploadAvatar': (
                <div className="d-flex flex-column flex1">
                    { !sentFromOnboarding && <h1 className="bold" style={{ color: Colors.colorPrimary }}>
                        Please, upload your <br /> Avatar.
                    </h1>
                    }
                    <h6 className="mt-3" style={{ color: Colors.textColorSecondary }}>
                        IN JPEG, PNG and GIF
                    </h6>
                    <div>
                        <div className='d-flex justify-content-center align-items-center py-2' style={{ background: Colors.bg400, borderRadius: 5, border: `1px solid ${Colors.colorSecondaryTransparent}` }}>
                            {imageUrl == null ?
                            <>
                                <p className='pt-2' style={{ position: 'absolute' }}>{arenaUploadAvatarIcon()}</p>
                                <input type="file" accept="image/jpg, image/jpeg, image/png, image/gif" style={{ cursor: "pointer", position: 'relative', width: '300px', height: '100px', opacity: "0.0" }} onChange={handleImage} />
                            </>
                            :
                            <div className='d-flex justify-content-between align-items-center px-3 w-100'>
                                <div className='d-flex'>
                                <img src={imageUrl} alt='' style={{ maxWidth: '90px', maxHeight: '90px', borderRadius: 5 }} />
                                <h5 className='pl-2'>{image && image.name}</h5>
                                </div>
                                <p className='mt-2 bold' style={{ color: Colors.colorPrimary, cursor: 'pointer' }} onClick={() => {
                                setImage(null);
                                setImageUrl(null);
                                }}>Change</p>
                            </div>
                            }
                        </div>
                        <div className="d-flex justify-content-between align-items-center px-1 mt-3">
                                {fromSettings &&                                     
                                <Button outline onClick={() => props.toggle()}> 
                                    Cancel
                                </Button>}
                                {!sentFromOnboarding && !fromSettings ? <p style={{ cursor: 'pointer' }} onClick={() => props.toggle()}>
                                    {arenaBackIcon()}
                                </p> : (<p></p>)}
                                <Button
                                    className="mb-2"
                                    disabled={loading}
                                    style={{
                                        color: Colors.colorPrimary,
                                        backgroundColor: Colors.colorPrimaryTransparent,
                                        border: `1px solid ${Colors.colorPrimary}`,
                                        padding: '6px 23px 6px 23px'
                                    }}
                                    onClick={handleUpdateImage}>
                                    Upload
                                </Button>
                        </div>
                    </div>
                    { error && <ErrorText customStyles={{textAlign: "center", marginBottom: 20}} msg={error} /> }
                </div>
            ),
            'uploaded': (
                <div className='d-flex flex-column flex1'>
                    <div className='pt-2' style={{ aspect: 1, width: 100, height: 100 }}>{arenaUploadedAvatarIcon()}</div>
                        <h1 className='bold flex-grow-1' style={{ color: Colors.colorPrimary }}>Your Avatar <br /> has been uploaded.</h1>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br /> Vitae amet vulputate vivamus tincidunt. Feugiat lacus ipsum <br /> sem orci non tempus.</p> */}
                        <div className='d-flex justify-content-between align-items-center px-1'>
                        <p style={{ cursor: 'pointer' }} onClick={() => props.toggle()}>{arenaBackIcon()}</p>
                        <Button
                            className='mb-2'
                            style={{
                            color: Colors.colorPrimary,
                            backgroundColor: Colors.colorPrimaryTransparent,
                            border: `1px solid ${Colors.colorPrimary}`,
                            padding: '6px 23px 6px 23px'
                            }}
                        onClick={onSuccess}
                        >
                            Next
                        </Button>
                    </div>
                </div>
            ),
        }[view]
    )
}

export default TeamAvatar