import React from 'react';
import { Button, Modal } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import LineupImportIndex from '../pages/LineupImport/LineupImportIndex';
import YahooImport from '../pages/LineupImport/YahooImport';

class ImportLeagueModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1
        };
    }

    componentDidMount(){
        if(this.props.importPlatform){
            if(this.props.importPlatform == "espn"){
                this.setState({step: 2})
            }else if(this.props.importPlatform == "yahoo"){
                this.setState({step: 3})
            }
        }
    }

    handleSwitch = (step, queryString) => {
        this.props.history.replace({ pathname: window.location.pathname, search: queryString});       
        this.setState({step: step})
    }

    closeModal = () => {
        if(this.props.importSuccess){
            window.location.href = "/dashboard"
            this.props.toggle("close")
        }else{
            this.props.toggle("close")
        }
    }

    render() {
        return (
            <div>
                <Modal size={"lg"} isOpen={this.props.modal}>
                <i onClick={this.closeModal} class="cui-circle-x icons pointer modalClose"></i>
                
                {(() => {
                    switch (this.state.step) {
                        case 1:
                            return (
                                <div className="text-center p-4" >
                                    <h3 className="bold mt-3" >Import League</h3>
                                    {/* <div>
                                        <img src={signUpChoice} style={{maxWidth: "60%"}} className="img-fluid"   />
                                    </div> */}
                                    <h5 className="mt-3" >Which platform do you want to import from?</h5>
                                    <div className="mt-4" >
                                        <div>
                                            <Button onClick={() => { this.handleSwitch(3, "?platform=yahoo")} } className="btn-pill yahooBtn mt-3 mb-3" ><div style={{width: "25%",margin: "auto"}} ><span className="bold" >YAHOO</span></div></Button>
                                        </div>
                                        <div>
                                            <Button onClick={() => this.handleSwitch(2, "?platform=espn")} className="btn-pill espnBtn mb-3" ><div style={{width: "25%", margin: "auto"}}><span className="bold">ESPN</span></div></Button>
                                        </div>
                                    </div>
                                </div>
                            )
                        case 2: 
                            return (
                                <div>
                                    <LineupImportIndex />
                                </div>
                                
                            )
                        case 3: 
                            return (
                                <div>
                                    <YahooImport />
                                </div>
                                
                            )
                        default :
                            return null
                    }
                    })()}
                   
                </Modal>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
	importSuccess: state.leagueJoin.importSuccess
});
export default connect(mapStateToProps, null)(withRouter(ImportLeagueModal));