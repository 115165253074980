import React, { useEffect, useState } from 'react';
import { Button, Input, Modal } from 'reactstrap';
import { STYLES } from '../../helpers/stylesHelper';
// import { Colors } from '../../helpers/colors';
import { useForm } from 'react-hook-form';
import { fetchCountries, statesByCountry } from '../../apis/Gidx/GidxApi';
import ErrorText from '../../components/ErrorText';
import { useColors } from '../../helpers/CustomHooks/useColors';

const GidxBillingModal = (props) => {
  const Colors = useColors();
    const {visible, setAddress, toggle } = props
    const { formState: { errors }, handleSubmit, control, setError, clearErrors, register} = useForm(); // initialize the hook
    const [selectedCountry, setSelectedCountry] = useState()
    const [selectedState, setSelectedState] = useState() 
    const [filteredStates, setFilteredStates] = useState([])
    const [allCountries, setAllCountries] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
      // FETCH COUNTRIES FROM BE
      fetchCountries().then((resp) => {
        // FETCH UNITED STATES AND PUT IT AS FIRST ELEMENT
        setAllCountries([
          resp.list.find(item => item.isoCode === 'US'),
          ...resp.list.filter(item => item.isoCode !== 'US'),
        ])
        setLoading(false)
      })
    }, [])

    const handleCountry = (country) => {

    statesByCountry("?countryCode="+country).then((resp) => {
        setFilteredStates(sortStates(resp.list))
      })
      setSelectedCountry(country)
    }

    const sortStates = (states) => {
      return states.sort(function(a, b){
        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;
    })
    }

    const onSubmit = (data) => {
      data["country"] = {
        shortname: selectedCountry
      }
      data["state"] = {
        shortname: selectedState
      }
      setAddress(data)
      toggle()
    }

    const interceptValidations = () => {
      if(selectedCountry == undefined){
        setError("country", {message: "Please select country"})
      }
      if(selectedState == undefined){
        setError("state", {message: "Please select state"})
      }
      handleSubmit(onSubmit)()
    }

    return (
        <div >
            <Modal size="md" isOpen={visible} toggle={() => toggle()}>
               <div className="p-4 d-flex flex-column" style={{minHeight: "300px"}} >
                   <div className='d-flex justify-content-between align-items-center pb-3' style={{borderBottom: `1px solid ${Colors.darkBgBorder}`}} >
                    <h4 className='bold m-0' >Billing Address</h4>
                    <i onClick={() => toggle()} class="cui-circle-x icons pointer" style={{fontSize: "1.5rem"}} ></i>
                   </div>
                <form className='mt-1' >
                    <div className="mb-3 mt-3" >
                        <h6 className="bold" >Address</h6>
                        <input className='form-control' placeholder="Address" {...register("address", {required: true})} />
                        {errors.address && <ErrorText msg={"Address is required"} />}
                    </div>
                    <div className="mb-3 mt-3" >
                        <h6 className="bold" >Apt./Suit #</h6>
                        <input className='form-control' placeholder="Address (Optional)" {...register("address2")} />
                        {/* {errors.addres2 && errors.addres2.type === "required" && <ErrorText msg={"Address is required"} />} */}
                    </div>
                    <div className="mb-3 mt-3" >
                        <h6 className="bold" >City</h6>
                        <input className='form-control' placeholder="City" {...register("city", {required: true})} />
                        {errors.city && errors.city.type === "required" && <ErrorText msg={"City is required"} />}
                    </div>
                    <div className="mb-3 mt-3" >
                        <h6 className="bold" >Country</h6>
                        <select className='form-control' onChange={(e) => {clearErrors("country"); handleCountry(e.target.value)}} >
                            <option value={""} >Select country</option>
                            {allCountries.map((country) => {
                                return <option value={country.isoCode} >{country.name}</option>
                            })}
                        </select>
                        {errors.country && <ErrorText msg={errors.country.message} />}
                    </div>
                    <div className="mb-3 mt-3" >
                        <h6 className="bold" >State</h6>
                        <select className='form-control' onChange={(e) => {clearErrors("state"); setSelectedState(e.target.value)}} >
                            <option value={""} >Select state</option>
                            {filteredStates.map((state) => {
                                return <option value={state.isoCode.split("-")[1]} >{state.name}</option>
                            })}
                        </select>
                        {errors.state && <ErrorText msg={errors.state.message} />}
                    </div>
                    <div className="mb-3 mt-3" >
                        <h6 className="bold" >Postal Code</h6>
                        <input className='form-control' placeholder="Postal Code" {...register("postalCode", {required: {value: true, message: "Postal is required"}})} />
                        {errors.postalCode && <ErrorText msg={errors.postalCode.message} />}
                    </div>

                    <Button onClick={interceptValidations} block style={STYLES.BUTTON_PRIMARY_TRANSPARENT} className="mt-4 mb-3" >Add Address</Button>
                </form>
               </div>
            </Modal>
        </div>
    );
}

export default GidxBillingModal