import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { swapPlayersAPI } from '../../../apis/SeasonLong/MyTeam/MyTeamAPI';
import { Colors } from '../../../helpers/colors';
import { BATTERS, CI_ELIGIBLE, errorMessage, IF_ELIGIBLE, ILStatuses, MI_ELIGIBLE, OF_ELIGIBLE, parsePlayerName, PITCHERS, POINT_BASED_LEAGUES, P_ELIGIBLE, successMessage, UTIL_ELIGIBLE } from "../../../helpers/common-methods";
import { parseTotalStat } from '../../../helpers/common-methods';
import { mlbPlayerPoints } from '../../../helpers/MLB/Points';
import { toastError, toastSuccess } from '../../../helpers/toasts';
import MyTeamHeader from "./MyTeamHeader";
import PlayerCardModal from '../../../modals/SeasonLong/PlayerCard/PlayerCardModal';
import { ColorContext } from '../../../context/ColorProvider';

class PlayerList extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      player_id: null,
      opp_id: null,
      swapPlayerId: null,
      swapPlayerPos: null,
      isBench: null,
      swapPlayerIndex: null,
      addRow: false,
      rowId: null,
      players: props.players,
      sc_rules: props.sc_rules,
      disableDiv: false,
      league_id: props.leagueId,
      modalVisible: false,
      selectedPlayerId: null,
      actualPos: null,
      playerStatus: null,
      swapping: false
    }
  }
  static contextType = ColorContext;

  componentWillReceiveProps(props) {
    this.setState({ players: props.players, sc_rules: props.sc_rules, league_id: props.leagueId })
  }

  swapPlayers(lPos, isEmpty, pl1, pl2, pli1, pli2, index) {
    this.setState({ addRow: false, swapping: true })
    let delBench = false
    let addBench = false
    let addIR = false
    if (lPos != "BE" && isEmpty && this.state.actualPos == "BE") {
      // delete row from bench
      // move player from bench to starter
      delBench = true
    } else if (lPos == "BE" && isEmpty) {
      addBench = true
    } else if ((lPos == "IR" || this.state.actualPos == "IR") && isEmpty) {
      addIR = true
    }

    swapPlayersAPI(pl1, pl2, pli1, pli2, delBench, addBench, this.state.league_id, addIR).then((resp) => {
      let playersCopy = this.state.players
      let successMessage = "Players have been swapped!"
      playersCopy = resp.players
      if (delBench) {
        this.setState({ swapPlayerPos: null, swapPlayerId: null, isBench: null, players: playersCopy, rowId: null, swapping: false })
        toastSuccess(successMessage)
        return 0
      }
      if (addBench) {
        this.setState({ swapPlayerPos: null, swapPlayerId: null, isBench: null, players: playersCopy, rowId: null, addRow: false, swapping: false })
        toastSuccess(successMessage)
        return 0
      }
      this.setState({ swapPlayerPos: null, swapPlayerId: null, isBench: null, players: playersCopy, addRow: false, rowId: null, swapping: false })
      toastSuccess(successMessage)
    }).catch(({ message }) => {
      toastError(message)
    })
  }

  // THIS IS FOR OVERdiv PLAYERS ROW
  overviewPlayer = (swapButton, player, dynamic, type, Colors = this.context) => {
    return (
      <Row>
        <Cell className="bg-transparent" >
          <div className='d-flex flex-row align-items-center' style={{ cursor: 'pointer' }}>
            {swapButton && !this.props.hasOpp && !player.game_started && !player.allLocked ? swapButton : (
              <div>
                <div className='d-flex' style={{ border: "2px solid dimgrey", borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center" }} >
                  <p className='m-0' > {player.pos} </p>
                </div>
              </div>
            )}
            <div className='d-flex' onClick={() => {this.showPlayerModal();this.setState({ selectedPlayerId: player.id });}}>
              <div className='ml-2' >
                <img className='roundImg' src={player.image} />
              </div>
              <div style={{ flex: 2 }} >
                <p className='bold ml-2 mt-4' style={{ marginLeft: "2px" }} >
                  {player.is_empty == 1 ? "Empty" : player.full_name}
                </p>
                {ILStatuses().includes(player.status) && <p category="c1" style={{ color: Colors.colorDanger, marginLeft: "2px", marginTop: 3 }}>IL</p>}
              </div>
            </div>
          </div>
        </Cell>
        {player.game_started || player.allLocked ? (
          <Cell className="bg-transparent" >
            <div style={{ marginVertical: 3 }} >
              {player.game_result ? (
                <p className='m-0' >
                  {player.game_result.home_abbr} <span className='bold' >{player.game_result.home_runs}</span> @ {player.game_result.away_abbr} <span className='bold ml-1 mr-1' >{player.game_result.away_runs}</span>
                </p>
              ) : ("--")}
            </div>
          </Cell>
        ) : (
          player.opp != null ? (
            <Cell className="bg-transparent">
              {player.opp.bye && !player.game_started ? (
                "BYE"
              ) : (
                <div>
                  <p className='m-0'>{player.opp.team1} @ {player.opp.team2}</p>
                  <p className='m-0'>{player.opp.date}</p>
                </div>
              )}
            </Cell>
          ) : (
            <Cell className="bg-transparent">
              --
            </Cell>
          ))}
        {POINT_BASED_LEAGUES.includes(this.props.scoringFormat) &&
          <Cell className="bg-transparent">
            <p style={{ marginBottom: 2, fontWeight: "bold" }}>
              {mlbPlayerPoints(player.stats, this.props.sc_rules, this.props.scoringFormat)}
            </p>
          </Cell>}
        {this.prepareDynamicColsVals(player, type)}
      </Row>)
  }

  prepareDynamicColsVals = (player, type) => {
    if (type == "batters") {
      return this.props.selectedCats && this.props.selectedCats.hitting.map((stat) => (
        <Cell className="bg-transparent">{parseTotalStat(stat, player.stats[stat])}</Cell>
      ))
    } else {
      return this.props.selectedCats && this.props.selectedCats.pitching.map((stat) => (
        <Cell className="bg-transparent">{parseTotalStat(stat, player.stats[stat])}</Cell>
      ))
    }
  }

  playerRow(player, disable = null, index = null, dynamic, type) {
    var isEmpty = player.is_empty == 1 ? true : false
    var swapButton = this.getSwapButton(player, disable, index, isEmpty)
    return this.overviewPlayer(swapButton, player, dynamic, type)
  }

  updateSwapInfo(playerPos, pos, rowId, playerId, isBench, index, playerStatus) {
    let addRow = false
    var needEmptyRow = true
    // CHECK IF WE ALREADY HAVE EMPTY BENCH SPOT THEN DON'T ADD NEW ONE, FILL IT FIRST
    this.state.players.be != undefined && this.state.players.be.map((player) => {
      if (player.is_empty == 1) {
        needEmptyRow = false
      }
    })
    // WE ARE ALLOWING ADDING EMPTY ROW IN THE CASE OF PICTHERS BECAUSE WHEN IT SWAP THIS WILL TAKE THE BATTERS EMPTY SPOT, WE ARE FOLLOWING ESPN WAY
    if ((pos != "BE" && needEmptyRow) || (this.props.type == "pitchers")) {
      addRow = true
    }
    this.setState({ swapPlayerPos: playerPos, rowId: rowId, swapPlayerId: playerId, isBench: isBench, swapPlayerIndex: index, addRow: addRow, actualPos: pos, playerStatus: playerStatus })
  }

  resetSwapInfo() {
    // remove empty spots from bench
    let players = this.state.players
    if (players.be != undefined) {
      players.be.map((player, index) => {
        if (player.is_empty == 1 && player.custom != undefined) {
          delete players.be[index]
        }
      })
    }
    this.setState({ swapPlayerPos: null, swapPlayerId: null, isBench: null, swapPlayerIndex: null, addRow: false, players: players })
  }

  getSwapCircle(player, index, Colors = this.context) {
    return <div onClick={() => this.swapPlayers(player.pos, player.is_empty, player.l_id, this.state.rowId, player.id, this.state.swapPlayerId, index)} style={{ alignItems: "center" }} >
      <div className='d-flex' style={{ border: "2px solid " + Colors.green, borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center" }} >
        <p className='m-0' > {player.pos} </p>
      </div>
    </div>
  }

  getSwapButton = (player, disable, index = null, isEmpty = false, Colors = this.context) => {
    let isBench = player.pos == "BE" ? true : false
    if (disable == null) {
      // normal case
      if (!isEmpty) {
        return <div onClick={() => this.updateSwapInfo(player.a_pos, player.pos, player.l_id, player.id, isBench, index, player.status)} style={{ alignItems: "center" }} >
          <div className='d-flex' style={{ border: "2px solid " + Colors.green, borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center" }} >
            <p className='m-0' > {player.pos} </p>
          </div>
        </div>
      }
    } else {
      if (this.state.actualPos == "IL") {
        // SELECTED PLAYER RESET LOGIC
        if (player.pos == "IL" && player.id == this.state.swapPlayerId) {
          return <div onClick={() => this.resetSwapInfo()} style={{ alignItems: "center" }} >
            <div className='d-flex' style={{ border: "2px solid " + Colors.green, borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center" }} >
              <p className='m-0'> {player.pos} </p>
            </div>
          </div>
        }
        // IL TO NON-IL SWAPPING
        else if (player.a_pos == this.state.swapPlayerPos && !isEmpty && ILStatuses().includes(player.status)) {
          return <div onClick={() => this.swapPlayers(player.pos, player.is_empty, player.l_id, this.state.rowId, player.id, this.state.swapPlayerId, index)} style={{ alignItems: "center" }} >
            <div className='d-flex' style={{ border: "2px solid " + Colors.green, borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center" }} >
              <p className='m-0' > {player.pos} </p>
            </div>
          </div>
        }
        // ?
        else if (player.a_pos == this.state.swapPlayerPos && !isEmpty && !ILStatuses().includes(this.state.playerStatus)) {
          return <div onClick={() => this.swapPlayers(player.pos, player.is_empty, player.l_id, this.state.rowId, player.id, this.state.swapPlayerId, index)} style={{ alignItems: "center" }} >
            <div className='d-flex' style={{ border: "2px solid " + Colors.green, borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center" }} >
              <p className='m-0' > {player.pos} </p>
            </div>
          </div>
        }
        // IL TO EMPTY SPOT
        else if (isEmpty && player.pos == this.state.swapPlayerPos) {
          return <div onClick={() => this.swapPlayers(player.pos, player.is_empty, player.l_id, this.state.rowId, player.id, this.state.swapPlayerId, index)} style={{ alignItems: "center" }} >
            <div className='d-flex' style={{ border: "2px solid " + Colors.green, borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center" }} >
              <p > {player.pos} </p>
            </div>
          </div>
        }
        // IL TO EMPTY UTIL
        else if (isEmpty && (player.pos == "UTIL" && (UTIL_ELIGIBLE.includes(this.state.swapPlayerPos)))) {
          return <div onClick={() => this.swapPlayers(player.pos, player.is_empty, player.l_id, this.state.rowId, player.id, this.state.swapPlayerId, index)} style={{ alignItems: "center" }} >
            <div className='d-flex' style={{ border: "2px solid " + Colors.green, borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center" }} >
              <p className='m-0' > {player.pos} </p>
            </div>
          </div>
        }
        // IL TO EMPTY BENCH
        else if (isEmpty && player.pos == "BE") {
          return <div onClick={() => this.swapPlayers(player.pos, player.is_empty, player.l_id, this.state.rowId, player.id, this.state.swapPlayerId, index)} style={{ alignItems: "center" }} >
            <div className='d-flex' style={{ border: "2px solid " + Colors.green, borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center" }} >
              <p className='m-0' > {player.pos} </p>
            </div>
          </div>
        }
      } else {
        if (disable == true) {
          // disable all bench position except selected player
          // enable all SL players
          // THIS ENABLED RESET LOGIC FOR SELECTED PLAYER
          if (player.pos == "BE" && player.id == this.state.swapPlayerId) {
            return <div onClick={() => this.resetSwapInfo()} style={{ flex: 1, alignItems: "center" }} >
              <div className='d-flex' style={{ border: "2px solid " + Colors.green, borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center" }} >
                <p className='m-0' > {player.pos} </p>
              </div>
            </div>
          }
          // THIS WILL ENABLED ELIGIBLE PLAYERS IN STARTER FOR SWAPPING
          else if (player.pos != "BE" && player.pos == this.state.swapPlayerPos) {
            return this.getSwapCircle(player, index)
          }
          // THIS WILL ENABLE UTIL FOR SWAPPING
          else if (player.pos != "BE" && (player.pos == "UTIL" && (UTIL_ELIGIBLE.includes(this.state.swapPlayerPos)))) {
            return this.getSwapCircle(player, index)
          }
          // THIS WILL ENABLE CI FOR SWAPPING
          else if (player.pos != "BE" && (player.pos == "CI" && (CI_ELIGIBLE.includes(this.state.swapPlayerPos)))) {
            return this.getSwapCircle(player, index)
          }
          // THIS WILL ENABLE MI FOR SWAPPING
          else if (player.pos != "BE" && (player.pos == "MI" && (MI_ELIGIBLE.includes(this.state.swapPlayerPos)))) {
            return this.getSwapCircle(player, index)
          }
          else if (player.pos != "BE" && (player.pos == "OF" && (OF_ELIGIBLE.includes(this.state.swapPlayerPos)))) {
            return this.getSwapCircle(player, index)
          }
          else if (player.pos != "BE" && (player.pos == "P" && (P_ELIGIBLE.includes(this.state.swapPlayerPos)))) {
            return this.getSwapCircle(player, index)
          }
          else if (player.pos != "BE" && (player.pos == "IF" && (IF_ELIGIBLE.includes(this.state.swapPlayerPos)))) {
            return this.getSwapCircle(player, index)
          }
          // THIS WILL ENABLE IL SWAPPING
          else if (player.pos == "IL" && ILStatuses().includes(this.state.playerStatus)) {
            return this.getSwapCircle(player, index)
          }
        } else {
          // disable all SL position except selected player
          // enable all BE players
          // ACTUAL POS IS SL LINEUP POS
          // SWAP_POS IS ORIGINAL POS IN PLAYERS TABLE
          // THIS WILL APPLY RESET LOGIC TO CURRENT SELECTED GUY
          if (player.pos != "BE" && player.id == this.state.swapPlayerId) {
            return <div onClick={() => this.resetSwapInfo()}>
              <div className='d-flex' style={{ border: "2px solid " + Colors.colorDanger, borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center" }} >
                <p className='m-0' > {player.pos} </p>
              </div>
            </div>
          }
          // THIS WILL DO SWAPPING FOR NON-UTIL TO UTIL
          else if (player.pos != "BE" && (this.state.actualPos != "UTIL" && player.pos == "UTIL" && (this.state.swapPlayerPos == player.a_pos)) && !isEmpty) {
            return this.getSwapCircle(player, index)
          }
          else if (player.pos != "BE" && (this.state.actualPos != "CI" && player.pos == "CI" && (this.state.swapPlayerPos == player.a_pos)) && !isEmpty) {
            return this.getSwapCircle(player, index)
          }
          else if (player.pos != "BE" && (this.state.actualPos != "MI" && player.pos == "MI" && (this.state.swapPlayerPos == player.a_pos)) && !isEmpty) {
            return this.getSwapCircle(player, index)
          }
          else if (player.pos != "BE" && (this.state.actualPos != "P" && player.pos == "P" && (this.state.swapPlayerPos == player.a_pos)) && !isEmpty) {
            return this.getSwapCircle(player, index)
          }
          else if (player.pos != "BE" && (this.state.actualPos != "OF" && player.pos == "OF" && (this.state.swapPlayerPos == player.a_pos)) && !isEmpty) {
            return this.getSwapCircle(player, index)
          }
          else if (player.pos != "BE" && (this.state.actualPos != "IF" && player.pos == "IF" && (this.state.swapPlayerPos == player.a_pos)) && !isEmpty) {
            return this.getSwapCircle(player, index)
          }
          // THIS MOVE UTIL PLAYER TO CORRESPONDING POSITION
          else if (player.pos != "BE" && this.state.actualPos == "UTIL" && (this.state.swapPlayerPos == player.pos) && !isEmpty) {
            return this.getSwapCircle(player, index)
          }
          else if (player.pos != "BE" && this.state.actualPos == "CI" && (this.state.swapPlayerPos == player.pos) && !isEmpty) {
            return this.getSwapCircle(player, index)
          }
          else if (player.pos != "BE" && this.state.actualPos == "MI" && (this.state.swapPlayerPos == player.pos) && !isEmpty) {
            return this.getSwapCircle(player, index)
          }
          else if (player.pos != "BE" && this.state.actualPos == "P" && (this.state.swapPlayerPos == player.pos) && !isEmpty) {
            return this.getSwapCircle(player, index)
          }
          else if (player.pos != "BE" && this.state.actualPos == "OF" && (this.state.swapPlayerPos == player.pos) && !isEmpty) {
            return this.getSwapCircle(player, index)
          }
          else if (player.pos != "BE" && this.state.actualPos == "IF" && (this.state.swapPlayerPos == player.pos) && !isEmpty) {
            return this.getSwapCircle(player, index)
          }
          // THIS WILL ENABLED BENCH FOR SWAPPING
          else if (player.pos == "BE" && player.a_pos == this.state.swapPlayerPos && !isEmpty) {
            return this.getSwapCircle(player, index)
          }
          // THIS WILL ENABLE IL FOR SWAPPING
          else if (player.pos == "IL" && ILStatuses().includes(this.state.playerStatus)) {
            return this.getSwapCircle(player, index)
          }
          // THIS WILL ENABLE EMPTY BENCH FOR SWAPPING
          else if (isEmpty && player.pos == "BE") {
            return this.getSwapCircle(player, index)
          }
        }
      }
    }
  }

  prepareRoster = (defaultPos, dynamic = false, type = "batters", Colors = this.context) => {
    var roster = []
    if (this.state.players != undefined || this.state.players != null) {
      if (this.state.addRow && !dynamic) {
        // WE WOULD NEED TO PUT DUMMY BATTER/PITCHER POS IN EMPTY SLOT SO THAT BENCH WILL BE SHOWN AT CORRECT LOCATION
        var aPos = type == "batters" ? "C" : "P"
        this.state.players.be.push({
          a_pos: aPos,
          full_name: "Empty", id: 0, image: null, l_id: null, locked: false, points: "", pos: "BE", ppg: "", team: "", is_empty: 1, stats: [], custom: true
        })
      }
      // NON BENCH PLAYERS
      defaultPos.map((val) => {
        this.state.players.non_be != undefined && this.state.players.non_be.map((player, index) => {
          if (player.pos == val) {
            if (this.state.swapPlayerId != null) {
              // means swap called
              if (this.state.isBench) {
                // disable bench and enable swap action for SL
                roster.push(
                  this.playerRow(player, true, index, dynamic, type)
                )
              } else {
                // disable SL and enable bench for swap action
                roster.push(
                  this.playerRow(player, false, index, dynamic, type)
                )
              }
            } else {
              // normal case
              roster.push(
                this.playerRow(player, null, index, dynamic, type)
              )
            }
          }
        })
      })
      roster.push(<Row>
        <Cell style={{ backgroundColor: Colors.bgLight }}> {"BENCH"}</Cell>
        <Cell style={{ backgroundColor: Colors.bgLight }}></Cell>
        {this.emptyCells()}
      </Row>)

      // BENCH PLAYERS
      var benchPlayers = []
      if (type == "pitchers") {
        this.state.players.be.map((player, index) => {
          if (PITCHERS.includes(player.a_pos)) {
            benchPlayers.push(player)
          }
        })
      } else {
        this.state.players.be.map((player, index) => {
          if (!PITCHERS.includes(player.a_pos)) {
            benchPlayers.push(player)
          }
        })
      }

      benchPlayers.map((player, index) => {
        if (this.state.swapPlayerId != null) {
          // means swap called
          if (this.state.isBench) {
            // disable bench and enable swap action for SL
            roster.push(
              this.playerRow(player, true, index, dynamic, type)
            )
          } else {
            // disable SL and enable bench for swap action
            roster.push(
              this.playerRow(player, false, index, dynamic, type)
            )
          }
        } else {
          // normal case
          roster.push(
            this.playerRow(player, null, index, dynamic, type)
          )
        }
      })

      if (type == "batters") {
        // ONLY BATTERS CAN BE MOVED TO IL POSITIONS
        roster.push(<Row>
          <Cell style={{ backgroundColor: Colors.bgLight }}> {"INJURED LIST"}</Cell>
          <Cell style={{ backgroundColor: Colors.bgLight }}></Cell>
          {this.emptyCells()}
        </Row>)

        this.state.players.non_be != undefined && this.state.players.non_be.map((player, index) => {
          if (player.pos == "IL") {
            if (this.state.swapPlayerId != null) {
              // means swap called
              if (this.state.isBench) {
                // disable bench and enable swap action for SL
                roster.push(
                  this.playerRow(player, true, index, dynamic, type)
                )
              } else {
                // disable SL and enable bench for swap action
                roster.push(
                  this.playerRow(player, false, index, dynamic, type)
                )
              }
            } else {
              // normal case
              roster.push(
                this.playerRow(player, null, index, dynamic, type)
              )
            }
          }
        })
      }
    }
    return roster
  }

  showPlayerModal = () => {
    // this.setState({ modalVisible: !this.state.modalVisible, selectedPlayerId: player.id });
    this.setState({ modalVisible: !this.state.modalVisible });
  };

  emptyCells = (Colors = this.context) => {
    if (this.props.type == "pitchers") {
      // PITCHERS
      return this.props.selectedCats && this.props.selectedCats.pitching.map(() => (
        <Cell style={{ backgroundColor: Colors.bgLight }} ></Cell>
      ))
    } else {
      // BATTERS
      return this.props.selectedCats && this.props.selectedCats.hitting.map(() => (
        <Cell style={{ backgroundColor: Colors.bgLight }}></Cell>
      ))
    }
  }

  render() {
    const Colors = this.context;
    return (
      <>
        <LoadingOverlay
          active={this.state.swapping}
          spinner
          text='Swapping...'
          className="d-flex flex-column flex1 min-h-0"
        >
          <StickyTable leftStickyColumnCount={1} borderWidth={'1px'} borderColor={Colors.darkBgBorder} >
            <MyTeamHeader listType={"BATTERS"} {...this.props} />
            {this.prepareRoster(BATTERS, true, "batters").map((player, index) => {
              return player
            })}
            <MyTeamHeader listType={"PITCHERS"} {...this.props} />
            {this.prepareRoster(PITCHERS, true, "pitchers").map((player, index) => {
              return player
            })}
          </StickyTable>
          {/* {this.state.modalVisible && (
            <PlayerModal
              player_id={this.state.selectedPlayerId}
              league_id={this.state.league_id}
              modalVisible={this.state.modalVisible}
              setModalVisible={() => this.setState({ modalVisible: !this.state.modalVisible})}
            />
          )} */}
        </LoadingOverlay>

        <PlayerCardModal modal={this.state.modalVisible} toggle={this.showPlayerModal} playerId={this.state.selectedPlayerId} leagueId={this.props.leagueId} sport={"MLB"} />
      </>
    )
  }
}

export default PlayerList