import React from 'react'
import { plusIcon } from '../../../helpers/icons'
import { setActiveTeam } from '../../../apis/Arena/MatchMakerApis'
import { connect } from 'react-redux'
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom'
import { countMatches } from '../../../helpers/Arena/ArenaHelper'
import { motion } from 'framer-motion/dist/framer-motion'
import { collapseHorizontal, collapseVertical, shrinkVertical } from '../../../pages/NewUserOnboarding/animationValues'
import { ColorContext } from '../../../context/ColorProvider'
import { getSportColor } from '../../../helpers/stylesHelper';

const LineupsHeader = (props) => {
    const {active_team, all_teams, setActiveTeam, teams_order} = props;
    const history = useHistory()
    const Colors = React.useContext(ColorContext);

    const handleTeamChange = (team) => {
        setActiveTeam(team);
    }

    const handleAllTeams = () => {
        const allTeamsObject =   {
            "teamId": "all",
            "teamName": "ALL",
            "leagueName": "View All Teams Data",
            "pendingCount": countMatches(all_teams, 'pending'),
            "confirmedMatchCount": countMatches(all_teams, 'confirmed'),
          }
          setActiveTeam(allTeamsObject);
    }

    return (
        <div className='d-flex flex-column' style={{overflow:'hidden'}}>
            <div className='d-flex flex-grow-1 pl-4 align-items-end scrollHidden' style={{background: Colors.arenaHeader_v3, height: "112px"}}>
                {/* Import Lineup */}
                <div className='d-flex align-items-center' style={{height: "76px"}} >
                    <div style={{width:10}}></div>
                    <div className='d-flex align-items-center justify-content-center my-auto pointer' style={{borderRadius:50, backgroundColor:Colors.bgDark_v3, padding: "10px"}} onClick={() =>history.push('/new-user-onboarding')}>
                        {plusIcon()}
                    </div>
                    <div style={{width:10}}></div>
                </div>

                {/* All */}
                {/* <div className='mx-2 d-flex align-items-center pointer px-3' style={{height: "76px", background: active_team.teamId == "all" ? Colors.bgDark_v3 : Colors.arenaHeader_v3, borderTopRightRadius: "16px", borderTopLeftRadius: "16px"}} onClick={handleAllTeams} >
                    <div className='d-flex align-items-center justify-content-center my-auto' style={{height:50, width:50, borderRadius:50, backgroundColor:Colors.bgDark_v3}}>
                        <h5 className='m-0' >ALL</h5>
                    </div>
                </div> */}

                <div className='d-flex pointer' style={{height: "76px"}} onClick={handleAllTeams}>
                    {/* <div style={{width:8, backgroundColor: active_team?.teamId == 'all' ? Colors.bgDark_v3 : ""}}>
                        <div className='h-100' style={{borderBottomRightRadius:'16px'}}></div>
                    </div> */}
                    <div style={{ backgroundColor: active_team?.teamId == 'all' ? Colors.bgDark_v3 : "", borderTopRightRadius: "16px", borderTopLeftRadius: "16px"  }}>
                        <div className='d-flex flex-column justify-content-center align-items-center h-100' style={{width: "88px"}} >
                          <div className='d-flex align-items-center justify-content-center my-auto' style={{height:50, width:50, borderRadius:50, backgroundColor: active_team?.teamId == 'all' ? Colors.arenaHeader_v3 : Colors.bgDark_v3}}>
                              <h5 className='m-0' style={{ fontWeight: 600 }}>ALL</h5>
                          </div>
                        </div>
                    </div>
                    {/* <div style={{width:8, backgroundColor: active_team?.teamId == 'all' ? Colors.bgDark_v3 : ""}}>
                        <div className='h-100' style={{borderBottomLeftRadius:'16px'}}></div>
                    </div> */}
                </div>

                <PerfectScrollbar className='d-flex align-items-end' style={{overflowX: "auto"}} >
                    {/* {
                    Object.values(all_teams).map(team => {
                        return (
                            <div key={team.id} className='d-flex pointer' style={{backgroundColor:Colors.arenaHeader_v3, height: "76px"}} onClick={() => handleTeamChange(team)}>
                                <div style={{width:8, backgroundColor: active_team.teamId == team.teamId ? Colors.bgDark_v3 : Colors.arenaHeader_v3}}>
                                    <div className='h-100' style={{backgroundColor:Colors.arenaHeader_v3, borderBottomRightRadius:'16px'}}></div>
                                </div>
                                <div style={{ background: active_team.teamId == team.teamId ? Colors.bgDark_v3 : Colors.arenaHeader_v3, borderTopRightRadius: "16px", borderTopLeftRadius: "16px"  }}>
                                    <div className='d-flex flex-column justify-content-center align-items-center h-100' style={{width: "88px"}} >
                                        <img className='img-fluid roundImgMed' src={team.teamImage}/>
                                    </div>
                                </div>
                                <div style={{width:8, backgroundColor: active_team.teamId == team.teamId ? Colors.bgDark_v3 : Colors.arenaHeader_v3}}>
                                    <div className='h-100' style={{backgroundColor:Colors.arenaHeader_v3, borderBottomLeftRadius:'16px'}}></div>
                                </div>
                            </div>
                        )
                    })
                    } */}

{
                    teams_order?.map(teamId => {
                        let team = all_teams[teamId];
                        // console.log(team);
                        return (
                            <div key={team.id} className='d-flex pointer' style={{height: "76px"}} onClick={() => handleTeamChange(team)}>
                                {/* <div style={{width:8, backgroundColor: active_team.teamId == team.teamId ? Colors.bgDark_v3 : Colors.arenaHeader_v3}}>
                                    <div className='h-100' style={{backgroundColor:Colors.arenaHeader_v3, borderBottomRightRadius:'16px'}}></div>
                                </div> */}
                                <div style={{ background: active_team.teamId == team.teamId ? Colors.bgDark_v3 : "", borderTopRightRadius: "16px", borderTopLeftRadius: "16px"  }}>
                                    <div className='d-flex flex-column justify-content-center align-items-center h-100 position-relative' style={{width: "88px"}} >
                                        {
                                            team.pendingCount > 0 &&
                                            <div className='position-absolute' style={{right:'10px', top:'10px'}}>
                                                <p className='d-flex align-items-center justify-content-center' style={{width:20, height:20, backgroundColor:'#FF0000', borderRadius:20, border:`2px solid ${Colors.bgDark_v3}`}}>{team.pendingCount}</p>
                                            </div>
                                        }
                                        <img className='img-fluid roundImgMed' src={team.teamImage} style={{borderColor: getSportColor(team.sport)}}/>
                                    </div>
                                </div>
                                {/* <div style={{width:8, backgroundColor: active_team.teamId == team.teamId ? Colors.bgDark_v3 : Colors.arenaHeader_v3}}>
                                    <div className='h-100' style={{backgroundColor:Colors.arenaHeader_v3, borderBottomLeftRadius:'16px'}}></div>
                                </div> */}
                            </div>
                        )
                    })
                    }

                </PerfectScrollbar>
            </div>

            {/* {
            active_team.teamId != 'all'
            &&
            <div className='d-flex align-items-center pt-3 pb-2 px-4'>
                <img className='roundImgMed' style={{height:70, width:70}} src={active_team.teamImage}/>
                <div className='ml-3'>
                    <h3 className='m-0 heading-1'>{active_team.teamName}</h3>
                    <p className='m-0 small-font' style={{color:Colors.textSecondary_v3}}>{active_team.leagueName}</p>
                </div>
            </div>
            } */}
            <div className='d-flex align-items-center m-0' style={{overflow:'hidden', marginTop: '16px', marginBottom:'16px'}}>
                <motion.div 
                    className='d-flex align-items-center px-5'
                    style={{height:'112px'}}
                    animate={ active_team.teamId == 'all' ? 'collapse' : 'expand'}
                    transition={{duration: 0.5}}
                    initial={false}
                    variants={shrinkVertical}
                >
                    {
                        active_team.teamId == 'all'
                        ?
                        <div className='d-flex align-items-center justify-content-center my-auto roundImgMedNoOutline' style={{height:70, width:70, borderRadius:50, backgroundColor:Colors.bgDark_v3}}><h4>{active_team.teamName}</h4></div>
                        :
                        <img className='roundImgMedNoOutline' style={{height:70, width:70, borderColor: getSportColor(active_team?.sport)}} src={active_team?.teamImage}/>
                    }
                    <div className='ml-3'>
                        <h3 className='m-0 heading-1' style={{ fontWeight: "600", fontFamily: "Alexandria", fontSize: 32 }}>{active_team.teamName}</h3>
                        <p className='m-0' style={{ color:Colors.textSecondary_v3, fontWeight: "500", fontFamily: "Barlow", fontSize: 16 }}>{active_team.leagueName}</p>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

const mapDispatchToProps = { setActiveTeam }
export default connect(null, mapDispatchToProps)(LineupsHeader)