import moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { addEmoji, chatConversation, getUrlPreview, sendMessage } from '../../apis/Chat/ChatAPI';
import RichText from './RichText';
import Thread from './Thread';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loaders';
import { UID } from '../../helpers/common-methods';
import { mapping } from '../../helpers/ChannelsConfig';
import LoadingOverlay from 'react-loading-overlay';
import { toastError } from '../../helpers/toasts';
import Message from './Templates/Message';
import ChatSettingsModal from '../../modals/ChatModals/ChatSettingsModal';
import { linkify } from '../../helpers/common-methods';
import ProgressBar from './ProgressBar/ProgressBar';
import queryString from 'query-string';
import { teamChatMeta } from '../../apis/Arena/MatchMakerApis';
import NewUnreadMessage from './NewUnreadMessage/NewUnreadMessage';
import store from '../../store';
import { PulseLoader, SmallLoader } from '../Loader';
import { ColorContext } from '../../context/ColorProvider';

class Main extends Component {

    // Globals
    channels = [];

    constructor(props) {
        super(props);
        this.state = {

            // Component states
            modal: false,
            thread: false,
            title: null,
            image: null,
            loader: true,
            chatData: null,
            lost: false,

            // History payload
            payload: null,

            // Backend states
            offset: 0,
            limit: 20,
            runningDate: null,

            // Pusher States
            lastReceiverId: null,
            lastChannelName: null,
            lastPublicChannelName: null,
            lastTypingPusherChannel: null,
            typingPusherChannel: null,

            // API Response states
            sidebet: {
                type: '',
            },
            messages: [
                {
                    msg_id: 0,
                    ts: null,
                    message_content: '',
                    attachment: null,
                    attachments: [],
                    attachment_type: null,
                    time: null,
                    reactions: [],
                    attachment_url: null,
                    user: {
                        username: "",
                        id: "",
                        image: ""
                    },
                    has_thread: false
                }
            ],
            hasMore: true,
            arena_mode: this.props.match.params.matchId ? true : false,
            league_id: this.props.match.params.matchId,
            users: [],
            current_team: null,
            chat_to: null,
            // Unread Messages
            unreadMessagesCount: 0,
            errors: []
        }

        this.toggleThread = this.toggleThread.bind(this);
        this.handleMessage = this.handleMessage.bind(this);
        this.handleBetChange = this.handleBetChange.bind(this);
        this.handleCommand = this.handleCommand.bind(this);
        this.fetchMessages = this.fetchMessages.bind(this);
        this.removeMessageFromState = this.removeMessageFromState.bind(this);
        this.sendReaction = this.sendReaction.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.getMentionableUsers = this.getMentionableUsers.bind(this);
        this.chatBottomListener = this.chatBottomListener.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
    }

    static contextType = ColorContext;

    componentDidMount() {
        this.fetchMessages()
        if (this.props.history.location.state) {
            this.setState({
                payload: this.props.history.location.state
            })
        }

        if (this.props.match.params.matchId) {
            teamChatMeta("?matchId="+this.props.match.params.matchId)
                .then(response => {
                    this.setState({
                        users: response.teams,
                        current_team: response.c_t,
                        chat_to: response.chatTo,
                        title: response.chatTo.team_name,
                        image: response.chatTo.image
                })
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let type = this.props.match.params.type
        let id = this.props.match.params.id

        // basic chat details like team name or group title
        if(!this.state.arena_mode){
            this.updateChatInfo(type, id)
        }
        if (this.state.loader == false) {
            if (this.state.payload && this.props.users[id]) {
                this.handleMessage('/trade', 'trade')
            }
        }

        // Check if user has switched chats
        if (prevProps.match.params.id !== id) {
            this.setState({
                messages: [],
                loader: true,
                lost: false
            })
            this.fetchMessages()

            if (this.props.history.location.state) {
                this.setState({
                    payload: this.props.history.location.state
                })
            }
        }

        // Reset running date everytime loadMore is hit so it doesnt append to bottom of inbox
        if (prevState.offset != this.state.offset) {
            this.setState({
                runningDate: null
            })
        }
    }

    componentWillUnmount() {
        this.resetChannels();
    }

    // unsubscribe all the subscribed channels related to chats
    resetChannels() {
        this.channels.map((channel) => {
            window.pusher.unsubscribe(channel);
        });
        this.channels = [];
    }

    /**
     * Update the information of currently active chat window (such as title)
     * @param {string} type - type of chat currently active group/user
     * @param {string} id - id of current active chat
     */
    updateChatInfo(type, id) {
        let title = null
        let image = null

        if (type == 'group') {
            title = this.props.channels[id]?.name
        }
        else if (type == 'user') {
            // if(this.state.chat_to){
            //     title = this.state.chat_to?.team_ame
            //     image = this.state.chat_to?.image
            // }
            // else {
                title = this.props.users[id]?.team_name
                image = this.props.users[id]?.image
            // }
        }
        // Prevent setting state multiple times in case componentDidUpdate runs multiple times
        if (title !== null && title != undefined && title != this.state.title) {
            this.setState({
                title: title,
                image: image
            })
        }
    }


    /**
     * Fetch messages for current user from API
     * @param {bool} loadMore - loadMore is true if fetchMessages is called on scroll in same conversation
     */
    fetchMessages(loadMore = false) {

        const type = this.props.match.params.type
        const id = this.props.match.params.id
        const offset = loadMore ? this.state.offset + this.state.limit : 0 // Send the state offset only if loadMore is true
        const league_id = this.state.league_id == undefined ? this.props.leagueId : this.props.match.params.matchId
        const related_to = this.props.match.params?.matchId ? "MM" : "L"

        chatConversation(league_id, related_to, type, id, offset)
            .then((response) => {
                if (loadMore) {
                    this.setState({
                        messages: [...this.state.messages, ...response.data.messages]
                    })
                }
                else {
                    this.setState({
                        messages: [...response.data.messages],
                        loader: false,
                    })
                    this.setupPusher(response.data, type)
                }
                const hasMore = !(response.data.messages.length < 20)
                const runningDate = null
                this.setState({
                    offset: offset,
                    hasMore: hasMore,
                    runningDate: runningDate // Set to null so it doesn't conflict while populating data again
                })
            })
            .catch((errors) => this.setState({
                errors: errors
            }));
    }

    sortMessages = (messages) => {
        return messages.sort(function (a, b) {
            return a.ts - b.ts
        })
    }

    /**
     * Setup pusher everytime user follows chat route
     * @param {string} channel - pusher channel to be subscribed
     * @param {string} type - group/user
     */
    setupPusher(data, type) {
        if (type == "user") {
            this.subscribeOneToOneChannel(data?.channel);
        } else {
            this.subscribeGroupChannel(data);
        }
        this.subscribeTypingChannel(data?.channel);
    }

    /**
     * Subscribe typing status channel
     * @param {string} pusherChannel - Channel to fire typing events to
     */
    subscribeTypingChannel(pusherChannel) {
        var channelTemp = window.pusher.subscribe(pusherChannel + "@typing");
        if (this.state.lastTypingPusherChannel != null) {
            this.unsubscribeLastTypingChannel();
        }
        this.channels.push(pusherChannel + "@typing");
        this.setState({
            typingPuhserChannel: channelTemp
        });
    }

    /**
     * If user is on group chat route subscribe this channel
     * @param {string} channelName - subscribe this channel
     */
    subscribeGroupChannel(data) {
        // var { chatData } = this.state;
        var chatId = this.props.match.params.id;
        var channelName = data?.channel  
        var public_channel = data?.public_channel  
        if (this.state.lastReceiverId != null) {
            this.unsubscribeLastChannel();
        }
        let groupChannel = window.pusher.subscribe(channelName)
        let publicGroupChannel = window.pusher.subscribe(public_channel)
        this.channels.push(channelName);
        this.channels.push(publicGroupChannel);
        groupChannel.bind(mapping.group_event + chatId, (msg) => {
            this.handleIncomingMessage(msg);
        });
        publicGroupChannel.bind(mapping.group_event + chatId, (msg) => {
            this.handleIncomingMessage(msg);
        });
        // bind reaction event
        groupChannel.bind(mapping.group_emoji_event, (resp) => {
            this.updateEmojiData(resp, "group");
        });
        // bind reaction event
        groupChannel.bind(mapping.group_emoji_event, (resp) => {
            // this.updateEmojiData(resp, "group")
        });
        this.setState({
            lastReceiverId: chatId,
            lastTypingPusherChannel: this.state.lastChannelName,
            lastChannelName: mapping.group_channel + chatId,
            lastPublicChannelName: publicGroupChannel,
        });
    }

    /**
     * If user is on one-to-one chat route subscribe this channel
     * @param {string} channelName - subscribe this channel
     */
    subscribeOneToOneChannel(channelName) {

        var senderId = this.props.auth.user.id;
        var chatId = this.props.match.params.id;

        if (this.state.lastReceiverId != null) {
            this.unsubscribeLastChannel();
        }

        let OneToOneChannel = window.pusher.subscribe(channelName);
        this.channels.push(channelName);

        OneToOneChannel.bind(mapping.user_chat_event + senderId, (msg) => {
            this.handleIncomingMessage(msg);
        });
        // bind reaction event
        OneToOneChannel.bind(mapping.user_chat_emoji_event, (resp) => {
            this.updateEmojiData(resp, "user");
        });
        this.setState({
            lastReceiverId: chatId,
            lastTypingPusherChannel: this.state.lastChannelName,
            lastChannelName: channelName
        });
    }

    /**
     * REAL TIME REACTIONS COUNT UPDATES
     * @param {object} resp - Updated reactions data
     */
    updateEmojiData(resp) {
        resp = resp.data;
        let newMessages = [];
        newMessages = this.state.messages.map((msg) => {
            if (msg.msg_id == resp.id) {
                msg["reactions"] = resp.reactions;
            }
            return msg;
        });
        this.setState({ messages: newMessages });
    }

    unsubscribeLastTypingChannel() {
        window.pusher.unsubscribe(this.state.lastTypingPusherChannel + '@typing');
    }
    // Unsunscribe the last set channel
    unsubscribeLastChannel() {
        window.pusher.unsubscribe(this.state.lastChannelName);
        window.pusher.unsubscribe(this.state.lastPublicChannelName);
    }

    /**
     * Send a message via API call
     * @param {string} message - message to be broadcasted
     * @param {string} type - type of message thread/null
     */
    sendMessage(data, config = {}) {
        // let id = null
        if(this.state.arena_mode){
            const exp = store.getState().leagueJoin.app_status
            if(Object.keys(config).length === 0){
                // NULL CONFIG
                data["product"] = exp
                data["related_to"] = "MM"
            }else{
                data.append("product", exp)
                data.append("related_to", "MM")
            }
        }else{
            if(Object.keys(config).length === 0){
                data["related_to"] = "L"
            }else{
                data.append("related_to", "L")
            }
        }
        return sendMessage(data, config)
            .then((response) => {
                // Make a deep copy of messages state to avoid new messages while processing ID
                let messages = this.state.messages

                // Find the index of temp message from deep copy
                let messageIndex = messages.findIndex(message => message.msg_id == data.temp_id)

                // If message is found update the ID
                if (messageIndex != -1) {
                    messages[messageIndex]['msg_id'] = response.data.id
                }

                /**
                 * +1 the offset everytime sendMessage has a success response
                 * Just to make sure top most message in current chat is not 
                 * retrieved again in case if loadMore is trigerred
                 */
                this.setState({
                    messages: [...messages],
                    offset: this.state.offset + 1
                })
                // console.log("SENT: ", response, response.data, response.data.id);
                // id = response.data
                return response.data.id
            })
            .catch((errors) => this.setState({
                errors: errors
            }));

        // return Promise.resolve(id);
    }

    sendReaction(data) {
        let messages = JSON.parse(JSON.stringify(this.state.messages));
        // let messages = this.state.messages
        data['window_type'] = this.props.match.params.type
        data['socket_id'] = window.pusher.connection.socket_id
        data['league_id'] = this.state.league_id || this.props.leagueId
        data['to'] = this.state.lastReceiverId
        data['related_to'] = this.state.arena_mode ? "MM" : "L"
        // update emoji state locally
        // update count of emoji
        let newMessages = {};
        newMessages = messages.map((originalMsg) => {

            var msg = JSON.parse(JSON.stringify(originalMsg))
            let emoji = data.emoji

            if (msg.msg_id == data.id) {
                if (msg.reactions[emoji] != undefined) {
                    if (msg.reactions[emoji].users[this.props.current_team.id] != undefined) {
                        // remove it
                        msg.reactions[emoji].count--;
                        if (msg.reactions[emoji].count == 0) {
                            delete msg.reactions[emoji]
                        } else {
                            delete msg.reactions[emoji].users[this.props.current_team.id]
                        }
                    } else {
                        msg.reactions[emoji].count++;
                        msg.reactions[emoji]["users"][this.props.current_team.id] = this.props.current_team.username;
                    }
                } else {
                    // first occurence of emoji
                    let users = {}
                    if (Object.keys(msg.reactions).length == 0) {
                        msg.reactions = {}
                    }
                    users[this.props.current_team.id] = this.props.current_team.username
                    msg.reactions[emoji] = {
                        count: 1,
                        users: users,
                        emoji_icon: data.emoji_icon
                    }
                }
                msg["_reaction_update"] = UID()
            }
            return msg
        })

        this.setState({ messages: newMessages });

        addEmoji(data)
            .then(response => {
                // Then
            })
            .catch(({ message }) => {
                toastError(message)
            })
    }


    // Scroll the conversation to bottom by default
    scrollToBottom(removeUnreadCount = false) {
        let el = document.getElementById('inbox-bottom');
        el.scrollIntoView({ behavior: 'smooth', block: 'end' });

        if(removeUnreadCount) {
            this.setState({
                unreadMessagesCount: 0
            })
        }
    }

    // Check if the chat is scrolled to bottom
    scrolledToBottom() {
        let el = document.getElementById('inbox-bottom');
        let rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    // TODO : Improve Efficiency of Listener
    // listen if inbox-bottom is in view
    // if yes, set unreadMessagesCount to 0
    chatBottomListener() {
        // let el = document.getElementById('inbox-bottom');
        // let rect = el.getBoundingClientRect();

        window.addEventListener('scroll', function() {
            var element = document.getElementById('inbox-bottom');
            var position = element.getBoundingClientRect();
        
            // checking whether fully visible
            // if(position.top >= 0 && position.bottom <= window.innerHeight) {
            //     console.log('Element is fully visible in screen');
            // }
            
            // checking for partial visibility
            if(position.top < window.innerHeight && position.bottom >= 0) {
                // console.log('Element is partially visible in screen');
                this.setState({ unreadMessagesCount: 0 });
            }
        });
    }


    // Toggle threads on message click handler
    toggleThread() {
        this.setState({
            thread: !this.state.thread
        });
    }

    // CHANGES BY FARAZ AT 6/7/22
    prepareTemplateAttachments(shortcutType, data) {
        // var data = null;
        if (shortcutType !== null) {
            if (shortcutType == 'giphy' && data.attachment == null) {
                data['attachments'] = [{
                    data: {
                        uri: null
                    },
                    template: 'GIPHY'
                }]
                // this.appendToStateMessages(data);
            }
            else if (shortcutType == 'sidebet' && data.attachment == null) {
                let { id } = this.props.match.params
                data['attachments'] = [{
                    data: {
                        leagueId: this.state.league_id || this.props.leagueId,
                    },
                    template: 'SIDEBET_CREATED'
                }]
                // this.appendToStateMessages(data);
            }
            else if (shortcutType == 'trade') {
                let { id } = this.props.match.params
                data['attachments'] = [{
                    data: {
                        oppId: id,
                        leagueId: this.state.league_id || this.props.leagueId,
                        oppName: this.props.users[id].team_name,
                        payload: this.state.payload
                    },
                    template: 'TRADE_PROPOSAL'
                }]
                if (this.state.payload) {
                    this.setState({
                        payload: null
                    })
                }
            }
            else if (shortcutType == 'tradeblock') {
                let { id } = this.props.match.params
                data['attachments'] = [{
                    data: {
                        oppId: id,
                        leagueId: this.state.league_id || this.props.leagueId,
                        // oppName: this.props.users[id].team_name,
                    },
                    template: 'TRADE_BLOCK_CREATION'
                }]
            }

            return data["attachments"]
        } else {
            return [];
        }
    }

    /**
     * Handle the message and prepare it to be sent
     * @param {string} message - message from Rich Text Box input
     * @param {string} type - thread/group/user
     */
    handleMessage(message, shortcutType = null, media = null, attachmentUrl = null, config) {
        // console.log({ media, attachmentUrl, shortcutType });
        // console.log('hand message config: ', config);
        // PREPARE LOCAL DATA

        var localData = {
            message_content: message,
            message_to: this.props.match.params.id,
            attachment: media ? media : null,
            attachment_type: media ? media.type.split('/')[0] : null,
            attachments: attachments,
            type: this.props.match.params.type,
            msg_type: "NORMAL",
            league_id: this.state.league_id || this.props.leagueId,
            temp_id: UID(),
            created_at: moment().utc().format(),
            socket_id: this.props.match.params.type == 'group' ? window.pusher.connection.socket_id : null,
            attachment_url: attachmentUrl ? attachmentUrl : null,
        };

        let data = new FormData()
        let message_content = localData.message_content;
        let message_to = localData.message_to;
        let attachment = localData.attachment;
        let attachment_type = localData.attachment_type;
        let attachments = shortcutType ? this.prepareTemplateAttachments(shortcutType, localData) : [];
        let type = localData.type;
        let msg_type = "NORMAL"
        let league_id = localData.league_id;
        let temp_id = localData.temp_id;
        let created_at = localData.created_at;
        let socket_id = localData.socket_id;
        let attachment_url = localData.attachment_url;


        // console.log('local data: ', localData);

        const links = linkify(message)
        if (shortcutType == 'trade' && type == 'group') {
            toastError('You can only create trades inside DM')
            return 0
        }
        // TYPE == COMMAND
        if (media !== null) {
            if (message) {
                data.append("message_content", message_content);
            };
            data.append("message_to", message_to);
            data.append("attachment", attachment);
            data.append("attachments", attachments);
            data.append("attachment_type", attachment_type);
            data.append("type", type);
            data.append("msg_type", msg_type);
            data.append("league_id", league_id);
            data.append("temp_id", temp_id);
            data.append("created_at", created_at);
            data.append("socket_id", socket_id);
        }

        // URL PREVIEW 
        if (links.length > 0) {
            localData['attachments'] = [
                {
                    data: links,
                    template: 'URL_LOCAL'
                }
            ];
            this.appendToStateMessages(localData);
            // GET PREVIEW OF LINKS
            getUrlPreview({ urls: links })
                .then(response => {
                    localData['attachments'] = [
                        {
                            data: response,
                            template: 'URL_PREVIEW'
                        }
                    ];
                    // REMOVE AND UPDATE STATE WITH NEW ATTACHMENTS
                    this.removeMessageFromState(localData.temp_id)
                    this.appendToStateMessages(localData);
                    if (media) {
                        this.sendMessage(data, config);
                    } else {
                        this.sendMessage(localData);
                    }
                }).catch(() => {
                    if (media) {
                        this.sendMessage(data, config);
                    } else {
                        this.sendMessage(localData);
                    }
                })
        } else {
            // SINGLE/COMMON LOGIC
            if (localData.attachments == undefined) {
                localData['attachments'] = [];
            }
            this.appendToStateMessages(localData);
            if (media) {
                // console.log('sim: ', config);
                if (localData.attachments.length == 0) {
                    // THIS IS TEMP CONDITION JUST TO VERIFY WE ARE NOT SENDING LOCAL TEMPLATES TO BACKEND
                    this.sendMessage(data, config);
                }
            } else {
                if (localData.attachments.length == 0) {
                    this.sendMessage(localData);
                }
            }
        }
    }



    /**
     * Handle messages with command
     * @param {string} message - Message to be sent
     * @param {string} type - type of command
     */
    handleCommand(message, msg_type = null) {
        let data = {
            message_content: message.message_content,
            message_to: this.props.match.params.id,
            attachment: [],
            attachments: message.attachments,
            type: this.props.match.params.type,
            msg_type: msg_type != null ? msg_type : "NORMAL",
            league_id: this.state.league_id || this.props.leagueId,
            temp_id: UID(),
            created_at: moment().utc().format(),
            socket_id: this.props.match.params.type == 'group' ? window.pusher.connection.socket_id : null
        };

        this.removeMessageFromState(message.msg_id)
        // this.appendToStateMessages(data)

        // Special case when we already have a message that need to be modified from -> to
        // TRADE_PROPOSAL -> TRADE_SENT
        // TRADE_BLOCK_CREATION -> TRADE_BLOCK_SENT
        // SIDEBET_SENT -> SIDEBET_ACCEPTED
        if (data.attachments[0].template == 'TRADE_SENT' || data.attachments[0].template == 'TRADE_BLOCK_SENT' || data.attachments[0].template == 'SIDEBET_SENT' || data.attachments[0].template == 'SIDEBET_ACCEPTED') {
            this.sendMessage(data)
                .then((id) => {
                    data['temp_id'] = id
                    this.appendToStateMessages(data)
                })
        }
        else {
            this.appendToStateMessages(data)
            this.sendMessage(data)
        }
    }

    /**
     * Handle all the incoming message whenever a pusher send message event is fired on current channel
     * @param {object} message - Message object receieved from pusher event when someone sends a message
     * @param {type} type - message/thread/command
     */
    handleIncomingMessage(message, type = null) {
        this.setState({
            messages: [{
                msg_id: message.data.id,
                ts: message.data.created_at,
                message_content: message.data.message_content,
                attachment: message.data.attachment,
                attachments: message.data.attachments,
                attachment_type: message.data.attachment_type,
                time: message.data.created_at,
                reactions: [],
                user: {
                    id: message.data.sender.id,
                    username: message.data.sender.name,
                    image: message.data.sender.image
                },
                has_thread: false
            }, ...this.state.messages],
            offset: this.state.offset + 1, // +1 the offset for every new message
            runningDate: null
        })

        if(this.scrolledToBottom()) {
            setTimeout(() => {
                this.scrollToBottom();
            }, 0);
        }
        else {
            this.setState({
                unreadMessagesCount: this.state.unreadMessagesCount + 1
            })
        }

        // [TODO]: Check if user is not completely scrolled to bottom
        // If not, show a badge to user that there are new messages
        //  
        // Scroll to chat bottom
        
    }

    /**
     * Append the message to the state
     * @param {object} data - Object containing data about message
     */
    appendToStateMessages(data) {
        let current_team = this.state.current_team || this.props.current_team;
    
        setTimeout(() => {
            this.setState({
                messages: [{
                    msg_id: data.temp_id,
                    ts: data.created_at,
                    message_content: data.message_content,
                    attachment: data.attachment,
                    attachments: data.attachments,
                    attachment_type: data.attachment_type,
                    attachment_url: data.attachment_url,
                    time: data.created_at,
                    reactions: [],
                    user: {
                        id: current_team.id,
                        username: (current_team.team_name || current_team.username),
                        image: current_team.image
                    },
                    has_thread: false
                }, ...this.state.messages]
            })
        }, 0);
    }

    /**
     * Remove the message from local state by Message ID
     * @param {string} id - id of the message to be removed
     */
    removeMessageFromState(id) {
        setTimeout(() => {
            let stateMessages = JSON.parse(JSON.stringify(this.state.messages))
            let filtered = stateMessages.filter(message => message.msg_id != id)
            this.setState({ messages: [...filtered] })
        }, 0);
    }

    // Handle created bets status
    handleBetChange(e) {
        this.setState({
            sidebet: { type: e.target.value }
        })
    }

    renderExceptionMessage(Colors = this.context) {
        if (!this.props.match.params.id) {
            return (
                <>
                    <h1 style={{ color: Colors.colorPrimary }}>Hey there!</h1>
                    <p>Click on a <strong>channel</strong> or <strong>user</strong> to start a chat.</p>
                </>
            )

        } else if (this.state.lost) {
            return (
                <>
                    <h1 style={{ color: 'red' }}>Uh Oh!</h1>
                    <p>Looks like you are lost. Click on a <strong>channel</strong> or <strong>user</strong> to start a chat.</p>
                </>
            )

        }
    }

    toggleModal() {
        this.setState({
            modal: !this.state.modal
        });
    }

    // GROUP MESSAGES BY DATE
    groupByDate() {
        var items = this.state.messages;
        let msgs = items.reduce((r, a) => {
            let ts1 = moment.utc(a.ts).local().format("MMMM Do");
            r[ts1] = [...(r[ts1] || []), a];
            return r;
        }, {});
        var msgsWithDates = [];
        Object.keys(msgs).map((dateTime) => {
            msgs[dateTime].map((singleMessage) => {
                msgsWithDates.push(singleMessage);
            });
            msgsWithDates.push({ type: "date", data: dateTime });
        });
        return msgsWithDates;
    }

    getMentionableUsers () {
        let users = {}
        if(this.state.arena_mode) {
            users = {[this.props.match.params.id]: this.state.chat_to}
        }
        else {
            users = this.props.users
        }
        return users
    }

    render() {
        const Colors = this.context;
        return (
            <LoadingOverlay
                active={this.state.loader}
                text='Loading...'
                fadeSpeed={0}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: Colors.bgDark
                    })
                }}
                spinner
            >
                <>
                    {
                        this.props.match.params.id === undefined || this.state.lost ?

                            // Show this when main /chat route is active
                            <Row className='p-0 m-0 d-flex align-items-center' style={{ height: '100vh' }}>
                                <div className='text-center m-auto'>
                                    {
                                        this.renderExceptionMessage()
                                    }
                                </div>
                            </Row>
                            // null
                            :

                            // If the active route points to specific chat
                            <Row className='p-0 m-0' id='chat-section'>
                                {/* Main Inbox */}
                                <div className={'p-0 d-flex flex-column ' + `${this.state.thread ? 'col-md-8' : 'col-md-12'}`} style={{ backgroundColor: Colors.bgDark, height: "100vh", borderRight: `0.01em solid ${Colors.darkBgBorder}` }} id="main">

                                    {/* Channel Header */}
                                    <div className="row m-0 position-sticky d-flex flex-column" id="channel-header" style={{ borderBottom: `0.01em solid ${Colors.darkBgBorder}` }}>
                                        <div className='d-flex px-3 justify-content-between'>
                                            {
                                                // user title
                                                this.props.match.params.type == 'user' ?
                                                    <div className='d-flex align-items-center' style={{ marginTop: '10px', marginBottom: '7px', minHeight: 34 }}>
                                                        {this.state.image ?
                                                            <>
                                                                <img className='roundImgSmall' src={this.state.image} alt='' />
                                                                <h4 className='m-0 pl-2 bold'>{this.state.title}</h4>
                                                            </>
                                                            :
                                                            <h4 className='m-0 pl-2 bold'>Loading...</h4>
                                                        }
                                                    </div>
                                                    :
                                                    // channel title
                                                    <>
                                                        <div className='d-flex align-items-center' style={{ marginTop: '13px', marginBottom: '14px' }}>
                                                            <h4 style={{ cursor: 'pointer' }} onClick={() => this.setState({ modal: true })} className='m-0 bold'>#{this.state.title ? this.state.title : 'Loading...'}</h4>
                                                        </div>
                                                        <div className='d-flex align-items-center px-1 my-2' style={{ border: `0.01em solid ${Colors.darkBgBorder}`, borderRadius: 5 }}>
                                                            {this.props.channels[this.props.match.params.id] && this.props.channels[this.props.match.params.id].members.length <= 3 ?
                                                                this.props.channels[this.props.match.params.id].members.map(member => {
                                                                    return (
                                                                        <img className='img-fluid p-1' style={{ width: 20 }} src={this.props.users[member.user_id] && this.props.users[member.user_id].image} alt='' />
                                                                    )
                                                                })
                                                                :
                                                                // ''
                                                                this.props.channels[this.props.match.params.id] && this.props.channels[this.props.match.params.id].members.slice(0, 3).map(member => {
                                                                    return (
                                                                        <img className='img-fluid' style={{ width: 20 }} src={this.props.users[member.user_id] && this.props.users[member.user_id].image} alt='' />
                                                                    )
                                                                })
                                                            }
                                                            <h6 className='m-0 px-1'>{this.props.channels[this.props.match.params.id] && this.props.channels[this.props.match.params.id].members.length}</h6>
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                        {/* !Title */}

                                        {/* Search */}
                                        {/* <div className='col-md-6 col-sm-12' style={{color:Colors.secondary}}>
                                        <div className="input-group w-75 pull-right" style={{border:`0.01em solid ${Colors.darkBgBorder}`, borderRadius:4}}>
                                            <span className="input-group-prepend">
                                                <span className="btn  border-0" >
                                                    <i className="fa fa-search" />
                                                </span>
                                            </span>
                                            <input className="form-control border-0 " type="search" id="player-search" placeholder='Search a Player'  />
                                        
                                        </div>
                                    </div> */}
                                        {/* !Search */}
                                    </div>
                                    {/* !Channel Header */}

                                    {/* Progress bar */}
                                    <ProgressBar />
                                    { (this.state.unreadMessagesCount > 0) && <NewUnreadMessage unreadCount={this.state.unreadMessagesCount} scrollToBottom={this.scrollToBottom}/> }
                                    {/* !Progress bar */}

                                    {/* TODO: Listener */}
                                    {/* {this.state.unreadMessagesCount > 0 && this.chatBottomListener()} */}
                                    
                                    {/* Inbox Body */}
                                    {/* Messages Container */}
                                    <div className="flex1 pb-3 d-flex flex-column-reverse scrollbar ff-scrollbar" style={{ overflowY: 'auto', overflowX: 'hidden' }} id="messages-container">
                                        <div id='inbox-bottom'></div>
                                        {
                                            this.state.messages.length === 0 ?
                                                <div className="row m-0 p-0" style={{ borderBottom: `0.01em solid ${Colors.darkColor}` }}>
                                                    <div className="col p-0 mx-4 text-white-50">
                                                        <h3 className="text-white"><i className="fa fa-lock mr-2" />{this.state.title}</h3>
                                                        {
                                                            this.props.match.params.type == 'group' ?
                                                                <p>This is the very beginning of <span style={{ color: Colors.colorLink }}>{this.state.title}</span>. Send a message to break the silence.</p>
                                                                :
                                                                <p>This is the very beginning of your direct message history with <span style={{ color: Colors.colorLink }}>@{this.state.title}</span>. Only the two of you are in this conversation, and no one else can join it.</p>
                                                        }

                                                    </div>
                                                </div>
                                                :
                                                <InfiniteScroll
                                                    className='d-flex flex-column-reverse'
                                                    dataLength={this.state.messages.length}
                                                    next={() => this.fetchMessages(true)}
                                                    hasMore={this.state.hasMore}
                                                    loader={<div className='d-flex'><PulseLoader className='m-auto' color={Colors.colorPrimary} /></div>}
                                                    endMessage={<div className='text-center py-3'><i class="fa fa-check-circle" aria-hidden="true"></i> You are all caught up!</div>}
                                                    scrollableTarget={'messages-container'}
                                                    inverse
                                                >
                                                    {this.groupByDate().map((item, index) => {
                                                        return <div key={item.msg_id} style={{ whiteSpace: 'pre-wrap' }} className='' >
                                                            {/* {
                                                            item.attachments.length && item.attachments[0].template == 'GIPHY'?
                                                                <Giphy data={item} removeMessageFromState={this.removeMessageFromState} handleCommand={this.handleCommand}/>
                                                            :
                                                                <NormalText data={item}/>
                                                            } */}
                                                            {item.type == "date" ? (
                                                                // <div className="d-flex justify-content-center text-center">
                                                                //     <div className='my-2 px-4 badge-pill' style={{ backgroundColor: Colors.bgLight, border: `0.01em solid ${Colors.darkColor}`, borderRadius: '20px', }}>
                                                                //         <h6 className="m-0 p-0 text-white" style={{ minWidth: 80 }}>{item.data} date</h6>
                                                                //     </div>
                                                                // </div>
                                                                <div className='date-separator'>
                                                                    <div className='line'></div>
                                                                    <span className="text-white py-1 px-4" style={{ minWidth: 80, border: `1px solid ${Colors.darkBgBorder}`, borderRadius: 50, fontWeight: 600, fontSize: 12 }}>{item.data}</span>
                                                                    <div className='line'></div>
                                                                </div>
                                                            ) : (
                                                                <div className='px-4'>
                                                                    <Message bots={this.props.bots} data={item} users={this.state.users} removeMessageFromState={this.removeMessageFromState} handleCommand={this.handleCommand} sendReaction={this.sendReaction} channels={this.props.channels} />
                                                                </div>
                                                            )}
                                                        </div>
                                                    }
                                                    )}

                                                </InfiniteScroll>
                                        }
                                    </div>
                                    {/* !Messages Container */}
                                    {/* !Inbox Body */}
                                    <RichText params={this.props.location ? queryString.parse(this.props.location.search) : null} current_team={this.props.current_team} leagueId={this.props.leagueId} className="px-4" placeholder={this.state.title ? `Send a message to ${this.state.title}` : 'Loading...'} handleMessage={this.handleMessage} pusherChannel={this.state.typingPuhserChannel} users={this.props.users} loader={this.state.loader} allowControls />
                                </div>
                                {/* !Main Inbox */}
                                {
                                    this.state.thread ?
                                        <Thread toggle={this.toggleThread} /> :
                                        null
                                }

                            </Row>
                    }
                    <ChatSettingsModal {...this.props} modal={this.state.modal} toggleModal={this.toggleModal} />
                </>
            </LoadingOverlay>
        );
    }
}

const mapStateToProps = (state) => ({ leagueId: state.leagueJoin.leagueId, channels: state.leagueJoin.channels, users: state.leagueJoin.users, bots: state.leagueJoin.bots, current_team: state.leagueJoin.current_team, auth: state.auth })
export default connect(mapStateToProps, null)(withRouter(Main));