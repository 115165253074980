import LoadingOverlay from 'react-loading-overlay';
import { withRouter } from 'react-router-dom';
import ReactSelect from 'react-select';
import { Button, Input, Label } from 'reactstrap';
import { createSidebet, fetchSidebetData } from '../../../../apis/Sidebet/SidebetAPI';
import { STYLES } from '../../../../helpers/stylesHelper';
import { toastError } from '../../../../helpers/toasts';
import React, { Component } from 'react';
import { parseImage, prepareLiveDSTData } from '../../../../helpers/common-methods';
import { PrepareSidebetData } from './PrepareSidebetData';
import store from '../../../../store';
import { ColorContext } from '../../../../context/ColorProvider'
class SidebetCreated extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            chatData: null,
            sidebetInProgress: false,

            // API Data
            data: null,
            teams: null,

            // Teams Data
            myTeam: null,
            oppTeam: null,

            // Bet Data
            betTypes: null,
            selectedBetType: null,
            isOpenBet: false,

            //Amount
            betAmounts: null,
            selectedAmount: null,

            // Positions data
            positions: null,
            selectedPosition: null,

            // Timeframe
            timeframes: null,
            selectedTimeframe: null,

            // Players
            myPlayers: null,
            oppPlayers: null,
            mySelectedPlayers: null,
            oppSelectedPlayers: null,

            // Confirmation
            confirmation: false
        }

        this.handleOpenBet = this.handleOpenBet.bind(this)
        this.handleBetAmountSelect = this.handleBetAmountSelect.bind(this)
        this.handleBetTypeSelect = this.handleBetTypeSelect.bind(this)
        this.handleMyPlayersSelect = this.handleMyPlayersSelect.bind(this)
        this.handleOppPlayersSelect = this.handleOppPlayersSelect.bind(this)
        this.handleOppTeamSelect = this.handleOppTeamSelect.bind(this)
        this.handlePositionSelect = this.handlePositionSelect.bind(this)
        this.handleTimeframeSelect = this.handleTimeframeSelect.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.saveSidebet = this.saveSidebet.bind(this)
        this.handleConfirmation = this.handleConfirmation.bind(this)
        this.denySidebet = this.denySidebet.bind(this)
    }
    
    static contextType = ColorContext;

    componentDidMount() {
        this.initComponent()
        this.callAPI()
    }

    componentDidUpdate() {
        if (!this.state.loading && !this.state.betIsValid) {
            this.sidebetValidations()
        }
    }

    initComponent() {
        const activeLeague = store.getState().leagueJoin.activeLeague
        let types = [
            {
                value: 'team',
                label: 'Team vs. Team'
            },
            {
                value: 'player',
                label: 'Player vs. Player'
            },
            {
                value: 'position',
                label: 'Position vs. Position'
            }
        ]

        let timeframes = activeLeague.sport == "MLB" ? ([
            {
                value: 0,
                label: 'Daily'
            },
            {
                value: 1,
                label: 'This Week'
            },
            {
                value: 3,
                label: '3 Weeks'
            },
            {
                value: 18,
                label: 'Rest of Season'
            }
        ]) : 
        (
            [
                {
                    value: 1,
                    label: 'This Week'
                },
                {
                    value: 3,
                    label: '3 Weeks'
                },
                {
                    value: 18,
                    label: 'Rest of Season'
                }
            ]
        )

        let chatData = {
            type: this.props.match.params.type,
            id: this.props.match.params.id
        }

        this.setState({
            betTypes: types,
            chatData: chatData,
            timeframes: timeframes
        })
    }

    callAPI() {

        let params = { leagueId: this.props.leagueId }
        let chatData = {
            type: this.props.match.params.type,
            id: this.props.match.params.id
        }

        fetchSidebetData(params)
            .then(response => {
                let { players, metaData } = response.data
                let pos = [];
                let teams = this.prepareTeams(players)

                players[this.props.user].players.map((pl) => {
                    if (!pos.includes(pl.pos)) {
                        pos.push(pl.pos);
                    }
                });

                if (chatData.type == 'user') {
                    this.setState({
                        data: response.data,
                        myPlayers: players[this.props.user].players,
                        oppTeam: teams.find(team => team.id == chatData.id),
                        oppPlayers: players[chatData.id].players,
                        betAmounts: metaData.amount,
                        positions: pos,
                        loading: false
                    })
                }
                else {
                    this.setState({
                        data: response.data,
                        teams: teams,
                        // timeframes:this.prepareTimeframes(metaData.timeFrame), THIS LINE SETS TIMEFRAMES ACCORDING TO BACKEND
                        myPlayers: players[this.props.user].players,
                        betAmounts: metaData.amount,
                        positions: pos,
                        loading: false
                    })
                }
            })
            .catch(({ messsage }) => {
                toastError(messsage)
                this.setState({
                    loading: false
                })
            })
    }

    handleConfirmation() {
        this.setState({
            confirmation: true
        })
    }

    denySidebet() {
        this.setState({
            confirmation: false
        })
    }

    saveSidebet() {
        let data = this.prepareSidebetData()
        this.setState({
            sidebetInProgress: true
        })
        createSidebet(data)
            .then(response => {
                let { cId } = response.data
                let msgData = {}
                msgData['msg_id'] = this.props.data.msg_id
                msgData['attachments'] = this.prepareAttachments(cId)
                this.props.handleCommand(msgData, 'SIDEBET_CREATE')
            })
            .catch(error => {
                toastError(error.errors.exception)
                this.setState({
                    sidebetInProgress: false
                })
            })
    }

    prepareDataForConfirmaton(Colors = this.context) {
        let data = this.prepareAttachments()

        return (
            <>
                <p>Are you sure you want to create this proposal? By clicking "Yes" below, you will initiate your <span style={{ color: Colors.colorPrimary }}>{'$' + this.state.selectedAmount}</span></p>
                <PrepareSidebetData data={data[0].data} />
                {
                    this.state.sidebetInProgress ?
                    <Button className='w-100 my-1 disabled' style={STYLES.BUTTON_SECONDARY_TRANSPARENT}> <i class="fa fa-refresh fa-spin"></i></Button>
                    :
                    <Button className='w-100 my-1' onClick={this.saveSidebet} style={STYLES.BUTTON_PRIMARY_TRANSPARENT}>Yes</Button>
                }
                <Button className='w-100 my-1' onClick={this.denySidebet} style={STYLES.BUTTON_DANGER_TRANSPARENT}>No</Button>
            </>
        )
    }

    prepareSidebetData() {
        let { value } = this.state.selectedBetType
        let data = {
            league_id: this.props.leagueId,
            sidebet_type: this.state.selectedBetType.value,
            contest_length: this.state.selectedTimeframe.value,
            sidebet_amount: this.state.selectedAmount[0]
        }

        if (this.state.isOpenBet) {
            data['is_public'] = 1
        }
        else {
            data['opp_id'] = this.state.oppTeam.id
        }


        if (value == 'team') {
            return data
        }
        else if (value == 'position') {
            data['pos'] = this.state.selectedPosition[0]
        }
        else if (value == 'player') {
            data['my_player_ids'] = this.preparePlayerIds(this.state.mySelectedPlayers)
            data['opp_player_ids'] = this.state.oppSelectedPlayers ? this.preparePlayerIds(this.state.oppSelectedPlayers) : []
        }
        return data
    }

    preparePlayerIds(players) {
        let IDs = players.map(value => { return value.playerId })
        return IDs
    }

    prepareAttachments(cId = null) {

        let data = {
            attachments: [{
                data: {
                    bet: this.state.selectedBetType.label,
                    myBetType: this.state.selectedBetType.value,
                    myBetPosition: this.state.selectedPosition ? this.state.selectedPosition[0] : null,
                    myBetPlayer: this.state.mySelectedPlayers,
                    theirBetPlayer: this.state.oppSelectedPlayers,
                    myBetTimeframe: this.state.selectedTimeframe.label,
                    myBetPrice: '$' + this.state.selectedAmount[0],
                    receiverId: this.state.isOpenBet ? null : this.state.oppTeam.id,
                    receiverName: this.state.isOpenBet ? null : this.state.oppTeam.teamName,
                    cId: cId,
                    openBet: this.state.isOpenBet,
                    chatToId: this.state.chatData.id,
                    senderName: this.props.data.user.username,
                    senderId: this.props.data.user.id,
                    windowType: this.state.chatData.type,
                    myteamName: { teamName: this.props.data.user.username },
                    myteamMeta: this.prepareMeta(this.state.data.players[this.props.user]),
                    oppteamMeta: this.state.isOpenBet ? { teamName: "TBD", teamImage: parseImage(null), teamRecord: "0 - 0" } : this.prepareMeta(this.state.data.players[this.state.oppTeam.id]),
                    oppteamName: this.state.isOpenBet ? { teamName: "TBD" } : { teamName: this.state.oppTeam.team },
                    channelOpp: this.state.oppTeam ? this.state.oppTeam.oppName : 'TBD'
                },
                template: "SIDEBET_SENT",
            }]
        }
        return data.attachments
    }

    prepareMeta(obj) {
        delete obj["players"]
        return obj
    }

    handleCancel() {
        this.props.removeMessageFromState(this.props.data.msg_id)
    }

    prepareTeams(players) {
        let teams = Object.keys(players).filter(id => id != this.props.auth.user.id).map(id => { return { id: id, team: players[id].teamName } })
        return teams
    }

    prepareTimeframes(timeframes) {
        let parsedTimeframe = Object.keys(timeframes).map(timeframe => { return { value: timeframe, label: timeframes[timeframe] } })
        return (parsedTimeframe)
    }

    handleOpenBet(e) {
        this.setState({
            isOpenBet: e.target.checked,
            oppTeam: null,
            oppSelectedPlayers: null,
            oppPlayers: null
        })
        if (!e.target.checked) {
            this.setState({
                betIsValid: false
            })
        }
    }
    handleOppTeamSelect(team) {
        this.setState({
            oppTeam: team,
            oppPlayers: this.state.data.players[team.id].players,
            oppSelectedPlayers: null,
            betIsValid: false
        })
    }

    handleBetTypeSelect(bet) {
        this.setState({
            selectedBetType: bet,
            mySelectedPlayers: null,
            oppSelectedPlayers: null,
            selectedPosition: null,
            betIsValid: false
        })
    }

    handleTimeframeSelect(timeframe) {
        this.setState({
            selectedTimeframe: timeframe
        })
    }

    handleMyPlayersSelect(myPlayers) {
        if (myPlayers) {
            this.setState({
                mySelectedPlayers: myPlayers
            })
        }
        else {
            this.setState({
                mySelectedPlayers: null,
                betIsValid: false
            })
        }
    }

    handleOppPlayersSelect(oppPlayers) {
        if (oppPlayers) {
            this.setState({
                oppSelectedPlayers: oppPlayers
            })
        }
        else {
            this.setState({
                oppSelectedPlayers: null,
                betIsValid: false
            })
        }
    }

    handleBetAmountSelect(betAmount) {
        this.setState({
            selectedAmount: [betAmount]
        })
    }

    handlePositionSelect(position) {
        this.setState({
            selectedPosition: [position]
        })
    }

    sidebetValidations() {
        // Check if all the basic data for bet creation is ready (!openBet && Group || DM)
        if (this.state.selectedBetType && this.state.oppTeam && this.state.selectedTimeframe && this.state.selectedAmount) {
            if (this.state.selectedBetType.value == 'team') // Check if bet is Team vs. Team and all the realted data is available
            {
                this.setState({
                    betIsValid: true
                })
            }
            else if (this.state.selectedBetType.value == 'player' && this.state.mySelectedPlayers && this.state.oppSelectedPlayers) // Check if bet is Player vs. Player and all the related data is available
            {
                this.setState({
                    betIsValid: true
                })
            }
            else if (this.state.selectedBetType.value == 'position' && this.state.selectedPosition) // Check if bet is Position vs. Position and all the related data is available
            {
                this.setState({
                    betIsValid: true
                })
            }
        }
        // Check if all the basic data for bet creation is ready (isOpenBet && Group)
        else if (this.state.isOpenBet && this.state.selectedBetType && this.state.selectedTimeframe && this.state.selectedAmount) {
            if (this.state.selectedBetType.value == 'team') // Check if bet is Team vs. Team and all the realted data is available
            {
                this.setState({
                    betIsValid: true
                })
            }
            else if (this.state.selectedBetType.value == 'player' && this.state.mySelectedPlayers) // Check if bet is Player vs. Player and all the related data is available
            {
                this.setState({
                    betIsValid: true
                })
            }
            else if (this.state.selectedBetType.value == 'position' && this.state.selectedPosition) // Check if bet is Position vs. Position and all the related data is available
            {
                this.setState({
                    betIsValid: true
                })
            }
        }
    }

    render() {
        const Colors = this.context
        return (
            this.state.confirmation ?
                this.prepareDataForConfirmaton()
                :
                <>
                    <div className='d-flex'>
                        <span className='mr-2' style={{ borderLeft: `5px solid ${Colors.darkBgBorder}`, borderRadius: 50 }}></span>
                        <div>
                            <LoadingOverlay className='p-3' active={this.state.loading} spinner text='Fetching...' fadeSpeed={500} styles={{ overlay: (base) => ({ ...base, background: 'rgba(0,0,0,0.5)' }) }}>
                                <p>You are creating a sidebet. Start building your bet by selecting the bet type below:</p>
                                <div>
                                    {
                                        this.state.chatData && this.state.chatData.type == 'group' &&
                                        <Label className='ml-4'>
                                            <Input type='checkbox' onChange={this.handleOpenBet} checked={this.state.isOpenBet} /> Open Bet
                                        </Label>
                                    }
                                    {
                                        this.state.chatData && this.state.chatData.type == 'group' && !this.state.isOpenBet &&
                                        <ReactSelect menuPortalTarget={document.body} getOptionValue={option => option.id} getOptionLabel={option => option.team} options={this.state.teams} value={this.state.oppTeam} onChange={this.handleOppTeamSelect} className="bg-transparent my-2 text-white" styles={STYLES.REACT_SELECT} closeMenuOnSelect={true} isMulti={false} isClearable={false} placeholder='Select Opponent Team' />
                                    }
                                    <ReactSelect menuPortalTarget={document.body} options={this.state.betTypes} value={this.state.betType} onChange={this.handleBetTypeSelect} className="bg-transparent my-2 text-white" styles={STYLES.REACT_SELECT} closeMenuOnSelect={true} isMulti={false} isClearable={false} placeholder='Select Bet Type' />
                                    {
                                        {
                                            'team': '',
                                            'player':
                                                <>
                                                    <ReactSelect menuPortalTarget={document.body} getOptionValue={option => option.playerId} getOptionLabel={option => option.fullName} options={this.state.myPlayers} value={this.state.mySelectedPlayers} onChange={this.handleMyPlayersSelect} className="bg-transparent my-2 text-white" styles={STYLES.REACT_SELECT} closeMenuOnSelect={false} isMulti={true} isClearable={true} placeholder='Select Your Players' />
                                                    {
                                                        !this.state.isOpenBet &&
                                                        <ReactSelect menuPortalTarget={document.body} getOptionValue={option => option.playerId} getOptionLabel={option => option.fullName} options={this.state.oppPlayers} value={this.state.oppSelectedPlayers} onChange={this.handleOppPlayersSelect} className="bg-transparent my-2 text-white" styles={STYLES.REACT_SELECT} closeMenuOnSelect={false} isMulti={true} isClearable={true} placeholder='Select Their Players' />
                                                    }
                                                </>,
                                            'position':
                                                <ReactSelect menuPortalTarget={document.body} getOptionValue={option => option} getOptionLabel={option => option} options={this.state.positions} value={this.state.selectedPosition} onChange={this.handlePositionSelect} className="bg-transparent my-2 text-white" styles={STYLES.REACT_SELECT} closeMenuOnSelect={true} isMulti={false} isClearable={false} placeholder='Select A Position' />

                                        }[this.state.selectedBetType ? this.state.selectedBetType.value : '']
                                    }


                                    <ReactSelect menuPortalTarget={document.body} options={this.state.timeframes} value={this.state.selectedTimeframe} onChange={this.handleTimeframeSelect} className="bg-transparent my-2 text-white" styles={STYLES.REACT_SELECT} closeMenuOnSelect={true} isMulti={false} isClearable={false} placeholder='Select Timeframe' />
                                    <ReactSelect menuPortalTarget={document.body} getOptionValue={option => '$' + option} getOptionLabel={option => '$' + option} options={this.state.betAmounts} value={this.state.selectedAmount} onChange={this.handleBetAmountSelect} className="bg-transparent my-2 text-white" styles={STYLES.REACT_SELECT} closeMenuOnSelect={true} isMulti={false} isClearable={false} placeholder='Select Bet Amount' />
                                    {
                                        this.state.betIsValid && <Button className='w-100 mt-2' onClick={this.handleConfirmation} style={STYLES.BUTTON_PRIMARY_TRANSPARENT}>Create Sidebet</Button>
                                    }
                                    <Button className='w-100 mt-2' onClick={this.handleCancel} style={STYLES.BUTTON_DANGER_TRANSPARENT}>Delete</Button>
                                </div>
                            </LoadingOverlay>
                        </div>
                    </div>
                </>
        );
    }
}

export default withRouter(SidebetCreated);