const ENDPOINTS = {
  FETCH_STANDINGS: '/sl/standings',
  FETCH_ROTO_STANDINGS: "/sl/mlb/rotoStandings",
  LIVE_ROTO_STANDINGS: "/sl/mlb/liveRotoStandings"
};

export const fetchStandings = (queryString) => {
  return window.axios.get(ENDPOINTS.FETCH_STANDINGS+queryString)
    .then((response) => {
      return Promise.resolve(response.data.data);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export const fetchRotoStandings = (queryString) => {
  return window.axios.get(ENDPOINTS.FETCH_ROTO_STANDINGS+queryString)   
    .then((response) => {
      return Promise.resolve(response.data.data)
    })   
    .catch((error) => {
      return Promise.reject(error)
    })
}

export const liveRotoStandings = (queryString) => {
  return window.axios.get(ENDPOINTS.LIVE_ROTO_STANDINGS+queryString)
    .then((response) => {
      return Promise.resolve(response.data.data)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
