import React, { useContext } from "react"
import { Button, Modal } from "reactstrap";
import AllTeamsList from "../../components/V3/Arena/AllTeamsList";
import ArenaTimeframe from "../../components/V3/Arena/ArenaTimeframe";
import { ColorContext } from "../../context/ColorProvider";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { timeFrameIcon } from "../../helpers/icons";

const ArenaTimeframeModal = (props) => {
    const {modal, toggle, sport, teamId, callBack, active_team, lineupModalState} = props
    const Colors = useContext(ColorContext)
    return (
        <Modal isOpen={modal} size="md" backdrop={true} toggle={toggle}>
            {/* <i onClick={toggle} class="cui-circle-x icons pointer modalClose" style={{ color: Colors.modalCloseIconColor }} /> */}
            <PerfectScrollbar style={{maxHeight: "calc(100vh - 100px)"}} >
                <div className="d-flex justify-content-between align-items-center p-3" style={{backgroundColor: Colors.colorPrimary, borderTopRightRadius:20, borderTopLeftRadius:20}}>
                    {/* <h3><i class="fa fa-calendar-o mx-2" aria-hidden="true"> </i> Select Match Length</h3> */}
                    <p className="m-0 alex" style={{ fontSize: 20, fontWeight: 600, color: Colors.bgDark_v3 }}>Select Time Frame</p>
                    <p className="m-0">{timeFrameIcon()}</p>
                </div>
                {
                    active_team.teamId == 'all' 
                    ?
                    <AllTeamsList />
                    :
                    <ArenaTimeframe sport={active_team.sport} callBack={callBack} teamId={active_team.teamId} fromModal={true} lineupModalState={lineupModalState} />

                }
            </PerfectScrollbar>
        </Modal>
    )
}

const mapStateToProps = (state) => ({active_team: state.arenaReducer.active_team})
export default connect(mapStateToProps, null) (ArenaTimeframeModal)