import React from "react"
import { connect } from "react-redux"
import MLBMatchup from "./MLBMatchup"
import LiveMatchup from "./LiveMatchup"

const MatchupMain = (props) => {
    if(props.activeLeague.sport == "NFL"){
        return <LiveMatchup {...props} />
    }else if (props.activeLeague.sport == "MLB"){
        return <MLBMatchup {...props} />
    }
}

const mapStateToProps = (state) => ({activeLeague: state.leagueJoin.activeLeague})
export default connect(mapStateToProps, null) (MatchupMain)