import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import { Colors } from '../../helpers/colors';
import { saveCurrentLeagueChannels, saveCurrentLeagueUsers, saveBots, saveCurrentTeam, setOnlineStatus, updateUnreadCount, saveApplicationStatus } from '../../apis/Sidebar/LeagueAPI';
import { connect } from 'react-redux';
import WalletHeader from './WalletHeader';
import SidebarFooter from './SidebarFooter';
import { bettleLogo } from '../../helpers/icons';
import { motion } from 'framer-motion/dist/framer-motion';
import { swipeSidebarTabPill, textActiveToSecondary } from '../../pages/NewUserOnboarding/animationValues';
import { updateActiveExperience } from '../../apis/User/UserAPI';
import { getStore } from '../../helpers/common-methods';
import { ColorContext } from '../../context/ColorProvider';
class MainSidebar extends Component {
    // Globals
    leagueChannel = null;
    personalPresence = null;

    constructor(props) {
        super(props);

        // this.switchApp = this.switchApp.bind(this);
    }
    static contextType = ColorContext;

    componentDidUpdate(prevProps, prevState){
        if(prevProps.app_status != this.props.app_status){
            // 
        }
    }

    getMenuItemStatus(path, Colors = this.context){
        if(this.props.match.path.includes(path)){
            return Colors.colorPrimary;
        }
        else{
            return Colors.colorSecondary;
        }
    }

    handleMenuSwitch(selected){
        this.switchApp(selected);
    }

    switchApp(destination, origin = this.props.app_status) {
		if (destination != origin) {
			// this.setState({ loading: true });
            saveApplicationStatus(destination);
			updateActiveExperience({
				activeExp: destination
			})
				.then(() => {
					// this.setState({ loading: false });
					// getStore().dispatch({ type: SAVE_APPLICATION_STATUS, app_status: destination });
                    // saveApplicationStatus(destination);
					if (destination == 'BASEBALL_ARENA') {
						this.props.history.push('/arena/home');
					} else if (destination == 'FOOTBALL_ARENA') {
						this.props.history.push('/arena/home');
					} else {
						this.props.history.push('/dashboard');
					}
				})
				.catch(() => {
					// this.setState({ loading: false });
                    saveApplicationStatus(origin);
				});
		}
	}

    render() {
        const Colors = this.context;
        return (
            <div className="sidebar-wrapper text-white d-flex flex-column flex1 h-100" style={{ width:'100%'}}>
                {/* Wallet Header */}
                {/* <div className='w-100'>
                    <WalletHeader/>
                </div> */}

                {/* Flex 1 Dynamic Nav Section */}
                <div className="d-flex flex1">
                    <this.props.Component/>
                </div>

                {/* <hr className="w-100 mb-0" style={{borderColor:Colors.colorPrimary}}/> */}
                {/* Footer */}
                {/* <div>
                    <SidebarFooter/>
                </div> */}
            </div>
        );
    }
}

const mapDispatchToProps = { saveCurrentLeagueChannels, saveCurrentLeagueUsers, saveCurrentTeam, setOnlineStatus, updateUnreadCount, saveBots, saveApplicationStatus }
const mapStateToProps = (state) => ({leagueId: state.leagueJoin.leagueId, activeLeague: state.leagueJoin.activeLeague, channels: state.leagueJoin.channels, users: state.leagueJoin.users, app_status: state.leagueJoin.app_status});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MainSidebar));

