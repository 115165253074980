import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { GATEWAY_URL } from '../../../config/app';
import ResetPasswordCompV2 from './ResetPasswordCompV2';
import FloatingInput from '../../FloatingInput/FloatingInput';
import { useColors } from '../../../helpers/CustomHooks/useColors';

const ForgetPasswordCompV2 = (props) => {
    const [spinner, setSpinner] = useState(false);
    const [resetMessage, setResetMessage] = useState('');
    const [error, setError] = useState();
    const [email, setEmail] = useState();
    const { control, register, handleSubmit, formState: { errors } } = useForm();
    const history = useHistory();
    const {switchScreen, internal} = props
    const color = useColors()

    const onSubmit = (data) => {
        setSpinner(true);
        window.axios.post(GATEWAY_URL + '/forgotPassword', data)
            .then(({ data: { message } }) => {
                setResetMessage(message);
                console.log(message);
            }).catch(error => {
                setSpinner(false);
                if (error.response) {
                    setError(error.response.data.message);
                } else if (error.request) {
                    setError(error.request._response);
                } else {
                    setError("Something went wrong.");
                }
            });
    };

    const handleLogin = () => {
        if(internal){
            switchScreen("signin")
        }else{
            history.push('/signin')
        }
    }

    return (
        <>
        <div className='login-main-section h-100'>
            <div className='h-100 d-flex flex-column flex1'>
                <div className='flex-grow-1 d-flex align-items-center'>
                    {resetMessage ? (
                        <ResetPasswordCompV2 email={email} />
                    ) : (
                        <>
                            <div className='my-4 px-md-5 px-2' >
                                <p className='welcome-heading' >Whoops!</p>
                                <div className='d-flex'>
                                    <p>Enter the email address used when signing up and we'll send a reset link. If it doesn't appear in two minutes check your spam. If that doesn't work, try again.</p>
                                </div>

                                <div className='d-flex mb-2'>
                                    <span style={{ color: color.colorDanger }}>{error}</span>
                                </div>

                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className=''>
                                        <Controller
                                            control={control}
                                            render={({ field: { onChange, onBlur, value } }) =>
                                            {
                                                return <FloatingInput
                                                    error={errors?.email?.message}
                                                    type="email"
                                                    label="Email"
                                                    onChange={(e) => {
                                                        setEmail(e.target.value)
                                                        onChange(e)
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                />
                                            }}
                                            name="email"
                                            rules={
                                                {required:"Email is required"}
                                            }
                                            defaultValue={''}
                                        />
                                        {/* <input style={{ backgroundColor: Colors.inputBackground, color: Colors.inputText}} className='form-control border-0' {...register("email", { required: true })} type="text" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} /> */}
                                        {/* {errors.email && <ErrorText msg='Email field is required' />} */}
                                    </div>
                                    <div className=''>
                                        <Button disabled={spinner} style={{ width: "100%", height: "48px", fontSize: "16px" }} className="green-btn btn-pill mb-3 mt-3" type="submit">
                                            {spinner ? (
                                                <i class="fa fa-refresh fa-spin"></i>
                                            ) : (
                                                "SEND"
                                            )}
                                        </Button>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <span style={{ cursor: 'pointer' }} onClick={handleLogin} className='mt-2'>Back to login</span>
                                    </div>
                                </form>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>

        {/* <ResetPasswordCompV2 /> */}
        </>
    )
};

export default ForgetPasswordCompV2;