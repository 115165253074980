import { PUSHER_APP_KEY, PUSHER_CLUSTER, CHAT_SERVER, IMAGES_S3, IMAGE_BASE_URL, NFL_S3 } from "../config/app"
import store from "../store"
import { checkTokenExists } from '../helpers/auth';
import Pusher from "pusher-js"
import moment from "moment-timezone";
import localforage from 'localforage';
import profile from "../assets/images/profile.png"
import { toast } from 'react-toastify';
import { prepareStatValue } from "./MLB/Points";
import queryString from 'query-string';
import { injuryStatusDoubtful, injuryStatusOut, injuryStatusQuestionable } from "./icons";
export const STORE = store

var a = ['', 'First ', 'Second ', 'Third ', 'Fourth ', 'Fifth ', 'Sixth ', 'Seventh ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
var b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

export const CURRENT_SPORT = "NFL"
export const TOAST = toast;
export const LIVE_STATS_INTERVAL = 1000 * 60

export const BATTERS = ["C", "1B", "2B", "3B", "SS", "MI", "CI", "IF", "LF", "CF", "RF", "OF", "DH", "UTIL"]
export const PITCHERS = ["P", "SP", "RP"]

export const BATTERS_POS = ["C", "1B", "2B", "3B", "SS", "MI", "CI", "IF", "CF", "LF", "RF", "OF", "DH", "UTIL"]
export const PITCHERS_POS = ["SP", "RP", "P"]

export const NFL_POS = ['QB', 'RB', 'WR', 'TE', 'FLEX', 'SFLEX', 'DEF', 'KICK'];
export const UTIL_ELIGIBLE = ["C", "1B", "2B", "3B", "SS", "MI", "CI", "IF", "CF", "LF", "RF", "OF"]
export const OF_ELIGIBLE = ["LF", "RF", "CF"]
export const IF_ELIGIBLE = ["1B", "2B", "SS", "3B"]
export const CI_ELIGIBLE = ["1B", "3B"]
export const MI_ELIGIBLE = ["2B", "SS"]
export const P_ELIGIBLE = ["SP", "RP"]
export const POINT_BASED_LEAGUES = ["h2h_weekly_points"]
export const MLB_POS = ["C", "1B", "2B", "3B", "SS", "MI", "CI", "IF", "LF", "CF", "RF", "OF", "DH", "UTIL", "P", "RP", "SP"]
export const NBA_POS = ["PG", "SG", "SF", "PF","C", "UTIL", "UTIL"]

export const MATCH_MAKER_POS = [
    "C", "1B", "2B", "3B", "SS", "OF", "OF", "OF", "UTIL", "P", "P", "P", "P", "P"
]
export const MATCH_MAKER_PITCHING_POS = [
    "P", "P", "P", "P", "P"
]
export const MATCH_MAKER_BATTING_POS = [
    "C", "1B", "2B", "3B", "SS", "OF", "OF", "OF", "UTIL"
]
export const NFL_UNIQUE_POS = ["QB", "RB", "WR", "FLX"]
export const NFL_MM_POS = ["QB", "RB", "RB", "WR", "WR", "TE", "FLX"]

// FOR BTOA
const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
export const getStore = () => {
    return store
}

export const getQueryParams = (props) => {
    return queryString.parse(props.location.search)
}

export const getPlace = (i) => {
    i = i + 1;
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}

export async function initChatPusher() {
    let val = await checkTokenExists()
    return new Pusher(PUSHER_APP_KEY, {
        cluster: PUSHER_CLUSTER,
        authEndpoint: CHAT_SERVER + '/api/authorize',
        auth: {
            headers: {
                Authorization: 'Bearer ' + val
            },
        }
    });
}

export const teamLogo = (img) => {
    return (Number.isInteger(img)) ? IMAGE_BASE_URL + "NFL/helmets/h" + img + ".png" : IMAGES_S3 + img
}

export const americaTime = () => {
    return moment().tz('America/New_York')
}

export const friendlyTime = (time) => {
    return moment.utc(time).local().format("hh:mm A")
}

export const friendlyTimeAsIs = (time) => {
    return moment(time).tz("America/New_York").format("ddd h:mm A")
}

export const friendlyDateTime = (time) => {
    return moment.utc(time).local().format("ddd, MMM Do, h:mm A")
}

export const friendlyDateTimeUTC = (time) => {
    return moment(time).tz("America/New_York").format("ddd, MMM Do, h:mm A")
}

// CAN BE USED FOR NBA // RATHER THAN REPEATING
export const mergeAndSum = (map1, map2, sport = "MLB") => {
    var obj1Length = Object.keys(map1).length
    var obj2Length = Object.keys(map2).length
    if(obj1Length > 0 && obj2Length > 0){
      var sumUpVals = {}
      // iterate over map2 entries with acc set to map1 at start
      const merged = Object.entries(map2).reduce( (acc, [key, value]) => 
      // if key is already in map1, add the values, otherwise, create new pair
      {
          if(key == "pitching_ip_2" || key == "fielding_inn_2"){
            //FOR MLB
            return { ...acc, [key]: parseIPStat( (parseFloat(acc[key]) || 0) , parseFloat(value)) }
          }else if(key == "minutes"){
            //FOR NBA
            return { ...acc, [key]: parseIPStat( (parseFloat(acc[key]) || 0) , parseFloat(value)) }
          }else{
            // NORMAL FLOW
            return { ...acc, [key]: (parseFloat(acc[key]) || 0) + parseFloat(value) }
          }
      }
      , { ...map1 } )
      Object.keys(map2).map((key) => {
        if(sport == "MLB"){
          sumUpVals[key] = prepareStatValue(merged, key)
        }else if (sport == "NBA"){
          sumUpVals[key] = applyFormula(merged, key)
        }
      })
      return sumUpVals
    }else{
      if(obj1Length > 0){
        return map1
      }else if ( obj2Length > 0){
        return map2
      }else{
        return {}
      }
    }
  }

  function addZeroes(num, length) {
    if(num){
      const dec = num.toString().split('.')[1]
      const len = dec && dec.length > length ? dec.length : length
      var updatedNum = Number(num).toFixed(len)
      var statString = updatedNum.toString()
      if(statString.startsWith("0.")){
        return updatedNum.toString().replace("0.", ".")
      }else{
        return updatedNum
      }
    }
    return 0
  }

  export const parseTotalStat = (key, stat) => {
    if(key == "pitching_era" || key == "pitching_whip" ){
      if(stat){
        return addZeroes(stat, 2)
      }else{
        return "0.00"
      }
    }else if(key == "hitting_avg" || key == "hitting_obp" || key == "hitting_slg"){
      if(stat){
        return addZeroes(stat, 3)
      }else{
        return "0.000"
      }
    }
    // NORMAL CASE
    if(stat){
      var statString = stat.toString()
      if(statString.startsWith("0.")){
        return stat.toString().replace("0.", ".").replace(".00", "0")
      }else{
        return statString.replace(".00", "")
      }
    }
    return 0
  }

const parseIPStat = (statOne, statTwo) => {
    var result = 0
    var wholeNum = parseInt(statOne) + parseInt(statTwo)
    var fraction = (parseFloat(statOne) + parseFloat(statTwo)) - wholeNum
    var farctionWhole = parseInt(fraction / 0.3)
    result = wholeNum + farctionWhole
    var remainder = (((fraction - farctionWhole) / 0.3).toFixed(1)) / 3
    return (result + remainder).toFixed(1)
}

export var UID = function () {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substr(2, 9);
};

export const prepareLiveDSTData = (e, team) => {
    let currentStats = null
    if (e["stats"]["t1"]["t"] == team) {
        currentStats = {
            stats: { my: e["stats"]["t1"]["stats"], opp: e["stats"]["t2"]["stats"] },
            pa: e["stats"]["t1"]["pa"], pya: e["stats"]["t1"]["pya"], rya: e["stats"]["t1"]["rya"], started: e["stats"]["t1"]["started"]
        }
    } else {
        currentStats = {
            stats: { my: e["stats"]["t2"]["stats"], opp: e["stats"]["t1"]["stats"] },
            pa: e["stats"]["t2"]["pa"], pya: e["stats"]["t2"]["pya"], rya: e["stats"]["t2"]["rya"], started: e["stats"]["t2"]["started"]
        }
    }
    return currentStats
}

export const calculatePercent = (totalBuyIn, bettlePercent, val) => {
    let bettleFee = ((bettlePercent / 100) * totalBuyIn)
    let playersAmount = totalBuyIn - bettleFee
    let percent = ((val / 100) * playersAmount).toFixed(2)
    return isNaN(percent) ? 0 : percent
}

export const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const inWords = (num) => {
    if ((num = num.toString()).length > 9) return 'overflow';
    var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + ' ' : '';
    return str;
}

export const challengeGameType = (type) => {
    if (type == "0" || type == "1") {
        return "Head-to-Head";
    } else if (type == "2") {
        return "Multiplayer";
    } else if (type == "3") {
        return "50/50";
    }
}

export const getDuration = (expireTime) => {
    var now = moment()
    var expire = moment(expireTime)
    const diff = expire.diff(now);
    const diffDuration = moment.duration(diff);
    return diffDuration
}

export const twoDecimal = (number) => {
    if (isNaN(number)) {
        return 0.00;
    }
    return number.toFixed(2)
}

export const formatDayMonth = (date) => {
    return moment(date).format("D MMM")
}

export const contestTotalPoint = (points) => {
    var total = 0
    Object.keys(points).map((gameId) => {
        total = total + points[gameId]
    })
    return total.toFixed(2)
}

export const contestTotalPoints = (players) => {
    let total = 0
    if (players != undefined) {
        Object.values(players).map((player) => {
            total = total + parseFloat(player.points)
        })
    }
    return total.toFixed(2)
}

export const setInForage = async (key, val) => {
    await localforage.setItem(key, val)
}

export const getInForage = async (key) => {
    return await localforage.getItem(key)
}

export const removeInForage = async (key) => {
    return await localforage.removeItem(key)
}

export const parseEntries = (entries) => {
    if (entries != null) {
        if (entries < 100) {
            return "<100"
        } else if (entries >= 100 && entries <= 249) {
            return "100+"
        } else if (entries >= 250 && entries <= 499) {
            return "250+"
        } else if (entries >= 500 && entries <= 999) {
            return "500+"
        } else if (entries >= 1000) {
            return (Math.floor(entries / 1000) * 1000) + "+";
        }
    }
    return 0
}

export const parsePrizes = (prizes) => {
    var nf = new Intl.NumberFormat();

    if (prizes != null) {
        if (prizes < 100) {
            return prizes.toFixed(2)
        } else if (prizes >= 100 && prizes <= 249) {
            return "100+"
        } else if (prizes >= 250 && prizes <= 499) {
            return "250+"
        } else if (prizes >= 500 && prizes <= 999) {
            return "500+"
        } else if (prizes >= 1000) {
            var rounded = Math.floor(prizes / 1000) * 1000;
            return nf.format(rounded) + "+"
        }
    }
    return 0
}

export const parseImage = (image) => {
    return (image == null || image == "profile.png") ? profile : (Number.isInteger(image)) ? IMAGE_BASE_URL + "NFL/helmets/h" + image + ".png" : IMAGES_S3 + image
}

export const parsePlayerImage = (image) => {
    return (image == null || image == "profile.png") ? profile : NFL_S3 + image
}

export const parsePlayerName = (fullName) => {
    var parseName = ""
    parseName = fullName.substring(0, 1)
    var splittedString = fullName.split(" ")
    return parseName + ". " + splittedString[1]
}

export const ILStatuses = () => {
    return ["IR", "IRD", "PUP", "NON"]
}

export const IRStatuses = () => {
    return ["IR", "IRD", "PUP", "NON"]
}

export const successMessage = (msg, options = {}) => {
    TOAST.success(msg, options)
}

export const errorMessage = (msg) => {
    TOAST.error(msg)
}

export const parseMlbHeaders = (pos, headers) => {
    if (BATTERS_POS.includes(pos)) {
        return headers.batters
    } else if (PITCHERS_POS.includes(pos)) {
        return headers.pitchers
    }
}

// DETECT LINK FROM RICH TEXT INPUT
export const linkify = (text) => {
    var links = []
    var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    text.replace(urlRegex, function (url) {
        links.push(url)
    })
    return links
}

export const btoa = (input)  => {
    let str = input;
    let output = '';
  
    for (let block = 0, charCode, i = 0, map = chars;
    str.charAt(i | 0) || (map = '=', i % 1);
    output += map.charAt(63 & block >> 8 - i % 1 * 8)) {
  
      charCode = str.charCodeAt(i += 3/4);
  
      if (charCode > 0xFF) {
        throw new Error("'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.");
      }
  
      block = block << 8 | charCode;
    }
  
    return output;
  }

  export const get_ordinal_suffix_of = (i) => {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return "st";
    }
    if (j == 2 && k != 12) {
        return "nd";
    }
    if (j == 3 && k != 13) {
        return "rd";
    }
    return "th";
}

export const defaultImage = () => {
    return "https://dreamteam-images.s3.amazonaws.com/profile.png"
}

export const fullS3Path = (image) => {
    return "https://dreamteam-images.s3.amazonaws.com/"+image
}

export const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export const experienceFromSport = (sport) => {
    sport = sport.toUpperCase()
    if(sport == "NFL"){
        return "FOOTBALL_ARENA";
    }else if (sport == "MLB"){
        return "BASEBALL_ARENA";
    }else if (sport == "NBA"){
        return "BASKETBALL_ARENA";
    }
    return null
}

export const sportFromExperience = (experience, upperCase = false) => {
    if(experience == "FOOTBALL_ARENA"){
        return upperCase ? "NFL" : "nfl";
    }else if (experience == "BASEBALL_ARENA"){
        return upperCase ? "MLB" : "mlb";
    } else if (experience == "BASKETBALL_ARENA"){
        return upperCase ? "NBA" : "nba";
    }
    return upperCase ? "NFL" : "nfl";
}

export const sportNameFromExperience = async (experience) => {
    // We have format like this: "SPORT_ARENA" we need to split the sport from experience
    experience = experience.split("_")[0]
    return experience.toUpperCase()
}

export const sportLeagueNameFromSportName = (sport) => {
    if(sport == "FOOTBALL"){
        return "NFL"
    }else if (sport == "BASEBALL"){
        return "MLB"
    }else if (sport == "BASKETBALL"){
        return "NBA"
    }
    return "NFL"
}

export const parseInjuryIcons = (status) => {
    if(status){
        if(status == "DOUBTFUL"){
            return injuryStatusDoubtful()
        }else if(status == "IR" || status == "IR-R" || status == "NFI-R" || status == "OUT" || status == "PUP-R" ||
            status == "RESERVE-RET" || status == "RESERVE-EX" || status == "RESERVE-SUS"){
            return injuryStatusOut()
        }else if(status == "QUESTIONABLE"){
            return injuryStatusQuestionable()
        }
    }
}

const handleDivisionByZero = (nom, denom) => {
    if(denom){
        return parseFloat(nom/denom).toFixed(3)
    }
    return 0
}

export const applyFormula = (rawStats, statKey) => {
    if(statKey == "field_goals_pct"){
        return handleDivisionByZero(rawStats["field_goals_made"], rawStats["field_goals_att"])
    }else if (statKey == "three_points_pct"){
        return handleDivisionByZero(rawStats["three_points_made"], rawStats["three_points_att"])
    }else if (statKey == "two_points_pct"){
        return handleDivisionByZero(rawStats["two_points_made"], rawStats["two_points_att"])
    }else if (statKey == "free_throws_pct"){
        return handleDivisionByZero(rawStats["free_throws_made"], rawStats["free_throws_att"])
    }else if (statKey == "assists_turnover_ratio"){
        return handleDivisionByZero(rawStats["assists"], rawStats["turnovers"])
    }else if (statKey == "effective_fg_pct"){
        return handleDivisionByZero(
            ((rawStats["three_points_made"] * 0,5) + rawStats["field_goals_made"]),
            rawStats["field_goals_att"]
        )
    }else if (statKey == "points_in_paint_pct"){
        return handleDivisionByZero(rawStats["points_in_paint_made"], rawStats["points_in_paint_att"])
    }else if (statKey == "true_shooting_pct"){
        return handleDivisionByZero(rawStats["points"],
        (rawStats["field_goals_att"] + (rawStats["free_throws_att"] * 0.44) * 2)
        )
    }else if (statKey == "fast_break_pct"){
        return handleDivisionByZero(rawStats["fast_break_made"], rawStats["fast_break_att"])
    }else if (statKey == "offensive_rating"){
        return handleDivisionByZero(
            (rawStats["points"]*100),
            (rawStats["field_goals_att"]+0.44*rawStats["three_points_att"]+rawStats["turnovers"])
        )
    }else if (statKey == "turnovers_pct"){
        return handleDivisionByZero(
            (rawStats["turnovers"]*100),
            rawStats["field_goals_att"] + (rawStats["free_throws_att"]*0.44) + rawStats["turnovers"] + rawStats["assists"]
        )
    }else if (statKey == "second_chance_pctsum"){
        return handleDivisionByZero(rawStats["second_chance_made"], rawStats["second_chance_att"])
    }else if (statKey == "assists_game"){
        return handleDivisionByZero(rawStats["assists"], rawStats["game_played"])
    }else if (statKey == "blocks_game"){
        return handleDivisionByZero(rawStats["blocks"], rawStats["game_played"])
    }else if (statKey == "minutes_game"){
        return handleDivisionByZero(rawStats["minutes"], rawStats["game_played"])
    }else if (statKey == "points_game"){
        return handleDivisionByZero(rawStats["points"], rawStats["game_played"])
    }else if (statKey == "rebounds_game"){
        return handleDivisionByZero(rawStats["rebounds"], rawStats["game_played"])
    }else if (statKey == "steals_game"){
        return handleDivisionByZero(rawStats["steals"], rawStats["game_played"])
    }else if (statKey == "turnovers_game"){
        return handleDivisionByZero(rawStats["turnovers"], rawStats["game_played"])
    }else if (statKey == "three_points_made_game"){
        return handleDivisionByZero(rawStats["three_points_made"], rawStats["game_played"])
    }else if (statKey == "points_minute"){
        return handleDivisionByZero(rawStats["points"], rawStats["minutes"])
    }else if (statKey == "steals_turnover_ratio"){
        return handleDivisionByZero(rawStats["steals"], rawStats["turnovers"])
    }else if (statKey == "free_throws_att_per_field_goal_att"){
        return handleDivisionByZero(rawStats["free_throws_att"], rawStats["field_goals_att"])
    }else if (statKey == "assists_ratio"){
        return handleDivisionByZero(
            (rawStats["assists"]*100),
            rawStats["field_goals_att"] + (rawStats["free_throws_att"]*0.44) + rawStats["assists"] + rawStats["turnovers"]
        )
    }else{
        return parseFloat(rawStats[statKey]).toFixed(2)
    }
}