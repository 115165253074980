import React, { Component } from 'react';
import { fetchMatchup, myMatchup } from '../../../apis/SeasonLong/Scoreboard/ScoreboardAPI';
// import { Colors } from '../../../helpers/colors';
import { prepareLiveDSTData } from '../../../helpers/DTScoringHelper';
import { toastError } from '../../../helpers/toasts';
import NFLPlayersRow from '../../../components/SeasonLong/Scoreboard/NFL/NFLPlayersRow';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { dstPoints, playerPoints } from '../../../helpers/matchup-helpers';
import PlayerCardModal from '../../../modals/SeasonLong/PlayerCard/PlayerCardModal';
import { ColorContext } from '../../../context/ColorProvider';

class LiveMatchup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      refreshing: false,
      data: null,
      lineup_total: [0, 0],
      selectedWeek: 0,
      modal: false,
      lastPlayInfo: null,
      m_id: 0,
      noMatchup: false,
      lastLeagueId: null,
      rosterTemplate: ["QB", "RB", "WR", "TE", "FLX", "SFLX", "DST", "IDP", "K"],
      playerCardModal: false,
      selectedPlayerId: null,
    }
  }
  static contextType = ColorContext;

  componentDidMount() {
    this.setState({
      selectedWeek: parseInt(this.props.match.params.week),
      m_id: parseInt(this.props.match.params.id)
    })

    setTimeout(() => {
      if (this.state.lastLeagueId == null || this.state.lastLeagueId != this.props.activeLeague.id) {
        this.callAPI()
      }
    }, 0);

  }

  handleRefresh = () => {
    this.setState({ refreshing: true })
    this.callAPI()
  }

  componentWillUnmount() {
    // AppState.removeEventListener("change", this._handleAppStateChange)
    // if (this.unsubscribe) {
    // this.unsubscribe()
    // }
  }

  // _handleAppStateChange = (nextAppState) => {
  //     if (
  //         this.state.appState.match(/inactive|background/) &&
  //         nextAppState === "active"
  //     ) {
  //         this.handleRefresh()
  //     }
  //     this.setState({ appState: nextAppState })
  // }

  callAPI = (week = this.state.selectedWeek) => {
    if (this.props.activeLeague) {
      if (this.props.fromBottomTab) {
        myMatchup("?league_id=" + this.props.leagueId)
          .then((response) => {
            if (response.data && response.data.teams) {
              this.generateChannels(response.data)
            } else {
              this.setState({ noMatchup: true, loading: false })
            }
          })
          .catch(({ message }) => {
            toastError(message)
          })

      } else {
        fetchMatchup({ league_id: this.props.activeLeague.id, week: week, m_id: this.state.m_id })
          .then((response) => {
            if (response.data && response.data.teams) {
              this.generateChannels(response.data)
            } else {
              this.setState({ noMatchup: true, loading: false })
            }
            // this.generateChannels(response)
          })
          .catch(({ message }) => {
            toastError(message)
          })
      }
    } else {
      this.setState({ loading: false, noMatchup: true })
    }
  }

  generateChannels(response) {
    // There are Three channels for updating real time data, Game Channel, Player Channel and Player Highlight Channel
    var lineup_total = [];
    response.teams && response.teams.map((team, index) => {
      if (team.players != undefined) {
        let total = 0
        let prevTotal = 0;
        Object.values(team.players).map((player, plIndex) => {
          if (player.is_live) {
            let gameId = player.live_game_data.gsis_id != undefined ? player.live_game_data.gsis_id : player.live_game_data.game_id
            console.log(gameId)
            window.pusher.subscribe('private-game.' + gameId)
              .bind('GameUpdate', (e) => {
                var teams = this.state.data.teams
                teams[index].players[plIndex]["live_game_data"] = e.game
                this.setState({ data: { ...this.state.data, teams: teams } })
              });
            if (player.pos == "DST") {
              window.pusher.subscribe('private-dst.' + player.team)
                .bind('DSTUpdate', (e) => {
                  var lineup_total = 0
                  var teams = this.state.data.teams
                  let currentStats = null
                  currentStats = prepareLiveDSTData(e, player.team)
                  // teams[index].players[plIndex]["live_player_data"]["last_stats"] = teams[index].players[plIndex]["live_player_data"]
                  // teams[index].players[plIndex]["live_player_data"] = currentStats
                  // teams[index].players[plIndex]["live_player_data"]["stats_string"] = ""
                  teams[index].players[plIndex]["current_week_stats"] = currentStats
                  teams[index].players[plIndex]["player_stat_string"] = e.stats_string

                  // calculate lineup total again
                  teams[index].players.map((pl) => {
                    if (pl.pos != "BE" && pl.pos != "IR") {
                      if (pl.a_pos == "DST") {
                        lineup_total = lineup_total + dstPoints(response.sc_rules, pl.current_week_stats)
                        if (pl.prev_week_stats != null)
                          prevTotal = prevTotal + dstPoints(response.sc_rules, pl.prev_week_stats)
                      } else {
                        lineup_total = lineup_total + playerPoints(pl.current_week_stats, response.sc_rules, pl.pos)
                        if (pl.prev_week_stats != null)
                          prevTotal = prevTotal + playerPoints(pl.prev_week_stats, response.sc_rules, pl.pos)
                      }
                    }
                  })

                  let total = this.state.lineup_total
                  total[index] = { total: lineup_total, prev_total: lineup_total + prevTotal }
                  this.setState({ data: { ...this.state.data, teams: teams }, lineup_total: total })
                });

            } else {

              window.pusher.subscribe('private-player.' + player.id)
                .bind('PlayerUpdate', (e) => {
                  console.log("team index" + index)
                  console.log("player index" + plIndex)
                  var lineup_total = 0
                  var prevTotal = 0
                  var teams = this.state.data.teams
                  // teams[index].players[plIndex]["live_player_data"]["last_stats"] = teams[index].players[plIndex]["live_player_data"]["stats"]
                  // teams[index].players[plIndex]["live_player_data"]["stats"] = e.stats
                  // teams[index].players[plIndex]["live_player_data"]["stats_string"] = e.stats_string
                  teams[index].players[plIndex]["current_week_stats"] = e.stats
                  teams[index].players[plIndex]["player_stat_string"] = e.stats_string
                  // calculate lineup total again
                  teams[index].players.map((pl) => {
                    // calculate lineup total
                    if (pl.pos != "BE" && pl.pos != "IR") {
                      if (pl.a_pos == "DST") {
                        lineup_total = lineup_total + dstPoints(response.sc_rules, pl.current_week_stats)
                        if (pl.prev_week_stats != null)
                          prevTotal = prevTotal + dstPoints(response.sc_rules, pl.prev_week_stats)
                      } else {
                        lineup_total = lineup_total + playerPoints(pl.current_week_stats, response.sc_rules, pl.pos)
                        if (pl.prev_week_stats != null)
                          prevTotal = prevTotal + playerPoints(pl.prev_week_stats, response.sc_rules, pl.pos)
                      }
                    }
                  })
                  let total = this.state.lineup_total
                  total[index] = { total: lineup_total, prev_total: lineup_total + prevTotal }
                  let data = { ...this.state.data, teams: teams }
                  this.setState({ data: data, lineup_total: total })
                });
            }
          }

          // calculate lineup total
          if (player.pos != "BE" && player.pos != "IR") {
            if (player.a_pos == "DST") {
              total = total + dstPoints(response.sc_rules, player.current_week_stats)
              if (player.prev_week_stats != null)
                prevTotal = prevTotal + dstPoints(response.sc_rules, player.prev_week_stats)
            } else {
              total = total + playerPoints(player.current_week_stats, response.sc_rules, player.pos)
              if (player.prev_week_stats != null)
                prevTotal = prevTotal + playerPoints(player.prev_week_stats, response.sc_rules, player.pos)
            }
          }
        })
        // lineup_total[index] = { total: total, prev_total: total + prevTotal }
        lineup_total[index] = { total: total, prev_total: total + prevTotal }
      }
    })
    this.setState({ data: response, loading: false, refreshing: false, lineup_total: lineup_total, lastLeagueId: this.props.activeLeague.id })
  }

  findPlayer = (pos, index) => {
    var pls = []
    if (this.state.data.teams[index] && this.state.data.teams[index].players) {
      this.state.data.teams[index].players.map((player) => {
        if (player.pos == pos) {
          pls.push(player)
        }
      })
    }
    return pls
  }

  // THIS WILL ALSO HANDLES LINEUPS TOTAL FOR DOUBLE WEEKSs
  prepareLineuptTotal = (index) => {
    if (this.state.is_double) {
      if (this.state.lineup_total[index] != undefined) {
        return this.state.lineup_total[index].prev_total && this.state.lineup_total[index].prev_total.toFixed(2)
      } else {
        return 0
      }
    } else {
      if (this.state.lineup_total[index] != undefined) {
        return this.state.lineup_total[index].total && this.state.lineup_total[index].total.toFixed(2)
      } else {
        return 0
      }
    }
  }

  handleDoubleWeek = (week) => {
    this.setState({ selectedWeek: week })
    this.callAPI(week)
  }

  handlePlayerSelect = (playerId) => {
    this.setState({ selectedPlayerId: playerId, playerCardModal: true })
  }

  togglePlayerCardModal = () => {
    this.setState({ playerCardModal: false, selectedPlayerId: null })
  }

  render() {
    const Colors = this.context;
    return (
      <div class="container-fluid d-flex flex1 flex-column scrollHidden w-100 p-0 m-0 ">
        <div className='pl-3' style={{ borderBottom: `0.01em solid ${Colors.darkBgBorder}`, minHeight: '52px' }}>
          <h2 className='pt-2'>Scoreboard</h2>
        </div>
        <div className='py-3 pl-3 text-light justify-content-between' style={{ backgroundColor: Colors.bgSecondHeader }} >
          <p className='m-0 bold'><span onClick={() => this.props.history.goBack()} style={{ cursor: 'pointer' }}><i className='mr-2 fa fa-angle-left'></i>Live Matchup</span></p>
        </div>

        <div className="container mt-2 flex1 d-flex flex-column scrollHidden">
          {
            this.state.noMatchup 
            ?
            <div className='d-flex text-center flex-column m-auto'>
              <h1>⏳</h1>
              <h5>Matchup isn't decided yet.</h5>
            </div>
          :
            <>
              <div className="row justify-content-between p-0 m-0">
                {/* Team A */}
                <div className="col mr-3 p-3 " style={{ backgroundColor: Colors.bgLight, borderRadius: 8 }} >
                  {this.state.data ? (
                    <>
                      <div className="row ">
                        <div className="col text-center">
                          <img src={this.state.data ? this.state.data.teams[0].team_image : ''} style={{ width: 60, borderRadius: 50 }} alt="" />
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col text-center">
                          <label style={{ fontSize: 16, fontWeight: "bold" }}>
                            {this.state.data ? this.state.data.teams[0].team_name : ''}
                          </label>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className='row'>
                      <div className='col text-center'>
                        <h4>Loading...</h4>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col text-center">
                      <label style={{ fontSize: 12, color: Colors.colorSecondary, fontWeight: "bold", }} >
                        Proj. {this.state.data?.teams[0].total_projected_points}
                      </label>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col text-center">
                      <label className="col col-3" style={{ fontSize: 20, color: Colors.colorPrimary, fontWeight: "bolder", alignSelf: "center", }} >
                        {/* {this.state.lineup_total[0].total} */}
                        {this.prepareLineuptTotal(0)}
                      </label>
                    </div>
                  </div>
                </div>
                {/* !Team A */}

                {/* Team B */}
                <div className="col ml-3 p-3 " style={{ backgroundColor: Colors.bgLight, borderRadius: 8 }} >
                  {this.state.data ? (
                    <>
                      <div className="row ">
                        <div className="col text-center">
                          <img src={this.state.data ? this.state.data.teams[1].team_image : ''} style={{ width: 60, borderRadius: 50 }} alt="" />
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col text-center">
                          <label style={{ fontSize: 16, fontWeight: "bold" }}>
                            {this.state.data ? this.state.data.teams[1].team_name : ''}
                          </label>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="row ">
                      <div className="col text-center">
                        <h4>Loading...</h4>
                      </div>
                    </div>
                  )}

                  <div className="row ">
                    <div className="col text-center">
                      <lable style={{ fontSize: 12, color: Colors.colorSecondary, fontWeight: "bold", }} >
                        Proj. {this.state.data?.teams[1].total_projected_points}
                      </lable>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col text-center">
                      <label className="col col-3" style={{ fontSize: 20, color: Colors.colorPrimary, fontWeight: "bolder", alignSelf: "center" }} >
                        {/* {this.state.lineup_total[1].total} */}
                        {this.prepareLineuptTotal(1)}
                      </label>
                    </div>
                  </div>
                </div>
                {/* !Team B */}
              </div>
              <PerfectScrollbar>
                <div className='flex1 mt-4'>
                  {/* NFL Players Row Screen */}
                  <NFLPlayersRow data={this.state.data} selectPlayer={this.handlePlayerSelect} />
                </div>
              </PerfectScrollbar>
            </>
          }
        </div>
        <PlayerCardModal modal={this.state.playerCardModal} toggle={this.togglePlayerCardModal} playerId={this.state.selectedPlayerId} leagueId={this.props.activeLeague.id} sport={this.props.activeLeague.sport} teams={null}  />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({ leagueId: state.leagueJoin.leagueId })
export default LiveMatchup;