import React, { useEffect, useState } from "react"
import { createWebSession } from "../../apis/Gidx/GidxApi"
import { BASE_URL } from "../../config/app"
import { toggleGidxErrorModal, updateUserStatus } from "../../helpers/Payments/GidxHelper"
import Iframe from 'react-iframe'
import { useHistory } from "react-router-dom"
import store from "../../store"

const GidxVerifyIdentity = () => {
    const [loading, setLoading] = useState(true)
    const [sessionId, setSessionId] = useState(null)
    const history = useHistory()
    const exp = store.getState().leagueJoin.app_status

    useEffect(() => {
        window.addEventListener('message', handleEvent)
        createWebSession().then((resp) => {
            setSessionId(resp.id)
            setLoading(false)
        }).catch((err) => {
            toggleGidxErrorModal({
                msg: "Can't create session",
                path: exp == "SEASON_LONG" ? "/dashboard" : "/arena/home"
            })
        })
        return function cleanup() {
            window.removeEventListener('message', handleEvent)
        };
    }, []);

    const handleEvent = ev => {
        if (typeof ev.data === "object") return;
        const {data } = ev
        console.log(data)
        console.log(origin)
        //if (origin === "https://stage-api.bettle.co") {
          if(data  == "NEED_ID"){
            // REDIRECT USERT O GIDX ID UPLOAD SCREEN
            history.push("/payments/idUpload")
          }else if (data == "CANCEL"){
            history.push(exp == "SEASON_LONG" ? "/dashoard" : "/arena/home")
          }else if (data == "VERIFIED"){
            setTimeout(() => {
                updateUserStatus("VERIFIED")
                history.push(exp == "SEASON_LONG" ? "/payments/wallet" : "/arena/home")
            }, 2000)
          }
        //}
    };

    return (
        loading ? <p className="p-4" >Opening Up Verification Session....</p> : (
            <Iframe frameBorder={0} url={BASE_URL+"/payments/openSession?id="+sessionId} width="100%" height="100%" />
        )
    )
}
export default GidxVerifyIdentity