const ENDPOINTS = {
    FETCH_ADD_PLAYER_DATA: '/sl/fetchAddPlayerMob',
    ADD_CLAIM: '/sl/addClaim',
    DROP_PLAYER: '/sl/dropPlayer',
    FETCH_PLAYERS: "/sl/fetchPlayersMob",
    SEARCH_PLAYER: "/dfs/searchPlayer",
    PLAYER_DETAIL: "/dfs/playerDetail",
    MLB_PLAYER_DETAIL: "/sl/mlb/playerDetail",
    GAME_LOG: "/gamelog",
    MLB_GAME_LOG: "/sl/mlb/playerGamelogs",
    FETCH_MLB_PLAYERS: "/sl/mlb/players"
};

export const dropPlayer = (data) => {
    return window.axios.post(ENDPOINTS.DROP_PLAYER, data)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const addClaim = (data) => {
    return window.axios.post(ENDPOINTS.ADD_CLAIM, data)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const fetchAddPlayerData = (data) => {
    return window.axios.post(ENDPOINTS.FETCH_ADD_PLAYER_DATA, data)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const searchPlayer = (data) => {
    return window.axios.post(ENDPOINTS.SEARCH_PLAYER, data)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const fetchMlbPlayers = queryString => {
    return window.axios.get(ENDPOINTS.FETCH_MLB_PLAYERS + queryString)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const fetchPlayers = (queryString) => {
    return window.axios.get(ENDPOINTS.FETCH_PLAYERS + queryString)
        .then(response => {
            return Promise.resolve(response.data);
        }).catch(err => {
            return Promise.reject(err);
        })
}

export const mlbGameLogs = queryString => {
    return window.axios.get(ENDPOINTS.MLB_GAME_LOG + queryString)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const nflGameLogs = (reqData) => {
    return window.axios.post(ENDPOINTS.GAME_LOG,reqData)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const mlbPlayerDetail = data => {
    return window.axios.get(ENDPOINTS.MLB_PLAYER_DETAIL + data)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const playerDetail = (data) => {
    return window.axios.post('/dfs/playerDetail', data).then((response) => {
        return Promise.resolve(response.data);
    }).catch(error => {
        return Promise.reject(error.response.data.message);
    });
}