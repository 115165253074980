import React, { Component, Fragment } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { withRouter } from 'react-router-dom';
import { fetchStandings } from '../../../apis/SeasonLong/Standings/Standings';
// import { Colors } from '../../../helpers/colors';
import PerfectScrollbar from 'react-perfect-scrollbar'
import ReactSelect from 'react-select';
import { STYLES } from '../../../helpers/stylesHelper';
import { toastError, toastSuccess } from '../../../helpers/toasts';
import { LoaderCenter } from '../../../components/Loader';
import { ColorContext } from '../../../context/ColorProvider';

class H2HStandings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            standingsRows: {},
            div: true,
            error: {},
            view: 'divisional',
            loading: false,

            // REACT SELECT STATES
            filters: [
                {
                    value: 'overall',
                    label: 'Overall',
                },
                {
                    value: 'divisional',
                    label: 'Divisional'
                }
            ],

            filter: {
                value: 'divisional',
                label: 'Divisional'
            }
        }

        this.handleFilter = this.handleFilter.bind(this)
        this.navigateToChat = this.navigateToChat.bind(this)
    }
    static contextType = ColorContext; 

    componentDidMount() {
        this.callAPI()
    }

    callAPI() {
        var queryString = "?leagueId=" + this.props.leagueId
        if (this.state.filter.value == 'overall') {
            queryString += '&overall=' + true
        }
        this.setState({
            loading: true
        })
        fetchStandings(queryString).then((resp) => {
            this.setState({
                standingsRows: resp.regStandings.rows,
                div: resp.divLeague,
                loading: false
            })
        }).catch((err) => {
            this.setState({ error: err })
        })
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.leagueId != 0 && prevProps.leagueId !== this.props.leagueId) {
            this.callAPI()
        }

        if (prevState.filter != this.state.filter) {
            this.callAPI()
        }

    }

    handleFilter(filter) {
        this.setState({
            filter,
            loading: true
        })
    }

    handlediv() {
        this.setState({ view: 'divisional' })
        this.setState({ loading: true })
        setTimeout(() => {
            this.setState({ loading: false })
        }, 1000);
    }
    handleoverall() {
        this.setState({ view: 'overall' })
        this.setState({ loading: true })
        setTimeout(() => {
            this.setState({ loading: false })
        }, 1000);
    }

    // navigate to chat if user id is not equal to auth userId
    navigateToChat(userId) {
        if (this.props.userId != userId) {
            this.props.history.push('/chat/user/' + userId)
        }
    }

    render() {
        const Colors = this.context;
        return (
            <>
                <div className='d-flex align-items-center justify-content-between mb-2' style={{ borderBottom: `1px solid ${Colors.darkBgBorder}`, minHeight: '52px' }}>
                    <h2 className="my-2 ml-3">Standings</h2>
                    <ReactSelect className='mr-3' styles={STYLES.REACT_SELECT} options={this.state.filters} value={this.state.filter} onChange={this.handleFilter}/>
                </div>
                <div className="container d-flex flex-column h-100">
                    <PerfectScrollbar className="flex1 mb-4" style={{ border: `0.01em solid ${Colors.darkBgBorder}`, backgroundColor: Colors.bgDark, borderRadius: 15 }} >
                        <div className="w-100" id="standings">
                            {/* <LineupImportIndex /> */}
                            {this.state.loading ?
                                // <LoadingOverlay active={this.state.loading} spinner text='Loading...' fadeSpeed={500} styles={{ overlay: (base) => ({ ...base, background: 'rgb(0,0,0,0)' }) }}></LoadingOverlay> 
                                <LoaderCenter msg='Loading...' />
                                :
                                <div>
                                    <table className='table table-borderless text-nowrap'>
                                        {!this.state.div ? <thead
                                            className="bold"
                                            style={{
                                                position: 'sticky', top: 0, color: Colors.colorSecondary
                                            }}
                                        >
                                            <tr style={{ backgroundColor: Colors.bgLight, borderBottom: `0.01em solid ${Colors.darkBgBorder}` }}>
                                                <th></th>
                                                <th className="text-nowrap">Teams</th>
                                                {/* <th>Owner</th> */}
                                                <th>Record</th>
                                                <th>Win</th>
                                                <th>GB</th>
                                                <th>PF</th>
                                                <th>PA</th>
                                                <th>STRK</th>
                                                {/* <th>Next Matchup</th> */}
                                            </tr>
                                        </thead> : null}

                                        <tbody
                                        >
                                            {!this.state.div

                                                ?
                                                // first condition start runs when div league is false from the backend
                                                <>

                                                    {this.state.standingsRows.teamInfo.map((item, index) => (
                                                        <tr
                                                            style={{ borderBottom: `1px solid ${Colors.darkBgBorder}` }}
                                                        >
                                                            <td>
                                                                <img
                                                                    className=""
                                                                    src={item.teamImage}
                                                                    style={{
                                                                        width: 30,
                                                                        height: 25,
                                                                        // borderRadius: 30,
                                                                        // border: `2px solid ${Colors.colorPrimary}`,
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <div onClick={() => this.navigateToChat(item.userId)} style={{ cursor: 'pointer' }}>
                                                                    {/* <a href="#"> */}
                                                                    <b className="align-middle" style={{ color: "#429ded" }}>
                                                                        {item.teamName}
                                                                    </b>
                                                                    {/* </a> */}
                                                                </div>
                                                            </td>
                                                            <>
                                                                {/* <td>
                                                                    <b>Cody Fisher</b>
                                                                </td> */}
                                                                <td>
                                                                    <b className="align-middle">{this.state.standingsRows.stats[index].record}</b>
                                                                </td>
                                                                <td>
                                                                    <b className="align-middle">{this.state.standingsRows.stats[index].win_percentage}</b>
                                                                </td>
                                                                <td>
                                                                    <b className="align-middle">{this.state.standingsRows.stats[index].games_brack}</b>
                                                                </td>
                                                                <td>
                                                                    <b className="align-middle"> {this.state.standingsRows.stats[index].points_for} </b>
                                                                </td>
                                                                <td>
                                                                    <b className="align-middle"> {this.state.standingsRows.stats[index].points_against} </b>
                                                                </td>
                                                                <td>
                                                                    <b className="align-middle"> {this.state.standingsRows.stats[index].streak} </b>
                                                                </td>
                                                            </>
                                                            {/* <td>
                                                                <b className="text-center">
                                                                    <img
                                                                        className=""
                                                                        // src={require("../../../assets/images/ragbi.png")}
                                                                        style={{
                                                                            width: 30,
                                                                            height: 30,
                                                                            borderRadius: 30,
                                                                            border: `2px solid ${Colors.colorPrimary}`,
                                                                        }}
                                                                    />{" "}
                                                                    <span className="align-middle">
                                                                        The Van Buren Boys
                                                                    </span>
                                                                </b>
                                                            </td> */}
                                                        </tr>
                                                    ))}
                                                </>
                                                // first condition end runs when div league is false from the backend
                                                : (
                                                    // runs when div league is true from the backend
                                                    <>
                                                        {this.state.filter.value == 'overall' ?
                                                            <>
                                                                <table className='table table-borderless'>
                                                                    <tbody>
                                                                        <tr className='spacer' style={{ backgroundColor: Colors.bgLight, borderBottom: `0.01em solid ${Colors.darkBgBorder}`, color: Colors.colorSecondary }}>
                                                                            <th></th>
                                                                            <th className="text-nowrap">Team</th>
                                                                            {/* <th>Owner</th> */}
                                                                            <th>Record</th>
                                                                            <th>Win</th>
                                                                            <th>GB</th>
                                                                            <th>PF</th>
                                                                            <th>PA</th>
                                                                            <th>STRK</th>
                                                                            {/* <th>Next Matchup</th> */}
                                                                        </tr>
                                                                        {/* {Object.keys(this.state.standingsRows).map((key,index) => ( */}

                                                                        <Fragment >
                                                                            {this.state.standingsRows.teamInfo.map((item, index) => (
                                                                                <tr
                                                                                    style={{ borderBottom: `1px solid ${Colors.darkBgBorder}`, backgroundColor: Colors.bgDark }}
                                                                                >
                                                                                    <td>
                                                                                        <img
                                                                                            className=""
                                                                                            src={item.teamImage}
                                                                                            style={{
                                                                                                width: 30,
                                                                                                height: 25,
                                                                                                // borderRadius: 30,
                                                                                                // border: `2px solid ${Colors.colorPrimary}`,
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                    <td style={{ width: '20%' }}>
                                                                                        <div onClick={() => this.navigateToChat(item.userId)} style={{ cursor: 'pointer' }}>
                                                                                            {/* <a href="#"> */}
                                                                                            <span className="bold align-middle text-truncate" style={{ color: "#429ded", maxWidth: '10px' }}>
                                                                                                {item.teamName}
                                                                                            </span>
                                                                                            {/* </a> */}
                                                                                        </div>
                                                                                    </td>
                                                                                    <>
                                                                                        {/* <td>
                                                                                            <b>Cody Fisher</b>
                                                                                        </td> */}
                                                                                        <td>
                                                                                            <b className="align-middle">{this.state.standingsRows.stats[index].record}</b>
                                                                                        </td>
                                                                                        <td>
                                                                                            <b className="align-middle">{this.state.standingsRows.stats[index].win_percentage}</b>
                                                                                        </td>
                                                                                        <td>
                                                                                            <b className="align-middle">{this.state.standingsRows.stats[index].games_brack}</b>
                                                                                        </td>
                                                                                        <td>
                                                                                            <b className="align-middle"> {this.state.standingsRows.stats[index].points_for} </b>
                                                                                        </td>
                                                                                        <td>
                                                                                            <b className="align-middle"> {this.state.standingsRows.stats[index].points_against} </b>
                                                                                        </td>
                                                                                        <td>
                                                                                            <b className="align-middle"> {this.state.standingsRows.stats[index].streak} </b>
                                                                                        </td>
                                                                                    </>
                                                                                    {/* <td>
                                                                                        <b className="text-center">
                                                                                            <img
                                                                                                className=""
                                                                                                // src={require("../../../assets/images/ragbi.png")}
                                                                                                style={{
                                                                                                    width: 30,
                                                                                                    height: 30,
                                                                                                    borderRadius: 30,
                                                                                                    border: `2px solid ${Colors.colorPrimary}`,
                                                                                                }}
                                                                                            />{" "}
                                                                                            <span className="align-middle">
                                                                                                The Van Buren Boys
                                                                                            </span>
                                                                                        </b>
                                                                                    </td> */}
                                                                                </tr>
                                                                            ))}
                                                                        </Fragment>
                                                                    </tbody>
                                                                </table>
                                                            </>
                                                            : null}

                                                        {/* next one  */}
                                                        {this.state.filter.value === 'divisional' ?
                                                            <>
                                                                {Object.keys(this.state.standingsRows).map((key, index) => (
                                                                    <table className='table table-borderless mb-4'>
                                                                        <tbody>

                                                                            <Fragment >

                                                                                <tr className='spacer' style={{ backgroundColor: Colors.bgLight, borderBottom: `0.01em solid ${Colors.darkBgBorder}`, color: Colors.colorSecondary }}>
                                                                                    <th></th>
                                                                                    <th className="text-nowrap">{key}</th>
                                                                                    {/* <th>Owner</th> */}
                                                                                    <th>Record</th>
                                                                                    <th>Win</th>
                                                                                    <th>GB</th>
                                                                                    <th>PF</th>
                                                                                    <th>PA</th>
                                                                                    <th>STRK</th>
                                                                                    {/* <th>Next Matchup</th> */}
                                                                                </tr>

                                                                                {this.state.standingsRows[key].teamInfo.map((item, index) => (
                                                                                    <tr
                                                                                        style={{ borderBottom: `1px solid ${Colors.darkBgBorder}`, backgroundColor: Colors.bgDark }}
                                                                                    >
                                                                                        <td>
                                                                                            <img
                                                                                                className=""
                                                                                                src={item.teamImage}
                                                                                                style={{
                                                                                                    width: 30,
                                                                                                    height: 25,
                                                                                                    // borderRadius: 30,
                                                                                                    // border: `2px solid ${Colors.colorPrimary}`,
                                                                                                }}
                                                                                            />
                                                                                        </td>
                                                                                        <td style={{ width: '20%' }}>
                                                                                            <div onClick={() => this.navigateToChat(item.userId)} style={{ cursor: 'pointer' }}>
                                                                                                {/* <a href="#"> */}
                                                                                                <span className="bold align-middle text-truncate" style={{ color: "#429ded", maxWidth: '10px' }}>
                                                                                                    {item.teamName}
                                                                                                </span>
                                                                                                {/* </a> */}
                                                                                            </div>
                                                                                        </td>
                                                                                        <>
                                                                                            {/* <td>
                                                                                                <b>Cody Fisher</b>
                                                                                            </td> */}
                                                                                            <td>
                                                                                                <b className="align-middle">{this.state.standingsRows[key].stats[index].record}</b>
                                                                                            </td>
                                                                                            <td>
                                                                                                <b className="align-middle">{this.state.standingsRows[key].stats[index].win_percentage}</b>
                                                                                            </td>
                                                                                            <td>
                                                                                                <b className="align-middle">{this.state.standingsRows[key].stats[index].games_brack}</b>
                                                                                            </td>
                                                                                            <td>
                                                                                                <b className="align-middle"> {this.state.standingsRows[key].stats[index].points_for} </b>
                                                                                            </td>
                                                                                            <td>
                                                                                                <b className="align-middle"> {this.state.standingsRows[key].stats[index].points_against} </b>
                                                                                            </td>
                                                                                            <td>
                                                                                                <b className="align-middle"> {this.state.standingsRows[key].stats[index].streak} </b>
                                                                                            </td>
                                                                                        </>
                                                                                        {/* <td>
                                                                                            <b className="text-center">
                                                                                                <img
                                                                                                    className=""
                                                                                                    // src={require("../../../assets/images/ragbi.png")}
                                                                                                    style={{
                                                                                                        width: 30,
                                                                                                        height: 30,
                                                                                                        borderRadius: 30,
                                                                                                        border: `2px solid ${Colors.colorPrimary}`,
                                                                                                    }}
                                                                                                />{" "}
                                                                                                <span className="align-middle">
                                                                                                    The Van Buren Boys
                                                                                                </span>
                                                                                            </b>
                                                                                        </td> */}
                                                                                    </tr>
                                                                                ))}
                                                                            </Fragment>
                                                                        </tbody>
                                                                    </table>
                                                                ))}
                                                            </>
                                                            : null}
                                                    </>
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                    </PerfectScrollbar>
                </div>
            </>
        );
    }
}


export default withRouter(H2HStandings);