import React, { useState } from "react";
import { useColors } from "../../helpers/CustomHooks/useColors";

const CustomToggle = (props) => {
    const { onChange } = props;
    const Colors = useColors();
    const [click, setClick] = useState(true);

    const handleToggle = () => {
        setClick(!click)
        onChange(click);
    };

    return (
        <div className={click ? "d-flex align-items-center pointer mx-2" : "d-flex justify-content-end align-items-center pointer mx-2"} style={{ width: "45px", height: "15px", background: click ? Colors.arenaLineupPlayerRow : Colors.schedulePlayerColor, borderRadius: 100 }} onClick={handleToggle}>
            <div style={{ width: "20px", height: "20px", background: click ? Colors.textLight_v3 : Colors.colorPrimary, borderRadius: 100 }}></div>
        </div>
    );
};

export default CustomToggle;