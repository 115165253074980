import React, { useState } from 'react';
import { Button, Modal } from 'reactstrap';
import ArenaPlayerCard from '../../components/Matchmaker/ArenaPlayerCard';

const ArenaPlayerModal = (props) => {
	const { modal, toggle } = props;

	return (
        <Modal isOpen={modal} size="md">
            <i onClick={() => toggle()} class="cui-circle-x icons pointer modalClose"></i>
            <ArenaPlayerCard {...props} />
        </Modal>
	);
};

export default ArenaPlayerModal;
