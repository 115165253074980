import React from 'react';
import { useColors } from '../helpers/CustomHooks/useColors';

function Marquee(props) {
    const { icon, handleRegister, fromArena } = props;
    const Colors = useColors();

    const handleMarquee = (type) => {
        const mrId = document.getElementById("mrId");
        if (type == "stop") {
            mrId.stop();
        } else {
            mrId.start();
        };
    };

    return (
        <marquee id="mrId" behavior="scroll" direction="left" onMouseEnter={() => handleMarquee("stop")} onMouseLeave={() => handleMarquee("start")}>
            {fromArena ?
                <div className='d-flex justify-content-between align-items-center'>
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(() => {
                        return (
                            <div className='d-flex align-items-center px-5' style={{ color: Colors.secondary_100 }}>
                                <p className='m-0 px-2 pt-2'>{icon}</p>
                                <p className='m-0' style={{ fontSize: 17, fontWeight: 700, color: Colors.colorDark }}>Refer-a-Friend and get $10 for each new sign-up</p>
                            </div>
                        )
                    })}
                </div>
                :
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex align-items-center px-5' style={{ color: Colors.secondary_100 }}>
                        <div className='m-0 mx-2' style={{}}>
                            <img src={icon} alt="" height={"34px"} style={{ filter: "invert(53%) sepia(33%) saturate(530%) hue-rotate(7deg) brightness(92%) contrast(91%)" }} />
                        </div>
                        <p className='m-0'>Get a <strong className='m-0'>$10 FREE PLAY</strong> when you sign up</p>
                    </div>
                    <div className='d-flex align-items-center px-5' style={{ color: Colors.secondary_100 }}>
                        <div className='m-0 mx-2'>
                            <img src={icon} alt="" height={"34px"} style={{ filter: "invert(53%) sepia(33%) saturate(530%) hue-rotate(7deg) brightness(92%) contrast(91%)" }} />
                        </div>
                        <p className='m-0'><strong className='m-0'>DEPOSIT MATCH UP TO $100</strong> on your first deposit <u className='pointer' onClick={handleRegister} >Get Started</u></p>
                    </div>
                    <div className='d-flex align-items-center px-5' style={{ color: Colors.secondary_100 }}>
                        <div className='m-0 mx-2'>
                            <img src={icon} alt="" height={"34px"} style={{ filter: "invert(53%) sepia(33%) saturate(530%) hue-rotate(7deg) brightness(92%) contrast(91%)" }} />
                        </div>
                        <p className='m-0'>Get a <strong className='m-0'>$10 FREE PLAY</strong> when you sign up </p>
                    </div>
                    <div className='d-flex align-items-center px-5' style={{ color: Colors.secondary_100 }}>
                        <div className='m-0 mx-2'>
                            <img src={icon} alt="" height={"34px"} style={{ filter: "invert(53%) sepia(33%) saturate(530%) hue-rotate(7deg) brightness(92%) contrast(91%)" }} />
                        </div>
                        <p className='m-0'><strong className='m-0'>DEPOSIT MATCH UP TO $100</strong> on your first deposit <u className='pointer' onClick={handleRegister} >Get Started</u></p>
                    </div>
                </div>
            }
        </marquee>
    )
}

export default Marquee;