import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { gidxUploadID } from '../../apis/Gidx/GidxApi';
import ErrorText from '../../components/ErrorText';
// import { Colors } from '../../helpers/colors';
import { getStore, STORE } from '../../helpers/common-methods';
import { updateUserStatus } from '../../helpers/Payments/GidxHelper';
import { expCheck } from '../../helpers/RouteHelper';
import { STYLES } from '../../helpers/stylesHelper';
import { useColors } from '../../helpers/CustomHooks/useColors';

const GidxIdUpload = () => {
	const Colors = useColors();
	const [ error, setError ] = useState(null);
	const [ success, setSuccess ] = useState(false);
	const [ imagePreviewUrl, setImagePreviewUrl ] = useState(null);
	const [ file, setFile ] = useState(null);
	const [ uploading, setUploading ] = useState(false);
	const history = useHistory();

	useEffect(() => {
		var userStatus = getStore().getState().auth.user.idVerified;
		if (userStatus == 'ID_UPLOADED') {
			setSuccess(true);
		}
	}, []);

	const _handleImageChange = (e) => {
		e.preventDefault();
		setError(null);
		let reader = new FileReader();
		let file = e.target.files[0];
		reader.onloadend = () => {
			setFile(file);
			setImagePreviewUrl(reader.result);
		};
		reader.readAsDataURL(file);
	};

	const onSubmit = () => {
		if (file == null) {
			setError('Please select file');
		} else {
			setUploading(true);
			var data = new FormData();
			data.append('image', file);
			gidxUploadID(data)
				.then((response) => {
					updateUserStatus('ID_UPLOADED');
					setSuccess(true);
					setUploading(false);
				})
				.catch((err) => {
					setError('Something went wrong');
				});
		}
	}

	return success ? (<div className="d-flex flex-column flex1 justify-content-center align-items-center col-md-6 col-12 mx-auto">
			<i class="fa fa-check-square-o" style={{ fontSize: '10rem', color: Colors.colorPrimary }} aria-hidden="true" />
			<h2 className="text-center my-4">We have received your ID document and it is under process. We will update you shortly.</h2>
			<Button onClick={() => expCheck(history)} className="mt-4" style={STYLES.BUTTON_PRIMARY_TRANSPARENT} >Go to Home</Button>
		</div>) :
		<div className="d-flex flex-column flex1 justify-content-center align-items-center col-md-6 col-12 mx-auto">
			<h4 className="text-center">Please upload an "ID Document", which should be an image of the customer Drivers License, Passport, other official Identification</h4>
			{imagePreviewUrl && <img className="my-3" height={'200px'} src={imagePreviewUrl} />}
			<input className="fomt-control my-3" type={'file'} onChange={_handleImageChange} />
			{error && <ErrorText msg={error} />}
			<Button disabled={uploading} className="mt-4" style={STYLES.BUTTON_PRIMARY_TRANSPARENT} onClick={onSubmit}>
				{
					uploading ? 'Uploading...' :
					'Upload'}
			</Button>
		</div>
};
export default GidxIdUpload;
