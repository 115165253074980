import axios from 'axios';
import _ from 'lodash';
// import loadProgressBar from './helpers/progress-bar';
import { checkTokenExists } from './helpers/auth';
import { PUSHER_APP_KEY, PUSHER_CLUSTER, CHAT_SERVER, BASE_URL, GIPHY_KEY  } from "./config/app"
import Echo from 'laravel-echo'
import Pusher from "pusher-js"
import { GiphyFetch } from '@giphy/js-fetch-api';

window._ = _;
window.axios =  axios.create({
    baseURL: BASE_URL
});

window.gf = new GiphyFetch(GIPHY_KEY)

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

// loadProgressBar(window.axios);

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

checkTokenExists().then(
    val => {
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: PUSHER_APP_KEY,
            cluster: 'us2',
            encrypted: true,
            authEndpoint: BASE_URL+'/broadcasting/auth',
            auth: {
                headers: {
                    Authorization: 'Bearer ' + val
                },
            }
        });

        window.pusher = new Pusher(PUSHER_APP_KEY, {
            cluster: PUSHER_CLUSTER,
            authEndpoint: BASE_URL+'/chatAuthorize',
            auth: {
                headers: {
                    Authorization: 'Bearer ' + val
                },
            }
        });
    // const state = createStore(reducer).getState();
    // subscribe to global status 
    }

).catch((err) => {
    console.log("Token not found")
});

window.axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        if(window.location.pathname != "/signin"){
            alert("Your session has expired. Please log in again!")
            window.location.href = "/signin"
        }
    } else {
        return Promise.reject(error);
    }
});


