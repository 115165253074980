import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { LoaderCenter } from '../Loader';
import { mlbGameLogs } from '../../apis/SeasonLong/PlayerAPI/PlayerAPI';
import { useColors } from '../../helpers/CustomHooks/useColors';
import { parseTotalStat } from '../../helpers/common-methods';

const ArenaGameLogs = (props) => {

	const Colors = useColors()

	const { playerId, statHeaders } = props;
	const [ logData, setLogData ] = React.useState();
	const [ loader, setLoader ] = React.useState(true);
	const [ headers, setHeaders ] = useState(

			statHeaders ? statHeaders :
			[]
	);

	useEffect(() => {
		var qs = '?playerId=' + playerId;
		mlbGameLogs(qs).then((resp) => {
			setLogData(resp.data);
			setLoader(false);
		});
	}, []);

	return (loader ? <LoaderCenter /> :
		<div className="d-flex flex-column py-3">
			<div>
				<div
					className="d-flex align-items-center"
					style={{
						border: `1px solid ${Colors.bgDark}`,
						background: Colors.mlbPlayerCardStatHeader
					}}>
					{headers.length > 0 && (
						<div
							className="d-flex flex-column"
							style={{
								flex: 1.3,
								alignItems: 'center',
								padding: 3
							}}>
							<p className="m-0" style={{ textAlign: 'center', fontWeight: 'bold' }}>
								DATE
							</p>
						</div>
					)}

					{headers.length > 0 && (
						<div
							className="d-flex flex-columna align-items-center"
							style={{
								flex: 1,
								padding: 3
							}}>
							<p className="m-0" style={{ textAlign: 'center', fontWeight: 'bold' }}>
								FPS
							</p>
						</div>
					)}

					{headers.map((item, index) => {
						return (
							<div
								className="d-flex flex-column"
								key={index}
								style={{
									flex: 1,
									alignItems: 'center',
									padding: 3,
									flexShrink: 0
								}}>
								<p className="m-0" style={{ textAlign: 'center', fontWeight: 'bold', flexShrink: 0 }}>
									{logData.statAbbr[item]}
								</p>
							</div>
						);
					})}
				</div>
			</div>

			<div>
				{logData.logs.map((log, key) => (
					<div
						key={key}
						className="d-flex"
						style={{
							flexDirection: 'row',
							border: `1px solid ${Colors.bgDark}`,
							alignItems: 'center'
						}}>
						<div
							style={{
								flex: 1.2,
								alignItems: 'center',
								padding: 3
							}}>
							<p className='m-0'>{moment(log.meta['date']).format('MMM D')}</p>
						</div>
						<div
							style={{
								flex: 1,
								alignItems: 'center',
								padding: 3
							}}>
							<p className='m-0 text-center'>{log.meta['points']}</p>
						</div>
						{headers.map((item, key) => (
							<div
								key={key}
								style={{
									flex: 1,
									alignItems: 'center',
									paddingVertical: 5,
									marginHorizontal: 1
								}}>
								<p className='m-0 text-center' >{parseTotalStat(item, log.stats[item])}</p>
							</div>
						))}
					</div>
				))}
			</div>
		</div>)
};

export default ArenaGameLogs;
