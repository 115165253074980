import React, { useState, useEffect, useStore } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Button, Label, Col, Form, FormGroup, Input, Row } from 'reactstrap';
import { BASE_FE_URL, BASE_URL } from '../../config/app';
import { getStore, validateEmail } from '../../helpers/common-methods';
import { getError, hasError } from '../../helpers/error';
import { sendInvites } from "../../actions/Import/api"
import ios from "../../assets/images/ios.png";
import importSuccess2 from "../../assets/images/importSuccess2.png";
import android from "../../assets/images/android.png";

export const InviteMembers = (props) => {

    const [loading, setLoading] = useState(false)
    const [emails, setEmails] = useState({"email0": null})
    const [copyText, setCopyText] = useState("Copy Link")
    const [current_input, setCurrentInput] = useState(1)
    // const [inputs, setInputs] = useState([])
    const [errors, setErrors] = useState([])
    const [invitesSent, setInvitesSent] = useState(false)

    const getCopyText = () => {
        return encodeURI(BASE_FE_URL+"/sl/lg/invite/"+props.lg.key+"/"+props.platform+"/"+props.lg.name)
    }

    const validate = () => {
        let isValidate = true
        let errors = []
        // validate emails dynamic fields
        Object.values(emails).map((val, i) => {
            if (val == null || val == "") {
                errors["email" + i] = "Email is required"
                isValidate = false
            }
            if ( (val != null && val != "") && !validateEmail(val)) {
                errors["email" + i] = "Email is invalid"
                isValidate = false
            }
        })
        
        setErrors(errors)
        return isValidate
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(validate()){
            setLoading(true)
            var reqLg = props.lg
            reqLg["platform"] = props.platform
            getStore().dispatch(sendInvites({
                lg: reqLg,
                emails: emails
            }))
                .then(response => {
                    props.updateStep("download")
                    setInvitesSent(true)
                })
                .catch(error => alert(error))
        }
    }

    const handleInputChange = (e) => {
        let emailsCopy = emails
        emailsCopy[e.target.name] = e.target.value
        setEmails(emailsCopy)
    }


    const getInputs = () => {
        let inputsCopy = []

        for(var i=0; i<current_input; i++){
            let name = "email" + i
            inputsCopy.push(
                <FormGroup row>
                    {/* <Label sm={2}>Email</Label> */}
                    <Col sm={12}>
                        <Input
                            placeholder={"john@gmail.com"}
                            name={name}
                            onChange={e => handleInputChange(e)}
                            className={`${hasError(errors, name) ? 'is-invalid' : ''}`}
                        />
                        {hasError(errors, name) &&
                            <div className="invalid-feedback">{getError(errors, name)}</div>
                        }
                    </Col>
                </FormGroup>
            )
        }

        return inputsCopy
    }



    const increaseInput = () => {
        setCurrentInput(current_input + 1)
    }

    const isCopyLink = () => {
        if(copyText == "Copied!"){
            return true
        }
        return false
    }

    return (
            <Col className="p-0" >
                {invitesSent ? (
                    <div className="text-center" >
                        <h2 className="mb-2 bold greenText" >{isCopyLink() ? "Invite Link Copied!" : "Invites Sent!"}</h2>
                        <p>{isCopyLink() ? (
                            <span>Share the link to invite league mates to {<b>{props.lg.name}</b>}</span>
                        ) : <span>to {<b>{props.lg.name}</b>} league members</span>}</p>
                        <div><img src={importSuccess2} className="img-fluid mt-3 mb-5" /></div>
                        <div className="mt-4" >
                            <h5>Now it's time to download our app and start playing!</h5>
                            <div className="d-flex align-items-center ml-3 mr-3 justify-content-center" >
                                <div>
                                    <a target="_blank" href="https://apps.apple.com/us/app/bettle-fantasy-sports/id1591274931" >
                                    <img className="img-fluid" src={ios} />
                                    </a>
                                </div>
                                <div>
                                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.bettle.bettlelive" >
                                    <img className="img-fluid" src={android} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="mb-3" >
                            <h3 className="bold greenText mb-3" >Invite {props.lg.name} members</h3>
                            <p className="bold mb-0 lead mt-5" >Invite via link:</p>
                            <p className="mt-2" >Copy the link below and send it to your leaguemates to invite them to your league.</p>
                            <Form style={{ marginBottom: "20px" }} >
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0" style={{flex: 1}} >
                                    <Input type="text" style={{width:"100%"}} readonly value={getCopyText()} />
                                </FormGroup>
                                <div className="text-right" >
                                    <CopyToClipboard text={getCopyText()}
                                        onCopy={() => {props.updateStep("download"); setInvitesSent(true); setCopyText("Copied!")}}>
                                        <Button className="green-btn default-btn btn-pill mt-3" >{ copyText }</Button>
                                    </CopyToClipboard>
                                </div>
                            </Form>

                        </div>
                        <h4 className="or">OR</h4>
                        <div className="mt-3" >
                            <p className="bold mb-0 lead" >Invite via email:</p>
                            <p className="mt-2" >Enter your leaguemates addresses below to invite them to your league.</p>
                            <Form onSubmit={e => handleSubmit(e)}>
                                    {getInputs(current_input).map((input) => {
                                        return input
                                    })}
                                    <div className="text-right" >
                                        <Button onClick={() => increaseInput()} className="green-btn" >
                                            <i className="icon-plus icons font-2xl"></i>
                                        </Button>
                                    </div>
                                    
                                <div className="text-right mt-4" >
                                    <Button className="green-btn btn-pill default-btn" type="submit" >{loading ? "Processing..." : "Next"}</Button>{' '}
                                </div>
                            </Form>
                        </div>
                    </div>
                )}
                
            </Col>
    )
}