import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Badge, Col, UncontrolledTooltip } from 'reactstrap';
import * as Icons from '../../../helpers/icons';
import ChannelsCollpasible from '../../../components/Chat/ChannelsCollapsible';
import DirectMessageCollapsible from '../../../components/Chat/DirectMessageCollapsible';
import { getLeagues, saveCurrentLeagueChannels, saveCurrentLeagueUsers, saveBots, updateActiveLeagueId, saveCurrentTeam, setOnlineStatus, updateUnreadCount } from '../../../apis/Sidebar/LeagueAPI';
import { chatIndex } from '../../../apis/Chat/ChatAPI';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import LeaguesList from './LeaguesList';
import WalletHeader from '../../MainSidebar/WalletHeader';
import { mapping } from '../../../config/ChatConfig';
import { LoaderCenter } from '../../../components/Loader';
import InviteLeagueMateModal from '../../../modals/InviteLeagueMateModal';
import SidebarFooter from '../../MainSidebar/SidebarFooter';
import { ColorContext } from '../../../context/ColorProvider';

class Sidebar extends Component {
	// Globals
	leagueChannel = null;
	personalPresence = null;

	constructor(props) {
		super(props);
		this.state = {
			chatData: {
				data: {
					bots: {},
					c_t: {},
					defaultGroups: '',
					groups: {},
					users: {}
				}
			},
			status: false,
			showLeagueInvites: false,
			inviteLeagueMateModal: false
		};
	}

	static contextType = ColorContext;

	componentDidMount() {
        if(this.props.leagueId){
            this.fetchChatIndex()
        }
		// this.initPusher()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.leagueId != 0 && prevProps.leagueId !== this.props.leagueId) {
			this.fetchChatIndex();
		} else if (prevProps.nav_loading != this.props.nav_loading && this.props.nav_loading == false) {
			this.fetchChatIndex();
		}
	}

	componentWillUnmount() {
		if (this.leagueChannel != null) {
			window.pusher.unsubscribe(this.leagueChannel);
			window.pusher.unsubscribe(this.personalPresence);
		}
	}

	/**
     * Initialize pusher everytime league is switched
     * @param {Object} response - API response for ChatIndex
     */
	initPusher(response) {
		this.setState({ data: response, loading: false })

		// subscribe to general league channel to get online/offline statuses
		var leagueChannel = null;

		if (this.props.leagueId > 0) {
			leagueChannel = window.pusher.subscribe(mapping.lg_channel + this.props.leagueId);

			// Unsubscribe the previous league channel
			if (this.leagueChannel != null) {
				window.pusher.unsubscribe(this.leagueChannel);
			}
			this.leagueChannel = leagueChannel.name;
		}

		leagueChannel.bind('pusher:member_added', (resp) => {
			this.memberAdded(resp);
		});

		leagueChannel.bind('pusher:member_removed', (resp) => {
			this.memberRemoved(resp);
		});

		// Unread messages count logic
		var personalPresence = null;

		// subscribe personal presence channel
		if (this.props.leagueId > 0) {
			personalPresence = window.pusher.subscribe(mapping.personal_presence + this.props.leagueId + '-' + response.c_t.id);
			
			// Unsubscribe the previous personal presence channel
			if (this.personalPresence != null) {
				window.pusher.unsubscribe(this.personalPresence);
			}
			this.personalPresence = personalPresence.name;
		}

		// Bind and listen to new messages events
		personalPresence.bind(mapping.user_chat_event + response.c_t.id, (resp) => {
			// here we will receive broadcasted data on user presence channel
			let users = this.props.users;
			let channels = this.props.channels;

			let data = null;
			let type = null;

			if (resp.data.in_thread) {
				//   let newMessages = this.updateThreadRepliesInMainWindow(resp.data.convo_id, resp.data.created_at, resp.data.sender.image)
				//    this.setState({ messages: newMessages })
			} else {
				if (resp.data.in_group) {
					channels[resp.data.receiver_id].unread_messages += 1;
					data = channels;
					type = 'group';
				} 
				else {
					users[resp.data.sender_id].unread_messages += 1;
					data = users;
					type = 'user';
				}
				
				// Update the redux state
				this.props.updateUnreadCount(data, type);
				// this.updateSidebar();
				// this.setState({ data: data, errors: "" });
			}
		});
	}

	// MEMBERS SUBSCRIBE TO CHANNEL SO THAT WE CAN UPDATE ONLINE/OFFLINE STATUS
	memberAdded(response) {
		let users = this.props.users;
		let dataModified = false;

		if (users[response.id] != undefined) {
			const userIsOnline = users[response.id].lg_online;
			if (!userIsOnline) {
				users[response.id].lg_online = true;
				dataModified = true;
			}
		}

		// If selected user was offline, only then dispatch the event to make it online
		if (dataModified) {
			this.props.setOnlineStatus(users);
			// this.updateSidebar();
		}
	}

	// MEMBERS UNSUBCRIBE TO CHANNEL SO THAT WE CAN UPDATE ONLINE/OFFLINE STATUS
	memberRemoved(response) {
		let users = this.props.users;
		let dataModified = false;

		if (users[response.id] != undefined) {
			const userIsOnline = users[response.id].lg_online;
			if (userIsOnline) {
				users[response.id].lg_online = false;
				dataModified = true;
			}
		}

		// If selected user was online, only then dispatch the event to make it offline
		if (dataModified) {
			this.props.setOnlineStatus(users);
			// this.updateSidebar();
		}
	}

	// updateSidebar() {
	// 	this.setState({
	// 		status: !this.state.status
	// 	});
	// }

	fetchChatIndex() {
		chatIndex(this.props.leagueId)
			.then((response) => {
				this.setState({ chatData: response });
				this.props.saveCurrentLeagueChannels(response.data.groups);
				this.props.saveCurrentLeagueUsers(response.data.users);
				this.props.saveCurrentTeam(response.data.c_t);
				this.props.saveBots(response.data.bots);
				this.initPusher(response.data);
			})
			.catch((error) => this.setState({ errors: error.response }));
	}

	getMenuItemStatus(path) {
		const Colors = this.context;
		if (this.props.match.path.includes(path)) {
			return Colors.colorPrimary;
		} else {
			return '';
		}
	}

	toggleLeagueInvites = () => {
		this.setState({
			showLeagueInvites: !this.state.showLeagueInvites
		})
	}

	toggleInviteLeagueMate() {
		this.setState({
			inviteLeagueMateModal: !this.state.inviteLeagueMateModal
		});
	}

	render() {
		const Colors = this.context;
		var { activeLeague } = this.props;
		const activeLeagueExists = Object.keys(activeLeague).length != 0;
		return (
			<div className="text-white d-flex w-100 flex-column pr-0 h-100" id="chats">
				{/* <WalletHeader /> */}
					{/* {<LeaguesList />} */}
					<div className="d-flex flex-column flex1 h-100" style={{ minWidth: 0 }}>
						{/* Active League */}
						{/* {activeLeagueExists && (
							<div className="text-white pl-3 pr-3 mb-2 mt-2 min-w-0">
								<div className="d-flex min-w-0 align-items-center">
									<img style={{ borderRadius: 10, height: '40px', border: '2px solid' + Colors.colorPrimary }} src={activeLeague.image} />
									<div className="flex1 ml-2 min-w-0">
										<h6 className="mb-1 bold text-truncate" id="league-name">
											{activeLeague.league_name}
										</h6>
										<UncontrolledTooltip placement="top" target="league-name">
											{activeLeague.league_name}
										</UncontrolledTooltip>
										<p className="m-0">{activeLeague.sport}</p>
									</div>
									<div style={{ width: 20, height: 20, aspectRatio: 1, cursor: 'pointer' }} onClick={() => this.props.history.push('/settings')}>
										<Icons.settingsIcon />
									</div>
								</div>
							</div>
						)} */}
						{/* !Active League */}
						{
							this.props.nav_loading ? null :
							<PerfectScrollbar className="flex1">
								{/* Sidebar Nav Container */}
								{/* Main Menu */}
								<div

									style={{ minWidth: 0 }}>
									{/* Single Menu Item */}
									{/* <div className="row py-1 m-0">
                                            <div className="col">
                                                <h6 className="text-truncate" onClick={() => this.props.history.push('/dashboard')} style={{cursor:'pointer', color: `${this.getMenuItemStatus('/dashboard')}` }}>
                                                    <i className="mr-2" >
                                                        <Icons.dashboardIcon />
                                                    </i>
                                                    Dashboard
                                                </h6>
                                            </div>
                                        </div> */}
									{/* !Single Menu Item */}

									{/* Single Menu Item */}
									<div
										className="d-flex mx-2 mb-1 px-2 pb-2"
										onClick={() => this.props.history.push('/dashboard')}
										style={{ borderRadius: 5, color: `${this.getMenuItemStatus('/dashboard')}`, cursor: 'pointer' }}>
										<div className="d-flex align-items-center">
											<div className="mr-2" style={{ width: 15, height: 15, aspectRatio: 1 }}>
												<Icons.dashboardIcon />
											</div>
											<p className="text-truncate m-0" style={{ cursor: 'pointer' }}>
												Dashboard
											</p>
										</div>
									</div>
									{/* !Single Menu Item */}
									{activeLeagueExists && (
										<div>
											{/* Single Menu Item */}
											{/* <div className="row py-1 m-0">
                                                <div className="col">
                                                    <h6 className="text-truncate" onClick={() => this.props.history.push('/my-team')} style={{cursor:'pointer',color: `${this.getMenuItemStatus('/my-team')}` }}>
                                                        <i className="mr-2" >
                                                            <Icons.myTeamIcon />
                                                        </i>
                                                        My Team
                                                    </h6>
                                                </div>
                                            </div> */}
											{/* !Single Menu Item */}

											{/* Single Menu Item */}
											<div
												className="d-flex mx-2 my-1 px-2 py-2"
												onClick={() => this.props.history.push('/my-team')}
												style={{ borderRadius: 5, color: `${this.getMenuItemStatus('/my-team')}`, cursor: 'pointer' }}>
												<div className="d-flex align-items-center">
													<div className="mr-2" style={{ width: 15, height: 15, aspectRatio: 1 }}>
														<Icons.myTeamIcon />
													</div>
													<p className="text-truncate m-0" style={{ cursor: 'pointer' }}>
														My Team
													</p>
												</div>
											</div>
											{/* !Single Menu Item */}

											{/* Single Menu Item */}
											{/* <div className="row py-1 m-0">
                                                <div className="col">
                                                    <h6 className="text-truncate" onClick={() => this.props.history.push('/my-bets')}  style={{cursor:'pointer',color: `${this.getMenuItemStatus('/my-bets')}` }}>
                                                        <i className="mr-2" >
                                                            <Icons.myBetsIcon />
                                                        </i>
                                                        My Bets
                                                    </h6>
                                                </div>
                                            </div> */}
											{/* !Single Menu Item */}

											{/* Single Menu Item */}
											<div
												className="d-flex mx-2 my-1 px-2 py-2"
												onClick={() => this.props.history.push('/my-bets')}
												style={{ borderRadius: 5, color: `${this.getMenuItemStatus('/my-bets')}`, cursor: 'pointer' }}>
												<div className="d-flex align-items-center">
													<div className="mr-2" style={{ width: 15, height: 15, aspectRatio: 1 }}>
														<Icons.myBetsIcon />
													</div>
													<p className="text-truncate m-0" style={{ cursor: 'pointer' }}>
														My Bets
													</p>
												</div>
											</div>
											{/* !Single Menu Item */}

											{/* Single Menu Item */}
											{/* <div className="row py-1 m-0">
                                                <div className="col">
                                                    <h6 className="text-truncate" onClick={() => this.props.history.push('/standings')} style={{cursor:'pointer',color: `${this.getMenuItemStatus('/standings')}` }}>
                                                        <i className="mr-2" >
                                                            <Icons.standingsIcon />
                                                        </i>
                                                        Standings
                                                    </h6>
                                                </div>
                                            </div> */}
											{/* !Single Menu Item */}

											{/* Single Menu Item */}
											<div
												className="d-flex mx-2 my-1 px-2 py-2"
												onClick={() => this.props.history.push('/standings')}
												style={{ borderRadius: 5, color: `${this.getMenuItemStatus('/standings')}`, cursor: 'pointer' }}>
												<div className="d-flex align-items-center">
													<div className="mr-2" style={{ width: 15, height: 15, aspectRatio: 1 }}>
														<Icons.standingsIcon />
													</div>
													<p className="text-truncate m-0" style={{ cursor: 'pointer' }}>
														Standings
													</p>
												</div>
											</div>
											{/* !Single Menu Item */}

											{
												this.props.activeLeague.scoringFormat == 'roto' ? '' :
												// Single Menu Item
												// <div className="row py-1 m-0">
												//     <div className="col">
												//         <h6 className="text-truncate" onClick={() => this.props.history.push('/scoreboard')} style={{cursor:'pointer',color: `${this.getMenuItemStatus('/scoreboard')}` }}>
												//             <i className="mr-2" >
												//                 <Icons.scoreboardIcon />
												//             </i>
												//             Scoreboard
												//         </h6>
												//     </div>
												// </div>
												// !Single Menu Item

												// Single Menu Item
												<div
													className="d-flex mx-2 my-1 px-2 py-2"
													onClick={() => this.props.history.push('/scoreboard')}
													style={{ borderRadius: 5, color: `${this.getMenuItemStatus('/scoreboard')}`, cursor: 'pointer' }}>
													<div className="d-flex align-items-center">
														<div className="mr-2" style={{ width: 15, height: 15, aspectRatio: 1 }}>
															<Icons.scoreboardIcon />
														</div>
														<p className="text-truncate m-0" style={{ cursor: 'pointer' }}>
															Scoreboard
														</p>
													</div>
												</div>
											// !Single Menu Item
											}

											{/* !Single Menu Item */}

											{/* Single Menu Item */}
											{/* <div className="row py-1 m-0">
                                                <div className="col">
                                                    <h6 className="text-truncate" onClick={() => this.props.history.push('/players')} style={{cursor:'pointer',color: `${this.getMenuItemStatus('/players')}` }}>
                                                        <i className="mr-2" >
                                                            <Icons.playersIcon />
                                                        </i>
                                                        Players
                                                    </h6>
                                                </div>
                                            </div> */}
											{/* !Single Menu Item */}

											{/* Single Menu Item */}
											<div
												className="d-flex mx-2 my-1 px-2 py-2"
												onClick={() => this.props.history.push('/players')}
												style={{ borderRadius: 5, color: `${this.getMenuItemStatus('/players')}`, cursor: 'pointer' }}>
												<div className="d-flex align-items-center">
													<div className="mr-2" style={{ width: 15, height: 15, aspectRatio: 1 }}>
														<Icons.playersIcon />
													</div>
													<p className="text-truncate m-0" style={{ cursor: 'pointer' }}>
														Players
													</p>
												</div>
											</div>
											{/* !Single Menu Item */}

											{/* Single Menu Item */}
											<div
												className="d-flex mx-2 my-1 px-2 py-2"
												onClick={() => this.props.history.push('/league/managers')}
												style={{ borderRadius: 5, color: `${this.getMenuItemStatus('/league/managers')}`, cursor: 'pointer' }}>
												<div className="d-flex align-items-center">
													<div className="mr-2" style={{ width: 15, height: 15, aspectRatio: 1 }}>
														<Icons.chatIcon />
													</div>
													<p className="text-truncate m-0" style={{ cursor: 'pointer' }}>
														Managers
													</p>
												</div>
											</div>
											{/* !Single Menu Item */}

											{/* Single Menu Item */}
											{/* <div className="row py-1 m-0">
                                                <div className="col">
                                                    <h6 className="text-truncate" onClick={() => this.props.history.push('/mentions')} style={{cursor:'pointer',color: `${this.getMenuItemStatus('/mentions')}` }}>
                                                        <i className="mr-2" >
                                                            <Icons.mentionsIcon />
                                                        </i>
                                                        Mentions & Reactions
                                                    </h6>
                                                </div>
                                            </div> */}
											{/* !Single Menu Item */}

											{/* Single Menu Item */}
											<div
												className="d-flex mx-2 my-1 px-2 py-2"
												onClick={() => this.props.history.push('/mentions')}
												style={{ borderRadius: 5, color: `${this.getMenuItemStatus('/mentions')}`, cursor: 'pointer' }}>
												<div className="d-flex align-items-center">
													<div className="mr-2" style={{ width: 15, height: 15, aspectRatio: 1 }}>
														<Icons.mentionsIcon />
													</div>
													<p className="text-truncate m-0" style={{ cursor: 'pointer' }}>
														Mentions & Reactions
													</p>
												</div>
											</div>
											{/* !Single Menu Item */}

											{/* Single Menu Item */}
											{/* <div className="row py-1 m-0">
                                                <div className="col">
                                                    <h6 className="text-truncate" onClick={() => this.props.history.push('/threads')} style={{cursor:'pointer',color: `${this.getMenuItemStatus('/threads')}` }}>
                                                        <i className="mr-2" >
                                                            <Icons.threadsIcon />
                                                        </i>
                                                        Threads
                                                    </h6>
                                                </div>
                                            </div> */}
											{/* !Single Menu Item */}

											{/* Single Menu Item */}
											{/* <div className="row py-1 m-0">
                                                <div className="col">
                                                    <h6 className="text-truncate" onClick={() => this.props.history.push('/settings')} style={{cursor:'pointer',color: `${this.getMenuItemStatus('/settings')}` }}>
                                                        <i className="mr-2" >
                                                            <Icons.settingsIcon />
                                                        </i>
                                                        League Settings
                                                    </h6>
                                                </div>
                                            </div> */}
											{/* !Single Menu Item */}

											{/* Single Menu Item */}
											<div
												className="d-flex mx-2 my-1 px-2 py-2"
												onClick={() => this.props.history.push('/settings')}
												style={{ borderRadius: 5, color: `${this.getMenuItemStatus('/settings')}`, cursor: 'pointer' }}>
												<div className="d-flex align-items-center">
													<div className="mr-2" style={{ width: 15, height: 15, aspectRatio: 1 }}>
														<Icons.settingsIcon />
													</div>
													<p className="text-truncate m-0" style={{ cursor: 'pointer' }}>
														League Settings
													</p>
												</div>
											</div>
											{/* !Single Menu Item */}

										</div>
									)}
									
									{/* Single Menu Item */}
									{/* <div
										className="d-flex mx-2 my-1 px-2 py-2"
										onClick={this.toggleLeagueInvites}
										style={{ borderRadius: 5, color: `${this.getMenuItemStatus('/inviteLeagueMate')}`, cursor: 'pointer' }}>
										<div className="d-flex align-items-center flex1">
											<div className="mr-2" style={{ width: 15, height: 15, aspectRatio: 1 }}>
												<Icons.inviteIcon />
											</div>
											<p className="text-truncate m-0 flex1" style={{ cursor: 'pointer' }}>
												Invite League Mate
											</p>
											<Badge className='pull-right' pill style={{backgroundColor: Colors.colorPrimary}}>$10</Badge>
										</div>
									</div> */}
									{/* !Single Menu Item */}

									{/* Single Menu Item */}
									<div
										className="d-flex mx-2 my-1 px-2 py-2"
										onClick={() => this.toggleInviteLeagueMate()}
										style={{ borderRadius: 5, cursor: 'pointer' }}>
										<div className="d-flex align-items-center flex1">
											<div className="mr-2" style={{ width: 15, height: 15, aspectRatio: 1 }}>
												<Icons.sendInvitationIcon />
											</div>
											<p className="text-truncate m-0 flex1" style={{ cursor: 'pointer' }}>
												Invite League Mates
											</p>
											{/* <Badge className='pull-right' pill style={{backgroundColor: Colors.colorPrimary}}>$10</Badge> */}
										</div>
									</div>
									{/* !Single Menu Item */}

									{/* Single Menu Item */}
									<div
										className="d-flex mx-2 my-1 px-2 py-2"
										onClick={() => this.props.history.push('/refer')}
										style={{ borderRadius: 5, color: `${this.getMenuItemStatus('/refer')}`, cursor: 'pointer' }}>
										<div className="d-flex align-items-center flex1">
											<div className="mr-2" style={{ width: 15, height: 15, aspectRatio: 1 }}>
												<Icons.inviteIcon />
											</div>
											<p className="text-truncate m-0 flex1" style={{ cursor: 'pointer' }}>
												Refer a Friend
											</p>
											<Badge className='pull-right' pill style={{backgroundColor: Colors.colorPrimary}}>$10</Badge>
										</div>
									</div>
									{/* !Single Menu Item */}

									{activeLeagueExists && (
										<div>
											<ChannelsCollpasible channels={this.props.channels} status={this.state.status} />
											{/* <DirectMessageCollapsible users={this.props.users} status={this.state.status} /> */}
										</div>
									)}
									{
										this.state.inviteLeagueMateModal && <InviteLeagueMateModal modal={this.state.inviteLeagueMateModal} toggle={() => this.toggleInviteLeagueMate()} />
									}
								</div>
								{/* !All Channels */}
							</PerfectScrollbar>}
					</div>
				<SidebarFooter/>
				{/* <LeagueInfo/> */}
			</div>
		);
	}
}

const mapDispatchToProps = { saveCurrentLeagueChannels, saveCurrentLeagueUsers, saveCurrentTeam, setOnlineStatus, updateUnreadCount, saveBots };
const mapStateToProps = (state) => ({
	leagueId: state.leagueJoin.leagueId,
	activeLeague: state.leagueJoin.activeLeague,
	channels: state.leagueJoin.channels,
	users: state.leagueJoin.users,
	current_team: state.leagueJoin.current_team,
	nav_loading: state.NavReducer.loading,
    app_status: state.leagueJoin.app_status
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));
