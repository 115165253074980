import React, { useEffect, useState } from "react"
import LoadingOverlay from "react-loading-overlay";
import { useSelector } from "react-redux";
import { Modal } from "reactstrap";
import { mmSavePlayersInMatch } from "../../apis/Arena/MatchMakerApis";
import Starters from "../../components/Matchmaker/ArenaOnboarding/Starters";

const CounterPlayerSelectModal = (props) => {
    const {teamData, visible, toggle, onSuccess, counterMatchId, timeFrame} = props
    const [loading, setLoading] = useState(false)
    const activeTeam = useSelector(state => state.arenaReducer.active_team)
    const timeframe = useSelector(state => state.arenaReducer.selectedTimeframe)

    const savePlayersInMatch = (players) => {
        setLoading(true)
        var reqData = {}
        reqData["players"] = players
        reqData["matchId"] = counterMatchId
        reqData["timeFrame"] = timeFrame
        mmSavePlayersInMatch(reqData).then((resp) => {
            setLoading(false)
            toggle()
            onSuccess()
        }).catch((e) => {
            setLoading(false)
            alert(e.message)
        })
    }

    return (
        <Modal isOpen={visible}  size="lg">
            <LoadingOverlay
					active={loading}
					text='Saving...'
					fadeSpeed={0}
					spinner
					className='d-flex flex-column flex1 scrollHidden'
				>
            <Starters closePlayerModal={toggle} afterOnboard={true} afterOnboardData={teamData} successCallback={savePlayersInMatch} />
            </LoadingOverlay>
        </Modal>
    )
}

export default CounterPlayerSelectModal