import React, { useEffect, useState } from "react"
import { Button, Collapse } from "reactstrap";
import { mmAcceptMatch } from "../../../apis/Arena/MatchMakerApis";
import ErrorText from "../../../components/ErrorText";
import ArenaError from "../../../components/Matchmaker/ArenaError";
import { LoaderCenter } from "../../../components/Loader";
import { STYLES } from "../../../helpers/stylesHelper";
// import OnboardedPlayerModal from "./OnboardPlayerModal";
// import ArenaPlayerSelectModal from "./ArenaPlayerSelectModal";
import { errorMessage } from "../../../helpers/common-methods";
import Confetti from "../../../helpers/animations/Confetti";
import { parseProfleStatus } from "../../../helpers/Payments/GidxHelper";
import { Colors } from "../../../helpers/colors";
import { backArrowIcon } from "../../../helpers/icons";
import OnboardedPlayerModal from "../../../modals/Arena/OnboardPlayerModal";
import ArenaPlayerSelectModal from "../../../modals/Arena/ArenaPlayerSelectModal";
import { useSelector } from "react-redux";
import { useColors } from "../../../helpers/CustomHooks/useColors";

const styles = {
    container: {
        backgroundColor: "color-basic-1100",
        borderRadius: 16,
        minHeight: 300,
      }
}
const AcceptAction = (props) => {
    const Colors = useColors();
    const {isOpen, toggle, selectedLineup, successCallback} = props
    const [loading, setLoading] = useState(false)
    const [view, setView] = useState("confirm")
    const [error, setError] = useState(null)
    const [title, setTitle] = useState(null)
    const [data, setData] = useState({})
    const [playerSelectModal, setPlayerSelectModal] = useState(false)
    const [onboardedPlayerModal, setOnboardedPlayerModal] = useState(false)

    const activeTeam = useSelector(state => state.arenaReducer?.activeTeam)
    const timeframe = useSelector(state => state.arenaReducer.selectedTimeframe)

    const callApi = () => {
        setView("loading")
        mmAcceptMatch({
            matchId: selectedLineup.proposedData.matchId,
            teamId: selectedLineup.proposedData.myTeamId,
            timeFrame: selectedLineup.proposedData?.timeFrameKey,
        }).then((resp) => {
            setData(resp)
            if(resp.hadTeam){
                setView("success")
                successCallback()
            }else{
                if(resp.hasPlayers){
                    setOnboardedPlayerModal(true)
                }else{
                    // TRIGGER SELECT PLAYER SCREEN
                    setPlayerSelectModal(true)
                }
            }
        }).catch((e) => {
            parseException(e)
        })
    }

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: Confetti,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const parseException = (e) => {
        if(e?.data?.ERROR_CODE){
            // PARESE ERRORS
            if(e?.data?.ERROR_CODE == "PNV"){
                // PROFILE NOT VERIFIED
                toggle()
                parseProfleStatus(e?.data?.status)
            }else if(e?.data?.ERROR_CODE == "LB"){
                // LOW BALANCE
                setError(e.data.message)
                setView("lowBalance")
            }else if(e?.data?.ERROR_CODE == "LB_WB"){
                // LOW BALANCE FROM WEEKLY BALANCE
                setTitle(e?.data?.heading)
                setError(e.data.message)
                setView("lowWeeklyBankroll")
            }
        }else{
            setError(e.message)
        }
    }

    const handleCancel = () => {
        toggle(true)
    }

    const getView = () => {

        if(view == "loading"){
            return <LoaderCenter msg={"Accepting Matchup..."} />
        }else if(view == "confirm"){
            return (
                <div> 
                    <h2 className="mt-2">Ready to accept the match?</h2>
                    {selectedLineup.proposedData?.isPaid && <p className="lead" >${selectedLineup.proposedData.entryFee} will be deducted from your wallet</p>}
                    <div className="d-flex align-items-center mb-2" >
                        <img className="roundImgMed img-fluid" src={selectedLineup.proposedData.myTeamImage} />
                        <div style={{marginLeft: 10}} >
                            <p className="lead mb-0" >{selectedLineup.proposedData.myTeamName}</p>
                        </div>
                    </div>
                    {/* <div className="d-flex flex-column align-items-center mt-4" >
                        <Button className="mb-3 w-50" onClick={callApi} style={STYLES.BUTTON_PRIMARY_TRANSPARENT} >Accept</Button>
                        <Button className="w-50" disabled={loading} status={"danger"} onClick={handleCancel} style={STYLES.BUTTON_DANGER_TRANSPARENT}>Cancel</Button>
                    </div> */}

                    <div className="d-flex">
                        <Button className='d-flex align-items-center flex1 mr-1 justify-content-center bold' style={{color:Colors.mlbPrimaryColor, backgroundColor: Colors.colorPrimaryDark, border: `1px solid ${Colors.mlbPrimaryColor}`}} onClick={callApi}> 
                            Accept
                        </Button>

                        <Button className='d-flex align-items-center flex1 ml-1 justify-content-center bold' style={{color:Colors.colorDanger, backgroundColor: Colors.colorDangerTransparent, border: `1px solid ${Colors.rejectColor}`}} onClick={toggle}> 
                            Cancel
                        </Button>
                    </div>
                </div>
            )
        }else if(view == "success"){
            return (
                <div style={{paddin: "0 40px", marginTop: 30}} >
                    <h2 style={{marginBottom: 8, textAlign: "center"}}>Successfully accepted the match</h2>
                    <div style={{marginTop: 20}} className="text-center" >
                        <Button className="w-50" onClick={() => toggle(false)} style={STYLES.BUTTON_PRIMARY_TRANSPARENT} >Continue</Button>
                    </div>
                </div>
            )
        }else if (view == "lowBalance"){
            return <ArenaError msg={error}
            buttonp={"Deposit Funds"}
            routeTo={{
                path: "GidxAddFunds",
                params: {payType: "PAY"}
            }}
            errorCallback = {errorCallback}
            cancelCallback = {cancelCallback}
            />
        }else if (view == "lowWeeklyBankroll"){
            return <ArenaError msg={error}
                title={title ? title : "You've reached your limit"}
                buttonp={"Update Settings"}
                routeTo={{
                    path: "ArenaSettings",
                    params: {}
                }}
                errorCallback = {errorCallback}
                cancelCallback = {cancelCallback}
            />
        }
    }

    const handleSuccess = () => {
        setView("success")
    }

    const errorCallback = () => {
        toggle()
    }

    const cancelCallback = () => {
        handleCancel()
    }

    const handleConfirm = () => {
        setOnboardedPlayerModal(!onboardedPlayerModal)
        mmAcceptMatch({
            matchId: selectedLineup.proposedData.matchId,
            teamId: selectedLineup.proposedData.myTeamId
        }).then((resp) => {
            if(resp.hadTeam){
                setView("success")
                successCallback()
            }else{
                errorMessage("Something went wrong")
            }
        }).catch((e) => {
            parseException(e)
        })
    }

    const handleEdit = () => {
        setOnboardedPlayerModal(!onboardedPlayerModal)
        // TRIGGER SELECT PLAYER SCREEN
        setPlayerSelectModal(true)
    }

    return (
        // <Collapse isOpen={isOpen}>
        //     <div className='d-flex align-items-center align-items-center mx-3 py-1'  style={{ backgroundColor: `#00000030`, borderRadius: 5 }}>
        //         <div className='mx-2 pointer align-items-center d-flex' style={{width:20}} onClick={toggle}>{backArrowIcon()}</div>
        //         <p className='m-0'>ACCEPT</p>
        //     </div>
            <div className='px-3 pb-3' style={{ color: Colors.colorLight }}>
                {getView()}
                {onboardedPlayerModal && <OnboardedPlayerModal handleConfirm={handleConfirm} handleEdit={handleEdit} players={data.onboardedPlayers} proposedData={selectedLineup.proposedData} visible={onboardedPlayerModal} />}
            {playerSelectModal && <ArenaPlayerSelectModal onSuccess={handleSuccess} selectedLineup={selectedLineup} toggle={() => {setPlayerSelectModal(!playerSelectModal); setView("confirm")}} visible={playerSelectModal} teamData={data} /> }
            </div>
        // </Collapse>
    )
}

export default AcceptAction


            {/* Confetti After Match Accepted */}
            // { view == 'success' && <Confetti/>}
            // <div style={styles.container}>
                {/* <div style={{marginVertical: 5, flexDirection: "row", "justifyContent": "flex-end", alignItems: "center"}} >
                    <Pressable onPress={toggle} ><CloseIcon /></Pressable>
                </div>*/}
                {/* {error && <ErrorText customStyles={{textAlign: "center"}} msg={error} />}
                {getView()}
            </div>
            {onboardedPlayerModal && <OnboardedPlayerModal handleConfirm={handleConfirm} handleEdit={handleEdit} players={data.onboardedPlayers} proposedData={selectedLineup.proposedData} visible={onboardedPlayerModal} />}
            {playerSelectModal && <ArenaPlayerSelectModal onSuccess={handleSuccess} selectedLineup={selectedLineup} toggle={() => {setPlayerSelectModal(!playerSelectModal); setView("confirm")}} visible={playerSelectModal} teamData={data} /> }
        </div> */}