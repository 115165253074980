export const getImportedLeagues = () => dispatch => {
    return window.axios.get('/sl/importedLeagues').then((response) => {
      return Promise.resolve(response.data.data);
    }).catch(error => {
      return Promise.reject(error);
    });
}

export const sendInviteEmails = (league_id, emails, memberId = undefined) => dispatch => {
    let params = { league_id: league_id, emails: emails }
    if(memberId){
        params = { league_id: league_id, emails: emails, invitee: memberId }
    }
    return window.axios.post('/sl/sendInvites', params).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error);
    });
}

export const loginESPN = (data) => dispatch => {
    return window.axios.post('/loginEspn', data).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error.response.data.errors);
    });
}

export const sleeperLeagues = (qs) => dispatch => {
    return window.axios.get('/sleeperLeagues'+qs).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error.response.data.errors);
    });
}

export const sendEspnCode = (data) => dispatch => {
    return window.axios.post('/sendEspnCode', data).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error.response.data.errors);
    });
}

export const loginIntoEspn = (data) => dispatch => {
    return window.axios.post('/loginIntoEspn', data).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error.response.data.errors);
    });
}

export const triggerSleeperImport = (data) => dispatch => {
    return window.axios.post('/triggerSleeperImport', data).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        // return Promise.reject(error.response.data.errors);
        return Promise.reject(error.response.data)
    });
}


export const sendInvites = (data) => dispatch => {
    return window.axios.post('/sl/importSendInvites', data).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error.response.data.message);
    });
}

export const claimTeam = (data) => dispatch => {
    return window.axios.post('/sl/claimTeam', data).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error.response.data.message);
    });
}

export const fetchTeams = (data) => dispatch => {
    return window.axios.post('/sl/fetchTeams', data).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error.response.data.message);
    });
}

export const getImportInProgress = (data) => dispatch => {
    return window.axios.post('/sl/getImportInProgress', data).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error.response.data.message);
    });
}

export const loginCBS = (data) => dispatch => {
    return window.axios.post('/loginCbs', data).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error.response.data.errors);
    });
}

export const getRoster = (data) => dispatch => {
    return window.axios.post('/contest/espnRoster', data).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error.response.data.errors);
    });
}

export const triggerYahooImport = (data) => dispatch => {
    return window.axios.post('/sl/triggerYahooImport', data).then((response) => {
        return Promise.resolve(response.data.message);
    }).catch(error => {
        return Promise.reject(error.response.data.message);
    });
}

export const triggerESPNImport = (data) => dispatch => {
    return window.axios.post('/sl/triggerESPNImport', data).then((response) => {
        return Promise.resolve(response.data.message);
    }).catch(error => {
        return Promise.reject(error.response.data.message);
    });
}


export const getYahooRoster = (data) => dispatch => {
    return window.axios.post('/contest/yahooRoster', data).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error);
    });
}

export const isYahooCommissioner = (data) => dispatch => {
    return window.axios.post('/api/isYahooCommissioner', data).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error);
    });
}

export const saveRoster = (data) => dispatch => {
    return window.axios.post('/contest/saveRoster', data).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error.response.data.errors);
    });
}

export const getLineup = (chId, id) => dispatch => {
    return window.axios.post('/contest/importedLineup', { chId: chId, id: id }).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error);
    });
}

export const getYahooLeagues = (data) => dispatch => {
    return window.axios.post('/sl/yahooLeagues', data).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error);
    });
}

export const getLineups = (code) => dispatch => {
    return window.axios.get('/contest/importedLineups').then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error);
    });
}

export const sendChallengeData = (code) => dispatch => {
    return window.axios.get('/contest/fetchContestData').then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error);
    });
}

export const sendChallenge = (data) => dispatch => {
    return window.axios.post('/contest/saveContest', data).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error.response.data.errors);
    });
}

export const sendChallengeInvites = (data) => dispatch => {
    return window.axios.post('/contest/sendInvites', data).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error.response.data.errors);
    });
}

export const acceptChallenge = (data) => dispatch => {
    return window.axios.post('/contest/acceptContest', data).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error.response.data.errors);
    });
}

export const autoLogin = (data) => dispatch => {
    return window.axios.post('/contest/autoLogin', data).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error.response.data.errors);
    });
}

export const syncLineup = (data) => dispatch => {
    return window.axios.post('/contest/syncLineup', data).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error.response.data.errors);
    });
}

export const cancelChallenge = (data) => dispatch => {
    return window.axios.post('/contest/cancelContest', data).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error.response.data.errors);
    });
}

export const importedLineup = (data) => dispatch => {
    return window.axios.post('/contest/importedLineupPerContest', data).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error.response.data.errors);
    });
}

export const getSwitchLineup = (data) => dispatch => {
    return window.axios.get('/contest/switchLineups').then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error.response.data.errors);
    });
}

export const userHasLineup = (data) => dispatch => {
    return window.axios.get('/contest/hasLineup').then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error.response.data.errors);
    });
}

export const isLeagueImported = (lgId) => dispatch => {
    return window.axios.get('/sl/leagueImported?lgId='+lgId).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error.response.data.message);
    });
}

export const switchLineup = (data) => dispatch => {
    return window.axios.post('/contest/switchSingleLineup', data).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error.response.data.errors);
    });
}
