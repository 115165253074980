import React, { Component } from 'react';
import { Oval, ThreeDots } from 'react-loader-spinner'
import { bettleWhiteLogo } from '../helpers/icons';
import "./Loader.css";

export const LoaderCenter = (props) => {
    return (
        <div style={{ width: "100%", height: "200px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <Oval color="#03F29E" secondaryColor="#03F29E" height={50} width={50} />
            <div className="mt-2" >{props.msg ? props.msg : 'Loading...'}</div>
        </div>
    );
}

export const SmallLoader = () => {
    return (
        <Oval color="#03F29E" secondaryColor="#03F29E" height={50} width={50} />
    );
}

export const ArenaLoaderCenter = (props) => {
    return (
        <div className='d-flex flex-column flex1 justify-content-center align-items-center' style={{ height: '100vh' }}>
            <div className='fadeInOut' style={{ width: 100, height: 100 }}>
                {bettleWhiteLogo()}
            </div>
            <div className="mt-2 bold" >{props.msg ? props.msg : 'Loading...'}</div>
        </div>
    );
}

export const PulseLoader = (props) => {
    return (
        <div className='d-flex w-100 justify-content-center align-items-center'>
            <ThreeDots color="#03F29E" secondaryColor="#03F29E" height={50} width={50} />
        </div>
    );
}
