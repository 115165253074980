import React, {Component} from 'react';
import {StickyTable, Row, Cell} from 'react-sticky-table';
// import { Colors } from '../../../helpers/colors';
import { POINT_BASED_LEAGUES } from '../../../helpers/common-methods';
import { useColors } from '../../../helpers/CustomHooks/useColors';

const MyTeamHeader = (props) => {
    const Colors = useColors();
    const prepareDynamicStatCols = () => {
        if(props.type == "pitchers"){
        // PITCHERS
        return props.selectedCats && props.selectedCats.pitching.map((stat, key) => {
            return <Cell style={{backgroundColor: Colors.bgDark}} className="bold">{props.statAbbr[stat]}</Cell>
        })
        }else{
        // BATTERS
        return props.selectedCats && props.selectedCats.hitting.map((stat, key) => {
            return <Cell style={{backgroundColor: Colors.bgDark}} className="bold">{props.statAbbr[stat]}</Cell>
        })
        }
    }
    
    return (
        <Row>
            <Cell style={{backgroundColor: Colors.bgDark}} className="bold" >{props.listType}</Cell>
            <Cell style={{backgroundColor: Colors.bgDark}} className="bold" >OPP</Cell>
            {POINT_BASED_LEAGUES.includes(props.scoringFormat) && <Cell style={{backgroundColor: Colors.bgDark}} className="bold">
             SCORE
            </Cell>}
            {prepareDynamicStatCols()}
        </Row>
    );
}

export default MyTeamHeader