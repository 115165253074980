import moment from "moment-timezone"
import React, {useState, useEffect} from "react"
import { Button, Table } from "reactstrap"
import styled, {css} from 'styled-components'
import { nflColors as Colors } from "../../../../helpers/colors"
import '../../../../modals/SeasonLong/PlayerCard/colors.css'
import { externalLinkIcon } from "../../../../helpers/icons"
import GameLogsNFL from "../GameLogs/GameLogsNFL"
import PerfectScrollbar from "react-perfect-scrollbar"

const PlayerCardTabsNBA = (props) => {
    const {news, statAbbr, statHeaders, statsTab} = props.data

    // const [logData, setLogData] = React.useState();
    // const [loader, setLoader] = React.useState(true);
    // const [headers, setHeaders] = useState([])

    const Tab = styled.div`
        min-height: 250px;
        height: 100%;
        padding: 1rem;
    `

    // const fetchGameLogs = () => {
    //     mlbGameLogs("?playerId="+player.player_id+"&leagueId="+lg.id)
    //         .then(response => {
    //             setLogData(response)
    //             setHeaders(parseMlbHeaders(player.pos, response.headers))
    //             setLoader(false);
    //         })
    //         .catch(({message}) => {
    //             toastError(message)
    //         })  
    // }
    return (
        <PerfectScrollbar className="flex1 mb-3">
        <div style={{height:0}}>
        {
            {
                'overview':
                    <Tab> 
                            <>
                                {/* Bio */}
                                <div className="w-100 my-3 shadow" style={{backgroundColor: Colors.bgLight, borderRadius: 10, border:`0.01em solid ${Colors.colorSecondaryTransparent}`}}>
                                    <h4 className="p-2 w-100" style={{borderBottom:`0.01em solid ${Colors.colorSecondaryTransparent}`}}>Bio</h4>
                                    <div className="mx-2">
                                        <div className="mb-2">
                                            <span style={{color: Colors.colorSecondary}}>Appearances</span>
                                            <span className="pull-right bold">24 apps</span>
                                        </div>

                                        <div className="my-2">
                                            <span style={{color: Colors.colorSecondary}}>Age</span>
                                            <span className="pull-right bold">{props.bio.age} years</span>
                                        </div>

                                        <div className="my-2">
                                            <span style={{color: Colors.colorSecondary}}>Height</span>
                                            <span className="pull-right bold">{props.bio.height}</span>
                                        </div>

                                        <div className="my-2">
                                            <span style={{color: Colors.colorSecondary}}>Weight</span>
                                            <span className="pull-right bold">{props.bio.weight}lbs</span>
                                        </div>

                                        <div className="my-2">
                                            <span style={{color: Colors.colorSecondary}}>Arms</span>
                                            <span className="pull-right bold">32 3/4</span>
                                        </div>

                                        <div className="my-2">
                                            <span style={{color: Colors.colorSecondary}}>Hands</span>
                                            <span className="pull-right bold">9 1/4</span>
                                        </div>
                                    </div>
                                </div>
                                {/* Stats */}
                                <div className="w-100 my-3 shadow" style={{backgroundColor: Colors.bgLight, borderRadius: 10, border:`0.01em solid ${Colors.colorSecondaryTransparent}`}}>
                                    <h4 className="p-2">Season Stats</h4>

                                    <Table size='sm' striped>
                                            <thead>
                                                <tr className="border-0">
                                                    <th></th>
                                                {
                                                    statsTab.header.map(header => {
                                                        return (
                                                            <th>{header}</th>
                                                        )
                                                    })
                                                }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>This Season</td>
                                                    {/* {
                                                        statHeaders.batters.map(value => {
                                                            return (
                                                                <td>{parseFloat(statsTab.currentYear[value]) || '-'}</td>
                                                            )
                                                        })
                                                    } */}
                                                </tr>
                                            </tbody>
                                    </Table>
                                </div>

                                {/* News */}
                                {news[0] &&
                                <div className="w-100 my-3 shadow" style={{backgroundColor: Colors.bgLight, borderRadius: 10, border:`0.01em solid ${Colors.colorSecondaryTransparent}`}}>
                                    <h4 className="p-2 w-100" style={{borderBottom:`0.01em solid ${Colors.colorSecondaryTransparent}`}}>Recent News</h4>
                                    <div className="h-50">
                                        {
                                            news.map(news => {
                                                return (
                                                    <div className="d-flex px-2 my-2" >
                                                        <div style={{height:80, width:80, borderRadius:10, backgroundColor:Colors.bgDark}}>
                                                            {/* Image goes here */}
                                                        </div>
                                                        <div className="flex1 ml-2">
                                                            <h5>{news.data.headline}</h5>
                                                            <p>{news.data.notes}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        
                                    </div>
                                </div>
                                }
                            </>
                    </Tab>,
                'gamelog':
                    //   <Tab>
                          <GameLogsNFL playerId={props.data.playerId} leagueId={props.data.leagueId} position={props.data.pos}/>
                    //   </Tab>,
                    ,
                'stats':
                <div>
                        <Table striped>
                            <thead>
                                <tr>
                                <th></th>
                                {
                                    statsTab.header.map(header => {
                                        return (
                                            <th>{header}</th>
                                        )
                                    })
                                }
                                </tr>
                            </thead>
                            <tbody>
                            {
                                Object.keys(statsTab).map(tab => {
                                    return (
                                        tab == 'header' ? 
                                        ''
                                        :
                                        <tr>
                                            <td>{tab.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() + tab.replace(/([A-Z])/g, " $1").slice(1)}</td>
                                            {
                                                Object.values(statsTab[tab]).map(value => {
                                                    return (
                                                        <td>{parseFloat(value) || '-'}</td>
                                                    )
                                                }
                                                )
                                            }
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </Table>
                        </div>,
                    
                'news':
                    <Tab>
                        {
                            news.map(news => {
                                return (
                                    <div className="w-100 my-3 shadow" style={{backgroundColor: Colors.colorSecondary_v3}}>
                                            {   <>
                                                    <div className="d-flex p-2 my-2" >
                                                        <div className="flex1 ml-2">
                                                            <h6>{news.date}</h6>
                                                            <p>{news.data.notes}</p>
                                                            <h6 style={{color: Colors.colorSecondary}}>ANALYSIS</h6>
                                                            <p>{news.data.analysis}</p>
                                                        </div>
                                                    </div>
                                                    {/* <div className='p-2'>
                                                        <Button className='w-100' onClick={''} style={{ color: Colors.colorInfo, backgroundColor: Colors.colorInfoTransparent, border: `2px solid ${Colors.colorInfo}`, borderRadius:8 }}>
                                                            Read More
                                                            <i className="ml-2">
                                                                {externalLinkIcon()}
                                                            </i>
                                                        </Button>
                                                    </div> */}
                                                    
                                                </>
                                                
                                            }
                                    </div>
                                )
                            })
                        }
                    </Tab>,
                // 'bio':
                //     <Tab>
                //         <div>
                //             <div className="my-2">
                //                 <span style={{color: Colors.colorSecondary}}>Appearances</span>
                //                 <span className="pull-right bold">24 apps</span>
                //             </div>

                //             <div className="my-2">
                //                 <span style={{color: Colors.colorSecondary}}>Age</span>
                //                 <span className="pull-right bold">{props.bio.age} years</span>
                //             </div>

                //             <div className="my-2">
                //                 <span style={{color: Colors.colorSecondary}}>Height</span>
                //                 <span className="pull-right bold">{props.bio.height}</span>
                //             </div>

                //             <div className="my-2">
                //                 <span style={{color: Colors.colorSecondary}}>Weight</span>
                //                 <span className="pull-right bold">{props.bio.weight}lbs</span>
                //             </div>

                //             <div className="my-2">
                //                 <span style={{color: Colors.colorSecondary}}>Arms</span>
                //                 <span className="pull-right bold">32 3/4</span>
                //             </div>

                //             <div className="my-2">
                //                 <span style={{color: Colors.colorSecondary}}>Hands</span>
                //                 <span className="pull-right bold">9 1/4</span>
                //             </div>
                //         </div>
                //     </Tab>
            }[props.tab]
        }
    
        
        
        {/* <Tab>
             {news.map((n) => (
                 <div style={{borderBottom: "1px solid #6c757d"}} >
                     <h5 className="bold" >{n.data.headline}</h5>
                     <p>{n.date}</p>
                     <p>{n.data.analysis}</p>
                 </div>
             ))}
        </Tab> */}
  </div>
  </PerfectScrollbar>
    )
}

export default PlayerCardTabsNBA