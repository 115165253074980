import React from 'react';
import { slideInOut, slideOutLeft } from './animationValues';
import { motion } from "framer-motion/dist/framer-motion"
// import { Colors } from '../../helpers/colors';
import { espnWordMark, fantraxLogo, sleeperSvg, yahooWordMark } from '../../helpers/icons'
import PerfectScrollbar from 'react-perfect-scrollbar';

const SelectPlatform = (props) => {
	const {step, updateStep, setSelectedPlatform, sport, Colors} = props

	const nextStep = (platform) => {
		setSelectedPlatform(platform)
		updateStep("platformSelected")
	}

	return (
		<motion.div
			className="flex-column scrollHidden"
			// animate={(step == "platformSelected") ? 'hide' :'show'}
			animate={step == 'sportSelected' ? "show" : step == 'platformSelected' ? "hideLeft" : "hideRight"}
			transition={{ duration: 0.5 }}
			style={{display: "flex"}}
			initial={false}
			variants={slideInOut}>
			
			{step == "sportSelected" && <PerfectScrollbar style={{minHeight: "500px"}} className='d-flex flex-column flex1 mx-2 text-center' > 
				<h1 className="mb-3 alex bold" style={{ color: Colors.colorPrimary }}>
					Import League
				</h1>
				<p className='lead' >Which platform are you on?</p>
				<div className="mx-auto">
					{/* Yahoo Word Mark */}
					<motion.div
						onClick={() => nextStep('yahoo')}
						whileHover={{
							color: '#420194'
						}}
						className="col-md col-12 mt-3"
						style={{ color: '#666666', cursor: 'pointer', height: 50 }}>
						{yahooWordMark()}
					</motion.div>
					{/* !Yahoo Word Mark */}

					{/* ESPN Word Mark */}
					<motion.div
						onClick={() => nextStep('espn')}
						whileHover={{
							color: '#D61E26'
						}}
						className="col-md col-12 mt-5"
						style={{  color: '#666666', cursor: 'pointer', height: 50 }}>
						{espnWordMark()}
					</motion.div>
					{/* !ESPN Word Mark */}

					{sport == "NFL" &&<motion.div
						onClick={() => nextStep('sleeper')}
						whileHover={{
							color: '#3a465b'
						}}
						className="col-md col-12 mt-5"
						style={{ color: '#666666', cursor: 'pointer', height: 50 }}>
						{sleeperSvg()}
					</motion.div>
					}

					{ sport != "NFL" && <motion.div
						onClick={() => nextStep('fantrax')}
						whileHover={{
							color: '#095597'
						}}
						className="col-md col-12 mt-5"
						style={{ color: '#666666', cursor: 'pointer', height: 50 }}>
						{fantraxLogo()}
					</motion.div>}

				</div>
			</PerfectScrollbar>}

		</motion.div>
	);
};

export default SelectPlatform