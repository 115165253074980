import React, { useState, useEffect } from 'react';
// import { Colors } from '../../../helpers/colors';
import ArenaSettingsComp from '../../../components/Matchmaker/ArenaSettings/ArenaSettingsComp/ArenaSettingsComp';
import Rules from '../../../components/Matchmaker/ArenaSettings/Rules/Rules';
import UploadAvatarModalSett from '../../../components/Matchmaker/ArenaSettings/UploadAvatarModalSett';
import InviteFriendModal from '../../../components/Matchmaker/ArenaSettings/InviteFriendModal';
import { Row, Col } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { updateArenaSettings } from '../../../apis/Matchmaker/ArenaSettings/ArenaSettings';
import { arenaSettings } from '../../../apis/Matchmaker/ArenaSettings/ArenaSettings';
import { toastSuccess, toastError } from '../../../helpers/toasts';
import { getStore, sportFromExperience } from '../../../helpers/common-methods';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import store from '../../../store/index';
import GidxPaymentModal from '../../../modals/GidxModals/GidxPaymentModal';
import { verifyUserStatus } from '../../../helpers/Payments/GidxHelper';
import LoadingOverlay from 'react-loading-overlay';
import GidxAddFunds from '../../../components/Payments/Gidx/GidxAddFunds';
import GidxErrorModal from '../../../modals/GidxModals/GidxErrorModal';
import GidxSuccessModal from '../../../modals/GidxModals/GidxSuccessModal';
import { useColors } from '../../../helpers/CustomHooks/useColors';

const ArenaSettings = (props) => {
  const Colors = useColors();
  const maxBetBtns = ['5', '10', '25', '50', '100'];
  const bankrollBtns = [25, 50, 100, 250, 500];
  const [loading, setLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [page, setPage] = useState('default');
  const [maxBet, setMaxBet] = useState('');
  const [maxBetInput, setMaxBetInput] = useState('');
  const [bankroll, setBankroll] = useState('');
  const [bankrollInput, setBankrollInput] = useState('');
  const [uploadAvatarModal, setUplaodAvatarModal] = useState(false);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null)
  const [paymentModal, setPaymentModal] = useState(false)
  const [payType, setPayType] = useState()
  const [inviteFriendModal, setInviteFriendModal] = useState(false);
  const experience = store.getState().leagueJoin.app_status;
  const sport = sportFromExperience(experience, true)
  const errorModal = useSelector(state => state.gidxErrorReducer.errorModal)
  const successModal = useSelector(state => state.gidxErrorReducer.successModal)

  const toggleUploadAvatar = () => {
    setUplaodAvatarModal(!uploadAvatarModal);
  };

  const toggleInviteFriend = () => {
    setInviteFriendModal(!inviteFriendModal);
  };

  const handleUpdateSettings = () => {
    setLoading(true);
    const reqData = {};
    reqData['maxBetAmount'] = maxBet == '' ? maxBetInput : maxBet;
    reqData['maxExposure'] = bankroll == '' ? bankrollInput : bankroll;
    updateArenaSettings(reqData).then((resp) => {
      if (resp.data.message == 'done') {
        const userDataCopy = JSON.parse(JSON.stringify(props.userData));
        userDataCopy['maxBetAmount'] = maxBet == '' ? maxBetInput : maxBet;
        userDataCopy['maxExposure'] = bankroll == '' ? bankrollInput : bankroll;
        getStore().dispatch({ type: 'SET_ARENA_USER_DATA', payload: userDataCopy });
        toastSuccess('Settings Updated!');
      };
      setLoading(false);
    }).catch(err => {
      toastError(err.message);
      setLoading(false);
    });
  };

  useEffect(() => {
    arenaSettings("?sport=" + sport).then((response) => {
      maxBetBtns.includes(response.data.maxBetAmount) ? setMaxBet(response.data.maxBetAmount) : setMaxBetInput(response.data.maxBetAmount)
      bankrollBtns.includes(parseInt(response.data.maxExposure)) ? setBankroll(parseInt(response.data.maxExposure)) : setBankrollInput(parseInt(response.data.maxExposure));
      setImageUrl(response.data.teamImage);
      getStore().dispatch({ type: 'SET_ARENA_USER_DATA', payload: response.data });
    }).catch(err => {
      toastError(err.message);
    });
  }, [sport])

  const openPaymentModal = (pt) => {
    setPaymentLoading(true)
    verifyUserStatus().then((resp) => {
        if (resp && resp.verified) {
            setPayType(pt)
            setPaymentModal(true)
        }
        setPaymentLoading(false)
    })
  }

  const settingsLayout = () => {
    return (
      <div className='d-flex flex-column flex1 scrollHidden col-10 col-centered'>
          <div className='my-2 py-3' style={{ background: Colors.bg400, borderRadius: 10 }}>
            <div className='d-flex justify-content-center align-items-cener'>
              <div className='text-center'>
                {props.userData ?
                  <>
                    <div className='d-flex justify-content-center align-items-center'>
                      {imageUrl != null ?
                        // <div style={{ overflow: 'hidden', maxWidth: '100px', maxHeight: '100%' }}>
                          <img className='roundImgMed' src={imageUrl} style={{ border: `1px solid ${Colors.colorPrimary}`, objectFit: 'cover' }} />
                        // </div>
                        :
                        <p className='bold'>Loading..</p>
                      }
                    </div>
                    <h3 className='mt-1 mb-0'>{props.userData.teamName}</h3>
                    <span style={{ color: Colors.textColorSecondary }}>4-1-0</span>
                    <div className='d-flex justify-content-center align-items-center mt-2'>
                      {/* <div className='p-3' style={{ background: Colors.bgLight, borderRadius: 10 }}>
                        <h5 className='mb-0 bold' style={{ color: Colors.colorPrimary }}>${props.userData.maxBetAmount}</h5>
                        <p className='mb-0'>Max Bet Size</p>
                      </div> */}
                      {/* <div className='p-3 ml-2' style={{ background: Colors.bgLight, borderRadius: 10 }}>
                        <h5 className='mb-0 bold' style={{ color: Colors.colorPrimary }}>${parseInt(props.userData.maxExposure)}</h5>
                        <p className='mb-0'>Weekly Bankroll</p>
                      </div> */}
                    </div>
                  </>
                  :
                  <h4 className='bold'>Loading...</h4>
                }
              </div>
            </div>
          </div>

          <div className='d-flex flex-column flex1' style={{overflow: "auto"}} >
          {/* <div className='d-flex align-items-center '>
            <div className='flex1 mr-3 px-4 py-3 my-2' style={{ background: Colors.bg400, borderRadius: 10,  }}>
              <h5 className='bold mb-0' style={{ cursor: 'pointer' }} onClick={() => setPage('arena-settings')}>Arena Settings</h5>
              <p style={{ color: Colors.textColorSecondary }}>Manage max bet limit</p>
            </div>
            <div className='flex1 px-4 py-3 my-2' style={{ background: Colors.bg400, borderRadius: 10 }}>
              <h5 className='bold mb-0' style={{ cursor: 'pointer' }} onClick={() => { setUplaodAvatarModal(true) }}>Update Avatar</h5>
              <p style={{ color: Colors.textColorSecondary }}>This will be your team default avatar</p>
            </div>
          </div> */}
          <div className='d-flex justify-content-between align-items-center'>

            <div className='flex1 px-4 py-3 my-2 mr-3' style={{ background: Colors.bg400, borderRadius: 10 }}>
              <h5 className='bold mb-0' style={{ cursor: 'pointer' }} onClick={() => { setUplaodAvatarModal(true) }}>Update Avatar</h5>
              <p style={{ color: Colors.textColorSecondary }}>This will be your team default avatar</p>
            </div>

            <div className='flex1 px-4 py-3 my-2' style={{ background: Colors.bg400, borderRadius: 10,  }}>
              <h5 className='bold mb-0' style={{ cursor: 'pointer' }} onClick={() => { setPage('rules') }}>Rules</h5>
              <p style={{ color: Colors.textColorSecondary }}>All your questions, answered</p>
            </div>

          </div>
          <div className='d-flex justify-content-between align-items-center'>
            <div onClick={() => openPaymentModal("deposit")} className='flex1 mr-3 px-4 py-3 my-2 pointer' style={{ background: Colors.bg400, borderRadius: 10,  }}>
              <h5 className='bold mb-0' style={{ cursor: 'pointer' }} onClick={() => { }}>Deposit</h5>
              <p style={{ color: Colors.textColorSecondary }}>Top-up your wallet</p>
            </div>

            
            <div onClick={() => openPaymentModal("credit")} className='flex1 px-4 py-3 my-2 pointer' style={{ background: Colors.bg400, borderRadius: 10 }}>
              <h5 className='bold mb-0' style={{ cursor: 'pointer' }} onClick={() => { }}>Withdraw</h5>
              <p style={{ color: Colors.textColorSecondary }}>Get your money out</p>
            </div>
          </div>
          <div className='d-flex justify-content-between aling-items-center'>
            {/* <div className='flex1 mr-3 px-4 py-3 my-2' style={{ background: Colors.bg400, borderRadius: 10,  }}>
              <h5 className='bold mb-0' style={{ cursor: 'pointer' }} onClick={() => { setInviteFriendModal(true) }}>Invite Friends</h5>
              <p style={{ color: Colors.textColorSecondary }}>Use your private link to invite friends on to bettle...</p>
            </div> */}
            <div className='flex1 px-4 py-3 my-2 mr-4' style={{ background: Colors.bg400, borderRadius: 10 }}>
              <h5 className='bold mb-0' ><a target='blank' href="https://bettlesupport.zendesk.com/hc/en-us" style={{ textDecoration: 'none' }}>Contact Support</a></h5>
              <p style={{ color: Colors.textColorSecondary }}>Have a quick chat with our support team.</p>
            </div>
            <div className='flex1 px-4 py-3 my-2'></div>
          </div>
          </div>
      </div>
    )
  }

  const toggle = () => {
    setPaymentModal(!paymentModal)
  }

  return (
    <LoadingOverlay
        className={`d-flex flex-column flex1 scrollHidden`}
        active={paymentLoading}
        spinner
        text='Processing...'
        fadeSpeed={500}
    >
      <div className='d-flex align-items-center px-3' style={{ borderBottom: `1px solid ${Colors.darkBgBorder}`, height: '52px' }}>
        <h2>Settings</h2>
      </div>
      {/* <PerfectScrollbar className='d-flex flex-column flex1'> */}
        <div className="d-flex flex1 scrollHidden" >
          <div className={`d-flex flex-column flex1 scrollHidden p-0 ${paymentModal ? "col-8" : ""}`} >
            {(() => {
            switch (page) {
              case 'arena-settings':
                return <ArenaSettingsComp setPage={setPage} maxBet={maxBet} setMaxBet={setMaxBet} bankroll={bankroll} setBankroll={setBankroll} maxBetInput={maxBetInput} setMaxBetInput={setMaxBetInput} bankrollInput={bankrollInput} setBankrollInput={setBankrollInput} handleUpdateSettings={handleUpdateSettings} maxBetBtns={maxBetBtns} bankrollBtns={bankrollBtns} loading={loading} userData={props.userData} />
              case 'rules':
                return <Rules setPage={setPage} />
              case 'default':
                return settingsLayout()
            }
          })()}
          </div>
          {paymentModal && <div style={{borderLeft: `1px solid ${Colors.darkBgBorder}`}} className="col-4 p-0 d-flex flex-column flex1 scrollHidden">
            <div className="d-flex align-items-center justify-content-between p-3" style={{ borderBottom: `1px solid ${Colors.darkBgBorder}` }} >
                <div className="d-flex align-items-center" >
                    <div onClick={() => setPayType("deposit")} className="mr-3" style={{ borderBottom: `2px solid ${payType == "deposit" ? Colors.colorPrimary : Colors.bgLight}` }} ><h5 className="m-0" >Add Funds</h5></div>
                    <div onClick={() => setPayType("credit")} style={{ borderBottom: `2px solid ${payType == "credit" ? Colors.colorPrimary : Colors.bgLight}` }} ><h5 className="m-0">Withdraw Funds</h5></div>
                </div>
                <div><i className='fa fa-close' onClick={toggle} style={{ cursor: 'pointer', fontSize: "1.25rem" }}></i></div>
            </div>
            <GidxAddFunds fromModal={true} key={payType} payType={payType} toggleFlyout={toggle} />
        </div>}
        </div>
      {/* </PerfectScrollbar> */}
      {uploadAvatarModal && <UploadAvatarModalSett isOpen={uploadAvatarModal} toggle={toggleUploadAvatar} image={image} setImage={setImage} imageUrl={imageUrl} setImageUrl={setImageUrl} userData={props.userData} />}
      {inviteFriendModal && <InviteFriendModal isOpen={inviteFriendModal} toggle={toggleInviteFriend} />}
      {errorModal.show && <GidxErrorModal />}
      {successModal.show && <GidxSuccessModal />}
      {/* {paymentModal && <GidxPaymentModal modal={paymentModal} toggle={() => setPaymentModal(!paymentModal)} payType={payType} />} */}
    </LoadingOverlay>
  )
};

const mapStateToProps = (state) => ({
  userData: state.arenaReducer.userData
});

export default connect(mapStateToProps, null)(withRouter(ArenaSettings));