import moment from "moment-timezone";
import React, { useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { startDateStatus } from "../../../apis/Arena/MatchMakerApis";
import { ColorContext } from "../../../context/ColorProvider";
import { parseScoringPeriod } from "../../../helpers/Arena/ArenaHelper";
import { STYLES } from "../../../helpers/stylesHelper";
import store from "../../../store";
import { LoaderCenter } from "../../Loader";
import FullWidthButton from "../../subcomponents/FullWidthButton";

const ArenaTimeframe = (props) => {
    const {sport, callBack, teamId, fromModal, lineupModalState} = props
    const Colors = useContext(ColorContext);

    const [preiods, setPeriods] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(); // TD (Today), TM (Tomorrow), NW (Next Week)
    const [loading, setLoading] = useState(true);
    const [redirecting, setRedirecting] = useState(false);
    const activeTeam = useSelector(state => state.arenaReducer.active_team);

    useEffect(() => {
        // if(sport != "NFL")
        // {
            callApi()
        //}
        // else {
        //     callBack()
        // }
    },[])

    useEffect(() => {
        if (lineupModalState) {
            setRedirecting(!redirecting);
        };
    }, [lineupModalState]);

    const callApi = () => { 
        startDateStatus({sport})
            .then((resp) => {
                setPeriods(resp.data.timeFrames);
                setSelectedPeriod(resp.data.timeFrames[0]);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleTimePeriodSelect = (index) => {
        setSelectedPeriod(preiods[index])
    }
    
    const handleTimeframeConfirmation = async () => {
        // const selectedTimeFrame = preiods.find((item) => item.key === selectedPeriod);
        // const savedTimeFrames = await store.getState().arenaReducer.selectedTimeframe;
        // If team does not exists in timeframes add it with timeframe and set callBack to false
        // Else check if timeframe is same as saved timeframe or changed
        // If same then set callBack to true else false
        // CallBack(true) will start "Select Lineup" step
        // if(savedTimeFrames.length === 0 || !savedTimeFrames[teamId]) {
        //     const teamTimeFrame = {
        //         [teamId]: selectedTimeFrame
        //     }
        //     console.log(teamTimeFrame)
        //     store.dispatch({ type: "SAVE_SELECTED_TIMEFRAME", payload: {teamTimeFrame} });
        //     callBack(true, selectedTimeFrame.key);
        // }
        // else {
        //     if(selectedTimeFrame.key == savedTimeFrames[teamId]?.key) {
        //         callBack(false);
        //     }
        //     else {
        //         const savedTimeFramesCopy = {...savedTimeFrames};
        //         savedTimeFramesCopy[teamId] = selectedTimeFrame;

        //         console.log(selectedTimeFrame)

        //         store.dispatch({ type: "SAVE_SELECTED_TIMEFRAME", payload: selectedTimeFrame });
        //         callBack(true, selectedTimeFrame.key);
        //     }
        // }
        store.dispatch({ type: "SAVE_SELECTED_TIMEFRAME", payload: selectedPeriod });
        // usage of activeTeam, timeFrame from local storage is strictly prohibited
        localStorage.setItem("activeTeam", JSON.stringify(activeTeam));
        localStorage.setItem("timeFrame", JSON.stringify(selectedPeriod));
        callBack(true, selectedPeriod.key)
        setRedirecting(true)


        // if(savedTimeFrames.length === 0) {
        //     const teamTimeFrame = {
        //         [teamId]: selectedTimeFrame
        //     }
        //     store.dispatch({ type: "SAVE_SELECTED_TIMEFRAME", payload: teamTimeFrame });
        //     callBack(true);
        // }
        // else {
        //     if(selectedTimeFrame[teamId] && selectedTimeFrame[teamId]?.key == savedTimeFrames[teamId]?.key) {
        //         const teamTimeFrame = {
        //             [teamId]: selectedTimeFrame,

        //         }
        //         store.dispatch({ type: "SAVE_SELECTED_TIMEFRAME", payload: selectedTimeFrame });
        //         callBack(true);
        //     }
        //     else {
        //         callBack(false);
        //     }
        // }
    }
    return (
            <div className="d-flex flex-column flex1 m-2"style={{overflow: "auto"}} >
                
                <div className="p-3 my-2" style={{backgroundColor: Colors.bgSecondHeader, borderRadius:10}}>
                    {/* <h1 style={{color: Colors.colorPrimary}}>Weekly Changes Format</h1> */}
                    <h5 className="mx-5">Choose the length of your desired matchup. <br /> Only starters accrue stats.</h5>
                    {/* Radio */}
                    {
                        loading ?
                        <LoaderCenter />
                        :
                        <div className="mx-5">
                            {
                                preiods.map((item, index) => {
                                    return (
                                        <div className="align-items-center">
                                            <div className="d-flex align-items-start">
                                                <div className="d-flex align-items-center justify-content-center pointer" style={{ width: "22px", height: "22px", border: `2px solid ${Colors.colorPrimary}`, borderRadius: 100, marginTop: "12px" }} onClick={() => handleTimePeriodSelect(index)}>
                                                    <div style={{ width: "12px", height: "12px", background: selectedPeriod.key == item.key ? Colors.colorPrimary : Colors.bgSecondHeader, borderRadius: 100 }}></div>
                                                </div>
                                                {/* <input className="pointer" style={{ verticalAlign: "middle", width: "20px", height: "20px", accentColor: Colors.colorPrimary, marginTop: "14px" }} type="radio" id={item.key} checked={selectedPeriod.key == item.key} value={item.key} onClick={() => handleTimePeriodSelect(index)} /> */}
                                                <div>
                                                    <label className="d-flex flex-column mt-2" for={item.key}>
                                                        <p className={`m-0 alex`} style={{ color: Colors.colorPrimary, fontSize: 20, fontWeight: 600, paddingLeft: "12px" }}>{item.value}</p>
                                                        <p className="m-0" style={{ color: Colors.colorPrimary, fontSize: 14, fontWeight: 500, paddingLeft: "12px", marginTop: "8px" }}>{item.key == "FW" ? "Your familiar Mon. to Sun. matchup" : "Just the Fri. to Sun. slate "}</p>
                                                        <p className="m-0" style={{ fontSize: 16, fontWeight: 500, paddingLeft: "12px" }}>{parseScoringPeriod(item.start, item.end)}</p>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
                {!loading && <FullWidthButton className='bold font-lg' text={ redirecting ? <i class="fa fa-refresh fa-spin"></i> : `SUBMIT`} style={{color:'#000000'}} onClick={handleTimeframeConfirmation} />}
            </div>
    )
}
export default ArenaTimeframe