import React from 'react'
import { bettleSignupQR } from '../../helpers/icons'
import qrCode from "../../assets/images/qrCode.png"
import { useColors } from '../../helpers/CustomHooks/useColors'

const ArenaLeaderboardsInfo = (props) => {
  const { week } = props
  const Colors = useColors();
  return (
    <div className='m-2 d-flex px-3 flex-column justify-content-center' style={{overflow:'auto'}}>
        <h3 style={{fontWeight:'bold'}}>
            Week {week}
        </h3>
        <h1 style={{color:Colors.arenaLeaderbaordsPrimary, fontSize:48, fontWeight:'bold'}}>Arena<br/> Leaderboard</h1>

        <h3 className='my-4'>
            This could be you.<br/> Sign up now!
        </h3>
        <div className='text-white' style={{height:250, width:250, aspectRatio:1}}>
            <img src={qrCode} className="img-fluid" />
        </div>
    </div>
  )
}

export default ArenaLeaderboardsInfo