import React, { Component } from 'react';
import Select from 'react-select';
import { Button, Form, FormGroup, Label } from 'reactstrap';
import { deleteTradeOffer, dropPlayersForReceiver, saveTradeOffer, tradeData, updateTradeOffer, validateSenderRoster } from '../../../../apis/SeasonLong/Trade/TradeAPI';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { deleteMsgById } from '../../../../apis/Chat/ChatAPI';
import LoadingOverlay from 'react-loading-overlay';
import { toastError } from '../../../../helpers/toasts';
import { STYLES } from '../../../../helpers/stylesHelper';
import { ColorContext } from '../../../../context/ColorProvider';

class Trade extends Component {
    constructor(props) {
        super(props);

        this.state = {

            // Components States
            
            // {Object} - Variables
            theirPlayer:null,
            yourPlayer:null,
            needDrop: false,
            numbersToDrop:0,
            playersDropList:null,
            picksToTrade:null,
            picksToAcquire:null,

            // {Object} - Constant
            playersDrop:[],
            yourPicks:null,
            theirPicks:null,

            loader: true,

            // Drop Players States
            needDrop: false,
            numbersToDrop:0,
            playersDropList:null,
            
            // {Bool}
            picksTradeAway: false,
            picksAcquire: false,
            playersTradeAway:true,
            playersAcquire:true,


            selectedOptions: [],

            // API Response States
            tradeData: {
                draftType:'',
                my:{
                    name:'',
                    picks:[],
                    players:[]
                },
                opp:{
                    name:'',
                    picks:[],
                    players:[]
                }
            },
        }


        // Send or Delete at internal state level
        this.send = this.send.bind(this)
        this.delete = this.delete.bind(this)

        // Event Handlers
        this.goBack = this.goBack.bind(this)
        this.dropAndAccept = this.dropAndAccept.bind(this)
        this.sendTradeButton = this.sendTradeButton.bind(this)
        this.handleYourPlayer = this.handleYourPlayer.bind(this)
        this.handleTheirPlayer = this.handleTheirPlayer.bind(this)
        this.handlePicksToTrade = this.handlePicksToTrade.bind(this)
        this.handlePicksToAcquire = this.handlePicksToAcquire.bind(this)
        this.handlePlayersDropList = this.handlePlayersDropList.bind(this)

        // Proposal Actions
        this.acceptProposal = this.acceptProposal.bind(this)
        this.rejectProposal = this.rejectProposal.bind(this)
        this.cancelProposal = this.cancelProposal.bind(this)

        // Toggle Handlers
        this.togglePicksAcquire = this.togglePicksAcquire.bind(this)
        this.togglePlayerAcquire = this.togglePlayerAcquire.bind(this)
        this.togglePicksTradeAway = this.togglePicksTradeAway.bind(this)
        this.togglePlayerTradeAway = this.togglePlayerTradeAway.bind(this)
    }

    static contextType = ColorContext;

    componentDidMount() {
        if(this.props.status == 'TRADE_PROPOSAL') {
            this.fetchTradeData()
        }

        let { payload } = this.props.data.attachments[0].data
        if(payload) {
            this.setState({
                theirPlayer:[payload]
            })
        }
    }

    // Fetch Trade data for of current trade
    fetchTradeData() {
        let params = {
            leagueId:this.props.leagueId,
            oppId:this.props.match.params.id
        }
        tradeData(params)
            .then(data => {
                this.setState({
                    loader: false,
                    tradeData:data.data
                })
            })
            .catch(error => {

            })
    }

    /**
     * Set trade data to local state
     * @param {object} data - data related to trade
     */    
    setData(data) {
        this.setState({
            tradeData: data,
            options:{
                my:[],
                opp:[]
            }
        })
    }

    // Send the proposal from state to server
    send() {

        let data = {}

        data = {
            league_id: this.props.data.attachments[0].data.leagueId,
            trade: {
                step_1: {
                    player_ids:[]
                },
                step_2: {
                    player_ids:[]
                }
            }
        }

        if (this.state.playersTradeAway) {
            data["trade"]["step_2"]['player_ids'] = this.prepareIds(this.state.yourPlayer)
        }

        if (this.state.playersAcquire) {
            data["trade"]["step_1"]['player_ids'] = this.prepareIds(this.state.theirPlayer)
        }
        if(this.state.picksTradeAway) {
            data["trade"]["step_2"]["picksTradeAway"] = this.preparePicksIds(this.state.picksToTrade)
        }

        if (this.state.picksAcquire) {
            data["trade"]["step_1"]["picksAcquire"] = this.preparePicksIds(this.state.picksToAcquire)
        }

        if(this.state.playersDrop.length > 0) {
            data['drop_players_sender'] = this.prepareIds(this.state.playersDrop)
        }

        if(this.state.needDrop) {
            if(this.state.playersDrop.length >= this.state.numbersToDrop) {
                this.saveTradeOffer(data)
            }
            else {
                toastError('You need to select '+(this.state.numbersToDrop-this.state.playersDrop.length)+' more players')
            }
        }
        else {
            validateSenderRoster(data)
            .then(response => {
                if(response.data.redirect) {
                    let playersDropList = []
                    if(this.state.yourPlayer) {
                        playersDropList = this.state.tradeData.my.players.filter(player => this.state.yourPlayer.indexOf(player) == -1)
                    }
                    else {
                        playersDropList = this.state.tradeData.my.players
                    }
                    
                    this.setState({
                        playersDropList: playersDropList,
                        needDrop: true,
                        numbersToDrop:response.data.player_drop
                    })
                }

                else {
                    this.saveTradeOffer(data)
                }
            })
            .catch(error => {
            })
        }
    }

    /**
     * broadcast trade offer to server
     * @param {object} data - trade data
     */
    saveTradeOffer(data){
        data['opp_id'] = this.props.data.attachments[0].data.oppId
        saveTradeOffer(data)
            .then( response => {
                // Prepare trade data
                data = this.props.data
                data['attachments'] = this.prepareAttachments(response.data.tid)
                this.props.handleCommand(data, 'TARDE_PROPOSAL')
                // Send the message by handleCommand
            })
            .catch( error => {
                toastError(error)
            })
    }

    // Remove this offer from internal state
    delete(){
        this.props.removeMessageFromState()
    }

    // Go back to previous step 
    goBack() {
        this.setState({
            needDrop:false,
            numbersToDrop:null,
            playersDrop:[],
            playersDropList:null
        })
    }

    /**
     * Extract IDs of players to send in API request
     * @param {object} data - Object of Palyers to extract IDs from
     * @returns IDs of passed players
     */
    prepareIds(data) {
        let IDs = data.map(value => {return value.playerId})
        return IDs
    }

    /**
     * Extract IDs of picks to send in API request
     * @param {object} data - Object of Picks to extract IDs from
     * @returns IDs of passed picks
     */
    preparePicksIds(data) {
        let IDs = data.map(value => {return value.id})
        return IDs
    }

    /**
     * Extract Names of players to send in Message Attachments
     * @param {object} data - Object of Palyers to extract Names from
     * @returns Names of passed players
     */
    prepareNames(data) {
        let Names = data.map(value => {return value.playerName})
        return Names
    }

    /**
     * Extract Names of picks to send in Message Attachments
     * @param {object} data - Object of Picks to extract Names from
     * @returns Names of passed picks
     */
    preparePickNames(data) {
        let Names = data.map(value => {return value.pick})
        return Names
    }

    /**
     * Prepare attachments with transaction ID
     * @param {int} tid - Transaction ID of the trade
     * @returns attachments to send with message
     */
    prepareAttachments(tid) {
        let data =  {attachments: [{
            data: {
                tid: tid,
                myPicks: this.state.picksToTrade? this.preparePickNames(this.state.picksToTrade):[],
                oppPicks: this.state.picksToAcquire? this.preparePickNames(this.state.picksToAcquire):[],
                draftType: "snake",
                myPlayers: this.state.yourPlayer? this.prepareNames(this.state.yourPlayer):[],
                oppPlayers: this.state.theirPlayer? this.prepareNames(this.state.theirPlayer):[],
                receiverId: this.props.data.attachments[0].data.oppId,
                senderName: this.props.data.user.username,
                receiverName: this.props.data.attachments[0].data.oppName,
                myAuctionBudget: null,
                oppAuctionBudget: null
              },
              template: "TRADE_SENT"
        }]}

        return data.attachments
    }

    // Accept the trade proposal as a receiver
    acceptProposal() {
        updateTradeOffer({tid: this.props.data.attachments[0].data.tid, accept:true})
            .then(response => {
                if(response.data.error) {
                    toastError(response.data.error)
                }
                else if(response.data.redirect) {
                    let playersDropList = response.data.rPlayers.filter(player => this.props.data.attachments[0].data.oppPlayers.indexOf(player.playerName) == -1)
                    this.setState({
                        needDrop: true,
                        numbersToDrop: response.data.rPlayersDrop,
                        playersDropList: playersDropList
                    })
                }
                else {
                    // Accept the trade (No need to do anything yet)
                }
            })
            .catch(error => {
                toastError(error.response.data.message)
            })
    }

    // Check if players being dropped meet the min limit or not
    dropAndAccept() {
        if(this.state.playersDrop.length >= this.state.numbersToDrop){
            dropPlayersForReceiver({tid: this.props.data.attachments[0].data.tid, player_ids: this.prepareIds(this.state.playersDrop)})
                .then(response => {
                    // this.acceptProposal()
                    this.updateMessageTemplate('TRADE_ACCEPTED')
                })
                .catch(error => {
                    toastError('Cannot drop players, try again!')
                })
        }
        else {
            toastError('You need to select '+(this.state.numbersToDrop-this.state.playersDrop.length)+' more players')
        }
    }

    // Reject the trade proposal as receiver
    rejectProposal() {
        updateTradeOffer({tid: this.props.data.attachments[0].data.tid, accept:false})
            .then(response => {
                if(response.data.error) {
                    toastError(response.data.error)
                }
                else{
                    this.updateMessageTemplate('TRADE_REJECTED')
                }
                
            })
            .catch(error => {
                toastError(error.response.data.message)
            })
    }

    // Cancel trade proposal as sender
    cancelProposal() {
        deleteTradeOffer({tid: this.props.data.attachments[0].data.tid, st:5})
            .then(response => {
                this.updateMessageTemplate('TRADE_CANCELLED')
            })
            .catch((error) => {
                toastError(error.response.data.message)
            })
    }

    /**
     * Update the status of trade
     * @param {string} template - update the existing message template to new status
     */
    updateMessageTemplate(template) {
        let data = this.props.data
        deleteMsgById(this.props.data.msg_id)
            .then(response => {
                data['attachments'][0]['template'] = template
                this.props.handleCommand(data)
            })
            .catch((error) => {
                toastError(error.response.data.message)
            })
    }
    
    /**
     * Handle players to be traded
     * @param {object} yourPlayer - Selected player from sender's team
     */
    handleYourPlayer(yourPlayer) {
        if (yourPlayer == null) {
            this.setState({
                yourPlayer:[]
            })
        }
        else{
            this.setState({yourPlayer})
        }
    }

    /**
     * Handle players to be acquired
     * @param {object} theirPlayer - Selected player from receiever's team
     */
    handleTheirPlayer(theirPlayer) {
        if (theirPlayer == null) {
            this.setState({
                theirPlayer:[]
            })
        }
        else{
            this.setState({theirPlayer})
        }
    }

    /**
     * Handle players to be dropped (During sending/accepting trade)
     * @param {object} playersDrop - players to be dropped during trade
     */
    handlePlayersDropList(playersDrop) {
        if (playersDrop == null) {
            this.setState({
                playersDrop:[]
            })
        }
        else{
            this.setState({playersDrop})
        }
    }

    /**
     * Handle picks to be traded
     * @param {object} picksToTrade - array of picks to be traded
     */
    handlePicksToTrade(picksToTrade) {
        if (picksToTrade == null) {
            this.setState({
                picksToTrade:[]
            })
        }
        else{
            this.setState({picksToTrade})
        }
    }

    /**
     * Handle picks to be acquired
     * @param {object} picksToAcquire - array of picks to be acquired
     */
    handlePicksToAcquire(picksToAcquire) {
        if (picksToAcquire == null) {
            this.setState({
                picksToAcquire:[]
            })
        }
        else{
            this.setState({picksToAcquire})
        }
    }

    // Toggle allow picks to be traded
    togglePicksTradeAway() {
        if(this.state.picksTradeAway){
            this.setState({
                picksToTrade:null,
                picksTradeAway: !this.state.picksTradeAway
            })
        }
        else {
            this.setState({
                picksTradeAway: !this.state.picksTradeAway
            })
        }
        
    }

    // Toggle allow picks to be acquired
    togglePicksAcquire() {
        if(this.state.picksAcquire){
            this.setState({
                picksToAcquire:null,
                picksAcquire: !this.state.picksAcquire
            })
        }
        else {
            this.setState({
                picksAcquire: !this.state.picksAcquire
            })
        }
    }

    // Toggle allow players to be traded
    togglePlayerTradeAway() {
        if(this.state.playersTradeAway){
            this.setState({
                yourPlayer:null,
                playersTradeAway: !this.state.playersTradeAway
            })
        }
        else {
            this.setState({
                playersTradeAway: !this.state.playersTradeAway
            })
        }
        
    }

    // Toggle allow players to be acqired
    togglePlayerAcquire() {
        if(this.state.playersAcquire){
            this.setState({
                theirPlayer:null,
                playersAcquire: !this.state.playersAcquire
            })
        }
        else {
            this.setState({
                playersAcquire: !this.state.playersAcquire
            })
        }
    }

    // Render the trade creation form based on status
    renderForm() {
        if(this.state.needDrop){
            return(
                this.dropPlayersForm()
            )
        }
        else {
            return (
                this.selectPlayersForm()
            )
        }
    }

    // Render form to drop players
    dropPlayersForm() {
        const {playersDrop} = this.state
        return (
            <>
                <p className='text-danger'>You need to drop atleast {this.state.numbersToDrop} players</p>
                <Label className='mb-0 mt-2' for='dropPlayers'>Drop Players: <span className='small'>(Selected: {playersDrop? playersDrop.length:0})</span></Label>
                <Select menuPortalTarget={document.body} getOptionValue={option => option.playerId} getOptionLabel={option => option.playerName} options={this.state.playersDropList} value={playersDrop} onChange={this.handlePlayersDropList} className="" styles={STYLES.REACT_SELECT} isMulti={true} closeMenuOnSelect={false} />
            </>
        )
    }

    // Render form to select players/picks
    selectPlayersForm() {
        const {yourPlayer, theirPlayer} = this.state
        return (
            <>
                {this.tradePlayerForm()}
                {this.tradePicksForm()}
                {this.acquirePlayerForm()}
                {this.acquirePicksForm()}
            </>
        )
    }

    // Trade players form
    tradePlayerForm() {
        const yourPlayer = this.state.yourPlayer
        const Colors = this.context
        if(this.state.playersTradeAway) {
            return (
                <>
                    <div className='d-flex flex-row align-items-center'>
                        <i class="fa fa-minus-circle mr-2 mt-4 text-danger" onClick={this.togglePlayerTradeAway} style={{cursor:'pointer'}} aria-hidden="true"></i>
                        <div className='flex1'>
                        <Label className='mb-0 mt-2' for='yourPlayer'>Your Player</Label>
                        <Select menuPortalTarget={document.body} getOptionValue={option => option.playerId} getOptionLabel={option => option.playerName} options={this.state.tradeData.my.players} value={yourPlayer}  onChange={this.handleYourPlayer} className="" styles={STYLES.REACT_SELECT} isMulti={true} closeMenuOnSelect={false} />
                        </div>
                    </div>

                    
                </>
            )
        }
        else {
            return (
                <p className='pt-1'><i class="fa fa-plus-circle" onClick={this.togglePlayerTradeAway} style={{cursor:'pointer', color:Colors.colorLink}} aria-hidden="true"></i> Add Your Player</p>
            )
            
        }
    }

    // Acquire players form
    acquirePlayerForm() {
        const theirPlayer = this.state.theirPlayer
        const Colors = this.context
        if(this.state.playersAcquire) {
            return (
                <>
                    <div className='d-flex flex-row align-items-center'>
                        <i class="fa fa-minus-circle mr-2 mt-4 text-danger" onClick={this.togglePlayerAcquire} style={{cursor:'pointer'}} aria-hidden="true"></i>
                        <div className='flex1'>
                        <Label className='mb-0 mt-2' for='theirPlayer'>Their Player</Label>
                        <Select menuPortalTarget={document.body} getOptionValue={option => option.playerId} getOptionLabel={option => option.playerName} options={this.state.tradeData.opp.players} value={theirPlayer} onChange={this.handleTheirPlayer} className="" styles={STYLES.REACT_SELECT} isMulti={true} closeMenuOnSelect={false} />
                        </div>
                    </div>
                </>
            )
        }
        else {
            return (
                <p className='pt-1'><i class="fa fa-plus-circle" onClick={this.togglePlayerAcquire} style={{cursor:'pointer', color:Colors.colorLink}} aria-hidden="true"></i> Add Your Player</p>
            )
            
        }
    }

    // Trade picks form
    tradePicksForm() {
        const picksToTrade = this.state.picksToTrade
        const Colors = this.context
        if(this.state.picksTradeAway) {
            return (
                <>
                    {/* <Label className='mb-0 mt-2' for='picksTrade'>Draft Picks Trade Away</Label>
                    <div className='d-flex flex-row align-items-center'>
                        <Select getOptionValue={option => option.id} getOptionLabel={option => option.pick} options={this.state.tradeData.my.picks} value={picksToTrade}  onChange={this.handlePicksToTrade} className=" flex1" styles={STYLES.REACT_SELECT} isMulti={true} closeMenuOnSelect={false} />
                        <i class="fa fa-minus-circle ml-2 text-danger" onClick={this.togglePicksTradeAway} aria-hidden="true"></i>
                    </div> */}

                    <div className='d-flex flex-row align-items-center'>
                        <i class="fa fa-minus-circle mr-2 mt-4 text-danger" onClick={this.togglePicksTradeAway} style={{cursor:'pointer'}} aria-hidden="true"></i>
                        <div className='flex1'>
                            <Label className='mb-0 mt-2' for='picksTrade'>Draft Picks Trade Away</Label>
                            <Select menuPortalTarget={document.body} getOptionValue={option => option.id} getOptionLabel={option => option.pick} options={this.state.tradeData.my.picks} value={picksToTrade}  onChange={this.handlePicksToTrade} className="" styles={STYLES.REACT_SELECT} isMulti={true} closeMenuOnSelect={false} />
                        </div>
                    </div>

                    
                </>
            )
        }
        else {
            return (
                <p className='pt-1'><i class="fa fa-plus-circle" onClick={this.togglePicksTradeAway} style={{cursor:'pointer', color:Colors.colorLink}} aria-hidden="true"></i> Include Draft Picks</p>
            )
            
        }
    }

    // Acquire picks form
    acquirePicksForm() {
        const picksToAcquire = this.state.picksToAcquire
        const Colors = this.context
        if(this.state.picksAcquire) {
            return (
                    <>
                        <div className='d-flex flex-row align-items-center'>
                            <i class="fa fa-minus-circle mr-2 mt-4 text-danger" onClick={this.togglePicksAcquire} style={{cursor:'pointer'}} aria-hidden="true"></i>
                            <div className='flex1'>
                                <Label className='mb-0 mt-2' for='picksAcquire'>Acquire Draft Picks</Label>
                                <Select menuPortalTarget={document.body} getOptionValue={option => option.id} getOptionLabel={option => option.pick} options={this.state.tradeData.opp.picks} value={picksToAcquire}  onChange={this.handlePicksToAcquire} className="" styles={STYLES.REACT_SELECT} isMulti={true} closeMenuOnSelect={false} />
                            </div>
                        </div>
                    </>
                )
        }
        else {
            return (
                <p className='pt-1'><i class="fa fa-plus-circle" onClick={this.togglePicksAcquire} style={{cursor:'pointer', color:Colors.colorLink}} aria-hidden="true"></i> Acquire Draft Picks</p>
            )
        }
    }

    /**
     * Allow trade to be sent based on conditions to make sure the trade is not one sided but exchange on both sides
     * @returns (jsx) - SEND button
     */
    sendTradeButton() {
        // Conditionals {Bool}
        let {picksAcquire, picksTradeAway, playersAcquire, playersTradeAway} = this.state
        let tradingSomethingAway = false
        let acquiringSomething = false

        // Data {Object}
        let {yourPlayer, theirPlayer, picksToAcquire, picksToTrade} = this.state

        // Trading away something
        if (playersTradeAway || picksTradeAway) {
            if ((playersTradeAway && yourPlayer) && !picksTradeAway ) {
                tradingSomethingAway = true
            } else if ((picksTradeAway && picksToTrade) && !playersTradeAway) {
                tradingSomethingAway = true
            } else if ((playersTradeAway && yourPlayer) && (picksTradeAway && picksToTrade)) {
                tradingSomethingAway = true
            }
        }
        else {
            tradingSomethingAway = false
        }
        
        // Acquiring something
        if (playersAcquire || picksAcquire) {
            if ((playersAcquire && theirPlayer) && !picksAcquire ) {
                acquiringSomething = true
            } else if ((picksAcquire && picksToAcquire) && !playersAcquire) {
                acquiringSomething = true
            } else if ((playersAcquire && theirPlayer) && (picksAcquire && picksToAcquire)) {
                acquiringSomething = true
            }
        }
        else {
            acquiringSomething = false
        }
        
        if(tradingSomethingAway && acquiringSomething) {
            return (
                <Button onClick={this.send} className='w-100 mt-2 d-block' outline> Send </Button>
            )
        }
    }

    /**
     * Get proposal message string identifying the status of proposal for each chat participant
     * @returns - proper message indicating who took what action on proposal
     */
    proposalInfo() {
        const Colors = this.context
        if(this.props.status == 'TRADE_PROPOSAL') {
            return(
                <p  style={{cursor:'pointer'}} >You are creating a <span className='bold'>trade proposal</span> with <span style={{color:Colors.colorLink}}>@{this.props.data.attachments[0].data.oppName}</span>. Start building your proposal below.</p>
            )
        }
        else if (this.props.status == 'TRADE_SENT') {
            if(this.props.data.user.id == this.props.match.params.id) {
                return (
                    <p> 🔃 <span style={{color:Colors.colorLink}}> @{this.props.data.attachments[0].data.senderName} </span> sent you a new trade proposal:</p>
                )
            }
            else {
                return (
                    <p>You sent the following trade proposal to <span style={{color:Colors.colorLink}}>@{this.props.data.attachments[0].data.receiverName}</span></p>
                )
                
            }
        }
        else if (this.props.status == 'TRADE_CANCELLED') {
            if(this.props.data.user.id == this.props.match.params.id) {
                return (
                    <p> <span style={{color:Colors.colorLink}}> @{this.props.data.attachments[0].data.senderName} </span> cancelled this trade:</p>
                )
            }
            else {
                return (
                    <p>You cancelled this trade:</p>
                )
                
            }
        }
        else if (this.props.status == 'TRADE_ACCEPTED') {
            if(this.props.data.user.id == this.props.match.params.id) {
                return (
                    <p> Things are looking up! <span style={{color:Colors.colorLink}}> @{this.props.data.attachments[0].data.receiverName} </span> accepted the following trade proposal:</p>
                )
            }
            else {
                return (
                    <p> Ok! You've accepted the following trade from <span style={{color:Colors.colorLink}}> @{this.props.data.attachments[0].data.senderName} </span>: </p>
                )
                
            }
        }

        else if (this.props.status == 'TRADE_REJECTED') {
            if(this.props.data.user.id == this.props.match.params.id) {
                return (
                    <p> <span style={{color:Colors.colorLink}}> @{this.props.data.attachments[0].data.receiverName} </span> rejected this trade:</p>
                )
            }
            else {
                return (
                    <p>You rejected this trade:</p>
                )
                
            }
        }
        
    }

    render() { 
        const Colors = this.context;
        return ( 
            <div className='pl-2 mt-2' style={{maxWidth:'400px',borderLeft:`5px solid ${Colors.darkBgBorder}`}}>
                {this.proposalInfo()}
                {
                    
                    (() => {
                        switch (this.props.status){
                            case 'TRADE_PROPOSAL': // Trade sent and stored locally
                                return(
                                        <Form style={{maxWidth:'100%'}}>
                                            <FormGroup>
                                                <LoadingOverlay 
                                                    active={this.state.loader}
                                                    spinner
                                                    text='Fetching...'
                                                    fadeSpeed={500}
                                                    styles={{
                                                    overlay: (base) => ({
                                                        ...base,
                                                        background: 'rgba(0,0,0,0.5)'
                                                        })
                                                    }}
                                                >
                                                {this.renderForm()}
                                                </LoadingOverlay>
                                                {
                                                    this.state.needDrop ?
                                                    <Button className='w-100 mt-2 d-block' onClick={this.goBack} outline> Back </Button>
                                                    :
                                                    <Button className='w-100 mt-2 d-block' onClick={this.delete} outline> Delete </Button>
                                                }

                                                { this.sendTradeButton() }
                                            </FormGroup>
                                        </Form>
                                    )

                            case 'TRADE_SENT':  // Trade sent to server
                                return(   
                                    <>
                                        {/* Sender */}
                                        {
                                            this.state.needDrop ?
                                                this.dropPlayersForm()
                                            :
                                            <>
                                                <div className='my-2'>
                                                    <div className='flex1'>
                                                            <h5>{ this.props.data.attachments[0].data.senderName }</h5>
                                                            <p>{ this.props.data.attachments[0].data.myPlayers.toString() }</p>

                                                            {
                                                                this.props.data.attachments[0].data.myPicks.length != 0 ?
                                                                <>
                                                                    <h5>Picks</h5>
                                                                    <p>{ this.props.data.attachments[0].data.myPicks.toString() }</p>
                                                                </>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                </div>
                            
                                                <i>for</i>
                            
                                                {/* Receiever */}
                                                <div className='my-2'>
                                                    <div className='flex1'>
                                                        <h5>{ this.props.data.attachments[0].data.receiverName }</h5>
                                                        <p>{ this.props.data.attachments[0].data.oppPlayers.toString() }</p>
                                                        {
                                                            this.props.data.attachments[0].data.oppPicks.length != 0 ?
                                                            <>
                                                                <h5>Picks</h5>
                                                                <p>{ this.props.data.attachments[0].data.oppPicks.toString() }</p>
                                                            </>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        }
                                            
                                        {
                                            this.state.needDrop ?
                                                <>
                                                        <Button onClick={this.dropAndAccept} className='w-100 my-1 d-block' style={{maxWidth:'400px'}} outline> Drop & Accept </Button>
                                                        <Button onClick={this.goBack} className='w-100 my-1 d-block' style={{maxWidth:'400px'}} outline> Go Back </Button>
                                                </>
                                            :
                                                this.props.data.user.id == this.props.match.params.id ? 
                                                    <>
                                                        <Button onClick={this.acceptProposal} className='w-100 my-1 d-block' style={{maxWidth:'400px', color: Colors.colorLink, backgroundColor: Colors.colorPrimaryTransparent ,border: `1px solid ${Colors.colorLink}`}} outline> Accept </Button>
                                                        <Button onClick={this.rejectProposal} className='w-100 my-1 d-block' style={{maxWidth:'400px', color: Colors.colorDanger, backgroundColor: Colors.colorDangerTransparent ,border: `1px solid ${Colors.colorDanger}`}} outline> Reject </Button>
                                                    </>
                                                :
                                                    <Button onClick={this.cancelProposal} className='w-100 my-1 d-block' style={{maxWidth:'400px'}} outline> Cancel </Button>
                                        }
                                    </>
                                )
                            case 'TRADE_CANCELLED': // Trade cancelled by sender
                            case 'TRADE_ACCEPTED':  // Trade accepted be receiver
                            case 'TRADE_REJECTED':  // Trade rejected be receiver
                                return (
                                    <>
                                        {/* Sender */}
                                        <div className='my-2'>
                                            <div className='flex1'>
                                                <h5>{ this.props.data.attachments[0].data.senderName }</h5>
                                                <p>{ this.props.data.attachments[0].data.myPlayers.toString() }</p>
                                                {
                                                    this.props.data.attachments[0].data.myPicks.length != 0 ?
                                                    <>
                                                        <h5>Picks</h5>
                                                        <p>{ this.props.data.attachments[0].data.myPicks.toString() }</p>
                                                    </>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                    
                                        <i>for</i>
                    
                                        {/* Receiever */}
                                        <div className='my-2'>
                                            <div className='flex1'>
                                                <h5>{ this.props.data.attachments[0].data.receiverName }</h5>
                                                <p>{ this.props.data.attachments[0].data.oppPlayers.toString() }</p>
                                                {
                                                    this.props.data.attachments[0].data.oppPicks.length != 0 ?
                                                    <>
                                                        <h5>Picks</h5>
                                                        <p>{ this.props.data.attachments[0].data.oppPicks.toString() }</p>
                                                    </>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            default:
                                return('Something went wrong, TRADE TEMPLATE CODE: ', this.state.template)
                        }
                    })()
                }
            </div>
         );
    }
}

const mapStateToProps = (state) => ({leagueId: state.leagueJoin.leagueId})
export default connect(mapStateToProps)(withRouter(Trade));