import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { Colors } from '../../../helpers/colors';
import { leagueSettings } from '../../../apis/SeasonLong/Settings/SettingsAPI'
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import GeneralSettings from '../../../components/SeasonLong/Settings/GeneralSettings';
import ScoringSettings from '../../../components/SeasonLong/Settings/ScoringSettings';
import ScheduleSettings from '../../../components/SeasonLong/Settings/ScheduleSettings';
import RosterSettings from '../../../components/SeasonLong/Settings/RosterSettings';
import DraftSettings from '../../../components/SeasonLong/Settings/DraftSettings';
import MemberSettings from '../../../components/SeasonLong/Settings/MemberSettings';
import { ColorContext } from '../../../context/ColorProvider';

class LeagueSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 'default',
            loading: true,
            default: 'default',
            data: {},
            isValid: false,
            isCommisioner: null,
        }
        this.noRefCheck = this
            .noRefCheck
            .bind(this)
    }
    static contextType = ColorContext; 

    componentDidMount() {
        this.API()
    }

    gotoPage(page) {
        this.setState({ page: page })
    }

    API() {
        leagueSettings(this.props.leagueId).then((resp) => {
            this.setState({ data: resp.data, isCommisioner: resp.data.isCommissioner })
            this.setState({ loading: false, isValid: false })
        }).catch((err) => {
            this.setState({ errors: err, loading: false, isValid: true })
        })
    }

    goBack() {
        // this.setState({loading: true})
        this.setState({ page: this.state.default });
        // this.API()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.leagueId !== this.props.leagueId) {
            this.setState({ page: this.state.default, loading: true })
            this.API()
        }
    }

    noRefCheck() {
        this.setState({
            isValid: !this.state.isValid
        })
    }

    render() {
        const Colors = this.context;
        const { options_data, steps_data } = this.state.data

        return (this.state.isValid
            ? (
                <Row className='p-0 m-0 h-100 d-flex'>
                    <div className='text-center m-auto'>
                        <h1
                            style={{
                                color: Colors.colorPrimary
                            }}>Uh oh!</h1>
                        <p>Something went
                            <strong>Wrong</strong>
                            try again later.</p>
                    </div>
                </Row>
            )
            : <LoadingOverlay
                active={this.state.loading}
                spinner
                text='Loading...'
                fadeSpeed={1000}
                className="d-flex flex-column flex1 scrollHidden"
            >
                <div className='d-flex flex-column flex1 scrollHidden'>
                    <div style={{ borderBottom: `0.01em solid ${Colors.darkBgBorder}`, padding: '5px 0px 5px 0px' }}>
                        <h2 style={{ marginLeft: '30px' }}>League Settings</h2>
                    </div>
                    {(() => {
                        switch (this.state.page) {
                            case 'default':
                                return (
                                    <div className="row p-4">
                                        <div
                                            onClick={() => this.gotoPage('general-settings')}
                                            className="col-md-3 col-sm-6 py-3 m-4"
                                            style={{
                                                cursor: 'pointer',
                                                backgroundColor: Colors.bgLight,
                                                borderRadius: 10
                                            }}>
                                            <h3>General Settings</h3>
                                            <p>Manage general settings.</p>
                                        </div>

                                        <div
                                            onClick={() => this.gotoPage('scoring-settings')}
                                            className="col-md-3 col-sm-6 py-3 m-4"
                                            style={{
                                                cursor: 'pointer',
                                                backgroundColor: Colors.bgLight,
                                                borderRadius: 10
                                            }}>
                                            <h3>Scoring Settings</h3>
                                            <p>Manage scoring settings.</p>
                                        </div>

                                        <div
                                            onClick={() => this.gotoPage('roster-settings')}
                                            className="col-md-3 col-sm-6 py-3 m-4"
                                            style={{
                                                cursor: 'pointer',
                                                backgroundColor: Colors.bgLight,
                                                borderRadius: 10
                                            }}>
                                            <h3>Roster Settings</h3>
                                            <p>Manage roster size and position.</p>
                                        </div>

                                        <div
                                            onClick={() => this.gotoPage('schedule-settings')}
                                            className="col-md-3 col-sm-6 py-3 m-4"
                                            style={{
                                                cursor: 'pointer',
                                                backgroundColor: Colors.bgLight,
                                                borderRadius: 10
                                            }}>
                                            <h3>Schedule Settings</h3>
                                            <p>Set League Schedule.</p>
                                        </div>

                                        <div
                                            onClick={() => this.gotoPage('draft')}
                                            className="col-md-3 col-sm-6 py-3 m-4"
                                            style={{
                                                cursor: 'pointer',
                                                backgroundColor: Colors.bgLight,
                                                borderRadius: 10
                                            }}>
                                            <h3>Draft</h3>
                                            <p>Manage draft settings.</p>
                                        </div>

                                        <div
                                            onClick={() => this.gotoPage('members')}
                                            className="col-md-3 col-sm-6 py-3 m-4"
                                            style={{
                                                cursor: 'pointer',
                                                backgroundColor: Colors.bgLight,
                                                borderRadius: 10
                                            }}>
                                            <h3>Members</h3>
                                            <p>Manage league members.</p>
                                        </div>

                                        <div
                                            className="col-md-3 col-sm-6 py-3 m-4"
                                            style={{
                                                cursor: 'pointer',
                                                backgroundColor: Colors.bgLight,
                                                borderRadius: 10
                                            }}>
                                            <h3>Edit Rosters</h3>
                                            <p>Add, drop, trade, etc.</p>
                                        </div>

                                        <div
                                            className="col-md-3 col-sm-6 py-3 m-4"
                                            style={{
                                                cursor: 'pointer',
                                                backgroundColor: Colors.bgLight,
                                                borderRadius: 10
                                            }}>
                                            <h3>Edit Results</h3>
                                            <p>-</p>
                                        </div>

                                        <div
                                            className="col-md-3 col-sm-6 py-3 m-4"
                                            style={{
                                                cursor: 'pointer',
                                                backgroundColor: Colors.bgLight,
                                                borderRadius: 10
                                            }}>
                                            <h3>Rosters</h3>
                                            <p>-</p>
                                        </div>

                                        <div
                                            className="col-md-3 col-sm-6 py-3 m-4"
                                            style={{
                                                cursor: 'pointer',
                                                backgroundColor: Colors.bgLight,
                                                borderRadius: 10
                                            }}>
                                            <h3>Commisioner Powers</h3>
                                            <p>-</p>
                                        </div>
                                    </div>
                                );
                            case 'general-settings':
                                return (<GeneralSettings
                                    options_data={options_data}
                                    steps_data={steps_data}
                                    loading={this.state.loading}
                                    onBackHandler={() => this.goBack()}
                                    leagueId={this.props.leagueId} 
                                    isCommisioner={this.state.isCommisioner}
                                    />);
                            case 'scoring-settings':
                                return (<ScoringSettings
                                    options_data={options_data}
                                    steps_data={steps_data}
                                    loading={this.state.loading}
                                    onBackHandler={() => this.goBack()}
                                    leagueId={this.props.leagueId} />);
                            case 'schedule-settings':
                                return (<ScheduleSettings
                                    options_data={options_data}
                                    steps_data={steps_data}
                                    onBackHandler={() => this.goBack()}
                                    leagueId={this.props.leagueId} />);

                            case 'roster-settings':
                                return (<RosterSettings
                                    options_data={options_data}
                                    steps_data={steps_data}
                                    onBackHandler={() => this.goBack()}
                                    leagueId={this.props.leagueId} />);
                            case 'draft':
                                return (<DraftSettings
                                    options_data={options_data}
                                    steps_data={steps_data}
                                    onBackHandler={() => this.goBack()}
                                    leagueId={this.props.leagueId} />);

                            case 'members':
                                return (<MemberSettings
                                    options_data={options_data}
                                    steps_data={steps_data}
                                    onBackHandler={() => this.goBack()} />);

                            case 'edit-rosters':
                                return (
                                    <div className="row flex1 align-items-center justify-content-center p-4 mb-4">
                                        <div className="text-center col-md-6 col-sm-12">
                                            <h1>Edit Rosters Page</h1>
                                            <p>The edit rosters settings will go here.</p>
                                        </div>
                                    </div>
                                );

                            case 'edit-results':
                                return (

                                    <div className="row flex1 align-items-center justify-content-center p-4 mb-4">
                                        <div className="text-center col-md-6 col-sm-12">
                                            <h1>Edit Results</h1>
                                            <p>The edit results settings will go here.</p>
                                        </div>
                                    </div>
                                );

                            case 'edit-rosters':
                                return (

                                    <div className="row flex1 align-items-center justify-content-center p-4 mb-4">
                                        <div className="text-center col-md-6 col-sm-12">
                                            <h1>Draft Page</h1>
                                            <p>The draft will go here.</p>
                                        </div>
                                    </div>
                                );

                            case 'rosters':
                                return (

                                    <div className="row flex1 align-items-center justify-content-center p-4 mb-4">
                                        <div className="text-center col-md-6 col-sm-12">
                                            <h1>Rosters Page</h1>
                                            <p>The rosters will go here.</p>
                                        </div>
                                    </div>
                                );

                            case 'comissioner-powers':
                                return (

                                    <div className="row flex1 align-items-center justify-content-center p-4 mb-4">
                                        <div className="text-center col-md-6 col-sm-12">
                                            <h1>Commisioners Page</h1>
                                            <p>The commisioners will go here.</p>
                                        </div>
                                    </div>
                                );
                            default:
                                return (

                                    <div
                                        className="row flex1 align-items-center justify-content-center p-4 mb-4"
                                        style={{
                                            backgroundColor: Colors.bgDark,
                                            borderRadius: 15
                                        }}>
                                        <div className="text-center col-md-6 col-sm-12" id='invitation-prompt'>
                                            <h1>404</h1>
                                            <p>This Page is Not Completed Yet</p>
                                        </div>
                                    </div>
                                );
                        }

                    })()}
                </div>
            </LoadingOverlay>)
    }
}
const mapStateToProps = (state) => ({ leagueId: state.leagueJoin.leagueId })
export default connect(mapStateToProps, null)(LeagueSettings);