import React, { useContext, useState } from 'react';
import { ColorContext } from '../../../context/ColorProvider';
import { prepareArenaOpps } from '../../../helpers/Arena/ArenaHelper';

import { parseInjuryIcons } from '../../../helpers/common-methods';
import { injuryStatusDoubtful, injuryStatusOut, injuryStatusQuestionable } from '../../../helpers/icons';
import ArenaPlayerModal from '../../../modals/Arena/ArenaPlayerModal';
import LineupPlayerRowNba from './LineupPlayerRowNba';

const LineupPlayerRow = (props) => {
	const Colors = useContext(ColorContext);

	const { player, sport } = props
	const [playerModal, setPlayerModal] = useState(false)

	const toggle = () => {
		setPlayerModal(!playerModal)
	}

	return (
		sport == 'NBA' || sport == "MLB" ?
		<LineupPlayerRowNba {...props} />
		:
		<div onClick={toggle} className="d-flex align-items-center px-2 mx-3 my-1" style={{ borderRadius: 5, backgroundColor: Colors.arenaLineupPlayerRow }}>
			<h6 className="mx-1 my-0" style={{width:30}}>{player.pos}</h6>
			<div className="d-flex align-items-center" style={{flex: 1.5}} >
				<div className="mx-1 position-relative" style={{ overflow: 'hidden', maxWidth: '40px', maxHeight: '100%' }}>
					<img className="img-responsive rounded-circle my-1" src={player.image} width="100%" height="40px" style={{ objectFit: 'cover' }} />
					<div style={{position: "absolute", right: 0, bottom: 0}}  >
						{parseInjuryIcons(player.injuryStatus)}
					</div>
				</div>
				<p className="my-0 mx-2">{player.fullName}</p>
			</div>
			<p className="m-0 text-right" style={{ color: Colors.colorSecondary, flex: 1 }}>
				{prepareArenaOpps(player.opps)}
			</p>
			{sport == "NFL" && <p className="m-0 text-right bold greenText" style={{flex: .3 }}>
				{player.points}
			</p>}
			{playerModal && <ArenaPlayerModal
				modal={playerModal}
				playerId={player.playerId}
				toggle={toggle}
			/>}
		</div>
	);
};

export default LineupPlayerRow;
