import React, { useContext, useEffect, useState } from 'react'
import MainSidebar from './MainSidebar'
import { updateActiveExperience } from '../../apis/User/UserAPI'
import MatchmakerLayout from '../MatchmakerLayout/MatchmakerLayout'
import V2Layout from '../V2Layout/V2Layout'
import store from '../../store'
import { SAVE_APPLICATION_STATUS } from '../../apis/Sidebar/LeagueAPI'
import { ColorContext } from '../../context/ColorProvider'
import InviteMembersBanner from '../../components/LeagueImport/InviteMembersBanner'
import { ARENA } from '../../helpers/arena_sports_mapping'
import Media from 'react-media'
import { Button, Col, Navbar, NavbarBrand, Row } from 'reactstrap'
import logo from '../../assets/images/logo_trans.png';
import { useHistory } from 'react-router-dom'
import { getStore } from '../../helpers/common-methods'
import { logoutUser } from '../../actions/auth'
import Marquee from '../../components/Marquee'
import { bettleMarIcon } from '../../helpers/icons'
import SplashModalLayout from '../../modals/SplashModals/SplashModalLayout'
import { useSelector } from 'react-redux'
import ArenaProfile from '../../pages/Matchmaker/ArenaProfile/ArenaProfile'

const MainLayout = (props) => {
    const {Component, exp } = props
    const history = useHistory()
    const authUser = useSelector((state) => state.auth.user);
    const [splashModal, setSplashModal] = useState(true);
    const [showProposal, setShowProposal] = useState(false);
    const [showCompletedMatches, setShowCompletedMatches] = useState(false);
    const [profileModal, setProfileModal] = useState(false);

    // const [loading, setLoading] = useState(true)
    const Colors = useContext(ColorContext);
	const updateExperience = () => {
        var experience = store.getState().leagueJoin.app_status
        if(exp == "ARENA" && experience == "SEASON_LONG"){
            const { sport } = store.getState().arenaReducer
            const sportName = sport != undefined ? ARENA.SPORT_NAME_FROM_SPORT[sport]  : "BASKETBALL"
            experience = `${sportName}_ARENA`
        }else if(exp == "SL" && (experience == "FOOTBALL_ARENA" || experience == "BASEBALL_ARENA" || experience == "BASKETBALL_ARENA")){
            experience = "SEASON_LONG"
        }
        store.dispatch({ type: SAVE_APPLICATION_STATUS, app_status: experience });
        updateActiveExperience({
            activeExp: experience
        })
	};

	useEffect(() => {
		updateExperience()
	}, [exp])

    const stateCallback = (type, value) => {
        if (type == "showProposal") {
            setShowProposal(value);
        } else if (type == "showCompletedMatches") {
            setShowCompletedMatches(value);
        } else if (type == "profileModal") {
            setProfileModal(value);
        }
    };

    return (
        <div className="app d-flex" style={{ overflow: 'hidden', height: '100vh' }}>

            <Row className='d-flex flex-column px-2' style={{ height: '100vh', overflow: 'auto' }}>
                <Navbar style={{ backgroundColor: "#1D2226" }} className='w-100 shadow-sm d-flex justify-content-between'>
                    <NavbarBrand href="/">
                        <img src={logo} width="180" height="auto" alt="Bettle Inc" className="ml-4" />
                    </NavbarBrand>
                    <Button
                        style={{ fontSize: '16px' }}
                        className="green-btn btn-pill bold btn btn-secondary px-4"
                        onClick={() => {
                            getStore().dispatch(logoutUser(() => { })).then(() => {
                                history.push("/signin")
                            })
                        } }
                    >
                        Logout
                    </Button>
                </Navbar>
                <Col className="d-flex flex1 flex-column" style={{ overflow: 'auto', height: '100vh', backgroundColor: Colors.bgDark }}>
                    <props.Component {...props} />
                </Col>
            </Row>

            {/* <Media query="(max-width: 767px)">
                {matches =>
                    {
                            return matches ?
                                <Row className='d-flex flex-column px-2' style={{ height: '100vh', overflow: 'scroll' }}>
                                    <Navbar style={{ backgroundColor: "#1D2226" }} className='w-100 shadow-sm d-flex justify-content-between'>
                                        <NavbarBrand href="/">
                                            <img src={logo} width="180" height="auto" alt="Bettle Inc" className="ml-4" />
                                        </NavbarBrand>
                                        <Button
                                            style={{ fontSize: '16px' }}
                                            className="green-btn btn-pill bold btn btn-secondary px-4"
                                            onClick={() => {
                                                getStore().dispatch(logoutUser(() => { })).then(() => {
                                                    history.push("/signin")
                                                })
                                            } }
                                        >
                                            Logout
                                        </Button>
                                    </Navbar>
                                    <Col className="d-flex flex1 flex-column" style={{ overflow: 'scroll', height: '100vh', backgroundColor: Colors.bgDark }}>
                                        <props.Component {...props} />
                                    </Col>
                                </Row>
                                :
                                <>
                                 
                                    <div className='d-flex' style={{ height: '100vh' }}>
                                        {exp !== "MOB" && <MainSidebar stateCallback={stateCallback} />}
                                        <div className="d-flex flex1 flex-column pl-0" style={{ height: '100vh', width: "100vh", backgroundColor: Colors.bgDark }}>
                                            {exp == "SL" && <InviteMembersBanner />}
                                            <props.Component {...props} showProposal={showProposal} showCompletedMatches={showCompletedMatches} />
                                        </div>
                                    </div>
                                    {splashModal && authUser.unread_notification !== null && <SplashModalLayout isOpen={splashModal} toggle={() => setSplashModal(!splashModal)} status={authUser?.unread_notification} stateCallback={stateCallback} />}
                                    {profileModal && <ArenaProfile isOpen={profileModal} toggle={() => setProfileModal(!profileModal)} />}
                                </>
                        }
                }
            </Media> */}
        </div>
    )
}

export default MainLayout