import React from 'react';
import { Button, UncontrolledCollapse, Card, CardBody } from 'reactstrap';
import { Colors } from '../../../../helpers/colors';
import PerfectScrollbar from 'react-perfect-scrollbar';

function ArenaGeneralRule(props) {

  const data = [
    "The MatchMaker arena hosts matches between season-long fantasy teams. The arena currently supports head-to-head matchups, where we standardize lineups, scoring and rules so teams from different leagues can compete. Multiplayer tournaments are coming soon.",
    "All rosters imported onto Bettle are entered into the MatchMaker arena.",
    "To participate in MatchMaker matches, users provide Max Bet size, Active Bankroll exposure, and the team and starting lineup to use in matches.",
    "Once onboarded, users who enter the arena have the ability to accept or reject the potential matchups presented to them.",
    "Once a potential matchup is accepted by User One, they are presented the match details screen, where entry fee, odds boost, and start date are proposed. When the details have been finalized, a proposed match is sent to User Two.",
    "User Two has the ability to accept, counter, or reject User One’s proposed match. If a proposed match is accepted, we have a confirmed match! If User Two counters with new bet details, User One has one last chance to accept, counter, or reject the proposed match. User two does not get the chance to counter. The match must either be accepted or rejected at this point.",
    "Lineups lock once a match is accepted. Users do not have the ability to modify their lineup after this point. In the case where a player is declared inactive before a match begins."
  ];

  const List = (props) => {
    return (
      props.data && props.data.map((list, index) => (
        <ul key={index}>
          <li>{list}</li>
        </ul>
      ))
    )
  };

  return (
    <div className='d-flex flex-column flex1 scrollHidden'>
      {props.slug == "MatchMakerRules" ?
        <>
          <h5 className='bold' style={{ fontSize: 20 }}>General</h5>
          <List data={data} />
        </>
        :
        <>
          <div className='d-flex justify-content-start align-items-center px-3 my-2' style={{ borderBottom: `1px solid ${Colors.darkBgBorder}`, cursor: 'pointer' }} onClick={() => props.setView('default')}>
            <h5><span> <i className='mr-2 fa fa-angle-left'></i></span> General</h5>
          </div>
          <PerfectScrollbar>
            <Card className='border-0 mb-0 mx-3' style={{ background: Colors.bgLight }}>
              <CardBody style={{ overflow: 'scroll', height: '100%' }}>
                <p className='bold' style={{ fontSize: 20 }}>General</p>
                <List data={data} />
              </CardBody>
            </Card>
          </PerfectScrollbar>
        </>
      }
    </div>
  )
}

export default ArenaGeneralRule;