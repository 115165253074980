import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { Colors } from '../../../helpers/colors';
import { get_ordinal_suffix_of } from '../../../helpers/common-methods';
import { prepareLiveDSTData } from '../../../helpers/DTScoringHelper';
import { generatePoints } from '../../../helpers/matchup-helpers';
import { mlbGeneratePoints, mlbH2HEachCatPoints } from '../../../helpers/MLB/Points';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { ColorContext } from '../../../context/ColorProvider';

class SLMatchup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            team1Points: 0,
            team2Points: 0,
            m_id:null,
            matchup: null,
            sc_rules: null,
        }

        // this.calculatePoints = this.calculatePoints.bind(this)
    }
    static contextType = ColorContext;

    componentDidMount(){
        this.setState({
            matchup: this.props.matchup,
            m_id: this.props.matchup.matchup_id,
            sc_rules: this.props.sc_rules,
            sport: this.props.activeLeague.sport,
            scoringFormat: this.props.activeLeague.scoringFormat
        })
    }

    componentDidUpdate(prevProps) {
        // Handle updates here
        if(prevProps.loading != this.props.loading){
            this.setState({
                matchup: this.props.matchup,
                m_id: this.props.matchup.matchup_id,
                sc_rules: this.props.sc_rules,
                sport: this.props.activeLeague.sport,
                scoringFormat: this.props.activeLeague.scoringFormat
            })

            setTimeout(() => {
                    this.calculatePoints()
                    if(this.props.activeLeague.sport == "NFL"){
                        this.generateChannels()
                    }
            }, 0);
        }
    }
    
    fetchMatchup(){

    }

    generateChannels = (matchup = this.props.matchup) => {
        
        var teams = ["team_1", "team_2"];
        teams.map((team) => {
          if (matchup[team].p_r.length != 0) {
            Object.values(matchup[team].p_r.players).map((player, plIndex) => {
              if (player.is_live) {
                if (player.pos == "DST") {
                  window.pusher.subscribe("private-dst." + player.team).bind(
                    "DSTUpdate",
                    (e) => {
                        let matchupCopy =  JSON.parse(JSON.stringify(this.state.matchup));
                        let currentStats = null;
                        currentStats = prepareLiveDSTData(e, player.team);
                        matchupCopy[team].p_r.players[plIndex]["stats"] = currentStats;
                        matchupCopy[team].p_r.players[plIndex]["rem_time"] = e.rem_time;
                        this.setState({
                            matchup: matchupCopy
                        })
                    }
                  );
                } else {
                  window.pusher.subscribe("private-player." + player.id).bind(
                    "PlayerUpdate",
                    (e) => {
                      let matchupCopy = JSON.parse(JSON.stringify(this.state.matchup));
                      matchupCopy[team].p_r.players[plIndex]["stats"] = e.stats;
                      matchupCopy[team].p_r.players[plIndex]["rem_time"] = e.rem_time;
                        this.setState({
                            matchup: matchupCopy
                        })
                    }
                  );
                }
              }
            });
          }
        });
      }
    
    detectWinner = (team1, team2) => {
        if( parseFloat(team1) > parseFloat(team2)){
          return true
        }
        return false
      }

    calculatePoints() {
        let {team1Points, team2Points, matchup, sc_rules, sport, scoringFormat} = this.state

        if(sport == "NFL"){
                team1Points = generatePoints(sc_rules, matchup.team_1.p_r.players, "points")
                team2Points = generatePoints(sc_rules, matchup.team_2.p_r.players, "points")
            }
        else if (sport == "MLB"){
            if(scoringFormat == "h2h_weekly_points"){
                team1Points = mlbGeneratePoints(sc_rules, matchup.team_1.p_r.players)
                team2Points = mlbGeneratePoints(sc_rules, matchup.team_2.p_r.players)
            }
        else if (scoringFormat == "h2h_each_category"){
                var teamsPoints = mlbH2HEachCatPoints(sc_rules, matchup.team_1.p_r.players, matchup.team_2.p_r.players, matchup.team_1.team)
                team1Points = teamsPoints.team1Wlt.w + "-"+teamsPoints.team1Wlt.l+"-"+teamsPoints.team1Wlt.t
                team2Points = teamsPoints.team2Wlt.w + "-"+teamsPoints.team2Wlt.l+"-"+teamsPoints.team2Wlt.t
            }
        }
        this.setState({
            team1Points: team1Points,
            team2Points: team2Points
        })
    }

    /**
     * generate team's stats and return a string (| Proj. # | Min. Rem ###)
     * @param {Object} team - team data to make calculaions on
     * @returns string
     */
    getNFLStats(team){
        let stats = ''
        stats = ' | Proj. ' + (team?.p_r?.projPoints || '0')
        stats += ' | Min. Rem ' + this.calculateRemainingTime(team?.p_r?.players)

        return stats;
    }

    calculateRemainingTime(players){
        let remainingTime = 0
        players && players.map((player) => {
            remainingTime += player.rem_time
        } )
        return remainingTime
    }

    render() { 
        const Colors = this.context;
        return ( 
            <>
                {
                    this.state.matchup 
                    && 
                    <div className='p-2' style={{backgroundColor: Colors.bgLight, borderRadius:10 , cursor:'pointer' ,margin:'1%', width:'48%'}} onClick={() => this.props.history.push(`/livematchup/${this.state.m_id}/${this.props.week}`)}>
                        <div className='d-flex flex-column justify-content-between'>
                            {/* Team 1 */}
                            <div className='d-flex pb-1'>
                                <div className='rounded-circle p-1' style={{width:40, height:40, border:`2px solid ${Colors.colorPrimary}`}}>
                                    <img className='img-fluid' src={this.state.matchup.team_1.image}/>
                                </div>
                                <div className='flex1'>
                                    <p className='bold ml-1 p-0 m-0'>{this.state.matchup.team_1.team}
                                        {this.props.loading ? <Skeleton className='d-block w-50' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor}/> 
                                        :
                                        <span  className='small d-block'>{`${this.state.matchup.team_1.record} ${this.state.sport == 'NFL' ? this.getNFLStats(this.state.matchup.team_1) : ''}`}</span>
                                        }
                                    </p>
                                </div>
                                <div>
                                    {this.props.loading ? <Skeleton className='d-block' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor} width={30} height={30}/> 
                                    :
                                    <h5 className='m-0 my-2' style={{color: this.detectWinner(this.state.team1Points, this.state.team2Points) ? Colors.colorPrimary: ''}}>{this.state.team1Points}</h5>
                                    }
                                </div>
                            </div>
                            {/* ! Team 1 */}
                            
                            {/* Team 2 */}
                            <div className='d-flex pt-1'>
                                <div className='rounded-circle p-1' style={{width:40, height:40, border:`2px solid ${Colors.colorPrimary}`}}>
                                    <img className='img-fluid' src={this.state.matchup.team_2.image}/>
                                </div>
                                <div className='flex1'>
                                    <p className='bold ml-1 p-0 m-0'>{this.state.matchup.team_2.team}
                                        {this.props.loading ? <Skeleton className='d-block w-50' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor}/> 
                                        :
                                        <span  className='small d-block'>{`${this.state.matchup.team_2.record} ${this.state.sport == 'NFL' ? this.getNFLStats(this.state.matchup.team_2) : ''}`}</span>
                                        }
                                    </p>
                                </div>
                                <div>
                                    {this.props.loading ? <Skeleton className='d-block' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor} width={30} height={30}/> 
                                    :
                                    <h5 className='m-0 my-2' style={{color: this.detectWinner(this.state.team2Points, this.state.team1Points) ? Colors.colorPrimary : ''}}>{this.state.team2Points}</h5>
                                    }
                                </div>
                            </div>
                            {/* ! Team 2 */}
                            
                            {/* Game Place */}
                            {
                                this.state.matchup.pos_priority
                                && 
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center", fontWeight:'bold'}} >
                                    {this.props.loading ? <Skeleton className='d-block w-50' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor}/> 
                                    :
                                    <p className='m-0' style={{color:Colors.colorPrimary}}>{(this.state.matchup.pos_priority * 2) - 1}<sup>{get_ordinal_suffix_of((this.state.matchup.pos_priority * 2) - 1)}</sup> Place Game</p>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }
            </>
         );
    }
}
 
const mapStateToProps = (state) => ({activeLeague: state.leagueJoin.activeLeague})
export default connect(mapStateToProps, null) (withRouter (SLMatchup));