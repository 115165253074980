import React, { useState, useEffect } from "react";
import { nflColors as Colors } from "../../../../helpers/colors";
import { parseInjuryIcons } from "../../../../helpers/common-methods";
import { dstPoints, playerPoints } from '../../../../helpers/matchup-helpers';

const NFLPlayersPointsRow = (props) => {
  const { players1, players2, sc_rules, pos, selectPlayer } = props
  const [length, setLength] = useState(0)
  console.log(props);

  useEffect(() => {
    setLength(posLength())
  }, [])

  const posLength = () => {
    var l = 0;
    if (players1.length > players2.length) {
      l = players1.length
    } else if (players2.length > players1.length) {
      l = players2.length
    } else if (players2.length == players1.length) {
      return players2.length
    }
    return l
  }

  return (
    posLength() != 0 &&
    <>
      <div className="flex1 mr-3 my-0 p-0">
        {
          players1.map(player => {
            return (
              <div className="d-flex p-2 mt-1 align-items-center pointer" onClick={() => selectPlayer(player.id)} style={{ backgroundColor: Colors.bgLight, borderRadius: 8 }} key={player.id}>
                <div className="position-relative mr-2" style={{ overflow: 'hidden', maxWidth: '40px', maxHeight: '100%' }}>
                  <img className='img-responsive rounded-circle' src={player.image} width='100%' height='40px' style={{ objectFit: 'cover' }} />
                  <div style={{position:'absolute', bottom:-5, right: 0}}>
                    {parseInjuryIcons(player.injuryStatus)}
                  </div>
                </div>
                <div className="flex1">
                  <h5>
                    {player.full_name}
                  </h5>
                  <h6 style={{whiteSpace:'pre-line'}}>
                    {player.player_stat_string}
                  </h6>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <h3 className="m-0" style={{ color: Colors.colorPrimary }}>
                    {player.a_pos == "DST" && dstPoints(sc_rules, player.current_week_stats)}
                    {player.a_pos != "DST" && playerPoints(player.current_week_stats, sc_rules, player.a_pos)}
                  </h3>
                  <p className="m-0">{player?.proj_points || ''}</p>
                </div>
              </div>
            )
          })
        }
      </div>

      <div className="d-flex flex-column mx-auto" style={{width:20}}>
        {[...Array(posLength()).keys()].map((val) => (
          <div className="flex1 d-flex align-items-center justify-content-center mt-1" key={val}>
            {pos}
          </div>
        ))}
      </div>

      <div className="flex1 ml-3 m-0 p-0">
        {
          players2.map(player => {
            return (

              <div className="d-flex flex-row-reverse p-2 mt-1 align-items-center pointer" onClick={() => selectPlayer(player.id)} style={{ backgroundColor: Colors.bgLight, borderRadius: 8 }} key={player.id}>
                <div className="position-relative ml-2" style={{ overflow: 'hidden', maxWidth: '40px', maxHeight: '100%' }}>
                  <img className='img-responsive rounded-circle' src={player.image} width='100%' height='40px' style={{ objectFit: 'cover' }} />
                  <div style={{position:'absolute', bottom:-5, left: 0}}>
                    {parseInjuryIcons(player.injuryStatus)}
                  </div>
                </div>
                <div className="flex1 justify-content-end">
                  <h5 className="text-right">
                    {player.full_name}
                  </h5>
                  <h6 className="text-right" style={{whiteSpace:'pre-line'}}>
                    {player.player_stat_string}
                  </h6>
                </div>
                <div className="d-flex flex-column align-items-start">
                  <h3 className="m-0" style={{ color: Colors.colorPrimary }}>
                    {player.a_pos == "DST" && dstPoints(sc_rules, player.current_week_stats)}
                    {player.a_pos != "DST" && playerPoints(player.current_week_stats, sc_rules, player.a_pos)}
                  </h3>
                  <p className="m-0">{player?.proj_points || ''}</p>
                </div>
              </div>
            )
          })
        }
      </div>
    </>

  );
};
export default NFLPlayersPointsRow;
