import React, { useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { useColors } from '../../helpers/CustomHooks/useColors';
import { closeIcon, copiedIcon, copyIcon, referralBackIcon, referralFlowIcon, shareIcon, whiteShareIcon } from '../../helpers/icons';
import { BASE_FE_URL } from '../../config/app';
import { useSelector } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookMessengerIcon, FacebookMessengerShareButton, FacebookShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import CreatableSelect from 'react-select/creatable';
import { STYLES } from '../../helpers/stylesHelper';
import { toastError, toastSuccess } from '../../helpers/toasts';
import { Controller, useForm } from 'react-hook-form';
import FloatingInput from '../../components/FloatingInput/FloatingInput';

const ReferralFlowModal = (props) => {
    const Colors = useColors();
    const { isOpen, toggle } = props;
    const [copyText, setCopyText] = useState('Copy');
    const user = useSelector(state => state.auth.user);
    const [shareInvite, setShareInvite] = useState(false);
    const [email, setEmail] = useState()
    const [emailList, setEmailList] = useState([])
    const { control, register, handleSubmit, formState: { errors } } = useForm()

    const getCopyText = () => {
        let invite_text = "Let's battle this week on Bettle! There is a FREE $10 waiting for you once you create an account and import your team. ";
        let url = BASE_FE_URL + '/' + user?.ref_id + '/challenge' + user?.username;
        invite_text += url;
        return invite_text;
    };

    const handleShareInvite = () => {
        setShareInvite(true);
        setCopyText('Copy');
    };

    const shareUrl = () => {
        return BASE_FE_URL + "/" + user?.ref_id + "/challenge+" + user?.username;
    };

    // const valueIsEmail = (em) => {
    //     return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(em);
    // }

    // const sendInvitations = () => {
    //     setLoading(true)
    //     let data = { emails: emailList, referralLink: referralLink() }

    //     inviteViaEmail(data)
    //         .then(response => {
    //             toastSuccess('Invitations Sent Succesfully')
    //             setLoading(false)
    //         })
    //         .catch(err => {
    //             toastError('Something Went Wrong, Try Again')
    //             setLoading(false)
    //         })
    // }

    // const onSubmit = (data) => {
    //     const emails = Object.values(data);
    //     emails?.map((email) => {
    //         email.split(" ").map((email) => {
    //             if (valueIsEmail(email.split(" "))) {
    //                 console.log(email); 
    //             } else {
    //                 toastError("Invalid Email Address");
    //             };
    //         });
    //     });
    // };

    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle} style={{ height: "200px" }}>
                <div className='my-3 mx-3'>
                    <div className='d-flex justify-content-between align-items-center' style={{ borderBottom: `1px solid ${Colors.darkBgBorder}` }}>
                        <p className='m-0 pb-2' style={{ fontSize: 16, fontWeight: 500 }}>{shareInvite ? <div className='d-flex align-items-center pointer' onClick={() => setShareInvite(!shareInvite)}><span style={{ marginTop: 5 }}>{referralBackIcon()}</span> <span className='ml-2'>Refer a Friend</span></div> : "Refer a Friend"}</p>
                        <p className='m-0 pointer' onClick={toggle}>{closeIcon()}</p>
                    </div>
                    {shareInvite ?
                        <>
                            <div className="d-flex align-items-center justify-content-center my-4">
                                {shareIcon()}
                                <p className="mb-0 alex green-font bold ml-2" style={{ fontSize: "32px" }} >Share Invite</p>
                            </div>
                            <div className='d-flex justify-content-center align-items-center mt-4'>
                                <FacebookShareButton
                                    url={shareUrl()}
                                    className="mx-2"
                                >
                                    <FacebookIcon size={48} round />
                                </FacebookShareButton>
                                <TwitterShareButton
                                    url={shareUrl()}
                                    className="mx-2"
                                >
                                    <TwitterIcon size={48} round />
                                </TwitterShareButton>
                                <FacebookMessengerShareButton
                                    url={shareUrl()}
                                    className="mx-2"
                                >
                                    <FacebookMessengerIcon size={48} round />
                                </FacebookMessengerShareButton>
                                <WhatsappShareButton
                                    url={shareUrl()}
                                    className="mx-2"
                                >
                                    <WhatsappIcon size={48} round />
                                </WhatsappShareButton>
                                <EmailShareButton
                                    subject="Let's battle this week on Bettle!"
                                    body={shareUrl()}
                                    url={shareUrl()}
                                    className="mx-2"
                                >
                                    <EmailIcon size={48} round />
                                </EmailShareButton>
                            </div>
                            <div className='d-flex justify-content-center align-items-center mb-3'>
                                <div className='border d-flex justify-content-center align-items-center pointer mt-4' style={{ width: "400px", height: "56px", borderRadius: 50, background: Colors.bgDark_v3 }}>
                                    <p className='m-0 text-left flex1 text-truncate px-3' style={{ fontSize: 16, fontWeight: 400 }}>{`${BASE_FE_URL}/${user?.ref_id}/challenge${user?.username}`}</p>
                                    <CopyToClipboard text={getCopyText()} onCopy={() => setCopyText('Copied!')}>
                                        <p className='m-0 d-flex justify-content-center align-items-center' style={{ background: "white", width: "92px", height: "56px", color: "black", borderTopRightRadius: 50, borderBottomRightRadius: 50 }}>
                                            <i class="fa fa-clone px-1" aria-hidden="true" /> {copyText}
                                        </p>
                                    </CopyToClipboard>
                                </div>
                            </div>
                            {/* <div className='d-flex flex-column justify-content-center align-items-center'>
                                <div style={{ width: "400px" }}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Controller
                                            control={control}
                                            render={({ field: { onChange, onBlur, value } }) => {
                                                return <FloatingInput
                                                    error={errors?.email?.message}
                                                    type="text"
                                                    label="Enter email address (es)"
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                />
                                            }}
                                            name="email"
                                            rules={
                                                { required: "Email is required" }
                                            }
                                            defaultValue={''}
                                        />
                                        <Button style={{ width: "100%", height: "48px", fontSize: "16px" }} className="green-btn btn-pill mb-2 mt-3" type="submit">
                                            Send Invite
                                        </Button>
                                    </form>
                                </div>
                            </div> */}
                        </>
                        :
                        <div className='d-flex flex-column justify-content-center align-items-center px-2'>
                            <p className='m-0 my-4'>{referralFlowIcon()}</p>
                            <p className='m-0 alex' style={{ fontSize: 32, fontWeight: 600 }}>Give $10, Get $10</p>
                            <p className='m-0 text-center' style={{ fontSize: 16, fontWeight: 500 }}>Get a friend to join Bettle via your <span style={{ color: Colors.mlbPrimaryColor }}>personalized invite</span>, you and your friend each get $10 towards your first Arena match</p>
                            <div className='my-4'>
                                <div className='border d-flex justify-content-center align-items-center pointer' style={{ width: "295px", height: "56px", borderRadius: 50, background: Colors.bgDark_v3 }} onClick={handleShareInvite}>
                                    <span style={{ marginTop: "7px" }}>{whiteShareIcon()}</span>
                                    <p className='m-0 pl-2' style={{ fontSize: 18, fontWeight: 600 }}>Share Invite</p>
                                </div>
                                <div className='border d-flex justify-content-center align-items-center pointer mt-4' style={{ width: "295px", height: "56px", borderRadius: 50, background: Colors.bgDark_v3 }}>
                                    <p className='m-0 text-left flex1 text-truncate px-3' style={{ fontSize: 16, fontWeight: 400 }}>{`${BASE_FE_URL}/${user?.ref_id}/challenge${user?.username}`}</p>
                                    <CopyToClipboard text={getCopyText()} onCopy={() => setCopyText('Copied!')}>
                                        <p className='m-0 d-flex justify-content-center align-items-center' style={{ background: "white", width: "92px", height: "56px", color: "black", borderTopRightRadius: 50, borderBottomRightRadius: 50 }}>
                                            <i class="fa fa-clone px-1" aria-hidden="true" /> {copyText}
                                        </p>
                                    </CopyToClipboard>
                                </div>
                            </div>
                        </div>
                    }
                    {copyText == "Copied!" && <p className='m-0 d-flex justify-content-center align-items-center' style={{ background: Colors.arenaLineupPlayerRow, height: "43px", borderRadius: 4 }}><span className='mt-1'>{copiedIcon()}</span> <span className='ml-1' style={{ fontSize: 14, fontWeight: 600 }}>Link copied to clipboard</span></p>}
                </div>
            </Modal>
        </div>
    )
}

export default ReferralFlowModal;