import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Alert, Button, Input, UncontrolledAlert } from 'reactstrap';
// import { Colors } from '../../../helpers/colors';
import { updateUserData } from '../../../actions/auth';
import LoadingOverlay from 'react-loading-overlay';
import { useForm } from 'react-hook-form';
import { updateUserInfo, updateUserProfileImage } from '../../../apis/User/UserAPI';
import ErrorText from '../../ErrorText';
import { useDispatch } from 'react-redux';
import { useColors } from '../../../helpers/CustomHooks/useColors';

const Account = (props) => {
	const Colors = useColors();
	const dispath = useDispatch();
	const [file, setFile] = useState('');
	const [filename, setFileName] = useState(null);
	const [imageUrl, setImageUrl] = useState('');
	const [image, setImage] = useState(props.auth.user.image);
	const [loading, setLoading] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [error, setError] = useState('');
	const [firstName, setFirstName] = useState(props.auth.user.first_name);
	const [lastName, setLastName] = useState(props.auth.user.last_name);
	const { register, handleSubmit, control, formState: { errors } } = useForm();

	const handleImageChange = (e) => {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		reader.onloadend = () => {
			setFile(file);
			setImageUrl(reader.result);
			setImage(reader.result);
		};
		reader.readAsDataURL(file);
		setFileName(imageUrl.split('/').pop());
	};

	const submitUpdate = (reqdata) => {
		setLoading(true);
		var data = {};
		data['first_name'] = firstName;
		data['last_name'] = lastName;
		// data['zip'] = reqdata.zip;

		// UPDATED DATA: SENDING TO REDUCER
		const updateData = {};
		updateData['first_name'] = firstName;
		updateData['last_name'] = lastName;
		updateData['image'] = image;
		dispath(updateUserData(updateData));

		// WHEN FIRST NAME AND LAST NAME DOES NOT MATCH WITH AUTH'S FIRST NAME LAST NAME
		if (firstName != props.auth.user.first_name || lastName != props.auth.user.last_name) {
			// console.log("data API");
			updateUserInfo(data).then((response) => {
				setLoading(false);
			}).catch((error) => {
				setError(error);
				//   console.log(error)
				setLoading(false);
			});
			setTimeout(() => {
				// props.onOkay()
				setIsValid(true);
			}, 1000);
		};
		// WHEN IMAGE DOES NOT MATCH WITH AUTH'S IMAGE
		if (image != props.auth.user.image) {
			// console.log('image API');
			if (imageUrl != '') {
				var data = new FormData();
				data.append('image', file);
				updateUserProfileImage(data)
					.then((response) => {
						setLoading(false);
					})
					.catch((error) => {
						setError(error);
						//   errorMessage("Image was not updated!")
						setLoading(false);
					});
				setTimeout(() => {
					// props.onOkay()
					setIsValid(true);
				}, 1000);
			} else {
				setLoading(false);
			}
		};

		// updateUserInfo(data)
		// 	.then((response) => {
		// 		if (imageUrl != '') {
		// 			var data = new FormData();
		// 			data.append('image', file);
		// 			updateUserProfileImage(data)
		// 				.then((response) => {
		// 					setLoading(false);
		// 				})
		// 				.catch((error) => {
		// 					setError(error);
		// 					//   errorMessage("Image was not updated!")
		// 					setLoading(false);
		// 				});
		// 			setTimeout(() => {
		// 				// props.onOkay()
		// 				setIsValid(true);
		// 			}, 1000);
		// 		} else {
		// 			setLoading(false);
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		setError(error);
		// 		//   console.log(error)
		// 		setLoading(false);
		// 	});
	};

	return (
		<LoadingOverlay
			active={loading}
			spinner
			text="Saving..."
			fadeSpeed={500}
			styles={{
				overlay: (base) => ({
					...base,
					background: 'rgb(0,0,0,0)'
				})
			}}>
			<form onSubmit={handleSubmit(submitUpdate)}>
				<div className="col text-center">
					{
						error ? <UncontrolledAlert color="danger">Something Went Wrong, try again</UncontrolledAlert> :
							null}
					{
						isValid ? <Alert
							toggle={function noRefCheck() {
								setIsValid(!isValid);
							}}
							color="success">
							Profile Updated
						</Alert> :
							null}

					<img
						className="rounded-circle"
						src={image}
						height="100px"
						width="100px"
						style={{
							border: `1px solid ${Colors.colorPrimary}`
						}}
					/>
					{/* <h6 className='mt-2' style={{color:Colors.colorPrimary}} onClick={handleImageChange}>Change Profile Photo</h6> */}
					<Input className="mx-5 my-2" type="file" onChange={handleImageChange} />
					<div className="mx-5">
						<input className="flex1 form-control my-2 mt-4" {...register('firstname', { required: true })} defaultValue={firstName} onChange={(e) => setFirstName(e.target.value)} name='firstName' placeholder="First Name" />
						{/* {errors.firstname && <ErrorText msg="First Name is required" />} */}
						<input className="flex1 form-control my-2 mt-4" {...register('lastname', { required: true })} defaultValue={lastName} onChange={(e) => setLastName(e.target.value)} name='lastName' placeholder="Last Name" />
						{/* {errors.lastname && <ErrorText msg="Last Name is required" />} */}
						{/* <input placeholder="Zip Code" className="flex1 form-control my-2 mt-4" {...register('zip', { required: true })} defaultValue={props.auth.user.zip} />
						{errors.zip && <ErrorText msg="Zip is required" />} */}

						<Button
							className="mx-2 mt-4"
							style={{
								color: Colors.colorPrimary,
								backgroundColor: Colors.colorPrimaryTransparent,
								border: `1px solid ${Colors.colorPrimary}`
							}}
							disabled={props.auth.user.image == image && props.auth.user.first_name == firstName && props.auth.user.last_name == lastName}
							type="submit"
							onChange={props.setModal}>
							Save Changes
						</Button>
						<Button
							className="mx-2 mt-4"
							style={{
								color: Colors.colorPrimary,
								backgroundColor: Colors.colorPrimaryTransparent,
								border: `1px solid ${Colors.colorPrimary}`
							}}
							onClick={props.onBack}
							onChange={props.setModal}>
							Back
						</Button>
					</div>
				</div>
			</form>
		</LoadingOverlay>
	);
};
const mapDispatchToProps = { updateUserData };
export default connect(null, mapDispatchToProps)(withRouter(Account));
