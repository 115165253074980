import { Colors } from "../../helpers/colors"

export const headerVariants = {
    expanded: {height: "40%"},
    collapsed: {height: "20%"},
    sidebar: { height: "100%", width:'20%',  backgroundColor: Colors.sidebarBody},
    mobileExpanded: {height: 'auto'},
    mobileCollapsed: {height: '15%'},
    hideTopHeader: {height: 0}
}


// Fade Out Animations
export const fadeOutRight = {
    show: {
        opacity: 1, x: 0,

    },
    hide: {
        opacity: 0, x: "100%",
        transitionEnd: {
            display: "none",
        },
    }
}

export const fadeOutLeft = {
    show: {
        opacity: 1, x: 0,
    },
    hide: {
        opacity: 0, x: "-120%",
        transitionEnd: {
            height:0,
            display: "none",
        },
    }
}

export const fadeOutUp = {
    show: {opacity: 1, y: 0,},
    hide: {
        opacity: 0,
        y: "-100%",
        transitionStart: {
            display: "none",
        },
        transitionEnd: {
            display: "none",
        },
    }
}

export const fadeOutDown = {
    show: {opacity: 1, y: 0,},
    hide: {
        opacity: 0, y: "1200%",
        transitionEnd: {
            display: "none",
        },
    }
}

export const fadeOut = {
    show: {opacity: 1, height:'auto', display:'block'},
    hide: {
        opacity: 0,
        height:'0',
        transitionEnd: {
            display: "none",
        },
    }
}


// Fade In Animations

export const fadeInLeft = {
    show: {
        opacity: 1, x: 0,
    },
    hide: {
        opacity: 0, x: "-120%",
        transitionEnd: {
            display: "none",
        },
    }
}


export const fadeInRight = {
    hide: {
        opacity: 0, x: "120%",
        transitionEnd: {
            display: "none",
        }
    },
    show: {opacity: 1, x: 0, display: "block"}
}

export const fadeInUp = {
    show: {opacity: 1, y: 0,},
    hide: {
        opacity: 0, y: "-1200%",
        transitionEnd: {
            display: "none",
        },
    }
}

export const fadeInDown = {
    show: {opacity: 1, y: 0,},
    hide: {
        opacity: 0, y: "1200%",
        transitionEnd: {
            display: "none",
        },
    }
}

export const fadeIn = {
    hide: {opacity: 0},
    show: {opacity: 1}
}


// Slide In Animations

export const slideInLeft = {
    hide: {opacity: 0, x: "100%"},
    show: {
        opacity: 1, x: 0,
        transitionEnd: {
            display: "none",
        },
    }
}

export const slideInRight = {
    hide: {
        opacity: 0, x: "-100%",
        height:0,
        transitionEnd: {
            display: "none",
        },
    },
    show: {opacity: 1, x: 0}
}

export const slideInUp = {
    hide: {opacity: 0, y: "100%"},
    show: {opacity: 1, y: 0}
}

export const slideInDown = {
    hide: {opacity: 0, y: "-100%"},
    show: {opacity: 1, y: 0}
}

// Slide Out Animations

export const slideOutLeft = {
    hide: {
        opacity: 0, x: "-100%",
        height:0,
        transitionEnd: {
            display: "none !important",
        },
    },
    show: {opacity: 1, x: 0}
}

export const slideOutRight = {
    show: {opacity: 1, x: 0},
    hide: {
        opacity: 0, x: "100%",
        height:0,
        transitionEnd: {
            display: "none",
        },
    }
}

export const slideOutUp = {
    show: {opacity: 1, y: 0},
    hide: {opacity: 0, y: "100%"}
}

export const slideOutDown = {
    show: {opacity: 1, y: 0},
    hide: {opacity: 0, y: "-100%"}
}

export const slideInOut = {
    hideRight: {
        opacity: 0, x: "100%"
    },
    show: {opacity: 1, x: 0},
    lineupShow: {opacity: 1, x: 0, flex: 1},
    hideLeft: {
        flex: 0,
        height: 0,
        opacity: 0,
        x: "-100%",
        transitionEnd: {
            display: "none",
        },
    }
}

export const animateSidebar = {
    hideRight: {opacity: 0, x: "100%"},
    show: {opacity: 1, x: 0},
    hideLeft: {opacity: 0, x: "-100%"}
}


// Text
export const text18to14 = {
    from: {fontSize: '18px'},
    to: {fontSize: '14px'}
}
export const text40to16 = {
    from: {fontSize: '40px'},
    to: {fontSize: '20px'}
}

export const text40to32 = {
    from: {fontSize: '40px'},
    to: {fontSize: '32px'}
}

export const text40to36 = {
    from: {fontSize: '40px'},
    to: {fontSize: '36px'}
}

export const text50to36 = {
    from: {fontSize: '50px'},
    to: {fontSize: '36px'}
}

export const text36to16 = {
    from: {fontSize: '36px'},
    to: {fontSize: '16px'}
}

export const text24to16 = {
    from: {fontSize: '32px'},
    to: {fontSize: '16px'}
}

export const text32to24 = {
    from: {fontSize: '36px'},
    to: {fontSize: '20px'}
}

// Collapse Animation
export const collapseVertical = {
    expand: {opacity: 1, display:'block', height:'auto', padding:'auto'},
    collapse: {
        opacity: 0,
        height:0,
        padding:0,
        transitionEnd: {
            display: "none",
        },
    }
}

export const collapseHorizontal = {
    expand: {opacity: 1, display:'flex', width:'auto'},
    collapse: {
        opacity: 0,
        width:0,
        transitionEnd: {
            display: "none",
        },
    }
}

export const collapseLineupVertical = {
    expand: {opacity: 1, display:'block', height:'auto'},
    collapse: {
        opacity: 0,
        height:0,
        paddingTop:0,
        transitionEnd: {
            display: "none",
        },
    }
}

// Shrink Animation
export const shrinkVertical = {
    expand: {opacity: 1, display:'block'},
    collapse: {
        opacity: 0,
        height:0,
        transitionEnd: {
            display: "none",
        },
    }
}

// Progress Bar
export const progressBar = {
    incomplete: {borderColor:'#808080', marginLeft:4},
    complete: {borderColor:'#000000', marginLeft:0}
}

export const stepStatus = {
    active: {borderColor:'#000000'},
    inactive: {borderColor:'#808080'},
    complete: {borderColor:'#000000', backgroundColor: '#000000'}
}

export const stepStatusText = {
    active: {color:'#000000'},
    inactive: {color:'#808080'},
}

// Flex

export const flexDirection = {
    row: {flexDirection: 'row'},
    column: {flexDirection: 'column'}
}


// Component Specific Animations

export const sidebarBody = {
    show: {opacity: 1, x: 0, flexDirection: 'column'},
    hide: {opacity: 0, x: "-100%"}
}

// Bars
export const expand = {
    full: {width: '100%'},
    half: {width: '50%'},
    quarter: {width: '25%'},
    none: {width: '0%'}
}

export const progressPercentage = {
    1: {width: '1%'},
    2: {width: '2%'},
    3: {width: '3%'},
    4: {width: '4%'},
    5: {width: '5%'},
    6: {width: '6%'},
    7: {width: '7%'},
    8: {width: '8%'},
    9: {width: '9%'},
    10: {width: '10%'},
    11: {width: '11%'},
    12: {width: '12%'},
    13: {width: '13%'},
    14: {width: '14%'},
    15: {width: '15%'},
    16: {width: '16%'},
    17: {width: '17%'},
    18: {width: '18%'},
    19: {width: '19%'},
    20: {width: '20%'},
    21: {width: '21%'},
    22: {width: '22%'},
    23: {width: '23%'},
    24: {width: '24%'},
    25: {width: '25%'},
    26: {width: '26%'},
    27: {width: '27%'},
    28: {width: '28%'},
    29: {width: '29%'},
    30: {width: '30%'},
    31: {width: '31%'},
    32: {width: '32%'},
    33: {width: '33%'},
    34: {width: '34%'},
    35: {width: '35%'},
    36: {width: '36%'},
    37: {width: '37%'},
    38: {width: '38%'},
    39: {width: '39%'},
    40: {width: '40%'},
    41: {width: '41%'},
    42: {width: '42%'},
    43: {width: '43%'},
    44: {width: '44%'},
    45: {width: '45%'},
    46: {width: '46%'},
    47: {width: '47%'},
    48: {width: '48%'},
    49: {width: '49%'},
    50: {width: '50%'},
    51: {width: '51%'},
    52: {width: '52%'},
    53: {width: '53%'},
    54: {width: '54%'},
    55: {width: '55%'},
    56: {width: '56%'},
    57: {width: '57%'},
    58: {width: '58%'},
    59: {width: '59%'},
    60: {width: '60%'},
    61: {width: '61%'},
    62: {width: '62%'},
    63: {width: '63%'},
    64: {width: '64%'},
    65: {width: '65%'},
    66: {width: '66%'},
    67: {width: '67%'},
    68: {width: '68%'},
    69: {width: '69%'},
    70: {width: '70%'},
    71: {width: '71%'},
    72: {width: '72%'},
    73: {width: '73%'},
    74: {width: '74%'},
    75: {width: '75%'},
    76: {width: '76%'},
    77: {width: '77%'},
    78: {width: '78%'},
    79: {width: '79%'},
    80: {width: '80%'},
    81: {width: '81%'},
    82: {width: '82%'},
    83: {width: '83%'},
    84: {width: '84%'},
    85: {width: '85%'},
    86: {width: '86%'},
    87: {width: '87%'},
    88: {width: '88%'},
    89: {width: '89%'},
    90: {width: '90%'},
    91: {width: '91%'},
    92: {width: '92%'},
    93: {width: '93%'},
    94: {width: '94%'},
    95: {width: '95%'},
    96: {width: '96%'},
    97: {width: '97%'},
    98: {width: '98%'},
    99: {width: '99%'},
    100: {width: '100%'}
}

export const swipeSidebarTabPill = {
    matches: {left:'0%'},
    leagues: {left:'50%'},
}

export const textActiveToSecondary = {
    active: {color: Colors.textDark_v3},
    secondary: {color: Colors.textSecondary_v3}
}

export const matchupCardBgColor = {
    collapsed: {backgroundColor: Colors.bgDark_v3},
    expanded: {backgroundColor: Colors.arenaContentBg_v3}
}