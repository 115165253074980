import React from 'react'
import { Colors } from '../helpers/colors';

const ErrorText = (props) => {
    const {msg} = props
  return (
    <p className='my-2' style={{color: Colors.colorDanger}} >{msg}</p>
  )
}

export default ErrorText;