import React, { useEffect, useState } from "react"
import { Button, Col, Input, Row } from "reactstrap"
import { defaultColors } from '../../../helpers/colors';
import {STYLES} from "../../../helpers/stylesHelper"
import PerfectScrollbar from 'react-perfect-scrollbar'
import { dCashierCreateSession, gidxCompleteSession, gidxWithdrawFunds } from "../../../apis/Gidx/GidxApi";
import { toggleGidxErrorModal, toggleGidxSuccessModal, updateUserStatus } from "../../../helpers/Payments/GidxHelper";
import LoadingOverlay from "react-loading-overlay";
import { useSelector } from "react-redux";
import ErrorText from "../../../components/ErrorText";
import GidxErrorModal from "../../../modals/GidxModals/GidxErrorModal";
import GidxSuccessModal from "../../../modals/GidxModals/GidxSuccessModal";
import { useColors } from "../../../helpers/CustomHooks/useColors";

var Colors = defaultColors

const styles = {
    amountBox: {
        border: `1px solid ${Colors.darkBgBorder}`,
    },
    amountBoxChecked: {
        border: `1px solid ${Colors.colorPrimary}`,
    },
    pmBox: {
        border: `1px solid ${Colors.darkBgBorder}`,
    },
    pmBoxChecked: {
        border: `1px solid ${Colors.colorPrimary}`,
    },
    storedMethods: {
        border: `1px solid ${Colors.darkBgBorder}`,
        height: "70px",
        marginTop: "10px"
    },
    storedMethodsChecked: {
        border: `1px solid ${Colors.colorPrimary}`,
        height: "70px",
        marginTop: "10px"
    }
}

const GidxWithdrawFunds = (props) => {
    const {toggleFlyout} = props 

    Colors = useColors();
    const gidxReducer = useSelector(state => state.gidxErrorReducer)
    const [formVals, setFormVals] = useState({
        amount: 25
      })
      const [errors, setErrors] = useState({})
      const [existPMId, setExistPMId] = useState()
      const [loading, setLoading] = React.useState(true)
      const [error, setError] = React.useState(null)
      const [cvvModal, setCVVModal] = useState(false)
      const [sessionData, setSessionData] = React.useState(
        {paymentMethods: []}
      )  
    useEffect(() => {
        dCashierCreateSession("?payType=PAYOUT").then((resp) => {
          setSessionData(resp)
          setLoading(false)
        }).catch(e => {
          // WE NEED TO PARSE ERRORS HERE
          // updateUserStatus(e.data.status).then(() => {
          setError("Something went wrong!")
          // })
          setLoading(false)
        })
      }, [])
  
    const handleInput = (key, val) => {
      var formCopy = {...formVals}
      var newErrors = {...errors}
      if(newErrors[key] != undefined){
        delete newErrors[key]
      }if(key == 'cAmount'){
        formCopy["amount"] = ""
        delete newErrors["amount"]
        formCopy[key] = val
      }else if(key == 'amount'){
        formCopy["cAmount"] = ""
        formCopy[key] = val
      }else{
        formCopy[key] = val
      }
      if(key == "paymentMethod" && formVals.paymentMethod != val){
        // RESET STATES
        setErrors({})
        delete formCopy["save"]
      }else{
        setErrors(newErrors)
      }
      setFormVals(formCopy)
    }
  
    const handleSubmit = () => {
      // WE NOW HAVE ALL THE DATA PREPARED IN FORM VALS
      // WE JUST NEED TO DYNAMICALLY ATTACH NEW OR EXISTING PM DATA
      if(validateForm()){
        setLoading(true)
        // EXISTING PM
        formVals["newPM"] = false
        // ADD EXISTING PM ID IN REQUEST
        formVals["pmId"] = existPMId.toString()
        // SEND REQUEST NOW
        formVals["bSessionId"] = sessionData.id
        gidxWithdrawFunds(formVals).then((resp) => {
          if(resp.status == "Complete"){
            toggleGidxSuccessModal({msg: `Congratulations! You have deposited $${getAmount()}`, path: "/dashboard"})
            toggleFlyout()
          }else{
            if(resp.status == "Pending"){
              toggleGidxErrorModal({msg: "You transaction is pending, we will notify you in a bit", path: "/payments/wallet"})
            }else if(resp.status == "Processing"){
              toggleGidxErrorModal({msg: "You transaction is processing, we will add the funds in your bettle wallet shortly", path: "/payments/wallet"})
            }else if(resp.status == "Ineligible"){
              toggleGidxErrorModal({msg: "You transaction is Ineligible", path: "/payments/wallet"})
            }else{
              toggleGidxErrorModal({msg: "You transaction has been failed", path: "/payments/wallet"})
            }
            toggleFlyout()
          }
          setLoading(false)
        }).catch((err) => {
            toggleGidxErrorModal({msg: err.message, path: "/payments/wallet"})
            setLoading(false)
        })
      }
    }
  
    const validateForm = () => {
        var newErrors = {}
        if((formVals.amount == undefined || formVals.amount == '' || formVals.amount == null) &&
        (formVals.cAmount == undefined || formVals.cAmount == '' || formVals.cAmount == null)){
            newErrors["amount"] = "Please select an amount"
        }
        // EXISTING PM
        if(existPMId != 0 && (existPMId == undefined || existPMId == '' || existPMId == null)){
            newErrors["existPM"] = "Please select payment method"
        }
        if(Object.keys(newErrors).length > 0){
            setErrors(newErrors)
            return false
        }
        return true
    }
  
    const handleExistingPM = (id, type) => {
      var newErr = {...errors}
      // var newVals = {...formVals}
      delete newErr["existPM"]
      // delete newVals["securityCode"]
      setErrors(newErr)
      setFormVals(formVals)
      // if(type == "CC"){
      //   setCVVModal(true)
      // }
      setExistPMId(id)
    }
  
    const getAmount = () => {
      if(formVals.cAmount){
        return formVals.cAmount
      }
      return formVals.amount
    }

    return(
      <LoadingOverlay
          className='d-flex flex-column flex1'
          active={loading}
          spinner
          text='Processing...'
          fadeSpeed={500}
          styles={{
          overlay: (base) => ({
              ...base,
              background: 'rgba(0,0,0,0.5)',
          }),
          wrapper: {
              overflow: "hidden"
          }
          }}
      >
    <div className="d-flex flex-column flex1 scrollHidden" >
        {error ? (<ErrorText msg="This is error" />) : (<PerfectScrollbar className="p-3" >
        <h6 className="bold" >Select Amount (USD)</h6>
        <div className="d-flex align-items-center justify-content-between flexWrap">
            <div className={`pt-2 pb-2 pl-3 pr-3 mr-2 mt-2`} style={formVals.amount == 25 ? styles.amountBoxChecked : styles.amountBox} onClick={() => handleInput("amount", 25)} >
                <span className="bold" >$25</span>
            </div>
            <div className={`pt-2 pb-2 pl-3 pr-3 mr-2 mt-2`} style={formVals.amount == 50 ? styles.amountBoxChecked : styles.amountBox} onClick={() => handleInput("amount", 50)} >
                <span className="bold">$50</span>
            </div>
            <div className={`pt-2 pb-2 pl-3 pr-3 mr-2 mt-2`} style={formVals.amount == 75 ? styles.amountBoxChecked : styles.amountBox} onClick={() => handleInput("amount", 75)} >
                <span className="bold">$75</span>
            </div>
            <div className={`pt-2 pb-2 pl-3 pr-3 mr-2 mt-2`} style={formVals.amount == 100 ? styles.amountBoxChecked : styles.amountBox} onClick={() => handleInput("amount", 100)} >
                <span className="bold">$100</span>
            </div>
            <div className={`pt-2 pb-2 pl-3 pr-3 mr-2 mt-2`} style={formVals.amount == 125 ? styles.amountBoxChecked : styles.amountBox} onClick={() => handleInput("amount", 125)} >
                <span className="bold">$125</span>
            </div>
        </div>
        <h6 className="mb-3 mt-3 bold" >Other Amount (USD)</h6>
        <Input value={formVals.cAmount} placeholder="0.00" onChange={(e) => handleInput("cAmount", e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'))} />
        {errors.cAmount && <ErrorText msg={errors.cAmount} />}
        {errors.amount && <ErrorText msg={errors.amount} />}

        {!loading && (<div>

        <h6 className="mb-3 mt-3" >Payment Details</h6>
            <div>
            {sessionData.paymentMethods.map((pm) => (
                <div onClick={() => handleExistingPM(pm.id, pm.type)} className="d-flex flexGrow align-items-center p-2 pointer" style={existPMId == pm.id ? styles.storedMethodsChecked : styles.storedMethods} >
                    {pm.type == "Paypal" && <i class="fa fa-paypal mb-2" aria-hidden="true" style={{fontSize: "2rem"}} />}
                    {pm.type != "Paypal" && <i class="fa fa-credit-card mb-2" aria-hidden="true" style={{fontSize: "2rem"}} />}
                    <div className="ml-3" >
                        <p className="mb-1 bold" >{pm.display}</p>
                        <p className="m-0" >{pm.expiry}</p>
                    </div>
                </div>
            ))}
            {errors.existPM && <ErrorText msg={errors.existPM} />}
            </div>
          <Button className="mt-4" onClick={handleSubmit} block style={STYLES.BUTTON_PRIMARY_TRANSPARENT} >Withdraw Funds</Button>
        </div>)}
        </PerfectScrollbar>)}
        {gidxReducer.errorModal.show && <GidxErrorModal />}
        {gidxReducer.successModal.show && <GidxSuccessModal />}
    </div>
    </LoadingOverlay>)
}
export default GidxWithdrawFunds