import React from 'react'
// import { Colors } from '../../../helpers/colors'
import BetsMatchup from './BetsMatchup'
import EachCatBet from './EachCatBet'
import RotoBet from './RotoBet'

const BetLive = (props) => {
  return (
    <>
        <tbody>
            {
                props.data.map((challenge, index) => {
                    return (
                        {
                            'roto':
                                <RotoBet bet={challenge} league={props.activeLeague} />,
                            'h2h_weekly_points':
                                <BetsMatchup bet={challenge} league={props.activeLeague} />,
                            'h2h_each_category':
                                <EachCatBet bet={challenge} league={props.activeLeague} />

                        }[props.activeLeague.scoringFormat]
                    )
                })
            }
        </tbody>
    </>
  )
}

export default BetLive