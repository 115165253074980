import React, { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import DocumentTitle from 'react-document-title'
import { connect } from 'react-redux'
import { matchPath, useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import { BASE_URL } from '../../../config/app'
// import { Colors } from '../../../helpers/colors'
import { LoaderCenter } from '../../../components/Loader';
import './refer.css'
import { useColors } from '../../../helpers/CustomHooks/useColors';
import referSplash from "../../../assets/images/refer_splash.png"
import { bettleLogoSvg, rightArrow } from '../../../helpers/icons'
import { refPreview } from '../../../apis/Arena/MatchMakerApis'
import { prepareArenaOpps } from '../../../helpers/Arena/ArenaHelper'

const AcceptInvitation = (props) => {
    const Colors = useColors();
    const history = useHistory();
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()

    useEffect(() => {
        let {ref_id, sport } = props.match?.params;
        refPreview("?refId="+ref_id).then((resp) => {
            if(resp?.success){
                setData(resp?.data)
                setLoading(false)
            }else{
                handleRediect()
            }
        }).catch(() => {
            handleRediect()
        })
    }, [])

    const handleRediect = () => {
        let {ref_id } = props.match?.params;
        let { path } = props.match;
        // check if the route is exact macth for "challenge me"
        let route = matchPath(path, {
            path: '/:ref_id/challengeme/:username',
            exact: true,
            strict: false
        })
        if (route?.isExact) {
            // setInForage('challenger', {ref_id, sport});
            redirectToSignup(ref_id, "CM");
        }
        else {
            redirectToSignup(ref_id);
        }
    }

    const redirectToSignup = (referrer = null, type = "R") => {
        if(referrer) {
            history.push('/register?ref='+referrer+"&refType="+type);
        }
        else {
            history.push('/register');
        }
    }

    const styles = {
        img: {
            width: "72px",
            height:" 72px",
            objectFit: "cover",
            borderRadius: "50%"
        },
        darkBorder: {
            borderBottom: "1px solid #333333"
        }
    }

    const mlbPlayersByPos = (players, type) => {
        var finalPlayers = []
        players?.map((player) => {
            if (type == "hitters") {
                if(player.pos != 'P'){
                    finalPlayers.push(player)
                };
            } else if (type == "pitchers") {
                if(player.pos == 'P'){
                    finalPlayers.push(player)
                };
            };
        });
        return finalPlayers;
    }

    return (
        <DocumentTitle title={`Bettle Fantasy Sports`}>
            <div className='d-flex flex-column flex1' style={{height: "100vh", background: "black"}} >

                <div class="d-flex flex-column flex1 scrollHidden my-4 align-items-center justify-content-center" >

                    {loading ? <LoaderCenter/> : (
                    <div className="d-flex flex-column scrollHidden" style={{
                        borderRadius: "16px",
                        backgroundImage: `url(${referSplash})`,
                        backgroundRepeat: "no-repeat",
                        overflow: "hidden",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        maxWidth: "800px"
                        }}>
                        
                        <div className='d-flex flex-column align-items-center' >
                            <div onClick={() => {
                               history.push("/")
                            }} className='my-4 pointer' style={{color: "black", width: "150px"}} >
                                {bettleLogoSvg()}
                            </div>
                        </div>

                        <div className='d-block d-md-none bold text-center mb-3' style={{color: "black"}} >
                            This is {data?.firstName}'s lineup for the week.
                        </div>

                        <div className="d-flex scrollHidden flex1" >
                            
                            <div className='flex1 font16 text-left px-4 bold d-none d-md-block' style={{color: "black"}} >
                                This is {data?.firstName}'s lineup for the week.
                                {rightArrow()}
                            </div>

                            <div className='d-flex flex1 flex-column py-4 px-4 scrollHidden mx-3 mx-md-0' style={{
                                background: "linear-gradient(180deg, #431614 11.67%, #121212 26.94%)",
                                boxShadow: "0px 11.3878px 9.1102px rgba(0, 0, 0, 0.33)",
                                borderRadius: "9.1102px",
                               flex: 3,
                               
                            }} >

                                <div className='d-flex align-items-center'>
                                    <div className='mr-3' >
                                        <div className='d-flex align-items-center justify-content-center' >
                                            <img style={styles.img} src={data?.teamImage} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3 className='alex bold mb-0' style={{color: "#DF4943"}} >{data?.teamName}</h3>
                                        <p style={styles.dimColor} >{data?.leagueName}</p>
                                    </div>
                                </div>


                                <div className='d-flex mt-5 flex-grow-1' style={{overflow: "auto"}} >
                                    <div className='flex1 mr-4' >
                                        {mlbPlayersByPos(data?.players?.players, "hitters").map((player) => (
                                            <div className='d-flex' >
                                                <p className='mb-0 mr-3 greyText' >{player?.pos == "UTIL" ? "UT" : player.pos}</p>
                                                <div className='d-flex flex-column pb-1' style={styles.darkBorder} >
                                                    <div className='font16' >{player?.fullName}<span className='teamName greyText pl-2' >{player?.team}</span></div>
                                                    <div className='teamName mlbOpp' >{prepareArenaOpps(player?.opps)}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className='flex1 ml-4' >
                                        {mlbPlayersByPos(data?.players?.players, "pitchers").map((player) => (
                                            <div className='d-flex' >
                                                <p className='mb-0 mr-3 greyText' >{player?.pos == "UTIL" ? "UT" : player.pos}</p>
                                                <div className='d-flex flex-column pb-1' style={styles.darkBorder} >
                                                    <div className='font16' >{player?.fullName}<span className='teamName greyText pl-2' >{player?.team}</span></div>
                                                    <div className='teamName mlbOpp' >{prepareArenaOpps(player?.opps)}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                </div>

                            </div>
                            
                            <div className='flex1 d-none d-md-block' >

                            </div>
                        </div>

                        <div className='text-center mb-4 mx-4' >
                            <Button onClick={handleRediect} style={{ width: "328px", height: "48px", fontSize: "18px" }} className="green-btn btn-pill mb-2 mt-3 bold">
                                LET’S G0000!
                            </Button>
                        </div>

                    </div>
                    )}

                </div>
            </div>
        </DocumentTitle>
  )
}

export default AcceptInvitation