import React from 'react'
import { motion } from 'framer-motion/dist/framer-motion';
import { slideInLeft } from '../NewUserOnboarding/animationValues';
import { Colors } from '../../helpers/colors';

const ClaimTeamSteps = (props) => {
    const { step, hasLeague, forMob} = props;

    const styles = {
        circle: {
            width: "30px", height: "30px", borderRadius: "50%", color: "white", background: "black"
        }
    }

    return (
        
        <motion.div className="d-flex flex-column align-items-center justify-content-center flex1" initial={false} animate={step != 'default' ? 'show' : 'hide'}
            transition={{ duration: .5, delay: .2 }}
            variants={slideInLeft} >
                {((step != "default" && forMob) || !forMob) &&  <div className='d-flex align-items-center' >

                    {hasLeague ? (
                        <>
                        <div className='mx-2 d-flex flex-column align-items-center' style={{
                            opacity: ["startClaim", "default", "sportSelected", "platformSelected"].includes(step) ? 1 : .5
                        }} >
                            <p className='lead' >Claim</p>
                            <div>
                                {["leagueImported", "lineupSelected", "allDone"].includes(step) ?
                                (<i class="fa fa-check-circle" style={{fontSize: "2rem"}} aria-hidden="true"></i>) : 
                                (<div className='d-flex align-items-center justify-content-center bold' style={styles.circle} >
                                    1
                                </div>)}
                            </div>
                        </div>


                        <div className='mx-2 d-flex flex-column align-items-center' style={{
                            opacity: step == "leagueImported" ? 1 : .5
                        }} >
                            <p className='lead' >Avatar</p>
                            <div>
                                {["avatarSelected", "allDone"].includes(step) ?
                                (<i class="fa fa-check-circle" style={{fontSize: "2rem"}} aria-hidden="true"></i>) : 
                                (<div className='d-flex align-items-center justify-content-center bold' style={styles.circle} >
                                    2
                                </div>)}
                            </div>
                        </div>

                        <div className='mx-2 d-flex flex-column align-items-center' style={{
                            opacity: step == "avatarSelected" ? 1 : .5
                        }} >
                            <p className='lead' >Arena</p>
                            <div>
                                {["allDone"].includes(step) ?
                                (<i class="fa fa-check-circle" style={{fontSize: "2rem"}} aria-hidden="true"></i>) : 
                                (<div className='d-flex align-items-center justify-content-center bold' style={styles.circle} >
                                    3
                                </div>)}
                            </div>
                        </div>
                    </>
                    ) : (
                        <>
                        <div className='mx-2 d-flex flex-column align-items-center' style={{
                            opacity: step == "startClaim" ? 1 : .5
                        }} >
                            <p className='lead' >Claim</p>
                            <div>
                                {["leagueImported", "avatarSelected", "lineupSelected", "allDone"].includes(step) ?
                                (<i class="fa fa-check-circle" style={{fontSize: "2rem"}} aria-hidden="true"></i>) : 
                                (<div className='d-flex align-items-center justify-content-center bold' style={styles.circle} >
                                    1
                                </div>)}
                            </div>
                        </div>
                        <div className='mx-2 d-flex flex-column align-items-center' style={{
                            opacity: step == "leagueImported" ? 1 : .5
                        }} >
                            <p className='lead' >Avatar</p>
                            <div>
                            {["lineupSelected", "avatarSelected", "allDone", "leagueImported"].includes(step) ?
                                (<i class="fa fa-check-circle" style={{fontSize: "2rem"}} aria-hidden="true"></i>) : 
                                (<div className='d-flex align-items-center justify-content-center bold' style={styles.circle} >
                                    2
                                </div>)}
                            </div>
                        </div>

                        {/* <div className='mx-2 d-flex flex-column align-items-center' style={{
                            opacity: step == "avatarSelected" ? 1 : .5
                        }} >
                            <p className='lead' >Lineup</p>
                            <div>
                            {["avatarSelected", "allDone"].includes(step) ?
                                (<i class="fa fa-check-circle" style={{fontSize: "2rem"}} aria-hidden="true"></i>) : 
                                (<div className='d-flex align-items-center justify-content-center bold' style={styles.circle} >
                                    3
                                </div>)}
                            </div>
                        </div> */}

                        <div className='mx-2 d-flex flex-column align-items-center' style={{
                            opacity: step == "lineupSelected" ? 1 : .5
                        }} >
                            <p className='lead' >Wallet</p>
                            <div>
                            {["allDone"].includes(step) ?
                                (<i class="fa fa-check-circle" style={{fontSize: "2rem"}} aria-hidden="true"></i>) : 
                                (<div className='d-flex align-items-center justify-content-center bold' style={styles.circle} >
                                    3
                                </div>)}
                            </div>
                        </div>
                    </>
                    )}
                    
                </div>}
    </motion.div>
    )
}

export default ClaimTeamSteps