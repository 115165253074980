import React, { useEffect, useState } from 'react';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import store from '../../store';
import { claimTeam, fetchTeams } from '../../actions/Import/api';
import { LoaderCenter } from '../../components/Loader';
import { Colors } from '../../helpers/colors';
import { errorMessage, removeInForage } from '../../helpers/common-methods';
import { useHistory } from 'react-router-dom';
import { slideInOut } from '../NewUserOnboarding/animationValues';
import { motion } from "framer-motion/dist/framer-motion"
import { STYLES } from '../../helpers/stylesHelper';

const NewClaimTeam = (props) => {
	const { claimData, successCallback, step } = props
    const [teams, setTeams] = useState([])
    const [loading, setLoading] = useState(true)
    const history = useHistory()

    useEffect(() => {
        if(step == "startClaim"){
            var params = {lgId: claimData.lgId}
            if(claimData?.grudgeId){
                params["grudgeId"] = claimData?.grudgeId
            }
            store.dispatch(fetchTeams(params)).then((resp) => {
                setTeams(resp)
                setLoading(false)    
            })
        }
    }, [step])

    const claimTeamAPI = (team) => {
        setLoading(true)
        var reqData = {
            lgId: claimData.lgId,
            teamId: team.teamId
        }
        reqData["sb_id"] = claimData?.sbId
        if(claimData?.grudgeId){
            reqData["grudgeId"] = claimData?.grudgeId
        }
        store.dispatch(claimTeam(reqData)).then(async (resp) => {
            successCallback(resp.sport)
            // updateStep("leagueImported")
        }).catch(async (err) => {
            setLoading(false)
            errorMessage(err)
            // successCallback()
        })
    }

	return (

        <motion.div
            className="flex-column text-center col-md-5 col-12 col-centered px-0"
            animate={ step == "startClaim" ? 'lineupShow' : step == "teamSelected" ? 'hideLeft' :'hideRight'}
            transition={{ duration: 0.5 }}
            // style={{flex: step == "leagueImported" ? 1 : 0}}
            style={{display: "flex", flex: 0}}
            initial={false}
            variants={slideInOut}
            >
                {
                !loading && teams.length == 0 ?
                <div className="d-flex flex-column flex1 my-3 py-4 text-center scrollHidden px-4" >
                    <h1 className="mb-3 alex bold" style={{ color: Colors.colorPrimary }}>
                        League is Full
                    </h1>
                    <p className='w-75 mx-auto' >All the teams in <b>{decodeURI(claimData.lgName).replaceAll("+"," ")}</b> have been claimed already. Don't worry you can still import your own league.</p>
                    <Button className="mt-3" style={STYLES.BUTTON_PRIMARY_TRANSPARENT} onClick={() => history.push("/new-user-onboarding")} >Import League</Button>
                </div>
                :
                step == "startClaim" && <div className="d-flex flex-column flex1 my-3 py-4 text-center scrollHidden" style={{background: Colors.bgDark, borderRadius: "10px"}} >
                    <h1 className="mb-3 alex bold" style={{color: Colors.colorPrimary }}>
                        Claim your Team
                    </h1>
                    <p className='w-75 mx-auto' >Click your team below in <b>{decodeURI(claimData.lgName)}</b> and join league</p>
                    {loading ? <LoaderCenter /> : (<ListGroup style={{overflow: "auto"}} >
                        {teams.map((team) => {
                            return (
                                <ListGroupItem style={{color: "white", border: "none"}} tag="button" onClick={() => claimTeamAPI(team)} action>
                                    <div className="d-flex justify-content-between align-items-center" >
                                        <div className="d-flex align-items-center" >
                                            <img className="roundImgSmall mr-2"  src={team.teamImage } />
                                            <div>
                                                {/* <p className="bold m-0" >{league.name}</p> */}
                                                <p className="m-0" >{team.teamName}</p>
                                            </div>
                                        </div>
                                    </div>
                                </ListGroupItem>
                            )
                        })}
                    </ListGroup>)}
                </div>}
        </motion.div>
	);
};
export default NewClaimTeam;
