import moment from 'moment-timezone';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReactSelect from 'react-select';
import { fetchMlbMatchup, fetchMlbMyMatchup } from '../../../apis/SeasonLong/Scoreboard/ScoreboardAPI';
import { mlbColors as Colors } from '../../../helpers/colors';
import { mlbGeneratePoints, mlbH2HEachCatPoints } from '../../../helpers/MLB/Points';
import { toastError } from '../../../helpers/toasts';
import { LoaderCenter } from '../../../components/Loader';
import MLBEachCatPlayers from '../../../components/SeasonLong/Scoreboard/MLB/MLBEachCatPlayers';
import MLBWeeklyPointsPlayers from '../../../components/SeasonLong/Scoreboard/MLB/MLBWeeklyPointsPlayer';

class MLBMatchup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            refreshing: false,
            data: [],
            totalPeriods: { daily_period: '0', date: 'Total' },
            selectedPeriod: null,
            modal: false,
            lastPlayInfo: null,
            m_id: 0,
            noMatchup: false,
            lastLeagueId: null,
            statType: "total",
            periodIndex: { daily_period: '0', date: 'Total' },

            customStyles:
            {
                option: (base) => ({
                    ...base,
                    backgroundColor: Colors.bgLight,
                    color: 'white'

                }),
                input: (base) => ({
                    ...base,
                    backgroundColor: Colors.bgLight,
                    color: 'white',
                    minWidth: 100
                }),
                menu: (base) => ({
                    ...base,
                    backgroundColor: Colors.bgLight,
                    marginTop: 0,
                    zIndex: 10000
                }),
                menuList: (base) => ({
                    ...base,
                    backgroundColor: Colors.bgLight,
                    borderRadius: 8,
                }),
                valueContainer: (base) => ({
                    ...base,
                    backgroundColor: Colors.bgLight,
                }),
                control: (base) => ({
                    ...base,
                    backgroundColor: Colors.bgLight,
                }),
                singleValue: (base) => ({
                    ...base,
                    color: 'white'
                }),
            },
        }
        this.handleAPISuccess = this.handleAPISuccess.bind(this)
        this.handlePeriodSelect = this.handlePeriodSelect.bind(this)
    }

    componentDidMount() {

        this.setState({
            m_id: parseInt(this.props.match.params.id)
        })
        setTimeout(() => {
            if (this.state.lastLeagueId == null || this.state.lastLeagueId != this.props.activeLeague.id) {
                this.callAPI()
            }
        }, 0);
    }

    handleRefresh() {
        this.setState({ refreshing: true })
        this.callAPI()
    }

    callAPI = (period = this.state.selectedPeriod, statType = this.state.statType) => {
        var queryString = ""
        if (period) {
            queryString = "?league_id=" + this.props.activeLeague.id + "&dailyPeriod=" + period + "&m_id=" + this.state.m_id + "&type=" + statType
        } else {
            queryString = "?league_id=" + this.props.activeLeague.id + "&m_id=" + this.state.m_id + "&type=" + statType
        }
        if (this.props.activeLeague) {
            if (this.props.fromBottomTab) {
                fetchMlbMyMatchup(queryString)
                    .then((response) => {
                        this.handleAPISuccess(response)
                    })
                    .catch(({ message }) => {
                        toastError(message)
                    })
            } else {
                fetchMlbMatchup(queryString)
                    .then((response) => {
                        this.handleAPISuccess(response)
                    })
                    .catch(({ message }) => {
                        toastError(message)
                    })
            }
        } else {
            this.setState({ loading: false, noMatchup: true })
        }
    }

    handleAPISuccess(response) {
        if (response.data && response.data.teams) {
            let { totalPeriods } = this.state
            if (this.state.selectedPeriod == null) {
                totalPeriods = [this.state.totalPeriods, ...response.data.totalPeriods]
            }

            this.setState({
                data: response.data,
                loading: false,
                refreshing: false,
                totalPeriods: totalPeriods
            })
        }
        else {
            this.setState({ noMatchup: true, loading: false, refreshing: false })
        }
    }

    getTeamTotalPoints(myPlayers, oppPlayers) {
        var { scRules, scoringFormat } = this.state.data
        if (scoringFormat == "h2h_weekly_points") {
            return mlbGeneratePoints(scRules, myPlayers)
        } else if (scoringFormat == "h2h_each_category") {
            var wlt = mlbH2HEachCatPoints(scRules, myPlayers, oppPlayers)
            return wlt.team1Wlt.w + "-" + wlt.team1Wlt.l + "-" + wlt.team1Wlt.t
        }
    }

    handlePeriodSelect(index) {
        if (index.daily_period == 0) {
            this.setState({ statType: "total", selectedPeriod: null, loading: true, periodIndex: index })
            this.callAPI(null, "total")
        } else {
            // var { data } = this.state
            var reqPeriod = index.daily_period
            this.setState({ periodIndex: index, statType: "daily", selectedPeriod: reqPeriod, loading: true })
            this.callAPI(reqPeriod, "daily")
        }
    }

    parseDailyPeriodDate(date) {
        if (date == 'Total') {
            return 'Total'
        }
        else {
            return moment(date).format('DD-MMM')
        }
    }

    // selectedPeriod() {
    //     console.log('SELECTED PERIOD');
    //     var { totalPeriods, periodIndex } = this.state
    //     if (periodIndex == 0) {
    //         console.log('TOTAL');
    //         return totalPeriods[0]
    //     } else {
    //         console.log('DAY');
    //         let period = totalPeriods.find(period => period.daily_period == periodIndex)
    //         console.log(period, '178');
    //         return period
    //         // return this.parseDailyPeriodDate(totalPeriods.filter(period => {if(period.daily_period == periodIndex) {return period.date}}))
    //     }
    // }

    render() {
        return (

            <>
                {
                    this.state.loading ?
                        <LoaderCenter />
                        :
                        <div class="container-fluid d-flex flex-column w-100 p-0 m-0" style={{ height: '100vh', overflowY: 'scroll', overflowX: 'hidden' }}>
                            <div className='pl-3' style={{ borderBottom: `0.01em solid ${Colors.darkBgBorder}`, minHeight: '52px' }}>
                                <h2 className='pt-2'>Scoreboard</h2>
                            </div>
                            <div className='py-3 px-5 text-light justify-content-between' style={{ backgroundColor: Colors.bgSecondHeader }} >
                                <p className='m-0 bold'><span onClick={() => this.props.history.goBack()} style={{ cursor: 'pointer' }}><i className='mr-2 fa fa-angle-left'></i>Live Matchup</span></p>
                            </div>

                            <div className="container mt-2 flex1 d-flex flex-column" style={{ overflow: 'hidden' }}>
                                <div className="row justify-content-between p-0 m-0">
                                    {/* Team A */}
                                    <div className="col mr-3 p-3 " style={{ backgroundColor: Colors.bgLight, borderRadius: 8 }} >
                                        <div className="row ">
                                            <div className="col text-center">
                                                <img src={this.state.data.teams[0].team_image} style={{ width: 60, borderRadius: 50 }} alt="Lights" />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className="col text-center">
                                                <label style={{ fontSize: 16, fontWeight: "bold" }}>
                                                    {this.state.data.teams[0].team_name}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className="col text-center">
                                                <label className="col col-3" style={{ fontSize: 20, color: Colors.colorPrimary, fontWeight: "bolder", alignSelf: "center", }} >
                                                    {this.getTeamTotalPoints(this.state.data.teams[0].players, this.state.data.teams[1].players)}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {/* !Team A */}

                                    {/* Team B */}
                                    <div className="col ml-3 p-3 " style={{ backgroundColor: Colors.bgLight, borderRadius: 8 }} >
                                        <div className="row ">
                                            <div className="col text-center">
                                                <img src={this.state.data.teams[1].team_image} style={{ width: 60, borderRadius: 50 }} alt="Lights" />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className="col text-center">
                                                <label style={{ fontSize: 16, fontWeight: "bold" }}>
                                                    {this.state.data.teams[1].team_name}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className="col text-center">
                                                <label className="col col-3" style={{ fontSize: 20, color: Colors.colorPrimary, fontWeight: "bolder", alignSelf: "center" }} >
                                                    {this.getTeamTotalPoints(this.state.data.teams[1].players, this.state.data.teams[0].players)}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {/* !Team B */}
                                </div>

                                <div className='w-100 d-flex justify-content-end mt-3 mb-2'>
                                    <div className='w-25'>
                                        <ReactSelect getOptionValue={option => option.daily_period} getOptionLabel={option => this.parseDailyPeriodDate(option.date)} options={this.state.totalPeriods} value={this.state.periodIndex} onChange={this.handlePeriodSelect} className=" mx-1 text-white" styles={this.state.customStyles} closeMenuOnSelect={true} isClearable={false} />
                                    </div>
                                </div>
                                <div className='flex1' style={{ overflow: 'scroll' }}>
                                    {this.state.data.scoringFormat == "h2h_weekly_points" ? (
                                        <MLBWeeklyPointsPlayers data={this.state.data} />
                                    ) : (
                                        <MLBEachCatPlayers data={this.state.data} />
                                    )}
                                </div>
                            </div>
                        </div>
                }
            </>
        );
    }
}

export default withRouter(MLBMatchup);