import React, { useEffect, useState } from 'react'
import { Colors } from '../../../helpers/colors';
import { chevronDownIcon, arenaRejectIcon, arenaAcceptIcon, arenaBackIcon, backArrowIcon } from '../../../helpers/icons';
import { motion } from 'framer-motion/dist/framer-motion';
import { Button, Collapse } from 'reactstrap';
import { defaultColors } from '../../../helpers/colors';
import counterIcon from '../../../assets/images/counter.png'
import { MatchNotificationModal } from '../../../modals/Arena/MatchNotificationModal';
import { MatchNotificationCollapse } from './MatchNotificationCollpase';
import RejectMatchCollapse from './RejectMatchCollapse';
import AcceptMatchCollapse from './AcceptMatchCollapse';
import CounterMatchCollapse from './CounterMatchCollapse';
import WinProbabilityModal from '../../../modals/Arena/WinProbabilityModal';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';

const CounterProposalCollapse = (props) => {
    
    const { matchData, sport, arenaData, refresh, winProbData, status, propMatchId, setActionMatchId } = props

    const isPaid = matchData.selectedLineup.proposedData.isPaid
    const entryFee = matchData.selectedLineup.proposedData.entryFee
    const possibleWinnings = matchData.selectedLineup.proposedData.possibleWin
    const oppData = matchData.selectedLineup
    const startDate = matchData.slot
    const oddsBoost = matchData.oddsBoost
    const matchId = matchData.selectedLineup.proposedData.matchId
    const periods = {FW: "Full Week", WD: "Weekend"};
    const middleModal = useSelector((state) => state.arenaReducer.middleModal);

    const Colors = defaultColors;
    const [isOpen, setIsOpen] = useState(propMatchId !== null ? true : false)
    const [targetCollapse, setTargetCollapse] = useState('default')
    const [prevOffers, setPrevOffers] = useState(false);

    const collapseStep = (title) => {
        setTargetCollapse(title)
    }

    const toggleCollapse = () => {
        setIsOpen(!isOpen)
        // setActionMatchId(null);
    }

    useEffect(() => {
        if (!middleModal) {
            setActionMatchId(null);
        }
    }, [middleModal]);

    return (
        <div className='d-flex flex-column flex1 scrollHidden' style={styles.container}>
            <div style={{ background: !isOpen ? Colors.colorWarning : Colors.colorWarning }}>
                <motion.div 
                    className='mx-auto pointer' style={{width: 25}}
                    onClick={toggleCollapse}
                    animate={{ rotate: isOpen ? 0 : 180 }}
                    transition={{ duration: 0.5 }}
                >
                {chevronDownIcon()}
                </motion.div>
                <div className='d-flex flex1 justify-content-between align-items-center pb-2 px-3'>
                    <h5 className='m-0 mr-1 bold'>Counter Offer</h5>
                    <div className='d-flex align-items-center'>
                        <p className='m-0'>from</p>
                        <img src={oppData.teamImage} className='roundImgSmall mx-2' />
                        <p className='m-0 bold' >{oppData.teamName}</p>
                    </div>
                </div>

                {targetCollapse == "default" ?
                    <>
                        {/* <div className='position-absolute' style={{ top: "14%", background: Colors.colorWarning }}></div> */}
                        <div>
                            <div className='mb-2 mx-3' style={{ border: `3px solid ${Colors.colorWarningLight}`, borderRadius: 10 }}>
                                <div className='d-flex align-items-center justify-content-between small pointer m-0' style={prevOffers ? styles.previousOffer_v2 : styles.previousOffer} onClick={() => setPrevOffers(!prevOffers)}>
                                    <p className='m-0' style={{ fontSize: 12, fontWeight: 500, letterSpacing: 3 }}>PREVIOUS OFFERS</p>
                                    <motion.div 
                                    // className='mx-auto pointer' style={{width: 25}}
                                    animate={{ rotate: prevOffers ? 270 : 90 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                </motion.div>
                                </div>
                                <Collapse isOpen={prevOffers}>
                                    <div className='px-2' style={{ background: Colors.black_90, color: Colors.colorLight, borderBottomLeftRadius: 5, borderBottomRightRadius: 5 }}>
                                        {
                                            matchData?.selectedLineup?.proposedData?.counterHistory?.map((history, index) => (
                                                <div key={index}>
                                                    <h5 className='py-1' style={{ borderBottom: `1px solid ${Colors.darkBgBorder}` }}><span style={{ fontSize: 14, fontWeight: 600 }}>Proposal</span> <span style={{ color: Colors.colorWarningLight, fontSize: 12, fontWeight: 500 }}>{history.mine ? "from Me" : "from Opponent"}</span></h5>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div>
                                                            <h6 className='m-0' style={{ fontSize: 12, fontWeight: 400 }}>Scoring Period</h6>
                                                            <h6 style={{ width: "150px", fontSize: 15, fontWeight: 600 }}>Start {history.timeFrame}</h6>
                                                        </div>
                                                        <div>
                                                            <h6 className='m-0' style={{ fontSize: 12, fontWeight: 500 }}>Wager</h6>
                                                            <h6 className='text-center' style={{ fontSize: 15, fontWeight: 600 }}>{history.entryFee ? `$${history.entryFee}` : "FREE"}</h6>
                                                        </div>
                                                        <div>
                                                            <h6 className='m-0' style={{ fontSize: 12, fontWeight: 500 }}>Odds</h6>
                                                            <h6 className='text-center' style={{ fontSize: 15, fontWeight: 600 }}>{history.oddsBoost ? history.oddsBoost : "N/A"}</h6>
                                                        </div>
                                                        {
                                                            history.possibleWin ?
                                                                <div>
                                                                    <h6 className='m-0 text-center' style={{ fontSize: 12, fontWeight: 500, color: Colors.colorWarningLight }}>Win</h6>
                                                                    <h6 className='text-center' style={{ fontSize: 15, fontWeight: 600, color: Colors.colorWarningLight}}>${history.possibleWin.possibleWin}</h6>
                                                                </div>
                                                            :
                                                            ""
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </>
                    :
                    ""
                }
            </div>
            {isOpen && targetCollapse == "default" ? <>
                <PerfectScrollbar style={{ position: "relative"}}>
                    {/* Counter Info Collapse */}
                    <Collapse isOpen={targetCollapse == 'default' && isOpen}>

                        <div className='py-2 px-2' style={{ background: Colors.colorDark, color: Colors.colorLight }}>
                            <WinProbabilityModal winProbData={winProbData} status={status} slug={"counterProposal"} />
                        </div>
                        
                        <div className='px-2 pt-2' style={{ background: Colors.colorDark, color: Colors.colorLight }}>
                            {/* Stats */}
                            <div className='align-items-center d-flex' style={{ marginBottom: "7px" }}>
                                <p className='m-0 flex1' style={{ fontSize: 13, letterSpacing: 2 }}>BET AMOUNT</p>
                                <p className='m-0' style={styles.statBox}>{isPaid  ? `${entryFee}$` : "FREE"}</p>
                            </div>
                            { 
                                oddsBoost != null 
                                &&
                                <div className='align-items-center d-flex' style={{ marginBottom: "7px" }}>
                                    <p className='m-0 flex1' style={{ fontSize: 13, letterSpacing: 2 }}>ODDS BOOST</p>
                                    <p className='m-0' style={styles.statBox}>{oddsBoost}</p>
                                </div>
                            }

                            <div className='align-items-center d-flex' style={{ marginBottom: "7px" }}>
                                <p className='m-0 flex1' style={{ fontSize: 13, letterSpacing: 2 }}>START DATE</p>
                                <p className='m-0' style={styles.statBox}>{periods[startDate]}</p>
                            </div>

                            {
                                isPaid 
                                &&
                                <div className='align-items-center d-flex' style={{ marginBottom: "7px" }}>
                                    <p className='m-0 flex1' style={{ fontSize: 13, letterSpacing: 2 }}>POSSIBLE WINNINGS</p>
                                    <p className='m-0' style={styles.statBox}>{possibleWinnings.possibleWin}</p>
                                </div>
                            }
                            

                            {/* Actions */}
                            <div className='pb-2'>
                                <div className="d-flex">
                                    <Button className='d-flex align-items-center flex1 mr-1 justify-content-center bold' style={{color:Colors.colorDanger, backgroundColor: Colors.colorDangerTransparent, border: `1px solid ${Colors.rejectColor}`}} onClick={() => collapseStep('reject')}> 
                                        <div style={styles.buttonIcon}>{arenaRejectIcon()}</div>
                                        Reject
                                    </Button>

                                    <Button className='d-flex align-items-center flex1 ml-1 justify-content-center bold' style={{color:Colors.colorPrimary, backgroundColor: Colors.colorPrimaryDark, border: `1px solid ${Colors.mlbPrimaryColor}`}} onClick={() => collapseStep('accept')}> 
                                        <div style={styles.buttonIcon}>{arenaAcceptIcon()}</div>
                                        Accept
                                    </Button>
                                </div>
                                {
                                    oppData?.proposedData?.counterStatus != "SECOND_COUNTER" &&
                                    <Button className='bold w-100 mt-2' style={{color:Colors.colorWarning, backgroundColor: Colors.colorWarningTransparent, border: `1px solid ${Colors.colorWarningLight}`}} onClick={() => collapseStep('counter')}> 
                                        <img src={counterIcon} style={{width: 20, height: 20, marginRight: 5}} />
                                        Counter
                                    </Button>
                                }
                            </div>
                        </div>
                    </Collapse>
                </PerfectScrollbar>
            </> : ""}

            {/* Reject Collapse */}
            <RejectMatchCollapse isOpen={targetCollapse == 'reject' && isOpen} selectedLineup={oppData} toggle={() => collapseStep('default')} successCallback={refresh} />
            {/* Accept Collapse */}

            <AcceptMatchCollapse isOpen={targetCollapse == 'accept' && isOpen} selectedLineup={oppData} toggle={() => collapseStep('default')} successCallback={refresh}/>

            {/* Counter Collapse */}
            <CounterMatchCollapse isOpen={targetCollapse == 'counter' && isOpen} selectedLineup={oppData} arenaData={arenaData} sport={sport} successCallback={refresh} cancelCallback={() => collapseStep('default')}  counterMatchId={matchId} isCounter={true}  />
        </div>
    )
}

export default CounterProposalCollapse


const styles = {
    container: {
        // background: "linear-gradient(180deg, #F0BE30 35.42%, rgba(240, 190, 48, 0) 100%)",
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
        color:'#000'
    },
    statBox: {
        minWidth: 70,
        backgroundColor: Colors.arenaLineupPlayerRow,
        padding: 5,
        borderRadius: 5,
        textAlign: 'center',
        fontWeight: 600
    },
    previousOffer: {
        backgroundColor: Colors.colorWarningLight,
        padding: 5,
        borderRadius: 5,
        marginBottom: 10
    },
    previousOffer_v2: {
        backgroundColor: Colors.colorWarningLight,
        padding: 5,
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        marginBottom: 10
    },
    buttonIcon: {
        width: 20,
        height: 20,
        marginRight: 5
    }
}