import React, { useState } from 'react';
// import ArenaPlayerCardModal from './ArenaPlayerCardModal';
import { mmConfirmNewDayLineup } from '../../apis/Arena/MatchMakerApis';
import LoadingOverlay from 'react-loading-overlay';
import { Button, Modal } from 'reactstrap';
import { StickyTable, Row, Cell } from 'react-sticky-table';
// import { Colors } from '../../helpers/colors';
import { arenaLineupIcon } from '../../helpers/icons';
import ArenaPlayerRow from '../../components/Matchmaker/ArenaOnboarding/ArenaPlayerRow';
import { STYLES } from '../../helpers/stylesHelper';
import { useColors } from '../../helpers/CustomHooks/useColors';
import { useSelector } from 'react-redux';

const OnboardedPlayerModal = (props) => {
	const Colors = useColors();
	const { visible, handleEdit, handleConfirm, proposedData, players, timeFrame } = props
	const [ loading, setLoading ] = useState(false)
	const [ playerCardModal, setPlayerCardModal ] = useState(false)
	const [ tappedPlayerId, setTappedPlayerId ] = useState()

	var tf = null
	if(proposedData){
		tf = proposedData?.timeFrameKey
	}else{
		tf = timeFrame
	}

	const handleConfirmLocal = () => {
		setLoading(true);
		mmConfirmNewDayLineup({
			teamId: proposedData.myTeamId,
			timeFrame: tf
		}).then((resp) => {
				handleConfirm()
			}).catch(() => {
				alert('Seomthing went wrong')
				setLoading(false);
			});
	};

	return (
        <Modal isOpen={visible} size="lg" >
		<LoadingOverlay active={loading} text="Saving..." fadeSpeed={0} spinner className="d-flex flex-column flex1 scrollHidden">
			<div style={{ flex: 1, backgroundColor: Colors.bgDark, borderRadius: 10 }}>
				<div style={{ justifyContent: 'space-between', flex: 1 }}>
					<div style={{ flex: 1, padding: 20 }}>
                        {/* <div className='pt-2' style={{ aspect: 1, width: 100, height: 100 }}>{arenaLineupIcon()}</div> */}
						<div className='my-3'>
							<h2 className='greenText' >
								{"Confirm your lineup\nfor today's arena"}
							</h2>
							<div className='d-flex align-items-center my-4'>
								<img className="roundImgMed" src={proposedData.myTeamImage } />
								<div style={{ marginLeft: 10 }}>
									<p className='mb-0 lead' >{proposedData.myTeamName}</p>
								</div>
							</div>
							<p className='lead' >Lineup you selected for previous day:</p>
                            <div style={{width: '100%', height: "50vh"}}>
								<StickyTable borderColor={Colors.darkBgBorder}>
									<Row>
										<Cell style={{ backgroundColor: Colors.bgLight }}>POS</Cell>
										<Cell style={{ backgroundColor: Colors.bgLight }}>PLAYERS</Cell>
										<Cell style={{ backgroundColor: Colors.bgLight }}>GAMES</Cell>
									</Row>
									{players.map((player, index) => (
										<ArenaPlayerRow
											togglePlayerCard={() => setPlayerCardModal(!playerCardModal)}
											setTappedPlayerId={(playerId) => setTappedPlayerId(playerId)}
											index={index}
											disableAction={true}
											key={player.playerId}
											player={player}
										/>
									))}
								</StickyTable>
							</div>
						</div>
					</div>
					<div className='d-flex align-items-center justify-content-center mb-4'>
						<Button className="mr-4" onClick={handleEdit} style={STYLES.BUTTON_PRIMARY_TRANSPARENT} >
							Edit
						</Button>
						<Button onClick={handleConfirmLocal} style={STYLES.BUTTON_PRIMARY_TRANSPARENT}>
							Confirm and Accept
						</Button>
					</div>
				</div>
			</div>
			{/* {playerCardModal && <ArenaPlayerCardModal playerId={tappedPlayerId} visible={playerCardModal} toggle={() => setPlayerCardModal(!playerCardModal)} />} */}
		</LoadingOverlay>
        </Modal>
	);
};

export default OnboardedPlayerModal;
