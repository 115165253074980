import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion/dist/framer-motion"
import queryString from 'query-string';
import NewClaimTeam from './NewClaimTeam';
import { fadeIn } from '../NewUserOnboarding/animationValues';
import OnboardingArenaLineup from '../NewUserOnboarding/OnboardingArenaLineup';
import OnboardingWallet from '../NewUserOnboarding/OnboardingWallet';
import { useHistory } from 'react-router-dom';
import store from '../../store';
import OnboardingTeamAvatar from '../NewUserOnboarding/OnboardingTeamAvatar';
import { defaultColors, mlbColors, nflColors, nbaColors } from '../../helpers/colors';
import { saveArenaSport } from '../../apis/Arena/MatchMakerApis';
import { connect } from 'react-redux';

const NewClaimBody = (props) => {
	const { step, updateStep, claimData, hasLeague, saveArenaSport } = props;
	const history = useHistory()
	const { onboardBySport } = store.getState().leagueJoin
	var Colors = defaultColors
	const [ teamId, setTeamId ] = useState()
	const [ sport, setSport ] = useState()

	useEffect(() => {
		
		switch(sport){
			case "MLB":
				Colors = mlbColors
				break;
			case "NFL":
				Colors = nflColors
				break;
			case "NBA":
				Colors = nbaColors
				break;
			default:
				Colors = defaultColors
		}
    }, [sport])

	const successCallback = () => {
		if(hasLeague && onboardBySport[sport]){
			history.push("/newHome")
		}
		else {
			updateStep("lineupSelected")
		}
	}

	// const successLineupSelected = () => {
	// 	updateStep("lineupSelected")
	// }

	const claimTeamSuccess = (sport) => {
		// if(hasLeague) {
		// 	updateStep("lineupSelected")
		// }
		// else {
			setSport(sport)
			updateStep("leagueImported")
			store.dispatch({type: "FORCE_REFRESH_ARENA_DATA", payload: true});
			store.dispatch({type: "SAVE_ARENA_SPORT", sport: sport});
		// }
	}

	const setLineupTeamId = (teamId) => {
		setTeamId(teamId)
	}

	return (
		<motion.div
			className="d-flex flex-column flex1 scrollHidden"
			// style={{ marginTop: "4rem"}}
			animate={step != "default" ? 'show' :'hide'}
			transition={{ duration: 0.5 }}
			initial={false}
			variants={fadeIn}>
			
            {/* <NewClaimTeam claimData={claimData} successCallback={successCallback} {...props} /> */}
			<NewClaimTeam claimData={claimData} successCallback={claimTeamSuccess} {...props} />

			<OnboardingTeamAvatar teamId={teamId} leagueId={claimData.leagueId} successCallback={successCallback} {...props} Colors={Colors} hasLeague={hasLeague} />
			
			{/* <OnboardingArenaLineup leagueId={claimData.leagueId} setLineupTeamId={setLineupTeamId} viaClaim={true} successCallback={successLineupSelected} {...props} Colors={Colors} sport={sport} /> */}
			
			<OnboardingWallet {...props} Colors={Colors} />
		</motion.div>
	);
};

const mapDispatchToProps = { saveArenaSport };
export default connect(null, mapDispatchToProps)(NewClaimBody);
