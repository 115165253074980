const ENDPOINTS = {
    FETCH_REFERRAL_LEADERBOARD: '/leaderboard/referral',
    FETCH_ARENA_LEADERBOARD: '/leaderboard/arena',
}

export const fetchReferralLeaderboards = () => {
    return window.axios.get(ENDPOINTS.FETCH_REFERRAL_LEADERBOARD, {})
        .then(response => {
            return Promise.resolve(response.data.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const fetchArenaLeaderboards = () => {
    return window.axios.get(ENDPOINTS.FETCH_ARENA_LEADERBOARD, {})
        .then(response => {
            return Promise.resolve(response.data.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}