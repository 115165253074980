import React, { Component } from 'react';
import { Colors } from '../../../helpers/colors';
import { fetchBasicScoreboard, fetchScoreboard } from '../../../apis/SeasonLong/Scoreboard/ScoreboardAPI';
import { toastError } from '../../../helpers/toasts';
import { connect } from 'react-redux';
import ReactSelect from 'react-select';
import { STYLES } from '../../../helpers/stylesHelper';
import { LoaderCenter } from '../../../components/Loader';
import SLMatchup from '../../../components/SeasonLong/Scoreboard/SLMatchup';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { ColorContext } from '../../../context/ColorProvider';

class Scoreboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            weekIndex: 0,
            selectedWeek: 0,
            loading: true,
            loadingAdditionalData: true,
            refreshing: true,
            hasWinnerBrackets: false,
            hasWinnersConsBracket: false,
            hasLosersConsBracket: false,
            sc_rules: null,
            scoreboardData: {},
            matchups: []
        }

        this.handleWeekChange = this.handleWeekChange.bind(this)
    }
    static contextType = ColorContext;

    componentDidMount() {
        this.callAPI()
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.leagueId != this.props.leagueId) {
            this.callAPI()
        }
    }

    callAPI(week = undefined) {
        this.setState({
            loading: true,
            loadingAdditionalData: true,
            hasLosersConsBracket: false,
            hasWinnerBrackets: false,
            hasWinnersConsBracket: false
        })

        fetchBasicScoreboard({ league_id: this.props.leagueId, week })
            .then((response) => {
                let { hasWinnerBrackets, hasWinnersConsBracket, hasLosersConsBracket } = this.state
                let weekIndex = this.findWeekIndex(response.data.game_weeks, week || response.data.week)

                response.data.matchups.map((match) => {
                    if (match.is_consolation == false && match.in_winner_bracket) {
                        hasWinnerBrackets = true
                    } else if (match.is_consolation == false && match.in_loser_bracket) {
                        hasWinnersConsBracket = true
                    } else if (match.is_consolation == true) {
                        hasLosersConsBracket = true
                    }
                })

                this.setState({
                    weekIndex: weekIndex,
                    selectedWeek: response.data.week,
                    hasWinnerBrackets: hasWinnerBrackets,
                    hasWinnersConsBracket: hasWinnersConsBracket,
                    hasLosersConsBracket: hasLosersConsBracket,
                    scoreboardData: {...this.state.scoreboardData, ...response.data},
                    loading: false
                })
            })
            .catch(({ message }) => {
                this.setState({
                    weekIndex: this.state.weekIndex,
                    loading: false
                })
                // toastError(message)
                toastError('Something went wrong, Try Again.')
            })

        fetchScoreboard({ league_id: this.props.leagueId, week })
            .then((response) => {
                this.setState({
                    scoreboardData: {...this.state.scoreboardData, ...response.data},
                    matchups: response.data.matchups,
                    loadingAdditionalData: false
                })
            })
            .catch(({ message }) => {
                // toastError(message)
                toastError('Something went wrong, Try Again.')
            })
    };

    findWeekIndex(weeks, week) {
        let ind = 0
        weeks.map((val, index) => {
            if (val.weekArray.includes(week)) {
                ind = index
            }
        })
        return ind
    }

    // Handle weeks filter
    handleWeekChange(week) {
        this.callAPI(week.week)
    }

    render() {
        const Colors = this.context;
        return (<>
            <div className='d-flex justify-content-between align-items-center px-3' style={{ borderBottom: `0.01em solid ${Colors.darkBgBorder}`, minHeight: '52px' }}>
                <h2 className='pt-2'>Scoreboard</h2>
                {!this.state.loading && <ReactSelect getOptionValue={option => option.week} getOptionLabel={option => option.value} options={this.state.scoreboardData.game_weeks} value={this.state.scoreboardData.game_weeks[this.state.weekIndex]} onChange={this.handleWeekChange} className=" mx-1 text-white" styles={STYLES.REACT_SELECT} closeMenuOnSelect={true} isClearable={false} />}
            </div>
            <div className='container d-flex flex-column flex1 scrollHidden'>
                <div className="col d-flex flex-column scrollHidden">
                    <div className="d-flex flex-column flex1 scrollHidden mb-4 mt-2" style={{ backgroundColor: Colors.bgDark, borderRadius: 15, border: `0.01em solid ${Colors.darkBgBorder}` }}>
                        <PerfectScrollbar>
                            {this.state.loading ? (
                                <LoaderCenter />
                            ) : (
                                <div className="w-100 h-100" id='players-table'>
                                {<div className='d-flex flex-wrap'>
                                        {
                                            this.state.matchups.map((matchup, index) => {
                                                if (!matchup.in_winner_bracket && !matchup.in_loser_bracket && !matchup.is_consolation) {
                                                    return (
                                                        <SLMatchup matchup={matchup} sc_rules={this.state.scoreboardData.sc_rules} key={index} week={this.state.selectedWeek} loading={this.state.loadingAdditionalData} />
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                }
                                {
                                    this.state.hasWinnerBrackets &&
                                    <>
                                        <h3 className='m-2'>Winners Bracket</h3>
                                        <div className='d-flex flex-wrap'>
                                            {
                                                this.state.matchups.map((matchup, index) => {
                                                    if (matchup.in_winner_bracket && !matchup.is_consolation) {
                                                        return (
                                                            <SLMatchup matchup={matchup} sc_rules={this.state.scoreboardData.sc_rules} key={index} week={this.state.selectedWeek} loading={this.state.loadingAdditionalData} />
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    </>
                                }
                                {
                                    this.state.hasWinnersConsBracket &&
                                    <>
                                        <h3 className='m-2'>Winners Consolation Bracket</h3>
                                        <div className='d-flex flex-wrap'>
                                            {
                                                this.state.matchups.map((matchup, index) => {
                                                    if (matchup.in_loser_bracket && !matchup.is_consolation) {
                                                        return (
                                                            <SLMatchup matchup={matchup} sc_rules={this.state.scoreboardData.sc_rules} key={index} week={this.state.selectedWeek} loading={this.state.loadingAdditionalData} />
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    </>
                                }
                                {
                                    this.state.hasLosersConsBracket &&
                                    <>
                                        <h3 className='m-2'>Losers Consolation Bracket</h3>
                                        <div className='d-flex flex-wrap'>
                                            {
                                                this.state.matchups.map((matchup, index) => {
                                                    if (matchup.is_consolation) {
                                                        return (
                                                            <SLMatchup matchup={matchup} sc_rules={this.state.scoreboardData.sc_rules} key={index} week={this.state.selectedWeek} loading={this.state.loadingAdditionalData} />
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                            )}
                            
                        </PerfectScrollbar>
                    </div>
                </div>
            </div>
        </>
        );
    }
}

const mapStateToProps = (state) => ({ leagueId: state.leagueJoin.leagueId })
export default connect(mapStateToProps, null)(Scoreboard);