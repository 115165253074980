import React, { useEffect, useState } from 'react';
import { Button, Modal, Input, ModalBody } from 'reactstrap';
import ComparionView from '../../components/Matchmaker/ComparisonView';
// import { Colors } from '../../helpers/colors';
import AcceptMatchModal from './AcceptMatchModal';
import ArenaMatchModal from './ArenaMatchModal';
import RejectMatchModal from './RejectMatchModal';
import counterIcon from "../../assets/images/counter.png"
import { useColors } from '../../helpers/CustomHooks/useColors';
import { useSelector } from 'react-redux';
import MatchamakerSchedule from '../../components/Matchmaker/MatchamakerSchedule';
import MatchmakerSeasonAvg from '../../components/Matchmaker/MatchmakerSeasonAvg';
import { getStore } from '../../helpers/common-methods';

export const MatchNotificationModal = (props) => {
    const Colors = useColors();
	const { modal, toggle, matchData, sport } = props;
	const [activeView, setActiveView ] = useState('h2h')
    const [teamView, setTeamView] = useState('my') //my or opp
    const mmLineupDetail = useSelector((state) => state.arenaReducer.mmLineupDetail);

    const getView = () => {
        return (
            {
                "h2h": <ComparionView forNotification={true} sport={sport} teamId={matchData?.selectedLineup?.teamId ? matchData?.selectedLineup?.teamId : matchData?.oppTeamId} proposedData={matchData?.selectedLineup?.proposedData} comparisonData={mmLineupDetail?.comparisonData} />,
                "schedule": <MatchamakerSchedule teamView={teamView} setTeamView={setTeamView} comparisonData={mmLineupDetail?.comparisonData} comparisonDataLoading={mmLineupDetail?.loading} sport={sport} />,
                "season": <MatchmakerSeasonAvg teamView={teamView} setTeamView={setTeamView} comparisonData={mmLineupDetail?.comparisonData} comparisonDataLoading={mmLineupDetail?.loading} sport={sport} />
            }[activeView]
        )
    }

    const handleViewChange = (view) => {
        setActiveView(view);
        setTeamView("my");
	};

    useEffect(() => {
        return (() => {
            getStore().dispatch({ type: "SAVE_MM_LINEUP_DETAIL", payload: {} });
        });
    }, []);

	return (
		<div className='d-flex flex-column flex1 scrollHidden'>
            <div className='d-flex flex-column flex1 scrollHidden position-relative' style={{maxHeight: "calc(100vh - 50px)"}} >
			    <div className='d-flex justify-content-between align-items-start px-3 pt-3' style={{ backgroundColor: Colors.colorDark, paddingBottom: "28px" }}>
                    <div className='d-flex align-items-center '>
                        <img className='roundImgMedNoOutline' style={{ height: 80, width: 80 }} src={matchData?.selectedLineup?.teamImage ? matchData?.selectedLineup?.teamImage : matchData?.oppTeamImg} alt="" />
                        <div className='d-flex flex-column pl-2'>
                            {matchData?.propStatus ? 
                                <p className='m-0 text-center' style={{ background: matchData?.propStatus == "P" ? Colors.mlbPrimaryColor : Colors.nbaPrimaryColor, color: Colors.bgDark, borderRadius: 50, fontWeight: 600, width: "80px" }}>{matchData?.propStatus == "P" ? "Proposal" : "Counter"}</p>
                            :   
                                <div className='d-flex align-items-center'>
                                    <p className='m-0 px-2' style={{ background: Colors.mlbPrimaryColor, borderTopLeftRadius: 100, borderBottomLeftRadius: 100, color: Colors.black_90 }}>Rematch</p>
                                    <p className='m-0 px-2' style={{ background: Colors.black_90, borderTopRightRadius: 100, borderBottomRightRadius: 100 }}>{matchData?.oppTeamRecord}</p>
                                </div>
                            }
                            <p className='m-0 alex' style={{ fontSize: 24, fontWeight: 600 }}>{matchData?.selectedLineup?.teamName ? matchData?.selectedLineup?.teamName : matchData?.oppTeamName}</p>
                        </div>
                    </div>
                    <h3><i onClick={toggle} class="cui-circle-x pointer" /></h3>
                </div>
                {sport != "NFL" && <div className="pointer d-flex align-items-center" style={{ color: Colors.colorSecondary, backgroundColor: Colors.colorDark, height: "48px"}}>
                    <p className='m-0 flex1 p-2 pointer bold text-center' onClick={() =>  handleViewChange('h2h')} style={{fontSize: 16, color: activeView == 'h2h' ? Colors.colorPrimary : null, borderBottom: activeView == 'h2h' ? `2px solid ${Colors.colorPrimary}` : '0'}}>H2H</p>
                    <p className='mb-0 flex1 p-2 pointer bold text-center' onClick={() => handleViewChange('schedule')} style={{fontSize: 16, color: activeView == 'schedule' ? Colors.colorPrimary : null, borderBottom: activeView == 'schedule' ? `2px solid ${Colors.colorPrimary}` : '0'}} >Schedule</p>
                    <p className='mb-0 flex1 p-2 pointer bold text-center' onClick={() => handleViewChange('season')} style={{fontSize: 16, color: activeView == 'season' ? Colors.colorPrimary : null, borderBottom: activeView == 'season' ? `2px solid ${Colors.colorPrimary}` : '0'}} >{sport == "MLB" ? "Projections" : "Season Avg."}</p>
			    </div>}
                {getView()}
            </div>
		</div>
	);
};
