import React from 'react'
import { useHistory } from 'react-router-dom';
import { useColors } from '../../helpers/CustomHooks/useColors'

const Footer = () => {
  const history = useHistory();
  const Colors = useColors();
  return (
    <div className='py-3' style={{ background: Colors.bgDark }}>
        <p className='text-center m-0'>
          <a href="/privacy" style={{ color: Colors.colorLink }}>Privacy Policy</a>
          <a className='ml-4' href="/terms-and-conditions"  style={{ color: Colors.colorLink }}>Terms & Conditions</a>
          {/* <a href="/responsiblegaming" className='ml-4' style={{ color: Colors.colorLink }}>Responsible Gaming</a> */}
        </p>
        <div className="d-flex flex-column align-items-center mt-2" >
          <p style={{fontSize: ".75rem", textAlign: "center", color: "grey"}} className='mb-0' >This is a real-money fantasy sports app. Please play responsibly and only bet what you can afford. To review our responsible gaming policy, visit <a target={"_blank"} style={{ color: Colors.colorLink }} href="https://bettle.co/responsiblegaming" >https://bettle.co/responsiblegaming</a></p>
          <p style={{fontSize: ".75rem", textAlign: "center", color: "grey"}} className='mb-0' >If you or someone you know has a gambling problem, help is available. Call 1-800-GAMBLER or go to <a style={{ color: Colors.colorLink }} target="_blank" href="https://ncpgambling.org" >https://ncpgambling.org</a></p>
        </div>
    </div>
  )
}
export default Footer