import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userBalance } from '../../apis/Gidx/GidxApi';
// import { Colors } from '../../helpers/colors';
import { defaultImage, errorMessage } from '../../helpers/common-methods';
import ProfileModal from '../../components/SeasonLong/Profile/ProfileModal';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/auth';
import { useColors } from '../../helpers/CustomHooks/useColors';

const WalletHeader = (props) => {
    const Colors = useColors();
    const activeLeague = useSelector(state => state.leagueJoin.activeLeague)
    const [balance, setBalance] = useState(0)
    const [profileModal, setProfileModal] = useState(false);
    const [currentViewModal, setCurrentViewModal] = useState('info');
    const history = useHistory()

    useEffect(() => {
        userBalance().then((resp) => {
            setBalance(resp.balance)
        }).catch((err) => {
            errorMessage(err.message)
        })
    }, [])

    const toggleProfileModal = () => {
        setProfileModal(!profileModal);
    };

    const handleLogout = () => {
        props.logoutUser(() => history.push('/signin'));
    }

    return (
        <>
            <div style={{ backgroundColor: Colors.sidebarHeader }} >
                <div className='pl-3 pr-3' style={{ borderBottom: "1px solid " + Colors.darkBgBorder, borderRight: "1px solid " + Colors.darkBgBorder }}  >
                    <div className='d-flex align-items-center pt-2 pb-2 justify-content-between' >
                        <div className='d-flex align-items-center pointer' onClick={() => toggleProfileModal()}>
                            <img className='roundImgSmall' src={activeLeague.team_image ? activeLeague.team_image : defaultImage()} />
                            <p className='m-0 ml-2 bold pointer'>{activeLeague.team_name}</p>
                        </div>
                        <div id="addFundsTopNav" className='d-flex align-items-center pointer' onClick={() => history.push("/payments/wallet")} >
                            <p className='m-0 ml-2 mr-2 bold greenText' style={{ fontSize: "1rem" }} >${balance}</p>
                            <i class="fa fa-plus-square-o greenText" style={{ fontSize: "1.5rem" }} aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>
            {profileModal && <ProfileModal isOpen={profileModal}
                toggle={toggleProfileModal}
                onClick={toggleProfileModal}
                currentViewModal={currentViewModal}
                onClickedit={() => setCurrentViewModal('editprofile')}
                onClicknotify={() => setCurrentViewModal('notifications')}
                onClickpass={() => setCurrentViewModal('password')}
                logoutUser={() => handleLogout()}
                auth={props.auth}
                onOkay={toggleProfileModal}
                onBack={() => setCurrentViewModal('info')} />}
        </>
    )
}

const mapDispatchToProps = { logoutUser };
const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletHeader);