export const mapping =  {
    one_to_one_channel: "presence-dream-user-",
    user_chat_emoji_event : "dream-user-emoji-event-",

    personal_presence: "presence-dream-channel-",
    user_chat_event: "dream-user-event-",
    group_channel : "presence-dream-group-",
    group_event : "dream-group-event-",
    group_emoji_event : "dream-group-emoji-event-",

    lg_event : "dream-lg-event-",
    lg_channel : "presence-dream-lg-",
    global_online : "presence-dream-global-online",
    global_online_event : "dream-global-online-event",

    thread_event : "dream-thread-event-",
    thread_channel : "presence-dream-thread-",
    thread_emoji_event : "dream-thread-emoji-event-"
}