import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {  Modal, ModalHeader, ModalBody, ModalFooter, Button, UncontrolledCollapse, InputGroupAddon, InputGroup, Input, Badge } from 'reactstrap';
import { plusIcon } from '../../helpers/icons';
import { updateUnreadCount } from '../../apis/Sidebar/LeagueAPI'
import { ColorContext } from '../../context/ColorProvider';
class DirectMessageCollapsible extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            collapse: true,
            users:[],
            query:'',
            status:false,
          };

        this.toggle = this.toggle.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
    }

    static contextType = ColorContext;

    toggle() {
        this.setState({
          modal: !this.state.modal,
          query:''
        });
      }
    
      toggleCollapse() {
          this.setState({
              collapse: !this.state.collapse
          })
      }
    
      handleChange(e){
         this.setState({query:e.target.value})
      }

    /**
     * Reset the count of opened chat if it has unread messages
     * @param {string} id - id of user to reset unread count
     */
    resetCount(id){
        let data = this.props.users

        if(data != undefined) {
            data[id].unread_messages = 0
            this.props.updateUnreadCount(data, 'user')
        }
    }

      onChatChange(id,unread_messages){
        this.props.history.push(`/chat/user/${id}`)

        if(unread_messages > 0) {
            this.resetCount(id)
        }

        if(this.state.modal){
            this.setState({
                modal:false, 
                query:''
            })
        }
        else {
            this.updateDM()
        }
      }

      updateDM() {
        this.setState({
            status: !this.state.status
        })
    }
      
    render() { 
        const Colors = this.context;
        return ( 
            <>
                {/* Collapsible Menu */}
                <div className='col-12'>
                    <h6 className="text-truncate bold d-flex align-items-center"><a className='flex1' href='#' onClick={this.toggleCollapse} id='direct-message'><span className={`pr-1 fa fa-caret-${this.state.collapse?'down':'right'}`}></span>Direct Messages</a><a href='#' onClick={this.toggle}><div style={{width:15, height:15}}>{ plusIcon() }</div></a></h6>
                    <UncontrolledCollapse toggler='#direct-message' isOpen={this.state.collapse}>

                        {/* Users */}
                        {
                            Object.values(this.props.users).map(data => {
                                return (
                                    <div className="d-flex flex-row my-2 text-white align-items-center" onClick={() => this.onChatChange(data.id, data.unread_messages)} style={{cursor:'pointer'}} key={data.id}>
                                        <div className="ml-0 mr-2 d-flex justify-content-center align-items-center rounded-circle" style={{width:25, height:25, border:`${data.lg_online?'2px solid'+Colors.colorPrimary :''}`}}>
                                            {/* <i  className='rounded position-absolute' style={{backgroundColor:`${data.lg_online? Colors.colorPrimary : Colors.colorSecondary}`,bottom:3, right:0, height:5, width:5}}></i> */}
                                            <img className="m-1 img-avatar" src={data.image} ></img>
                                        </div>
                                        <div className="m-0 flex1 p-0 text-truncate">
                                            <h6 className="text-truncate m-0" >{data.team_name}</h6>
                                        </div>
                                        <div>
                                            <Badge className={`pull-right ${data.unread_messages == 0 ? 'd-none':null}`} style={{backgroundColor:Colors.colorPrimary}} pill>{data.unread_messages}</Badge>
                                        </div>
                                    </div>
                                )}
                        )}
                    </UncontrolledCollapse>
                </div>
                {/* !Collapsible Menu */}  

                {/* Modal */}
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} centered>
                    <ModalHeader className='border-0 m-0 px-3 py-0 pt-3' style={{backgroundColor:Colors.bgLight}} cssModule={{'modal-title': 'w-100'}}>
                        <h4 className='bold text-truncate'>Direct Messages <i className='fa fa-close pull-right' onClick={this.toggle} style={{cursor:'pointer'}}></i></h4>
                        {/* Search */}
                        
                        <div className='mt-4' style={{color:Colors.secondary}}>
                                    <div className="input-group " style={{border:`0.01em solid ${Colors.colorSecondary}`, borderRadius:4}}>
                                        <span className="input-group-prepend">
                                            <span className="btn  border-0" >
                                                <i className="fa fa-search" />
                                            </span>
                                        </span>
                                        <input className="form-control border-0 " type="search" id="player-search" placeholder='Search a Team'
                                          onChange={this.handleChange.bind(this)}     
                                        />
                                    
                                    </div>
                                </div>
                        {/* !Search */}
                    </ModalHeader>
                    <ModalBody style={{backgroundColor:Colors.bgLight,maxHeight: 'calc(100vh - 300px)',minHeight: 'calc(100vh - 300px)', overflowY: 'auto'}}>
                        
                        <div>
                        {
                            Object.values(this.props.users).filter(teams => teams.team_name.toLowerCase().includes(this.state.query.toLowerCase())).map(data =>
                                <div onClick={() => this.onChatChange(data.id, data.unread_messages) } className="d-flex flex-row text-white align-items-center p-2" id="single-message">
                                    <div className="mx-0 p-0">
                                        <img width='40px' className="rounded mr-2" src={data.image} />
                                    </div>
                                    <div className="m-0 flex1 p-0 ml-3">
                                        <h6 className='m-0 bold' style={{cursor:'pointer'}} >{data.team_name}</h6>
                                    </div>
                                    <div>
                                        <Badge className={`pull-right ${data.unread_messages == 0 ? 'd-none':null}`} style={{backgroundColor:Colors.colorPrimary}} pill>{data.unread_messages}</Badge>
                                    </div>
                                    {/* <button  className='badge-pill border-0' style={{backgroundColor:Colors.colorPrimary}}>Chat</button> */}
                                    {/* {!data ? <h4>no user found</h4> : null} */}
                                </div>

                                // <div className="d-flex flex-row my-2 text-white" onClick={() => this.onChatChange(data.id, data.unread_messages)} style={{cursor:'pointer'}} key={data.id}>
                                // <div className="ml-0 mr-2 d-flex justify-content-center rounded-circle" style={{width:25, height:25, border:`${data.lg_online?'2px solid'+Colors.colorPrimary :''}`}}>
                                //     {/* <i  className='rounded position-absolute' style={{backgroundColor:`${data.lg_online? Colors.colorPrimary : Colors.colorSecondary}`,bottom:3, right:0, height:5, width:5}}></i> */}
                                //     <img className="m-1" src={data.image} ></img>
                                // </div>
                                // <div className="m-0 flex1 p-0 text-truncate">
                                //     <h6 className="text-truncate" style={{ color: Colors.colorSecondary }}>{data.team_name}</h6>
                                // </div>
                                // <div>
                                //     <Badge className={`pull-right ${data.unread_messages == 0 ? 'd-none':null}`} style={{backgroundColor:Colors.colorPrimary}} pill>{data.unread_messages}</Badge>
                                // </div>
                                // </div>
                            )}
                            
                        </div>
                    </ModalBody>
                    <ModalFooter className='border-0 py-1 pb-3' style={{backgroundColor:Colors.bgLight}}>
                        {/* <Button className='border-0' onClick={this.toggle} style={{backgroundColor:Colors.colorSecondary}}>Cancel</Button> */}
                    </ModalFooter>
                </Modal>
                {/* !Modal */}

            </>
         );
    }
}
 
const mapDispatchToProps = { updateUnreadCount }
const mapStateToProps = (state) => ({ leagueId: state.leagueJoin.leagueId})
export default connect(mapStateToProps, mapDispatchToProps) (withRouter(DirectMessageCollapsible));