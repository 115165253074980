import React, {useState, useEffect} from "react"
import styled, {css} from 'styled-components'
import {Nav, NavItem, NavLink} from "reactstrap"
import { Link } from "react-router-dom"
import { useColors } from "../../../helpers/CustomHooks/useColors"
// import { Colors } from "../../../../helpers/colors"

const PlayerCardNavNba = (props) => {
    const Colors = useColors();
    const CNavLink = styled(NavLink)`
        white-space: nowrap;
    `

    const CNav = styled(Nav)`
        background: #333D44;
        flex-wrap: nowrap;
        overflow-x: auto;
    `
    
    const [activeTab, setActiveTab] = useState(4)

    // Select Latest News tab by Default in case of NBA
    useEffect(() => {
        handleTab(4)
    }, [])

    const handleTab = tab => {
        setActiveTab(tab)
        props.handleActiveTab(tab)
    }
    
    return (
        <>
        <div className="d-flex px-2 pb-3">
            <div className="flex1 text-center mx-1">
                <h6 className="m-0 p-2 pointer" style={{borderRadius:25, color: activeTab == 4 ? Colors.colorLink : '', border: `${ activeTab == 4 ? '1px' : 0} solid`, borderColor:Colors.colorLink }} onClick={() => handleTab(4)}>Latest News</h6>
            </div>
            <div className="flex1 text-center mx-1">
                <h6 className="m-0 p-2 pointer" style={{borderRadius:25, color: activeTab == 3 ? Colors.colorLink : '', border: `${ activeTab == 3 ? '1px' : 0} solid`, borderColor:Colors.colorLink }} onClick={() => handleTab(3)}>Stats</h6>
            </div>
            <div className="flex1 text-center mx-1">
                <h6 className="m-0 p-2 pointer" style={{borderRadius:25, color: activeTab == 2 ? Colors.colorLink : '', border: `${ activeTab == 2 ? '1px' : 0} solid`, borderColor:Colors.colorLink }} onClick={() => handleTab(2)}>Game Log</h6>
            </div>
        </div>
      </>
    )
}

export default PlayerCardNavNba