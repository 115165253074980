import React from 'react'
import { useColors } from '../../../../helpers/CustomHooks/useColors';
import SingleMatchupExpandedNfl from './SingleMatchupExpandedNfl';
import SingleMatchupExpanded from './SingleMatchupExpanded';
// import SingleMatchupNfl from './SingleMatchupNfl'
// import SingleMatchupMlb from './SingleMatchupMlb'
// import SingleMatchupNba from './SingleMatchupNba'

const SingleMatchupH2h = (props) => {
  const { sport } = props

  return (
    {
      'NFL': <SingleMatchupExpandedNfl {...props} />,
      'MLB': <SingleMatchupExpanded {...props} />,
      'NBA': <SingleMatchupExpanded {...props} />,
    }[sport]
  )
}
export default SingleMatchupH2h