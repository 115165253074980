import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { LoaderCenter } from "../components/Loader"
import { getInForage } from "../helpers/common-methods"

const CheckExperience = () => {
    const hasLeague = useSelector(state => state.leagueJoin.hasLeague)
    const exp = useSelector(state => state.leagueJoin.app_status)
    const history = useHistory()

    useEffect(() => {
        getInForage('claimTeam')
        .then((val) => {
            if (val) {
                // REDIRECT TO NEW ONBOARDING
                history.push("/newClaimTeam")
            }else{
                if(hasLeague){
                    if(exp == "SEASON_LONG"){
                        history.push("/dashboard")
                    }else{
                        history.push("/newHome")
                    }
                }else{
                    history.push("/new-user-onboarding")
                }
            }
        }).catch((err) => {
            // FALLBACK TO DASHBOARD
            history.push("/dashboard")
        })
    }, [])

    return (
        null
    )
}

export default CheckExperience