import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { matchSchedule } from '../../apis/Matchmaker/ArenaScoreboard/ArenaScoreboard';
import { useColors } from '../../helpers/CustomHooks/useColors';
import { matchScheduleIcon } from '../../helpers/icons';
import { AppSwitch } from '@coreui/react';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { LoaderCenter } from '../../components/Loader';
import moment from 'moment-timezone';
import { defaultTimezone } from '../../helpers/Arena/ArenaHelper';
import ArenaPlayerSchedule from '../../components/Matchmaker/ArenaOnboarding/ArenaPlayerSchedule';

function MatchScheduleModal(props) {
    const Colors = useColors();
    const [loading, setLoading] = useState(true);
    const { isOpen, toggle, matchId, sport } = props;
    const [data, setData] = useState();
    const [teamView, setTeamView] = useState("opp");

    useEffect(() => {
        matchSchedule({ matchId }).then((resp) => {
            setData(resp);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    const handleTeamViewToggle = (e) => {
        setTeamView(e.target.checked ? 'opp' : 'my')
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalBody>
                <div className='d-flex justify-content-between align-items-center' style={{ borderBottom: `2px solid ${Colors.darkBgBorder}` }}>
                    <div className='d-flex align-items-center'>
                        <p className='m-0'>{matchScheduleIcon()}</p>
                        <h5 className='m-0 ml-2 bold'>Schedule</h5>
                    </div>
                    <div className='d-flex'>
                        <h5 className='m-0 bold mr-2'>{data?.timeFrame}</h5>
                        <i onClick={toggle} class="cui-circle-x icons pointer m-0 bold" style={{ color: Colors.modalCloseIconColor, fontSize: 22 }} />
                    </div>
                </div>
                {loading ? <LoaderCenter /> :
                    <>
                        <div className='d-flex flex1 justify-content-center align-items-center my-3'>
                            <h5 className='m-0 bold' style={{ color: teamView == "my" && Colors.colorDanger }}>{data?.data?.my?.teamName}</h5>
                            <AppSwitch className={'mx-2'} variant={'3d'} color={sport == "MLB" ? "danger" : "warning"} checked={teamView == "opp"} onChange={handleTeamViewToggle} />
                            <h5 className='m-0 bold' style={{ color: teamView == "opp" && Colors.colorDanger }}>{data?.data?.opp?.teamName}</h5>
                        </div>
                        <StickyTable borderColor={Colors.bgLight} style={{ borderRadius: "5px" }} >
                            <Row>
                                <Cell className="bold border-0" style={{ background: teamView == "opp" ? Colors.schedulePlayerColor : Colors.arenaLineupPlayerRow}}></Cell>
                                {
                                    !loading && Object.values(data?.scheduleHeader).map((header, index) => {
                                        return <Cell className="bold border-0" key={index} style={{ background: teamView == "opp" ? Colors.schedulePlayerColor : Colors.arenaLineupPlayerRow}}>{moment(header.date).tz(defaultTimezone()).format("ddd MMM D")}</Cell>
                                    })
                                }
                            </Row>
                            {data?.data[teamView]?.players.map((player) => (
                                <ArenaPlayerSchedule
                                    key={player.playerId}
                                    player={player}
                                    headers={data?.scheduleHeader}
                                    cellColor={teamView == "opp" ? Colors.schedulePlayerColor : Colors.arenaLineupPlayerRow}
                                    cellBorder={teamView == "opp" ? Colors.colorPrimaryTransparent : Colors.arenaLineupPlayerRow}
                                    sport={sport}
                                    teamView={teamView}
                                />

                            ))}
                        </StickyTable>
                    </>
                }
            </ModalBody>
        </Modal>
    )
}

export default MatchScheduleModal;