import moment from 'moment-timezone';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { parseMessage } from '../../../../helpers/MessageHelper';
import { detectUrls } from '../../../../helpers/MessageHelper';
import ImagePreview from '../Image/ImagePreview';
import { ColorContext } from '../../../../context/ColorProvider';

class NormalText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message_content: '',
        }
    }

    static contextType = ColorContext;

    componentDidMount() {
        this.setState({
            message_content: parseMessage(this.props.data.message_content, this.props.history)
        });
    };

    render() {
        const Colors = this.context
        return (
            <>
                {this.state.message_content !== "" && this.props.data.attachment == null ? (
                    <p>{this.state.message_content}</p>
                ) : (
                    <>
                        <p>{detectUrls(this.state.message_content)}</p>
                        <ImagePreview {...this.props} />
                    </>
                )}
            </>
            // <p dangerouslySetInnerHTML={{__html: this.state.message_content}}></p>
            // Thread toggler below comment above line before uncomenting below line
            // <p onClick={this.state.thread?null:this.toggleThread} style={{cursor:'pointer'}} >{this.props.data.message_content}</p>

        );
    }
}

export default withRouter(NormalText);