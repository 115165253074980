import { errorMessage } from "../../helpers/common-methods";

const ENDPOINTS = {
    PROFILE_OVERVIEW: '/user/profileOverview'
}

export const fetchUserMeta = () => {
	return window.axios
		.get('/user/userMeta')
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const updateUserInfo = (data) => {
	return window.axios
		.post('/user/updateUserInfo', data)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		});
};
export const updateUserProfileImage = (imageData) => {
	return window.axios
		.post('/user/updateProfileImage', imageData)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const updateUnreadNotificationApi = () => {
	return window.axios
		.post('/mm/updateUnreadNotification')
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const changePassword = (data) => {
	return window.axios
		.post('/user/updatePassword', data)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		});
};

export const hasDob = (qs) => {
	return window.axios
		.get('/user/hasDob'+qs)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		});
};


export const updateDob = (data) => {
	return window.axios
		.post('/user/updateDob', data)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		});
};

export const fetchActiveExperience = () => {
	return window.axios
		.get('/user/activeExperience', )
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		});
};

export const updateActiveExperience = (data) => {
	return window.axios
		.post('/user/updateActiveExperience', data)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		});
};

export const fetchReferralHistory = () => {
	return window.axios
		.get('/user/referralHistory', )
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		});
}

export const inviteViaEmail = (data) => {
	return window.axios
		.post('/user/inviteViaEmail', data)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		});
}

export const appDownloadLink = (data) => {
	return window.axios
		.post('user/smsAppDownloadLink', data)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		});
}

export const getProfileOverview = (params) => {
	return window.axios
		.get(ENDPOINTS.PROFILE_OVERVIEW, {params})
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		});
}
