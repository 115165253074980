import React, { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { BASE_FE_URL } from '../../../config/app'
// import { Colors } from '../../../helpers/colors'
import { STYLES } from '../../../helpers/stylesHelper'
import InviteEmailModal from '../../../modals/InviteEmailModal'
import InviteHistoryModal from '../../../modals/InviteHistoryModal'
import Send_invite from '../../../modals/send_invite'
import './refer.css'
import { useColors } from '../../../helpers/CustomHooks/useColors';

const Refer = (props) => {
    const Colors = useColors();
    const [copyText, setCopyText] = useState('Copy')
    const [inviteModal, setInviteModal] = useState(false)
    const [historyModal, setHistoryModal] = useState(false)

    const getCopyText = () => {
        let invite_text = "Let's battle this week on Bettle! There is a FREE $10 waiting for you once you create an account and import your team. "
		let url = BASE_FE_URL+'/'+props.auth?.user?.ref_id+'/challenge'+props.auth?.user?.username
        invite_text += url
        return invite_text
	}

    const shareToFacebook = () => {
        let url = `https://www.facebook.com/sharer.php?u=${BASE_FE_URL}/${props.auth?.user?.ref_id}/challenge${props.auth?.user?.username}`
        window.open(url, 'popup', 'width=600,height=600')
    }

    const referralLink = () => {
        return `${BASE_FE_URL}/${props.auth?.user?.ref_id}/challenge${props.auth?.user?.username}`
    }

    const toggleInviveViaEmailModal = () => {
        setInviteModal(!inviteModal)
    }

    const toggleInviteHistoryModal = () => {
        setHistoryModal(!historyModal)
    }

    return (
        <div className='d-flex flex-column flex1' >
            <div class="d-flex flex1 pl-4 pr-4 align-items-center justify-content-center">
                <div className="text-center" style={{ minWidth:250, width: '50%' }}>
                    <h1 style={{ color: Colors.colorPrimary, fontFamily:'Fritch' }}>REFER AND EARN</h1>
                    <p>
                    Get a friend to join <span style={{ color: Colors.colorPrimary }}> Bettle </span> and gift them a $25 credit for their first Arena match.
                    </p>
                    <div className='text-left p-4 text-dark' style={{backgroundColor: Colors.colorPrimary, borderRadius:10}}>
                        <h2 style={{fontFamily:'Fritch'}}>HOW TO CLAIM YOUR CREDIT?</h2>
                        <ul class="list-group vertical-steps mt-4">
                            <li class="list-group-item completed">
                                <p>Invite your friends to Bettle</p>
                                
                            </li>
                            <li class="list-group-item completed">
                                <p>That friend signs up and receives a $25 to use on matches.</p>
                                
                            </li>
                            <li class="list-group-item active">
                                <p className='bold'>After your friends make their first match, you receive your $10 credit</p>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <p className='text-left mb-1 mt-4' style={{color: Colors.colorSecondary}}>Share your referral Link:</p>
                        {props.auth && <div className='d-flex px-2 align-items-center' style={{border:`1px solid ${Colors.lightColor}`, backgroundColor: Colors.bgLight, borderRadius: 5}}>
                            <p className='m-0 text-left flex1 text-truncate'>{`${BASE_FE_URL}/${props.auth?.user?.ref_id}/challenge${props.auth?.user?.username}`}</p>
                            <CopyToClipboard text={getCopyText()} onCopy={() => setCopyText('Copied!')}>
                                <Button className="my-2" style={STYLES.BUTTON_PRIMARY_TRANSPARENT_RESPONSIVE}>
                                    <i class="fa fa-clone" aria-hidden="true" /> {copyText}
                                </Button>
                            </CopyToClipboard>
                        </div>
                        }

                        {/* Social Share */}
                        <div>
                            <p className='text-left mb-1 mt-4' style={{color: Colors.colorSecondary}}>Share via:</p>
                            <div className='d-flex align-items-center'>
                                <Button className="btn flex1 mx-1 text-dark border-0" onClick={toggleInviveViaEmailModal} style={{borderRadius:25, backgroundColor: 'white'}}><i class="fa fa-envelope-o" aria-hidden="true"></i> Email</Button>
                                <a className="btn flex1 mx-1 text-white border-0" target="_blank" href={'https://twitter.com/intent/tweet?text='+getCopyText()} style={{borderRadius:25, backgroundColor: '#00acee'}}><i class="fa fa-twitter" aria-hidden="true"></i> Twitter</a>
                                <a className="btn flex1 mx-1 text-white border-0" target='popup' onClick={shareToFacebook} style={{borderRadius:25, backgroundColor: '#3b5998'}}><i class="fa fa-facebook" aria-hidden="true"></i> Facebook</a>
                            </div>
                        </div>
                        {/* !Social Share */}
                        
                        {/* TODO Email Share Modal */}
                        {inviteModal && <InviteEmailModal isOpen={inviteModal} referralLink={referralLink} toggle={toggleInviveViaEmailModal}/>}
                        {historyModal && <InviteHistoryModal isOpen={historyModal} toggle={toggleInviteHistoryModal} />}
                    </div>
                    
                </div>
            </div>
            <div className='text-center py-2 w-50 mx-auto shadow-lg pointer' onClick={toggleInviteHistoryModal} style={{position:'sticky', bottom:0, backgroundColor:Colors.bgLight,borderTop:`0.01em solid ${Colors.bgLight}`, borderRadius:`20px 20px 0 0`}}>
                <h3 style={{fontFamily:'Fritch'}} className="text-center my-2"> Your Invite History </h3>
            </div>
        </div>
  )
}

const mapStateToProps = (state) => ({auth: state.auth})
export default connect(mapStateToProps, null) (Refer)