import React, { useContext } from 'react'
import { Row, Cell } from 'react-sticky-table';
import { ColorContext } from '../../../context/ColorProvider';
import { mlbScheduleStartIcon } from '../../../helpers/icons';

const ArenaPlayerSchedule = (props) => {
    const { headers, player, cellColor, cellBorder, sport, teamView } = props
    const Colors = useContext(ColorContext)

    const colorHandler = (color) => {
        if (color == "yellow") {
            return "#FFEDBD";
        } else if (color == "red") {
            return "#F24C65";
        } else if (color == "green") {
            return "#81F9CE";
        } else if (color == "default") {
            return "#b3b3b3";
        };
    };

    const boxColor = (player, dailyPeriod) => {
        if (player?.schedule?.[dailyPeriod]?.completed) {
            if (teamView == "my") {
                return "#4C4C4C";
            } else {
                return "#431614";
            };
        };
        if (player?.type == "bench") {
            return "#b3b3b3";
        } else {
            if (sport == "NBA") {
                return player?.schedule[dailyPeriod]?.opp ? colorHandler(player?.schedule[dailyPeriod]?.color) : teamView == "opp" ? cellColor : "#313538";
            } else if (sport == "MLB") {
                if (teamView == "opp") {
                    if (!player?.schedule[dailyPeriod]?.opp) {
                        return "#431614";
                    }
                    return player?.schedule?.[dailyPeriod]?.isAway ? "#F9DBD9" : "#FCEDED";
                } else {
                    if (!player?.schedule[dailyPeriod]?.opp) {
                        return "#4C4C4C";
                    }
                    return player?.schedule?.[dailyPeriod]?.isAway ? "#CCCCCC" : "#E6E6E6";
                };
            };
        };
    };

    const playerRowColor = (pl) => {
        if (pl?.type == "bench") {
            return "#484c4e";
        } else {
            return teamView == "opp" ? cellColor : "#333333";
        };
    };

    return (
        <Row>
            <Cell className="align-middle text-left border-0" style={{ backgroundColor: playerRowColor(player), width: "180px" }}><span className='mr-2' style={{ fontSize: 14, fontWeight: 600, color: Colors.colorPrimary }}>{player.defaultPos == "UTIL" ? "UT" : player.defaultPos}</span> <span style={{ fontSize: "14px", fontWeight: 600 }}>{player.fullName}</span></Cell>
            {Object.keys(headers)?.map((header, index) => {
                return (
                    player?.schedule?.hasOwnProperty(header) ?
                        <Cell key={index} style={{ backgroundColor: boxColor(player, header), border: `1px solid ${cellBorder || Colors.bgLight}`, height: "45px" }} className="align-middle text-center p-1" >
                            <div className='d-flex align-items-center justify-content-center'>
                                {sport == "MLB" ?
                                    <div className='d-flex flex-column'>
                                        {player?.schedule[header]?.starting ?
                                            <p className='m-0 bold'>
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <p className='m-0 bold' style={{color: "#666666", fontSize: "14px", color: Colors.black_90}}>{player?.schedule[header]?.isAway} {player?.schedule[header]?.opp}</p>
                                                    <p className='m-0 ml-1 pt-1'>{mlbScheduleStartIcon()}</p>
                                                </div>
                                            </p>
                                            : 
                                            <p className='m-0 bold' style={{color: "#666666", fontSize: "14px", color: Colors.black_90}}>{player?.schedule[header]?.isAway}{player?.schedule[header]?.opp}</p>
                                            }
                                        <p className='m-0'>{player.schedule[header]?.oppPitcher !== null ?
                                            <div className='d-flex'>
                                                <p className='m-0' style={{color: "#666666", fontSize: "12px", color: Colors.black_90}} >{player?.schedule[header]?.oppPitcher?.shortName}</p>
                                                {/* <p className='m-0 ml-1 px-1' style={{ background: Colors.bgSecondary_v3, borderRadius: 50 }}>{player?.schedule[header]?.oppPitcher?.pos}</p> */}
                                            </div>
                                            : ""}</p>
                                    </div>
                                    :
                                    <p className='mb-0 bold' style={{color: "#666666", fontSize: "12px", color: Colors.black_90}} >{player?.schedule[header]?.opp}</p>
                                }
                            </div>
                        </Cell>
                        :
                        <Cell key={index} style={{ backgroundColor: teamView == "my" ? "#333333" : "#431614", border: `1px solid ${cellBorder || Colors.bgLight}` }} className="align-middle text-center" >
                            <div className='d-flex align-items-center justify-content-center'>
                                {sport == "MLB" && <p className='m-0 bold' style={{ color: teamView == "opp" ? "#702521" : "#666666", fontSize: "14px", fontWeight: 600 }}>OFF</p>}
                            </div>
                        </Cell>
                )
            })}
        </Row>
    )
}

export default ArenaPlayerSchedule