import React from 'react';
import { connect } from 'react-redux';
import { Progress } from 'reactstrap';

const ProgressBar = (props) => {
  return (
    props.loaderValue && (
      <div className='d-flex justify-content-center'>
        <Progress className='progress-bar' animated value={100} style={{ width: '30%', height: '30px', borderRadius: 50 }}>
          <h6 className='pt-1'>Image is Uploading Please Wait...{props.loaderValue}%</h6>
        </Progress>
      </div>
    ) 
  );
};

const mapStateToProps = (state) => ({
  loaderValue: state.loader.loaderValue,
});

export default connect(mapStateToProps, null)(ProgressBar);