import React from 'react';
import PlayerCardTabsMLB from './PlayerCardTabsMLB';
import PlayerCardTabsNFL from './PlayerCardTabsNFL';
import PlayerCardTabsNBA from './PlyerCardTabsNBA';

const PlayerCardTabs = (props) => {
    return (
        
        {
            MLB: <PlayerCardTabsMLB {...props} />,
            NFL: <PlayerCardTabsNFL {...props} />,
            NBA: <PlayerCardTabsNBA {...props} />,
        }[props.sport]
        
        // props.sport == 'MLB' ?
        // <PlayerCardTabsMLB {...props} />
        // :
        // <PlayerCardTabsNFL {...props} />
    )
}

export default PlayerCardTabs