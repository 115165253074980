import localforage from 'localforage';

const INTENDED_URL = 'intended_url';
const DEFAULT_INTENDED_URL = '/home';

export async function setLocalForageToken(token) {
    if (window.window._.isEmpty(token)) {
      return await localforage.removeItem('authtoken', token)
    }
    return await localforage.setItem('authtoken', token)
}

async function setLocalForageIdToken(token) {
  if (window.window._.isEmpty(token)) {
    return await localforage.removeItem('idToken', token);
  }
  return await localforage.setItem('idToken', token);
}

export const setUserBasicInfo = info => {
  if (window.window._.isEmpty(info)) {
    localforage.removeItem('basic_info', info);
  }
  localforage.setItem('basic_info', info);
};

export const getUserBasicInfo = () => {
  return localforage.getItem("basic_info").then((info) => {
    if (window.window._.isEmpty(info)) {
      return Promise.reject(new Error('info not found'));
    }
    return Promise.resolve(info);
  });
};

export const setHttpToken = (token) => {
    if (window.window._.isEmpty(token)) {
      window.axios.defaults.headers.common['Authorization'] = null;
    }
    window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
};

export const checkTokenExists = () => {
  return localforage.getItem('authtoken').then((token) => {
    if (window.window._.isEmpty(token)) {
      return Promise.reject('invalid token');
    }
    return Promise.resolve(token);
  })
};

export const getIdToken = () => {

  return localforage.getItem('idToken').then((token) => {
    if (window.window._.isEmpty(token)) {
      return Promise.reject('invalid token');
    }
    return Promise.resolve(token);
  })
};

export const setToken = (token) => {
    setLocalForageToken(token)
    setHttpToken(token)
};

export const setIdToken = token => {
    return setLocalForageIdToken(token)
};

export const setIntendedUrl = url => {
  localforage.setItem(INTENDED_URL, url);
};

export const deleteIntendedUrl = url => {
  localforage.removeItem(INTENDED_URL, url);
};

export const getIntendedUrl = () => {
  return localforage.getItem(INTENDED_URL).then((url) => {
    if (window.window._.isEmpty(url)) {
      url = DEFAULT_INTENDED_URL;
    }
    return Promise.resolve(url);
  });
};


export const handleSocialAuth = (params) => {
  return new Promise((resolve, reject) => {
    setToken(params.access_token)
    document.cookie = 'bat='+params.access_token+';path=/;max-age=3600;domain=bettle.co'
    setIdToken(params.id_token)
    resolve("ok")
  })
}