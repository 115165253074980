import React, { useContext, useState } from 'react';
import { ColorContext } from '../../../context/ColorProvider';
import { prepareArenaOpps } from '../../../helpers/Arena/ArenaHelper';

import { parseInjuryIcons } from '../../../helpers/common-methods';
import { injuryStatusDoubtful, injuryStatusOut, injuryStatusQuestionable } from '../../../helpers/icons';
import ArenaPlayerModal from '../../../modals/Arena/ArenaPlayerModal';

const LineupPlayerRowNba = (props) => {
	const Colors = useContext(ColorContext);

	const { player, sport, seasonAvgKeys, teamView } = props
	const [playerModal, setPlayerModal] = useState(false)

	const toggle = () => {
		setPlayerModal(!playerModal)
	}

	return (
		<div onClick={toggle} className="d-flex align-items-center px-2 py-3" style={{ backgroundColor: sport !== "MLB" ? player.pos != 'BE' ? props.teamView == "opp" ? Colors.colorPrimaryTransparent : Colors.arenaLineupPlayerRow : Colors.arenaLineupPlayerRow : player?.type == "starters" ? teamView == "opp" ? Colors.colorPrimaryTransparent : "#313538" : Colors.colorPrimaryTransparent, height: "56px" }}>
			<h6 className="mx-1 my-0 bold" style={{width:20, color: Colors.colorPrimary, fontSize: 14, fontWeight: 500}}>{player.defaultPos == "UTIL" ? "UT" : player.defaultPos}</h6>
			<div className="d-flex align-items-center" style={{flex: 1.5}} >
				<div className="mx-1 position-relative" style={{ overflow: 'hidden', minWidth: '40px', maxHeight: '100%' }}>
					<img className="img-responsive rounded-circle my-1" src={player.image} width="100%" height="40px" style={{ objectFit: 'cover' }} />
					<div style={{position: "absolute", right: 0, bottom: 0}}  >
						{parseInjuryIcons(player.injuryStatus)}
					</div>
				</div>
                <div className='bold flex1'>
					<div className='d-flex align-items-center'>
				    	<p className="m-0 my-0 pl-1" style={{ fontSize: 16, fontWeight: 600 }}>{player.fullName}</p>
						<div className='m-0 d-flex align-items-center small pt-1' style={{ fontSize: 12, fontWeight: 500, color: Colors.textColorSecondary_v2 }}>
							<p className='m-0 px-1'>{player.defaultPos == "UTIL" ? "UT" : player.defaultPos}</p>
							<p className='m-0'>|</p>
							<p className='m-0 px-1'>{player.team}</p>
							<p className='m-0' style={{ color: Colors.colorDanger }}>{player.injuryStatus}</p>
						</div>
					</div>
                    <div className='d-flex ml-2'>
						{
							seasonAvgKeys?.map((key, index) => {
								return <p className="m-0 flex1 text-center" style={{color: Colors.textColorSecondary}} key={index}>
											{
												player?.seasonAvg?.hasOwnProperty(key) ?
													player?.seasonAvg[key] == null ? "-" :
													key == "pitching_era" || key == "pitching_whip" ?
													parseFloat(player?.seasonAvg[key])?.toFixed(2)
													:
													key == "hitting_avg" ?
													parseFloat(player?.seasonAvg[key])?.toFixed(3)
													:
													parseFloat(player?.seasonAvg[key])?.toFixed(1)
												:
												""
											}
										</p>
							})
						}
                    </div>
                </div>
			</div>
			{/* <p className="m-0 text-right" style={{ color: Colors.colorSecondary, flex: 1 }}>
				{prepareArenaOpps(player.opps)}
			</p> */}
			{playerModal && <ArenaPlayerModal
				modal={playerModal}
				playerId={player.playerId}
				toggle={toggle}
			/>}
		</div>
	);
};

export default LineupPlayerRowNba;
