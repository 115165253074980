import React from 'react';
import { useColors } from '../../helpers/CustomHooks/useColors';
import CustomToggle from '../subcomponents/CustomToggle';
import ArenaPlayerSchedule from './ArenaOnboarding/ArenaPlayerSchedule';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import moment from 'moment-timezone';

const MatchamakerSchedule = (props) => {
  const Colors = useColors();
  const { teamView, comparisonData, sport, comparisonDataLoading, setTeamView } = props;

  const handleTeamViewToggle = (view) => {
    setTeamView(view ? "opp" : "my");
  }

  return (
    <div className='d-flex flex-column flex1 scrollHidden' style={{ background: Colors.black_90 }}>
      <div className='d-flex align-items-center px-3 py-3'>
        <div className='d-flex flex1 align-items-center'>
          <p className='m-0 bold' style={{ color: teamView == "my" ? Colors.colorLight : Colors.textColorSecondary_v2 }}>{comparisonData?.my?.teamName}</p>
          <CustomToggle onChange={handleTeamViewToggle} />
          <p className='m-0 bold' style={{ color: teamView == "opp" ? Colors.colorPrimary : Colors.textColorSecondary_v2 }}>{comparisonData?.opp?.teamName}</p>
        </div>
        {sport !== "MLB" &&
          <div className='d-flex flex1 justify-content-around align-items-center'>
            <p className='m-0 bold' style={{ color: "#F24C65" }}>Unfavorable</p>
            <div className='d-flex flex1 mx-2'>
              <div className='flex1' style={{ backgroundColor: '#F24C65', height: 12 }}></div>
              <div className='flex1' style={{ backgroundColor: '#FEEDBD', height: 12 }}></div>
              <div className='flex1' style={{ backgroundColor: '#68F7C5', height: 12 }}></div>
            </div>
            <p className='m-0 bold' style={{ color: "#68F7C5" }}>Favorable</p>
          </div>
        }
      </div>

      <StickyTable borderColor={Colors.bgLight} style={{ borderRadius: "5px" }}>
        <Row>
          <Cell className="bold border-0" style={{ backgroundColor: Colors.black_90 }}></Cell>
          {
            !comparisonDataLoading && Object.values(comparisonData?.scheduleHeader).map((header, index) => {
              return <Cell className="border-0 text-center" style={{ backgroundColor: Colors.black_90 }} key={index}>{moment(header.date).format("ddd MMM D")}</Cell>
            })
          }
        </Row>
        {!comparisonDataLoading && comparisonData[teamView]?.players?.map((player, index) => (
          <ArenaPlayerSchedule
            key={player.playerId}
            player={player}
            headers={comparisonData.scheduleHeader}
            cellColor={teamView == "opp" ? Colors.schedulePlayerColor : Colors.arenaLineupPlayerRow}
            cellBorder={teamView == "opp" ? Colors.schedulePlayerColor : Colors.arenaLineupPlayerRow}
            sport={sport}
            teamView={teamView}
          />
        ))}
      </StickyTable>
    </div>
  )
}

export default MatchamakerSchedule;