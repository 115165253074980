import React, { useEffect, useState } from "react"
import { mlbColors as Colors } from "../../../../helpers/colors"
import MLBEachCatRow from "./MLBEachCatRow"
import { mergeAndSum } from "../../../../helpers/common-methods"

const MLBEachCatPlayers = (props) => {
    const {data} = props
    const [team1Total, setTeam1Total] = useState({})
    const [team2Total, setTeam2Total] = useState({})

    useEffect(() => {
        setTeam1Total(teamStatTotal(0))
        setTeam2Total(teamStatTotal(1))
    }, [])

    const teamStatTotal = (index) => {
        var teamTotal = {}
        if(data.teams[index] && data.teams[index].players){
            data.teams[index].players.map((player) => {
                var currentStats = player.stats ? player.stats : {}
                var wtdStats = player.wtdStats ? player.wtdStats : {}
                var prevStats = player.prevStats ? player.prevStats : {}
                var firstSum = mergeAndSum(currentStats, wtdStats)
                var finalSum = mergeAndSum(prevStats, firstSum)
                teamTotal = mergeAndSum(teamTotal, finalSum)
            })
        }
        return teamTotal
    }
    return (
        <>
            <div className="d-flex flex-column">
                <h3 className="flex1 text-center py-2 mt-1 mb-0" style={{borderBottom:`0.01em solid ${Colors.darkBgBorder}`, borderTop:`0.01em solid ${Colors.darkBgBorder}` }}>Batters</h3>
                {data.selectedCats.hitting.map((stat, index) => {
                    // var team1Total = teamStatTotal(stat,0)
                    // var team2Total = teamStatTotal(stat,1)
                    return <MLBEachCatRow statCat={data.statAbbr[stat]} team1Total={team1Total[stat]} team2Total={team2Total[stat]} key={index} />
                })}
            </div>
            <div className="d-flex flex-column mb-2">
            <h3 className="flex1 text-center py-2 mt-1 mb-0" style={{borderBottom:`0.01em solid ${Colors.darkBgBorder}`}}>Pitchers</h3>
                {data.selectedCats.pitching.map((stat, index) => {
                    // var team1Total = teamStatTotal(stat,0)
                    // var team2Total = teamStatTotal(stat,1)
                    return <MLBEachCatRow statCat={data.statAbbr[stat]} team1Total={team1Total[stat]} team2Total={team2Total[stat]} key={index} />
                })}
            </div>
        </>
    )
}

export default MLBEachCatPlayers