import React, { useEffect } from 'react'
import Header from '../../components/Header/Header'
import background from '../../assets/images/field-sepia.jpg'
import ArenaLeaderboardsInfo from '../../components/Leaderboards/ArenaLeaderboardsInfo'
import LeaderboardsRanking from '../../components/Leaderboards/LeaderboardsRanking'
import { useParams } from 'react-router-dom'
import ReferralLeaderboardsInfo from '../../components/Leaderboards/ReferralLeaderboardsInfo'
import { fetchArenaLeaderboards, fetchReferralLeaderboards } from '../../apis/Leaderboards/LeaderboardsAPI'
const ArenaLeaderboards = (props) => {
    let { type } = useParams()

    const [ data, setData ] = React.useState([])
    const [ highestScore, setHighestScore ] = React.useState(0)
    const [ week, setWeek ] = React.useState(0)

    useEffect(() => {
        callApi(type)
      setInterval(() => {
        callApi(type)
      }, 60000)
    }, [type])
    
    const callApi = (type) => {
        if (type == 'referral') {
            fetchReferralLeaderboards()
                .then((response) => {
                    setData(response.rankings)
                    setHighestScore(response.highest_winning)
                    setWeek(response.week)
                })
                .catch((error) => {
                    console.log(error)
                })
        } else if(type == 'arena') {
            fetchArenaLeaderboards()
                .then((response) => {
                    setData(response.rankings)
                    setHighestScore(response.highest_winning)
                    setWeek(response.week)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
    return (
        <div className=' d-flex flex-column' style={{height:'100vh', backgroundImage:`url(${background})`, overflow:'hidden'}}>
            <Header/>
            <div className='d-flex flex1' style={{overflow:'hidden'}}>
                {
                    type === 'arena' && <ArenaLeaderboardsInfo week={week}/>
                }
                {
                    type === 'referral' && <ReferralLeaderboardsInfo week={week}/>
                }
                <LeaderboardsRanking type={type} data={data} highestScore={highestScore} week={week}/>
            </div>
            {/* Rankings */}
        </div>
    )
}

export default ArenaLeaderboards