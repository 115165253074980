import React, { useState } from "react"
import LoadingOverlay from "react-loading-overlay";
import { useSelector } from "react-redux";
import { Modal } from "reactstrap";
import { mmAcceptAndSavePlayers } from "../../apis/Arena/MatchMakerApis";
import Starters from "../../components/Matchmaker/ArenaOnboarding/Starters";
import { currentTimeframe } from "../../helpers/Arena/ArenaHelper";

const ArenaPlayerSelectModal = (props) => {
    const {teamData, visible, toggle, selectedLineup, onSuccess} = props
    const [loading, setLoading] = useState(false)
    const timeframe = useSelector(state => state.arenaReducer.selectedTimeframe)
    const saveAndAcceptMatch = (players) => {
        setLoading(true)
        var reqData = {}
        reqData["players"] = players
        reqData["matchId"] = selectedLineup.proposedData.matchId
        reqData["teamId"] = selectedLineup.proposedData.myTeamId
        reqData["timeFrame"] = currentTimeframe()

        mmAcceptAndSavePlayers(reqData).then((resp) => {
            setLoading(false)
            toggle()
            onSuccess()
        }).catch((e) => {
            setLoading(false)
            alert(e.message)
        })
    }

    return (
        <Modal isOpen={visible} size="lg" >
             <LoadingOverlay
					active={loading}
					text='Saving...'
					fadeSpeed={0}
					spinner
					className='d-flex flex-column flex1 scrollHidden'
				>
                <Starters closePlayerModal={toggle} afterOnboard={true} afterOnboardData={teamData} successCallback={saveAndAcceptMatch} />
            </LoadingOverlay>
        </Modal>
    )
}

export default ArenaPlayerSelectModal