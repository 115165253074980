import React, { useEffect, useState } from "react"
import { mmNflPlayerDetails, mmPlayerDetails } from "../../apis/Arena/MatchMakerApis"
import { prepareArenaOpps } from "../../helpers/Arena/ArenaHelper"
import { defaultColors } from "../../helpers/colors"
import { parseMlbHeaders, parseTotalStat, sportFromExperience } from "../../helpers/common-methods"
import { useColors } from "../../helpers/CustomHooks/useColors"
import store from "../../store"
import { LoaderCenter } from "../Loader"
import ArenaGameLogs from "./ArenaGameLogs"
import { nflGameLogs } from "../../apis/SeasonLong/PlayerAPI/PlayerAPI"
import ArenaNbaGameLogs from "./ArenaNbaGameLogs"
import PerfectScrollbar from "react-perfect-scrollbar"

const ArenaPlayerCard = (props) => {
    const {playerId} = props
    const Colors = useColors()
    const [playerData, setPlayerData] = useState()
    const [loading, setLoading] = useState(true)
    const [headers, setHeaders] = useState()
    const [gamelogs, setGameLogs] = useState(undefined)
    const [tab, setTab] = useState("gameLogs")
    const experience = store.getState().leagueJoin.app_status
    const sport = sportFromExperience(experience, true)

    const styles = {
        posTab: {
            // flex: 1,
            width: 100,
            height: 30,
            alignItems: "center",
            justifyContent: "center",
            border: `1px solid ${Colors.bg400}`,
            borderRadius: 30,
            display: "flex",
            flexDirection: "column",
            margin: 0
        },
        posTabActive: {
            // flex: 1,
            width: 100,
            height: 30,
            alignItems: "center",
            justifyContent: "center",
            border: `1px solid ${Colors.playerCardTabColor}`,
            borderRadius: 30,
            display: "flex",
            flexDirection: "column",
            margin: 0
        }
    }

    useEffect(() => {
        if(sport == "MLB"){
            mmPlayerDetails("?playerId="+playerId).then((resp) => {
                setHeaders(parseMlbHeaders(resp.pos, resp.statHeaders))
                setPlayerData(resp)
                setLoading(false)
            })
        }else if (sport == "NFL" || sport == "NBA"){
            mmNflPlayerDetails("?playerId="+playerId+"&sport="+sport).then((resp) => {
                setHeaders(resp.statHeaders)
                setPlayerData(resp)
                setLoading(false)
            })
        }
    }, [])

    useEffect(() => {
        if(tab == "gameLogs" && gamelogs == undefined){
            if(sport == "NBA"){
                const reqData = {
                    "player_id": playerId,
                    'sport': sport,
                }
                nflGameLogs(reqData)
                    .then((resp) => {
                        setGameLogs(resp.data)
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            }
        }
    },[tab])

    // filter the headers for mlb case 
    const filterHeader = (headers) => {
        var filteredHeader = []
        var validHeaders = ["hitting_avg", "hitting_onbase_hr", "hitting_rbi", "pitching_era", "pitching_whip", "pitching_outs_ktotal"]
        if(sport == "MLB"){
            headers.map((head) => {
                if(validHeaders.includes(head)){
                    filteredHeader.push(head)
                }
            })
            return filteredHeader
        }
        return headers
    }

    const prepareTabView = () => {
        if(tab == "news"){
            return (
                <div>
                    {playerData.news.length > 0 ? (
                        Object.values(playerData.news).map((item, key) => (
                            <div
                                key={key}
                                style={{
                                    border: `1px solid ${Colors.bgLight}`,
                                    padding: 10,
                                    borderRadius: 5,
                                    marginTop: 5,
                                }}
                            >
                            <div className="d-flex justify-content-between mb-2">
                                <p className="bold mb-0" >
                                {item.data.Player.FirstName + " " + item.data.Player.LastName}
                                </p>
                                <p className="mb-0">{item.date}</p>
                            </div>
                            <p className="mb-0">
                                {item.data.notes}
                            </p>
                            </div>
                        ))
                        ) : (
                        <div className="text-center">
                            <p>No Recent News Found</p>
                        </div>
                    )}
                </div>
            )
        }else if(tab == "gameLogs"){
            return (
                // <div horizontal={true} contentContainerStyle={{flexGrow: 1}} >
                    <div className="d-flex flex-column flex1" >
                        {sport == "MLB" && <ArenaGameLogs playerId={playerId} statHeaders={headers} sport={sport} />}
                        {/* {sport == "NFL" && <ArenaNflPlayerLogs playerId={playerId}/>} */}
                        {sport == "NBA" && gamelogs != undefined && <ArenaNbaGameLogs playerId={playerId} gamelogs={gamelogs} />}
                    </div>
                // </div>
            )
        }
    }

    return (
        loading ? <LoaderCenter /> : 
        (<div className="d-flex flex-column flex1 scrollHidden">
            <div className="d-flex align-items-center py-3" style={{background: sport == "MLB" ? "linear-gradient(#002D72, #02132E)" : Colors.playerCardHeader, borderTopRightRadius:10, borderTopLeftRadius:10}}>
                <img className="mx-2 roundImgBig mr-4" style={{border: sport !== "MLB" && `1px solid ${Colors.colorPrimary}`}}  src={playerData.image} />
                <div>
                    <h3>{playerData.fullName}</h3>
                    {playerData.injuryData && <div className="d-flex flex-column" style={{marginTop: 5, backgroundColor: Colors.colorDanger, padding: 3, borderRadius: 5, alignSelf: "flex-start"}} >
                        <p style={{fontSize: 10, fontWeight: "bold"}} className="mb-0" >
                            {playerData.injuryData.type} | {playerData.injuryData.status}
                        </p>
                    </div>}
                    <p className="mb-1" >{playerData.teamFullName} | {playerData.pos}</p>
                    <div className="d-flex" >
                        <div style={{alignItems: "center"}} >
                            <p className=" mb-0" >{playerData.age}</p>
                            <p className="  mb-0"  >age</p>
                        </div>
                        <p className="mb-0 mx-2"  >|</p>
                        <div style={{alignItems: "center"}} >
                            <p className="  mb-0" >{playerData.height}</p>
                            <p className="  mb-0" >height</p>
                        </div>
                        <p className="mb-0 mx-2"  >|</p>
                        <div style={{alignItems: "center"}} >
                            <p className="  mb-0" >{playerData.weight}</p>
                            <p className="  mb-0" >weight</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center py-2 px-4" style={{backgroundColor: sport == "MLB" ? Colors.playerCardSubHeader : Colors.bgDark, color: Colors.colorDark}} >
                <p className="mb-0 mr-2"  >Upcoming: </p>
                <p className="mb-0 bold">
                    {prepareArenaOpps(playerData.opps)}
                </p>
            </div>

            <div className="d-flex justify-content-around align-items-center pt-3" >
                {filterHeader(headers)?.map((item, index) => (
                    <div key={index} className="mx-2 d-flex flex-column align-items-center">
                        {sport == "MLB" && 
                            <div className="mb-3">
                                <p className="m-0 bold text-center">{playerData.statAbbr[item] ? playerData.statAbbr[item] : item}</p>
                                <p className="m-0 bold text-center" style={{ fontSize: 30 }} >{parseTotalStat(item, playerData.stats[item])}</p>
                            </div>
                        }
                        {sport == "NFL" && <p className="" style={{marginTop: 5}} >{playerData.stats[item]}</p>}
                        {sport == "NBA" && 
                            <div className="d-flex py-3" style={{ backgroundColor: Colors.bgSecondHeader}}>
                                <div className="text-center flex1">
                                    <p className="m-0" style={{color: Colors.colorSecondary}}>{item}</p>
                                    <h1>{playerData.stats[item]}</h1>
                                </div>
                            </div>
                        }
                    </div>
                ))}
            </div>

            <div className="d-flex" style={{flexDirection: "row", alignItems: "center", justifyContent: "space-evenly", paddingBottom: 15}} >
                <div onClick={() => setTab("gameLogs")} style={tab == "gameLogs" ? styles.posTabActive : styles.posTab}  >
                    <p status={tab == "gameLogs" ? "success" : "basic"} className="mb-0 pointer" >Game Log</p>
                </div>
                <div onClick={() => setTab("news")} style={tab == "news" ? styles.posTabActive : styles.posTab} >
                    <p status={tab == "news" ? "success" : "basic"} className="mb-0 pointer" >Latest News</p>
                </div>
            </div>

            {/* <PerfectScrollbar className="mx-3 px-3 mb-2" style={{ */}
            <PerfectScrollbar className={sport == "NBA" || sport == "NFL" ? "mx-3 px-3-mb-2"  : "mx-3"} style={{
                overflowY: "auto",
                backgroundColor: sport == "NBA" || sport == "NFL" && Colors.bgDark,
                borderRadius: 5,
                height: "50vh"
                }} >
                {prepareTabView()}
            </PerfectScrollbar>
        </div>)
    )
}

export default ArenaPlayerCard