import { SAVE_ALL_TEAMS, SET_ACTIVE_TEAM, SAVE_ARENA_SPORT } from "../../apis/Arena/MatchMakerApis";


const initialState = {
  onBoarded: false,
  userData: {},
  proposedData: null,
  updateBalance: false,
  showComparison: false,
  refreshArena: false,
  importSuccess: false,
  all_teams: [],
  active_team: {},
  forceRefresh: false,
  selectedTimeframe: [],
  sport: undefined,
  matchesCount: {},
  startOnboarding: false,
  userBalance: {},
  middleModal: false,
  actionMatchData: {},
  mmLineupDetail: {}, // compView, schedule, projections data
  rematchData: null, // rematch modal state, match details
}

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_ONBOARDED":
      return Object.assign({}, state, { onBoarded: action.payload });
    case "SET_ARENA_USER_DATA":
      return Object.assign({}, state, { userData: action.payload });
    case "SET_PROPOSED_DATA":
      return Object.assign({}, state, { proposedData: action.payload });
    case "SET_UPDATE_BALANCE":
      return Object.assign({}, state, { updateBalance: !state.updateBalance });
    case "SET_SHOW_COMPARISON":
      return Object.assign({}, state, { showComparison: !state.showComparison });
    case "SET_REFRESH_ARENA":
      return Object.assign({}, state, { refreshArena: !state.refreshArena })
    case "SET_IMPORT_SUCCESS":
      return Object.assign({}, state, { importSuccess: action.payload });
    case SAVE_ALL_TEAMS:
      return Object.assign({}, state, { all_teams: action.all_teams });
    case SET_ACTIVE_TEAM:
      return Object.assign({}, state, { active_team: action.active_team, sport: action.active_team.sport });
    case SAVE_ARENA_SPORT:
      return Object.assign({}, state, { sport: action.sport });
    case "SAVE_ACTIVE_TEAM_SPORT": 
      return Object.assign({}, state, { activeTeamSport: action.payload, sport: action.payload });
    case "SAVE_TEAMS_ORDER":
      return Object.assign({}, state, { teamsOrder: action.payload });
    case "SAVE_ARENA_HOME_DATA":
      return Object.assign({}, state, { 
        all_teams: action.payload.teams,
        active_team: action.payload.defaultTeam,
        teamsOrder: action.payload.teamsOrder,
      });
    case "FORCE_REFRESH_ARENA_DATA":
      return Object.assign({}, state, {forceRefresh: action.payload});
    case "SAVE_SELECTED_TIMEFRAME":
      return Object.assign({}, state, {selectedTimeframe: {...action.payload}});
    case "SAVE_MATCHUPS_COUNT":
      return Object.assign({}, state, { matchesCount: action.payload });
    case "SET_START_ONBOARDING":
      return Object.assign({}, state, { startOnboarding: action.payload });
    case "SET_USER_BALANCE":
      return Object.assign({}, state, { userBalance: action.payload });
    case "OPEN_MIDDLE_MODAL":   
      return Object.assign({}, state, { middleModal: action.payload });
    case "SAVE_ACTION_MATCH_DATA":
      return Object.assign({}, state, { actionMatchData: action.payload });
    case "SAVE_MM_LINEUP_DETAIL":
      return Object.assign({}, state, { mmLineupDetail: action.payload });
    case "SET_REMATCH_DATA":
      return Object.assign({}, state, { rematchData: action.payload });
    default:
      return state;
  }
};