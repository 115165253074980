import React, { useEffect } from "react"
import { Modal } from "reactstrap";
import ArenaPlayerRow from "../../components/Matchmaker/ArenaOnboarding/ArenaPlayerRow";
// import {Colors} from "../../helpers/colors"
import {StickyTable, Row, Cell} from 'react-sticky-table';
import { useColors } from '../../helpers/CustomHooks/useColors';
import { getSportColor } from "../../helpers/stylesHelper";

const SwapPlayersModal = (props) => {
    const Colors = useColors();
    const {modal, toggle, swapPlayers, selectedPlayer, selectPlayer, sport} = props
    const styles = {
        container: {
            backgroundColor: Colors.bgDark,
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            border: `1px solid ${Colors.colorPrimary}`
        }
    }

    return (
        <Modal isOpen={modal} toggle={toggle} >
            <div style={styles.container}>
                <div>  
                    <div className="d-flex justify-content-between p-2 align-items-center" style={{backgroundColor: Colors.colorPrimary}} >
                        <p className="mb-0 bold" style={{color: Colors.bgDark}} >Sub In</p>
                        <i className="fa fa-arrow-up" style={{color: Colors.bgDark}} ></i>
                    </div>
                    <div style={{width: '100%', maxHeight: "40vh", overflow: "auto"}}>
                    {swapPlayers?.length == 0 ? (<div className="py-3 text-center" ><p className="mb-0 lead" >No Player found!</p></div>) : (
                        // <StickyTable borderColor={Colors.darkBgBorder} >
                        //     <Row>
                        //         <Cell style={{backgroundColor: Colors.bgLight}} >POS</Cell>
                        //         <Cell style={{backgroundColor: Colors.bgLight}} >PLAYERS</Cell>
                        //         {sport != "NBA" && <Cell style={{backgroundColor: Colors.bgLight}} >GAMES</Cell>}
                        //     </Row>
                            swapPlayers.map((player, index) => (
                                <ArenaPlayerRow callBack={selectPlayer} index={index} key={player.playerId} player={player} sport={sport} />
                            ))
                        // </StickyTable>
                    )}
                    </div>
                    <div className="d-flex justify-content-between p-2 align-items-center" style={{backgroundColor:Colors.colorPrimary}} >
                        <p className="mb-0 bold" style={{color: Colors.bgDark}} >Sub Out</p>
                        <i className="fa fa-arrow-down" style={{color: Colors.bgDark}} aria-hidden="true"></i>
                    </div>
                    {/* <StickyTable borderColor={Colors.darkBgBorder} >
                        <Row>
                            <Cell style={{backgroundColor: Colors.bgLight}} >POS</Cell>
                            <Cell style={{backgroundColor: Colors.bgLight}} >PLAYERS</Cell>
                            {sport != "NBA" && <Cell style={{backgroundColor: Colors.bgLight}} >GAMES</Cell>}
                        </Row> */}
                        <ArenaPlayerRow disableAction={true} player={selectedPlayer} sport={sport} />
                    {/* </StickyTable> */}
                </div>
            </div>
        </Modal>
    )
}
export default SwapPlayersModal