import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import AddPlayer from '../../../SeasonLong/Player/AddPlayer';

class ModalPlayerAdd extends Component {
    constructor(props) {
        super(props);
    }

    state = {  }

    addPlayer() {

    }

    render() { 
        return ( 
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
                <ModalBody className='p-0 border-0'>
                    <AddPlayer toggle={this.props.toggle} data={this.props.data}/>
                </ModalBody>
            </Modal>
         );
    }
}
 
export default ModalPlayerAdd;