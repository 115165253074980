import React, {useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {Button} from 'reactstrap'
// import {Colors} from '../../../helpers/colors'
import Select from 'react-select'
import Header from './Header'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { STYLES } from '../../../helpers/stylesHelper'
import { useColors } from '../../../helpers/CustomHooks/useColors';

const options = [
    {
        value: 1,
        label: 'Week 1'
    }, {
        value: 2,
        label: 'Week 2'
    }, {
        value: 3,
        label: 'Week 3'
    }, {
        value: 4,
        label: 'Week 4'
    }, {
        value: 5,
        label: 'Week 5'
    }, {
        value: 6,
        label: 'Week 6'
    }, {
        value: 7,
        label: 'Week 7'
    }, {
        value: 8,
        label: 'Week 8'
    }, {
        value: 9,
        label: 'Week 9'
    }, {
        value: 10,
        label: 'Week 10'
    }, {
        value: 10,
        label: 'Week 11'
    }, {
        value: 10,
        label: 'Week 12'
    }, {
        value: 10,
        label: 'Week 13'
    }, {
        value: 10,
        label: 'Week 14'
    }, {
        value: 10,
        label: 'Week 15'
    }, {
        value: 10,
        label: 'Week 16'
    }, {
        value: 10,
        label: 'Week 17'
    }, {
        value: 10,
        label: 'Week 18'
    }, {
        value: 10,
        label: 'Week 19'
    }, {
        value: 10,
        label: 'Week 20'
    }, {
        value: 10,
        label: 'Week 21'
    }, {
        value: 10,
        label: 'Week 22'
    }, {
        value: 10,
        label: 'Week 23'
    }, {
        value: 10,
        label: 'Week 24'
    }, {
        value: 10,
        label: 'Week 25'
    }
]

const ScheduleSettings = ({onBackHandler, options_data, steps_data, leagueId}) => {
    const Colors = useColors();
    const [schedule,
        setSchedule] = useState(steps_data.schedule_data)
    const [selected,
        setSelected] = useState(1)
    const {formState: {errors}, handleSubmit, control, register} = useForm(); // initialize the hook

    const incrementDecrement = (key, type) => {
        var newSchdule = {
            ...schedule
        }
        if ((newSchdule[key] >= 0 && type == "inc") || (newSchdule[key] > 0 && type == "dec")) {
            if (type == "inc") {
                newSchdule[key] = newSchdule[key] + 1
            } else {
                newSchdule[key] = newSchdule[key] - 1
            }
            setSchedule(newSchdule)
        }
    }

    return (
        <div className='d-flex flex-column scrollHidden' >
            <Header onBackHandler={onBackHandler} name='Schedule Settings'/>
            <PerfectScrollbar>
            <div className="row flex1 align-items-center justify-content-center p-4 m-0">
                <div className="text-center col-md-6 col-sm-12">

                    {/* General Schedule Settings */}
                    <div className='row d-flex flex-column align-items-start'>
                        {/* Heading */}
                        <div
                            className="row flex1 w-100 py-2 px-0 mx-0 justify-content-between"
                            style={{
                            borderBottom: `1px solid ${Colors.bgDark}`
                        }}>
                            <label
                                className='mt-2'
                                style={{
                                fontWeight: "bold"
                            }}>Regular Season Wins</label>
                            <div className="">
                                <div>
                                    <button
                                        type="button"
                                        onClick={() => incrementDecrement("reg_games", "dec")}
                                        class="btn btn-dark"
                                        style={{
                                        color: Colors.colorPrimary,
                                        fontWeight: "bold",
                                        border: `1px solid ${Colors.bgDark}`
                                    }}>
                                        -
                                    </button>

                                    <span
                                        class="badge mx-2"
                                        style={{
                                        fontSize: 15
                                    }}>
                                        {schedule.reg_games}
                                    </span>

                                    <button
                                        type="button"
                                        onClick={() => incrementDecrement("reg_games", "inc")}
                                        class="btn btn-dark"
                                        style={{
                                        color: Colors.colorPrimary,
                                        fontWeight: "bold",
                                        border: `1px solid ${Colors.bgDark}`
                                    }}>
                                        +
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* !General Schedule Settings*/}

                    {/* Matchup Tiebreaker */}
                    <div className='row d-flex flex-column align-items-start my-3'>
                        {/* Heading */}
                        <h6
                            className='bold mt-1'
                            style={{
                            color: Colors.colorLight
                        }}>Matchup Tiebreaker</h6>
                        {/* Actions Container */}
                        <div>
                            {/* <select className='p-2' disabled style={{borderRadius:5, backgroundColor:Colors.bgLight, color:Colors.colorLight}}>
                        <option>Bench Points</option>
                    </select> */}
                            <Controller
                                control={control}
                                render={({
                                field: {
                                    onChange,
                                    onBlur,
                                    value
                                }
                            }) => (
                                <div
                                    style={{
                                    flexDirection: "row"
                                }}>
                                    {Object
                                        .keys({total_points: "Total Points"})
                                        .map((key) => (
                                            <Button
                                                onBlur={onBlur}
                                                onClick={() => onChange(key)}
                                                className='mr-1 mt-1'
                                                style={{
                                                border: `1px solid ${Colors.bgDark}`,
                                                borderRadius: 25,
                                                backgroundColor: value == key
                                                    ? Colors.colorPrimary
                                                    : Colors.bgLight,
                                                color: value == key
                                                    ? Colors.bgLight
                                                    : Colors.colorLight
                                            }}>{{
                                                    total_points: "Total Points"
                                                }[key]}</Button>
                                        ))}
                                </div>
                            )}
                                name="reg_tb"
                                defaultValue={schedule.reg_tb}/>
                        </div>
                    </div>
                    {/* !Draft Date and Time*/}

                    {/* Playoff Settings */}
                    <div className='row d-flex flex-column align-items-start my-3'>
                        {/* Heading */}
                        <h6
                            className='bold mt-1'
                            style={{
                            color: Colors.colorLight
                        }}>Playoff Settings</h6>
                        {/* Actions Container */}
                        <div
                            className="row flex1 w-100 py-2 px-0 mx-0 justify-content-between"
                            style={{
                            borderBottom: `1px solid ${Colors.bgDark}`
                        }}>
                            <label
                                className='mt-2'
                                style={{
                                fontWeight: "bold"
                            }}>
                                Playoff Teams
                            </label>
                            <div className="">
                                <div>
                                    <button
                                        type="button"
                                        onClick={() => incrementDecrement("playoff_teams", "dec")}
                                        class="btn btn-dark"
                                        style={{
                                        color: Colors.colorPrimary,
                                        fontWeight: "bold",
                                        border: `1px solid ${Colors.bgDark}`
                                    }}>
                                        -
                                    </button>

                                    <span
                                        class="badge mx-2"
                                        style={{
                                        fontSize: 15
                                    }}>
                                        {schedule.playoff_teams || 0}
                                    </span>

                                    <button
                                        type="button"
                                        onClick={() => incrementDecrement("playoff_teams", "inc")}
                                        class="btn btn-dark"
                                        style={{
                                        color: Colors.colorPrimary,
                                        fontWeight: "bold",
                                        border: `1px solid ${Colors.bgDark}`
                                    }}>
                                        +
                                    </button>

                                </div>
                            </div>
                        </div>

                        <div
                            className="row flex1 w-100 py-2 px-0 mx-0 justify-content-between"
                            style={{
                            borderBottom: `1px solid ${Colors.bgDark}`
                        }}>
                            <label
                                className='mt-2'
                                style={{
                                fontWeight: "bold"
                            }}>
                                Weeks per Matchup
                            </label>
                            <div className="">
                                <div>
                                    <button
                                        type="button"
                                        onClick={() => incrementDecrement("weeks_per_game", "dec")}
                                        class="btn btn-dark"
                                        style={{
                                        color: Colors.colorPrimary,
                                        fontWeight: "bold",
                                        border: `1px solid ${Colors.bgDark}`
                                    }}>
                                        -
                                    </button>

                                    <span
                                        class="badge mx-2"
                                        style={{
                                        fontSize: 15
                                    }}>
                                        {schedule.weeks_per_game}
                                    </span>

                                    <button
                                        type="button"
                                        onClick={() => incrementDecrement("weeks_per_game", "inc")}
                                        class="btn btn-dark"
                                        style={{
                                        color: Colors.colorPrimary,
                                        fontWeight: "bold",
                                        border: `1px solid ${Colors.bgDark}`
                                    }}>
                                        +
                                    </button>

                                </div>
                            </div>
                        </div>

                        <div
                            className='row d-flex flex-column w-100 py-2 px-0 mx-0 mt-2 align-items-start'>
                            <h6
                                className='bold mt-1'
                                style={{
                                color: Colors.colorLight
                            }}>Playoff Seeding Tiebreaker</h6>
                            <div>
                                <Controller
                                    control={control}
                                    render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value
                                    }
                                }) => (
                                    <div
                                        style={{
                                        flexDirection: "row"
                                    }}>
                                        {Object
                                            .keys({total_points: "Total Points"})
                                            .map((key) => (
                                                <Button
                                                    onBlur={onBlur}
                                                    onClick={() => onChange(key)}
                                                    className='mr-1 mt-1'
                                                    style={{
                                                    border: `1px solid ${Colors.bgDark}`,
                                                    borderRadius: 25,
                                                    backgroundColor: value == key
                                                        ? Colors.colorPrimary
                                                        : Colors.bgLight,
                                                    color: value == key
                                                        ? Colors.bgLight
                                                        : Colors.colorLight
                                                }}>{{
                                                        total_points: "Total Points"
                                                    }[key]}</Button>
                                            ))}
                                    </div>
                                )}
                                    name="playoff_tb"
                                    defaultValue={schedule.playoff_tb}/>
                            </div>
                        </div>
                        <div className='row d-flex flex-column align-items-start py-2 px-0 mx-0'>
                            <h6 className='bold mt-1'>Playoff Seeding</h6>
                            <Controller
                                control={control}
                                render={({
                                field: {
                                    onChange,
                                    onBlur,
                                    value
                                }
                            }) => (
                                <div className='flex1 mt-1'>
                                    {Object
                                        .keys(options_data.schedule.seeding)
                                        .map((key) => (
                                            <Button
                                                onBlur={onBlur}
                                                onClick={() => onChange(key)}
                                                className='mr-1'
                                                style={{
                                                border: `1px solid ${Colors.bgDark}`,
                                                borderRadius: 25,
                                                backgroundColor: value == key
                                                    ? Colors.colorPrimary
                                                    : Colors.bgLight,
                                                color: value == key
                                                    ? Colors.bgLight
                                                    : Colors.colorLight
                                            }}>{options_data.schedule.seeding[key]}</Button>
                                        ))}
                                </div>
                            )}
                                name="playoff_seeding"
                                defaultValue={schedule.playoffSeeding}/>
                        </div>
                        <div className='row d-flex flex-column align-items-start py-2 px-0 mx-0'>
                            <h6 className='bold mt-1'>Consolation Bracket</h6>
                            <Controller
                                control={control}
                                render={({
                                field: {
                                    onChange,
                                    onBlur,
                                    value
                                }
                            }) => (
                                <div className='flex1 mt-1'>
                                    {Object
                                        .keys(options_data.schedule.consolationBracket)
                                        .map((key) => (
                                            <Button
                                                onBlur={onBlur}
                                                onClick={() => onChange(key)}
                                                className='mr-1'
                                                style={{
                                                border: `1px solid ${Colors.bgDark}`,
                                                borderRadius: 25,
                                                backgroundColor: value == key
                                                    ? Colors.colorPrimary
                                                    : Colors.bgLight,
                                                color: value == key
                                                    ? Colors.bgLight
                                                    : Colors.colorLight
                                            }}>
                                                {options_data.schedule.consolationBracket[key]}</Button>
                                        ))}
                                </div>
                            )}
                                name="consolation_bracket"
                                defaultValue={schedule.consolationBracket}/>
                        </div>

                        <div className='row d-flex flex-column align-items-start my-3'>
                            <h6
                                className='bold mt-1'
                                style={{
                                color: Colors.colorLight
                            }}>Head-to-Head Schedule</h6>
                            <div
                                className='my-3'
                                style={{
                                width: 150
                            }}>
                                <Select
                                    className="basic-single"
                                    name="color"
                                    defaultValue={options[0]}
                                    onChange={(value) => {
                                    setSelected(value.value)
                                }}
                                    options={options}
                                    styles={STYLES.REACT_SELECT}/>
                            </div>
                            {schedule.schedule.length > 0 && schedule
                                .schedule[selected]
                                .matchups
                                .map((matchup, key) => (
                                    <div
                                        className='d-flex flex-row w-100 py-2'
                                        style={{
                                        borderBottom: `1px solid ${Colors.bgDark}`
                                    }}>
                                        <div className="flex1 d-flex flex-row text-left text-white">
                                            <div>
                                                <img width='40px' className="rounded-circle mx-2" src={matchup.team_1.image}/>
                                            </div>
                                            <div className="m-0 pl-2 align-items-start flex1 p-0">
                                                <h6
                                                    className='m-0 bold text-truncate'
                                                    style={{
                                                    maxWidth: '200px'
                                                }}>{matchup.team_1.team}</h6>
                                                <p
                                                    className='p-0 m-0 text-truncate'
                                                    style={{
                                                    color: Colors.colorSecondary
                                                }}>{matchup.team_1.owner}</p>
                                            </div>
                                        </div>
                                        <span>vs.</span>
                                        <div
                                            className="flex1 d-flex flex-row-reverse justify-content-end text-right text-white">
                                            <div>
                                                <img width='40px' className="rounded-circle mx-2" src={matchup.team_2.image}/>
                                            </div>
                                            <div className="m-0 pl-2 align-items-start flex1 p-0">
                                                <h6
                                                    className='m-0 bold text-truncate'
                                                    style={{
                                                    maxWidth: '220px'
                                                }}>{matchup.team_2.team}</h6>
                                                <p
                                                    className='p-0 m-0 text-truncate'
                                                    style={{
                                                    color: Colors.colorSecondary,
                                                    maxWidth: '220px'
                                                }}>{matchup.team_2.owner}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
            </PerfectScrollbar>
        </div>
    )
}

export default ScheduleSettings