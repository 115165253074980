import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Button, UncontrolledAlert, Input } from "reactstrap";
import { loginESPN, getRoster, saveRoster, autoLogin, triggerESPNImport, sleeperLeagues, triggerSleeperImport } from "../../actions/Import/api"
import { errorMessage, setInForage, successMessage, TOAST } from "../../helpers/common-methods";
import sleeperLogo from '../../assets/images/sleeperLogo.png';
import styled from "styled-components"
import ImportProgress from "../../components/LeagueImport/ImportProgress";
import { LoaderCenter } from "../../components/Loader";
import ErrorText from "../../components/ErrorText";
// import { Colors } from "../../helpers/colors";
import { STYLES } from "../../helpers/stylesHelper";
import store from "../../store";
import { ColorContext } from '../../context/ColorProvider';

const Li = styled.li`
    list-style-type: decimal;
    margin: .5rem 0;
`

class SleeperImport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            container: 2,
            username: "",
            resp: [],
            teamId: "",
            teamName: "",
            players: [],
            errors: [],
            sport: "NFL",
            importSuccess: false,
            response: {},
            selectedLeague: null,
            showToast: false,
            step: "import",
            selectedLeagueId: null,
        }
    }
    static contextType = ColorContext;

    componentDidMount(){
        // this.autoLogin(this.props.sport)
    }

    triggerImport(league){
        if(league.status == "pre_draft"){
            errorMessage("Your league is in pre-draft stage.")
            return 0
        }
        this.setState({ selectedLeagueId: league.id})
        // Call Import API here
        store.dispatch(triggerSleeperImport(
            {leagueId: league.id, name: league.name, sport: this.props.sport, username: this.state.username}
            )).then((resp) => {
            this.setState({container: 6})
        }).catch(async ({message, errors}) => {
            if(message) {
                if(message == "IMPORTED"){
                    // NAVIGATE TO CLAIM FLOW
                    await setInForage("claimTeam", {
                        lgId: league.id,
                        platform: "fantrax",
                        lgName: decodeURIComponent(league.name),
                        sbId: null,
                        leagueId: league.leagueId,
                        grudgeId: null
                    })
                    this.props.history.push("/newClaimTeam?fromImport=true")
                }else{
                    errorMessage(message)
                }
            }
            else if(errors) {
                errorMessage(errors)
            }
            else {
                errorMessage("Something went wrong")
            }
        })
        this.setState({selectedLeague: league})
    }

    updateContainer(index){
        this.setState({ container: index })
    }

    handleInputChange(e) {
        this.setState({
          [e.target.name]: e.target.value
        });
      }

    handleSubmit = () => {
        this.setState({errors: {}})
        if(this.state.username == null || this.state.username == ""){
            this.setState({errors: {
                username: "Username is required"
            }})
        }else{
            this.setState({ loading: true })
            store.dispatch(sleeperLeagues("?username="+this.state.username)).then((resp) => {
                this.setState({ loading: false, resp: resp, container: 3})
            }).catch((err) => {
            this.setState({ errors: err, loading: false })
            })
        }
    }

    autoLogin(sport = this.props.sport){
        this.setState({ loading: true })
        this.props.autoLogin({ platform: "sleeper", sport: sport }).then((resp) => {
            this.setState({ loading: false, resp: resp, container: 3})
        }).catch((err) => {
            this.setState({ loading: false, container: 2})
        })  
    }

    updateStep = (step) => {
        this.setState({step: step})
    }

    render() {
        const Colors = this.context;
        return (
            <div className="d-flex flex-column flex1">
                <div className="p-md-2 p-2 d-flex flex-column flex1">
                    {this.state.loading ? (<LoaderCenter msg={<div className="text-center mb-5 d-flex flex-column flex1" ><p className="mb-1" style={{fontSize: "20px"}} >Fetching leagues...</p></div>} />) : (
                        this.state.error ? (<ErrorText msg={this.state.error} />) : (
                            <div>
                                { this.state.errors.exception != undefined && <UncontrolledAlert color="danger">
                                    {this.state.errors.exception}
                                </UncontrolledAlert>}
                                    {(() => {
                                    switch (this.state.container) {
                                        case 2:
                                            return (
                                                <div className="mx-auto p-3" style={{maxWidth: 400}} >
                                                    <Input placeholder="Sleeper Username" onChange={(e) => {this.setState({username: e.target.value})}} />
                                                    {this.state.errors?.username && <ErrorText msg={this.state.errors.username} />}
                                                    <Button disabled={this.state.loading} onClick={this.handleSubmit} className="mt-4" style={STYLES.BUTTON_PRIMARY_TRANSPARENT} >Get Sleeper Leagues</Button>
                                                </div>
                                            )
                                        case 3: 
                                            return (
                                                <div className="d-flex flex-column flex1"  >
                                                    {this.state.resp.length > 0 ? (
                                                        <div className="d-flex flex-column flex1">
                                                            { this.props?.for != 'newUser' && <h3 style={{ marginBottom: "20px", fontWeight: "bold" }} className="greenText" >Which league you would like to import:</h3> }
                                                            <div className="d-flex flex-column">
                                                                {this.state.resp.map((league) => {
                                                                    return (
                                                                        <div className="text-left pointer my-1" style={{padding: "10px", borderRadius:10, color: this.state.selectedLeagueId == league.id ? "black": "white", backgroundColor:this.state.selectedLeagueId == league.id ? Colors.colorPrimary: '' }} tag="button" onClick={() => this.triggerImport(league)} action>
                                                                            <div className="d-flex align-items-center" >
                                                                                <img className="roundImgMed mr-3" src={sleeperLogo} />
                                                                                <div>
                                                                                    <p className="bold m-0" >{league.name}</p>
                                                                                    <p className="m-0" >{league.team_name}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="d-flex flex-column justify-content-center align-items-center" >
                                                            <h4 className="text-center" style={{lineHeight: "30px"}} >We couldn't find any league in your Sleeper account, please join or create league and sync again.</h4>
                                                            <div>
                                                                <a className="d-block default_a mt-5" href="https://www.sleeper.com" target="_blank" >Go to Sleeper</a>
                                                            </div>
                                                        </div>
                                                        
                                                    )}
                                                </div>
                                            )
                                        case 6:
                                            return (
                                               <ImportProgress
                                                    handleSuccess={this.props?.handleSuccess}
                                                    showInvite={() => this.setState({container: 5, step: "invite"})}
                                                    platform={'sleeper'}
                                                    lg={this.state.selectedLeague}
                                                    sport={this.props.sport}
                                               />
                                            )
                                        default :
                                            return null
                                    }
                                    })()}
                            </div>
                        ))}
                </div>               
            </div>
        )
    }
}

const mapDispatchToProps = {
    autoLogin,
    triggerESPNImport
};
export default connect(null, mapDispatchToProps)(withRouter(SleeperImport));