import { map } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addEmoji, fetchMentions } from '../../../apis/Chat/ChatAPI';
import { LoaderCenter } from '../../../components/Loader';
import GroupMention from '../../../components/Mentions/GroupMention';
import UserMention from '../../../components/Mentions/UserMention';
// import { Colors } from '../../../helpers/colors';
import { parseMessage } from '../../../helpers/MessageHelper';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { toastError } from '../../../helpers/toasts';
import { withRouter } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'react-loaders';
import { ColorContext } from '../../../context/ColorProvider';

class Mentions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            keys: [],
            loading: true,
            reactionModal: false,

            // API States
            offset: 0,
            limit: 30,
            hasMore: true,
        }

        this.toggleReactions = this.toggleReactions.bind(this)
        this.sendReaction = this.sendReaction.bind(this)
        this.callApi = this.callApi.bind(this)
        // this.reactedBy = this.reactedBy.bind(this)
    }
    static contextType = ColorContext;

    componentDidMount() {
        this.callApi()
    }

    callApi() {
        let params = {
            league_id: this.props.leagueId,
            offset: this.state.offset,
            limit: this.state.limit
        }
        fetchMentions(params)
            .then(response => {
                this.setState({
                    data: [...this.state.data, ...Object.values(response.data)],
                    loading: false,
                    offset: this.state.offset + this.state.limit,
                    hasMore: !(Object.values(response.data).length < this.state.limit)
                })
            })
            .catch(({ message }) => {
                toastError(message)
            })
    }

    toggleReactions() {
        this.setState({
            reactionModal: !this.state.reactionModal
        })
    }

    sendReaction(emojiObject, data) {

        data = {
            emoji: emojiObject.unified.split('-')[0],
            emoji_icon: emojiObject.native,
            type: 'convo',
            socket_id: window.pusher.connection.socket_id,
            league_id: this.props.leagueId,
            window_type: data.window_type, // user or group
            id: data.id,
            to: data.to
        }


        // // update emoji state locally
        // // update count of emoji
        let newData = {};
        newData = this.state.data.map((originalMsg) => {

            var msg = JSON.parse(JSON.stringify(originalMsg))
            let emoji = emojiObject.unified.split('-')[0]

            if (msg.reactionsData == false) {
                msg.reactionsData = {}
            }

            if (msg.msgId == data.id) {
                if (msg.reactionsData[emoji] != undefined) {
                    if (msg.reactionsData[emoji].users[this.props.current_team.id] != undefined) {
                        // remove it
                        msg.reactionsData[emoji].count--;
                        if (msg.reactionsData[emoji].count == 0) {
                            delete msg.reactionsData[emoji]
                        } else {
                            delete msg.reactionsData[emoji].users[this.props.current_team.id]
                        }
                    } else {
                        msg.reactionsData[emoji].count++;
                        msg.reactionsData[emoji]["users"][this.props.current_team.id] = this.props.current_team.username;
                    }
                } else {
                    // first occurence of emoji
                    let users = {}
                    if (Object.keys(msg.reactionsData).length == 0) {
                        msg.reactionsData[emoji] = {}
                    }
                    users[this.props.current_team.id] = this.props.current_team.username
                    msg.reactionsData[emoji] = {
                        count: 1,
                        users: users,
                        emoji_icon: emojiObject.native
                    }
                }
            }
            return msg
        })

        this.setState({ data: newData });

        addEmoji(data)
            .then(response => {
                // Then
            })
            .catch(({ message }) => {
                toastError('Something went wrong while sending reaction')
            })
    }

    render() {
        const Colors = this.context;
        return (
            <>
                <div style={{ borderBottom: `0.01em solid ${Colors.darkBgBorder}`, padding: '5px 0px 5px 0px' }}>
                    <h2 className='d-flex align-items-center pl-3'>Mentions & Reactions</h2>
                </div>
                <div class="container d-flex flex-column h-100" style={{ backgroundColor: Colors.bg400 }}>
                    {
                        this.state.loading ?
                            <LoaderCenter />
                            :
                            this.state.data.length == 0 ?
                                <div className='d-flex flex1 text-center align-self-center align-items-center'><p>You don't have any <span className='bold'>mentions</span> or <span className='bold'>reactions</span> yet 😕</p></div>
                                :
                                <PerfectScrollbar className="flex1 py-2 mb-4" style={{ overflow: 'scroll' }} id="mentions-container">
                                    <InfiniteScroll
                                        // className='d-flex flex-column-reverse'
                                        dataLength={this.state.data.length}
                                        next={this.callApi}
                                        hasMore={this.state.hasMore}
                                        loader={<div className='d-flex py-3'><Loader className='m-auto' type="ball-pulse" color={Colors.colorPrimary} /></div>}
                                        endMessage={<div className='text-center py-3'><i class="fa fa-check-circle" aria-hidden="true"></i> No More Mentions or Reactions. You are all caught up!</div>}
                                        scrollableTarget={'mentions-container'}
                                    >
                                        <div className="w-100" id='mentions'>
                                            {
                                                this.state.data.map((mentionAndReaction, index) => {
                                                    switch (mentionAndReaction.msgType) {
                                                        case 'user':
                                                            return <UserMention key={mentionAndReaction.msgId} message={parseMessage(mentionAndReaction.msg)} data={mentionAndReaction} sendReaction={this.sendReaction} current_team={this.props.current_team} users={this.props.users} />
                                                        case 'group':
                                                            return <GroupMention key={mentionAndReaction.msgId} message={parseMessage(mentionAndReaction.msg)} data={mentionAndReaction} sendReaction={this.sendReaction} current_team={this.props.current_team} users={this.props.users} channel={Object.values(this.props.channels).find(channel => channel.id == mentionAndReaction.chatTo)} />
                                                    }
                                                })
                                            }
                                        </div>
                                    </InfiniteScroll>
                                </PerfectScrollbar>
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({ leagueId: state.leagueJoin.leagueId, channels: state.leagueJoin.channels, users: state.leagueJoin.users, current_team: state.leagueJoin.current_team })
export default connect(mapStateToProps, null)(Mentions);