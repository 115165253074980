import React, { useEffect } from "react"
import { useColors } from "../../../helpers/CustomHooks/useColors"
import { getProfileOverview } from "../../../apis/User/UserAPI"
import { LoaderCenter } from "../../Loader"
import { MlbTransparentIcon, NbaTransparentIcon, arenaIcon, espnIcon, fantraxIcon, matchupIconSolid, yahooIcon } from "../../../helpers/icons"
import { Collapse, Modal, UncontrolledCollapse } from "reactstrap"
import PerfectScrollbar from 'react-perfect-scrollbar';
import ComparisonViewModal from "../../../modals/Arena/ComparisonViewModal"
import MatchMakerLineup from "../MatchMaker/MatchMakerLineup"
import MatchMakerLineupModal from "../../../modals/Arena/MatchMakerLineupModal"
import store from "../../../store"
import { getStore } from "../../../helpers/common-methods"
import SingleMatchupH2hExpanded from "../../V3/Arena/Matchups/SingleMatchupExpanded"
import H2hModal from "../../../modals/Arena/H2hModal"
import { useSelector } from "react-redux"

const UserProfile = (props) => {

    const { teamId } = props
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState(null);
    const [h2hView, setH2hView] = React.useState(false)
    const [h2hData, setH2hData] = React.useState({})
    const [toggleLatestMatches, setToggleLatestMatches] = React.useState(false);
    const [comparisonData, setComparisonData] = React.useState({teamId: null,sport: null});
    const [comparisonModal, setComparisonModal] = React.useState(false);
    const activeTeam = useSelector((state) => state.arenaReducer.active_team)
    const [openCollapse, setOpenCollapse] = React.useState(activeTeam.teamId)

    const Colors = useColors()
    const { active_team } = store.getState().arenaReducer
    const { selectedTimeframe } = store.getState().arenaReducer

    useEffect(() => {
        callApi()
    },[])

    const callApi = () => {
        getProfileOverview({team_id: teamId})
            .then((response) => {
                setData(response)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getLeaguePlatformIcon = (platform) => {
        switch(platform) {
            case "yahoo":
                return yahooIcon()
            case "espn":
                return espnIcon()
            case "fantrax":
                return fantraxIcon()
            default:
                return platform.toUppercase()
        }
    }

    const toggleh2hView = (match) => { 
        let h2hData = {
            match,
            userInfo: data.userInfo,
            record: data.latestMatches?.record
        }
        setH2hData(h2hData)
        setH2hView(true)
    }

    const collapseHandler = (teamId) => setOpenCollapse((prevTeamId) => prevTeamId == teamId ? null : teamId);

    const enableComparisonView = (team) => {
        setComparisonData({
            team,
            user: data.userInfo
        });
        rematchHandler(team)
        setComparisonModal(true);
    }

    const disableComparisonView = () => {
        setComparisonModal(false);

        setComparisonData({
            teamId: null,
            sport: null
        });
        getStore().dispatch({ type: "SET_REMATCH_DATA", payload: null });
    }

    const rematchHandler = (team) => {
        const prepData = {
          proposalDrawer: true,
          myTeamId: active_team?.teamId,
          oppTeamId: team?.team_id,
          sport: team?.sport,
          timeFrame: selectedTimeframe?.key,
          oppTeamImg: team?.image,
          oppTeamRecord: team?.arena_record,
          oppTeamName: team?.team_name,
          oppTeamLgId: team?.league_id,
        };
        getStore().dispatch({ type: "SET_REMATCH_DATA", payload: prepData });
      };

    return (
        <div className="d-flex flex-column flex1 scrollHidden">
        {    
            loading ?
                <LoaderCenter />
                :
                (
                    <div className="d-flex flex-column flex1 scrollHidden">
                    {/* Header */}
                    <div>
                        <h1 className="m-0" style={{fontWeight:"600", fontFamily:'Alexandria', color: Colors.mlbPrimaryColor}}>{data.userInfo?.username}</h1>
                        <p className="mb-1" style={{fontWeight:"400", fontStyle:'italic', fontSize:14}}>
                            {data.userInfo?.bio && `"${data.userInfo?.bio}"`}
                        </p>
                    </div>

                    <hr className="w-100 mb-2 mt-0"style={{backgroundColor: Colors.black_90}} />

                    {/* Sport Records */}

                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <p className="m-0" style={{fontFamily:'Barlow', fontWeight:'600', color: Colors.textColorSecondary_v2 }}>Records</p>
                        <div className="d-flex flex-row">
                        {
                            data.allTimeRecords?.sport
                            ?
                            Object.entries(data?.allTimeRecords?.sport).map(([sport, record], index) => {
                            return (
                                    <div className="d-flex flex-row align-items-center ml-2">
                                        {
                                            {
                                                "NBA": <NbaTransparentIcon />,
                                                "MLB": <MlbTransparentIcon />,
                                                "NFL":  null,    
                                            }[sport]
                                        }
                                        <p className="ml-1 m-0" style={{fontFamily:'Barlow', fontWeight:'600', }}>{record}</p>
                                    </div>
                                )
                            })
                            :
                            <p className="m-0" style={{fontFamily:'Barlow', fontWeight:'600', }}>No Records yet.</p>
                        }
                        </div>
                    </div>
                    
                    <PerfectScrollbar>
                        {/* Last 5 Matches */}
                        <div>
                            <div className="d-flex flex-row justify-content-between align-items-center mt-2">
                                <p className="m-0 pointer" style={{fontFamily:'Barlow', fontWeight:'600',color: Colors.textColorSecondary_v2 }} onClick={() => setToggleLatestMatches(!toggleLatestMatches)}>Last 5 {toggleLatestMatches ? <i class="fa fa-chevron-up" aria-hidden="true"></i> : <i class="fa fa-chevron-down" aria-hidden="true"></i>}</p>
                                <p className="m-0" style={{fontFamily:'Barlow', fontWeight:'600'}}>{data.latestMatches.record}</p>
                            </div>
                            <Collapse isOpen={toggleLatestMatches}>
                                {
                                    data.latestMatches.matches.length == 0 ?
                                    <div className="text-center">
                                        <p>No Matches Yet</p>
                                    </div>
                                    :
                                    data.latestMatches.matches.map((match) => {
                                        return (
                                            <div key={match.match_id} className="d-flex flex-row align-items-center justify-content-between my-1 pointer" onClick={() => toggleh2hView(match)}>
                                                <div className="d-flex flex-row">
                                                    <p  className="m-0" style={{color: Colors.textColorSecondary_v2, fontSize:12, fontFamily:'Alexandria', fontWeight:'500'}} >{match.my_team_name} v. </p> 
                                                    <p className="m-0 ml-1" style={{fontSize:12, fontFamily:'Alexandria', fontWeight:'600'}}>{match.opponent_team_name}</p>
                                                </div>
                                                <p className="m-0" style={{fontWeight:500}}>{match.result}</p>
                                            </div>
                                        )
                                    })
                                }
                            </Collapse>
                        </div>

                        {/* Top Teams */}
                            {
                                data.teams.length > 0 
                                &&                                
                                <div className="mt-2 px-2" style={{borderRadius:10, backgroundColor:Colors.black_90}}>
                                    {
                                        data.teams.map((team) => {
                                        return (
                                                <div style={{borderBottom:`1px solid ${Colors.arenaLineupPlayerRow}`}}>
                                                    <div className="py-2 d-flex justify-content-between" key={team.team_id}>
                                                        <div className="d-flex align-items-center">
                                                            <img className="mr-2" style={{height:30, width:30, objectFit:'cover', borderRadius:'50%', border: `2px solid ${team.offseason ? Colors.textColorSecondary_v2 : Colors.colorPrimary}`}} src={team.image} />
                                                            <p className="m-0 pointer" id={`toggler-${team.team_id}`} style={{color: team.offseason ? Colors.textColorSecondary_v2 : Colors.colorPrimary, fontFamily:'Alexandria'}} onClick={() => collapseHandler(team.team_id)}>{team.team_name} <i class="fa fa-chevron-down" aria-hidden="true"></i></p>
                                                        </div>
                                                        {
                                                            !team.offseason
                                                            &&
                                                            <div className={`p-2 d-flex align-items-center ${teamId == team.team_id ? 'not-allowed' : 'pointer'}`} style={{ backgroundColor: teamId == team.team_id ? Colors.black_80 : Colors.colorPrimary, borderRadius:20, color: "#000" }} onClick={() => { teamId != team.team_id ? enableComparisonView(team) : null }}>
                                                                { matchupIconSolid() }
                                                                <p className="m-0 ml-2" style={{fontWeight:600}}>See Lineup</p>
                                                            </div>
                                                        }
                                                    </div>
                                                    {
                                                        !team.offseason
                                                        &&
                                                        <UncontrolledCollapse toggler={`toggler-${team.team_id}`} isOpen={openCollapse == team.team_id}>
                                                            <div >
                                                                {/* Arena Info */}
                                                                <div className="my-1 p-2 d-flex align-items-center justify-content-between" style={{backgroundColor:Colors.black_80, borderRadius:6}}>
                                                                    <div className="d-flex align-items-center" style={{color: Colors.black_40}}>
                                                                        <div style={{height:20, width:20}}>
                                                                            {arenaIcon()}
                                                                        </div>
                                                                        <p className="m-0 ml-2" style={{fontWeight:600}}>Arena Record</p>
                                                                    </div>
                                                                    <p className="m-0" style={{fontWeight:600}}>{team.arena_record}</p>
                                                                </div>
                                                                
                                                                {/* League Info */}
                                                                <div className="my-1 p-2 d-flex align-items-center justify-content-between" style={{backgroundColor:Colors.black_80, borderRadius:6}}>
                                                                    <div className="d-flex align-items-center" style={{color: Colors.black_40}}>
                                                                        <div style={{height:20, width:20}}>
                                                                            { getLeaguePlatformIcon(team?.imported_from.toLowerCase())}
                                                                        </div>
                                                                        <p className="m-0 ml-2 text-truncate" style={{fontWeight:600}}>{team.league_name}</p>
                                                                    </div>
                                                                    <p className="m-0" style={{fontWeight:600}}>{team.scoring_format}</p>
                                                                </div>

                                                                {/* Positions and categories */}
                                                                <div className="m-3">
                                                                    <div className="d-flex mb-2">
                                                                        <p className="m-0 w-25" style={{fontWeight:500, color:Colors.black_50}}>Pos.</p>
                                                                        <p className="m-0 w-75" style={{fontWeight:500}}>{team.positions?.join(', ')}</p>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <p className="m-0 w-25" style={{fontWeight:500, color:Colors.black_50}}>Cats.</p>
                                                                        <p className="m-0 w-75" style={{fontWeight:500}}>{team.categories?.join(', ')}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </UncontrolledCollapse>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                    </PerfectScrollbar>
                    </div>
                )
            }
            { comparisonModal && <div className={`d-flex flex-column flex1 scrollHidden justify-content-center align-items-center Modal position-absolute h-100 modalBackdropFilter ${comparisonModal ? 'Show' : ''}`} style={{ background: "rgba( 0, 0, 0, 0.3 )", zIndex:999 }}>
				<div className='d-flex flex-column p-3 scrollHidden justify-content-center' style={{ backgroundColor: "#000", margin: "0px 40px 0px 40px", maxHeight: "600px", borderRadius: 10, minWidth:"500px" }}>
                <MatchMakerLineupModal modal={comparisonModal} toggle={disableComparisonView} data={comparisonData} />
                </div>
            </div>
            } 
            { h2hView && <H2hModal modal={h2hView} data={h2hData} toggle={() => {setH2hView(false); setH2hData({})}}  /> }
        </div>
    )
}

export default UserProfile;