import React from 'react';
// import { Colors } from '../../../helpers/colors';
import { slideInLeft } from '../animationValues';
import { motion } from "framer-motion/dist/framer-motion"
import androidLogo from '../../../assets/images/android.png';
import iosLogo from '../../../assets/images/ios.png';
import background from '../../../assets/images/field-sepia.jpg';

const UserOnboardedMobile = (props) => {
	const {step, Colors} = props 
	return (
		<motion.div
			className="flex-column text-center p-4 d-flex d-md-none"
			style={{ overflow: 'hidden', backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
			animate={step == "allDone" ? 'show' :'hide'}
			transition={{ duration: 0.5 }}
			initial={false}
			variants={slideInLeft}>
 
			{step == "allDone" && <div className='d-flex flex-column flex1' >
				<h1 className="mb-3 mt-5" style={{ fontFamily: 'Fritch', color: Colors.colorPrimary }}>
					YOU ARE NOW BETTLE READY.
				</h1>
				<p>Download the app to prove your squad's dominance.</p>
				<div className="m-auto" style={{ height: '50vw', width: '50vw' }}>
					<div className="d-flex flex-column flex1 justify-content-center h-100 w-100">
						<a href='https://play.google.com/store/apps/details?id=com.bettle.bettlelive&hl=en_US&gl=US' ><img height='81px' src={androidLogo} alt="" /></a>
						<a href='https://apps.apple.com/us/app/bettle-fantasy-sports/id1591274931' className='mt-2'><img height='62px' src={iosLogo} alt="" /></a>
					</div>
				</div>
			</div>}

		</motion.div>
	);
};

export default UserOnboardedMobile;
