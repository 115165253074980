import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import AuthLandingWeb from '../../components/Auth/AuthLandingWeb/AuthLandingWeb';
import ForgetPasswordCompV2 from '../../components/Auth/ForgetPassword/ForgetPasswordCompV2';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Colors } from '../../helpers/colors';
import background from '../../assets/images/field-sepia.jpg';

const ForgetV2 = () => {

    return (
        <div className='d-flex flex-column' style={{ height: "100vh", backgroundImage:`url(${background})`}}>
            <Header />
            <div className='flex1 d-flex justify-content-center' style={{overflow:'hidden', backgroundColor:Colors.colorDark}}>
                <div cclassName='flex1 d-flex' style={{maxWidth: "600px", width: "100%"}}>
                    <ForgetPasswordCompV2 />
                </div>
                {/* <div className='flex1 d-none d-sm-flex h-100 align-items-center justify-content-center'>
                    <div className='d-none d-sm-block'>
                        <AuthLandingWeb />
                    </div>
                </div> */}
            </div>
            {/* <Footer /> */}
        </div>
    )

};

export default ForgetV2;