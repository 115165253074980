import { Modal, ModalBody } from "reactstrap"
import { backArrowIcon, closeIcon } from "../../helpers/icons";
import React from "react";
import { useColors } from "../../helpers/CustomHooks/useColors";
import SingleMatchupExpanded from "../../components/V3/Arena/Matchups/SingleMatchupExpanded";
import SingleMatchupH2h from "../../components/V3/Arena/Matchups/SingleMatchupH2h";
import UserProfile from "../../components/Matchmaker/UserProfile/UserProfile";
import PerfectScrollbar from 'react-perfect-scrollbar';

const H2hModal = (props) => {
    const { modal, toggle, data } = props


    const Colors = useColors()

    const disableH2hView = () => {
        setH2hView(false)
        setData({})
    }

    return (
        <Modal isOpen={modal} toggle={toggle} contentClassName="customModal">
            <div className="d-flex flex-column flex1 scrollHidden" style={{borderRadius:10}}>
                <div className="d-flex p-2 align-items-center" style={{backgroundColor:"#000"}}>
                    <div className="pointer mr-2" onClick={toggle} style={{height:30, width:30}}>
                        {backArrowIcon()}
                    </div>
                    <h2 style={{color: Colors.mlbPrimaryColor}}>{data?.userInfo?.username}</h2>
                </div>  
                <div style={{fontWeight:600, backgroundColor: Colors.black_90}} className="d-flex align-items-center justify-content-between p-2">
                    <p className="m-0" style={{color: Colors.black_50}}>Last 5</p>
                    <p className="m-0">{data?.record}</p>
                </div>
                <PerfectScrollbar>
                    <ModalBody style={{backgroundColor: Colors.black_90}} >
                        <div>
                            <SingleMatchupH2h match_id={data?.match?.match_id} match={data?.match} sport={data?.match?.sport}/>
                        </div>
                    </ModalBody>
                </PerfectScrollbar>
            </div>
        </Modal>
    )
}

export default H2hModal;