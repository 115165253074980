import React from 'react';
import UserOnboardedWeb from './Web/UserOnboardedWeb';
import UserOnboardedMobile from './Mobile/UserOnboardedMobile';

const UserOnboarded = (props) => {
	return (
		<>
			<UserOnboardedWeb {...props} />
			<UserOnboardedMobile {...props} />
		</>
	);
};

export default UserOnboarded;
