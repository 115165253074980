import React, { useState } from 'react';
import Header from '../../components/Header/Header';
import { Card, CardBody, InputGroup, Button, Col } from 'reactstrap';
import { Colors } from '../../helpers/colors';
import Footer from '../../components/Footer/Footer';
import { saveInternshipData } from '../../apis/Careers/Careers';
import { useForm } from 'react-hook-form';
import ErrorText from '../../components/ErrorText';
import { toastError } from '../../helpers/toasts';
import image from '../../assets/images/internship.png';

function Internships() {
  const [loading, setLoading] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    setLoading(true);
    saveInternshipData(data).then(resp => {
      console.log(resp);
      if (resp.message == "OK") {
        setSubmissionSuccess(true);
      };
      setLoading(false);
    }).catch(err => {
      console.log(err);
      toastError(err.message);
      setLoading(false);
    });
  };

  return (
    <div className='d-flex flex-column flex1' style={{overflow: "hidden"}} >  
      <Header />
      {
        submissionSuccess ?
          <div className='d-flex justify-content-center align-items-center' style={{ marginTop: '5%' }}>
            <div>
              <div className='d-flex justify-content-center align-items-center mb-3'>
                <div style={{ overflow: 'hidden', maxWidth: '180px', maxHeight: '100%' }}>
                  <img className='img-responsive rounded-circle my-1 center' src={'https://png.pngtree.com/element_our/sm/20180515/sm_5afb099b30a2a.jpg'} width='100%' style={{ border: `2px solid ${Colors.colorPrimary}`, objectFit: 'cover' }} />
                </div>
              </div>
              <h3 className='bold'>Thank you! We have received your information and will be in touch shortly.</h3>
            </div>
          </div>
          :
          <div className='d-flex flex-column flex1' style={{overflow: 'hidden' }}>
            <div className='d-flex flex-column flex1' style={{ overflow: 'auto' }}>
                <div>
                  <img src={image} width='100%' height='100%' alt="" />
                </div>
                <Col md={6} className='col-centered py-2' style={{ background: Colors.bg400, borderRadius: 10, marginTop: '-12%', opacity: '0.9' }}>
                  <h1 className='mb-0 mt-3'>Get in on the ground floor</h1>
                  <p>at an emerging <span className='bold' style={{ color: Colors.colorPrimary }}>Fantasy Sports and Gaming</span> company!</p>
                  <p>Are you looking <span className='bold' style={{ color: Colors.colorPrimary }}> to earn some cash this fall?</span> Bettle is looking to hire <span className='bold' style={{ color: Colors.colorPrimary }}>Marketing Interns</span> to help <span className='bold' style={{ color: Colors.colorPrimary }}>launch our new app</span> on college campuses this football season. This is perfect opportunity to cut your teeth at a <span className='bold' style={{ color: Colors.colorPrimary }}>Boston-based start up</span>, where you will work with a small team executing a go-to-market plan! </p>
                </Col>
                <Col md={6} className='col-centered my-2' style={{ background: Colors.bg400, borderRadius: 10 }}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Card className='border-0' style={{ background: Colors.bg400 }}>
                      <CardBody>
                        <div className='d-flex justify-content-start'>
                          <div>
                            <h5 className='bold'>Apply for this Job</h5>
                            <p className='d-flex justify-content-start'><span className='mr-1' style={{ color: 'red' }}>*</span>required</p>
                          </div>
                        </div>
                        <div className='mt-2'>
                          <label className='d-flex justify-content-start bold' htmlFor="">First Name <span className='ml-1' style={{ color: 'red' }}>*</span></label>
                          <InputGroup>
                            <input {...register('first_name', { required: true })} className='form-control border-0' placeholder='John' pe="text" />
                          </InputGroup>
                          {errors.first_name && <ErrorText msg='Name Field is required' />}
                        </div>
                        <div className='mt-2'>
                          <label className='d-flex justify-content-start bold' htmlFor="">Last Name <span className='ml-1' style={{ color: 'red' }}>*</span></label>
                          <InputGroup>
                            <input {...register('last_name', { required: true })} className='form-control border-0' placeholder='Deo' pe="text" />
                          </InputGroup>
                          {errors.last_name && <ErrorText msg='Name Field is required' />}
                        </div>
                        <div className='mt-2'>
                          <label className='d-flex justify-content-start bold' htmlFor="">Email <span className='ml-1' style={{ color: 'red' }}>*</span></label>
                          <InputGroup>
                            <input {...register('email', { required: true })} className='form-control border-0' placeholder='JohnDeo@email.com' name='email' ype="email" />
                          </InputGroup>
                          {errors.email && <ErrorText msg='Email Field is required' />}
                        </div>
                        <div className='mt-2'>
                          <label className='d-flex justify-content-start bold' htmlFor="">Phone <span className='ml-1' style={{ color: 'red' }}>*</span></label>
                          <InputGroup>
                            <input {...register('phone', { required: true })} className='form-control border-0' placeholder='(123) 456-7890' name='phone' ype="phone" />
                          </InputGroup>
                          {errors.phone && <ErrorText msg='Phone Field is required' />}
                        </div>
                        <div className='mt-2'>
                          <label className='d-flex justify-content-start bold' htmlFor="">College/University <span className='ml-1' style={{ color: 'red' }}>*</span></label>
                          <InputGroup>
                            <input {...register('college', { required: true })} className='form-control border-0' placeholder='Bettle University' name='college' type="text" />
                          </InputGroup>
                          {errors.college && <ErrorText msg='College/University Field is required' />}
                        </div>
                        <div className='mt-2'>
                          <label className='d-flex justify-content-start bold' htmlFor="">Expected Graduation Date <span className='ml-1' style={{ color: 'red' }}>*</span></label>
                          <InputGroup>
                            <input {...register('graduation_date', { required: true })} className='form-control border-0' placeholder='Fall 2022' name='graduation_date' type="text" />
                          </InputGroup>
                          {errors.graduation_date && <ErrorText msg='Graduation Date Field is required' />}
                        </div>
                        <div className='mt-2'>
                          <label className='d-flex justify-content-start bold' htmlFor="">Are you currently a Bettle user? <span className='ml-1' style={{ color: 'red' }}>*</span></label>
                          <InputGroup>
                            <div className='d-flex justify-content-start w-100'>
                              <input {...register('work_status', { required: true })} className='mr-1' name='work_status' value='Yes' type="radio" /> Yes
                            </div>
                            <div className='d-flex justify-content-start w-100'>
                              <input {...register('work_status', { required: true })} className='mr-1' name='work_status' value='No' type="radio" /> No
                            </div>
                            {errors.work_status && <ErrorText msg='This Field is required' />}
                          </InputGroup>
                        </div>
                        <div className='text-right' >
                        <Button
                          className='mt-3 px-5 bold'
                          disabled={loading}
                          style={{
                            color: 'black',
                            backgroundColor: Colors.colorPrimary,
                            border: `1px solid ${Colors.colorPrimary}`,
                          }}
                        >
                          {loading ? (
                            <i class="fa fa-refresh fa-spin"></i>
                          ) : (
                            "Submit"
                          )}
                        </Button>
                        </div>
                        
                      </CardBody>
                    </Card>
                  </form>
                </Col>
            </div>
            {/* <Footer /> */}
          </div>
      }
    </div>
  )
}

export default Internships;