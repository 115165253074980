import React, { Component } from 'react';
import { PlayerVPlayer } from './PlayerVPlayer';
import { PositonVPosition } from './PositonVPosition';
import { TeamVTeam } from './TeamVTeam';

export const PrepareSidebetData = (props) => {
  return (
    <>
        <p></p>
        <p><span className='bold'>Bet Type:</span> {props.data.bet}</p>
        {
            {
                'team':
                    <TeamVTeam {...props} />,
                'player':
                    <PlayerVPlayer {...props} key={props.data.cId} />,
                'position':
                    <PositonVPosition {...props} />
            }[props.data.myBetType]
        }
        <p><span className='bold'>Time Frame:</span> {props.data.myBetTimeframe}</p>
        <p><span className='bold'>Amount:</span> {props.data.myBetPrice}</p>
    </>
  )
}
