import React, { Component } from 'react';
import { Button } from 'reactstrap';
// import { Colors } from '../../helpers/colors';
// import Players from '../../Players/Players';
// import Standings from '../../Standings/Standings';
import { updateActiveLeagueId, saveActiveLeague } from '../../apis/Sidebar/LeagueAPI';
import queryString from 'query-string';
import { STYLES } from '../../helpers/stylesHelper';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getInForage, removeInForage } from '../../helpers/common-methods';
import ImportLeagueModal from '../../modals/ImportLeagueModal';
import ClaimTeamModal from '../../modals/ClaimTeamModal';
import JoinedTeamsModal from '../../modals/SeasonLong/JoinedTeamsModal';
import WelcomeModal from '../../modals/WelcomeModal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import LeaguesHeader from '../../components/V3/Dashboard/LeaguesHeader';
import { ColorContext } from '../../context/ColorProvider';

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			switchLeagueModal: false,
			user: this.props.auth.user,
			loading: true,
			importLeagueModal: false,
			leagues: [],
			claimTeamModal: false,
			claimData: {},
			welcomeModal: false,
			importPlatform: null,
			joinedTeamModal: false,
			selectedLg: null,
			copyText: 'Copy Invite Link'
		};
		this.toggle = this.toggle.bind(this);
	}

	static contextType = ColorContext;

	toggle() {
		this.setState({
			switchLeagueModal: !this.state.switchLeagueModal
		});
	}

	componentDidMount() {
		// Get leagues
		let params = queryString.parse(this.props.location.search);
		if (params.platform) {
			this.setState({ importLeagueModal: true, importPlatform: params.platform });
		} else {
			// VERIFY IF USER VISITING THE PAGE FIRST TIME THEN TRIGGER IMPORT LEAGUE FLOW
			getInForage('newUser')
				.then((val) => {
					if (val) {
						this.setState({ welcomeModal: true });
					}
				})
				.catch((err) => {
					console.log(err);
				});
			// Check if user has any pending claim team
			// getInForage('claimTeam')
			// 	.then((val) => {
			// 		if (val) {
			// 			this.setState({ claimTeamModal: true, claimData: val });
			// 		}
			// 	})
			// 	.catch((err) => {
			// 		console.log(err);
			// 	});
		}
	}

	async toggleImportLeague() {
		this.props.history.replace({ pathname: window.location.pathname, search: '' });
		this.setState((prevState) => ({
			importLeagueModal: !this.state.importLeagueModal,
			importPlatform: null
		}));
	}

	async toggleClaimTeam() {
		await removeInForage('claimTeam')
		this.setState((prevState) => ({
			claimTeamModal: !prevState.claimTeamModal
		}));
	}

	toggleJoinedTeamModal = () => {
		this.setState({ joinedTeamModal: !this.state.joinedTeamModal });
	};

	handleJoineView = (lg) => {
		this.setState({ selectedLg: lg });
		this.toggleJoinedTeamModal();
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.leagueId != this.props.leagueId) {
			this.props.history.push('/dashboard')
		}
	}

	getCopyText = () => {
		var url = encodeURI(this.props.activeLeague.inviteLink);
		return "You've been invited to join " + this.props.activeLeague.league_name + ' on Bettle. Sign up and claim your team here: ' + url;
	};

	render() {
		const Colors = this.context;
		return (
			<div className='d-flex flex-column flex1' >
				{/* <h2 className="d-flex align-items-center pl-3" style={{ borderBottom: `0.01em solid ${Colors.darkBgBorder}`, paddingTop: '9px', paddingBottom: '9px' }}>
					Dashboard
				</h2> */}
				<LeaguesHeader {...this.props} />
				<div class="d-flex flex1 justify-content-center pt-5" style={{ backgroundColor: Colors.bgDark_v3 }}>
					<div className="text-center" style={{ width: '50%' }}>
						<h1>🎉</h1>
						<h1 style={{ color: Colors.colorPrimary }}>Welcome Aboard!</h1>
						<p>
							Having you as a part of <span style={{ color: Colors.colorPrimary }}>Bettle</span> is a great honor! We hope that your new journey with us will be exciting and fruitful. Welcome aboard!
						</p>
						{this.props.activeLeague &&
						Object.keys(this.props.activeLeague).length > 0 && (
							<CopyToClipboard text={this.getCopyText()} onCopy={() => this.setState({ copyText: 'Copied!' })}>
								<Button className="my-2" style={STYLES.BUTTON_PRIMARY_TRANSPARENT}>
									<i class="fa fa-clone" aria-hidden="true" /> {this.state.copyText}
								</Button>
							</CopyToClipboard>
						)}
					</div>
				</div>
				{this.state.welcomeModal && <WelcomeModal modal={true} toggleImportLeague={() => this.toggleImportLeague()} />}
				{this.state.joinedTeamModal && <JoinedTeamsModal lg={this.state.selectedLg} modal={true} toggle={this.toggleJoinedTeamModal} />}
				{this.state.claimTeamModal && <ClaimTeamModal modal={this.state.claimTeamModal} toggle={(close) => this.toggleClaimTeam(close)} claimData={this.state.claimData} />}
				{/* {this.state.importLeagueModal && <ImportLeagueModal importPlatform={this.state.importPlatform} modal={this.state.importLeagueModal} toggle={(close) => this.toggleImportLeague(close)} />} */}
			</div>
		);
	}
}

const mapDispatchToProps = { updateActiveLeagueId, saveActiveLeague };
const mapStateToProps = (state) => ({
	leagueId: state.leagueJoin.leagueId,
	activeLeague: state.leagueJoin.activeLeague,
	auth: state.auth
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));
