import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Colors } from '../../helpers/colors';
import { withRouter } from 'react-router-dom';
import { bettleLogo } from '../../helpers/icons';
import { Button } from 'reactstrap';
import store from '../../store';
import { ColorContext } from '../../context/ColorProvider';

class SimpleLayout extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
    static contextType = ColorContext;

    handleHomeNavigation = () => {
        this.props.history.push("/dashboard")
    }

	render() {
        const Colors = this.context;
		return (
			<div className="app d-flex" style={{ overflow: 'hidden', height: '100vh' }}>
                <div className="d-flex flex1 flex-column pl-0" style={{ height: '100vh', backgroundColor: Colors.bgDark }}>
                    <div className='py-3 d-flex justify-content-between align-items-center px-4' style={{height: "8%", background: Colors.colorPrimary, color: Colors.bgLight}} >
                        {/* <div className='img-fluid w-50' > */}
                        {/* </div> */}
                        <div className='' >
                            <a onClick={this.handleHomeNavigation} className='bold pointer' ><u>Go to Home</u></a>
                        </div>
                        <div className='h-100'> 
                            {/* {bettleLogo()} */}
                        </div>
                    </div>
                    <this.props.Component {...this.props} />
                </div>
			</div>
		);
	}
}

const mapStateToProps = state => ({ nav_loading: state.NavReducer.loading });
export default connect(mapStateToProps, null)(withRouter(SimpleLayout));
