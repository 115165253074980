import './bootstrap';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './router';
import { Provider } from 'react-redux';
import ErrorBoundary from "./pages/ErrorBoundary";
// CoreUI Icons Set
// import 'loaders.css/src/animations/ball-clip-rotate.scss';
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.scss';
// import './scss/style.css';
import './scss/custom.css';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TagManager from 'react-gtm-module'
import 'react-perfect-scrollbar/dist/css/styles.css';
import { getStore } from './helpers/common-methods';
import ColorProvider from './context/ColorProvider';

// landing page for mobile users
// import LandingPageMob from './pages/LandingPageMob';
if (process.env.NODE_ENV == "production") {
    const tagManagerArgs = {
        gtmId: 'GTM-TXPG3BS',
    }
    TagManager.initialize(tagManagerArgs)
}
// Containers
// import { DefaultLayout } from './containers';
ReactDOM.render(
    <Provider store={getStore()}>
        <ErrorBoundary>
            <ColorProvider>
                <App />
            </ColorProvider>
            <ToastContainer
                position="bottom-right"
                newestOnTop={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                closeOnClick={false}
                hideProgressBar={true}
                autoClose={5000}
                transition={Slide}
                />
        </ErrorBoundary>
    </Provider>,
    document.getElementById('root')
);
