import React from 'react';
import { Button, UncontrolledCollapse, Card, CardBody } from 'reactstrap';
import { Colors } from '../../../../helpers/colors';
import PerfectScrollbar from 'react-perfect-scrollbar';

function IneligibleLocation(props) {
  return (
    <div className='d-flex flex-column flex1 scrollHidden'>
      {props.slug == "MatchMakerRules" ?
        <>
          <h5 className='bold' style={{ fontSize: 20 }}>Ineligible Locations</h5>
          <p className='ml-3'>Bettle is currently only available in the United States due to regulations or pending applications. Furthermore, Bettle is currently not available in the following US states: <span style={{ color: Colors.colorPrimary }}>Alabama, Alaska, Arizona, Delaware, Hawaii, Idaho, Indiana, Iowa, Louisiana, Mississippi, Missouri, Montana, Nevada, New York, North Dakota, Ohio, South Dakota, Vermont, Virginia, Washington Wyoming.</span></p>
        </>
        :
        <>
          <div className='d-flex justify-content-start align-items-center px-3 my-2' style={{ borderBottom: `1px solid ${Colors.darkBgBorder}`, cursor: 'pointer' }} onClick={() => props.setView('default')}>
            <h5><span> <i className='mr-2 fa fa-angle-left'></i></span>Ineligible Locations</h5>
          </div>
          <PerfectScrollbar>
            <Card className='border-0 mb-0 mx-3' style={{ background: Colors.bgLight }}>
              <CardBody>
                <h5 className='bold' style={{ fontSize: 20 }}>Ineligible Locations</h5>
                <p>Bettle is currently only available in the United States due to regulations or pending applications. Furthermore, Bettle is currently not available in the following US states: <span style={{ color: Colors.colorPrimary }}>Alabama, Alaska, Arizona, Delaware, Hawaii, Idaho, Indiana, Iowa, Louisiana, Mississippi, Missouri, Montana, Nevada, New York, North Dakota, Ohio, South Dakota, Vermont, Virginia, Washington Wyoming.</span></p>
              </CardBody>
            </Card>
          </PerfectScrollbar>
        </>
      }
    </div>
  )
}

export default IneligibleLocation;