import React, {useState, useEffect} from "react"
import styled, {css} from 'styled-components'
import {Nav, NavItem, NavLink} from "reactstrap"
import { Link } from "react-router-dom"
// import { Colors } from "../../../../helpers/colors"
import { useColors } from '../../../../helpers/CustomHooks/useColors';
import store from "../../../../store";
import PlayerCardNavNba from "../../../../components/V3/PlayerCard/PlayerCardNavNba";

const PlayerCardNav = (props) => {
    const Colors = useColors();
    const CNavLink = styled(NavLink)`
        white-space: nowrap;
    `

    const CNav = styled(Nav)`
        background: #333D44;
        flex-wrap: nowrap;
        overflow-x: auto;
    `

    const { activeLeagueSport } = store.getState().leagueJoin

    const [activeTab, setActiveTab] = useState(1)

    const handleTab = tab => {
        setActiveTab(tab)
        props.handleActiveTab(tab)
    }
    
    return (
        activeLeagueSport == "NBA" ?
        <PlayerCardNavNba {...props} />
        :
        <CNav className="" >  
            <NavItem className="flex1 text-center" style={{cursor:'pointer',borderBottom: activeTab == 1 ? `2px solid ${Colors.colorPrimary}`:''}} onClick={() => handleTab(1)}>
                <CNavLink>
                    <div className="d-flex justify-content-center align-items-center" style={{color:activeTab == 1 ? Colors.colorPrimary : ''}} >
                        Overview
                    </div>
                </CNavLink>
            </NavItem>
            <NavItem className="flex1 text-center" style={{cursor:'pointer',borderBottom: activeTab == 2 ? `2px solid ${Colors.colorPrimary}`:''}} onClick={() => handleTab(2)}>
                <CNavLink>
                    <div className="d-flex justify-content-center align-items-center" style={{color:activeTab == 2 ? Colors.colorPrimary : ''}}>
                        Game Log
                    </div>
                </CNavLink>
            </NavItem>
            <NavItem className="flex1 text-center" style={{cursor:'pointer',borderBottom: activeTab == 3 ? `2px solid ${Colors.colorPrimary}`:''}} onClick={() => handleTab(3)}>
                <CNavLink>
                    <div className="d-flex justify-content-center align-items-center" style={{color:activeTab == 3 ? Colors.colorPrimary : ''}}>
                        Stats
                    </div>
                </CNavLink>
            </NavItem>
            <NavItem className="flex1 text-center" style={{cursor:'pointer',borderBottom: activeTab == 4 ? `2px solid ${Colors.colorPrimary}`:''}} onClick={() => handleTab(4)}>
                <CNavLink>
                    <div className="d-flex justify-content-center align-items-center" style={{color:activeTab == 4 ? Colors.colorPrimary : ''}}>
                        News
                    </div>
                </CNavLink>
            </NavItem>
            {/* <NavItem className="flex1 text-center" style={{cursor:'pointer',borderBottom: activeTab == 5 ? `2px solid ${Colors.colorPrimary}`:''}} onClick={() => handleTab(5)}>
                <CNavLink>
                    <div className="d-flex justify-content-center align-items-center" style={{color:activeTab == 5 ? Colors.colorPrimary : ''}}>
                        Bio
                    </div>
                </CNavLink>
            </NavItem> */}
      </CNav>
    )
}

export default PlayerCardNav