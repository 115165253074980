import React, { Component } from "react";
import { Card } from "reactstrap";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../../components/Header/Header';

class Privacy extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            data: []
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }


    render() {
        return (
            <div className="d-flex flex-column" style={{ height: '100vh', overflow: 'hidden' }}>
                <Header />
                <div className="container d-flex flex1 my-2" style={{ overflow: 'scroll' }}>
                    <Card className="flex1" style={{ padding: "20px", overflow: 'scroll' }}>

                        <h5 className="bold" >
                            Bettle is committed to creating a fair, fun and safe platform for all users. We are committed to transparency and strive to ensure your privacy and security are safe here.
                        </h5>

                        <h5 className="bold" >How is my information protected?</h5>
                        <p>Bettle is constantly improving its extensive security program to exceed Industry standard expectations and best practices. We use a variety of processes and technologies to protect your personal information from unauthorized access, use, or disclosure. All confidential consumer information is safeguarded by encryption tools, firewalls, and other technologies. Our IT and engineering teams are constantly reviewing our infrastructure to detect and improve on inconsistencies as technology changes.</p>

                        <h5 className="bold">Protect yourself</h5>
                        <ul>
                            <li>Change your password every few months</li>
                            <li>Never give your password to anyone. Bettle will never ask for it</li>
                            <li>Use numbers, letters, and special characters in your password</li>'
                            <li>The longer the password, the better</li>
                            <li>Don’t use family names names and important dates</li>
                            <li>Never share your password with anyone</li>
                            <li>Use a password unique to Bettle</li>
                            <li>Sign out of your account if you are using a shared computer</li>
                        </ul>

                        <h5 className="bold" >What should I do if my account has been misallocated, compromised or otherwise mishandled?</h5>
                        <p>If you believe your account has been misallocated, compromised or mishandled please contact support@bettle.com for assistance.Please provide all pertinent information related to your claim and a dedicated member of our Customer Service team will address your support ticket within 24 hours. If your support ticket cannot be resolved to your satisfaction by a Customer Service Representative, you may request escalation of the ticket to a Customer Service Supervisor. </p>

                        <p>For additional consumer protection information, visit the <a href="https://www.ftc.gov/faq/consumer-protection">Federal Trade Commission’s consumer protection page.</a></p>
                    </Card>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = {
};

export default connect(null, mapDispatchToProps)(withRouter(Privacy));