import React, { useEffect, useState } from 'react';
import { CardBody, Button, Table } from 'reactstrap';
import { arenaIcon, arenaBackIcon } from '../../../helpers/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {StickyTable, Row, Cell} from 'react-sticky-table';
import EnterArenaModal from '../../../modals/Arena/EnterArenaModal';
import StarterModal from '../../../modals/Arena/StarterModal';
import ArenaPlayerRow from './ArenaPlayerRow';
import store from '../../../store';
import SwapPlayersModal from '../../../modals/Arena/SwapPlayersModal';
import ErrorText from '../../ErrorText';
import { registerUserInArena } from '../../../apis/Arena/MatchMakerApis';
import LoadingOverlay from 'react-loading-overlay';
import AfterOnboardFooter from '../AfterOnboardFooter';
import { useColors } from '../../../helpers/CustomHooks/useColors';
import moment from 'moment-timezone';
import ArenaPlayerSchedule from './ArenaPlayerSchedule';
import { defaultTimezone, findEligiblePositionsNfl, normalizedBalance, parseScoringPeriod } from '../../../helpers/Arena/ArenaHelper';
import { useSelector } from 'react-redux';
import DepositFlowModal from '../../../modals/Arena/DepositFlowModal';

function LineupStarters(props) {

    const Colors = useColors()

  const [loader, setLoader] = useState(false)
  const [error, setError] = useState()
  const [swapPlayers, setSwapPlayers] = useState([])
  const [showSwap, setShowSwap] = useState(false)
  const [selectedPlayer, setSelectedPlayer] = useState(null)
  const [selectedPlayerIndex, setSelectedPlayerIndex] = useState(null)
  const [errorModal, setErrorModal] = useState(false)
  const [confirmPlayerModal, setConfirmPlayerModal] = useState(true)
  const [arenaSuccessModal, setArenaSuccessModal] = useState(false)
  const [playerCardModal, setPlayerCardModal] = useState(false)
  const [tappedPlayerId, setTappedPlayerId] = useState()
  const experience = store.getState().leagueJoin.app_status
  const userBalance = useSelector(state => state.arenaReducer.userBalance);
  const [depositFlow, setDepositFlow] = useState(false);

  const [activeTab, setActiveTab] = useState('lineup') // lineup, schedule
  
  // CHECK IF USER IS ONBOARDING
  const {afterOnboard, successCallback, fromReenter, fromRegister, registerSuccessCallBack, sport} = props
  const {arenaData, teamId, step, decrement, updateStepsData, stepsData, afterOnboardData, closePlayerModal, callBack, updateTeamsLoading} = props

  const { selectedTimeframe } = store.getState().arenaReducer

  const getPlayers = () => {
    if(afterOnboard){
        return afterOnboardData.teamData.players
      }else{
          var players = []
          arenaData.map((item) => {
              if(item.teamId == teamId){
                  players = item.players
              }
          })
          return players
      }   
  }
  const [players, setPlayers] = useState(stepsData?.players ? stepsData?.players : getPlayers())

      // THIS FUNCTION RUNS WHEN USER ONBOARD
      const onBoardUser = () => {
        setLoader(true)
        // CALL API AND SAVE DATA
        var dataCopy = JSON.parse(JSON.stringify(stepsData))
        delete dataCopy["terms"]
        // dataCopy["maxAmount"] = dataCopy["maxBet"]["maxAmount"]
        // dataCopy["maxExposure"] = dataCopy["maxExposure"]["amount"]
        // delete dataCopy["maxBet"]
        dataCopy["players"] = players.starters
        callBack(dataCopy, teamId);

        // registerUserInArena(dataCopy).then((resp) => {
        //     // REDIRECT USER TO SUCCESS SCREEN
        //     setLoader(false)
        //     if(fromRegister){
        //         registerSuccessCallBack()
        //     }else{
        //         setArenaSuccessModal(true)
        //     }
        // }).catch((err) => {
        //     setError(err.message)
        //     setErrorModal(true)
        //     setLoader(false)
        // })
    }

    const toggleDepositFlow = () => setDepositFlow(!depositFlow);

    const onSubmit = (depositModal = false) => {
        if (depositModal) {
            if(normalizedBalance(userBalance?.balance) < 10 && !userBalance?.depositClaimed){
                setDepositFlow(true);
            }
        } else {
            setDepositFlow(false);
            var validated = true
            players.starters.forEach((val) => {
                if(val.playerId == null){
                    validated = false
                }
            })
            if(!validated){
                setError("Seems like you have some empty spots.")
                setErrorModal(true)
            }else{
                if(afterOnboard){
                    if(fromReenter){
                        // FULL PLAYERS LIST
                        successCallback(players)
                    }else{
                        successCallback(players.starters)
                    }
                }else{
                    if(!fromRegister){
                        updateStepsData("players", players)
                    }
                    // ONBOARD USER
                    onBoardUser()
                }
            }
        }
  }

  const findPlayerIndex = (playerId, list) => {
      var index = null
      for(var i=0; i<list.length; i++){
          if(list[i].playerId == playerId){
              index = i
              break;
          }
      }
      return index
  }

      // PLAYER COMING FROM BENCH
      const selectPlayer = (benchPlayer, index) => {
        var playersCopy = JSON.parse(JSON.stringify(players))
        var starterCopy = JSON.parse(JSON.stringify(players.starters[selectedPlayerIndex]))
        playersCopy.starters[selectedPlayerIndex]["pos"] = benchPlayer["pos"]
        playersCopy.starters[selectedPlayerIndex]["playerId"] = benchPlayer["playerId"]
        playersCopy.starters[selectedPlayerIndex]["fullName"] = benchPlayer["fullName"]
        if(experience == "BASEBALL_ARENA" || experience == "BASKETBALL_ARENA"){
            playersCopy.starters[selectedPlayerIndex]["eligiblePositions"] = benchPlayer["eligiblePositions"]
        }
        playersCopy.starters[selectedPlayerIndex]["team"] = benchPlayer["team"]
        playersCopy.starters[selectedPlayerIndex]["image"] = benchPlayer["image"]
        playersCopy.starters[selectedPlayerIndex]["opps"] = benchPlayer["opps"]
        // MOVE STARTER TO BENCH
        var benchIndex = findPlayerIndex(benchPlayer.playerId, playersCopy.bench)
        playersCopy.bench[benchIndex]["pos"] = starterCopy["pos"]
        playersCopy.bench[benchIndex]["playerId"] = starterCopy["playerId"]
        playersCopy.bench[benchIndex]["fullName"] = starterCopy["fullName"]
        if(experience == "BASEBALL_ARENA" || experience == "BASKETBALL_ARENA"){
            playersCopy.bench[benchIndex]["eligiblePositions"] = starterCopy["eligiblePositions"]
        }
        playersCopy.bench[benchIndex]["team"] = starterCopy["team"]
        playersCopy.bench[benchIndex]["image"] = starterCopy["image"]
        playersCopy.bench[benchIndex]["opps"] = starterCopy["opps"]
        setPlayers(playersCopy)
        setSwapPlayers([])
        setSelectedPlayer(null)
        setSelectedPlayerIndex(null)
        setShowSwap(false)
    }

        // THIS WILL FIND ELIGIBLE POSITIONS FOR A GIVEN PLAYER FOR EXAMPLE 1B CAN BE GO TO 1B/UTIL
        const findEligiblePositions = (nativePos) => {
          // RULES
          // SP/RP CAN GO TO P
          // C -> C/UTIL
          // 2B -> 2B/UTIL
          // 3B -> 3B/UTIL
          // SS -> SS/UTIL
          // LF/CF/RF -> OF/UTIL
          // ANY NON P -> UTIL
          if(nativePos == "C"){
              return ["C", "UTIL"]
          }else if(nativePos == "1B"){
              return ["1B", "UTIL"]
          }else if(nativePos == "2B"){
              return ["2B", "UTIL"]
          }else if(nativePos == "3B"){
              return ["3B", "UTIL"]
          }else if(nativePos == "SS"){
              return ["SS", "UTIL"]
          }else if(nativePos == "P"){
              return ["P", "RP", "SP"]
          }else if(nativePos == "OF"){
              return ["LF", "RF", "CF", "OF"]
          }else if(nativePos == "UTIL"){
              return ["LF", "RF", "CF", "OF", "C", "1B", "2B", "3B"]
          }
          return []
      }

      	
        const findEligiblePositionsNba = (nativePos) => {
            if(nativePos == "PG"){
                return ["PG"];
            }else if(nativePos == "SG"){
                return ["SG"];
            }else if(nativePos == "SF"){
                return ["SF"];
            }else if(nativePos == "PF"){
                return ["PF"];
            }else if(nativePos == "C"){
                return ["C"];
            }else if(nativePos == "G"){
                return ["G", "PG", "SG"];
            }else if(nativePos == "F"){
                return ["F", "SF", "PF"];
            }else if(nativePos == "UTIL"){
                return ["PG", "SG", "SF", "PF", "C", "G", "F"];
            }
            return []
        }

        // THIS WILL FILTER OUT ELIGIBLE PLAYERS FOR SWAPPING
        const displaySwapablePlayers = (player, index) => {
          var validPlayers = []
          if(experience == "BASEBALL_ARENA" || experience == "BASKETBALL_ARENA"){
            
            if(experience == "BASEBALL_ARENA"){
                var validPositions = findEligiblePositions(player.defaultPos)
            }else if(experience == "BASKETBALL_ARENA"){
                var validPositions = findEligiblePositionsNba(player.defaultPos)
            }
              var foundIntersect = []
              for(var i=0; i<players.bench.length; i++){
                  foundIntersect = []
                  foundIntersect = validPositions.filter(function(n) {
                      return players.bench[i]?.eligiblePositions?.indexOf(n) !== -1;
                  });
                  if(foundIntersect.length > 0){
                      validPlayers.push(players.bench[i])
                  }
              }
          }else{
              var validPositions = findEligiblePositionsNfl(player.defaultPos)
              for(var i=0; i<players.bench.length; i++){
                  if( validPositions.includes(players.bench[i].pos)){
                      validPlayers.push(players.bench[i])
                  }
              }
          }
          // WE SAVE INDEX FOR LATER USER // THIS WILL ALWAYS FROM STARTERS
          setSelectedPlayer(player)
          setSelectedPlayerIndex(index)
          setShowSwap(true)
          setSwapPlayers(validPlayers)
      }
  
      // CANCEL SELECTION MODE
      const cancelSwap = () => {
          setSelectedPlayer(null)
          setSelectedPlayerIndex(null)
          setSwapPlayers([])
          setShowSwap(false)
      }
  
    //   const persistStateOnBack = () => {
    //       updateStepsData("players", players)
    //       decrement()
    //   }

      const renderButtons = () => {
        if(!afterOnboard){
          return (
            <div className='d-flex justify-content-end align-items-center px-4 pb-2 py-3' style={{ background: Colors.black_90, borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}>
              {/* {step > 1 ? <p style={{ cursor: 'pointer' }} onClick={persistStateOnBack}>{arenaBackIcon()}</p> : <div></div>} */}
              <Button
                style={{
                  color: Colors.colorPrimary,
                  backgroundColor: Colors.colorPrimaryTransparent,
                  border: `1px solid ${Colors.colorPrimary}`,
                  padding: '6px 23px 6px 23px'
                }}
                onClick={() => onSubmit(normalizedBalance(userBalance?.balance) < 10 && !userBalance?.depositClaimed ? true : false)}
              >
                Next
              </Button>
            </div>
          )
        }
    }

  return (
    <LoadingOverlay
        active={loader || updateTeamsLoading}
        text='Processing...'
        fadeSpeed={0}
        spinner
        className='d-flex flex-column flex1 scrollHidden p-0'
    >
      <CardBody className='d-flex flex-column flex1 p-0' style={{overflow: "hidden", maxHeight: "650px"}} >
        {!fromRegister && <div className='p-4 text-dark' style={{backgroundColor: Colors.colorPrimary, borderTopRightRadius:15, borderTopLeftRadius:15}}>
          <h4 className='alex' style={{ fontWeight: 600 }}>Confirm Lineup</h4>
          <p className='bold m-0'>Strategize your lineup as you see fit. Once confirmed, you’re battle-ready for this week's matches.</p>
          {error && <ErrorText msg={error} />}
        </div>}

        { sport != "NFL" 
        &&
        <div className='d-flex justify-content-between px-3 py-2 align-items-center text-dark' style={{backgroundColor: Colors.colorPrimary900}}>
            <p className='m-0 text-white' style={{ fontWeight: 500 }}>Scoring Period</p>
            <h4 className='m-0 text-white' style={{ fontSize: 16 }}>{parseScoringPeriod(selectedTimeframe.start, selectedTimeframe.end)}</h4>
        </div>
        }
        <div className='d-flex flex-column flex1 scrollHidden'>
            {
                sport != "NFL"
                &&
                <div className='d-flex justify-content-between text-center pt-2'>
                    <h5 className='flex1 m-0 py-1 pb-2 pointer' style={{color: activeTab == "lineup" ? Colors.colorPrimary : '', borderBottom: `2px solid ${activeTab == "lineup" ? Colors.colorPrimary : '#00000000'}`, fontFamily: "Barlow", fontWeight: 16 }} onClick={() => setActiveTab('lineup')}>Set Lineup</h5>
                    <h5 className='flex1 m-0 py-1 pb-2 pointer' style={{color: activeTab == "schedule" ? Colors.colorPrimary : '', borderBottom: `2px solid ${activeTab == "schedule" ? Colors.colorPrimary : '#00000000'}`, fontFamily: "Barlow", fontWeight: 500 }} onClick={() => setActiveTab('schedule')}>Schedule</h5>
                </div>

            }
            {
                activeTab == "lineup" ?
                <div style={{ overflowY: "auto", background: Colors.arenaLineupPlayerRow }}>
                    {/* {(sport == "NFL") &&
                        <Row>
                            <Cell className="bold" style={{backgroundColor: Colors.bgLight}} >POS</Cell>
                            <Cell className="bold" style={{backgroundColor: Colors.bgLight}} >PLAYERS</Cell>
                            <Cell className="bold" style={{backgroundColor: Colors.bgLight}} >GAMES</Cell> 
                        </Row>
                    } */}
                    {players.starters.map((player, index) => (
                        <ArenaPlayerRow
                            togglePlayerCard={() => setPlayerCardModal(!playerCardModal)}
                            setTappedPlayerId={(playerId) => setTappedPlayerId(playerId)}
                            selectedPlayer={selectedPlayer} index={index}
                            callBack={displaySwapablePlayers}
                            sport={sport}
                            key={player.playerId} player={player}
                        />  
                    ))}
                </div>
          :
          <StickyTable borderColor={Colors.bgLight} style={{borderRadius: "5px"}} >
            <Row>
                <Cell className="bold bg400 border-0" ></Cell>
                {
                    Object.values(players.scheduleHeader).map((header, index) => {
                        return <Cell className="bold bg400 border-0 text-center" key={index}>{moment(header.date).tz(defaultTimezone()).format("ddd MMM D")}</Cell>
                    })
                }
            </Row> 
            {players.starters.map((player, index) => (

            <ArenaPlayerSchedule 
                key={player.playerId} 
                player={player}
                headers={players.scheduleHeader}
                cellColor={Colors.arenaLineupPlayerRow}
				cellBorder={Colors.arenaLineupPlayerRow}
                sport={sport}
            />
              
            ))}
          </StickyTable>
        }
        </div>
        {afterOnboard && <AfterOnboardFooter onSubmit={onSubmit} closePlayerModal={closePlayerModal}  />}
        {renderButtons()}
        {/* {playerCardModal && <ArenaPlayerCardModal playerId={tappedPlayerId} visible={playerCardModal} toggle={() => setPlayerCardModal(!playerCardModal)}  />} */}
        {/* {errorModal && <ErrorModal modalVisible={errorModal} closeModal={() => setErrorModal(!errorModal)} msg={error}  />} */}
        {arenaSuccessModal && <EnterArenaModal isOpen={arenaSuccessModal} closeModal={() => arenaSuccessModal(!arenaSuccessModal)} />}
        {/* {confirmPlayerModal && <StarterModal isOpen={confirmPlayerModal} toggle={() => setConfirmPlayerModal(!confirmPlayerModal)}  />} */}
        {showSwap && <SwapPlayersModal selectPlayer={selectPlayer} selectedPlayer={selectedPlayer} modal={showSwap} toggle={cancelSwap} swapPlayers={swapPlayers} sport={sport} />}
        {depositFlow && <DepositFlowModal isOpen={depositFlow} toggle={toggleDepositFlow} userBalance={userBalance} onSubmit={onSubmit} />}
      </CardBody>
      </LoadingOverlay>
  )
}

export default LineupStarters;