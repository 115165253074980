import React, { useState } from 'react';
import { CardBody, Button, Table, Alert } from 'reactstrap';
import { arenaIcon, arenaBackIcon } from '../../../helpers/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {StickyTable, Row, Cell} from 'react-sticky-table';
import EnterArenaModal from '../../../modals/Arena/EnterArenaModal';
import StarterModal from '../../../modals/Arena/StarterModal';
import ArenaPlayerRow from './ArenaPlayerRow';
import store from '../../../store';
import SwapPlayersModal from '../../../modals/Arena/SwapPlayersModal';
import ErrorText from '../../ErrorText';
import { registerUserInArena } from '../../../apis/Arena/MatchMakerApis';
import LoadingOverlay from 'react-loading-overlay';
import AfterOnboardFooter from '../AfterOnboardFooter';
import { useColors } from '../../../helpers/CustomHooks/useColors';
import moment from 'moment-timezone';
import ArenaPlayerSchedule from './ArenaPlayerSchedule';
import { useSelector } from 'react-redux';
import { findEligiblePositionsNfl } from '../../../helpers/Arena/ArenaHelper';

function Starters(props) {

    const Colors = useColors();

  const [loader, setLoader] = useState(false)
  const [error, setError] = useState()
  const [swapPlayers, setSwapPlayers] = useState([])
  const [showSwap, setShowSwap] = useState(false)
  const [selectedPlayer, setSelectedPlayer] = useState(null)
  const [selectedPlayerIndex, setSelectedPlayerIndex] = useState(null)
  const [errorModal, setErrorModal] = useState(false)
  const [confirmPlayerModal, setConfirmPlayerModal] = useState(true)
  const [arenaSuccessModal, setArenaSuccessModal] = useState(false)
  const [playerCardModal, setPlayerCardModal] = useState(false)
  const [tappedPlayerId, setTappedPlayerId] = useState()

  const [activeTab, setActiveTab] = useState('lineup') // lineup, schedule

  // CHECK IF USER IS ONBOARDING
  const {afterOnboard, successCallback, fromReenter, fromRegister, registerSuccessCallBack} = props
  const {arenaData, teamId, step, decrement, updateStepsData, stepsData, afterOnboardData, closePlayerModal} = props

  var {sport} = props
  if(sport == undefined){
    sport = useSelector(state => state.arenaReducer.sport)
  }


  const getPlayers = () => {
    if(afterOnboard){
        return afterOnboardData.teamData.players
      }else{
          var players = []
          arenaData.map((item) => {
              if(item.teamId == teamId){
                  players = item.players
              }
          })
          return players
      }   
  }
  const [players, setPlayers] = useState(stepsData?.players ? stepsData?.players : getPlayers())

      // THIS FUNCTION RUNS WHEN USER ONBOARD
      const onBoardUser = () => {
        setLoader(true)
        // CALL API AND SAVE DATA
        var dataCopy = JSON.parse(JSON.stringify(stepsData))
        delete dataCopy["terms"]
        // dataCopy["maxAmount"] = dataCopy["maxBet"]["maxAmount"]
        // dataCopy["maxExposure"] = dataCopy["maxExposure"]["amount"]
        // delete dataCopy["maxBet"]
        dataCopy["players"] = players.starters
        registerUserInArena(dataCopy).then((resp) => {
            // REDIRECT USER TO SUCCESS SCREEN
            setLoader(false)
            if(fromRegister){
                registerSuccessCallBack()
            }else{
                setArenaSuccessModal(true)
            }
        }).catch((err) => {
            setError(err.message)
            setErrorModal(true)
            setLoader(false)
        })
    }

    const onSubmit = () => {
      var validated = true
      players.starters.forEach((val) => {
          if(val.playerId == null){
              validated = false
          }
      })
      if(!validated){
          setError("Seems like you have some empty spots.")
          setErrorModal(true)
      }else{
          if(afterOnboard){
              if(fromReenter){
                  // FULL PLAYERS LIST
                  successCallback(players)
              }else{
                  successCallback(players.starters)
              }
          }else{
              if(!fromRegister){
                updateStepsData("players", players)
              }
              // ONBOARD USER
              onBoardUser()
          }
      }
  }

  const findPlayerIndex = (playerId, list) => {
      var index = null
      for(var i=0; i<list.length; i++){
          if(list[i].playerId == playerId){
              index = i
              break;
          }
      }
      return index
  }

      // PLAYER COMING FROM BENCH
      const selectPlayer = (benchPlayer, index) => {
        var playersCopy = JSON.parse(JSON.stringify(players))
        var starterCopy = JSON.parse(JSON.stringify(players.starters[selectedPlayerIndex]))
        playersCopy.starters[selectedPlayerIndex]["pos"] = benchPlayer["pos"]
        playersCopy.starters[selectedPlayerIndex]["playerId"] = benchPlayer["playerId"]
        playersCopy.starters[selectedPlayerIndex]["fullName"] = benchPlayer["fullName"]
        if(sport == "NBA" || sport == "MLB"){
            playersCopy.starters[selectedPlayerIndex]["eligiblePositions"] = benchPlayer["eligiblePositions"]
        }
        playersCopy.starters[selectedPlayerIndex]["team"] = benchPlayer["team"]
        playersCopy.starters[selectedPlayerIndex]["image"] = benchPlayer["image"]
        playersCopy.starters[selectedPlayerIndex]["opps"] = benchPlayer["opps"]
        // MOVE STARTER TO BENCH
        var benchIndex = findPlayerIndex(benchPlayer.playerId, playersCopy.bench)
        playersCopy.bench[benchIndex]["pos"] = starterCopy["pos"]
        playersCopy.bench[benchIndex]["playerId"] = starterCopy["playerId"]
        playersCopy.bench[benchIndex]["fullName"] = starterCopy["fullName"]
        if(sport == "NBA" || sport == "MLB"){
            playersCopy.bench[benchIndex]["eligiblePositions"] = starterCopy["eligiblePositions"]
        }
        playersCopy.bench[benchIndex]["team"] = starterCopy["team"]
        playersCopy.bench[benchIndex]["image"] = starterCopy["image"]
        playersCopy.bench[benchIndex]["opps"] = starterCopy["opps"]
        setPlayers(playersCopy)
        setSwapPlayers([])
        setSelectedPlayer(null)
        setSelectedPlayerIndex(null)
        setShowSwap(false)
    }

        // THIS WILL FIND ELIGIBLE POSITIONS FOR A GIVEN PLAYER FOR EXAMPLE 1B CAN BE GO TO 1B/UTIL
        const findEligiblePositions = (nativePos) => {
          // RULES
          // SP/RP CAN GO TO P
          // C -> C/UTIL
          // 2B -> 2B/UTIL
          // 3B -> 3B/UTIL
          // SS -> SS/UTIL
          // LF/CF/RF -> OF/UTIL
          // ANY NON P -> UTIL
          if(nativePos == "C"){
              return ["C", "UTIL"]
          }else if(nativePos == "1B"){
              return ["1B", "UTIL"]
          }else if(nativePos == "2B"){
              return ["2B", "UTIL"]
          }else if(nativePos == "3B"){
              return ["3B", "UTIL"]
          }else if(nativePos == "SS"){
              return ["SS", "UTIL"]
          }else if(nativePos == "P"){
              return ["P", "RP", "SP"]
          }else if(nativePos == "OF"){
              return ["LF", "RF", "CF", "OF"]
          }else if(nativePos == "UTIL"){
              return ["LF", "RF", "CF", "OF", "C", "1B", "2B", "3B"]
          }
          return []
      }

      const findEligiblePositionsNba = (nativePos) => {
        if(nativePos == "PG"){
            return ["PG"];
        }else if(nativePos == "SG"){
            return ["SG"];
        }else if(nativePos == "SF"){
            return ["SF"];
        }else if(nativePos == "PF"){
            return ["PF"];
        }else if(nativePos == "C"){
            return ["C"];
        }else if(nativePos == "G"){
            return ["G", "PG", "SG"];
        }else if(nativePos == "F"){
            return ["F", "SF", "PF"];
        }else if(nativePos == "UTIL"){
            return ["PG", "SG", "SF", "PF", "C", "G", "F"];
        }
        return []
    }


    
        // THIS WILL FILTER OUT ELIGIBLE PLAYERS FOR SWAPPING
        const displaySwapablePlayers = (player, index) => {
          var validPlayers = []
            if(sport == "NBA" || sport == "MLB"){
            if(sport == "MLB"){
                var validPositions = findEligiblePositions(player.defaultPos)
            }else if(sport == "NBA"){
                var validPositions = findEligiblePositionsNba(player.defaultPos)
            }
            console.log("VALID POSITIONS", players);
              var foundIntersect = []
              for(var i=0; i<players.bench.length; i++){
                  foundIntersect = []
                  foundIntersect = validPositions.filter(function(n) {
                      return players.bench[i]?.eligiblePositions?.indexOf(n) !== -1;
                  });
                  if(foundIntersect.length > 0){
                      validPlayers.push(players.bench[i])
                  }
              }
          }else{
              var validPositions = findEligiblePositionsNfl(player.defaultPos)
              for(var i=0; i<players.bench.length; i++){
                  if( validPositions.includes(players.bench[i].pos)){
                      validPlayers.push(players.bench[i])
                  }
              }
          }
          // WE SAVE INDEX FOR LATER USER // THIS WILL ALWAYS FROM STARTERS
          setSelectedPlayer(player)
          setSelectedPlayerIndex(index)
          setShowSwap(true)
          setSwapPlayers(validPlayers)
      }
  
      // CANCEL SELECTION MODE
      const cancelSwap = () => {
          setSelectedPlayer(null)
          setSelectedPlayerIndex(null)
          setSwapPlayers([])
          setShowSwap(false)
      }
  
      const persistStateOnBack = () => {
          updateStepsData("players", players)
          decrement()
      }

      const renderButtons = () => {
        if(!afterOnboard){
          return (
            <div className='w-100 px-1 mt-4'>
              {step > 1 ? <p style={{ cursor: 'pointer' }} onClick={persistStateOnBack}>{arenaBackIcon()}</p> : <div></div>}
              <Button
                className='mb-2 w-100 btn-pill green-btn bold'
                style={{
                  height: "56px"
                }}
                onClick={onSubmit}
              >
                SUBMIT
              </Button>
            </div>
          )
        }
    }

  return (
    <LoadingOverlay
        active={loader}
        text='Processing...'
        fadeSpeed={0}
        spinner
        className='d-flex flex-column flex1 scrollHidden'
    >
    <CardBody className='d-flex flex-column flex1 p-0' style={{overflow: "hidden", borderRadius: "20px"}} >
        {!fromRegister && <div className='p-4 text-dark' style={{backgroundColor: Colors.colorPrimary, borderTopRightRadius:15, borderTopLeftRadius:15}}>
          <h4 className='bold'>Confirm Lineup</h4>
          <p className='bold m-0'>Strategize your lineup as you see fit. Once confirmed, you’re battle-ready for this week's matches.</p>
          {error && <ErrorText msg={error} />}
        </div>}

        {/* { sport != "NFL" 
        &&
        <div className='d-flex justify-content-between px-3 py-2 align-items-center text-dark' style={{backgroundColor: Colors.colorWarning}}>
            <p className='m-0'>Scoring Period</p>
            <h4 className='m-0'>{`${moment(selectedTimeframe.teamTimeFrame[teamId]?.start).format("MMM D")} - ${moment(selectedTimeframe.teamTimeFrame[teamId]?.end).format("MMM D")}`}</h4>
        </div>
        } */}
        <div className='d-flex flex-column scrollHidden'>
            {
                sport != "NFL"
                &&
                <div className='d-flex justify-content-between align-items-center' style={{backgroundColor: Colors.bgLight}}>
                    <h5 className='flex1 m-0 pointer justify-content-center d-flex align-items-center' style={{ height: "48px", color: activeTab == "lineup" ? Colors.colorPrimary : '', borderBottom: `2px solid ${activeTab == "lineup" ? Colors.colorPrimary : '#00000000'}` }} onClick={() => setActiveTab('lineup')}>Set Lineup</h5>
                    <h5 className='flex1 m-0 pointer justify-content-center d-flex align-items-center' style={{height: "48px", color: activeTab == "schedule" ? Colors.colorPrimary : '', borderBottom: `2px solid ${activeTab == "schedule" ? Colors.colorPrimary : '#00000000'}` }} onClick={() => setActiveTab('schedule')}>Schedule</h5>
                </div>

            }
            {
                activeTab == "lineup" ?
                <StickyTable borderColor={Colors.bgLight} style={{borderRadius: "5px", background: Colors.bgDark}} >
                    <Row></Row>
                    {players.starters.map((player, index) => (
                    <ArenaPlayerRow
                        togglePlayerCard={() => setPlayerCardModal(!playerCardModal)}
                        setTappedPlayerId={(playerId) => setTappedPlayerId(playerId)}
                        selectedPlayer={selectedPlayer} index={index}
                        callBack={displaySwapablePlayers}
                        sport={sport}
                        key={player.playerId} player={player}
                    />
                    
                    ))}
                </StickyTable>
                :
                <StickyTable borderColor={Colors.bgLight} style={{borderRadius: "5px"}} >
                    <Row>
                        <Cell className="bold bg400 border-0" ></Cell>
                        {
                            Object.values(players.scheduleHeader).map((header, index) => {
                                return <Cell className="bold bg400 border-0" key={index}>{moment(header.date).format("ddd MMM D")}</Cell>
                            })
                        }
                    </Row> 
                    {players.starters.map((player, index) => (
                        <ArenaPlayerSchedule 
                            key={player.playerId} 
                            player={player}
                            headers={players.scheduleHeader}
                            teamView={"my"}
                            sport={sport}
                        />
                    ))}
                </StickyTable>
        }
        </div>
        {afterOnboard && <AfterOnboardFooter onSubmit={onSubmit} closePlayerModal={closePlayerModal}  />}
        {renderButtons()}
        {/* {playerCardModal && <ArenaPlayerCardModal playerId={tappedPlayerId} visible={playerCardModal} toggle={() => setPlayerCardModal(!playerCardModal)}  />} */}
        {/* {errorModal && <ErrorModal modalVisible={errorModal} closeModal={() => setErrorModal(!errorModal)} msg={error}  />} */}
        {arenaSuccessModal && <EnterArenaModal isOpen={arenaSuccessModal} closeModal={() => arenaSuccessModal(!arenaSuccessModal)} />}
        {/* {confirmPlayerModal && <StarterModal isOpen={confirmPlayerModal} toggle={() => setConfirmPlayerModal(!confirmPlayerModal)}  />} */}
        {showSwap && <SwapPlayersModal selectPlayer={selectPlayer} selectedPlayer={selectedPlayer} modal={showSwap} toggle={cancelSwap} swapPlayers={swapPlayers} sport={sport} />}
        
      </CardBody>
      </LoadingOverlay>
  )
}

export default Starters;