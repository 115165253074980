import React, { useState } from 'react';
import { Button } from 'reactstrap';
import bettleBee from '../../assets/images/bettleBee.png';
import check from '../../assets/images/check.png';
import espn from '../../assets/images/nfl/espn.svg';
import yahoo from '../../assets/images/nfl/yahoo.png';
import { STYLES } from '../../helpers/stylesHelper';
import androidLogo from '../../assets/images/android.png';
import iosLogo from '../../assets/images/ios.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BASE_FE_URL, BASE_URL } from '../../config/app';
import { errorMessage, getStore } from '../../helpers/common-methods';
import { SAVE_APPLICATION_STATUS, updateActiveLeagueId } from '../../apis/Sidebar/LeagueAPI';
import store from '../../store';
import { updateActiveExperience } from '../../apis/User/UserAPI';

const ImportSuccess = (props) => {
	const { bettleLeagueId, sport } = props;
	const [ copyText, setCopyText ] = useState('Copy Link');
	const [ loading, setLoading ] = useState(false);
	const [ arenaLoading, setArenaLoading ] = useState(false);

	const getCopyText = () => {
		var url = encodeURI(BASE_FE_URL + '/sl/lg/invite/' + props.lg.key + '/' + props.platform + '/' + props.lg.name);
		return "You've been invited to join " + props.lg.name + ' on Bettle. Sign up and claim your team here: ' + url;
	};

	const handleUpdateActiveLeagueId = () => {
		setLoading(true);
		store
			.dispatch(updateActiveLeagueId(bettleLeagueId))
			.then((response) => {
				window.location.href = '/dashboard';
			})
			.catch((error) => {
				setLoading(false);
				errorMessage("Can't open league home, please try again~");
			});
	};

	const switchApp = () => {
		var destination =

				sport == 'NFL' ? 'FOOTBALL_ARENA' :
				'BASEBALL_ARENA';
		setArenaLoading(true);
		updateActiveExperience({
			activeExp: destination
		})
			.then(() => {
				store.dispatch({ type: SAVE_APPLICATION_STATUS, app_status: destination });
				window.location.href = '/arena/home';
				// setArenaLoading(false)
			})
			.catch(() => {
				errorMessage('Something went wrong, please try again later!');
				setArenaLoading(false);
			});
	};

	return (
		<div className="d-flex flex-column flex1 align-items-center">
			{/* <div className="mb-2" >
                <img src={bettleBee} className="img-fluid" />
            </div> */}

			<h1 className="mt-2 mb-4 text-center" style={{ fontFamily: 'Fritch' }}>
				Success!
			</h1>
			{/* <h5>League Successfully Imported!</h5> */}
			{/* <div className="mt-3 mb-3" >
                <img src={check} style={{height: "80px"}} className="img-fluid" />
            </div> */}
			<p className="lead mb-0">
				You have successfully imported <b className="greenText">{props.lg.name}</b> and claimed the team below:
			</p>

			<div>
				<div className="d-flex align-items-center my-3">
					<div>
						<img
							style={{ border: '3px solid #03F29E' }}
							className="roundImgMed mr-3"
							src={

									props.platform == 'espn' ? espn :
									yahoo
							}
						/>
					</div>
					<p className="bold mb-0 lead">{props.lg.team_name}</p>
				</div>

				{/* <h2 className="greenText mb-4" style={{fontFamily: "Fritch"}} >Time to Get Started!</h2> */}
				{/* <p className="mt-4" >The MatchMaker arena will be open next week, but there is plenty for you to do!</p> */}
				{/* <p>The MatchMaker arena is open! Get your team in there ahead of this week's action.</p> */}
				{/* <ul style={{listStyle: "none"}} > */}
				<div className="mt-4">
					<p className="mb-2 lead d-md-none d-block">
						You are ready to go! To enter the arena on your mobile device, you can download our apps here:
					</p>
					<p className="mb-2 lead d-none d-md-block">
						The MatchMaker arena is open! Set your lineup. Make a Match. <span className="greenText lead">Win cash.</span>
					</p>
					<div className="text-center mt-4 d-none d-md-block">
						<Button disabled={arenaLoading} onClick={switchApp} style={STYLES.BUTTON_PRIMARY_TRANSPARENT}>
							{
								arenaLoading ? 'Processing...' :
								'Enter the Arena'}
						</Button>
					</div>
                    <div className='d-flex align-items-center d-block d-md-none' >
                        <div style={{width: "40%"}}>
                        <a target={"_blank"} href='https://apps.apple.com/us/app/bettle-fantasy-sports/id1591274931' ><img src={iosLogo} className="img-fluid" /></a>
                        </div>
                        <div style={{width: "46%"}} >
                        <a target={"_blank"} href='https://play.google.com/store/apps/details?id=com.bettle.bettlelive&hl=en_US&gl=US' ><img src={androidLogo} className="img-fluid" /></a>
                        </div>
                    </div>
                    <p className="mb-2 lead d-block d-md-none">
						or hop on your computer and play at <span className='greenText lead' >bettle.co</span>
					</p>
				</div>
				{/* <li className="mt-2" >
                        <p className="lead" style={{fontFamily: "Fritch"}} >Download App and Enter Arena</p>
                        <div className='d-flex align-items-center' >
                            <div style={{width: "25%"}}>
                            <a target={"_blank"} href='https://apps.apple.com/us/app/bettle-fantasy-sports/id1591274931' ><img src={iosLogo} className="img-fluid" /></a>
                            </div>
                            <div style={{width: "31%"}} >
                            <a target={"_blank"} href='https://play.google.com/store/apps/details?id=com.bettle.bettlelive&hl=en_US&gl=US' ><img src={androidLogo} className="img-fluid" /></a>
                            </div>
                        </div>
                    </li> */}
				{/* <li><p className="lead my-3" >or:</p></li> */}
				{/* <li>
                        <p className="lead mt-4" style={{fontFamily: "Fritch"}} >Invite your league</p>
                        <p className="mb-2" >Copy this link and send to your league mates via text, email, etc.</p>
                        <CopyToClipboard text={getCopyText()}
                            onCopy={() => {setCopyText("Copied!")}}>
                            <Button style={STYLES.BUTTON_PRIMARY_TRANSPARENT} ><i class="fa fa-clone" aria-hidden="true"></i>  { copyText }</Button>
                        </CopyToClipboard>
                    </li> */}
				{/* <li className="mt-2" >
                        <p>Download our mobile app for the optimal Bettle experience</p>
                        <div className='d-flex align-items-center' >
                            <div style={{width: "25%"}}>
                            <a target={"_blank"} href='https://apps.apple.com/us/app/bettle-fantasy-sports/id1591274931' ><img src={iosLogo} className="img-fluid" /></a>
                            </div>
                            <div style={{width: "31%"}} >
                            <a target={"_blank"} href='https://play.google.com/store/apps/details?id=com.bettle.bettlelive&hl=en_US&gl=US' ><img src={androidLogo} className="img-fluid" /></a>
                            </div>
                        </div>
                    </li> */}
				{/* <li><p className="lead my-1" >or:</p></li> */}
				<div className='d-none d-md-block' style={{ marginTop: '4rem' }}>
					<p className="mb-0 text-center">
						Take me to {props.lg.name}{' '}
						<span disabled={loading} onClick={handleUpdateActiveLeagueId} className="greenText pointer">
							{
								loading ? 'Processing...' :
								'League Home'}
						</span>
					</p>
					{/* <Button className="greenText" color="link" disabled={loading} onClick={handleUpdateActiveLeagueId} ><u className="lead" >{loading ? "Processing..." : "Take me to League Home"}</u></Button> */}
				</div>
				{/* </ul> */}
			</div>
		</div>
	);
};

export default ImportSuccess;
