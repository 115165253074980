import { checkTokenExists, setToken, setUserBasicInfo, setIntendedUrl, getIdToken, setIdToken } from '../helpers/auth';
import { isExpired, decodeToken } from "react-jwt";
import { fetchGeoLocation } from '../helpers/Location/LocationHelper';
import { fetchUserMeta } from '../apis/User/UserAPI';

export const SET_USER_DATA = 'SET_USER_DATA';
// export const SET_USER_BASIC = 'SET_USER_BASIC';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
//const BASE_URL = "http://localhost/dream-team/public";

const fetchUser = () => {
  return window.axios.get('/api/get/user/detail')
    .then(({ data: { data } }) => {
      return Promise.resolve(data)
    })
    .catch(error => Promise.reject(error));
};

export const fetchUserData = () => {
  return window.axios.get('/api/get/user/detail')
    .then(({ data: { data } }) => {
      return Promise.resolve(data)
    })
    .catch(error => Promise.reject(error));
};

export const setUserData = user => ({
  type: SET_USER_DATA,
  user
});

// export const setUserBasic = input => ({
//   type: SET_USER_BASIC,
//   input
// });

export const setAuthenticated = authenticated => ({
  type: SET_AUTHENTICATED,
  authenticated
});

export const signInUser = credentials => dispatch => {
  return window.axios.post('/api/user/login', { data: { username: credentials.username, password: credentials.password } }).then(({ data: { data, meta } }) => {
    setToken(meta.token);
    dispatch(setUserData(data));
    dispatch(setAuthenticated(true));
    return Promise.resolve({ data, meta });
  }).catch(error => {
    if (error.response.data.data != undefined) {
      // put basic info in local forage
      setUserBasicInfo(error.response.data.data)
    }
    return Promise.reject(error);
  });
};

export const googleSignIn = (tokenId, ref_id) => dispatch => {
  var data = {}
  if(ref_id){
    data = { tokenId: tokenId, ref_id: ref_id }
  }else{
    data = { tokenId: tokenId }
  }
  return window.axios.post('/api/googleAuth',data).then(({ data: { data, meta } }) => {
    setToken(meta.token);
    dispatch(setUserData(data));
    dispatch(setAuthenticated(true));
    return Promise.resolve({ data, meta });
  }).catch(error => {
    return Promise.reject(error);
  });
};

/**
 * Register step 1
 */
export const registerUser = credentials => dispatch => {
  return window.axios.post('/api/user/register', { data: { name: credentials.name, zip: credentials.zip, email: credentials.email, password: credentials.password, password_confirmation: credentials.password_confirmation } }
  ).then((response) => {
    // dispatch(setUserBasic());
    setUserBasicInfo(response.data.data)
    return Promise.resolve();
  }).catch(error => {
    return Promise.reject(error);
  });
};

/**
 * Register step 2
 */
export const personalInfo = requestData => dispatch => {
  return window.axios.post('/api/user/personalInfo', { data: { 
        code: requestData.basic_info.code,
        first_name: requestData.first_name,
        zip: requestData.zip,
        last_name: requestData.last_name,
        dob: requestData.dob,
        ref_id: requestData.ref_id
       }
      }
  ).then(({ data: { data, meta } }) => {
    setToken(meta.token);
    dispatch(setUserData(data));
    dispatch(setAuthenticated(true));
    return Promise.resolve({ data, meta });
  }).catch(error => {
    return Promise.reject(error);
  });
};

// COGNITO REGISTER

export const cognitoRegister = data => dispatch => {
  return window.axios.post('/bettleSignUp', data)
  .then((response) => {
    return Promise.resolve(response.data);
  }).catch(error => {
    return Promise.reject(error.response.data);
  });
};

export const cognitoConfirmRegister = data => dispatch => {
  return window.axios.post('/confirmSignUp', data)
  .then((response) => {
    console.log(response)
    return Promise.resolve(response);
  }).catch(error => {
    return Promise.reject(error.response.data);
  });
};

export const cognitoLogin = data => dispatch => {
  return window.axios.post('/bettleLogin', data)
  .then((response) => {
    var resp = response.data
    setToken(resp.AccessToken)
    document.cookie = 'bat='+resp.AccessToken+';path=/;max-age=3600;domain=bettle.co'
    setIdToken(resp.IdToken)
    return Promise.resolve(response.data)
  }).catch(error => {
    return Promise.reject(error.response.data)
  });
};


export const clearAuth = () => dispatch => {
  document.cookie = 'bat=; path=/; Max-Age=0; domain=bettle.co';
  setToken(null);
  setIdToken(null);
  dispatch(setUserData(null));
  dispatch(setAuthenticated(false));
};

export const logoutUser = cb => dispatch => {
  return window.axios.post('/api/logout')
    .then(response => {
      window.pusher.disconnect();
      dispatch(clearAuth());
      cb();
    })
    .catch(anyError => {
      dispatch(clearAuth());
      cb();
    });
};

export const initAuthFromExistingToken = (cb) => dispatch => {
  return checkTokenExists().then( async (accessToken) => {
    setToken(accessToken)
    const tokenExpired = isExpired(accessToken);
    const idToken  = await getIdToken()
    if(idToken){
      const decodedToken = decodeToken(idToken);
      if(!tokenExpired){
        // API
        var userMeta = await fetchUserMeta()
        // var userMeta = {}
        // FETCH GEO LOCATION
        fetchGeoLocation()
        // DATA MERGE 
        dispatch(setUserData({
          id: decodedToken["custom:uuid"] != undefined ? decodedToken["custom:uuid"] : decodedToken["sub"],
          username: decodedToken.preferred_username,
          email: decodedToken.email,
          ...userMeta
        }));
        dispatch(setAuthenticated(true));
        cb(true);
      }else{
        dispatch(clearAuth());
        cb(false);
      }
    }else{
      dispatch(clearAuth());
      cb(false);
    }

  }).catch(anyError => {
    dispatch(clearAuth());
    cb(false);
  });
};

export const updateUserData = (data) => dispatch => {
  dispatch(setUserData(data))
}
