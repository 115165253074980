import React from 'react';
import { Button, Modal } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import WelcomeImage from "../assets/images/nfl/win_old.png"
import {removeInForage } from '../helpers/common-methods';
import { STYLES } from '../helpers/stylesHelper';

class WelcomeModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: this.props.modal,
            user: this.props.auth.user
        };
    }

    async toggle() {
        await removeInForage("newUser")
        this.setState(({modal: false}))
    }

    render() {
        return (
            <div >
                <Modal isOpen={this.state.modal} toggle={() => this.toggle()}>
                   <div className="text-center p-4" >
                   <i onClick={() => this.toggle()} class="cui-circle-x icons pointer modalClose"></i>
                        <h3 className="bold" >Welcome, {this.state.user.username}!</h3>
                        <div>
                            <img src={WelcomeImage} className="img-fluid"   />
                        </div>
                        <p className='lead' >It's time to get more out of your fantasy team. Import your league to get started.</p>
                        <Button style={STYLES.BUTTON_PRIMARY_TRANSPARENT} onClick={() => this.props.toggleImportLeague()} >Import League</Button>
                   </div>
                </Modal>
            </div>
        );
    }
}
const mapStateToProps = ({ auth }) => ({ auth });
const mapDispatchToProps = {
    
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WelcomeModal));