import React, { useEffect, useState } from "react"
import { Button, Collapse } from "reactstrap";
// import { mmRejectMatch } from "../../apis/Arena/MatchMakerApis";
// import { LoaderCenter } from "../../components/Loader";
// // import { Colors } from "../../helpers/colors";
// import { errorMessage, successMessage } from "../../helpers/common-methods";
// import { STYLES } from "../../helpers/stylesHelper";
import { mmRejectMatch } from "../../../apis/Arena/MatchMakerApis";
import { LoaderCenter } from "../../../components/Loader";
import { Colors } from "../../../helpers/colors";
import { errorMessage, successMessage } from "../../../helpers/common-methods";
import { backArrowIcon } from "../../../helpers/icons";
import { STYLES } from "../../../helpers/stylesHelper";
import { useColors } from "../../../helpers/CustomHooks/useColors";

const RejectAction = (props) => {
    const Colors = useColors();
    const {isOpen, toggle, selectedLineup, successCallback} = props
    const [loading, setLoading] = useState(false)
    const [view, setView] = useState("confirm")

    useEffect(() => {
    }, [])

    const callApi = () => {
        setView("loading")
        mmRejectMatch({
            matchId: selectedLineup.proposedData.matchId
        }).then(() => {
            toggle(false)
            successMessage("Successfully rejected")
            if(successCallback) {
                successCallback()
            }
        }).catch((e) => {
            errorMessage(e.message)
        })
    }

    const handleCancel = () => {
        toggle(true)
    }

    const getView = () => {
        if(view == "loading"){
            return <LoaderCenter msg={"Rejecting Matchup..."} />
        }else if(view == "confirm"){
            return (
                <div> 
                    <h2 className="mt-2">Are you sure?</h2>
                    <p>You are going to reject match proposal from <span className="bold">{selectedLineup.teamName}</span></p>
                    {/* <div className="d-flex align-items-center justify-content-center my-4" >
                        <img className="roundImgMed" src={selectedLineup.teamImage} />
                        <div style={{marginLeft: 10}} >
                            <p className="mb-0" >{selectedLineup.teamName}</p>
                        </div>
                    </div> */}
                    <div className="d-flex">
                        <Button className='d-flex align-items-center flex1 mr-1 justify-content-center bold' style={{color:Colors.colorDanger, backgroundColor: Colors.colorDangerTransparent, border: `1px solid ${Colors.rejectColor}`}} onClick={callApi}> 
                            Reject
                        </Button>

                        <Button className='d-flex align-items-center flex1 ml-1 justify-content-center bold' style={{color:Colors.colorDanger, backgroundColor: Colors.colorDangerTransparent, border: `1px solid ${Colors.rejectColor}`}} onClick={toggle}> 
                            Cancel
                        </Button>
                    </div>
                </div>
            )
        }
    }

    return (
            <div className='px-3 pb-3' style={{ color: Colors.colorLight }}>
                {getView()}
            </div>
    )
}

export default RejectAction