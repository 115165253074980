import { ARENA } from "../../helpers/arena_sports_mapping";
import { saveApplicationStatusAction } from "../Sidebar/LeagueAPI";

const ENDPOINTS = {
    ARENA_HOME: 'mm/arenaHome',
	LOAD_PLAYERS: 'mm/loadPlayers',
	MANAGERS: 'mm/managers',
	TEAM_ONBOARD_STATUS: 'mm/checkTeamStatus',
	SWITCH_TEAM: 'mm/switchTeam',
	MM_LIVE_DATA: "mm/liveData",
	DELETE_TEAM: "mm/deleteTeam",
	UPDATE_TEAMS: "mm/updateUserTeams",
	FETCH_TEAMS: "mm/fetchTeams",
	MM_META: "mm/matchMakerMeta",
	MATCHUP_PLAYERS: "/mm/matchupPlayers",
	MM_START_DATE_STATUS: "mm/startDateStatus",
	MM_WIN_PROBABILITY: "mm/winProbability"
}

export const SAVE_ALL_TEAMS = 'SAVE_ALL_TEAMS';
export const SET_ACTIVE_TEAM = 'SET_ACTIVE_TEAM';
export const SAVE_ARENA_SPORT = 'SAVE_ARENA_SPORT';

// ACTIONS

export const saveAllTeamsAction = teams => ({
    type: SAVE_ALL_TEAMS,
    all_teams: teams
})

export const setActiveTeamAction = team => ({
    type: SET_ACTIVE_TEAM,
    active_team: team
})

export const saveArenaSportAction = sport => ({
	type: SAVE_ARENA_SPORT,
	sport: sport
})

// APIs

export const checkArenaStatus = (qs) => {
	return window.axios
		.get('/mm/checkArenaStatus' + qs)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const registerUserInArena = (reqData) => {
	return window.axios
		.post('/mm/onBoardUser', reqData)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const updateTeams = (reqData) => {
	return window.axios
		.post('/mm/updateTeams', reqData)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const matchMaker = (qs, sortId) => {
	return window.axios
		.get('/mm/fetchLineups'+qs)
		.then((response) => {
			const teamIds = response.data?.data.map(i => i["teamId"]);
			window.axios.post('/mm/trackLineUpViews', {
				views: teamIds,
				"sort_id": sortId
			}).catch((err) => {
				//
			})	
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const loadPlayers = (qs) => {
	return window.axios
		.get('/mm/loadPlayers'+qs)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const refPreview = (qs) => {
	return window.axios
		.get('/mm/refPreview'+qs)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const mmNotifications = (qs) => {
	return window.axios
		.get('/mm/notifications'+qs)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const mmHeaderData = () => {
	return window.axios
		.get('/mm/headerData')
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const mmPreOnboardPlayers = (qs) => {
	return window.axios
		.get('/mm/preOnboardPlayers'+qs)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const mmTeamOnboarded = (qs) => {
	return window.axios
		.get('/mm/teamOnboarded'+qs)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const mmCounterMatch = (reqData) => {
	return window.axios
		.post('/mm/counterMatch', reqData)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error.response.data);
		});
};

export const mmAcceptMatch = (reqData) => {
	return window.axios
		.post('/mm/acceptMatch', reqData)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error.response.data);
		});
};

export const mmRejectMatch = (reqData) => {
	return window.axios
		.post('/mm/rejectMatch', reqData)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error.response.data);
		});
};



export const mmCreateMatch = (reqData) => {
	return window.axios
		.post('/mm/createMatch', reqData)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error.response.data);
		});
};

export const mmConfirmNewDayLineup = (reqData) => {
	return window.axios
		.post('/mm/confirmLineup', reqData)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error.response.data);
		});
};

export const mmSavePlayersInMatch = (reqData) => {
	return window.axios
		.post('/mm/savePlayersInMatch', reqData)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error.response.data);
		});
};

export const mmAcceptAndSavePlayers = (reqData) => {
	return window.axios
		.post('/mm/acceptAndSavePlayers', reqData)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error.response.data);
		});
};

export const teamChatMeta = (qs) => {
	return window.axios
		.get('/mm/teamMeta'+qs)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error.response.data);
		});
};

export const mmTeamComparison = (qs) => {
	return window.axios
		.get('/mm/teamComparison'+qs)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error.response.data);
		});
};


export const mmPlayerDetails = (qs) => {
	return window.axios
		.get('/mlb/matchMakerPlayerDetails'+qs)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error.response.data);
		});
};

export const hasChallengeMe = () => {
	return window.axios
		.get('/user/hasChallengeMe')
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error.response.data);
		});
};

export const hideChallengeMe = () => {
	return window.axios
		.post('/user/hideChallengeMe')
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error.response.data);
		});
};

export const mmNflPlayerDetails = (qs) => {
	return window.axios
		.get('/mm/nflPlayerDetails'+qs)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error.response.data);
		});
};

export const mmFetchChallengerLineup = (params) => {
	return window.axios
		.get('/mm/fetchSingleLineup', {params})
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error.response.data);
		});
}

export const mmAllNotifications = (params) => {
	return window.axios
		.get('/mm/allNotifications', {params})
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error.response.data);
		});
}

export const arenaHome = (params) => {
    return window.axios.get(ENDPOINTS.ARENA_HOME, { params })
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            if (error.response) {
                return Promise.reject(error.response.data);
            } else if (error.request) {
                errorMessage(error.request._response);
            } else {
                errorMessage(error.message);
            }
        })
}

export const managers = (params) => {
	return window.axios.get(ENDPOINTS.MANAGERS, { params })
		.then((response) => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		})
}

export const checkTeamStatus = (params) => {
	return window.axios.get(ENDPOINTS.TEAM_ONBOARD_STATUS, { params })
		.then((response) => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		})
}

export const mmLiveData = (qs) => {
	return window.axios.get(ENDPOINTS.MM_LIVE_DATA+qs)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch(error => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		})
}

export const switchTeam = (reqData) => {
	return window.axios.post(ENDPOINTS.SWITCH_TEAM, reqData)
		.then((response) => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		})
}

export const deleteTeam = (reqData) => {
	return window.axios.post(ENDPOINTS.DELETE_TEAM, reqData)
		.then((response) => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		})
};

export const updateUserTeams = (reqData) => {
	return window.axios.post(ENDPOINTS.UPDATE_TEAMS, reqData)
		.then((response) => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		})
};

export const fetchAllTeams = (params) => {
	return window.axios.get(ENDPOINTS.FETCH_TEAMS, { params })
		.then((response) => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		})
};

export const matchMakerMeta = (params) => {
	return window.axios.get(ENDPOINTS.MM_META, { params })
		.then((response) => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		})
}

export const matchupPlayers = (params) => {
	return window.axios.get(ENDPOINTS.MATCHUP_PLAYERS, { params })
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch(error => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		})
}

export const startDateStatus = (params) => {
	return window.axios.get(ENDPOINTS.MM_START_DATE_STATUS, {params})
		.then((response) => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		})
}

export const winProbability = (params) => {
	return window.axios.get(ENDPOINTS.MM_WIN_PROBABILITY, {params})
		.then((response) => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		})
}

// DISPATCHERS

export const  saveAllTeams = (teams) => dispatch => {
    dispatch(saveAllTeamsAction(teams));
}

export const setActiveTeam = (team) => dispatch => {
    dispatch(setActiveTeamAction(team));
	dispatch(saveApplicationStatusAction(ARENA.EXPERIENCE_FROM_SPORT[team.sport]));
}

export const saveArenaSport = (sport) => async dispatch => {
	await dispatch(saveArenaSportAction(sport));
	return Promise.resolve();
}