import moment from 'moment-timezone'
import React from 'react'
import { useCountdown } from '../../../helpers/CustomHooks/useCountdown'
import { arenaIcon, nbaArenaLineupIcon } from '../../../helpers/icons'
import FullWidthButton from '../../subcomponents/FullWidthButton'
import { motion } from 'framer-motion/dist/framer-motion'
import { shrinkVertical } from '../../../pages/NewUserOnboarding/animationValues'
import { ColorContext } from '../../../context/ColorProvider'
import store from '../../../store'

const LineupInfo = (props) => {
  const { user } = store.getState().auth;
  const { handleOnboarding, active_team, disableOnboarding, arenaHomeData, sport, sportEnabled } = props;
  // const [seconds, minutes, hours ] = useCountdown(moment(arenaHomeData?.weekStartTime))
  // Start Countdown till 27th of Feb
  const [seconds, minutes, hours, days ] = useCountdown(moment('2023-03-30T00:00:00.000Z'))
  const Colors = React.useContext(ColorContext);
  // console.log(hours)
  return (
    <div>
      {/* {arenaHomeData?.weekStartTime != null && <div className='mb-3 d-flex justify-content-center align-items-center' style={{backgroundColor:Colors.timerBg_v3, borderRadius:10, height:'64px', paddingLeft:'24px', paddingRight:'24px'}}>
            <h1 className='m-0 align-items-center d-flex justify-content-center heading-1'>
              <span className='mr-3'>WEEK {arenaHomeData?.week} STARTS IN </span>
              
              <span className='font-digital-numbers' style={{color:Colors.colorPrimary}}> 
              {hours < 10 ? "0"+hours : hours}
              :
              {minutes < 10 ? "0"+minutes : minutes}
              :
              {seconds < 10 ? "0"+seconds : seconds}
              </span>
            </h1>
        </div>} */}
        {
          active_team?.teamId == "all" &&
            <div className='d-flex justify-content-between align-items-center'>
              <h1><span style={{ fontSize: 20 }}>Welcome back, </span><br /><span style={{ color: Colors.colorPrimary, fontWeight: "bolder", fontSize: 40 }}>{user?.first_name}.</span></h1>
              {/* <div className='p-3 mb-4' style={{ background: Colors.colorPrimaryTransparent, borderRadius: 20 }}>
                <div className='d-flex align-items-start'>
                  <h6 className='d-flex justify-content-center align-items-center p-1' style={{ background: Colors.bgSecondary_v3, borderRadius: 100, width: 32, height: 32 }}>{nbaArenaLineupIcon(Colors.colorPrimary)}</h6> 
                  <h6 className='ml-2'>You can <span style={{ color: Colors.colorPrimary }}>edit your lineup up</span><br /> until the start of the first <br /> game of the week’s <br /> matchup</h6>
                </div>
              </div> */}
            </div>
        }
      {/* { sport == "MLB" ? (<div className='mb-3 d-flex justify-content-center align-items-center' style={{backgroundColor:Colors.timerBg_v3, borderRadius:10, height:'64px', paddingLeft:'24px', paddingRight:'24px'}}>
            <h1 className='m-0 align-items-center d-flex justify-content-center heading-1'>
              <span className='mr-3 font-digital-numbers'>LEVEL UP IN </span>
              
              {
                hours > 72 ?
                (<span className='font-digital-numbers' style={{color:Colors.colorPrimary}}>
                  {days} DAYS
                </span>)
                :
                (<span className='font-digital-numbers' style={{color:Colors.colorPrimary}}> 
                  {hours < 10 ? "0"+hours : hours}
                  :
                  {minutes < 10 ? "0"+minutes : minutes}
                  :
                  {seconds < 10 ? "0"+seconds : seconds}
                </span>)
            }
            </h1>
        </div>) : (
          active_team.teamId != 'all' && sportEnabled && <FullWidthButton className='bold font-lg' icon={arenaIcon()} text={`ENTER ${sport} ARENA`} style={{color:'#000000'}} onClick={() => handleOnboarding(active_team.teamId)} disabled={disableOnboarding} />
        )} */}
        {/* {active_team.teamId != 'all' && sportEnabled && <FullWidthButton className='bold font-lg' icon={arenaIcon()} text={`ENTER ${sport} ARENA`} style={{color:'#000000'}} onClick={() => handleOnboarding(active_team.teamId)} disabled={disableOnboarding} />} */}
        {/* {
          hours == 0 && minutes == 0 && seconds == 0 && days == 0 && (
            <div style={{overflow:'hidden'}}>
              <motion.div 
                  animate={ active_team.teamId == 'all' ? 'collapse' : 'expand'}
                  transition={{duration: 0.5}}
                  initial={false}
                  variants={shrinkVertical}
              >
                  <FullWidthButton className='bold font-lg' icon={arenaIcon()} text={`ENTER ${sport} ARENA`} style={{color:'#000000'}} onClick={() => handleOnboarding(active_team.teamId)} disabled={disableOnboarding} />
              </motion.div>
            </div>
          )
        } */}
    </div>
  )
}

export default LineupInfo