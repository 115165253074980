import React, { useState, useEffect } from "react"
import styled, { css } from 'styled-components'
import { useColors } from "../../../helpers/CustomHooks/useColors";
// import { Colors } from "../../../../helpers/colors"

const PlayerCardHeaderNba = (props) => {
    const Colors = useColors();
    const { data } = props
    
    const Image = styled.img`
        width: 100%;
        max-width: 140px;
    `

    const Divider = styled.div`
        border-left: .1rem solid #6c757d;
        margin-right: .5rem;
        margin-left: .5rem;
        margin-top: .5rem;
        margin-bottom: .5rem;
    `

    return (
        <div className="d-flex flex-column pt-5" style={{backgroundColor:Colors.playerCardHeader}} >
            {/* Player Info */}
            <div className="mx-4 mb-3">
                <p>PLAYER PROFILE</p>
                <div className="d-flex align-items-center">
                    <div style={{ overflow: 'hidden', maxWidth: '100px', maxHeight: '100%' }} >
                        <img className='img-responsive rounded-circle my-1' src={data.image} width='100%' height='100px' style={{ objectFit: 'cover', border: `2px solid ${Colors.colorPrimary}` }} />
                    </div>
                    <div className="flex1 ml-2">
                        <h4 className="d-inline">{data.fullName}</h4> <span className="pull-right small pr-5"></span>
                        <h6 className="bold m-0">
                            {props.teams && props.teams.filter(team => team.team_id == data.team).map(found => { return (found.name) })} | #{data.rank} | {data.pos}
                        </h6>
                        <h6 className="bold m-0">Ht/Wt: {data.weight}, {data.weight}</h6>
                    </div>
                </div>
            </div>
            {/* Player Info */}
            

            {/* Upcoming Bar */}
                <div className="d-flex align-items-center text-center" style={{backgroundColor: Colors.bgDark}}>
                    <h6 className="m-0 py-3 w-100"><span style={{color:Colors.colorSecondary}}>Upcoming:</span> @POR, @GSW, OKC, BKN</h6>
                </div>
            {/* !Upcoming Bar */}

            {/* Highlights */}
            <div className="d-flex py-3" style={{ backgroundColor: Colors.bgSecondHeader}}>
                <div className="text-center flex1">
                    <p className="m-0" style={{color: Colors.colorSecondary}}>PT</p>
                    <h1>{data.points}</h1>
                </div>
                <div className="text-center flex1">
                    <p className="m-0" style={{color: Colors.colorSecondary}}>REB</p>
                    <h1>4.4</h1>
                </div>
                <div className="text-center flex1">
                    <p className="m-0" style={{color: Colors.colorSecondary}}>AST</p>
                    <h1>8.0</h1>
                </div>
            </div>
            {/* !Highlights */}

        </div>
    )
}

export default PlayerCardHeaderNba