import moment from 'moment-timezone';
import React, { useState } from 'react';
import { Button, Modal, ModalBody, UncontrolledTooltip } from 'reactstrap';
// import { Colors } from '../helpers/colors';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getStore } from '../helpers/common-methods';
import CopyToClipboard from 'react-copy-to-clipboard';
import { STYLES } from '../helpers/stylesHelper';
import { useColors } from '../helpers/CustomHooks/useColors';

const InviteLeagueMateModal = (props) => {
    const Colors = useColors();
    const { allLeagues } = getStore().getState().leagueJoin;
    const [ copiedLeagueId, setCopiedLeagueId ] = useState(null);

    const getCopyText = (league) => {
        var url = encodeURI(league.inviteLink);
		return "You've been invited to join " + league.league_name + ' on Bettle. Sign up and claim your team here: ' + url;
    }

    return (
        <div>
            <Modal isOpen={props.modal} toggle={props.toggle}>
            <i onClick={props.toggle} class="cui-circle-x icons pointer modalClose"></i>
            <ModalBody className='d-flex flex-column' style={{maxHeight: 'calc(100vh - 100px)', minHeight:'calc(100vh - 100px)'}}>
                <div className='p-2' >
                    <h1 style={{fontFamily: "Fritch", textAlign:'center', color:Colors.colorPrimary}} >Refer & Earn</h1>
                </div>
                <PerfectScrollbar className='flex1'>
                    {allLeagues.map((league) => {
							return (
								<div className="d-flex my-1 p-2" style={{ backgroundColor: Colors.bgDark, borderRadius: 5 }}>
									<div style={{ overflow: 'hidden', width: 40, height: 40, borderRadius: 5 }}>
										<img className="img-responsive" src={league.image} width="100%" height="100%" style={{ objectFit: 'cover' }} />
									</div>

									<p
										className="ml-2 flex1 my-0 text-truncate text-white">
										<span className="bold text-truncate" id={`tip-league-${league.id}`}>{league.league_name}</span>
										<br />
										<span>{league.sport}</span>
									</p>
									<UncontrolledTooltip placement="top"  target={`tip-league-${league.id}`}>{league.league_name}</UncontrolledTooltip>
                                    <CopyToClipboard text={getCopyText(league)} onCopy={() => setCopiedLeagueId(league.id)}>
                                        <Button className="my-2" style={STYLES.BUTTON_PRIMARY_TRANSPARENT_RESPONSIVE}>
                                            {
                                                league.id == copiedLeagueId ?
                                                <p className='m-0'><i class="fa fa-clone" aria-hidden="true" /> Copied!</p>
                                                :
                                                <p className='m-0'><i class="fa fa-clone" aria-hidden="true" /> Copy Invite Link</p>
                                            }
                                        </Button>
                                    </CopyToClipboard>
								</div>
							);
						})}
                </PerfectScrollbar>
            </ModalBody>
            </Modal>
        </div>
    );
    
  }
  
  export default InviteLeagueMateModal;