import React from 'react';
import { useColors } from '../../../helpers/CustomHooks/useColors';
import { arrowDownIcon } from '../../../helpers/icons';

const NewUnreadMessage = (props) => {

    const Colors = useColors();

    return (
        <div className='position-relative w-100 d-flex justify-content-center'>
            <div className='px-2 py-1 mt-2 position-absolute' style={{backgroundColor:Colors.colorLink, cursor:'pointer', borderRadius:100}} onClick={() => props.scrollToBottom(true)}>
                <p className='m-0'>{arrowDownIcon()} {props.unreadCount} New Message</p>
            </div>
        </div>
    );
};

export default NewUnreadMessage;