import React, { useEffect, useState } from 'react';
import { useColors } from '../../helpers/CustomHooks/useColors';
import { chevronDownIcon } from '../../helpers/icons';
import { defaultColors } from '../../helpers/colors';
import CounterActions from '../../components/Matchmaker/ProposalActions/CounterActions';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getStore } from '../../helpers/common-methods';

function ArenaMatchModal(props) {
    const Colors = useColors();
    const { isCounter, cancelCallback, stateSetterCallback, rematchData } = props
    const handleCancel = () => {
        // setWeeklyBankrollError(null)
        // setWalletError(null)
        // setCancelBtnLoading(true)
		// cancelCallback()
        if (rematchData?.proposalDrawer) {
            getStore().dispatch({ type: "SET_REMATCH_DATA", payload: null });
        } else {
            stateSetterCallback("proposalModal", null, "invert");
            stateSetterCallback("matchModalCancelCallback", true);
        }
    }

	return (
        <div className='d-flex flex-column flex1 scrollHidden'>
            <PerfectScrollbar>
                <div className='text-center' style={{backgroundColor: isCounter ? Colors.nbaPrimaryColor : Colors.darkGreen_v3}}>
                    <div className='mx-auto pointer' onClick={handleCancel} style={{height:30, width:30}}>
                        {
                            chevronDownIcon()
                        }
                    </div>
                </div>
                <CounterActions {...props} />
            </PerfectScrollbar>
        </div>
	);
}

export default ArenaMatchModal;
