import React, { useEffect, useState } from 'react';
import androidLogo from '../assets/images/android.png';
import iosLogo from '../assets/images/ios.png';
import { Colors } from '../helpers/colors';
import { withRouter } from 'react-router-dom';
import { Container, Col, Row, Carousel, Button, Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem } from 'reactstrap';
import { STYLES } from '../helpers/stylesHelper';
import logo from '../assets/images/logo_trans.png'
import bettleBee from '../assets/images/bettleBee.png';
import { useHistory } from 'react-router-dom';

const LandingHeader = () => {
    const history = useHistory()
    return (
        <div>
            <div className='d-none d-md-block' >
                <Navbar className='shadow-sm' expand={"lg"}  >
                    <NavbarBrand href="/">
                        <img src={logo} width="180" height="auto" alt="Bettle Inc" className="ml-4" />
                    </NavbarBrand>
                    {/* <Nav className="ml-auto text-right" navbar>
                        <NavItem className="px-3">
                            <Button style={STYLES.BUTTON_PRIMARY_ROUND} onClick={() => history.push('/register')} >Import Your Team</Button>
                        </NavItem>
                    </Nav> */}
                </Navbar>
            </div>
            <div className='d-flex align-items-center justify-content-between d-block d-md-none py-3' style={{borderBottom: "1px solid #34383C"}} >
                <img src={logo} width="140" height="auto" alt="Bettle Inc" className="ml-4" />
                <div>
                    {/* <Button onClick={() => history.push('/register')} style={
                        {
                            color: Colors.bgDark,
                            backgroundColor: Colors.colorPrimary,
                            border: `1px solid ${Colors.colorPrimary}`,
                            fontWeight: "bold",
                            borderRadius: "20px",
                            marginRight: "20px"
                        }
                    } >Import Your Team</Button> */}
                </div>
            </div>
        </div>
    )
}

const AppDownloadPage = (props) => {

    return (
        <div className='d-flex flex-column flex1' style={{height: "100vh"}} >
            <LandingHeader/>
            <div className='d-flex flex-column flex1 align-items-center justify-content-center'>
                <h1 className='text-center greenText mb-4' style={{fontFamily: "Fritch"}} >GET STARTED.</h1>
                <p className='text-center lead' >Download the Bettle app below to sign up and import your squad.</p>
                <div className='d-flex flex-column align-items-center justify-content-center'>
                    <div className='d-flex align-items-center' style={{height:'5rem'}}>
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.bettle.bettlelive" >
                            <img className='img-fluid' src={androidLogo} height='100%' alt="" />
                        </a>
                    </div>
                    <div className='d-flex align-items-center' style={{ height: '5rem'}}>
                        <a target="_blank" href="https://apps.apple.com/us/app/bettle-fantasy-sports/id1591274931" >
                            <img className='img-fluid' src={iosLogo} height='100%' alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default withRouter(AppDownloadPage);