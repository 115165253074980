import React from 'react';
import NFLPlayersPointsRow from './NFLPlayersPointsRow';

const NFLPlayersRow = (props) => {
  const [rosterTemplate, setRosterTemplate] = React.useState(["QB", "RB", "WR", "TE", "FLX", "SFLX", "DST", "IDP", "K"]);
  const { data, selectPlayer } = props;

  const findPlayer = (pos, index) => {
    var pls = []
    if (data && data.teams[index] && data.teams[index].players) {
      data.teams[index].players.map((player) => {
        if (player.pos == pos) {
          pls.push(player)
        }
      })
    }
    return pls
  }

  return (
    <div className="mb-3">
      {rosterTemplate.map((pos, index) => {
        var players1 = findPlayer(pos, 0)
        var players2 = findPlayer(pos, 1)
        return (
          <div className="d-flex">
            {data &&
              <NFLPlayersPointsRow pos={pos} sc_rules={data.sc_rules} players1={players1} players2={players2} selectPlayer={selectPlayer} key={index} />
            }
          </div>
        )
      })}
    </div>
  )
};

export default NFLPlayersRow;