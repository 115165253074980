import React, { useEffect, useState } from 'react';
import androidLogo from '../assets/images/android.png';
import iosLogo from '../assets/images/ios.png';
import { Colors } from '../helpers/colors';
import { withRouter } from 'react-router-dom';
import { userBalance } from '../apis/Gidx/GidxApi';
import store from '../store';

const LandingPageMob = (props) => {

    const [balanace, setBalance] = useState({
        a: 0,
        b: 0
    })
    const [importSuccess, setImportSuccess] = useState(store.getState().arenaReducer.importSuccess)

    useEffect(() => {
        userBalance().then((resp) => {
            setBalance({
                a: resp.balance,
                // b: resp.bonusBalance
            })
        })
        store.dispatch({type: "SET_IMPORT_SUCCESS", payload: false})
    }, [])

    return (
        <div className='d-flex flex-column flex1'>
            {importSuccess && <div className="d-flex justify-content-between align-items-center" style={{background: Colors.colorPrimary, color: Colors.colorDark}} >                    
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                <span className="pl-2" >Your league successfully Imported!</span>
            </div>}
            <div className="d-flex align-items-center justify-content-between w-100 mx-auto p-3 mt-3" style={{background: Colors.bgLight, borderRadius: "5px"}} >
                <p className="mb-0" >Available</p>
                <h1 className="bold mb-0" >${(balanace.a)}</h1>
            </div>

            <div className='d-flex flex-column flex1 align-items-center justify-content-center'>
                <h1 className='text-center greenText mb-4' style={{fontFamily: "Fritch"}} >YOU ARE NOW BATTLE READY.</h1>
                <p>Download the app to prove your squad's dominance.</p>
                <div className='d-flex flex-column align-items-center justify-content-center'>
                    <div className='d-flex align-items-center' style={{height:'5rem'}}>
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.bettle.bettlelive" >
                            <img className='img-fluid' src={androidLogo} height='100%' alt="" />
                        </a>
                    </div>
                    <div className='d-flex align-items-center' style={{ height: '5rem'}}>
                        <a target="_blank" href="https://apps.apple.com/us/app/bettle-fantasy-sports/id1591274931" >
                            <img className='img-fluid' src={iosLogo} height='100%' alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default withRouter(LandingPageMob);