import React from "react"
import {Row, Cell} from 'react-sticky-table';
import { prepareArenaOpps } from "../../../helpers/Arena/ArenaHelper";
import { useColors } from "../../../helpers/CustomHooks/useColors";
import { parseInjuryIcons } from "../../../helpers/common-methods";
import { getSportColor } from "../../../helpers/stylesHelper";

const ArenaPlayerRow = (props) => {
    const {player, callBack, index, disableAction, highlightRow, selectedPlayer, togglePlayerCard, setTappedPlayerId, sport} = props

    const Colors = useColors();

    const prepareMultiPositions = (positions) => {
        var str = ""
        positions && positions.map((pos) => {
            str = str + pos + " "
        })
        return str
    }

    const getBorderColor = () => {
        if(!disableAction){
            return Colors.colorPrimary
        }else{
            return Colors.colorSecondary
        }
    }

    const handlePlayerTap = (playerId) => {
        if(setTappedPlayerId){
            setTappedPlayerId(playerId)
            togglePlayerCard()
        }
    }
    return (
        sport == "NBA" 
        ?
        <Row>
            <Cell className="bg400 align-middle border-0" >
                <div className="d-flex pointer m-auto align-self-center" onClick={() => {!disableAction ? callBack(player, index) : console.log("action is disabled")}} style={{
                    width: "40px", height: "40px",
                    border: `2px solid ${getSportColor(sport)}`,
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    }}
                    >
                    <p className="mb-0" >{player.defaultPos ? player.defaultPos : player.pos}</p>
                </div>
            </Cell>
            <Cell className="bg400 border-0" onPress={() => handlePlayerTap(player.playerId)} >
                <div className="d-flex align-items-center" >
                    <div className="position-relative">
                        <img className="roundImgSmall mr-2" src={player.image} style={{borderColor: getSportColor(sport)}} />
                        <div style={{position: "absolute", right: 5, bottom: -5}}  >
                            {parseInjuryIcons(player.injuryStatus)}
                        </div>
                    </div>
                    <div style={{marginLeft: 5}}  >
                        <p numberOfLines={1} className="mb-0" >{player.fullName} <span className="mb-0 small" style={{color: Colors.colorSecondary}} > {player.team} | {prepareMultiPositions(player.eligiblePositions)}</span></p>
                        <p className="text-left m-0 small" style={{color: Colors.colorSecondary}}>No Game</p>
                    </div>
                </div>
            </Cell>
            <Cell className="bg400 border-0 text-right" >
                {prepareArenaOpps(player.opps)}
            </Cell>
        </Row>
        :
        <div className="d-flex justify-content-between align-items-center px-4 py-2">
            <div className="d-flex align-items-center">
                <div className="d-flex pointer" onClick={() => {!disableAction ? callBack(player, index) : console.log("action is disabled")}} style={{
                    width: "35px", height: "35px",
                    border: `2px solid ${getBorderColor()}`,
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    color: Colors.colorPrimary,
                    fontSize: 16,
                    fontWeight: 600,
                    }}
                    >
                    <p className="mb-0">{player.defaultPos == "UTIL" ? "UT" : player.defaultPos ? player.defaultPos : player.pos}</p>
                </div>
                <div className="ml-3" onPress={() => handlePlayerTap(player.playerId)} >
                    <div className="d-flex align-items-center">
                        <div className="position-relative">
                            <img className="mr-2" src={player.image} style={{ width: 43, height: 43, objectFit: "cover", borderRadius: "50%" }} />
                            <div style={{position: "absolute", right: 5, bottom: -5}}>
                                {parseInjuryIcons(player.injuryStatus)}
                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{marginLeft: 5}}>
                            <p numberOfLines={1} className="mb-0" style={{ fontSize: 16, fontWeight: 600 }}>{player.fullName}</p>
                            {/* <p className="mb-0" >{prepareMultiPositions(player.eligiblePositions)}</p> */}
                            <p className="m-0 pt-1 pl-2 small" style={{ fontWeight: 500, color: Colors.colorSecondary }}>{player.team}</p>
                            <p className="m-0 pt-1 pl-2 small" style={{ fontWeight: 500, color: Colors.colorPrimary }}>{player.injuryStatus}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                {prepareArenaOpps(player.opps)}
            </div>
        </div>
    )
}
export default ArenaPlayerRow