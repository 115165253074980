import React from 'react'
import { withRouter } from 'react-router-dom'
import { arenaScoreboardMessageIcon } from '../../../helpers/icons'
import { useColors } from '../../../helpers/CustomHooks/useColors'
const ChatBubble = (props) => {

    const Colors = useColors();
    const navigateToChat = () => {
        props.history.push(`/chat/user/${props.userId}/${props.matchId}`)
    }
    return (
        <div className='shadow-sm' style={{position:'absolute', bottom:30, right:30 ,zIndex:999, cursor:'pointer'}} onClick={() => navigateToChat()}>
            <div className='d-flex align-items-center justify-content-center text-dark' style={{height:50, width:50, padding:12, backgroundColor:Colors.colorPrimary, borderRadius:'50%'}}>
                { arenaScoreboardMessageIcon()}
            </div>
        </div>
    )
}

export default withRouter(ChatBubble)