export default (state =
    {
    errorModal: {
        show: false,
        data: null
    },
    successModal: {
        show: false,
        data: null
    }
}, action) => {
    switch (action.type) {
        case "ERROR_MODAL":
            return Object.assign({}, state, { errorModal: action.payload })
        case "GIDX_SUCCESS_MODAL":
            return Object.assign({}, state, { successModal: action.payload })
        default:
            return state;
    }
}