import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Colors } from '../../helpers/colors';
import { getLeagues, updateActiveLeagueId, saveActiveLeague, saveAllLeagues, SAVE_APPLICATION_STATUS } from '../../apis/Sidebar/LeagueAPI';
import { chatIndex } from '../../apis/Chat/ChatAPI';
import { withRouter } from 'react-router-dom';
import { Popover, PopoverBody, PopoverHeader, UncontrolledTooltip } from 'reactstrap';
import LeagueListPopover from './LeagueListPopover';
import { getStore } from '../../helpers/common-methods';
import { updateActiveExperience } from '../../apis/User/UserAPI';
import LoadingOverlay from 'react-loading-overlay';
import ImportLeagueModal from '../../modals/ImportLeagueModal';
import baseballArena from "../../assets/images/baseballArena.png"
import footballArena from "../../assets/images/footballArena.png"
import { ColorContext } from '../../context/ColorProvider';
class SidebarFooter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			importLeagueModal: false,
			importPlatform: null
		};
		this.switchApp = this.switchApp.bind(this);
	}
	static contextType = ColorContext;

	componentDidMount() {
		getLeagues()
			.then((response) => {
				let activeLeague = response.on.sl.find((league) => {
					return league.id == response.activeLeague;
				});

				// Handle the exception if 'response.leagueId' is not related to fetched leagues
				if (activeLeague === undefined && response.on.sl.length > 0) {
					activeLeague = response.on.sl[0];
				}

				// If active league is undefined till this point, it means we dont have any leagues yet
				if (activeLeague !== undefined) {
					//League ID
					this.props
						.updateActiveLeagueId(activeLeague.id)
						.then((response) => {
							getStore().dispatch({ type: 'SET_NAV_LOADING', payload: false });
						})
						.catch((error) => this.setState({ errors: error.response }));
					// Active League
					this.props.saveActiveLeague(activeLeague);

					// All Leagues
					this.props.saveAllLeagues(response.on.sl);
				} else {
					getStore().dispatch({ type: 'SET_NAV_LOADING', payload: false });
				}
			})
			.catch((error) => this.setState({ errors: error.response }));
	}

	switchApp(destination, origin = this.props.app_status) {
		if (destination != origin) {
			this.setState({ loading: true });
			updateActiveExperience({
				activeExp: destination
			})
				.then(() => {
					this.setState({ loading: false });
					getStore().dispatch({ type: SAVE_APPLICATION_STATUS, app_status: destination });
					if (destination == 'BASEBALL_ARENA') {
						this.props.history.push('/arena/home');
					} else if (destination == 'FOOTBALL_ARENA') {
						this.props.history.push('/arena/home');
					} else {
						this.props.history.push('/dashboard');
					}
				})
				.catch(() => {
					this.setState({ loading: false });
				});
		}
	}

	async toggleImportLeague() {
		this.props.history.replace({ pathname: window.location.pathname, search: '' });
		this.setState((prevState) => ({
			importLeagueModal: !this.state.importLeagueModal,
			importPlatform: null
		}));
	}

	render() {
		const Colors = this.context; 
		return (
			true ? 
			<></>
			:
			<LoadingOverlay className="d-flex flex-column flex1" fadeSpeed={0} active={this.state.loading} spinner>
				<div className="d-flex flex-column mb-3 mx-2" style={{ maxHeight: '50%' }}>
					{this.props.app_status != 'SEASON_LONG' && (
						<div className="d-flex align-items-center my-1 mt-2">
							<div style={{ overflow: 'hidden', width: 30, height: 30, borderRadius: 5 }}>
								<img className="img-responsive" src={this.props.activeLeague.image} width="100%" height="100%" style={{ objectFit: 'cover' }} />
							</div>

							<p className="ml-2 flex1 my-0 text-truncate" style={{ cursor: 'pointer' }} onClick={() => this.switchApp('SEASON_LONG')}>
								<span className="bold text-truncate d-block" id="league-name">
									{this.props.activeLeague.league_name}
								</span>
								<span style={{fontSize: "12px"}} className="text-muted">{this.props.activeLeague.sport}</span>
							</p>
							<UncontrolledTooltip placement="top" target="league-name">
								{this.props.activeLeague.league_name}
							</UncontrolledTooltip>
						</div>
					)}

					{/* {this.props.app_status != 'BASEBALL_ARENA' && (
						<div className="d-flex align-items-center my-1" onClick={() => this.switchApp('BASEBALL_ARENA')}>
							<div style={{ height: '30px', width: '30px', borderRadius: '5px', backgroundColor: Colors.bgDark }}>
								<img src={baseballArena} style={{height:'100%', width:'100%', borderRadius:5}}/>
							</div>
							<p className="ml-2 my-0 bold" style={{ cursor: 'pointer' }}>
								Baseball Arena
							</p>
						</div>
					)} */}
					{this.props.app_status != 'FOOTBALL_ARENA' && (
						<div className="d-flex align-items-center my-1" onClick={() => this.switchApp('FOOTBALL_ARENA')}>
							<div style={{ height: '30px', width: '30px', borderRadius: '5px', backgroundColor: Colors.bgDark }}>
								<img src={footballArena} style={{height:'100%', width:'100%', borderRadius:5}}/>
							</div>
							<p className="ml-2 my-0 bold" style={{ cursor: 'pointer' }}>
								Football Arena
							</p>
						</div>
					)}
					<LeagueListPopover allLeagues={this.props.allLeagues} leagueId={this.props.leagueId} switchApp={this.switchApp} />
					<div
						id="importLeagueNav"
						className="pointer d-flex align-items-center mt-1"
						onClick={() => {
							// this.setState({ importLeagueModal: true })
							this.props.history.push("/new-user-onboarding")
						}}>
						<i id="import-league" class="fa fa-plus-square-o" style={{ fontSize: '2.2rem' }} aria-hidden="true" />
						<UncontrolledTooltip target="import-league" placement="right" delay={{ show: 0, hide: 0 }}>
							Import League
						</UncontrolledTooltip>
						<span className="bold text-truncate ml-3" id="league-name">
							Import League
						</span>
					</div>
				</div>
				{/* {this.state.importLeagueModal && <ImportLeagueModal importPlatform={this.state.importPlatform} modal={this.state.importLeagueModal} toggle={(close) => this.toggleImportLeague(close)} />} */}
			</LoadingOverlay>
		);
	}
}

const mapDispatchToProps = { updateActiveLeagueId, saveActiveLeague, saveAllLeagues };
const mapStateToProps = (state) => ({ leagueId: state.leagueJoin.leagueId, activeLeague: state.leagueJoin.activeLeague, allLeagues: state.leagueJoin.allLeagues, app_status: state.leagueJoin.app_status });
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SidebarFooter));
