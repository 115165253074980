import React, { Component } from 'react';
// import { Colors } from '../../../helpers/colors';
import * as Icons from '../../../helpers/icons';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Container, Col, Row, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logoutUser } from '../../../actions/auth';
import Sidebet from '../../../components/Chat/Templates/Sidebet/Sidebet';
import ProfileModal from '../../../components/SeasonLong/Profile/ProfileModal';
import ProfileHeader from '../../../components/User/Profile/ProfileHeader';
import ProfileTabs from '../../../components/User/Profile/ProfileTabs';
import image from '../../../assets/images/logo_trans.png';
import { ColorContext } from '../../../context/ColorProvider';

class Profile extends Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.toggleEdit = this.toggleEdit.bind(this);
		this.handleLogout = this.handleLogout.bind(this);
		this.state = {
			mainTab: 'results',
			activeTab: '1',
			modal: false,
			modalEdit: false,
			currentViewModal: 'info',
			// imgsrc: this.props.auth.user.image,
			// firstName: this.props.auth.user.first_name,
			// lastName: this.props.auth.user.last_name,
			// email: this.props.auth.user.email
		};
	}
	static contextType = ColorContext; 
	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({ activeTab: tab });
		}
	}
	toggleModal() {
		this.setState({
			modal: !this.state.modal
		});
	}

	toggleEdit() {
		this.setState({
			modalEdit: !this.state.modalEdit,
			currentViewModal: 'info'
		});
		// console.log(this.props.auth);
	}

	handleLogout() {
		this.props.logoutUser(() => this.props.history.push('/signin'));
	}

	shouldComponentUpdate(nextProps) {
		if (nextProps.auth.user == null) {
			return false;
		};
		return true;
	};

	render() {
		// console.log(this.props.auth.user)
		const Colors = this.context; 
		return (
			<div className="d-flex flex-column flex1 scrollHidden">
				<div className='d-flex align-items-center px-2' style={{ borderBottom: `0.01em solid ${Colors.darkBgBorder}`, height: '52px' }}>
					<h2>Profile</h2>
				</div>
				<div className="mb-4 d-flex flex-column flex1 scrollHidden mt-2">
					<div
						className="w-100 p-2 mx-2"
						style={{
							border: `0.01em solid ${Colors.darkBgBorder}`,
							backgroundColor: Colors.bgDark,
							borderRadius: 15,
						}}>
						<div className="" style={{ width: '100%', height: '160px', background: Colors.bgLight }}>
							{/* <img
								className="img-responsive"
								src="https://images.squarespace-cdn.com/content/v1/58dcfe39d482e901dc8e0abb/1493151033396-0LUK5WY8CA644SK4XC0I/PRPW_FACEBOOK.jpg"
								style={{
									width: '100%',
									// height: 268,
									height: 160,
									objectFit: 'cover',
									borderRadius: 5
								}}
							/> */}
							<div className='d-flex justify-content-center align-items-center h-100'>
								<img src={image} alt="" />
							</div>
						</div>
						<div className="d-flex flex-row align-items-center">
							<div className="flex1 d-flex flex-row mx-4">
								<img
									className="rounded-circle"
									src={this.props.auth.user.image}
									height="140px"
									width="140px"
									style={{
										border: `4px solid ${Colors.colorPrimary}`,
										marginTop: '-75px'
									}}
								/>
								<div className="">
									<h2
										className="mx-3"
										style={{
											fontWeight: 'bold'
										}}>
										{this.props.auth.user.first_name} {this.props.auth.user.last_name}
										<i
											className="fa fa-circle align-middle mx-2"
											style={{
												color: Colors.colorPrimary,
												fontSize: '0.35em'
											}}
										/>
										{/* <span
											style={{
												color: Colors.colorSecondary,
												fontSize: '0.52em'
											}}>
											#5
										</span> */}
									</h2>
									<h6
										className="ml-3"
										style={{
											color: Colors.colorSecondary
										}}>
										{this.props.auth.user.email}
									</h6>
								</div>
							</div>

							<div className="flex1 d-flex justify-content-end ">
								{/* <Button
									onClick={this.toggleModal}
									className="mx-2 d-flex align-items-center"
									style={{
										color: Colors.colorPrimary,
										backgroundColor: Colors.colorPrimaryTransparent,
										border: `1px solid ${Colors.colorPrimary}`
									}}>
									<div className="mr-2" style={{width:15, height:15, aspectRatio:1}}>
										<Icons.myBetsIcon />
									</div>
									New Bet
								</Button> */}

								<Modal isOpen={this.state.modal} toggle={this.toggleModal} className={this.props.className} centered>
									<ModalHeader
										className="border-0"
										style={{
											backgroundColor: Colors.bgLight
										}}
										cssModule={{
											'modal-title': 'w-100'
										}}>
										<h4 className="bold text-truncate">
											New Bet<i
												className="fa fa-close pull-right"
												onClick={this.toggleModal}
												style={{
													cursor: 'pointer'
												}}
											/>
										</h4>
									</ModalHeader>
									<ModalBody
										style={{
											backgroundColor: Colors.bgLight,
											marginTop: -30
										}}>
										<Sidebet />
									</ModalBody>
								</Modal>

								{/* <Button
									style={{
										color: Colors.colorPrimary,
										backgroundColor: Colors.colorPrimaryTransparent,
										border: `1px solid ${Colors.colorPrimary}`
									}}>
									Send Message
								</Button> */}
								<Button
									className="mx-2"
									style={{
										color: Colors.colorPrimary,
										backgroundColor: Colors.colorPrimaryTransparent,
										border: `1px solid ${Colors.colorPrimary}`
									}}
									onClick={this.toggleEdit}>
									Edit Profile
								</Button>
								{this.state.modalEdit && <ProfileModal
									isOpen={this.state.modalEdit}
									toggle={this.toggleEdit}
									className={this.props.className}
									onClick={this.toggleEdit}
									currentViewModal={this.state.currentViewModal}
									onClickedit={() => this.setState({ currentViewModal: 'editprofile' })}
									onClicknotify={() => this.setState({ currentViewModal: 'notifications' })}
									onClickpass={() => this.setState({ currentViewModal: 'password' })}
									logoutUser={() => this.handleLogout()}
									auth={this.props.auth}
									onOkay={this.toggleEdit}
									onBack={() => this.setState({ currentViewModal: 'info' })}
								/>}
							</div>
						</div>
						{/* <div className='d-flex flex-row justify-content-between mt-2 mx-2'>
							<ProfileHeader title='Overall' b1No='15' b1Heading='Sidebets' b2No='3' b2Heading='Leagues' b3No='$90' b3Heading='Profits' />
							<ProfileHeader title='Season Long' b1No='13' b1Heading='Wins' b2No='8' b2Heading='Losses' b3No='62%' b3Heading='Win pct.' />
							<ProfileHeader title='Sidebets' b1No='19' b1Heading='Wins' b2No='12' b2Heading='Losses' b3No='61%' b3Heading='Win pct.' />
						</div> */}
					</div>
					{/* <div className="d-flex flex-column flex1 mx-2 mt-1 scrollHidden"
						style={{
							border: `0.01em solid ${Colors.darkBgBorder}`,
							backgroundColor: Colors.bgDark,
							borderRadius: 15,
						}}>
						<ProfileTabs />
					</div> */}
					<div className='d-flex justify-content-center align-items-center h-100'>
						<h4 className='bold'>Data will be populated.</h4>
					</div>
				</div>
			</div>
		);
	}
}
const mapDispatchToProps = {
	logoutUser
};
const mapStateToProps = (state) => ({
	leagueId: state.leagueJoin.leagueId,
	users: state.leagueJoin.users,
	auth: state.auth
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Profile));
