import { btoa } from '../../helpers/common-methods';

const ENDPOINTS = {
    LEAGUES: '/chatIndex',
    CONVERSATION: "/chatConversation",
    SEND_MESSAGE: "/sendMessage",
    CREATE_GROUP: "/chat/createGroup",
    DELETE_GROUP: "/chat/deleteGroup",
    UPDATE_NOTIFICATION_PREF: "/chat/updateNotificationPref",
    GET_CHAT_DETAIL: "/chat/chatDetail",
    LATEST_CONVERSATION: "/chat/latestMessages",
    ADD_EMOJI: "/add/emoji",
    DELETE_MSG_BY_ID: "/chat/deleteMsgById",
    FETCH_MENTIONS_MASSAGES: "/chat/mentionsAndReaction",
    URL_PREVIEW: "/url-preview",
    LOAD_MEDIA: "/load/attachment",
    USER_INDEX: "/userIndex",
};

export const chatIndex = (league_id) => {
    return window.axios.get(ENDPOINTS.LEAGUES,
        {
            params: {
                league_id: league_id
            }
        })
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            return Promise.reject(error);
        });
}

export const chatConversation = (league_id, related_to = "L", type, with_id, offset = 0, limit = 20) => {
    return window.axios.get(ENDPOINTS.CONVERSATION, {
        params: {
            league_id: league_id,
            related_to: related_to,
            type: type,
            with_id: with_id,
            offset: offset,
            limit: limit
        }
    })
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            return Promise.reject(error);
        });
}

/**
 * 
 * @param {object} data - object containing required data to send a message
 * @returns - message with details
 */
export const sendMessage = (data, config) => {
    // console.log('config: ', config);
    return window.axios.post(ENDPOINTS.SEND_MESSAGE, data, config)
        .then((response) => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error);
        })
}

export const createGroup = (reqData) => {
    return window.axios.post(ENDPOINTS.CREATE_GROUP, reqData).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error);
    });
  }

export const deleteGroup = (reqData) => {
    return window.axios.post(ENDPOINTS.DELETE_GROUP, reqData)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const updateNotificationPref = (reqData) => {
    return window.axios.post(ENDPOINTS.UPDATE_NOTIFICATION_PREF, reqData).then((response) => {
        return Promise.resolve(response.data.data);
    }).catch(error => {
        return Promise.reject(error);
    });
}

export const deleteMsgById = (id) => {
    return window.axios.delete(ENDPOINTS.DELETE_MSG_BY_ID, { params: { mid: id } })
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const addEmoji = (data) => {
    return window.axios.post(ENDPOINTS.ADD_EMOJI, data)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const fetchMentions = (params) => {
    return window.axios.get(ENDPOINTS.FETCH_MENTIONS_MASSAGES, { params })
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

// GET URL PREVIEW 
export const getUrlPreview = (urls) => {
    return window.axios.post(ENDPOINTS.URL_PREVIEW, urls).then(response => {
        return Promise.resolve(response.data);
    }).catch(err => {
        return Promise.reject(err);
    });
};

// GET IMAGES URL
export const loadAttachment = (file, cId) => {
    return window.axios.get(ENDPOINTS.LOAD_MEDIA + "?file=" + file + "&cId=" + cId, { responseType: 'arraybuffer' }).then(response => {
        let image = btoa(
            new Uint8Array(response.data)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        // return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
        return Promise.resolve(`data:${response.headers['content-type'].toLowerCase()};base64,${image}`);
    }).catch(err => {
        return Promise.reject(err);
    })
}

export const userIndex = (params) => {
    return window.axios.get(ENDPOINTS.USER_INDEX,{params})
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            return Promise.reject(error);
        });
}