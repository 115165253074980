import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Button, Modal } from 'reactstrap'
import { STYLES } from '../../../helpers/stylesHelper'
import sendInvitesImage from '../../../assets/images/claimTeam.png'

const SendInvitesModal = (props) => {

    const [copyText, setCopyText] = React.useState("Copy Invite Link")

    const getCopyText = () => {
        var url = encodeURI(props.activeLeague.inviteLink);
		return "You've been invited to join " + props.activeLeague.league_name + ' on Bettle. Sign up and claim your team here: ' + url;
    }

    return (
        <div >
            <Modal isOpen={props.isOpen} toggle={() => props.toggle()}>
                <div className="text-center p-4" >
                <i onClick={() => props.toggle()} class="cui-circle-x icons pointer modalClose"></i>
                <div className="text-center" >
                    <h2 className='mt-4'>Invite Your Leaguemates!</h2>
                    <img className='mt-2 mb-4 w-50' src={sendInvitesImage}/>
                    <p>
                        You have succesfully imported your league! It is now time to invite everyone to Bettle.
                        <span>Copy the link below:</span>
                    </p>
                    <CopyToClipboard text={getCopyText()} onCopy={() => setCopyText('Copied!')}>
                        <Button className="my-2" style={STYLES.BUTTON_PRIMARY_TRANSPARENT}>
                            <i class="fa fa-clone" aria-hidden="true" /> {copyText}
                        </Button>
                    </CopyToClipboard>
                </div>
                </div>
            </Modal>
        </div>
    )
}

export default SendInvitesModal