import { Modal, ModalBody } from "reactstrap"
import { backArrowIcon, closeIcon } from "../../helpers/icons";
import React from "react";
import { useColors } from "../../helpers/CustomHooks/useColors";
import UserProfile from "../../components/Matchmaker/UserProfile/UserProfile";

const ProfileModal = (props) => {
    const { modal, toggle, teamId } = props

    const Colors = useColors()

    return (
        <>
            <div className="d-flex p-2 justify-content-end">
                <a className="pointer" onClick={toggle}>
                    {closeIcon()}
                </a>
            </div>  
            {/* <ModalBody > */}
                <UserProfile teamId={teamId} />
            {/* </ModalBody> */}
        </>
    )
}

export default ProfileModal;