import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import logo from '../../assets/images/logo_trans.png'
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink, Button } from 'reactstrap';
import { getStore } from '../../helpers/common-methods';

const Header = () => {
    const history = useHistory();
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(false);
    const { auth: { authenticated } } = getStore().getState();

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    };

    return (
        <Navbar expand="lg" style={{ backgroundColor: "rgb(17, 22, 26)" }} className='shadow-sm'>
            <NavbarBrand href="/">
                <img src={logo} width="180" height="auto" alt="Bettle Inc" className="ml-4" />
            </NavbarBrand>
            {location.pathname.includes('/internships') ? '' :
                <NavbarToggler onClick={toggleNavbar} />
            }
            <Collapse isOpen={collapsed} navbar>
                <Nav className="ml-auto text-right" navbar>
                    {(location.pathname.includes('/register') || location.pathname == '/') && !authenticated  &&
                        // <NavItem className="px-3">
                        //     <NavLink style={{ cursor: 'pointer' }} onClick={() => history.push('/signin')}>
                        //         Login
                        //     </NavLink>
                        // </NavItem>
                        <NavItem className="px-3" onClick={() => history.push('/signin')}>
                            <Button id="nav_sign_up_button" className="green-btn btn-pill bold" style={{ width: "200px" }}  >Login</Button>
                        </NavItem>
                    }
                    {location.pathname.includes('/signin') && !authenticated &&
                        <NavItem className="px-3" onClick={() => history.push('/register')}>
                            <Button id="nav_sign_up_button" className="green-btn btn-pill bold" style={{ width: "200px" }}  >Join</Button>
                        </NavItem>
                    }
                    {
                        (location.pathname.includes('/privacy') || location.pathname.includes('/terms-and-conditions')) &&
                        <NavItem className="px-3" onClick={() => history.location.key == 'POP' ? history.goBack() : history.push('/')}>
                            <Button id="nav_sign_up_button" color='primary' className="btn-pill bold" style={{ width: "200px" }}  >Go Back</Button>
                        </NavItem>
                    }
                    {
                        authenticated &&
                        <NavItem className="px-3" onClick={() => history.push('/dashboard')}>
                            <Button id="nav_sign_up_button" color='primary' className="btn-pill bold" style={{ width: "200px" }}>Dashboard</Button>
                        </NavItem>
                    }
                </Nav>
            </Collapse>
        </Navbar >
    )
};

export default Header;