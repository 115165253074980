
import { DSTScore } from "./DTScoringHelper"

const KICKER_BONUS_STATS = ["kick_fg_0_39", "kick_fg_40_49", "kick_fg_50", "kick_fg_attempt_0_39", "kick_fg_attempt_40_49", "kick_fg_attempt_50",
"kick_fg_miss_0_39", "kick_fg_miss_40_49", "kick_fg_miss_50"]

export const generatePoints = (rules, players, type = "points") => {
    let total = 0
    let prevTotal = 0
    if(players != undefined){
        if(type == "points"){
            players.map((player) => {
                if(player.pos == "DST"){
                    total = total + dstPoints(rules, player.stats)
                    if(player.prev_stats!=null)
                        prevTotal = prevTotal + dstPoints(rules, player.prev_stats)
                }else{
                    total = total + playerPoints(player.stats, rules, player.pos)
                    if(player.prev_stats!=null)
                        prevTotal = prevTotal + playerPoints(player.prev_stats, rules, player.pos)
                }
            })
        }else{
            players.map((player) => {
               total = total + player.rem_time
            })
        }
    }
    return type == "points" ? (total+prevTotal).toFixed(2) : parseInt(total)
}

export const dstPoints = (rules, stats) => {
    if(stats.started){
        return dstPointsNew(rules, stats)
    }
    return 0;
}

export const playerPoints = (stats, rules, playerPos = null) => {
    let points = 0.00
    let map = rules.mapping
        for (const [key, scoring] of Object.entries(map)) {
        // Object.values(map).map((scoring) => {
            if (scoring && scoring["dynamic"] != undefined){
                if(scoring["type"] == "pass" && stats["pass_yards"] && stats.pass_yards.length > 0){
                    var quantity = handleDynamicStats(stats["pass_yards"], scoring["target"])
                    points = points + (quantity * parseFloat(scoring["points"]))
                }else if (scoring["type"] == "rush"  && stats["rush_yards"] && stats.pass_yards.length > 0){
                    var quantity = handleDynamicStats(stats["rush_yards"], scoring["target"])
                    points = points + (quantity * parseFloat(scoring["points"]))
                }else if (scoring["type"] == "rec"  && stats["rec_yards"] && stats.pass_yards.length > 0){
                    var quantity = handleDynamicStats(stats["rec_yards"], scoring["target"])
                    points = points + (quantity * parseFloat(scoring["points"]))
                }
            }else if(scoring && scoring["stat"] != undefined){
                if( stats && (scoring !=undefined) && (stats[scoring["stat"]] !=undefined)){
                    if((scoring["sum"]) !=undefined){
                        if(scoring["sum"] == 0){
                            if(parseFloat(stats[scoring["stat"]]) <= parseFloat(scoring["sum"])){
                                if(playerPos && playerPos != "K" && !KICKER_BONUS_STATS.includes(key)){
                                    // give bonus points
                                    points = points + parseInt(scoring["points"]);
                                }else if(playerPos && playerPos == "K"){
                                    // give bonus points
                                    points = points + parseInt(scoring["points"]);
                                }
                            }
                        }else{
                            if(parseFloat(stats[scoring["stat"]]) >= parseFloat(scoring["sum"])){
                                if(playerPos && playerPos != "K" && !KICKER_BONUS_STATS.includes(key)){
                                    // give bonus points
                                    points = points + parseInt(scoring["points"]);
                                }else if(playerPos && playerPos == "K"){
                                    // give bonus points
                                    points = points + parseInt(scoring["points"]);
                                }
                            }
                        }
                    }else if((scoring["le"]) != undefined){
                        // give bonus between speicifed yards
                        if( parseFloat(stats[scoring["stat"]]) >= parseFloat(scoring["ge"]) && parseFloat(stats[scoring["stat"]]) <= parseFloat(scoring["le"])){
                            if(playerPos && playerPos != "K" && !KICKER_BONUS_STATS.includes(key)){
                                // give bonus points
                                points = points + parseInt(scoring["points"]);
                            }else if(playerPos && playerPos == "K"){
                                // give bonus points
                                points = points + parseInt(scoring["points"]);
                            }
                        }                                                                                                                           
                    }else{
                        // normal case
                        points = points + parseFloat(stats[scoring["stat"]]) * parseFloat(scoring["points"]);
                    }
                }
            }else if(scoring && scoring["statGroup"] != undefined){
                scoring["statGroup"].map((singleStat) => {
                    if(stats && stats[singleStat] != undefined){
                        if(playerPos && playerPos != "K" && !KICKER_BONUS_STATS.includes(key)){
                            // give bonus points
                            points = points + parseFloat(stats[singleStat]) * parseInt(scoring["points"]);
                        }else if(playerPos && playerPos == "K"){
                            points = points + parseFloat(stats[singleStat]) * parseInt(scoring["points"]);
                        }
                    }
                })
            }   
        }
    return parseFloat(points.toFixed(2))
}


export const dstPointsNew = (rules, stats) => {
    let myStats = stats.stats.my
    let points = 0.00
    let mapping = rules.dst_mapping
    var yardsAllowed = parseFloat(stats.pya) + parseFloat(stats.rya)
    var pointsAllowed = parseFloat(stats.pa)
    Object.values(mapping).map((scoring) => {
        if(scoring["viaCode"] != undefined){
            if(scoring["statType"] == "yards_allow"){
                if(scoring["sum"] != undefined){
                    if(scoring["sum"] == 0){
                        if(yardsAllowed <= scoring["sum"]){
                            points = points + parseFloat(scoring["points"])
                        }
                    }else{
                        if(yardsAllowed >= scoring["sum"]){
                            points = points + parseFloat(scoring["points"])
                        }
                    }
                }else if(scoring["le"] != undefined){
                    if(yardsAllowed >= scoring["ge"] && yardsAllowed <= scoring["le"]){
                        points = points + parseFloat(scoring["points"])
                    }
                }
            }else if(scoring["statType"] == "points_allow"){
                if(scoring["sum"] != undefined){
                    if(scoring["sum"] == 0){
                        if(pointsAllowed <= scoring["sum"]){
                            points = points + parseFloat(scoring["points"])
                        }
                    }else{
                        if(pointsAllowed >= scoring["sum"]){
                            points = points + parseFloat(scoring["points"])
                        }
                    }
                }else if(scoring["le"] != undefined){
                    if(pointsAllowed >= scoring["ge"] && pointsAllowed <= scoring["le"]){
                        points = points + parseFloat(scoring["points"])
                    }
                }
            }
        }else if (scoring["dynamic"] != undefined){
            if(scoring["type"] == "pass" && stats["pass_yards"] && stats.pass_yards.length > 0){
                var quantity = handleDynamicStats(stats["pass_yards"], scoring["target"])
                points = points + (quantity * parseFloat(scoring["points"]))
            }else if (scoring["type"] == "rush"  && stats["rush_yards"] && stats.pass_yards.length > 0){
                var quantity = handleDynamicStats(stats["rush_yards"], scoring["target"])
                points = points + (quantity * parseFloat(scoring["points"]))
            }else if (scoring["type"] == "rec"  && stats["rec_yards"] && stats.pass_yards.length > 0){
                var quantity = handleDynamicStats(stats["rec_yards"], scoring["target"])
                points = points + (quantity * parseFloat(scoring["points"]))
            }
        }else if(scoring["statGroup"] != undefined){
            scoring["statGroup"].map((singleStat) => {
                points = points + parseFloat(myStats[singleStat]) * parseFloat(scoring["points"])
                // console.log( "Stat is "+scoring["points"]+" "+parseFloat(myStats[singleStat]) * parseFloat(scoring["points"]))
            })
        }else{
            if(scoring["stat"] != undefined && myStats[scoring["stat"]] !=  undefined){
                points = points + parseFloat(myStats[scoring["stat"]]) * parseFloat(scoring["points"])
                // console.log( "Stat is "+ scoring["points"]+" "+ parseFloat(myStats[scoring["stat"]]) * parseFloat(scoring["points"]))
            }
        }
    })
    return points
}

export const handleDynamicStats = (stats, target) => {
    let quantity = 0
    stats.keys((col) => {
        if(col >= target){
            quantity = quantity + stats[col]
        }
    })
    return parseInt(quantity)
}


export const dstOppPoints = (rules, stats, myStats) => {
     let points = 0.00
     let yards = 0
    // points = playerPoints(stats, rules)        
    // // summed stats for all players
    // console.log(myStats.pya)
    yards = yards + parseFloat(myStats.pya) + parseFloat(myStats.rya)
    // console.log("----before----" + points)
    points = DFSPointsAllowed(points, myStats.pa, rules)
    // console.log(points)
    points =  yardsAllowed(points, yards, rules)
    return points
}


export const DFSPointsAllowed = (points, PA, scoringSettings) => {
    if(PA == 0){
        points = parseFloat(scoringSettings["st_0_pts_allow"]);
    }
    else if(PA >=1 && PA <=6){
        points = parseFloat(scoringSettings["st_1_6_pts_allow"]);
    }
    else if(PA >=7 && PA <=13){
        points =  parseFloat(scoringSettings["st_7_13_pts_allow"]);
    }
    else if(PA >=14 && PA <=19){
        points = parseFloat(scoringSettings["st_14_19_pts_allow"]);
    }
    else if(PA >=20 && PA <=29){
        points = parseFloat(scoringSettings["st_20_29_pts_allow"]);
    }
    else if(PA >=30 && PA <=39){
        points = parseFloat(scoringSettings["st_30_39_pts_allow"]);
    }
    else if(PA >=35 && PA <=45){
        points = parseFloat(scoringSettings["st_35_45_pts_allow"]);
    }
    else if(PA >= 46){
        points =  parseFloat(scoringSettings["st_46_pts_allow"]);
    }
    return points
}

export const yardsAllowed = (points, yardsAllowed, scoringSettings) => {
    // assign points based on yards category
    if(yardsAllowed == 0){
        points = points + parseFloat(scoringSettings["st_yds_allow"]);
    }
    else if (yardsAllowed <= 100 ){
        points = points + parseFloat(scoringSettings["st_less_100_yds_allow"]);
    }
    else if (yardsAllowed >= 100 && yardsAllowed <= 199 ){
        points = points + parseFloat(scoringSettings["st_100_199_yds"]);
    }
    else if (yardsAllowed >= 200 && yardsAllowed <= 299 ){
        points = points + parseFloat(scoringSettings["st_200_299_yds_allow"]);
    }
    else if (yardsAllowed >= 300 && yardsAllowed <= 349 ){
        points = points + parseFloat(scoringSettings["st_300_349_yds_allow"]);
    }
    else if (yardsAllowed >= 350 && yardsAllowed <= 399 ){
        points = points + parseFloat(scoringSettings["st_350_399_yds_allow"]);
    }
    else if (yardsAllowed >= 400 && yardsAllowed <= 449 ){
        points = points + parseFloat(scoringSettings["st_400_449_yds_allow"]);
    }
    else if (yardsAllowed >= 450 && yardsAllowed <= 499 ){
        points = points + parseFloat(scoringSettings["st_450_499_yds_allow"]);
    }
    else if (yardsAllowed >= 500 && yardsAllowed <= 549 ){
        points = points + parseFloat(scoringSettings["st_500_549_yds_allow"]);
    }
    else if (yardsAllowed >= 550 ){
        points = points + parseFloat(scoringSettings["st_550_yds_allow"]);
    }
    return points
}



/** DFS methods */


export const defaultPointsAllowed = (points, pointsAllowed) => {
    if(pointsAllowed == 0){
        points = points + 10;
    }
    else if(pointsAllowed >=1 && pointsAllowed <=9){
        points = points + 7;
    }
    else if(pointsAllowed >=10 && pointsAllowed <=16){
        points = points + 7;
    }
    else if(pointsAllowed >=17 && pointsAllowed <=25){
        points = points + 0;
    }
    else if(pointsAllowed >=26 && pointsAllowed <=34){
        points = points + 2;
    }
    else if(pointsAllowed >= 35){
        points = points - 4;
    }
    return isNaN(points) ? 0 : points
}


export const defaultDstOppPoints = (PA, points) => {
    let pointsAllowed = 0.00
    // if(stats && rules){
    //     Object.keys(rules).map((score) => {
    //         if(stats[score] != undefined)
    //         pointsAllowed = pointsAllowed + stats[score] * rules[score]
    //     })
    // }
    // pointsAllowed = isNaN(pointsAllowed) ? 0 : pointsAllowed
    return defaultPointsAllowed(points, PA)
}

export const defaultDstMyPoints = (rules, stats) => {
    let points = 0.00
    if(stats && rules){
        Object.keys(DSTScore).map((score) => {
            if(rules[DSTScore[score]] != undefined)
                points = points + stats[DSTScore[score]] * rules[DSTScore[score]]
        })
    }
    return isNaN(points) ? 0 : points
}

export const defaultPlayerPoints = (stats, rules) => {
    let points = 0.00
    if(stats && rules){
        Object.keys(stats).map((stat) => {
            if(rules[stat] != undefined){
                points = points + (stats[stat] * rules[stat])
            }
        })
    }
    return isNaN(parseFloat(points.toFixed(2))) ? 0 : parseFloat(points.toFixed(2))
}


export const defaultDstPoints = (rules, stats) => {
    if(stats == null) return 0
    if(stats.started){
        let myStats = stats.stats.my
        let myPoints = defaultDstMyPoints(rules, myStats)
        let oppPoints = defaultDstOppPoints(stats.pa, myPoints)
        return oppPoints
    }
    return 0
}

export const defaultTotalPoints = (rules, players, type) => {
    let total = 0
    if(players != undefined && rules != undefined){
        if(type == "points"){
            players.map((player) => {
                if(player.pos == "DST"){
                    total = total + defaultDstPoints(rules, player.stats)
                }else{
                    total = total + defaultPlayerPoints(player.stats, rules)
                } 
            })
        }else{
            players.map((player) => {
               total = total + player.rem_time
            })
        }
    }
    return type == "points" ? total.toFixed(2) : parseInt(total)
}

/**
 * Using for DFS leagues scorebaord
 * @param {*} rules 
 * @param {*} players 
 * @param {*} type 
 */
export const defaultTotalScoreboardPoints = (rules, players, type) => {
    let total = 0
    if(players != undefined && rules != undefined){
        if(type == "points"){
            Object.values(players).map((playerByPos) => {
                if(Array.isArray(playerByPos)){
                    playerByPos.map((player) => {
                        if(player.pos == "DST"){
                            total = total + defaultDstPoints(rules, player.stats)
                        }else{
                            total = total + defaultPlayerPoints(player.stats, rules)
                        } 
                    })
                }else{
                    if(playerByPos.pos == "DST"){
                        total = total + defaultDstPoints(rules, playerByPos.stats)
                    }else{
                        total = total + defaultPlayerPoints(playerByPos.stats, rules)
                    } 
                }
            })
        }else{
            Object.values(players).map((playerByPos) => {
                if(Array.isArray(playerByPos)){
                    playerByPos.map((player) => {
                        total = total + player.rem_time
                     })
                }else{
                    total = total + playerByPos.rem_time
                }
            })
          
        }
    }
    return type == "points" ? total.toFixed(2) : parseInt(total)
}