import React, { useState } from "react"
import { prepareArenaOpps } from "../../helpers/Arena/ArenaHelper"
import { parseInjuryIcons } from "../../helpers/common-methods"
import { useColors } from "../../helpers/CustomHooks/useColors"
import { injuryStatusDoubtful, injuryStatusOut, injuryStatusQuestionable } from "../../helpers/icons"
import ArenaPlayerModal from "../../modals/Arena/ArenaPlayerModal"

const PlayerMatchupRow = (props) => {
    const {data, sport} = props

    const Colors = useColors()

    const [playerModal, setPlayerModal] = useState(false)
    const [playerId, setPlayerId] = useState()

    const toggle = () => {
        setPlayerModal(!playerModal)
    }

    const openModal = (playerId) => {
        setPlayerId(playerId)
        toggle()
    }

    return (<div className="d-flex align-items-center mx-3" >  
        <div className="d-flex flex-column py-1" style={{flex: 1, borderBottom: sport == "MLB" && `1px solid ${Colors.darkBgBorder}`}} >
        {data.team1 ?  <div  onClick={() => openModal(data.team1.playerId)} className="d-flex align-items-center pointer">
                <div className="position-relative">
                    {/* <img className="roundImgSmall" style={{marginLeft: 5}} src={data.team1.image} /> */}
                    <div style={{position: "absolute", right: -5, bottom: -5}}  >
                        {parseInjuryIcons(data.team1.injuryStatus)}
                    </div>
                </div>
                <div style={{marginLeft: 5, flexShrink: 1}} >
                    <div className="d-flex align-items-center" >
                        <div style={{fontWeight: 600, marginRight: 5, fontSize: 16}}>{data.team1.fullName}</div>
                        {sport == "NFL" && <div>{data.team1.points}</div>}
                    </div>
                    <div className="bold mb-1" style={{flexShrink: 1, marginTop: 1, color: sport == "MLB" && Colors.colorDangerTransparent_v2, fontWeight: 500, fontSize: 12}}>
                        {prepareArenaOpps(data.team1.opps)}
                    </div>
                </div>
            </div>: (
                <div style={{minHeight: "45px"}} className="d-flex align-items-center px-3" ><div>Empty</div></div>
                )}
        </div>
        <div className="d-flex flex-column mx-3 justify-content-center bold" style={{width: 35, height:35, alignItems: "center", borderRadius:'50%', backgroundColor:Colors.colorSecondary_v3}} ><div style={{color: sport == "MLB" ? Colors.colorPrimary : Colors.colorSecondary}} >{data.pos == "UTIL" ? "UT" : data.pos}</div></div>
        <div className="py-1" style={{flex: 1, borderBottom: sport == "MLB" && `1px solid ${Colors.darkBgBorder}`}} >
            {data.team2 ? <div  onClick={() => openModal(data.team2.playerId)} className="d-flex align-items-center pointer">
                <div className="position-relative">
                    {/* <img className="roundImgSmall" style={{marginLeft: 5}} src={data.team2.image} /> */}
                    <div style={{position: "absolute", right: -5, bottom: -5}}  >
                        {parseInjuryIcons(data.team2.injuryStatus)}
                    </div>
                </div>
                <div style={{marginLeft: 5, flexShrink: 1}} >
                <div className="d-flex align-items-center" >
                    <div style={{fontWeight: 600, marginRight: 5, fontSize: 16}} >{data.team2.fullName}</div>
                    {sport == "NFL" && <div>{data.team2.points}</div>}
                    {/* {data.team2.isStarting ? <PlayerActive w={10} h={10} i={8} /> : <PlayerNonActive w={10} h={10} i={8} />} */}
                </div>
                <div className="bold mb-1" style={{flexShrink: 1, marginTop: 1, color: sport == "MLB" && Colors.colorDangerTransparent_v2, fontSize: 12, fontWeight: 500}}>
                {prepareArenaOpps(data.team2.opps)}
                </div>
                </div>
            </div> : (
                <div style={{minHeight: "45px"}} className="d-flex align-items-center px-3" ><div>Empty</div></div>
            )}
        </div>
        {playerModal && <ArenaPlayerModal
            modal={playerModal}
            playerId={playerId}
            toggle={toggle}
        />}
    </div>)
}

export default PlayerMatchupRow