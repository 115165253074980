

export const leagueSettings = (league_id) => {
    return window.axios.get('/sl/fetchLeagueMeta?league_id='+league_id).then((response) => {
      return Promise.resolve(response.data);
    }).catch(error => {
      return Promise.reject(error);
    });
  }

  export const updateLeagueSettings = (reqData) => {
    return window.axios.post('/sl/updateLeagueSettings', reqData).then((response) => {
      return Promise.resolve(response.data.data);
    }).catch(error => {
      return Promise.reject(error);
    });
  }

  export const updateScoringSettings = (reqData) => {
    return window.axios.post('/sl/updateScoringSettings', reqData).then((response) => {
      return Promise.resolve(response.data.data);
    }).catch(error => {
      return Promise.reject(error);
    });
  }

  export const getChatDetail = (league_id,message_to, type) => {
    return window.axios.get('/chat/chatDetail'+"?league_id="+league_id+"&message_to="+message_to+"&object_type="+type).then((response) => {
      return Promise.resolve(response.data.data);
    }).catch(error => {
      return Promise.reject(error);
    });
  }