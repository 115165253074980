const init = {
    loading: true,
    refreshSideNotification: false
}

export default (state = init, action) => {
    switch (action.type) {
        case "SET_NAV_LOADING":
            return Object.assign({}, state, { loading: action.payload })
        case "REFRESH_SIDE_NOT":
            return Object.assign({}, state, { refreshSideNotification: action.payload })
        default:
            return state;
    }
}