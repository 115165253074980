import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import { STYLES } from '../../../../helpers/stylesHelper';

class TradeblockPositions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allowTrade: true
        }

        this.initiateNegotiations = this.initiateNegotiations.bind(this)
    }
    state = {  }

    componentDidMount() {
        if(this.props.myId == this.props.sender.id) {
            this.setState({
                allowTrade: false
            })
        }
    }

    initiateNegotiations = () => {
        this.props.history.push('/chat/user/'+this.props.sender.id)
    }
    render() { 
        return (
            <Button className='w-100' onClick={this.state.allowTrade ? this.initiateNegotiations : ''} style={this.state.allowTrade ? STYLES.BUTTON_PRIMARY_TRANSPARENT : STYLES.BUTTON_SECONDARY_TRANSPARENT}> Negotiate with {this.props.sender.username}</Button>
         );
    }
}
 
export default withRouter(TradeblockPositions);