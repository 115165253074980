import { errorMessage } from "../../helpers/common-methods";

export const saveInternshipData = (data) => {
	return window.axios
		.post('/career/saveInternshipData', data)
		.then((response) => {
			return Promise.resolve(response.data);
		})
		.catch((error) => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		});
};