import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import NotFound from '../pages/404';
import PropTypes from 'prop-types';
import AuthRoute from './AuthRoute';
import { connect } from 'react-redux';
import { setLoading } from '../actions/loading';
import { initAuthFromExistingToken } from '../actions/auth';
import GuestRoute from './GuestRoute';
import Privacy from "../pages/StaticPages/Privacy"
import LineupImportIndex from "../pages/LineupImport/LineupImportIndex"
import YahooImport from "../pages/LineupImport/YahooImport"
import Favicon from 'react-favicon';
import fav from "../assets/images/favicon.ico"
import ClaimTeam from '../pages/LineupImport/ClaimTeam';
import LeagueImportManager from '../pages/LeagueImportManager';
// login signup umair compnents
import { LoaderCenter } from '../components/Loader';
import Dashboard from '../pages/Dashboard/Dashboard';
import ChatWindow from '../pages/Chat/ChatWindow';
import Mentions from '../pages/Chat/Mentions/Mentions';
import MyBets from '../pages/Sidebet/MyBets/MyBets';
import MyTeam from '../pages/SeasonLong/MyTeam/MyTeam';
import Players from '../pages/SeasonLong/Players/Players';
import KeeperValueChart from '../pages/SeasonLong/Players/KeeperValueChart';
import Profile from '../pages/User/Profile/Profile';
import MatchupMain from '../pages/SeasonLong/Scoreboard/MatchupMain';
import Scoreboard from '../pages/SeasonLong/Scoreboard/Scoreboard';
import LeagueSettings from '../pages/SeasonLong/Settings/LeagueSettings';
import Standings from '../pages/SeasonLong/Standings/Standings';
import Threads from '../pages/Threads/Threads';
import ForgetV2 from '../pages/Auth/ForgetV2';
import LoginV2 from '../pages/Auth/LoginV2';
import SignUpV2 from '../pages/Auth/SignUpV2';
import GidxAddFunds from '../components/Payments/Gidx/GidxAddFunds';
import GidxIdUpload from '../pages/Payments/GidxIdUpload';
import GidxVerifyIdentity from '../pages/Payments/GidxVerifyIdentity';
import Wallet from '../pages/Payments/Wallet';
import CognitoGoogleAuth from '../pages/Auth/CognitoGoogleAuth';
import H2HHome from '../pages/Sidebet/H2HHome/H2HHome';
import LandingPageMob from '../pages/LandingPageMob';
import Media from 'react-media';
import TermsAndService from "../pages/StaticPages/TermsAndService"
import PrivacyPolicy from "../pages/StaticPages/PrivacyPolicy"
import ArenaOnboarding from '../pages/Matchmaker/ArenaOnboarding/ArenaOnboarding';
import ArenaScoreboard from '../pages/Matchmaker/ArenaScoreboard/ArenaScoreboard';
import Internships from '../pages/Internships';
import ArenaSettings from '../pages/Matchmaker/ArenaSettings/ArenaSettings';
import store from '../store';
import { fetchActiveExperience } from '../apis/User/UserAPI';
import { SAVE_APPLICATION_STATUS } from '../apis/Sidebar/LeagueAPI';
import Landing2021 from '../pages/Landing2021';
import ResponsibleGaming from '../pages/StaticPages/ResponsibleGaming';
import MatchmakerRules from '../pages/StaticPages/MatchmakerRules';
import Internship from '../pages/Careers/Internship';
import Landing2022 from '../pages/Landing2022';
import { Helmet } from 'react-helmet';
import ArenaHome from '../pages/Matchmaker/ArenaHome/ArenaHome';
import MLBMatchup from '../components/Matchmaker/ArenaScoreboard/MLBMatchup';
import NFLMatchup from '../components/Matchmaker/ArenaScoreboard/NFLMatchup';
import NewUserOnboarding from '../pages/NewUserOnboarding/NewUserOnboarding';
import AuthRouteFullPage from './AuthRouteFullPage';
import CheckExperience from './CheckExperience';
import SinglePageAuthRoute from './SinglePageAuthRoute';
import Refer from '../pages/SeasonLong/Refer/Refer';
import AcceptInvitation from '../pages/SeasonLong/Refer/AcceptInvitation';
import NewClaimTeamIndex from '../pages/NewClaimTeam/NewClaimTeamIndex';
import NewLanding from '../pages/NewLanding';
import ArenaLeaderboards from '../pages/Leaderboards/ArenaLeaderboards';
import AppDownloadPage from '../pages/AppDownloadPage';
import { getStore, sportNameFromExperience } from '../helpers/common-methods';
import TagManager from 'react-gtm-module'
import NewHome from '../pages/NewHome';
import V3Route from './V3Route';
import { default as V3Wallet } from '../pages/Payments/V3/Wallet';
import {default as ArenaManagers} from '../pages/Matchmaker/Managers/Managers';
import {default as LeagueManagers} from '../pages/SeasonLong/Managers/Managers';
import ArenaSettingsV2 from '../pages/Matchmaker/ArenaSettings/ArenaSettingsV2';
import { SAVE_ARENA_SPORT } from '../apis/Arena/MatchMakerApis';
import { ARENA } from '../helpers/arena_sports_mapping';
import NewArenaOnboarding from '../pages/Matchmaker/ArenaOnboarding/NewArenaOnboarding';
import Welcome from '../pages/Welcome';
import { Landing2023 } from '../pages/Landing2023';
import Partnership from '../pages/Landing2023/Partnership';

const propTypes = {
  setLoading: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  initAuthFromExistingToken: PropTypes.func.isRequired
};

class App extends Component {

  constructor(props) {
		super(props);
		this.state = {
      // experience: "SEASON_LONG",
      // hasLeague: false
		};
	}

  componentDidMount() {
    this.props.initAuthFromExistingToken((authenticated) => {
      if(authenticated){
        if (process.env.NODE_ENV == "production"){
        let { user } = store.getState().auth;
        const tagManagerArgs = {
          dataLayer: {
              userId: user?.id
          },
        }
        TagManager.dataLayer(tagManagerArgs)
      }
      fetchActiveExperience().then((resp) => {
        store.dispatch({type: SAVE_APPLICATION_STATUS, app_status: resp.activeExp})
        store.dispatch({type: "HAS_LEAGUE", payload: resp.hasLeague})
        store.dispatch({type: "ONBOARD_BY_SPORT", payload: resp.onboardBySport})
        
          const activeTeam = JSON.parse(localStorage.getItem("activeTeam"));
          const timeFrame = JSON.parse(localStorage.getItem("timeFrame"));
          // usage of activeTeam, timeFrame from local storage is strictly prohibited
          if (activeTeam) {
            store.dispatch({ type: 'SET_ACTIVE_TEAM', active_team: activeTeam });
          }
          if (timeFrame) {
            store.dispatch({ type: "SAVE_SELECTED_TIMEFRAME", payload: timeFrame });
          }
          
          if(resp.activeLeagueSport) {
            store.dispatch({type: "SAVE_ACTIVE_LEAGUE_SPORT", payload: resp.activeLeagueSport})
            store.dispatch({type: "SAVE_ARENA_SPORT", sport: resp.activeTeamSport})
          }

          // In case of arena expeience we will have a format of "SPORT_ARENA" so we will split it and save the sport
          // if(resp.activeExp.includes("ARENA")){
          //   let sport = resp.activeExp.split("_")[0]
          //   store.dispatch({type: SAVE_ARENA_SPORT, sport: sport})
          // }

          // if(resp.activeExp.includes("ARENA")){
          //   let sport = ARENA.SPORT_FROM_EXPERIENCE[resp.activeExp]
          //   store.dispatch({type: SAVE_ARENA_SPORT, sport: sport})
          //   // sportNameFromExperience(resp.activeExp).then((sport) => {
          //   //   store.dispatch({type: SAVE_ARENA_SPORT, sport: sport})
          //   // })
          // }
          
          this.props.setLoading(false)
        })
      }else{
        this.props.setLoading(false)
      }
    }) 
  }

  render() {
    if (this.props.loading){
      return (
        <LoaderCenter />
      );
    }
    return (
      <div>
        {/* <Favicon url={fav} /> */}
        {/* <Helmet>
            <script async src="https://app.appzi.io/bootstrap/bundle.js?token=yqYWe"></script>
        </Helmet> */}
      <Router>
        <Media query="(max-width: 767px)">
          {matches => matches ? 
            // Mobile routes
            (
            <Switch>
                <Route exact path="/dashboard">
                  <Redirect to="/home" />
                </Route>
                <Route exact path="/arena/home">
                  <Redirect to="/home" />
                </Route>
                <Route exact path="/newHome">
                  <Redirect to="/home" />
                </Route>
                <Route exact path="/expCheck" component={CheckExperience} />
                <Route exact path="/download" component={AppDownloadPage} />
                <Route exact path="/landing2021" component={Landing2021} />
                <Route exact path="/landing2022" component={Landing2022} />
                <GuestRoute exact path="/register" component={SignUpV2} />
                <Route exact path="/privacy" component={PrivacyPolicy} />
                <Route exact path="/responsiblegaming" component={ResponsibleGaming} />
                <Route exact path="/matchmaker-rules" component={MatchmakerRules} />
                <Route exact path="/terms-and-conditions" component={TermsAndService} />
                <GuestRoute exact path="/signin" component={LoginV2} />
                <GuestRoute exact path="/forgot-password" component={ForgetV2} />
                <GuestRoute exact path="/:ref_id/challenge:username" component={AcceptInvitation} />
                <Route exact path="/:ref_id/challengeme/:username" component={AcceptInvitation} />
                <AuthRouteFullPage exact path="/new-user-onboarding" component={NewUserOnboarding} />
                <AuthRouteFullPage exact path="/refer" component={Refer} />
                <Route exact path="/sl/lg/invite/:lgId/:platform/:lgName" component={ClaimTeam} />
                <AuthRouteFullPage exact path="/newClaimTeam" component={NewClaimTeamIndex} />
                <Route exact path="/socialAuth" component={CognitoGoogleAuth} />
                <Route exact path="/internships" component={Internship} />
                <Route exact path="/landing2023" component={Landing2023} />
                <Route exact path="/" component={Landing2023} />
                <Route exact path="/fantasypros" component={NewLanding} />
                <Route exact path="/rotowire" component={NewLanding} />
                <Route exact path="/pr" component={Partnership} />
                <SinglePageAuthRoute exact path="/payments/single/verifyIdentity" component={GidxVerifyIdentity} />
                <SinglePageAuthRoute exact path="/payments/single/idUpload" component={GidxIdUpload} />
                <Route exact path="/leaderboards/:type" component={ArenaLeaderboards} />
                {/* <Route path='/'>
                  <Redirect to="/landing"/>
                </Route> */}
                <AuthRoute exact path="/landing" component={LandingPageMob} exp="MOB" />
                <AuthRoute exact path="/home" component={LandingPageMob} exp="MOB" />
                <Route component={NotFound} />
              </Switch>
              )
            :
              (

                <Switch>
                <Route exact path="/dashboard">
                  <Redirect to="/home" />
                </Route>
                <Route exact path="/arena/home">
                  <Redirect to="/home" />
                </Route>
                <Route exact path="/newHome">
                  <Redirect to="/home" />
                </Route>
                <Route exact path="/expCheck" component={CheckExperience} />
                <Route exact path="/download" component={AppDownloadPage} />
                <Route exact path="/landing2021" component={Landing2021} />
                <Route exact path="/landing2022" component={Landing2022} />
                <GuestRoute exact path="/register" component={SignUpV2} />
                <Route exact path="/privacy" component={PrivacyPolicy} />
                <Route exact path="/responsiblegaming" component={ResponsibleGaming} />
                <Route exact path="/matchmaker-rules" component={MatchmakerRules} />
                <Route exact path="/terms-and-conditions" component={TermsAndService} />
                <GuestRoute exact path="/signin" component={LoginV2} />
                <GuestRoute exact path="/forgot-password" component={ForgetV2} />
                <GuestRoute exact path="/:ref_id/challenge:username" component={AcceptInvitation} />
                <Route exact path="/:ref_id/challengeme/:username" component={AcceptInvitation} />
                <AuthRouteFullPage exact path="/new-user-onboarding" component={NewUserOnboarding} />
                <AuthRouteFullPage exact path="/refer" component={Refer} />
                <Route exact path="/sl/lg/invite/:lgId/:platform/:lgName" component={ClaimTeam} />
                <AuthRouteFullPage exact path="/newClaimTeam" component={NewClaimTeamIndex} />
                <Route exact path="/socialAuth" component={CognitoGoogleAuth} />
                <Route exact path="/internships" component={Internship} />
                <Route exact path="/landing2023" component={Landing2023} />
                <Route exact path="/" component={Landing2023} />
                <Route exact path="/fantasypros" component={NewLanding} />
                <Route exact path="/rotowire" component={NewLanding} />
                <Route exact path="/pr" component={Partnership} />
                <SinglePageAuthRoute exact path="/payments/single/verifyIdentity" component={GidxVerifyIdentity} />
                <SinglePageAuthRoute exact path="/payments/single/idUpload" component={GidxIdUpload} />
                <Route exact path="/leaderboards/:type" component={ArenaLeaderboards} />
                {/* <Route path='/'>
                  <Redirect to="/landing"/>
                </Route> */}
                <AuthRoute exact path="/landing" component={LandingPageMob} exp="MOB" />
                <AuthRoute exact path="/home" component={LandingPageMob} exp="MOB" />
                <Route component={NotFound} />
              </Switch>

            //   <Switch>
            //     <Route exact path="/expCheck" component={CheckExperience} />
            //     <Route exact path="/download" component={AppDownloadPage} />
            //     <Route exact path="/landing2021" component={Landing2021} />
            //     <Route exact path="/landing2022" component={NewLanding} />
            //     <Route exact path="/landing2023" component={Landing2023} />
            //     <Route exact path="/" component={Landing2023} />
            //     <Route exact path="/fantasypros" component={NewLanding} />
            //     <Route exact path="/rotowire" component={NewLanding} />
            //     <Route exact path="/socialAuth" component={CognitoGoogleAuth} />
            //     <Route exact path="/internships" component={Internships} />
            //     <Route exact path="/privacy" component={PrivacyPolicy} />
            //     <Route exact path="/internships" component={Internship} />
            //     <Route exact path="/responsiblegaming" component={ResponsibleGaming} />
            //     <Route exact path="/matchmaker-rules" component={MatchmakerRules} />
            //     <Route exact path="/terms-and-conditions" component={TermsAndService} />
            //     <Route exact path="/:ref_id/challengeme/:username" component={AcceptInvitation} />
            //     <GuestRoute exact path="/:ref_id/challenge:username" component={AcceptInvitation} />
            //     <GuestRoute exact path="/register" component={SignUpV2} />
            //     <GuestRoute exact path="/signin" component={LoginV2} />
            //     <GuestRoute exact path="/forgot-password" component={ForgetV2} />
            //     <AuthRoute exact path="/landing" exp="SL" >
            //       <Redirect to='/dashboard' />
            //     </AuthRoute>
            //     {/* <AuthRoute exact path="/home2" component={LeagueImportManager} /> */}
            //     <AuthRoute exact path="/home" component={Dashboard} exp="SL" />
            //     <Route exact path="/sl/lg/invite/:lgId/:platform/:lgName" component={ClaimTeam} />
            //     <AuthRouteFullPage exact path="/newClaimTeam" component={NewClaimTeamIndex} />
            //     {/* <Route exact path="/privacy" component={PrivacyPolicy} /> */}
            //     {/* <Route exact path="/terms-and-conditions" component={TermsAndService} /> */}
            //     <AuthRoute exact path="/import/lineup" component={LineupImportIndex}  exp="SL" />
            //     <AuthRoute exact path="/import/y" component={YahooImport}  exp="SL" />
            //     <AuthRoute exact path="/dashboard" component={Dashboard}  exp="SL"/> 
            //     {/* <AuthRoute exact path="/my-team" component={MyTeam}   exp="SL"/>  */}
            //     <AuthRoute exact path="/my-team" component={Welcome}   exp="SL"/> 
            //     {/* <AuthRoute exact path="/standings" component={Standings}  exp="SL" /> */}
            //     <AuthRoute exact path="/standings" component={Welcome}  exp="SL" />
            //     {/* <AuthRoute exact path="/scoreboard" component={Scoreboard}  exp="SL" /> */}
            //     <AuthRoute exact path="/scoreboard" component={Welcome}  exp="SL" />
            //     <AuthRoute exact path="/livematchup/:id/:week" component={MatchupMain}  exp="SL" />
            //     {/* <AuthRoute exact path="/players" component={Players}  exp="SL" /> */}
            //     <AuthRoute exact path="/players" component={Welcome}  exp="SL" />
            //     <AuthRoute exact path="/players/keeper-value-chart" component={KeeperValueChart}  exp="SL" />
            //     <AuthRoute exact path="/mentions" component={Mentions} exp="SL" />
            //     <AuthRoute exact path="/threads" component={Threads} exp="SL"/>
            //     <AuthRoute exact path="/settings" component={LeagueSettings} exp="SL"/>
            //     {/* <AuthRoute exact path="/my-bets" component={MyBets}  exp="SL" /> */}
            //     <AuthRoute exact path="/my-bets" component={Welcome}  exp="SL" />
            //     <AuthRoute exact path="/my-bets/h2h-matchup" component={H2HHome}  exp="SL" />
            //     <AuthRoute exact path="/profile" component={Profile}  exp="SL" />
            //     <AuthRoute exact path="/chat/:type/:id" component={ChatWindow}  exp="SL" />
            //     <AuthRoute exact path="/payments/wallet" component={Wallet}  exp="SL" />
            //     <AuthRoute exact path="/payments/verifyIdentity" component={GidxVerifyIdentity}  exp="SL" />
            //     <AuthRoute exact path="/payments/idUpload" component={GidxIdUpload}  exp="SL" />
            //     <AuthRoute exact path="/payments/addFunds" component={GidxAddFunds}  exp="SL" />
            //     <AuthRoute exact path="/refer" component={Refer}  exp="SL" />
            //     <AuthRoute exact path="/league/managers" component={LeagueManagers} exp="SL" />

            //     {/* MATCH MAKER ROUTES */}
            //     <AuthRoute exact path="/arena/home" home={true} component={ArenaHome} exp="ARENA"/>

            //     {/* HACK: Revert this to enable arena completely */}
            //     {/* <AuthRoute exact path='/arena/:sport/onboarding' component={ArenaOnboarding} exp="ARENA"/>
            //     <AuthRoute exact path="/arena/:sport/scoreboard" component={ArenaScoreboard} exp="ARENA"/>
            //     <AuthRoute exact path='/arena/nfl/:matchId/matchup' component={NFLMatchup} exp="ARENA"/>
            //     <AuthRoute exact path='/arena/mlb/:matchId/matchup' component={MLBMatchup} exp="ARENA"/> */}
            //     <AuthRoute exact path='/arena/:sport/settings' component={ArenaSettingsV2} exp="ARENA"/>
            //     <AuthRoute exact path="/payments/mm/verifyIdentity" component={GidxVerifyIdentity} exp="ARENA"/>
            //     <AuthRoute exact path="/payments/mm/idUpload" component={GidxIdUpload} exp="ARENA"/>
            //     {/* <AuthRoute exact path='/arena/:sport/:matchId/matchup' component={ArenaMatchup} /> */}
            //     <AuthRoute exact path='/chat/:type/:id/:matchId' component={ChatWindow}  exp="ARENA"/>
            //     {/* MATCH MAKER ROUTES */} 
            //     <AuthRoute exact path="/newHome" component={NewHome} exp="ARENA" />
            //     <AuthRoute exact path="/wallet" component={V3Wallet} exp="ARENA" />
            //     <AuthRoute exact path="/arena/managers" component={ArenaManagers} exp="ARENA" />
            //     <AuthRoute exact path="/arena/onboarding/:teamId" component={NewArenaOnboarding} exp="ARENA" />
            //     {/* Full page Layouts */}
            //     <AuthRouteFullPage exact path="/new-user-onboarding" component={NewUserOnboarding} />
            //     <SinglePageAuthRoute exact hideHeader={true} path="/payments/single/verifyIdentity" component={GidxVerifyIdentity} />
            //     <SinglePageAuthRoute exact  path="/payments/single/idUpload" component={GidxIdUpload} />
            //     <Route exact path="/leaderboards/:type" component={ArenaLeaderboards} />
            //     <Route exact path='/404' component={NotFound} />
            //     <Route component={NotFound} />
            // </Switch>
            )
            }
        </Media>
      </Router>
      </div>
    );
  }
}

App.propTypes = propTypes;

const mapDispatchToProps = {
  setLoading,
  initAuthFromExistingToken
};

const mapStateToProps = ({ loading, SeasonlongLeague }) => ({ loading: loading});

export default connect(mapStateToProps, mapDispatchToProps)(App);