import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {  Modal, ModalHeader, ModalBody, ModalFooter, Button, UncontrolledCollapse, InputGroupAddon, InputGroup, Input, Badge } from 'reactstrap';
// import { Colors } from '../../../helpers/colors';
import { chatBubble, plusIcon } from '../../../helpers/icons';
import { updateUnreadCount, setOnlineStatus } from '../../../apis/Sidebar/LeagueAPI'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { LoaderCenter } from '../../../components/Loader';
import { userIndex } from '../../../apis/Chat/ChatAPI';
import Divider from '../../../components/subcomponents/Divider';
import { mapping } from '../../../config/ChatConfig';
import { ColorContext } from '../../../context/ColorProvider';

class Managers extends Component {

    // Globals
	// leagueChannel = null;
	// personalPresence = null;

    constructor(props) {
        super(props);

        this.state = {
            users:{},
            status:false,
          };

    }
		static contextType = ColorContext;

    componentDidMount() {
        // if(this.props.leagueId) {
        //     this.initPusher()
        // }
    }

    componentDidUpdate(prevProps) {
        // if (prevProps.leagueId != 0 && prevProps.leagueId !== this.props.leagueId) {
        //     this.initPusher()
        // }
    }

    componentWillUnmount() {
		// if (this.leagueChannel != null) {
		// 	window.pusher.unsubscribe(this.leagueChannel);
		// 	window.pusher.unsubscribe(this.personalPresence);
		// }
	}

    callApi() {
        userIndex({league_id: this.props.leagueId})
        .then(response => {
            this.setState({
                users:response.data.users,
                loading:false
            })
            // this.initPusher(response.data)
        })
        .catch(error => {
            console.log(error)
        })
    }


	/**
     * Initialize pusher everytime league is switched
     * @param {Object} response - API response for ChatIndex
     */
	// initPusher() {
	// 	// this.setState({ data: response, loading: false })
	// 	// subscribe to general league channel to get online/offline statuses
	// 	var leagueChannel = null;

	// 	if (this.props.leagueId != undefined) {
	// 		leagueChannel = window.pusher.subscribe(mapping.lg_channel + this.props.leagueId);

	// 		// Unsubscribe the previous league channel
	// 		if (this.leagueChannel != null) {
	// 			window.pusher.unsubscribe(this.leagueChannel);
	// 		}
	// 		this.leagueChannel = leagueChannel.name;
	// 	}

	// 	leagueChannel.bind('pusher:member_added', (resp) => {
	// 		this.memberAdded(resp);
	// 	});

	// 	leagueChannel.bind('pusher:member_removed', (resp) => {
	// 		this.memberRemoved(resp);
	// 	});

	// 	// Unread messages count logic
	// 	var personalPresence = null;

	// 	// subscribe personal presence channel
	// 	if (this.props.leagueId != undefined) {
	// 		personalPresence = window.pusher.subscribe(mapping.personal_presence + this.props.leagueId + '-' + this.props.current_team.id);

	// 		// Unsubscribe the previous personal presence channel
	// 		if (this.personalPresence != null) {
	// 			window.pusher.unsubscribe(this.personalPresence);
	// 		}
	// 		this.personalPresence = personalPresence.name;
	// 	}

	// 	// Bind and listen to new messages events
	// 	personalPresence.bind(mapping.user_chat_event + this.props.current_team.id, (resp) => {
	// 		// here we will receive broadcasted data on user presence channel
	// 		let users = this.props.users;

	// 		let data = null;
	// 		let type = null;

	// 		if (resp.data.in_thread) {
	// 			//   let newMessages = this.updateThreadRepliesInMainWindow(resp.data.convo_id, resp.data.created_at, resp.data.sender.image)
	// 			//    this.setState({ messages: newMessages })
	// 		} else {
	// 			if (resp.data.in_group) {
    //                 // Ignore this event
	// 			} else {
	// 				users[resp.data.sender_id].unread_messages += 1;
	// 				data = users;
	// 				type = 'user';
    //                 this.updateStatus()
	// 			}

	// 			// Update the redux state
	// 			this.props.updateUnreadCount(data, type);
	// 			// this.updateSidebar();
	// 			// this.setState({ data: data, errors: "" });
	// 		}
	// 	});
	// }

	// // MEMBERS SUBSCRIBE TO CHANNEL SO THAT WE CAN UPDATE ONLINE/OFFLINE STATUS
	// memberAdded(response) {
	// 	let users = this.props.users;
	// 	let dataModified = false;

	// 	if (users[response.id] != undefined) {
	// 		const userIsOnline = users[response.id].lg_online;
	// 		if (!userIsOnline) {
	// 			users[response.id].lg_online = true;
	// 			dataModified = true;
	// 		}
	// 	}

	// 	// If selected user was offline, only then dispatch the event to make it online
	// 	if (dataModified) {
	// 		this.props.setOnlineStatus(users);
    //         this.updateStatus()
	// 	}
	// }

	// // MEMBERS UNSUBCRIBE TO CHANNEL SO THAT WE CAN UPDATE ONLINE/OFFLINE STATUS
	// memberRemoved(response) {
	// 	let users = this.props.users;
	// 	let dataModified = false;

	// 	if (users[response.id] != undefined) {
	// 		const userIsOnline = users[response.id].lg_online;
	// 		if (userIsOnline) {
	// 			users[response.id].lg_online = false;
	// 			dataModified = true;
	// 		}
	// 	}

	// 	// If selected user was online, only then dispatch the event to make it offline
	// 	if (dataModified) {
	// 		this.props.setOnlineStatus(users);
    //         this.updateStatus()
	// 	}
	// }

    onChatChange(id){
        this.props.history.push(`/chat/user/${id}`)
    }

    // updateStatus(){
    //     this.setState({
    //         status: !this.state.status
    //     })
    // }

    render() { 
			const Colors = this.context;
        return (

            <div className='d-flex flex-column flex1 h-100' >
				<h2 className="d-flex align-items-center pl-3" style={{ borderBottom: `0.01em solid ${Colors.darkBgBorder}`, paddingTop: '9px', paddingBottom: '9px' }}>
					Managers
				</h2>
                <PerfectScrollbar className='mx-4 mt-2 d-flex flex-column flex1'>
                {
                this.state.loading ?
                <LoaderCenter />
                :
                    Object.values(this.props.users).map(data => {
                        return (

                            <>
                                <div className='d-flex m  my-2 pointer'>
                                    <div onClick={() => this.onChatChange(data.id)}>
                                        <div className='position-relative'>
                                            {data.unread_messages > 0 && <span className='position-absolute rounded-circle text-dark text-center bold' style={{backgroundColor:Colors.colorPrimary, right:-2, top:-2, height:20, minWidth:20}}>{data.unread_messages}</span>}
                                        </div>
                                        <img className='roundImgMed' src={data.image} style={{borderColor: Colors.colorPrimary}} />
                                    </div>
                                    <div className='mx-2 flex1' onClick={() => this.onChatChange(data.id)}>
                                        <h5>{data.team_name}</h5>
                                        <h6 style={{color:Colors.textSecondary_v3}}>{this.props.activeLeague.league_name}</h6>
                                        </div>
                                        {
                                            data.preRegister 
											&& 
											<div className='my-auto mx-2' style={{zIndex:999}} onClick={() => this.props.history.push('/refer')}>
                                                <p className='px-1 m-0 small rounded text-dark bold' style={{backgroundColor: Colors.referralLeaderboardsPrimary}}>Refer</p>
                                            </div>
                                        }
									<div className='my-auto mx-2' style={{height:30, width:30, color:data.lg_online ? Colors.colorPrimary : Colors.colorSecondary}} onClick={() => this.onChatChange(data.id)}>
										{chatBubble()}
									</div>
                                </div>
                                <Divider/>
                            </>
                        )}
                    )
                }
                </PerfectScrollbar>
            </div>
    )}
}
 
const mapDispatchToProps = { updateUnreadCount, setOnlineStatus }
const mapStateToProps = (state) => ({ leagueId: state.leagueJoin.leagueId, users: state.leagueJoin.users,activeLeague: state.leagueJoin.activeLeague, current_team: state.leagueJoin.current_team})
export default connect(mapStateToProps, mapDispatchToProps) (withRouter(Managers));