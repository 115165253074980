import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import { STYLES } from '../../../../helpers/stylesHelper';


class TradeblockPlayers extends Component {
    constructor(props) {
        super(props);
        this.state ={
            allowTrade: true
        }

        this.initiateTradeSequence = this.initiateTradeSequence.bind(this)
    }
    state = {  }

    componentDidMount(){
        if(this.props.myId == this.props.senderId) {
            this.setState({
                allowTrade: false
            })
        }
    }

    initiateTradeSequence = (playerId, playerName) => {
        this.props.history.push('/chat/user/'+this.props.senderId, { playerId: playerId, playerName: playerName })
    }

    render() { 
        return ( 
            <div className='d-flex flex-column'>
                {
                    this.props.data.map(player => {
                        return (
                            <div className='d-flex flex1 align-items-center my-1'>
                               <div className='px-1'><img src={player.image} height='40px'/></div>
                               <div className='flex1'>{player.playerName}<span className='d-block'> {player.team}</span></div>
                               <div><Button onClick={this.state.allowTrade ? () => this.initiateTradeSequence(player.playerId, player.playerName) : ''} style={this.state.allowTrade ? STYLES.BUTTON_PRIMARY_TRANSPARENT : STYLES.BUTTON_SECONDARY_TRANSPARENT}>Trade</Button></div>
                            </div>
                        )
                    })
                }
            </div>
         );
    }
}
 
export default withRouter(TradeblockPlayers);