import React, { useContext, useEffect, useRef, useState } from 'react';
import MatchMakerHeader from '../../../components/Matchmaker/MatchMaker/MatchMakerHeader';
import './MatchMaker.css';
import { arenaAcceptIcon, arenaRejectIcon } from '../../../helpers/icons';
import MatchMakerLineup from '../../../components/Matchmaker/MatchMaker/MatchMakerLineup';
import TinderCard from 'react-tinder-card';
import { matchMaker, winProbability } from '../../../apis/Arena/MatchMakerApis';
import store from '../../../store';
import { getStore, sportFromExperience } from '../../../helpers/common-methods';
import useDynamicRefs from 'use-dynamic-refs';
import { Button, Modal } from 'reactstrap';
import ArenaMatchModal from '../../../modals/Arena/ArenaMatchModal';
import counterIcon from "../../../assets/images/counter.png"
import AcceptMatchModal from '../../../modals/Arena/AcceptMatchModal';
import RejectMatchModal from '../../../modals/Arena/RejectMatchModal';
import { LoaderCenter } from '../../../components/Loader';
import { currentTimeframe, toggleSideNotifications } from '../../../helpers/Arena/ArenaHelper';
import { connect, useSelector } from 'react-redux';
import { ColorContext } from '../../../context/ColorProvider';
import WinProbabilityModal from '../../../modals/Arena/WinProbabilityModal';
import { parseScoringPeriod } from '../../../helpers/Arena/ArenaHelper';
import { MatchNotificationModal } from '../../../modals/Arena/MatchNotificationModal';
import ProfileModal from '../../../modals/Arena/ProfileModal';

const MatchMaker = (props) => {
	const Colors = useContext(ColorContext);

	const { arenaData, sortId, active_team, isCounter, proposalModal, winProbabStatus, stateSetterCallback, matchModalSuccessCallbackState, matchModalCancelCallbackState } = props
	// console.log({matchModalCancelCallbackState});
	const [currentIndex, setCurrentIndex] = useState(0)
	const [offset, setOffset] = useState(0)
	const [limit, setLimit] = useState(5)
	const [dataEnd, setDataEnd] = useState(false)
	const [loading, setLoading] = useState(true)
	const [direction, setDirection] = useState()
	const [lineups, setLineups] = useState([])
	// const [isCounter, setIsCounter] = useState(false)
	// const [counterMatchId, setCounterMatchId] = useState()
	// const [selectedLineup, setSelectedLineup] = useState()
	const currentIndexRef = useRef(currentIndex)
	const [getRef, setRef] = useDynamicRefs()
	// const [proposeModal, setProposeModal] = useState(false)
	const experience = store.getState().leagueJoin.app_status
	const sport = sportFromExperience(experience, true)
	const [acceptMatchModal, setAcceptMatchModal] = useState(false)
	const [rejectModal, setRejectModal] = useState(false)
	const [profileModal, setProfileModal] = useState(false)
	
	const activeTeam = useSelector(state => state.arenaReducer.active_team)
	// const [winProbabData, setWinProbabData] = useState();
	// const [winProbabStatus, setWinProbabStatus] = useState();
	const [winProbabLoading, setWinProbabLoading] = useState(false);
	const timeFrame = useSelector(state => state.arenaReducer.selectedTimeframe);
	const middleModal = useSelector(state => state.arenaReducer.middleModal);
	const actionMatchData = useSelector(state => state.arenaReducer.actionMatchData);

	useEffect(() => {
		if (sortId) {
			if (currentIndex !== 0) {
				callApi().then(() => {
					fetchWinProbability();
				});
			};
			callApi();
		}
	}, [sortId])

	useEffect(() => {
		if (matchModalSuccessCallbackState) {
			matchModalSuccessCallback();
		} else if (matchModalCancelCallbackState) {
			matchModalCancelcalback();
		};
	}, [matchModalSuccessCallbackState, matchModalCancelCallbackState]);

	const fetchWinProbability = (currIndex = currentIndex, lineups = lineups) => {
		const matchId = lineups[currIndex]?.proposedData?.matchId;
		const myTeamId = activeTeam?.teamId;
		const oppTeamId = lineups[currIndex]?.teamId;
		var qs = {};
		if (matchId) {
			qs = { matchId, timeFrame: timeFrame.key, sport };
		} else {
			qs = { myTeamId, oppTeamId, timeFrame: timeFrame.key, sport }
		}
		setWinProbabLoading(true);
		winProbability(qs).then((resp) => {
			// setWinProbabData(resp.data);
			stateSetterCallback("winProbabData", resp.data);
			// setWinProbabStatus(resp.data.status);
			stateSetterCallback("winProbabStatus", resp.data.status);
			setWinProbabLoading(false);
		}).catch((err) => {
			console.log(err);
		});
	}

	// const createRefs = (length) => {
	// 	// SETTINGS REFS
	// 	childRefs = Array(length).fill(0).map((i) => React.createRef())
	// }
	const callApi = (l = limit, o = offset, indexUpdate = true) => {
		return matchMaker("?limit=" + l + "&offset=" + o + "&sport=" + sport + "&sortId=" + sortId + "&timeFrame=" + currentTimeframe() + "&teamId=" + activeTeam?.teamId, sortId).then((resp) => {
			setOffset(l + o)
			if (resp.length < 5) {
				setDataEnd(true)
			}
			var copy = JSON.parse(JSON.stringify(lineups))
			setLineups([...copy, ...resp])
			if (o == 0) {
				setLoading(false)
			}
			if (currentIndex == 0) {
				fetchWinProbability(currentIndexRef.current, resp);
			};
			// if(indexUpdate){
			// 	updateCurrentIndex([...copy, ...resp].length - 1)
			// }
			// createRefs([...copy, ...resp].length)
			// setLoading(false)
			// console.log("current Index is "+ currentIndex)
			// console.log(getRef("0"))
		})
	}

	const updateCurrentIndex = (val) => {
		setCurrentIndex(val);
		currentIndexRef.current = val;
	};

	// set last direction and decrease current index
	const swiped = (direction, index) => {
		if (currentIndex === (lineups.length - 1)) {
			setTimeout(() => {
				setDirection(null)
				updateCurrentIndex(0)
			}, 1000);
		} else {
			setTimeout(() => {
				setDirection(null)
				updateCurrentIndex(index + 1)
				var difference = (lineups.length - 1) - index
				if (difference == 3 && !dataEnd) {
					// console.log("Calling load more")
					callApi(limit, offset, false)
				}
			}, 1000);
		}
	};

	const outOfFrame = (idx) => {
		// updateCurrentIndex(idx - 1);
		// console.log(`${name} (${idx}) left the screen!`, currentIndexRef.current)
		// handle the case in which go back is pressed before card goes outOfFrame
		// currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
		// TODO: when quickly swipe and restore multiple times the same card,
		// it happens multiple outOfFrame events are queued and the card disappear
		// during latest swipes. Only the last outOfFrame event should be considered valid
	};

	const swipe = (dir) => {
		console.log("swipping card at index " + currentIndex)
		const canSwipe = currentIndex >= 0;
		if (canSwipe && currentIndex < lineups.length) {
			setDirection(dir)
			// setSelectedLineup(lineups[currentIndex])
			getRef(currentIndex.toString()).current.swipe(dir); // Swipe the card!
		}
	}

	const openProposalModal = () => {
		stateSetterCallback("selectedLineup", lineups[currentIndex])
		if (lineups[currentIndex].proposedData) {
			setAcceptMatchModal(!acceptMatchModal)
		} else {
			stateSetterCallback("proposalModal", null, "invert")
		}
	}

	// increase current index and show card
	// const goBack = async () => {
	// 	const canGoBack = currentIndex < lineups.length - 1;
	// 	if (!canGoBack) return;
	// 	const newIndex = currentIndex - 1;
	// 	updateCurrentIndex(newIndex)
	// 	setTimeout(async () => {
	// 		getRef(newIndex.toString()).current.restoreCard();
	// 	}, 300);
	// }

	// EMPTY PROPOSED DATA AFTER ACCEPT/REJECT/COUNTER
	const emptyProposedData = (index) => {
		var lineupsCopy = JSON.parse(JSON.stringify(lineups))
		lineupsCopy[index]["proposedData"] = null
		lineupsCopy[index]["teamStatus"] = "NPR"
		setLineups(lineupsCopy)
	}

	const matchModalSuccessCallback = () => {
		if (isCounter) {
			// swipeUp()
			swipe("up")
			// setCounterMatchId(null)
			stateSetterCallback("counterMatchId", null)
			stateSetterCallback("isCounter", false)
			// setIsCounter(false)
			emptyProposedData(currentIndex)
		} else {
			swipe("right")
		}
		// setProposeModal(false)
		stateSetterCallback("proposalModal", false)
		toggleSideNotifications()
		fetchWinProbability(currentIndex + 1, lineups);
	}

	const matchModalCancelcalback = () => {
		if (isCounter) {
			// setCounterMatchId(null)
			stateSetterCallback("counterMatchId", null)
			// setIsCounter(false)
			stateSetterCallback("isCounter", false)
		}
		// setProposeModal(false)
		stateSetterCallback("proposalModal", false)
	}

	const handleCounterModal = (matchId) => {
		// setCounterMatchId(matchId)
		stateSetterCallback("counterMatchId", matchId)
		// setIsCounter(true)
		stateSetterCallback("isCounter", true);
		// setProposeModal(true)
		stateSetterCallback("proposalModal", true)
		// setSelectedLineup(lineups[currentIndex])
		stateSetterCallback("selectedLineup", lineups[currentIndex])
	}

	// const toggleAcceptModal = (isCancel = false) => {
	// 	if (!isCancel) {
	// 		// REMOVED PROPOSED DATA
	// 		emptyProposedData(currentIndex)
	// 		toggleSideNotifications()
	// 		swipe("right")
	// 	}
	// 	setAcceptMatchModal(!acceptMatchModal)
	// }

	// const toggleRejectModal = (isCancel = false) => {
	// 	if (!isCancel) {
	// 		// REMOVED PROPOSED DATA
	// 		emptyProposedData(currentIndex)
	// 		toggleSideNotifications()
	// 		swipe("left")
	// 	}
	// 	setRejectModal(!rejectModal)
	// }

	const handleSwipeLeft = () => {
		// setSelectedLineup(lineups[currentIndex])
		stateSetterCallback("selectedLineup", lineups[currentIndex])
		if (lineups[currentIndex].proposedData) {
			setRejectModal(!rejectModal)
		} else {
			swipe("left")
			fetchWinProbability(currentIndex + 1, lineups);
		}
	}

	return (
		<>
			<div className="d-flex flex-column flex1 scrollHidden" style={{ position: "relative" }}>
				<div className="d-flex flex-column flex1" style={{ position: "relative" }}>
					<MatchMakerHeader uri={lineups[currentIndex]?.teamImage} />
				</div>
				<div className='mt-3 d-flex w-100' style={{ position: "absolute", left: "30%", right: "30%" }}>
					<div className='d-flex align-items-center px-5 py-2' style={{ background: Colors.schedulePlayerColor, borderRadius: 24, opacity: .8 }}>
						<p className='m-0'><span style={{ color: Colors.colorPrimary, fontSize: 14, fontWeight: 500 }}>Matchup Period</span> <span className='pl-1' style={{ fontSize: 14, fontWeight: 400 }}>{parseScoringPeriod(timeFrame?.start, timeFrame?.end)}</span></p>
					</div>
				</div>
				<div className="d-flex flex-column scrollHidden" style={{ flex: 3, marginTop: "-10%" }}>
					<div className="my-3 px-4">
						<div className='d-flex align-items-center justify-content-between position-relative' >
							<div>
								<h1 className="bold m-0 pointer" style={{ color: Colors.colorPrimary, fontFamily: "Alexandria", fontWeight: 600, fontSize: 32, opacity: 1 }} onClick={() => setProfileModal(true)}>
									{lineups[currentIndex]?.teamName}
								</h1>
								<p className="mb-0 lead">{lineups[currentIndex]?.record}</p>
							</div>
							{!middleModal &&
								<div className='d-flex align-items-center' >
									{/* {lineups[currentIndex]?.proposedData?.isPaid && <div className='mr-3 text-center' >
										<h2 className='greenText bold mb-0' >${lineups[currentIndex]?.proposedData.possibleWin.possibleWin}</h2>
										<p className='mb-0 greenText' >Possible Winnings</p>
									</div>} */}
									{lineups[currentIndex]?.proposedData && lineups[currentIndex]?.proposedData?.counterStatus != "SECOND_COUNTER"
										&& <div
											style={{ backgroundColor: Colors.bgDark, borderRadius: "50%", zIndex: 999 }}
											className="p-3 pointer mr-4"
											onClick={() => handleCounterModal(lineups[currentIndex]?.proposedData?.matchId)}
										>
											<img src={counterIcon} />
										</div>}
									{/* Win Probability Btn */}
									<div className='d-flex justify-content-center align-items-center flex-column py-1 mx-2 pointer' style={{ border: winProbabStatus == "favorite" ? `1px solid ${Colors.mlbPrimaryColor}` : winProbabStatus == "underdog" ? `1px solid ${Colors.colorDanger}` : `1px solid ${Colors.colorWarning}`, color: winProbabStatus == "favorite" ? Colors.mlbPrimaryColor : winProbabStatus == "underdog" ? Colors.colorDanger : Colors.colorWarning, borderRadius: 40, width: "180px", height: "55px", zIndex: 999, fontFamily: "Alexandria", letterSpacing: 2 }} onClick={openProposalModal}>
										{!winProbabLoading ?
											<>
												<h1 className='bold m-0 p-0' style={{ fontSize: 11 }}>{winProbabStatus == "toss up" ? "THIS MATCH IS A" : "YOU ARE THE"}</h1>
												<h1 className='bold m-0 p-0' style={{ fontSize: 22 }}>{winProbabStatus?.toUpperCase()}</h1>
											</>
											:
											<i class="fa fa-refresh fa-spin"></i>
										}
									</div>
									{/* !Win Probability Btn */}
								</div>
							}
						</div>
					</div>
					{/* Card */}
					{loading ? <LoaderCenter /> : (<div className="d-flex flex-grow-1 scrollHidden m-2">
						{/* REJECT */}
						<div
							className="d-flex flex-column align-items-center justify-content-center px-3 pointer"
							style={{ backgroundColor: proposalModal ? Colors.arenaLineupPlayerRow : Colors.colorDanger, borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}
							onClick={proposalModal ? "" : handleSwipeLeft}>
							<div style={{ height: 30, width: 30, aspectRatio: 1, color: 'black' }}>{arenaRejectIcon()}</div>
						</div>
						{/* !REJECT */}

						{/* Lineup */}
						<div className='d-flex flex-column flex1 scrollHidden mx-2 position-relative' >
							{[currentIndex, currentIndex + 1, currentIndex - 1].reverse().map((indexValue, index) => {
								return (
									indexValue >= 0 &&
									indexValue <= lineups.length - 1 && (
										<TinderCard
											// preventSwipe={["up","down", "left", "right"]}
											// swipeRequirementType="position"
											ref={setRef(indexValue.toString())}
											className="d-flex flex-column position-absolute h-100 w-100"
											key={indexValue}
											onSwipe={(dir) => swiped(dir, indexValue)}
											onCardLeftScreen={() => outOfFrame(indexValue)}>
											<MatchMakerLineup
												sport={sport}
												{...lineups[indexValue]}
												cardIndex={indexValue}
												key={indexValue}
												direction={indexValue == currentIndex ? direction : null}
												gameStarted={arenaData.todayGameStarted}
											/>
										</TinderCard>
									)
								);
							})}
						</div>
						{/* !Lineup */}
						{/* ACCEPT */}
						<div
							className="d-flex flex-column align-items-center justify-content-center px-3 pointer"
							style={{ backgroundColor: proposalModal ? Colors.arenaLineupPlayerRow : Colors.mlbPrimaryColor, borderTopRightRadius: 10, borderBottomRightRadius: 10 }}
							onClick={proposalModal ? "" : openProposalModal}>
							<div style={{ height: 30, width: 30, aspectRatio: 1, color: 'black' }}>{arenaAcceptIcon()}</div>
						</div>
						{/* !ACCEPT */}
					</div>)}
					{/* !Card */}
				</div>
			</div>
			<div className={`d-flex flex-column flex1 scrollHidden justify-content-center Modal position-absolute h-100 modalBackdropFilter ${middleModal ? 'Show' : ''}`} style={{ background: "rgba( 0, 0, 0, 0.3 )" }}>
				<div className='d-flex flex-column flex1 scrollHidden justify-content-center' style={{ background: Colors.bgDark, margin: "0px 40px 0px 40px", maxHeight: "600px", borderRadius: 10 }}>
					{/* THIS IS TO SHOW H2H VIEW IN THE MIDDLE WHEN CLICK ON THE NEED ACTION ROW */}
					{middleModal && 
						<MatchNotificationModal matchData={actionMatchData} toggle={() => getStore().dispatch({ type: "OPEN_MIDDLE_MODAL", payload: false })} sport={sport} />
					}
				</div>
			</div>
			<div className={`d-flex flex-column flex1 scrollHidden justify-content-center align-items-center Modal position-absolute h-100 modalBackdropFilter ${profileModal ? 'Show' : ''}`} style={{ background: "rgba( 0, 0, 0, 0.3 )", zIndex:999 }}>
				<div className='d-flex flex-column p-3 scrollHidden justify-content-center' style={{ backgroundColor: "#000", margin: "0px 40px 0px 40px", maxHeight: "600px", borderRadius: 10, width:"500px" }}>
					{ profileModal && <ProfileModal modal={profileModal} toggle={() => setProfileModal(false)} teamId={lineups[currentIndex]?.teamId} /> }
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({ active_team: state.arenaReducer.active_team });
export default connect(mapStateToProps, null)(MatchMaker);
