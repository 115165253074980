import React from 'react'

const MatchMakerHeader = (props) => {
  const {uri} = props
  return (
    <div className='d-flex flex-column flex1 fadeInImage'
      id="arena-header"
      style={{
        backgroundImage: `url(${uri})`,
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
        backgroundSize: "cover",
        backgroundPosition: "center"
      }}
    >
    </div>
  )
}

export default MatchMakerHeader