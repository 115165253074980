import React from 'react'
import { motion } from 'framer-motion/dist/framer-motion';
import ClaimTeamSteps from './CalimTeamSteps';
import { headerVariants, slideInRight, slideOutRight, text24to16, text40to32 } from '../NewUserOnboarding/animationValues';
import { Colors } from '../../helpers/colors';
import { bettleLogo, bettleLogoSvg, importIcon } from '../../helpers/icons';
import freeBadge from "../../assets/images/freeBadge.png"

const ClaimHeaderMobile = (props) => {
    const { step, updateStep, getUserName, hasLeague} = props;
  return (
    <motion.div
        className="text-dark d-flex flex-column d-md-none"
        animate={step == 'default' ? 'mobileExpanded' : 'mobileCollapsed'}
        transition={{ duration: 0.5 }}
        variants={headerVariants}
        style={{ backgroundColor: Colors.colorPrimary }}>
    
            <div className='d-flex flex-column flex1 mx-3'>
                <motion.div 
                    className='my-1' 
                    style={{maxWidth: 100, minWidth:'35%', aspect:1, x:'-100%', opacity:0}}
                    animate={step == 'default' ? 'show' : 'hide'}
                    transition={{ duration: 0.5}}
                    variants={slideInRight}
                >
                <div style={{height:'60px', color: 'black'}}>
                    {bettleLogoSvg()}
                </div>
                </motion.div>
                <motion.div 
                    className="my-4" 
                    style={{x:'-100%', opacity:0}}
                    animate={step == 'default' ? 'show' : 'hide'}
                    initial={true}
                    transition={{ duration: 0.5 }}
                    variants={slideInRight}
                    >
                    <motion.p
                        animate={step == 'default'? 'from' : 'to'}
                        transition={{ duration: 0.5 }}
                        initial={false}
                        variants={text24to16}
                        style={{lineHeight: "40px"}}
                        className="mb-0 bold w-75 alex"
                        >
                        Welcome to the Bettle Universe.
                    </motion.p>
                </motion.div>

                <motion.div 
                    className="my-3" 
                    style={{x:'100%', opacity:0}}
                    animate={step == 'default' ? 'show' : 'hide'} 
                    transition={{ duration: 0.5}} 
                    variants={slideOutRight} >

                        {/* {!hasLeague && <div className="position-absolute" style={{right: "-4%", top: "-50%"}} >
                            <img src={freeBadge} />
                        </div>} */}

                        {!hasLeague && (
                            <div>
                                <p className='mb-0 bold alex' style={{fontSize: "16px"}} >Get some action with</p>
                                <h2 className='bold my-1 alex' style={{fontSize: "32px"}} >$25 of House Money.</h2>
                                <p className='mb-0 alex' style={{fontSize: "16px"}} >It’s risk-free to try, keep the winnings in cash.</p>
                                <p className='alex' >(NO credit card info needed to get started!)</p>
                            </div>
                        )}
                        <div>
                            {hasLeague && <p className='mb-2 alex' style={{fontSize: "20px"}} >Having you as a part of Bettle is a great honor!</p>}
                        </div>
                    <button
                        className="p-2 w-100 my-2 mt-5 d-flex justify-content-center align-items-center"
                        style={{borderRadius: 50, color: Colors.colorPrimary, backgroundColor: Colors.bgDark,  }}
                        onClick={() => updateStep("startClaim")}>
                       {importIcon()}
                       <span className='alex' style={{fontSize: "16px", marginLeft: "5px"}} >CLAIM TEAM</span>
                    </button>

                </motion.div>


                <ClaimTeamSteps {...props} forMob={true} />
                    
            </div>
        </motion.div>
  )
}

export default ClaimHeaderMobile