import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'react-loaders';
import ReactSelect from 'react-select';
import { LoaderCenter } from '../../../components/Loader';
// import { Colors } from '../../../helpers/colors';
import { STYLES } from '../../../helpers/stylesHelper';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { ColorContext } from '../../../context/ColorProvider';

class KeeperValueChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sortFilter:[],
            positionFilter:[],
            fanTeamFilter:[],
            proTeamFilter:[]
        }
    }
    static contextType = ColorContext; 

    componentDidMount() {
        this.setState({
            sortFilter: [
                {
                    value: 'team-asc',
                    label: this.generateSortFilter('Last Name','ascending')
                },
                {
                    value: 'team-desc',
                    label: this.generateSortFilter('Last Name','descending')
                },
                {
                    value: 'cost-asc',
                    label: this.generateSortFilter('Keeper Value','ascending')
                },
                {
                    value: 'cost-desc',
                    label: this.generateSortFilter('Keeper Value','descending')
                }
            ],

            positionFilter: [
                {
                    value: 'position',
                    label: this.generatePositionFilter('Runningback (RB)')
                },
                {
                    value: 'position',
                    label: this.generatePositionFilter('Fallingback (FB)')
                },
                {
                    value: 'position',
                    label: this.generatePositionFilter('Tight End (TE)')
                }
            ],
            fanTeamFilter: [
                {
                    value: 'team',
                    label: this.generateTeamFilter('RTL', 'https://wtaw.com/wp-content/uploads/2022/03/Falcons-678x381.png')
                },
                {
                    value: 'team',
                    label: this.generateTeamFilter('LTR', 'https://wtaw.com/wp-content/uploads/2022/03/Falcons-678x381.png')
                },
                {
                    value: 'team',
                    label: this.generateTeamFilter('ATL', 'https://wtaw.com/wp-content/uploads/2022/03/Falcons-678x381.png')
                },
            ]
        })
    }

    generateSortFilter(value, order) {
        return(
            <div style={{backgroundColor:Colors.bgLight}}>
                <div className='d-flex p-1 m-1 align-items-center' style={{backgroundColor:Colors.bg400, borderRadius:4}}>
                    <i class={`fa ${order == 'ascending' ? 'fa-arrow-up' : 'fa-arrow-down'} mx-2`} aria-hidden="true"></i>
                    <div>
                        {value} <span className='d-block small'>{order.charAt(0).toUpperCase() + order.slice(1)}</span>
                    </div>
                </div>
            </div>
        )
    }

    generatePositionFilter(value, Colors = this.context) {
        return (
            <div style={{backgroundColor:Colors.bgLight}}>
                <div className='px-1 py-2 m-1 align-items-center' style={{backgroundColor:Colors.bg400, borderRadius:4}}>
                    {value}
                </div>
            </div>
        )
    }

    generateTeamFilter(value, teamImage, Colors = this.context) {
        return (
            <div style={{backgroundColor:Colors.bgLight}}>
                <div className='d-flex p-1 m-1 align-items-center' style={{backgroundColor:Colors.colorPrimary, color:'black', borderRadius:4}}>
                    <img className='img-responsive my-1' src={teamImage} width='40px'/> 
                    {value}
                </div>
            </div>
        )
    }

    render() { 
        const Colors = this.context; 
        return ( 
            <>
            {
                // this.state.loading ?
                // <LoaderCenter/>
                // :

                <div class="container d-flex flex-column h-100">
                    {
                        <>
                            <div className='d-flex justify-content-between'>
                                <h1 className='my-2'>Keeper Value Chart</h1>
                                <div className="input-group my-3" style={{border:`0.01em solid ${Colors.darkBgBorder}`, borderRadius:4, maxWidth: '25%', minWidth:274}} id='search-bar'>
                                    <span className="input-group-prepend">
                                        <span className="btn  border-0" >
                                            <i className="fa fa-search" />
                                        </span>
                                    </span>
                                    {/* <input className="form-control border-0 " type="search" id="player-search" placeholder='Search a Player' onChange={this.handleSearch.bind(this)} onBlur={() => this.handleSearchFocus(false)} onFocus={() => this.handleSearchFocus(true)} /> */}
                                </div>
                            </div>
                            {
                                // this.state.resultsVisible &&
                                // <div className='position-absolute' style={{border:`0.01em solid ${Colors.darkBgBorder}`, borderRadius:4, right:20, top: 60, zIndex:999}} >
                                //     <div className='pull-right border-danger' style={{maxWidth:'30%', minWidth:274}} onClick={this.focusSearch}>
                                //         <ul>
                                //         {
                                //             this.state.searchResults.map(player => {
                                //                 return (
                                //                     <li key={player.player_id} style={{cursor:'pointer',backgroundColor:Colors.bgDark, padding:'0.5rem 0.75rem'}} onClick={() => this.preparePlayer(player.player_id)}>
                                //                         {/* <div className=' d-flex m-0' style={{backgroundColor:Colors.bgDark, padding:'0.5rem 0.75rem'}}> */}
                                //                             <img className='img-responsive rounded-circle my-1' src={player.image} height='40px' style={{border:`2px solid ${Colors.colorPrimary}`}}/> 
                                //                             <span className='ml-1 flex1 bold d-inline-block align-middle' style={{cursor:'pointer'}} onClick={() => this.preparePlayer(player.player_id)}>
                                //                                 {player.full_name} 
                                //                                 <br/> 
                                //                                 {player.pos} • {player.team}
                                //                             </span> 
                                //                         {/* </div> */}
                                //                     </li>
                                //                 )
                                //                 this.player(player.full_name,player.team,player.pos,player.image, player.player_id, player.drafted)
                                //             })
                                //         }
                                //         </ul>
                                //     </div>
                                // </div>
                            }
                        </>
                    }
                        <>
                            <div className='d-flex flex1 flex-row justify-content-end align-items-center mb-2'>
                                <ReactSelect options={this.state.sortFilter} value={[{value:'Sort By', label:'Sort By'}]} onChange={''} className=" mx-1 text-white" styles={STYLES.REACT_SELECT_TOGGLES} closeMenuOnSelect={false} isClearable={false} />
                                <ReactSelect options={this.state.positionFilter} value={[{value:'Position', label:'Position'}]} onChange={''} className=" mx-1 text-white" styles={STYLES.REACT_SELECT_TOGGLES} closeMenuOnSelect={false} isClearable={false} />
                                <ReactSelect options={this.state.fanTeamFilter} value={[{value:'Fant. Team', label:'Fant. Team'}]} onChange={''} className=" mx-1 text-white" styles={STYLES.REACT_SELECT_TOGGLES} closeMenuOnSelect={false} isClearable={false} />
                                <ReactSelect options={[]} value={[{value:'Pro. Team', label:'Pro. Team'}]} onChange={''} className=" mx-1 text-white" styles={STYLES.REACT_SELECT} closeMenuOnSelect={false} isClearable={false} />
                            </div>
                        </>

                    <PerfectScrollbar className="mb-4" style={{ overflow:'scroll', backgroundColor: Colors.bgDark, borderRadius: 15,border:`0.01em solid ${Colors.darkBgBorder}` }} id='players-table-container'>
                    
                    
                    <div className="w-100 h-100" id='players-table'>

                    <InfiniteScroll
                        className='w-100 h-100'
                        dataLength = {10}
                        // next = {() => this.handleLoadMore()}
                        hasMore = {false}
                        loader = {<div className='d-flex py-3'><Loader className='m-auto' type="ball-pulse" color={Colors.colorPrimary} /></div>}
                        endMessage = {<div className='text-center py-3'><i class="fa fa-check-circle" aria-hidden="true"></i> No More Players. You are all caught up!</div>}
                        scrollableTarget = {'players-table-container'}
                    >
                        {/* Sticky Table */}
                        <StickyTable className='' stickyHeaderCount={1} leftStickyColumnCount={0} headerZ={3} borderWidth={'0.01em'} borderColor={Colors.darkBgBorder} style={{backgorundColor:Colors.darkColor}}>
                            {/* Header */}
                            <Row style={{backgroundColor:Colors.bgLight,borderBottom:`0.01em solid ${Colors.darkBgBorder}`,backgorundColor:Colors.darkColor}}>
                                <Cell className='p-0'>
                                    <div className='m-0' style={{backgroundColor:Colors.bgDark, padding:'0.5rem 0.75rem'}}>
                                        POS
                                    </div>
                                </Cell>
                                <Cell className='p-0'>
                                    <div className='m-0' style={{backgroundColor:Colors.bgDark, padding:'0.5rem 0.75rem'}}>
                                        Player
                                    </div>
                                </Cell>
                                <Cell className='p-0'>
                                    <div className='m-0' style={{backgroundColor:Colors.bgDark, padding:'0.5rem 0.75rem'}}>
                                        Keeper Cost
                                    </div>
                                </Cell>
                                <Cell className='p-0'>
                                    <div className='m-0' style={{backgroundColor:Colors.bgDark, padding:'0.5rem 0.75rem'}}>
                                        Fant. Team
                                    </div>
                                </Cell>
                                <Cell className='p-0'>
                                    <div className='m-0' style={{backgroundColor:Colors.bgDark, padding:'0.5rem 0.75rem'}}>
                                        NFL Team
                                    </div>
                                </Cell>
                            </Row>
                            {/* !Header */}

                            {/* Table Body */}

                            {/* Single Row */}
                            <Row style={{backgroundColor:Colors.bgLight,borderBottom:`0.01em solid ${Colors.darkBgBorder}`,backgorundColor:Colors.darkColor}}>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    RB
                                </Cell>
                                <Cell className='p-0 bg400 d-flex bold align-items-center p-3'>
                                    <img className='img-responsive rounded-circle my-1' src='https://thespun.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTgzMTI4MzMzNzI2OTE4MDUx/cincinnati-bengals-training-camp.jpg' height='40px' style={{border:`2px solid ${Colors.colorPrimary}`}}/> 
                                    <span className='ml-2'>T. Owens</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <span style={{color:Colors.colorPrimary}}>$50</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <img className='img-responsive rounded-circle my-1' src='https://5.imimg.com/data5/YR/PN/TB/SELLER-61552528/brown-rugby-ball-500x500.jpg' height='40px' style={{border:`2px solid ${Colors.colorPrimary}`}}/> 
                                    <span className='ml-2'>TVBB</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <img className='img-responsive my-1' src='https://wtaw.com/wp-content/uploads/2022/03/Falcons-678x381.png' height='40px'/> 
                                    <span className='ml-2'>ATL</span>
                                </Cell>
                            </Row>
                            {/* !Single Row */}

                            {/* Single Row */}
                            <Row style={{backgroundColor:Colors.bgLight,borderBottom:`0.01em solid ${Colors.darkBgBorder}`,backgorundColor:Colors.darkColor}}>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    RB
                                </Cell>
                                <Cell className='p-0 bg400 d-flex bold align-items-center p-3'>
                                    <img className='img-responsive rounded-circle my-1' src='https://thespun.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTgzMTI4MzMzNzI2OTE4MDUx/cincinnati-bengals-training-camp.jpg' height='40px' style={{border:`2px solid ${Colors.colorPrimary}`}}/> 
                                    <span className='ml-2'>T. Owens</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <span style={{color:Colors.colorPrimary}}>$50</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <img className='img-responsive rounded-circle my-1' src='https://5.imimg.com/data5/YR/PN/TB/SELLER-61552528/brown-rugby-ball-500x500.jpg' height='40px' style={{border:`2px solid ${Colors.colorPrimary}`}}/> 
                                    <span className='ml-2'>TVBB</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <img className='img-responsive my-1' src='https://wtaw.com/wp-content/uploads/2022/03/Falcons-678x381.png' height='40px'/> 
                                    <span className='ml-2'>ATL</span>
                                </Cell>
                            </Row>
                            {/* !Single Row */}

                            {/* Single Row */}
                            <Row style={{backgroundColor:Colors.bgLight,borderBottom:`0.01em solid ${Colors.darkBgBorder}`,backgorundColor:Colors.darkColor}}>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    RB
                                </Cell>
                                <Cell className='p-0 bg400 d-flex bold align-items-center p-3'>
                                    <img className='img-responsive rounded-circle my-1' src='https://thespun.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTgzMTI4MzMzNzI2OTE4MDUx/cincinnati-bengals-training-camp.jpg' height='40px' style={{border:`2px solid ${Colors.colorPrimary}`}}/> 
                                    <span className='ml-2'>T. Owens</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <span style={{color:Colors.colorPrimary}}>$50</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <img className='img-responsive rounded-circle my-1' src='https://5.imimg.com/data5/YR/PN/TB/SELLER-61552528/brown-rugby-ball-500x500.jpg' height='40px' style={{border:`2px solid ${Colors.colorPrimary}`}}/> 
                                    <span className='ml-2'>TVBB</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <img className='img-responsive my-1' src='https://wtaw.com/wp-content/uploads/2022/03/Falcons-678x381.png' height='40px'/> 
                                    <span className='ml-2'>ATL</span>
                                </Cell>
                            </Row>
                            {/* !Single Row */}

                            {/* Single Row */}
                            <Row style={{backgroundColor:Colors.bgLight,borderBottom:`0.01em solid ${Colors.darkBgBorder}`,backgorundColor:Colors.darkColor}}>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    RB
                                </Cell>
                                <Cell className='p-0 bg400 d-flex bold align-items-center p-3'>
                                    <img className='img-responsive rounded-circle my-1' src='https://thespun.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTgzMTI4MzMzNzI2OTE4MDUx/cincinnati-bengals-training-camp.jpg' height='40px' style={{border:`2px solid ${Colors.colorPrimary}`}}/> 
                                    <span className='ml-2'>T. Owens</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <span style={{color:Colors.colorPrimary}}>$50</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <img className='img-responsive rounded-circle my-1' src='https://5.imimg.com/data5/YR/PN/TB/SELLER-61552528/brown-rugby-ball-500x500.jpg' height='40px' style={{border:`2px solid ${Colors.colorPrimary}`}}/> 
                                    <span className='ml-2'>TVBB</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <img className='img-responsive my-1' src='https://wtaw.com/wp-content/uploads/2022/03/Falcons-678x381.png' height='40px'/> 
                                    <span className='ml-2'>ATL</span>
                                </Cell>
                            </Row>
                            {/* !Single Row */}

                            {/* Single Row */}
                            <Row style={{backgroundColor:Colors.bgLight,borderBottom:`0.01em solid ${Colors.darkBgBorder}`,backgorundColor:Colors.darkColor}}>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    RB
                                </Cell>
                                <Cell className='p-0 bg400 d-flex bold align-items-center p-3'>
                                    <img className='img-responsive rounded-circle my-1' src='https://thespun.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTgzMTI4MzMzNzI2OTE4MDUx/cincinnati-bengals-training-camp.jpg' height='40px' style={{border:`2px solid ${Colors.colorPrimary}`}}/> 
                                    <span className='ml-2'>T. Owens</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <span style={{color:Colors.colorPrimary}}>$50</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <img className='img-responsive rounded-circle my-1' src='https://5.imimg.com/data5/YR/PN/TB/SELLER-61552528/brown-rugby-ball-500x500.jpg' height='40px' style={{border:`2px solid ${Colors.colorPrimary}`}}/> 
                                    <span className='ml-2'>TVBB</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <img className='img-responsive my-1' src='https://wtaw.com/wp-content/uploads/2022/03/Falcons-678x381.png' height='40px'/> 
                                    <span className='ml-2'>ATL</span>
                                </Cell>
                            </Row>
                            {/* !Single Row */}

                            {/* Single Row */}
                            <Row style={{backgroundColor:Colors.bgLight,borderBottom:`0.01em solid ${Colors.darkBgBorder}`,backgorundColor:Colors.darkColor}}>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    RB
                                </Cell>
                                <Cell className='p-0 bg400 d-flex bold align-items-center p-3'>
                                    <img className='img-responsive rounded-circle my-1' src='https://thespun.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTgzMTI4MzMzNzI2OTE4MDUx/cincinnati-bengals-training-camp.jpg' height='40px' style={{border:`2px solid ${Colors.colorPrimary}`}}/> 
                                    <span className='ml-2'>T. Owens</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <span style={{color:Colors.colorPrimary}}>$50</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <img className='img-responsive rounded-circle my-1' src='https://5.imimg.com/data5/YR/PN/TB/SELLER-61552528/brown-rugby-ball-500x500.jpg' height='40px' style={{border:`2px solid ${Colors.colorPrimary}`}}/> 
                                    <span className='ml-2'>TVBB</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <img className='img-responsive my-1' src='https://wtaw.com/wp-content/uploads/2022/03/Falcons-678x381.png' height='40px'/> 
                                    <span className='ml-2'>ATL</span>
                                </Cell>
                            </Row>
                            {/* !Single Row */}

                            {/* Single Row */}
                            <Row style={{backgroundColor:Colors.bgLight,borderBottom:`0.01em solid ${Colors.darkBgBorder}`,backgorundColor:Colors.darkColor}}>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    RB
                                </Cell>
                                <Cell className='p-0 bg400 d-flex bold align-items-center p-3'>
                                    <img className='img-responsive rounded-circle my-1' src='https://thespun.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTgzMTI4MzMzNzI2OTE4MDUx/cincinnati-bengals-training-camp.jpg' height='40px' style={{border:`2px solid ${Colors.colorPrimary}`}}/> 
                                    <span className='ml-2'>T. Owens</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <span style={{color:Colors.colorPrimary}}>$50</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <img className='img-responsive rounded-circle my-1' src='https://5.imimg.com/data5/YR/PN/TB/SELLER-61552528/brown-rugby-ball-500x500.jpg' height='40px' style={{border:`2px solid ${Colors.colorPrimary}`}}/> 
                                    <span className='ml-2'>TVBB</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <img className='img-responsive my-1' src='https://wtaw.com/wp-content/uploads/2022/03/Falcons-678x381.png' height='40px'/> 
                                    <span className='ml-2'>ATL</span>
                                </Cell>
                            </Row>
                            {/* !Single Row */}

                            {/* Single Row */}
                            <Row style={{backgroundColor:Colors.bgLight,borderBottom:`0.01em solid ${Colors.darkBgBorder}`,backgorundColor:Colors.darkColor}}>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    RB
                                </Cell>
                                <Cell className='p-0 bg400 d-flex bold align-items-center p-3'>
                                    <img className='img-responsive rounded-circle my-1' src='https://thespun.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTgzMTI4MzMzNzI2OTE4MDUx/cincinnati-bengals-training-camp.jpg' height='40px' style={{border:`2px solid ${Colors.colorPrimary}`}}/> 
                                    <span className='ml-2'>T. Owens</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <span style={{color:Colors.colorPrimary}}>$50</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <img className='img-responsive rounded-circle my-1' src='https://5.imimg.com/data5/YR/PN/TB/SELLER-61552528/brown-rugby-ball-500x500.jpg' height='40px' style={{border:`2px solid ${Colors.colorPrimary}`}}/> 
                                    <span className='ml-2'>TVBB</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <img className='img-responsive my-1' src='https://wtaw.com/wp-content/uploads/2022/03/Falcons-678x381.png' height='40px'/> 
                                    <span className='ml-2'>ATL</span>
                                </Cell>
                            </Row>
                            {/* !Single Row */}

                            {/* Single Row */}
                            <Row style={{backgroundColor:Colors.bgLight,borderBottom:`0.01em solid ${Colors.darkBgBorder}`,backgorundColor:Colors.darkColor}}>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    RB
                                </Cell>
                                <Cell className='p-0 bg400 d-flex bold align-items-center p-3'>
                                    <img className='img-responsive rounded-circle my-1' src='https://thespun.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTgzMTI4MzMzNzI2OTE4MDUx/cincinnati-bengals-training-camp.jpg' height='40px' style={{border:`2px solid ${Colors.colorPrimary}`}}/> 
                                    <span className='ml-2'>T. Owens</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <span style={{color:Colors.colorPrimary}}>$50</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <img className='img-responsive rounded-circle my-1' src='https://5.imimg.com/data5/YR/PN/TB/SELLER-61552528/brown-rugby-ball-500x500.jpg' height='40px' style={{border:`2px solid ${Colors.colorPrimary}`}}/> 
                                    <span className='ml-2'>TVBB</span>
                                </Cell>
                                <Cell className='p-0 bg400 align-middle bold p-3'>
                                    <img className='img-responsive my-1' src='https://wtaw.com/wp-content/uploads/2022/03/Falcons-678x381.png' height='40px'/> 
                                    <span className='ml-2'>ATL</span>
                                </Cell>
                            </Row>
                            {/* !Single Row */}


                            {/* !Table Body */}
                        </StickyTable>
                        </InfiniteScroll> 
                    </div>
                    
                    </PerfectScrollbar>
                </div>
                
    }
            </>
         );
    }
}
 
export default KeeperValueChart;