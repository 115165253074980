import React, { useState, useEffect } from "react";
import { mlbNormalPoints, mlbSinglePlayerPoints} from "../../../../helpers/MLB/Points";
import { mlbColors as Colors } from "../../../../helpers/colors";

const MLBPlayerPointsRow = (props) => {
  const {players1, players2, sc_rules, pos} = props
  const [length, setLength] = useState(0)
  
  useEffect(() => {
    setLength(posLength())
  }, [])
  
  const posLength = () => {
    var l = 0;
    if(players1.length > players2.length){
      l = players1.length
    }else if(players2.length > players1.length){
      l = players2.length
    }else if (players2.length == players1.length){
      return players2.length
    }
    return l
  }
  
  return (
        posLength() != 0 &&
        <>
        <div className="flex1 mr-3 my-0 p-0">
            {
                players1.map(player => {
                    return (
                        <div className="d-flex p-2 mt-1" style={{backgroundColor:Colors.bgLight, borderRadius:8}} key={player.id}>
                            <div className="flex1">
                                <h5>
                                    {player.full_name}
                                </h5>
                                <h6>
                                    {player.team}
                                </h6>
                            </div>
                            <div className="d-flex align-items-center">
                                <h3 style={{color: Colors.colorPrimary}}>
                                    {mlbSinglePlayerPoints( player, sc_rules)}
                                </h3>
                            </div>
                        </div>
                    )
                })
            }
        </div>

        <div className="d-flex flex-column mx-auto">
        {[...Array(posLength()).keys()].map((val) => (
            <div className="flex1 d-flex align-items-center justify-content-center mt-1" key={val}>
                {pos}
            </div>
        ))}
        </div>
        
        <div className="flex1 ml-3 m-0 p-0">
        {
                players2.map(player => {
                    return (

                        <div className="d-flex flex-row-reverse p-2 mt-1" style={{backgroundColor:Colors.bgLight, borderRadius:8}} key={player.id}>
                            <div className="flex1 justify-content-end">
                                <h5 className="text-right">
                                    {player.full_name}
                                </h5>
                                <h6 className="text-right">
                                    {player.team}
                                </h6>
                            </div>
                            <div className="d-flex align-items-center">
                                <h3 style={{color: Colors.colorPrimary}}>
                                    {mlbSinglePlayerPoints( player, sc_rules)}
                                </h3>
                            </div>
                        </div>
                    )
                })
            }
        </div>
        </>

  );
};
export default MLBPlayerPointsRow;
