import React, { Component } from 'react';
import { Badge } from 'reactstrap';
import { Colors } from '../../helpers/colors';
import RichText from './RichText';

class Thread extends Component {
    constructor(props) {
        super(props);
    }
    state = { 
        message:[
            {message:'test message'}
        ]
     }
    render() { 
        return ( 
            <div className='col-4 p-0 d-flex flex-column'  style={{backgroundColor:Colors.bgDark, height: "100vh", borderRight:`0.01em solid ${Colors.darkBgBorder}`}}   id="main">

                {/* Channel Header */}
                <div className="row m-0 px-1 position-sticky d-flex align-items-center" id="thread-header" style={{borderBottom:`0.01em solid ${Colors.darkBgBorder}`}}>
                    {/* Title */}
                    <div className="col-12 py-2 mt-1 m-0 text-white d-flex justify-content-between align-items-center">
                    <h5>Thread</h5><i onClick={this.props.toggle} className="fa fa-close m-2" style={{cursor:'pointer'}}/>
                    </div>
                    {/* !Title */}
                </div>
                {/* !Channel Header */}


            {/* Inbox Body */}
                {/* Messages Container */}
                <div className="flex1 px-4" style={{overflow: "scroll"}} id="messages-container">

                    {/* Single Message */}
                    <div className="d-flex flex-row my-2 text-white" id="single-message">
                        <div className="mx-0 p-0">
                            <img width='40px' className="rounded mr-2" src="http://placehold.jp/3d4070/ffffff/150x150.png?text=DP" />
                        </div>
                        <div className="m-0 flex1 p-0">
                            <h6 className='m-0 bold' >Salman</h6>
                            <p>Hello to the private channel</p>
                        </div>
                    </div>
                    {/* !Single Message */}

                    {/* !Single Day Container */}

                    <RichText placeholder='Reply...'/>
                </div>
                {/* !Messages Container */}
            {/* !Inbox Body */}

        </div>
        );
    }
}
 
export default Thread;