import React from 'react';
import ClaimHeaderWeb from './ClaimHeaderWeb';
import ClaimHeaderMobile from './ClaimHeaderMobile';

const NewClaimHeader = (props) => {
	return (
		<>
			<ClaimHeaderWeb {...props}/>
			<ClaimHeaderMobile {...props} />
		</>
	);
};
export default NewClaimHeader;
