import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Label, Col, FormGroup, Input } from 'reactstrap';
import { hasError, getError } from '../helpers/error';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { BASE_FE_URL } from '../config/app';
import CopyToClipboard from 'react-copy-to-clipboard';
import {sendInviteEmails} from "../actions/Import/api"

class SendInviteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            current_input: 1,
            emails: {"email0": null},
            errors: {},
            inputs: [],
            loading: false,
            copyText: "Copy"
        };
        this.toggle = this.toggle.bind(this);
        // this.increaseInput = this.increaseInput.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    increaseInput() {
        let inputs = this.getInputs(this.state.current_input + 1)
        let emails = {...this.state.emails}
        emails["email"+this.state.current_input] = null
        this.setState({ current_input: this.state.current_input + 1, inputs: inputs, emails: emails })
    }

    getInputs() {
        let inputsCopy = []
        for(var i=0; i<this.state.current_input; i++){
            let name = "email" + i
            inputsCopy.push(
                <FormGroup row>
                    <Col sm={12}>
                        <Input
                            placeholder={"john@gmail.com"}
                            name={name}
                            onChange={e => this.handleInputChange(e)}
                            className={`${hasError(this.state.errors, name) ? 'is-invalid' : ''}`}
                        />
                        {hasError(this.state.errors, name) &&
                            <div className="invalid-feedback">{getError(this.state.errors, name)}</div>
                        }
                    </Col>
                </FormGroup>
            )
        }
        return inputsCopy
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validateForm = () => {
        let isValidate = true
        let errors = []
        // validate emails dynamic fields
        Object.values(this.state.emails).map((val, i) => {
            if (val == null || val == "") {
                errors["email" + i] = "Email is required"
                isValidate = false
            }
            if ( (val != null && val != "") && !this.validateEmail(val)) {
                errors["email" + i] = "Email is invalid"
                isValidate = false
            }
        })
        this.setState({
            errors: errors
        });
        return isValidate
    }

    handleInputChange(e) {
        let emails = this.state.emails
        emails[e.target.name] = e.target.value
        this.setState({
            emails: emails
        });
    }


    handleSubmit(e) {
        e.preventDefault();
        if(this.validateForm()){
            this.setState({ loading: true })
            this.props.sendInviteEmails(this.props.league_id, this.state.emails, this.props.memberId)
                .then(response => this.success())
                .catch(error => console.log(error))
        }
    }

    success() {
        this.toggle()
    }

    getCopyText = () => {
        return encodeURI(BASE_FE_URL+"/sl/lg/invite/"+this.props.lg.lgKey+"/"+this.props.lg.platform+"/"+this.props.lg.name)
    }

    render() {
        return (
            <div className="inline-block" >
                <Button id="leagueInviteBtn" className="green-btn" onClick={this.toggle}> {this.props.invited ? "Reinvite" : "Invite"}</Button>
                <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                <i onClick={() => this.toggle()} class="cui-circle-x icons pointer modalClose"></i>
                    <Form>
                        {/* <ModalHeader toggle={this.toggle}><b>Send Invites</b></ModalHeader> */}
                        <ModalBody>
                        <div className="p-3" >
                            <div className="mb-3" >
                                <h3 className="bold greenText mb-3" >Invite {this.props.lg.name} members</h3>
                                <p className="bold mb-0 lead mt-5" >Invite via link:</p>
                                <p className="mt-2" >Copy the link below and send it to your leaguemates to invite them to your league.</p>
                                <Form style={{ marginBottom: "20px" }} >
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0" style={{flex: 1}} >
                                        <Input type="text" style={{width:"100%"}} readonly value={this.getCopyText()} />
                                    </FormGroup>
                                    <div className="text-right" >
                                        <CopyToClipboard text={this.getCopyText()}
                                            onCopy={() => {this.setState({ copyText:  "Copied!"})}}>
                                            <Button className="green-btn default-btn btn-pill mt-3" >{ this.state.copyText }</Button>
                                        </CopyToClipboard>
                                    </div>
                                </Form>

                            </div>
                            <h4 className="or">OR</h4>
                            <div className="mt-3" >
                                <p className="bold mb-0 lead" >Invite via email:</p>
                                <p className="mt-2" >Enter your leaguemates addresses below to invite them to your league.</p>
                                <Form onSubmit={e => this.handleSubmit(e)}>
                                        {this.getInputs(this.state.current_input).map((input) => {
                                            return input
                                        })}
                                        <div className="text-right" >
                                            <Button onClick={() => this.increaseInput()} className="green-btn" >
                                                <i className="icon-plus icons font-2xl"></i>
                                            </Button>
                                        </div>
                                        
                                    <div className="text-right mt-4" >
                                        <Button className="green-btn btn-pill default-btn" type="submit" >{this.state.loading ? "Processing..." : "Send"}</Button>{' '}
                                    </div>
                                </Form>
                            </div>
                        </div>
                        </ModalBody>
                        {/* <ModalFooter>
                            <Button id="sendInviteButton" className="green-btn" type="submit" >{this.state.loading ? "Sending..." : "Send"}</Button>{' '}
                        </ModalFooter> */}
                    </Form>
                </Modal>
            </div>
        );
    }
}

const mapDispatchToProps = {
    sendInviteEmails
};
export default connect(null, mapDispatchToProps)(withRouter(SendInviteModal));