import React from 'react';
// import { Colors } from '../../../helpers/colors';
import { slideInLeft } from '../animationValues';
import { motion } from "framer-motion/dist/framer-motion"
import { useHistory } from 'react-router-dom';

const UserOnboardedWeb = (props) => {
	const {step, updateStep, Colors} = props
	const history = useHistory()

	return (
		<motion.div
			className="flex-column text-center p-4 d-none d-md-flex scrollHidden"
			animate={step == "allDone" ? 'show' :'hide'}
			transition={{ duration: 0.5 }}
			initial={false}
			variants={slideInLeft}>
 
			{step == "allDone" && <div className='d-flex flex-column flex1 justify-content-center' >
				<h1 className="mb-3 mt-5" style={{ fontFamily: 'Fritch', color: Colors.colorPrimary }}>
					YOU ARE NOW BETTLE READY.
				</h1>
				<p className='lead mb-4' >You may now enter the Arena to prove your squad's dominance.</p>
				<div className='d-flex flex-column col-8 col-centered' >
					<div className="d-flex flex-grow-1">
						<div onClick={
							() => history.push("/arena/home")
						} className="mr-4 flex1 justify-content-center d-flex flex-column pointer" style={{ backgroundColor: Colors.bgLight, flex: 1 }}>
							<h1 className='greenText bold' >ARENA</h1>
						</div>
						<div className="d-flex flex-column" style={{flex: .5}} >
							<div className="mb-2 flex1 justify-content-center d-flex flex-column" style={{ backgroundColor: Colors.bgLight }}>
								<h1 className='greenText bold' >AVATAR</h1>
							</div>
							<div className="mt-2 flex1 justify-content-center d-flex flex-column" style={{ backgroundColor: Colors.bgLight }}>
								<h1 className='greenText bold' >SETTINGS</h1>
							</div>
						</div>
					</div>
				</div>
			</div>}

		</motion.div>
	);
};

export default UserOnboardedWeb;
