import React, { useEffect, useState } from "react"
import { Input } from "reactstrap"
import { useColors } from "../../../helpers/CustomHooks/useColors";
import ErrorText from "../../ErrorText";

const GidxCheckFields = (props) => {
    const {setBillingModal, billingModal, errors, handleInput, handleSaveCheck, formVals, setCardNetworkParent, address, checkVal} = props

    const Colors = useColors();

    const prepareAddressString = () => {
        return address.address+ " " + address.city + " " + address.state.shortname + " " + address.country.shortname  
    }

    return (
        <div>
            <div className="mb-3 mt-3" >
                <h6 className="bold" >Name on Account</h6>
                <Input placeholder="John Doe" value={formVals.displayName} onChange={(e) => handleInput("displayName", e.target.value)} />
                {errors.displayName && <ErrorText msg={errors.displayName} />}
            </div>
            <div className="mb-3 mt-3" >
                <h6 className="bold" >Routing Number</h6>
                <Input placeholder="Routing Number" value={formVals.routing} onChange={(e) => handleInput("routing", e.target.value)} />
                {errors.routing && <ErrorText msg={errors.routing} />}
            </div>
            <div className="mb-3 mt-3" >
                <h6 className="bold" >Account Number</h6>
                <Input placeholder="Account Number" value={formVals.accountNumber} onChange={(e) => handleInput("accountNumber", e.target.value)} />
                {errors.accountNumber && <ErrorText msg={errors.accountNumber} />}
            </div>
            <div className="mb-3 mt-3" >
                <h6 className="bold" >Phone Number</h6>
                <Input placeholder="Phone Number"  value={formVals.phone} onChange={(e) => handleInput("phone", e.target.value)} />
                {errors.phone && <ErrorText msg={errors.phone}/>}
            </div>

            {address ? (
                <div className="mb-3 mt-3 pointer"  >
                    <h6 className="bold" >Billing Address</h6>
                    <div className="p-2 text-center" style={{border: `1px solid ${Colors.darkBgBorder}`}} >
                        <p className="m-0" >{prepareAddressString()}</p>
                    </div>
                </div>
            ) : (
                <div className="mb-3 mt-3 pointer" onClick={() => setBillingModal(!billingModal)}  >
                    <h6 className="bold" >Billing Address</h6>
                    <div className="p-2 text-center" style={{border: `1px solid ${Colors.darkBgBorder}`}} >
                        <i class="fa fa-plus-square-o" style={{fontSize: "1.5rem"}} aria-hidden="true"></i>
                    </div>
                </div>
            )}
            {errors.address && <ErrorText msg={errors.address} />}

            <div className="mb-2 mt-2 d-flex align-items-center" >
                <div className="mb-3 mt-3 mr-2" >
                    <input type="checkbox" checked={checkVal} onChange={(e) => handleSaveCheck(e.target.value)} />
                </div>
                <div className="mb-3 mt-3" >
                    <h6 className="bold" >Remember my eCheck (ACH) Account.</h6>
                </div>
            </div>
        </div>
    )
}

export default GidxCheckFields