const ENDPOINTS = {
    FETCH_SETTINGS: '/sl/fetchLeagueMeta',
    UPDATE_LEAGUE_SETTINGS: '/sl/updateLeagueSettings',
    UPDATE_SCORING_SETTINGS: '/sl/updateScoringSettings',
    TRADE_DATA: "/sl/tradeData",
    VALIDATE_SENDER_ROSTER: "/sl/validateSenderRoster",
    SAVE_TRADE_OFFER: "/sl/saveTradeOffer",
    UPDATE_TRADE_OFFER: "sl/updateTradeOffer",
    DROP_PLAYERS_RECEIVER_SETTLE_TRADE: "/sl/tradeDropPlayersReceiver",
    UPDATE_TRADE_STATE: "sl/updateTradeState",
    UPDATE_ACTIVE_LEAGUE: "user/updateActiveLeague",
    TRADE_BLOCK_DATA: "sl/tradeBlockData"
  };

/**
 * Fetch the trade data
 * @param {object} params - Request params to fetch trade data
 * @returns API response
 */
export const tradeData = (params) => {
    return window.axios.get(ENDPOINTS.TRADE_DATA, {params})
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            return Promise.reject(error);
        });
}

export const validateSenderRoster = (data) => {
    return window.axios.post(ENDPOINTS.VALIDATE_SENDER_ROSTER, data)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}


export const saveTradeOffer = (data) => {
    return window.axios.post(ENDPOINTS.SAVE_TRADE_OFFER, data)
        .then( response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            return Promise.reject(error);
        })
}

export const updateTradeOffer = (data) => {
    return window.axios.post(ENDPOINTS.UPDATE_TRADE_OFFER, data)
        .then( response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const dropPlayersForReceiver = (data) => {
    return window.axios.post(ENDPOINTS.DROP_PLAYERS_RECEIVER_SETTLE_TRADE, data)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const deleteTradeOffer = (data) => {
    return window.axios.post(ENDPOINTS.UPDATE_TRADE_STATE, data)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const tradeBlockData = (params) => {
    return window.axios.get(ENDPOINTS.TRADE_BLOCK_DATA, {params})
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}