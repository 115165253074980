import React, { Component } from "react";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Row, Card, Button } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isLeagueImported } from "../../actions/Import/api"
import { setInForage } from "../../helpers/common-methods";
import queryString from 'query-string';
import { LoaderCenter } from "../../components/Loader";
import bettleLogo from "../../assets/images/logo_trans.png"
import { STYLES } from "../../helpers/stylesHelper";

class ClaimTeam extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            data: [],
            errors: "",
            lg_id: props.match.params.lgId,
            platform: props.match.params.platform,
            lgName: props.match.params.lgName
        }
    }

    componentDidMount(){
        this.props.isLeagueImported(this.state.lg_id).then(async (resp) => {
            this.setState({ data: resp, loading: false })
        }).catch((errors) => {
            this.setState({ errors: errors, loading: false })
        })
    }

    handleWeb = async () => {
        let params = queryString.parse(this.props.location.search)
        await setInForage("claimTeam", {
            lgId :this.state.lg_id,
            platform: this.state.platform,
            lgName: decodeURIComponent(this.state.lgName),
            sbId: params.sb_id ? params.sb_id : null,
            leagueId: this.state.data.leagueId,
            grudgeId: params?.grudgeId ? params?.grudgeId : null
        })
        if(params.refId){
            this.props.history.push('/register/?ref='+params.refId+"&refType=R");
        }else{
            this.props.history.push("/register")
        }
    }

    mobileLink = () => {
        var scheme  = process.env.REACT_APP_ENV == "staging" ? "bettlestaging" : "bettle"
        let params = queryString.parse(this.props.location.search)
        var url = scheme+":///ClaimTeam?lgId="+this.state.lg_id+"&platform="+this.state.platform+
        "&lgName="+decodeURIComponent(this.state.lgName)+ "&leagueId="+this.state.data.leagueId
        if(params?.grudgeId){
            url = url + "&grudgeId=" + params?.grudgeId
        }
        return url
    }

    render() {
        return (
            <Row className="m-0 mt-4" style={{height: "100vh"}} >
                <Col xs="12" md="6" className="col-centered d-flex flex-column flex1 justify-content-center" >
                    <Card>
                        {this.state.loading ? (<LoaderCenter msg={"Fetching details..."} />) : (
                                <div className="p-3 center" >
                                    <img src={bettleLogo} style={{width: "30%"}} />
                                    <h3 className="center" >{this.state.errors}</h3>
                                    <h3 className="my-5" >Launching Bettle</h3>
                                    <div className="row px-3" >
                                        <Button className="col-12 mb-3" onClick={() => {
                                            window.location.href = this.mobileLink()
                                            // window.location.href= "exp://192.168.0.131:19000/--/ClaimTeam?lgId="+this.state.lg_id+"&platform="+this.state.platform+
                                            // "&lgName="+decodeURIComponent(this.state.lgName)+ "&leagueId="+this.state.data.leagueId
                                        }} style={STYLES.BUTTON_PRIMARY_TRANSPARENT} >Claim via Mobile App</Button>
                                        <Button className="col-12" onClick={this.handleWeb} style={STYLES.BUTTON_PRIMARY_TRANSPARENT} >Claim via Web</Button>
                                    </div>
                                </div>
                            )}
                    </Card>
                    <ToastContainer />
                </Col>
            </Row>        )
    }
}

const mapDispatchToProps = {
    isLeagueImported
};
export default connect(null, mapDispatchToProps)(withRouter(ClaimTeam));
