import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { connect, useSelector } from 'react-redux'
import { mmLiveData, setActiveTeam } from '../../../../apis/Arena/MatchMakerApis'
import { ColorContext } from '../../../../context/ColorProvider'
import { countMatches, enableSports } from '../../../../helpers/Arena/ArenaHelper'
import FullWidthButton from '../../../subcomponents/FullWidthButton'
import { motion } from 'framer-motion/dist/framer-motion'

const MatchupsOverview = (props) => {
    const { sport, matchesCount, handleOnboarding, disableOnboarding, activeTab, sportEnabled, setActiveTeam } = props;
    const Colors = useContext(ColorContext)
    const [data, setData] = useState()
    const activeTeam = useSelector(state => state.arenaReducer.active_team)
    const allTeams = useSelector(state => state.arenaReducer.all_teams)
    useEffect(() => {
        if(activeTeam?.teamId){
            mmLiveData("?teamId="+activeTeam.teamId+"&fromSb=true").then((resp) => {
                setData(resp)
            })
        }
    }, [activeTeam])

    const handleAllTeams = () => {
        const allTeamsObject =   {
            "teamId": "all",
            "teamName": "ALL",
            "leagueName": "View All Teams Data",
            "pendingCount": countMatches(allTeams, 'pending'),
            "confirmedMatchCount": countMatches(allTeams, 'confirmed'),
          }
          setActiveTeam(allTeamsObject);
    }

  return (
    <>
        <div className='mt-2' style={{background: `linear-gradient(180deg, rgba(46,46,46,1) 0%, rgba(46,46,46,0) 100%)`, borderRadius:10, paddingLeft:20, paddingRight:20, paddingTop:24, paddingBottom:24}}>
            {/* <div className='d-flex mx-2 align-items-center mb-4'>
                <div className='flex1'>
                    <p className='m-0 bold sub-font letter-spacing-2'>OVERVIEW</p>
                </div> */}
                {/* <div className='flex1 sub-font'>
                    <div className='d-flex justify-content-end'>
                        <p className='my-0 mx-1'>League</p>
                        <p className='my-0 mx-1'>NFL</p>
                        <p className='my-0 mx-1'>All Matches</p>
                    </div>
                </div> */}
                {/* <div className='px-3 bold d-flex align-items-center justify-content-center' style={{background: "#FF0000", borderRadius: "24px", height: "22px", width: "120px"}} >
                    <div>{data?.totalMatches} Matches</div>
                </div> */}
            {/* </div> */}

            <div>
                <div className='d-flex justify-content-between roboto'>
                    {/* TOP INFO SECTION */}
                    {/* Pulse anumation */}
                    <motion.div
                        initial={{ scale: 0.95 }}
                        animate={{ scale: 1 }}
                        transition={{
                            duration: 0.5,
                            repeat: Infinity,
                            repeatType: "reverse",
                            ease: "easeInOut"
                        }}
                        className='bold d-flex align-items-center justify-content-center' style={{background: "#FF0000", borderRadius: "24px"}} >
                        <h4 className='ml-3 my-0' style={{ fontSize: 16, fontWeight: 600, letterSpacing: 1, fontFamily: "Barlow" }}>LIVE </h4>
                        <h4 className='d-flex ml-2 text-center m-1 align-items-center justify-content-center' style={{borderRadius:20, width:27, height:27, background: "#BC0101", fontSize: 17, fontWeight: 700}}>{data?.totalMatches}</h4>
                    </motion.div>
                    {
                        activeTeam.teamName != "ALL" &&
                        <div className='d-flex justify-content-center align-items-center pointer' style={{border:`2px solid ${Colors.colorPrimary}`, borderRadius:'24px', color: Colors.colorPrimary}} onClick={handleAllTeams}>
                            <h5 className='my-1 mx-2' style={{ fontSize: 14, fontWeight: 600, fontFamily: "Barlow" }}>View All Matches</h5>
                        </div>
                    }
                </div>

                <div className='d-flex justify-content-between align-items-center mt-3'>
                    <div className='flex1'>
                        <h5 className='mt-0' style={{ color: Colors.textColorSecondary, fontSize: 14, fontWeight: 500 }}>My Team</h5>
                        <h5 className='m-0' style={{ fontFamily: "Alexandria", fontWeight: 600, fontSize: 20 }}>{activeTeam.teamName ? activeTeam.teamName : "ALL"}</h5>
                    </div>
                    <div className='d-flex'>
                        <div className='flex1'>
                            <h5 className='mt-0 text-center' style={{ color: Colors.textColorSecondary, fontSize: 14, fontWeight: 500, width: "200px" }}>At Stake/Total Pot</h5>
                            <div className='d-flex justify-content-around align-items-center' style={{ background: Colors.sidebarBody_v3, border: `1px solid ${Colors.textSecondary_v3}`, borderRadius: 6, height: "48px", width: "200px" }}>
                                <p className='bold m-0 heading-2' style={{color:Colors.textSecondary_v3}}>${data?.atStake}</p> 
                                <p className='bold m-0 heading-2' style={{color:Colors.textSecondary_v3}}>/</p> 
                                <p className='bold m-0 heading-2' style={{color:Colors.textSecondary_v3}}>${data?.totalPot}</p>
                            </div>
                        </div>
                        <div className='d-flex flex-column flex1 justify-content-end align-items-end' style={{ marginLeft: "16px" }}>
                            <h5 className='mt-0 text-center' style={{ color: Colors.textColorSecondary, fontSize: 14, fontWeight: 500, width: "170px" }}>Winning</h5>
                            <p className='d-flex justify-content-center align-items-center m-0 heading-2' style={{color:Colors.colorPrimary, background: Colors.sidebarBody_v3, border: `1px solid ${Colors.colorPrimary}`, borderRadius: 6, height: "48px", width: "170px"}}>${data?.winnings}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='d-flex my-2 roboto'>
                {/* <div className='d-flex align-items-center justify-content-center flex1 mx-2' style={{backgroundColor:Colors.bgDark_v3, border:`1px solid ${Colors.borderSecondary_v3}`, borderRadius:10}}>
                    <p className='m-0 small-font my-auto bold mr-2'>Total Pot</p> <p className='bold m-0 heading-2' style={{color:Colors.textSecondary_v3}}>${data?.totalPot}</p>
                </div>

                <div className='d-flex align-items-center justify-content-center flex1 mx-2' style={{backgroundColor:Colors.bgDark_v3, border:`1px solid ${Colors.borderSecondary_v3}`, borderRadius:10}}>
                    <p className='m-0 small-font my-auto bold mr-2'>At Stake</p> <p className='bold m-0 heading-2' style={{color:Colors.textSecondary_v3}}>${data?.atStake}</p>
                </div>

                <div className='d-flex align-items-center justify-content-center flex1 mx-2' style={{backgroundColor:Colors.bgDark_v3, border:`1px solid ${Colors.colorPrimary}`, borderRadius:10}}>
                    <p className='m-0 small-font my-auto bold mr-2'>Winning</p> <p className='bold m-0 heading-2' style={{color:Colors.colorPrimary}}>${data?.winnings}</p>
                </div> */}
                {/* <div className='d-flex justify-content-between  flex1'>
                    <div className=''>
                        <div className='bold d-flex align-items-center justify-content-center' style={{background: "#FF0000", borderRadius: "24px"}} >
                            <h4 className='ml-3 my-0'>LIVE </h4>
                            <h4 className='d-flex ml-2 text-center m-1 align-items-center justify-content-center' style={{borderRadius:20, width:30, height:30, background: "#BC0101"}}>{data?.totalMatches}</h4>
                        </div>
                        <div className='px-3 bold d-flex align-items-center justify-content-center' style={{background: "#FF0000", borderRadius: "24px", height: "22px", width: "120px"}} >
                            <div>{data?.totalMatches} Matches</div>
                        </div>
                    </div>
                    <div className='mr-5'>
                        <h5 className='bold' style={{ color: Colors.textColorSecondary }}>At Stake / Total Pot</h5>
                        <div className='d-flex justify-content-around align-items-center flex1' style={{ background: Colors.bgLight, border: `1px solid ${Colors.textSecondary_v3}`, borderRadius: 10 }}>
                            <p className='bold m-0 heading-2' style={{color:Colors.textSecondary_v3}}>${data?.atStake}</p> 
                            <p className='bold m-0 heading-2' style={{color:Colors.textSecondary_v3}}>/</p> 
                            <p className='bold m-0 heading-2' style={{color:Colors.textSecondary_v3}}>${data?.totalPot}</p>
                        </div>
                    </div>
                    <div>
                        <h5 className='bold' style={{ color: Colors.textColorSecondary }}>Winning</h5>
                        <p className='text-center bold m-0 heading-2' style={{color:Colors.colorPrimary, background: Colors.bgLight, border: `1px solid ${Colors.colorPrimary}`, borderRadius: 10}}>${data?.winnings}</p>
                    </div>
                </div> */}
            </div>

            {/* <div className='d-flex align-items-center my-2'>
                <p className='my-0 mx-2'>PMR</p>
                <div className='d-flex flex1 position-relative align-items-center justify-content-end' style={{height:5, backgroundColor:Colors.colorSecondary_v3}}>
                    <div className='podition-absolute' style={{height:10, backgroundColor:Colors.colorPrimary, width:'50%', zIndex:1}}></div>
                </div>
                <p className='my-0 mx-2' style={{color:Colors.colorPrimary}}>500</p>
            </div> */}
        </div>
        {
            (matchesCount?.liveMatchCount == 0 && activeTab == "live") || (matchesCount?.upcomingMatchCount == 0 && activeTab == "active") || (matchesCount?.pastMatchCount == 0 && activeTab == "past") ?
                <div className='m-0 p-4 text-center alex' style={{ background: Colors.bgDark_v3, borderRadius: 10, fontWeight: 600 }}>
                    <h3 style={{ fontWeight: "bolder" }}>No Matchups Scheduled</h3>
                    <h5>Don’t squander your next golden opportunity.</h5>
                    <div className='pt-4'>
                        { sportEnabled && <FullWidthButton className='bold font-lg' sport={sport} text={`ENTER THE ${sport || ''} ARENA`} style={{color:'#000000'}} onClick={() => handleOnboarding(activeTeam.teamId)} disabled={disableOnboarding} borderType={"circular"} />}
                    </div>
                </div>
                :
                ""
        }
    </>
  )
}

const mapDispatchToProps = { setActiveTeam }
export default connect(null, mapDispatchToProps) (MatchupsOverview)