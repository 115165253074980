import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal, ModalBody } from 'reactstrap'
import LineupStarters from '../../components/Matchmaker/ArenaOnboarding/LineupStarters'
import Starters from '../../components/Matchmaker/ArenaOnboarding/Starters'
import { ColorContext } from '../../context/ColorProvider'
import { matchupIcon } from '../../helpers/icons'
import { STYLES } from '../../helpers/stylesHelper'
import ArenaOnboarding from '../../pages/Matchmaker/ArenaOnboarding/ArenaOnboarding'

const ArenaOnboardModal = (props) => {

    const {arenaData, teamId, toggle, isOpen, callBack, sport, updateTeamsLoading} = props
    const Colors = useContext(ColorContext);

    const [stepsData, setStepsData] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setStepsData({teamId, players: arenaData[0]?.players})
        setLoading(false)
    }, [])


    const updateStepsData = (key, value) => {
        setStepsData((currentState) => {
            var dataCopy = JSON.parse(JSON.stringify(currentState))
            dataCopy[key] = value
            return dataCopy
        })
    }
    
    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <i onClick={toggle} class="cui-circle-x icons pointer modalClose" style={{ color: Colors.modalCloseIconColor }} />
            <ModalBody className="p-0 bg400" style={{borderRadius: 15}}>
                {
                    !loading && <LineupStarters
                        teamId={teamId}
                        arenaData={arenaData}
                        stepsData={stepsData}
                        updateStepsData={updateStepsData}
                        callBack={callBack}
                        sport={sport}
                        updateTeamsLoading={updateTeamsLoading}
                         /> 
                }
                {/* {
                {
                    1 :
                    <div>
                        <div className='' style={{height:40, width:40, color:Colors.colorPrimary}}>
                            {matchupIcon()}
                        </div>
                        <h1 style={{color:Colors.colorPrimary}}>Please confirm your lineup</h1>
                        <p>Set your lineup for this week's battle. Lineup can be changed for future matchups.</p>
                        <div className='d-flex justify-content-center'>
                            <Button style={STYLES.BUTTON_PRIMARY_TRANSPARENT_LARGE} onClick={() => updateStep(2)}>Edit Lineup</Button>
                        </div>
                    </div>,
                    2 :
                    // <ArenaOnboarding arenaData={arenaData} teamId={teamId} preSelectedStep={2} />
                    !loading && <LineupStarters
                        teamId={teamId}
                        arenaData={arenaData}
                        stepsData={stepsData}
                        updateStepsData={updateStepsData}
                        step={step}
                        callBack={callBack}
                        decrement={() => updateStep(1)} /> 
                }[step]
                } */}
            </ModalBody>
        </Modal>
    )
}

export default ArenaOnboardModal