import React, { useContext, useEffect, useState } from 'react'
import { arenaHeartIcon, arenaLineupIcon, matchupIcon } from '../../../helpers/icons'
import MatchupNotifications from './Matchups/MatchupNotifications'
import MatchupsOverview from './Matchups/MatchupsOverview'
import ViewMatchups from './Matchups/ViewMatchups'
import { motion } from 'framer-motion/dist/framer-motion'
import { collapseHorizontal, fadeIn, fadeOut } from '../../../pages/NewUserOnboarding/animationValues'
import { scoreboardMeta } from '../../../apis/Matchmaker/ArenaScoreboard/ArenaScoreboard'
import { useHistory, withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { LoaderCenter } from '../../Loader'
import { countMatches } from '../../../helpers/Arena/ArenaHelper'
import { ColorContext } from '../../../context/ColorProvider'
import { connect } from 'react-redux'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Divider from '../../subcomponents/Divider'

const LineupData = (props) => {

    const { activeTeam, location, sport, matchesCount, handleOnboarding, disableOnboarding, showCompletedMatches, rematchStateCallback } = props
    const history = useHistory()
    const Colors = useContext(ColorContext);

    const [activeTab, setActiveTab] = useState('matchups')
    const [matchupTab, setMatchupTab] = useState('live')
    const [sbMeta, setSbMeta] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let locationTab = queryString.parse(location.search).activeTab
        if(locationTab) {
            setActiveTab(locationTab)
        }
    },[location.search])
    
    useEffect(() => {
        if(sbMeta == null) {
            callApi()
        }
    }, [])
    
    useEffect(() => {
        if (showCompletedMatches) {
            setMatchupTab("past");
        }
    }, [showCompletedMatches]);

    const callApi = () => {
        scoreboardMeta("?sport=" + sport)
            .then((resp) => {
                // console.log("resp: ", resp.data);
                setSbMeta(resp);
                setLoading(false)
            });
    }

    const setActiveTabHandler = (tab) => {
        if(tab != activeTab){
            history.push('newHome?activeTab=' + tab)
            setActiveTab(tab)
        }
    }

    const handleMatchupTabChange = (tab) => {
        setMatchupTab(tab)
    }

  return (
    <div className='d-flex flex-column flex1 scrollHidden mt-1'>
        <div className='d-flex flex-column text-center'>
            <div className='flex1 d-flex align-items-center justify-content-between py-3 pointer px-4' style={{borderTopLeftRadius:10, borderTopRightRadius:10, backgroundColor: Colors.arenaContentBg_v3 }} onClick={() => setActiveTabHandler('matchups')}>
                <div className='d-flex align-items-center' style={{height:20}}>
                    {matchupIcon()}
                    <h4 className='m-0 big-text ml-2'>Matchups</h4>
                </div>
                {/* <div className='p-2 d-flex align-items-center' style={{ background: Colors.arenaLineupPlayerRow, borderRadius: 32 }}>
                    <p className='m-0 d-flex justify-content-center align-items-center' style={{ background: Colors.bgDark_v3, borderRadius: 24, fontWeight: "bolder", width: 25, height: 25 }}>{matchesCount?.liveMatchCount ? matchesCount?.liveMatchCount + matchesCount?.upcomingMatchCount : 0}</p>
                    <p className='m-0 bold px-1'>Confirmed</p>
                </div> */}
                {/* <motion.div 
                    className='ml-2 rounded-circle d-flex justify-content-center align-items-center' 
                    style={{color:Colors.textDark_v3, backgroundColor:Colors.colorPrimary, minHeight:25, minWidth:25}}
                    animate={activeTab == 'pending' ? 'expand' : 'collapse'}
                    transition={{duration:0.2}}
                    initial={false}
                    variants={collapseHorizontal}
                >
                    <h5 className='m-0'>{activeTeam.confirmedMatchCount}</h5>
                </motion.div> */}
            </div>
            <div style={{ background: Colors.textDark_v3 }}>
                <div className='mx-2' style={{ borderBottom: `1px solid ${Colors.darkBgBorder}` }}></div>
            </div>
            
            {/* <div className='flex1 d-flex align-items-center justify-content-center py-3 pointer' style={{borderTopLeftRadius:10, borderTopRightRadius:10, backgroundColor: activeTab == 'pending' ? Colors.arenaContentBg_v3 : '' }} onClick={() => setActiveTabHandler('pending')}>
                <div className='mr-2' style={{height:20}}>
                    {arenaHeartIcon()}
                </div>
                <p className='m-0 big-text'>Pending</p>
                <motion.div 
                    className='ml-2 rounded-circle d-flex justify-content-center align-items-center' 
                    style={{color:Colors.textDark_v3, backgroundColor:Colors.colorPrimary, minHeight:25, minWidth:25}}
                    animate={activeTab == 'matchups' ? 'expand' : 'collapse'}
                    transition={{duration:0.2}}
                    initial={false}
                    variants={collapseHorizontal}
                >
                    <h5 className='m-0'>{activeTeam.pendingCount}</h5>
                </motion.div>
            </div> */}
        </div>
        
        <div className='d-flex flex1 flex-column scrollHidden px-2' style={{backgroundColor:Colors.arenaContentBg_v3, borderBottomLeftRadius:10, borderBottomRightRadius:10, overflow:'hidden'}}>
            <motion.div
                className='w-100 d-flex flex-column flex1 scrollHidden'
                animate={activeTab == 'matchups'}
                transition={{ duration: 0.5 }}
                initial={false}
                variants={fadeOut}
            >
                <div className='d-flex text-center bold' style={{height:56}}>
                    <div className='d-flex flex1 pointer align-items-center justify-content-center' style={{borderBottom: matchupTab == 'live' ? `3px solid ${Colors.colorPrimary}` : ''}}  onClick={() => handleMatchupTabChange('live')}>
                        <p className='m-0 big-text' style={{ fontSize: 16 }}>Live</p>
                        <span className='ml-2 rounded-circle d-flex justify-content-center align-items-center' style={{color:Colors.colorLight, backgroundColor:Colors.colorDanger, minHeight:25, minWidth:25}}><h5 className='m-0' style={{ fontWeight: 600 }}>{matchesCount?.liveMatchCount ? matchesCount?.liveMatchCount : 0}</h5></span>
                    </div>
                    <div className='d-flex flex1 pointer align-items-center justify-content-center' style={{borderBottom: matchupTab == 'active' ? `3px solid ${Colors.colorPrimary}` : ''}}  onClick={() => handleMatchupTabChange('active')}>
                        <p className='m-0 big-text' style={{ fontSize: 16 }}>Upcoming</p>
                        <span className='ml-2 rounded-circle d-flex justify-content-center align-items-center' style={{color:Colors.colorLight, backgroundColor:Colors.colorDanger, minHeight:25, minWidth:25}}><h5 className='m-0' style={{ fontWeight: 600 }}>{matchesCount?.upcomingMatchCount ? matchesCount?.upcomingMatchCount : 0}</h5></span>
                    </div>
                    <div className='d-flex flex1 pointer align-items-center justify-content-center' style={{borderBottom: matchupTab == 'past' ? `3px solid ${Colors.colorPrimary}` : ''}} onClick={() => handleMatchupTabChange('past')}>
                        <p className='m-0 big-text' style={{ fontSize: 16 }}>Completed</p>
                        <span className='ml-2 rounded-circle d-flex justify-content-center align-items-center' style={{color: Colors.colorLight, backgroundColor:Colors.colorPrimary, minHeight:25, minWidth:25}}><h5 className='m-0' style={{ fontWeight: 600 }}>{matchesCount?.pastMatchCount ? matchesCount?.pastMatchCount : 0}</h5></span>
                    </div>
                </div>
                <PerfectScrollbar id='arena-body-container'>
                    <MatchupsOverview {...props} activeTab={matchupTab} />
                    { loading ?
                        <LoaderCenter />
                        :
                        <ViewMatchups teamId={activeTeam.teamId} sbMeta={sbMeta} activeTab={matchupTab} sport={sport} matchesCount={matchesCount} rematchStateCallback={rematchStateCallback} /> 
                    }
                </PerfectScrollbar>
            </motion.div>

            {/* <motion.div
                className='w-100'
                animate={activeTab == 'pending' ? 'show' : 'hide'}
                transition={{ delay: 0.5 }}
                initial={false}
                variants={fadeOut}
            >
                <MatchupNotifications activeTeam={activeTeam}/>
            </motion.div> */}
        </div>
    </div>
  )
}

const mapStateToProps = (state) => ({ matchesCount: state.arenaReducer.matchesCount });
export default  connect(mapStateToProps, null)(withRouter(LineupData))