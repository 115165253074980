import React, { useEffect, useState } from "react"
import { Button, Col, Input, Row } from "reactstrap"
import { defaultColors } from '../../../helpers/colors';
import {STYLES} from "../../../helpers/stylesHelper"
import GidxCCFields from "./GidxCCFields";
import GidxCheckFields from "./GidxCheckFields";
import PerfectScrollbar from 'react-perfect-scrollbar'
import { dCashierCreateSession, gidxCompleteSession, gidxWithdrawFunds } from "../../../apis/Gidx/GidxApi";
import { toggleGidxErrorModal, toggleGidxSuccessModal, updateUserStatus } from "../../../helpers/Payments/GidxHelper";
import LoadingOverlay from "react-loading-overlay";
import { useSelector } from "react-redux";
import GidxBillingModal from "../../../modals/GidxModals/GidxBillingModal";
import GidxErrorModal from "../../../modals/GidxModals/GidxErrorModal";
import GidxSuccessModal from "../../../modals/GidxModals/GidxSuccessModal";
import ErrorText from "../../ErrorText";
import { GidxCVVModal } from "../../../modals/GidxModals/GidxCVVModal";
import { useColors } from "../../../helpers/CustomHooks/useColors";
import { normalizedBalance } from "../../../helpers/Arena/ArenaHelper";

var Colors = defaultColors
const styles = {
    amountBox: {
        border: `1px solid ${Colors.darkBgBorder}`,
    },
    amountBoxChecked: {
        border: `1px solid ${Colors.colorPrimary}`,
    },
    pmBox: {
        border: `1px solid ${Colors.darkBgBorder}`,
    },
    pmBoxChecked: {
        border: `1px solid ${Colors.colorPrimary}`,
    },
    storedMethods: {
        border: `1px solid ${Colors.darkBgBorder}`,
        height: "70px",
        marginTop: "10px"
    },
    storedMethodsChecked: {
        border: `1px solid ${Colors.colorPrimary}`,
        height: "70px",
        marginTop: "10px"
    }
}

const GidxAddFunds = (props) => {
    const {toggleFlyout, payType, fromModal, walletData} = props 

    Colors = useColors();
    
    const [billingModal, setBillingModal] = useState(false)
    const [formVals, setFormVals] = useState({
        amount: 25,
        paymentMethod: payType == "deposit" ? "CC" : "ACH",
        cAmount: ""
      })
      const [errors, setErrors] = useState({})
      const [newPM, setNewPM] = useState(true)
      const [existPMId, setExistPMId] = useState()
      const [loading, setLoading] = React.useState(false)
      const [error, setError] = React.useState(null)
      const [cvvModal, setCVVModal] = useState(false)
      const [sessionData, setSessionData] = React.useState(
        {paymentMethods: []}
      )
      const [address, setAddress] = React.useState()
      const [cardNetwork, setCardNetwork] = React.useState()
      const [newBalance, setNewBalance] = useState();
      const [successModal, setSuccessModal] = useState(false);
  
    useEffect(() => {
      setLoading(true)
        var sessionType = payType == "deposit" ? "PAY" : "PAYOUT"
        dCashierCreateSession("?payType="+sessionType).then((resp) => {
          if(resp.paymentMethods.length > 0){
            setNewPM(false)
          }
          setSessionData(resp)
          setLoading(false)
        }).catch(e => {
          // WE NEED TO PARSE ERRORS HERE
          // updateUserStatus(e.data.status).then(() => {
            if(e.message){
              setError(e.message)
            }else{
              setError("Something went wrong. Please try again later.")
            }
          // })
          setLoading(false)
        })
      }, [payType])
  
    const handleInput = (key, val) => {
      var formCopy = {...formVals}
      var newErrors = {...errors}
      if(newErrors[key] != undefined){
        delete newErrors[key]
      }
      if(key == "expireDate"){
        formCopy[key]  = val.length === 3 && !val.includes("/")
            ? `${val.substring(0, 2)}/${val.substring(4)}`
            : val
      }else if(key == 'cAmount'){
        formCopy["amount"] = ""
        delete newErrors["amount"]
        formCopy[key] = val
      }else if(key == 'amount'){
        formCopy["cAmount"] = ""
        formCopy[key] = val
      }else{
        formCopy[key] = val
      }
      if(key == "paymentMethod" && formVals.paymentMethod != val){
        // RESET STATES
        setErrors({})
        delete formCopy["save"]
      }else{
        setErrors(newErrors)
      }
      setFormVals(formCopy)
    }
  
    const handleSaveCheck = (val) => {
      var formCopy = {...formVals}
      formCopy["save"] = val
      setFormVals(formCopy)
    }
  
    const handleSubmit = () => {
      // WE NOW HAVE ALL THE DATA PREPARED IN FORM VALS
      // WE JUST NEED TO DYNAMICALLY ATTACH NEW OR EXISTING PM DATA
      if(validateForm()){
        setLoading(true)
        // CHECK IF USER TRYING TO ADD NEW PM
        if(newPM){
          formVals["address"] = address
          formVals["newPM"] = true
          formVals["network"] = cardNetwork
          if(formVals.paymentMethod == "CC"){
            formVals["cardNumber"] = formVals.cardNumber.replace(/\s/gm, '')
          }
        }else{
          // EXISTING PM
          formVals["newPM"] = false
          // ADD EXISTING PM ID IN REQUEST
          formVals["pmId"] = existPMId.toString()
        }
        // SEND REQUEST NOW
        formVals["bSessionId"] = sessionData.id
        formVals["address"] = address
        var gidxApiFunc = null
        if(payType == "deposit"){
          gidxApiFunc = gidxCompleteSession
        }else if(payType == "credit"){
          gidxApiFunc = gidxWithdrawFunds
        }
        gidxApiFunc(formVals).then((resp) => {
          if(resp.status == "Complete"){
            // toggleGidxSuccessModal({msg: `Congratulations! You have deposited $${getAmount()}`, path: !fromModal ? "/dashboard" : ""})
            toggleFlyout()
            var balance = normalizedBalance(sessionData?.total + getAmount());
            if (!walletData?.depositClaimed && getAmount() <= 100) {
              balance = balance + getAmount();
            };
            setNewBalance(balance);
            setSuccessModal(true);
          }else{
            if(resp.status == "Pending"){
              toggleGidxErrorModal({msg: "Your transaction is pending, we will notify you in a bit", path: !fromModal ? "/payments/wallet" : ""})
              setSuccessModal(true);
            }else if(resp.status == "Processing"){
              toggleGidxErrorModal({msg: "Your transaction is processing, we will add the funds in your bettle wallet shortly", path: !fromModal ? "/payments/wallet" : ""})
              setSuccessModal(true);
            }else if(resp.status == "Ineligible"){
              toggleGidxErrorModal({msg: "Your transaction is Ineligible", path: !fromModal ? "/payments/wallet" : ""})
            }else{
              toggleGidxErrorModal({msg: "Your transaction has been failed", path: !fromModal ? "/payments/wallet" : ""})
            }
            toggleFlyout()
          }
          setLoading(false)
        }).catch((err) => {
            toggleGidxErrorModal({msg: err.message, path: !fromModal ? "/payments/wallet" : ""})
            setLoading(false)
        })
      }
    }
  
    const validateForm = () => {
      var newErrors = {}
      if((formVals.amount == undefined || formVals.amount == '' || formVals.amount == null) &&
      (formVals.cAmount == undefined || formVals.cAmount == '' || formVals.cAmount == null)){
        newErrors["amount"] = "Please select an amount"
      }
      if(formVals.paymentMethod == undefined || formVals.paymentMethod == '' || formVals.paymentMethod == null){
        newErrors["paymentMethod"] = "Please select payment method"
      }
  
      if(newPM){
        // ONLY RUNS WHEN WE HAVE NEW PM
        if(formVals.paymentMethod == "CC"){
          if(formVals.cardNumber == undefined || formVals.cardNumber == '' || formVals.cardNumber == null){
            newErrors["cardNumber"] = "Please enter card number"
          }
          if(formVals.expireDate == undefined || formVals.expireDate == '' || formVals.expireDate == null){
            newErrors["expireDate"] = "Please enter expiry date"
          }
          if(formVals.securityCode == undefined || formVals.securityCode == '' || formVals.securityCode == null){
            newErrors["securityCode"] = "Please enter security code"
          }
          if(formVals.displayName == undefined || formVals.displayName == '' || formVals.displayName == null){
            newErrors["displayName"] = "Please enter card holder name"
          }
          if(address == undefined || address == '' || address == null){
            newErrors["address"] = "Please enter billing address"
          }
        }
        // ACH VALIDATIONS
        if(formVals.paymentMethod == "ACH"){
          if(formVals.routing == undefined || formVals.routing == '' || formVals.routing == null){
            newErrors["routing"] = "Please enter routing number"
          }
          if(formVals.accountNumber == undefined || formVals.accountNumber == '' || formVals.accountNumber == null){
            newErrors["accountNumber"] = "Please enter account number"
          }
          if(formVals.phone == undefined || formVals.phone == '' || formVals.phone == null){
            newErrors["phone"] = "Please enter phone number"
          }
          if(formVals.displayName == undefined || formVals.displayName == '' || formVals.displayName == null){
            newErrors["displayName"] = "Please enter card holder name"
          }
          if(address == undefined || address == '' || address == null){
            newErrors["address"] = "Please enter billing address"
          }
        }
      }else{
        // EXISTING PM
        if(existPMId != 0 && (existPMId == undefined || existPMId == '' || existPMId == null)){
          newErrors["existPM"] = "Please select payment method"
        }
      }
      if(Object.keys(newErrors).length > 0){
        setErrors(newErrors)
        return false
      }
      return true
    }
  
    const handleExistingPM = (id, type) => {
      var newErr = {...errors}
      // var newVals = {...formVals}
      delete newErr["existPM"]
      // delete newVals["securityCode"]
      setErrors(newErr)
      setFormVals(formVals)
      if(type == "CC"){
        setCVVModal(true)
      }
      setExistPMId(id)
    }
  
    const getAmount = () => {
      if(formVals.cAmount){
        return formVals.cAmount
      }
      return formVals.amount
    }

    return(
      <LoadingOverlay
          className='d-flex flex-column flex1'
          active={loading}
          spinner
          text='Processing...'
          fadeSpeed={500}
          styles={{
          overlay: (base) => ({
              ...base,
              background: 'rgba(0,0,0,0.5)',
          }),
          wrapper: {
              overflow: "hidden"
          }
          }}
      >
    <div className="d-flex flex-column flex1 scrollHidden" >
        {error ? (<div className="mx-3" ><ErrorText msg={error} /></div>) : (<PerfectScrollbar className="p-3" >
        <h6 className="bold" >Select Amount (USD)</h6>
        <div className="d-flex align-items-center justify-content-between flexWrap">
            <div className={`pt-2 pb-2 pl-3 pr-3 mr-2 mt-2`} style={formVals.amount == 25 ? styles.amountBoxChecked : styles.amountBox} onClick={() => handleInput("amount", 25)} >
                <span className="bold" >$25</span>
            </div>
            <div className={`pt-2 pb-2 pl-3 pr-3 mr-2 mt-2`} style={formVals.amount == 50 ? styles.amountBoxChecked : styles.amountBox} onClick={() => handleInput("amount", 50)} >
                <span className="bold">$50</span>
            </div>
            <div className={`pt-2 pb-2 pl-3 pr-3 mr-2 mt-2`} style={formVals.amount == 75 ? styles.amountBoxChecked : styles.amountBox} onClick={() => handleInput("amount", 75)} >
                <span className="bold">$75</span>
            </div>
            <div className={`pt-2 pb-2 pl-3 pr-3 mr-2 mt-2`} style={formVals.amount == 100 ? styles.amountBoxChecked : styles.amountBox} onClick={() => handleInput("amount", 100)} >
                <span className="bold">$100</span>
            </div>
            <div className={`pt-2 pb-2 pl-3 pr-3 mr-2 mt-2`} style={formVals.amount == 125 ? styles.amountBoxChecked : styles.amountBox} onClick={() => handleInput("amount", 125)} >
                <span className="bold">$125</span>
            </div>
        </div>
        <h6 className="mb-3 mt-3 bold" >Other Amount (USD)</h6>
        <Input value={formVals.cAmount} placeholder="0.00" onChange={(e) => handleInput("cAmount", e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'))} />
        {errors.cAmount && <ErrorText msg={errors.cAmount} />}
        {errors.amount && <ErrorText msg={errors.amount} />}

        {walletData?.depositClaimed == false && 
          <div className="mt-3" style={{ border: `2px solid ${Colors.colorWarningLight}`, borderRadius: 8 }}>
            <div className="d-flex align-items-center p-2" style={{ background: Colors.colorWarningLight }}>
                <p className="m-0" style={{fontSize: 20, fontWeight: "bold", color: Colors.colorDark}}>Deposit Match</p>
                <p className="m-0 pl-1" style={{fontSize: 14, color: Colors.colorPrimaryTransparent_v3}}>Up to $100</p>
            </div>
            <p className="m-0 bold p-2" style={{ fontSize: 20 }}>Deposit <span style={{ color: Colors.colorWarning }}>${getAmount()}</span> , Get <span style={{ color: Colors.colorWarning }}>${getAmount() * 2}</span></p>
          </div>}

        {!loading && (<div>

        <h6 className="mb-3 mt-3" >Payment Details</h6>

          {newPM && <div>
            {/* <div className="d-flex align-items-center" >
                <div onClick={() => handleInput("paymentMethod", "CC")} style={formVals.paymentMethod == "CC" ? styles.pmBoxChecked : styles.pmBox} className="d-flex flex-column flex1 p-2 m-1 align-items-center justify-content-center">
                    <i class="fa fa-credit-card mb-2" aria-hidden="true" style={{fontSize: "1.5rem"}} ></i>
                    <p className="mb-0" >Credit</p>
                </div>
                <div onClick={() => handleInput("paymentMethod", "Paypal")} style={formVals.paymentMethod == "Paypal" ? styles.pmBoxChecked : styles.pmBox} className="d-flex flex-column flex1 p-2 m-1 align-items-center justify-content-center">
                    <i class="fa fa-paypal mb-2" aria-hidden="true" style={{fontSize: "1.5rem"}}></i>
                    <p className="mb-0" >Paypal</p>
                </div>
                <div onClick={() => handleInput("paymentMethod", "ACH")} style={formVals.paymentMethod == "ACH" ? styles.pmBoxChecked : styles.pmBox} className="d-flex flex-column flex1 p-2 m-1 align-items-center justify-content-center">
                    <i class="fa fa-university mb-2" aria-hidden="true" style={{fontSize: "1.5rem"}}></i>
                    <p className="mb-0" >Check</p>
                </div>
            </div> */}
            {errors.paymentMethod && <ErrorText msg={errors.paymentMethod} />}
            <div className="mt-3" >
                {formVals.paymentMethod == "CC" && <GidxCCFields
                    setBillingModal={setBillingModal}
                    billingModal={billingModal}
                    formVals={formVals}
                    checkVal={formVals.save}
                    handleInput={handleInput}
                    setCardNetworkParent={setCardNetwork}
                    handleSaveCheck={handleSaveCheck}
                    errors={errors}
                    address={address}
                />}
                {formVals.paymentMethod == "ACH" && <GidxCheckFields
                    setBillingModal={setBillingModal}
                    billingModal={billingModal}
                    formVals={formVals}
                    checkVal={formVals.save}
                    handleInput={handleInput}
                    setCardNetworkParent={setCardNetwork}
                    handleSaveCheck={handleSaveCheck}
                    errors={errors}
                    address={address}
                />}
            </div>
          </div>}

            {!newPM && (
              <div>
                {payType == "deposit" && sessionData.paymentMethods.map((pm) => (
                  <div onClick={() => handleExistingPM(pm.id, pm.type)} className="d-flex flexGrow align-items-center p-2 pointer" style={existPMId == pm.id ? styles.storedMethodsChecked : styles.storedMethods} >
                      {pm.type == "Paypal" && <i class="fa fa-paypal mb-2" aria-hidden="true" style={{fontSize: "2rem"}} />}
                      {pm.type != "Paypal" && <i class="fa fa-credit-card mb-2" aria-hidden="true" style={{fontSize: "2rem"}} />}
                      <div className="ml-3" >
                          <p className="mb-1 bold" >{pm.display}</p>
                          <p className="m-0" >{pm.expiry}</p>
                      </div>
                  </div>
                ))}
                {payType == "credit" && sessionData.paymentMethods.map((pm) => (
                  pm.type == "ACH" && <div onClick={() => handleExistingPM(pm.id, pm.type)} className="d-flex flexGrow align-items-center p-2 pointer" style={existPMId == pm.id ? styles.storedMethodsChecked : styles.storedMethods} >
                      {pm.type == "Paypal" && <i class="fa fa-paypal mb-2" aria-hidden="true" style={{fontSize: "2rem"}} />}
                      {pm.type != "Paypal" && <i class="fa fa-credit-card mb-2" aria-hidden="true" style={{fontSize: "2rem"}} />}
                      <div className="ml-3" >
                          <p className="mb-1 bold" >{pm.display}</p>
                          <p className="m-0" >{pm.expiry}</p>
                      </div>
                  </div>
                ))}
                <div onClick={() => setNewPM(true)} className="d-flex flexGrow align-items-center p-2 pointer" style={styles.storedMethods} >
                      <i class="fa fa-plus-square-o" aria-hidden="true" style={{fontSize: "2rem"}} />
                      <div className="ml-3" >
                          <p className="m-0 bold" >Add a New</p>
                      </div>
                  </div>
                {errors.existPM && <ErrorText msg={errors.existPM} />}
              </div>
            )}
        </div>)}
        <div className="p-3" style={{background: Colors.bgDark}} >
            <Button className="mt-4" onClick={handleSubmit} block style={STYLES.BUTTON_PRIMARY_TRANSPARENT} >{payType == "deposit" ? "Add Funds": "Withdraw Funds"}</Button>
        </div>
        </PerfectScrollbar>)}
        {successModal && <GidxSuccessModal isOpen={successModal} modalToggle={() => setSuccessModal(!successModal)} type={"addFunds"} newBalance={newBalance} depositClaimed={walletData?.depositClaimed} amount={getAmount()} />}
        {billingModal && <GidxBillingModal setAddress={(add) => setAddress(add)} visible={billingModal} toggle={() => setBillingModal(!billingModal)} />}
        {cvvModal && <GidxCVVModal modal={cvvModal}
            setCVV = {handleInput}
            toggle={() => {
            setCVVModal(!cvvModal)
        }} />}


    </div>
    </LoadingOverlay>)
}
export default GidxAddFunds