export const Colors = {
    //V1
    darkBlue: "#343434",
    green: "#03F29E",
    lightGreen: "#BAD5CC",
    footerColor: "#262626",
    // v2
    darkColor: "#222527",
    lightColor: "#484C4E",

    // BASEBALL PRODUCT
    //V2 Figma
    bgLight: "#212121",
    bgDark: "#161B1E",
    bgDarker: "#383838",
    fixedCell:'#14181B',
    bg400: "#282D31",
    colorLight:'#FFFFFF',
    colorDark:'#000000',
    colorPrimary: "#03F29E",
    colorPrimaryLight: "#03D29E",
    colorWarning: "#EFBD30",
    colorWarningLight: "#FFD04A",
    colorPrimaryDark: "#184C3E",
    colorSecondary: "#C4C4C4",
    colorDanger: '#DC3444',
    colorDanger_v2: "#F24C65",
    colorInfo: '#2B9BCF',
    // colorWarning: '#F5C12F',
    bgSecondary: "#4A5564",
    bgSecondaryLight: '#242A2F',
    colorInfoLight: '#B4CCF4',

    // V3 Figma - Matchmaker
    sidebarHeader: '#184C3E',
    sidebarBody:'#13805C',

    colorLink: '#2092C7',
    bgSecondHeader: '#1D2226',
    bgSecondHeaderSidebar: '#343F53',
    bgScoreboardFooter: '#657FAD',
    textColorScoreboard: '#A9BFE5',

    // Standard Colors Transparent 20
    colorPrimaryTransparent: "#06F19E20",
    colorPrimaryTransparent_v3: "#806F3E",
    colorSecondaryTransparent: "#C4C4C420",
    colorDangerTransparent: '#DC344420',
    colorInfoTransparent: '#2B9BCF20',
    colorWarningTransparent: '#F5C12F20',
    colorWarningTransparent_v2: "rgba(50, 34, 10, 0.8)",

    // Arena Colors
    arenaLineupBg: '#282828',
    arenaLineupNav: '#222222',
    arenaLineupPlayerRow: '#333333',
    
    //Borders
    darkBgBorder: '#36373B',

    // text color 
    textColorSecondary: '#888888',
    textColorSecondary_v2: "#7F7F7F",

    // Skeleton
    skeletonBaseColor: '#161B1E',
    skeletonHighlightColor: '#444444',

    // Leaderboards
    arenaLeaderbaordsPrimary: '#03F29E',
    referralLeaderboardsPrimary: '#FFDD7C',

    // Input
    inputBackground: '#292929',
    inputText: '#FFFFFF',

    // V3 Colors
    sidebarBody_v3: '#121212',
    bgDark_v3: '#000000',
    bgSecondary_v3: '#22332D',
    overviewCardBg_v3: '#2E2E2E',
    // V3 Text
    textDark_v3: '#121212',
    textLight_v3: '#FFFFFF',
    textSecondary_v3: '#909996',
    arenaHeader_v3: '#2D0F0D',
    timerBg_v3: '#1E1E1E',
    arenaContentBg_v3: '#121212',
    colorSecondary_v3: '#2E2E2E',
    borderSecondary_v3: '#909996',
    darkGreen_v3: '#013020',
    borderPrimary_v3: '#03DA8E',

    // Sports Primary Colors
    nbaPrimaryColor: '#FCAA31',
    nflPrimaryColor: '#03F29E',
    mlbPrimaryColor: '#03F29E',

    // Sport Primary Transparent
    nflPrimaryColorTransparent: '#F5C12F20',
    nbaPrimaryColorTransparent: '#03F29E20',
    mlbPrimaryColorTransparent: '#03F29E20',

    playerCardHeader: '#3a778c',
    playerCardTableHeader: '#0D272F',
    // new landing page colors
    secondary_30: "#4d4225",
    secondary_100: "#ffdd7c",
    black_40: "#999999",
    black_50: "#7F7F7F",
    black_90: "#1a1a1a",
    black_80: "#333333",
    rejectColor: "#F35970",
}

export const defaultColors = {
    //V1
    darkBlue: "#343434",
    green: "#03F29E",
    lightGreen: "#BAD5CC",
    footerColor: "#262626",
    // v2
    darkColor: "#222527",
    lightColor: "#484C4E",

    // BASEBALL PRODUCT
    //V2 Figma
    bgLight: "#212121",
    bgDark: "#161B1E",
    bgDarker: "#383838",
    fixedCell:'#14181B',
    bg400: "#282D31",
    colorLight:'#FFFFFF',
    colorDark:'#000000',
    colorPrimary: "#03F29E",
    colorWarning: "#EFBD30",
    colorWarningLight: "#FFD04A",
    colorPrimaryDark: "#184C3E",
    colorPrimaryLight: "#03009E",
    colorSecondary: "#C4C4C4",
    colorDanger: '#DC3444',
    colorDanger_v2: "#F24C65",
    colorInfo: '#2B9BCF',
    // colorWarning: '#F5C12F',
    bgSecondary: "#4A5564",
    bgSecondaryLight: '#242A2F',
    colorInfoLight: '#B4CCF4',

    // V3 Figma - Matchmaker
    sidebarHeader: '#184C3E',
    sidebarBody:'#13805C',

    colorLink: '#2092C7',
    bgSecondHeader: '#1D2226',
    bgSecondHeaderSidebar: '#343F53',
    bgScoreboardFooter: '#657FAD',
    textColorScoreboard: '#A9BFE5',

    // Standard Colors Transparent 20
    colorPrimaryTransparent: "#06F19E20",
    colorPrimaryTransparent_v3: "#806F3E",
    colorSecondaryTransparent: "#C4C4C420",
    colorDangerTransparent: '#DC344420',
    colorInfoTransparent: '#2B9BCF20',
    colorWarningTransparent: '#F5C12F20',
    colorWarningTransparent_v2: "rgba(50, 34, 10, 0.8)",

    // Arena Colors
    arenaLineupBg: '#282828',
    arenaLineupNav: '#222222',
    arenaLineupPlayerRow: '#333333',
    
    //Borders
    darkBgBorder: '#36373B',

    // text color 
    textColorSecondary: '#888888',
    textColorSecondary_v2: "#7F7F7F",

    // Skeleton
    skeletonBaseColor: '#161B1E',
    skeletonHighlightColor: '#444444',

    // Leaderboards
    arenaLeaderbaordsPrimary: '#03F29E',
    referralLeaderboardsPrimary: '#FFDD7C',

    // Input
    inputBackground: '#292929',
    inputText: '#FFFFFF',

    // V3 Colors
    sidebarBody_v3: '#121212',
    bgDark_v3: '#000000',
    bgSecondary_v3: '#22332D',
    overviewCardBg_v3: '#2E2E2E',
    // V3 Text
    textDark_v3: '#121212',
    textLight_v3: '#FFFFFF',
    textSecondary_v3: '#909996',
    arenaHeader_v3: '#2D0F0D',
    timerBg_v3: '#1E1E1E',
    arenaContentBg_v3: '#121212',
    colorSecondary_v3: '#2E2E2E',
    borderSecondary_v3: '#909996',
    darkGreen_v3: '#013020',
    borderPrimary_v3: '#03DA8E',

    nbaPrimaryColor: '#FCAA31',
    nflPrimaryColor: "#03F29E",
    mlbPrimaryColor: '#03F29E',

    playerCardHeader: '#3a778c',
    playerCardTableHeader: '#0D272F',
    black_40: "#999999",
    black_50: "#7F7F7F",
    black_90: "#1a1a1a",
    black_80: "#333333",
    rejectColor: "#F35970",
}

export const nbaColors = {
    //V1
    darkBlue: "#343434",
    green: "#03F29E",
    lightGreen: "#BAD5CC",
    footerColor: "#262626",
    // v2
    darkColor: "#222527",
    lightColor: "#484C4E",

    // BASEBALL PRODUCT
    //V2 Figma
    bgLight: "#212121",
    bgDark: "#161B1E",
    bgDarker: "#383838",
    fixedCell:'#14181B',
    bg400: "#282D31",
    colorLight:'#FFFFFF',
    colorDark:'#000000',
    colorPrimary: "#FCAA31",
    colorPrimary900: "#32220A",
    colorPrimary1000: "#211500",
    colorWarning: "#EFBD30",
    colorWarningLight: "#FFD04A",
    colorPrimaryDark: "#184C3E",
    colorSecondary: "#C4C4C4",
    colorDanger: '#DC3444',
    colorDanger_v2: "#F24C65",
    colorInfo: '#2B9BCF',
    // colorWarning: '#F5C12F',
    bgSecondary: "#4A5564",
    bgSecondaryLight: '#242A2F',
    colorInfoLight: '#B4CCF4',

    // V3 Figma - Matchmaker
    sidebarHeader: '#184C3E',
    sidebarBody:'#13805C',

    colorLink: '#2092C7',
    bgSecondHeader: '#1D2226',
    bgSecondHeaderSidebar: '#343F53',
    bgScoreboardFooter: '#657FAD',
    textColorScoreboard: '#A9BFE5',

    // Standard Colors Transparent 20
    colorPrimaryTransparent: "#FCAA3120",
    colorPrimaryTransparent_v3: "#806F3E",
    colorPrimaryTransparent_v2: "#CA8827",
    colorSecondaryTransparent: "#C4C4C420",
    colorDangerTransparent: '#DC344420',
    colorInfoTransparent: '#2B9BCF20',
    colorWarningTransparent: '#F5C12F20',
    colorWarningTransparent_v2: "rgba(50, 34, 10, 0.8)",

    // Arena Colors
    arenaLineupBg: '#282828',
    arenaLineupNav: '#222222',
    arenaLineupPlayerRow: '#333333',
    
    //Borders
    darkBgBorder: '#36373B',

    // text color 
    textColorSecondary: '#888888',
    textColorSecondary_v2: "#7F7F7F",

    // Skeleton
    skeletonBaseColor: '#161B1E',
    skeletonHighlightColor: '#444444',

    // Leaderboards
    arenaLeaderbaordsPrimary: '#03F29E',
    referralLeaderboardsPrimary: '#FFDD7C',

    // Input
    inputBackground: '#292929',
    inputText: '#FFFFFF',

    // V3 Colors
    sidebarBody_v3: '#121212',
    bgDark_v3: '#000000',
    bgSecondary_v3: '#FCAA3120',
    overviewCardBg_v3: '#2E2E2E',
    // V3 Text
    textDark_v3: '#121212',
    textLight_v3: '#FFFFFF',
    textSecondary_v3: '#909996',
    arenaHeader_v3: '#241706',
    timerBg_v3: '#1E1E1E',
    arenaContentBg_v3: '#121212',
    colorSecondary_v3: '#2E2E2E',
    borderSecondary_v3: '#909996',
    darkGreen_v3: '#013020',
    borderPrimary_v3: '#03DA8E',

    nbaPrimaryColor: '#FCAA31',
    nflPrimaryColor: "#03F29E",
    mlbPrimaryColor: '#03F29E',

    playerCardHeader: '#3a778c',
    playerCardTabColor: "#FFDD7C",
    playerCardTableHeader: '#0D272F',

    modalCloseIconColor: "#000",
    schedulePlayerColor: "#2D0F0D",
    black_40: "#999999",
    black_50: "#7F7F7F",
    black_90: "#1a1a1a",
    black_80: "#333333",
    rejectColor: "#F35970",
}

export const nflColors = {
    //V1
    darkBlue: "#343434",
    green: "#03F29E",
    lightGreen: "#BAD5CC",
    footerColor: "#262626",
    // v2
    darkColor: "#222527",
    lightColor: "#484C4E",

    // BASEBALL PRODUCT
    //V2 Figma
    bgLight: "#212121",
    bgDark: "#161B1E",
    bgDarker: "#383838",
    fixedCell:'#14181B',
    bg400: "#282D31",
    colorLight:'#FFFFFF',
    colorDark:'#000000',
    colorPrimary: "#03F29E",
    colorPrimary900: "#1A4337",
    colorPrimary1000: "#091C16",
    colorWarning: "#EFBD30",
    colorWarningLight: "#FFD04A",
    colorPrimaryDark: "#184C3E",
    colorSecondary: "#C4C4C4",
    colorDanger: '#DC3444',
    colorDanger_v2: "#F24C65",
    colorInfo: '#2B9BCF',
    // colorWarning: '#F5C12F',
    bgSecondary: "#4A5564",
    bgSecondaryLight: '#242A2F',
    colorInfoLight: '#B4CCF4',

    // V3 Figma - Matchmaker
    sidebarHeader: '#184C3E',
    sidebarBody:'#13805C',

    colorLink: '#2092C7',
    bgSecondHeader: '#1D2226',
    bgSecondHeaderSidebar: '#343F53',
    bgScoreboardFooter: '#657FAD',
    textColorScoreboard: '#A9BFE5',

    // Standard Colors Transparent 20
    colorPrimaryTransparent: "#06F19E20",
    colorPrimaryTransparent_v3: "#806F3E",
    colorSecondaryTransparent: "#C4C4C420",
    colorDangerTransparent: '#DC344420',
    colorInfoTransparent: '#2B9BCF20',
    colorWarningTransparent: '#F5C12F20',
    colorWarningTransparent_v2: "rgba(50, 34, 10, 0.8)",

    // Arena Colors
    arenaLineupBg: '#282828',
    arenaLineupNav: '#222222',
    arenaLineupPlayerRow: '#333333',
    
    //Borders
    darkBgBorder: '#36373B',

    // text color 
    textColorSecondary: '#888888',
    textColorSecondary_v2: "#7F7F7F",

    // Skeleton
    skeletonBaseColor: '#161B1E',
    skeletonHighlightColor: '#444444',

    // Leaderboards
    arenaLeaderbaordsPrimary: '#03F29E',
    referralLeaderboardsPrimary: '#FFDD7C',

    // Input
    inputBackground: '#292929',
    inputText: '#FFFFFF',

    // V3 Colors
    sidebarBody_v3: '#121212',
    bgDark_v3: '#000000',
    bgSecondary_v3: '#22332D',
    overviewCardBg_v3: '#2E2E2E',
    // V3 Text
    textDark_v3: '#121212',
    textLight_v3: '#FFFFFF',
    textSecondary_v3: '#909996',
    arenaHeader_v3: '#2D0F0D',
    timerBg_v3: '#1E1E1E',
    arenaContentBg_v3: '#121212',
    colorSecondary_v3: '#2E2E2E',
    borderSecondary_v3: '#909996',
    darkGreen_v3: '#013020',
    borderPrimary_v3: '#03DA8E',

    nbaPrimaryColor: '#FCAA31',
    nflPrimaryColor: "#03F29E",
    mlbPrimaryColor: '#03F29E',

    playerCardHeader: '#3a778c',
    playerCardTabColor: "#03F29E",
    playerCardTableHeader: '#0D272F',

    modalCloseIconColor: "#fff",
    schedulePlayerColor: "#2D0F0D",
    black_40: "#999999",
    black_50: "#7F7F7F",
    black_90: "#1a1a1a",
    black_80: "#333333",
    rejectColor: "#F35970",
}

export const mlbColors = {
    //V1
    // darkBlue: "#343434",
    // green: "#03F29E",
    // lightGreen: "#BAD5CC",
    // footerColor: "#262626",
    // v2
    darkColor: "#222527",
    lightColor: "#484C4E",

    // BASEBALL PRODUCT
    //V2 Figma
    bgLight: "#212121",
    bgDark: "#161B1E",
    bgDarker: "#383838",
    fixedCell:'#14181B',
    bg400: "#282D31",
    colorLight:'#FFFFFF',
    colorDark:'#000000',
    colorPrimary: "#DF4943",
    colorPrimary900: "#431614",
    colorPrimary1000: "#2D0F0D",
    colorWarning: "#EFBD30",
    colorWarningLight: "#FFD04A",
    colorPrimaryDark: "#184C3E",
    colorSecondary: "#C4C4C4",
    colorDanger: '#DC3444',
    colorDanger_v2: "#F24C65",
    colorInfo: '#2B9BCF',
    // colorWarning: '#F5C12F',
    bgSecondary: "#4A5564",
    bgSecondaryLight: '#242A2F',
    colorInfoLight: '#B4CCF4',

    // V3 Figma - Matchmaker
    sidebarHeader: '#184C3E',
    sidebarBody:'#13805C',

    colorLink: '#2092C7',
    bgSecondHeader: '#1D2226',
    bgSecondHeaderSidebar: '#343F53',
    bgScoreboardFooter: '#657FAD',
    textColorScoreboard: '#A9BFE5',
    ballColorEntry: "#702521",

    // Standard Colors Transparent 20
    colorPrimaryTransparent_v1: "#01492F",
    colorPrimaryTransparent_v2: "#06F19E20",
    colorPrimaryTransparent: "#DC344420",
    colorPrimaryTransparent_v3: "#806F3E",
    colorSecondaryTransparent: "#C4C4C420",
    colorDangerTransparent: '#DC344420',
    colorDangerTransparent_v2: "#EFA4A0",
    colorInfoTransparent: '#2B9BCF20',
    colorWarningTransparent: '#F5C12F20',
    colorWarningTransparent_v2: "rgba(50, 34, 10, 0.8)",

    // Arena Colors
    arenaLineupBg: '#282828',
    arenaLineupNav: '#222222',
    arenaLineupPlayerRow: '#333333',
    
    //Borders
    darkBgBorder: '#36373B',

    // text color 
    textColorSecondary: '#888888',
    textColorSecondary_v2: "#7F7F7F",
    // Skeleton
    skeletonBaseColor: '#161B1E',
    skeletonHighlightColor: '#444444',

    // Leaderboards
    arenaLeaderbaordsPrimary: '#03F29E',
    referralLeaderboardsPrimary: '#FFDD7C',

    // Input
    inputBackground: '#292929',
    inputText: '#FFFFFF',

    // V3 Colors
    sidebarBody_v3: '#121212',
    bgDark_v3: '#000000',
    bgSecondary_v3: '#DC344420',
    overviewCardBg_v3: '#2E2E2E',
    // V3 Text
    textDark_v3: '#121212',
    textLight_v3: '#FFFFFF',
    textSecondary_v3: '#909996',
    arenaHeader_v3: '#2D0F0D',
    timerBg_v3: '#1E1E1E',
    arenaContentBg_v3: '#121212',
    colorSecondary_v3: '#2E2E2E',
    borderSecondary_v3: '#909996',
    darkGreen_v3: '#013020',
    borderPrimary_v3: '#03DA8E',

    nbaPrimaryColor: '#FCAA31',
    nflPrimaryColor: "#03F29E",
    mlbPrimaryColor: '#03F29E',

    playerCardHeader: '#3a778c',
    playerCardSubHeader: "#FF5910",
    playerCardTabColor: "#FF5910",
    playerCardTableHeader: '#0D272F',

    modalCloseIconColor: "#fff",
    mlbPlayerCardStatHeader: "#002D72",
    schedulePlayerColor: "#2D0F0D",
    black_40: "#999999",
    black_50: "#7F7F7F",
    black_90: "#1a1a1a",
    black_80: "#333333",
    rejectColor: "#F35970",
}