import { errorMessage } from "../../../helpers/common-methods";
const ENDPOINTS = {
  SCOREBOARD_META: "/mm/scoreboardMeta",
  ACTIVE_MATCHES: "/mm/acceptedMatches",
  MM_SB_MLB_STATS: "/livePolling/mmScoreboard",
  MATCHUP: "/mm/matchup",
  MATCHUP_PLAYERS: "/mm/matchupPlayers",
  NFL_MATCHUP: "/mm/nfl/matchup",
  MATCH_SCHEDULE: "mm/matchSchedule",
};


export const scoreboardMeta = (qs) => {
  try {
    return window.axios.get(ENDPOINTS.SCOREBOARD_META + qs).then((response) => {
      return Promise.resolve(response.data);
    })
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    } else if (error.request) {
      errorMessage(error.request._response);
    } else {
      errorMessage(error.message);
    }
  }
}

export const activeMatches = (qs) => {
  try {
    return window.axios.get(ENDPOINTS.ACTIVE_MATCHES + qs).then((response) => {
      return Promise.resolve(response.data);
    })
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    } else if (error.request) {
      errorMessage(error.request._response);
    } else {
      errorMessage(error.message);
    }
  }
}

export const mmMlbLiveScoreboard = (qs) => {
  try {
    return window.axios.get(ENDPOINTS.MM_SB_MLB_STATS + qs).then((response) => {
      return Promise.resolve(response.data);
    })
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    } else if (error.request) {
      errorMessage(error.request._response);
    } else {
      errorMessage(error.message);
    }
  }
}

export const matchup = (qs) => {
  try {
    return window.axios.get(ENDPOINTS.MATCHUP + qs).then((response) => {
      return Promise.resolve(response.data.data);
    })
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    } else if (error.request) {
      errorMessage(error.request._response);
    } else {
      errorMessage(error.message);
    }
  }
}

export const matchupPlayers = (qs) => {
  try {
    return window.axios.get(ENDPOINTS.MATCHUP_PLAYERS + qs).then((response) => {
      return Promise.resolve(response.data.data);
    });
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    } else if (error.request) {
      errorMessage(error.request._response);
    } else {
      errorMessage(error.message);
    }
  }
}

export const nflMatchup = (qs) => {
  try {
    return window.axios.get(ENDPOINTS.NFL_MATCHUP + qs).then((response) => {
      return Promise.resolve(response.data.data);
    })
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    } else if (error.request) {
      errorMessage(error.request._response);
    } else {
      errorMessage(error.message);
    }
  }
}

export const matchSchedule = (params) => {
  try {
    return window.axios.get(ENDPOINTS.MATCH_SCHEDULE, { params }).then((response) => {
      return Promise.resolve(response.data.data);
    })
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    } else if (error.request) {
      errorMessage(error.request._response);
    } else {
      errorMessage(error.message);
    }
  }
};