import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RotoStandings from './RotoStandings';
import H2HStandings from './H2HStandings';
import auth from '../../../reducers/auth';

class Standings extends Component {
    constructor(props) {
        super(props);
    }
    state = {  }
    render() { 
        return ( 
            <>
                {(() => {
                    switch (this.props.activeLeague.scoringFormat) {
                        case 'roto':
                            return (
                                <RotoStandings leagueId={this.props.leagueId}/>
                                )
                        default:
                            return (
                                <H2HStandings leagueId={this.props.leagueId} userId={this.props.auth.user.id}/>
                                )
                    }
                })()}
            </>
        )
    }
}
const mapStateToProps = (state) => ({auth: state.auth, leagueId: state.leagueJoin.leagueId, activeLeague: state.leagueJoin.activeLeague})
export default connect(mapStateToProps,null) (withRouter(Standings));