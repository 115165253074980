import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'reactstrap';
import ComparionView from '../../components/Matchmaker/ComparisonView';
import MatchMakerLineup from '../../components/Matchmaker/MatchMaker/MatchMakerLineup';
import { backArrowIcon } from '../../helpers/icons';
import { useColors } from '../../helpers/CustomHooks/useColors';
import ArenaMatchModal from './ArenaMatchModal';

const MatchMakerLineupModal = (props) => {
    const {modal, toggle, data} = props

    // const [matchModal, setMatchModal] = useState(false)

    const Colors = useColors()

	return (
        <>
            <div className='d-flex align-items-center p-2'>
                <div className='mx-3 pointer' style={{height: 24, width: 24}} onClick={toggle}>
                    {backArrowIcon()}
                </div>
                <div className='d-flex align-items-center'>
                    <div className='j justify-content-center text-center'>
                        <img className='m-2 rounded-circle' src={data.team.image} style={{height:64, width:64}} />
                        <p className='m-0' style={{fontSize:14, fontWeight:400}}>{data.team.arena_record}</p>
                    </div>
                    <div className='ml-3'>
                        <p className='m-0' style={{fontSize: 24, fontFamily:'Alexandria', fontWeight:600, color: Colors.colorPrimary}}>{data.team.team_name}</p>
                        <p className='m-0' style={{ fontSize: 16, color: Colors.black_50}}>{data.user.username}</p>
                    </div>
                </div>
            </div>
            <MatchMakerLineup teamId={data.team.team_id} sport={data.team.sport} />
            {/* <Button onClick={() => setMatchModal(!matchModal)}>Send Proposal</Button>
            { matchModal && <ArenaMatchModal modal={matchModal} sport={data.team.sport} selectedLineup={{teamImage: data.team.image, teamId:data.team.team_id, leagueId: data.team.league_id, teamName:data.team.team_name}} />} */}
        </>
	);
}
export default MatchMakerLineupModal;
