import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { Row, Col, Button, Table } from 'reactstrap';
import gettingStarted from "../assets/images/gettingStarted.png";
import getStarted from "../assets/images/getStarted.png";
import ios from "../assets/images/ios.png";
import android from "../assets/images/android.png";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImportLeagueModal from '../modals/ImportLeagueModal';
import ClaimTeamModal from '../modals/ClaimTeamModal';
import { getInForage, getStore, removeInForage, STORE } from '../helpers/common-methods';
import SendInviteModal from "../modals/send_invite";
import WelcomeModal from '../modals/WelcomeModal';
import queryString from 'query-string';
import JoinedTeamsModal from '../modals/SeasonLong/JoinedTeamsModal';
import { getImportedLeagues } from '../actions/Import/api';
import { LoaderCenter } from '../components/Loader';

class LeagueImportManager extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      user: this.props.auth.user,
      loading: true,
      importLeagueModal: false,
      leagues: [],
      claimTeamModal: false,
      claimData: {
      },
      welcomeModal: false,
      importPlatform: null,
      joinedTeamModal: false,
      selectedLg: null
     };
  }

  componentDidMount(){
    let params = queryString.parse(this.props.location.search)
    if(params.platform){
      this.setState({ importLeagueModal : true, importPlatform: params.platform })
    }else{
      // VERIFY IF USER VISITING THE PAGE FIRST TIME THEN TRIGGER IMPORT LEAGUE FLOW
      getInForage("newUser").then((val) => {
        if(val){
          this.setState({ welcomeModal : true })
        }
      }).catch((err) => {
          console.log(err)
      })
      // Check if user has any pending claim team
      getInForage("claimTeam").then((val) => {
        if(val){
          this.setState({ claimTeamModal : true, claimData: val })
        }
      }).catch((err) => {
          console.log(err)
      })
    }
    
    getStore().dispatch(getImportedLeagues()).then((resp) => {
      this.setState({loading: false, leagues: resp})
    }).catch((err) => {
      this.setState({loading: false})
    })
  }

  async toggleImportLeague() {
    this.props.history.replace({ pathname: window.location.pathname, search: ""});
    this.setState(prevState => ({
      importLeagueModal: !prevState.importLeagueModal,
      importPlatform: null
    }));
  }

  async toggleClaimTeam() {
    await removeInForage("claimTeam")
    this.setState(prevState => ({
      claimTeamModal: !prevState.claimTeamModal
    }));
  }

  toggleJoinedTeamModal = () => {
    this.setState({joinedTeamModal: !this.state.joinedTeamModal})
  }

  handleJoineView = (lg) => {
    this.setState({selectedLg: lg})
    this.toggleJoinedTeamModal()
  }

  render() {
    return (
      <DocumentTitle title={`Fantasy Football 2021 | Bettle`}>
        <div>
        <div  body className="home_upper_div mt-20 p-1 mb-4" >
          <Row >
          <Col xs="8" >
              <div>
                  {/* <img style={{verticalAlign: "top"}} src={this.state.user.avatar == null ? profile : IMAGES_S3+this.state.user.image} className="roundImg" /> */}
                <div className="home_welcome" >
                  <h2 className="mbb-3" >Welcome, <div className="bold d-inline-block greenText" >{this.state.user.username}!</div></h2>
                  {/* <Button className="d-block d-sm-none mt-2 green-btn btn-pill" onClick={() => { this.setState({ importLeagueModal: true }) }} >
                    <div className="badgeContainer" >
                      Import League
                    </div> 
                  </Button> */}
                </div>
              </div>
          </Col>

          <Col xs="4" className="text-right" >
              <div className="d-flex align-items-center justify-content-end" style={{height: "100%"}} >
                <Button id="importLeagueBtn" className="green-btn default-btn mr-3 btn-pill"onClick={() => { this.setState({ importLeagueModal: true }) }} >
                  <div className="badgeContainer" >
                    Import League
                  </div>
                </Button>
              </div>
          </Col>
          </Row>
        </div>

            
          <div style={{backgroundColor: "#BEF4E3", color: "#343434"}} className="home_upper_div pt-2 pb-2 pl-0 pr-0 pt-md-3 pb-md-3 pl-md-3 pr-md-3 landingCard" >
            <Row >
              <Col md="8">
                  <h3 className="bold mb-0 pl-3" >Getting started:</h3>

                  <div className="ml-5 mt-3" >
                    <ul  >
                      <li className="lead" style={{listStyleType: "decimal"}} >Import your league from ESPN or Yahoo via the ‘Leagues’ section below.</li>
                      <li className="lead" style={{listStyleType: "decimal"}} >Once you’ve imported your league, invite your league members to join Bettle.</li>
                      <li className="lead" style={{listStyleType: "decimal"}} >Download the app to begin playing!</li>
                    </ul>
                </div>

                <div className="d-flex align-items-center ml-3 mr-3" >
                    <div>
                        <a target="_blank" href="https://apps.apple.com/us/app/bettle-fantasy-sports/id1591274931" >
                          <img className="img-fluid" src={ios} />
                        </a>
                      </div>
                      <div>
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.bettle.bettlelive" >
                          <img className="img-fluid" src={android} />
                        </a>
                      </div>
                  </div>
              </Col>
              
              <Col md="4" sm="12" >

                  <div className="d-flex flex-column justify-content-center" style={{height: "100%"}} >
                    <img src={getStarted} className="img-fluid" />
                  </div>
              </Col>

            </Row>
              
            
           
          </div>


        <div className="home_upper_div pt-2 pb-2 pl-0 pr-0 pt-md-3 pb-md-3 pl-md-3 pr-md-3 mt-4 landingCard" style={{ marginBottom: "0"}} >
          <Row  >
           <Col md="6" sm="12">
              <h3 className="bold mb-0 greenText pl-3" >Leagues</h3>
           </Col>
          </Row>
          
          { this.state.loading ? <LoaderCenter /> : (
          
          this.state.leagues.length == 0 ? (
            <div className="p-4 text-center" >
              <div className="mb-4" >
                <img src={gettingStarted} className="img-fluid" />
              </div>
              <p className="lead" >Look's like you are just getting started.</p>
              <p className="lead" >Click below to import your first league.</p>

                <Button className="green-btn default-btn mt-4 btn-pill"onClick={() => { this.setState({ importLeagueModal: true }) }} >
                  <div className="badgeContainer" >
                    Import League
                  </div>
                </Button>

            </div>
          ) : (
          <div className="table-responsive" style={{maxHeight: "400px", overflow: "auto"}} >
          <Table borderless>
              <thead style={{borderBottom: "1px solid dimgrey"}} >
                  <tr>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Import</th>
                      <th>League Size</th>
                      <th>Action</th>
                  </tr>
              </thead>
              <tbody>
                  {this.state.leagues.map((lg) => (
                    <tr style={{borderBottom: "1px solid dimgrey"}} >
                      <td>
                          <a href='#' ><b className="mb-0" >{lg.name}</b></a>
                          <p className="mb-0" >{lg ? lg.platform.toUpperCase() : "N/A"}</p>
                      </td>
                      <td>
                          <p>{lg.metaData.filled}</p>
                      </td>
                      <td>{lg ? lg.status.toUpperCase() : "N/A"}</td>
                      <td>
                        <div className="d-flex align-items-center" >
                          <span>
                          {lg.joined < lg.maxTeams && <i class="fa fa-exclamation-triangle fa-lg" style={{ "color": "#FF0066" }}></i>} {lg.joined+"/"+lg.maxTeams}
                          </span>
                          <Button onClick={()=> this.handleJoineView(lg)} className="greenText" color="link" >View</Button>
                        </div>
                      </td>
                      <td>
                          {<SendInviteModal league_id={lg.id} teams={lg.metaData.emptySpots} lg={lg} />}
                      </td>
                    </tr>
                  ))}
              </tbody>
          </Table>
          </div>
          ))}
        </div>
{/* 
        <div className="landingCard mt-4 p-3 d-none d-md-block" style={{maxHeight: "300px", overflow:"hidden"}} >
            <Row>
                <Col className="text-center" >
                    <img className="img-fluid" src={mobileApp} />
                </Col>
                <Col>
                    <h3 className="bold mt-5" >Download the Bettle App</h3>
                    <div className="d-flex align-items-center" >
                        <div>
                          <a target="_blank" href="https://apps.apple.com/us/app/bettle-fantasy-sports/id1591274931" >
                            <img src={ios} />
                          </a>
                        </div>
                        <div>
                          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.bettle.bettlelive"  >
                            <img src={android} />
                          </a>
                        </div>
                    </div>
                </Col>
            </Row>
        </div> */}

        {/* <div className="landingCard mt-4 d-md-none" >
            <Row>
                <Col className="mb-3 text-center" xs="12" >
                    <h3 className="bold mt-5" >Download the Bettle App</h3>
                    <div className="d-flex align-items-center" >
                      <div>
                          <a target="_blank" href="https://apps.apple.com/us/app/bettle-fantasy-sports/id1591274931" >
                            <img src={ios} />
                          </a>
                        </div>
                        <div>
                          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.bettle.bettlelive" >
                            <img src={android} />
                          </a>
                        </div>
                    </div>
                </Col>
                <Col className="text-center" >
                    <img className="img-fluid" src={mobileApp} />
                </Col>
            </Row>
        </div> */}

        <ToastContainer />
        {this.state.welcomeModal && <WelcomeModal modal={true} toggleImportLeague={() => this.toggleImportLeague()} />}
        {this.state.joinedTeamModal && <JoinedTeamsModal lg={this.state.selectedLg} modal={true} toggle={this.toggleJoinedTeamModal} />}
        {this.state.claimTeamModal && <ClaimTeamModal modal={this.state.claimTeamModal} toggle={(close) => this.toggleClaimTeam(close)} claimData={this.state.claimData}  />}
        {this.state.importLeagueModal && <ImportLeagueModal importPlatform={this.state.importPlatform} modal={this.state.importLeagueModal} toggle={(close) => this.toggleImportLeague(close)} />}
        </div>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = ({ auth, loading }) => ({ auth, loading });

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(LeagueImportManager);
