import { AppSwitch } from '@coreui/react';
import { isEmpty } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route,useHistory,withRouter } from 'react-router-dom';
import {  Modal, ModalHeader, ModalBody, ModalFooter, Button, UncontrolledCollapse, Form, FormGroup, InputGroup, Label, Input, Badge } from 'reactstrap';
import { createGroup } from '../../apis/Chat/ChatAPI';
import { saveNewChannel, updateUnreadCount } from '../../apis/Sidebar/LeagueAPI';
import { ColorContext } from '../../context/ColorProvider';
import { plusIcon } from '../../helpers/icons';
class ChannelsCollpasible extends Component {
    selectedUsers = []
    selectedUsersNameCopy = []
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            data:this.props.channels,
            collapse: true,
            loading:true,
            create_channel:0,
            channel_name:'',
            selectedUsers:[],
            selectedNames: [],
            isPublic:false,
            error:false,
            query:'',
            // channels: {},
            status:false
          };

        this.toggle = this.toggle.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.createChannel = this.createChannel.bind(this);
        this.addUsers = this.addUsers.bind(this);
        this.onBack = this.onBack.bind(this);
        this.handleNext = this.handleNext.bind(this);
    }

    static contextType = ColorContext;

    componentDidUpdate(prevProps) {
        if(prevProps.channels != null && prevProps.channels != this.props.channels){
            this.setState({
                loading:false
            })
        }
    }
    toggle() {
        this.setState({
          modal: !this.state.modal,
          create_channel:0,
          channel_name:'',
          selectedNames:[],
          isPublic:false,
          query:''
        });
        this.selectedUsersNameCopy = []
        this.selectedUsers = []
      }

    toggleCollapse() {
        this.setState({
            collapse: !this.state.collapse
        })
    }

    handleChange(e) {
        this.setState({
            channel_name: e.target.value.replace(/\s+/g, '-').toLowerCase()
        })
    }

    /**
     * Reset the count of opened chat if it has unread messages
     * @param {string} id - id of group to reset unread count
     */
    resetCount(id){
        let data = this.props.channels

        if(data != undefined) {
            data[id].unread_messages = 0
            this.props.updateUnreadCount(data, 'group')
        }
    }

    createChannel(data) {

        var reqData = {}
        reqData["invite"] = this.selectedUsers
        reqData["league_id"] = this.props.leagueId;
        reqData['name'] = this.state.channel_name;
        reqData['is_public'] = this.state.isPublic;
        createGroup(reqData).then((response) => {
            this.setState({
                modal:false,
                create_channel:0,
                channel_name:'',
                isPublic:false,
            })
            this.props.saveNewChannel({[response.id]:response})
            this.props.history.push(`/chat/group/${response.id}`)  
            this.selectedUsers = []
            this.setState({selectedNames:[]})
        }).catch(() => {
        })
    }
    handleUser(e){
            // Take only those inputs which are checkbox
            if (e.target.checked) {
               this.selectedUsers.push(e.target.value);
               this.selectedUsersNameCopy.push(e.target.name)
            }
            else if (!e.target.checked) {
                let index = this.selectedUsers.indexOf(e.target.value);
                let index1 = this.selectedUsersNameCopy.indexOf(e.target.name);
                if(index != -1) {
                    this.selectedUsers.splice(index,1)
                }
                if(index1 != -1) {
                    this.selectedUsersNameCopy.splice(index,1)
                }
             }
             this.setState({selectedNames: [...this.selectedUsersNameCopy] , selectedUsers: [...this.selectedUsers]})
    }

    addUsers(data) {
        if(this.state.channel_name.length !== 0)
        {
            this.setState({
                create_channel:2,
            })
        }
    }
    onBack(){
            this.setState({
            create_channel:this.state.create_channel - 1,
            })
    }

    handleSearch(e){
        this.setState({query:e.target.value})
    }

    onChatChange(id, unread_messages){
        this.props.history.push(`/chat/group/${id}`)
        
        if(unread_messages > 0) {
            this.resetCount(id)
        }

        if(this.state.modal){
            this.setState({
                modal:false, 
                query:''
            })
        }
        else {
            this.updateChnnels()
        }
    } 

    sortingName() {
        const channels  = this.props.channels;
        let sortedByMessages = {};
        sortedByMessages = Object.keys(channels).sort((a, b) => {
            return channels[a].name.localeCompare(channels[b].name)
          })
          .reduce((prev, curr, i) => {
            prev[i] = channels[curr];
            return prev;
          }, {});
          return sortedByMessages
      }

    handleNext(){
        if(this.state.isPublic  && this.state.channel_name != ''){
            this.createChannel()
        }
        if(!this.state.isPublic  && this.state.channel_name != ''){
            this.setState({create_channel:2})
        }
        
    }

    updateChnnels() {
        this.setState({
            status: !this.state.status
        })
    }
    
    state = {  }
    render() { 
        const Colors = this.context;
        return ( 
            <>
                {/* Collapsible Menu */}
                <div className='col-12'>
                    <h6 className="text-truncate bold d-flex align-items-center"><a className='flex1' href='#' onClick={this.toggleCollapse} id='channels' ><span className={`pr-1 fa fa-caret-${this.state.collapse?'down':'right'}`}></span>Channels</a><a href='#' onClick={this.toggle}><div style={{height:15, width:15}}>{plusIcon()}</div></a></h6>
                    <UncontrolledCollapse toggler='#channels' isOpen={this.state.collapse}>
                        
                            {Object.values(this.sortingName()).slice(0,5).map((data) => 
                                // Object.values(item).map(data => {
                                     (
                                        <div key={data.id} className="d-flex flex-row py-1 m-0">
                                            <div className="flex1 text-truncate" >
                                                <h6 onClick={() => this.onChatChange(data.id, data.unread_messages)} className="text-truncate" style={{ cursor:'pointer' }}><i className="mr-2 fa fa-hashtag" ></i>{data.name}</h6>
                                            </div>
                                            <div>
                                                <Badge className={`${data.unread_messages == 0 ? 'd-none':null}`} style={{backgroundColor:Colors.colorPrimary}} pill>{data.unread_messages}</Badge>
                                            </div>
                                        </div>
                                    )
                            )}
                    </UncontrolledCollapse>
                </div>
                {/* !Collapsible Menu */}     

                {/* Modal */}
                    <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} centered>
                    <ModalHeader className='border-0 m-0 px-3 py-0 pt-3' style={{backgroundColor:Colors.bgLight}} cssModule={{'modal-title': 'w-100'}}>
                        <h4 className='bold text-truncate'>Create Channel <i className='fa fa-close pull-right' onClick={this.toggle} style={{cursor:'pointer'}}></i></h4>
                        {/* Search */}
                        {this.state.create_channel === 0 ? 
                        <div className='mt-4' style={{color:Colors.secondary}}>
                                    <div className="input-group " style={{border:`0.01em solid ${Colors.colorSecondary}`, borderRadius:4}}>
                                        <span className="input-group-prepend">
                                            <span className="btn  border-0" >
                                                <i className="fa fa-search" />
                                            </span>
                                        </span>
                                        <input className="form-control border-0 " type="search" id="channel-search" value={this.state.query} placeholder='Search Channel'
                                            onChange={this.handleSearch.bind(this)}     
                                        />
                                    </div>
                                </div>
                        : null }
                        {/* !Search */}
                    </ModalHeader>
                        <ModalBody style={{backgroundColor:Colors.bgLight,maxHeight: 'calc(100vh - 500px)', minHeight:'calc(100vh - 500px)', overflowY: 'auto'}}>
                            
                        {(() => {
    
                            switch(this.state.create_channel)
                            {
                                case 0:
                                    return(
                                        <>
                                            
                                            <Form>
                                                <div>
                                                    
                                                    {
                                                    Object.values(this.props.channels).filter(channel => channel.name.toLowerCase().includes(this.state.query.toLowerCase())).map((data) => 
                                                          
                                                        (   
                                                            <div key={data.id} className="row d-flex flex-row py-1 m-0">
                                                                <div className="flex1 text-truncate" >
                                                                    <h6 onClick={() => this.onChatChange(data.id, data.unread_messages)} className="text-truncate" style={{ cursor:'pointer', color: Colors.colorSecondary }}><i className="mr-2 fa fa-hashtag" ></i>{data.name}</h6>
                                                                </div>
                                                                <div>
                                                                    <Badge className={`${data.unread_messages == 0 ? 'd-none':null}`} style={{backgroundColor:Colors.colorPrimary}} pill>{data.unread_messages}</Badge>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                                <div>
                                                </div>
                                            </Form>
                                        </>
                                    );
                                case 1:
                                    return(
                                        <>
                                            <p>
                                                Channels are where your team communicates. They’re best when organized around a topic — #scoreboard, for example.
                                            </p>
                                            <Form onSubmit={this.addUsers}>
                                                <FormGroup>
                                                    <Label className='bold' for='ChannelName'>Channel Name</Label>
                                                    <Input type='text' name='channelName' id='ChannelName' placeholder='league-news' 
                                                    onChange={this.handleChange.bind(this)} 
                                                    value={this.state.channel_name} style={{backgroundColor:Colors.bgLight}}/>
                                                    <div className='row d-flex flex-row align-items-center mt-2'>
                                                    <h6 className='bold ml-3 mt-2'>Public</h6>
                                                    <AppSwitch
                                                        className={"ml-2 vertical-middle"}
                                                        variant={("3d", "pill")}
                                                        color="success"
                                                        checked={this.state.isPublic}
                                                        onChange={() => this.setState({isPublic: !this.state.isPublic})}
                                                    />
                                                    </div>
                                                    <div className='d-flex flex-row justify-content-end'  >
                                                     
                                                    </div>
                                                </FormGroup>
                                            </Form>
                                        </>
                                    );
                                case 2:
                                    return (
                                        !this.state.isPublic ?
                                        <>
                                            <div className=''>
                                                <div>
                                            {this.state.selectedNames.map(user => {return (<span className='badge mx-1 mb-1 badge-pill' style={{backgroundColor:Colors.colorPrimary, color:'black',fontSize:10}}>{user}</span>)})}
                                            
                                            </div>
                                            {
                                            Object.values(this.props.users).map((data,index) => 
                                                        (
                                                <div key={index} className="d-flex flex-row text-white align-items-center p-2" id="single-message">
                                                    <div className="mx-0 p-0">
                                                        <img width='40px' className="rounded mr-2" src={data.image} />
                                                    </div>
                                                    <div className="m-0 flex1 p-0 ml-3">
                                                        <h6 className='m-0 bold' >{data.team_name}</h6>
                                                    </div>
                                                    <div className='mb-3'>
                                                        <Input type='checkbox' className='' name={data.team_name}  value={data.id} checked={this.selectedUsers.indexOf(data.id) == -1 ? false : true}
                                                        onChange={ this.handleUser.bind(this)}/>
                                                    </div>
                                                </div>
                                                        )
                                                )} 
                                            </div>
                                        </>
                                        :
                                        null
                                    );
                                    default:
                                        return null
                                }
                            })()}
                        </ModalBody>
                        <ModalFooter className='border-0 py-1 pb-3' style={{backgroundColor:Colors.bgLight}}>
                            <Button onClick={() => this.setState({create_channel:1})} className={`border-0 ${this.state.create_channel !== 0 ? 'd-none' :null}`} style={{backgroundColor:Colors.colorPrimary}}>Create</Button>                              
                            <Button 
                            onClick={this.state.createChannel != 0 && 1 ? this.onBack : null} 
                            className={`border-0 ${this.state.create_channel === 2 && !this.state.isPublic ?null:'d-none'}`} 
                            style={{backgroundColor: Colors.colorPrimary}}
                            >Back</Button> 
                            <Button 
                            onClick={ this.selectedUsers.length !== 0 && !this.state.isPublic ? this.createChannel : null} 
                            className={`border-0 ${this.state.create_channel=== 2 && !this.state.isPublic ?null:'d-none'}`} 
                            style={{backgroundColor:`${this.selectedUsers.length === 0 ?  Colors.colorSecondary : Colors.colorPrimary}`}}
                            >Create</Button>        

                            {this.state.create_channel === 1 ?  
                             <div className=''>
                                <Button 
                                onClick={ this.onBack } 
                                className={`border-0 ${this.state.create_channel !== 0 ? null : 'd-none' }`} 
                                style={{backgroundColor:Colors.colorPrimary}}>Back</Button>
                                <Button 
                                onClick={ this.handleNext } 
                                className={`border-0 ml-3`} 
                                style={{backgroundColor:`${ this.state.channel_name != '' ? Colors.colorPrimary : Colors.colorSecondary}`}}
                                > Next </Button> 
                             </div>
                            : null}
                        </ModalFooter>
                    </Modal>
                {/* !Modal */}

            </>
         );
    }
}

const mapDispatchToProps = { saveNewChannel, updateUnreadCount }
const mapStateToProps = (state) => ({ leagueId: state.leagueJoin.leagueId})
export default connect(mapStateToProps,mapDispatchToProps) (withRouter(ChannelsCollpasible));