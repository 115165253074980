import { AppSwitch } from '@coreui/react';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Alert, Button, UncontrolledAlert } from 'reactstrap';
import { Colors } from '../../../helpers/colors';
import { updateUserData } from '../../../actions/auth';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import { updateUserInfo } from '../../../apis/User/UserAPI';
import { useColors } from '../../../helpers/CustomHooks/useColors';

const NotificationPref = (props) => {
	const Colors = useColors();
	const { formState: { errors }, handleSubmit, control, reset } = useForm(); // initialize the hook
	const [ isValid, setIsValid ] = useState(false);
	const [ error, setError ] = useState('');
	const [ loading, setLoading ] = useState(false);

	const submitUpdate = (data) => {
		setLoading(true);
		updateUserInfo(data)
			.then((resp) => {
				setTimeout(() => {
					setIsValid(true);
					setLoading(false);
				}, 100);
			})
			.catch((err) => {
				setError(err);
				setLoading(false);
			});
	};

	return (
		<div className="col mt-4 ">
			<LoadingOverlay
				active={loading}
				spinner
				fadeSpeed={500}
				styles={{
					overlay: (base) => ({
						...base,
						background: 'rgb(0,0,0,0)'
					})
				}}>
				{/* Heading */}
				{
					error ? <UncontrolledAlert color="danger">Something Went Wrong, try again</UncontrolledAlert> :
					null}
				{
					isValid ? <Alert
						toggle={function noRefCheck() {
							setIsValid(!isValid);
						}}
						color="success">
						NotificationPref Updated
					</Alert> :
					null}
				<div className="row justify-content-center">
					<h5 className="bold p-0 my-0 mr-1 mt-1 mr-3">Notifications</h5>
					{/* Switch */}
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, value } }) => <AppSwitch className={''} variant={('3d', 'pill')} color="success" checked={value} onChange={(value) => onChange(value)} onBlur={onBlur} />}
						name="global_notifications"
						defaultValue={props.auth.user.notifications_pref}
					/>
				</div>
				<div className="row justify-content-center">
					<Button
						className="mx-2 mt-4"
						style={{
							color: Colors.colorPrimary,
							backgroundColor: Colors.colorPrimaryTransparent,
							border: `1px solid ${Colors.colorPrimary}`
						}}
						onClick={handleSubmit(submitUpdate)}>
						Update
					</Button>
					<Button
						className="mx-2 mt-4"
						style={{
							color: Colors.colorPrimary,
							backgroundColor: Colors.colorPrimaryTransparent,
							border: `1px solid ${Colors.colorPrimary}`
						}}
						onClick={props.onBack}>
						Back
					</Button>
				</div>
			</LoadingOverlay>
		</div>
	);
};
const mapDispatchToProps = { updateUserData };
export default connect(null, mapDispatchToProps)(withRouter(NotificationPref));
