import React from 'react';
import { Button, UncontrolledCollapse, Card, CardBody } from 'reactstrap';
import { Colors } from '../../../../helpers/colors';
import PerfectScrollbar from 'react-perfect-scrollbar';

function ContentIntegrity(props) {
  return (
    <div className='d-flex flex-column flex1 scrollHidden'>
      {props.slug == "MatchMakerRules" ?
        <>
          <h5 className='bold' style={{ fontSize: 20 }}>Content Integrity</h5>
          <div className='ml-3'>
            <div>
              <p>In an effort to provide a safe, transparent, and enjoyable experience, Bettle routinely performs integrity checks on matches. Matches flagged by our security system will be manually reviewed by our <span style={{ color: Colors.colorPrimary }}>Fraud & Compliance Team</span>, where we will determine if the match is eligible or ineligible. Ineligible matches will be canceled and funds will be returned to the participants.</p>
            </div>
            <div>
              <p>Bettle reserves the right to cancel matches at our sole discretion, without any restrictions.</p>
            </div>
          </div>
        </>
        :
        <>
          <div className='d-flex justify-content-start align-items-center px-3 my-2' style={{ borderBottom: `1px solid ${Colors.darkBgBorder}`, cursor: 'pointer' }} onClick={() => props.setView('default')}>
            <h5><span> <i className='mr-2 fa fa-angle-left'></i></span>Content Integrity</h5>
          </div>
          <PerfectScrollbar>
            <Card className='border-0 mb-0 mx-3' style={{ background: Colors.bgLight }}>
              <CardBody>
                <h5 className='bold' style={{ fontSize: 20 }}>Content Integrity</h5>
                <div>
                  <p>In an effort to provide a safe, transparent, and enjoyable experience, Bettle routinely performs integrity checks on matches. Matches flagged by our security system will be manually reviewed by our <span style={{ color: Colors.colorPrimary }}>Fraud & Compliance Team</span>, where we will determine if the match is eligible or ineligible. Ineligible matches will be canceled and funds will be returned to the participants.</p>
                </div>
                <div>
                  <p>Bettle reserves the right to cancel matches at our sole discretion, without any restrictions.</p>
                </div>
              </CardBody>
            </Card>
          </PerfectScrollbar>
        </>
      }
    </div>
  )
}

export default ContentIntegrity;