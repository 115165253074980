import React, { useState } from 'react';
// import { Colors } from '../../../helpers/colors';
import classnames from 'classnames';
import { TabContent, TabPane, Nav, NavItem, NavLink, Input, Alert } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import { updateScoringSettings } from '../../../apis/SeasonLong/Settings/SettingsAPI';
import LoadingOverlay from 'react-loading-overlay';
import Header from './Header';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ErrorText from '../../../components/ErrorText';
import { useColors } from '../../../helpers/CustomHooks/useColors';

const ScoringSettings = ({ onBackHandler, steps_data, leagueId }) => {
	const Colors = useColors();
	// State for current active Tab
	const [ currentActiveTab, setCurrentActiveTab ] = useState(Object.keys(steps_data.scoring.scoring)[0]);
	const [ loader, setLoader ] = useState(false);
	const [ error, setError ] = useState([]);
	const [ isValid, setIsValid ] = useState(false);
	const scoring = steps_data.scoring.scoring;
	const { formState: { errors }, handleSubmit, control, register } = useForm(); // initialize the hook

	// Toggle active state for Tab
	const toggle = (tabTitle) => {
		if (currentActiveTab !== tabTitle) setCurrentActiveTab(tabTitle);
	};

	const onSubmit = (data) => {
		console.log(data);
		setLoader(true);
		var reqData = {};
		reqData['leagueId'] = leagueId;
		reqData['type'] = 'scoring';
		reqData['values'] = prepareSettingsRequest(data);
		console.log(reqData);

		updateScoringSettings(reqData)
			.then(() => {
				setLoader(false);
				console.log('Settings Updated');
				setIsValid(false);
			})
			.catch((err) => {
				setLoader(false);
				console.log(err);
				setError(err);
				setIsValid(true);
			});
	};
	const prepareSettingsRequest = (data) => {
		var reqData = {};
		Object.keys(data).map((scoringKey) => {
			if (reqData[fetchType(scoringKey)] != undefined) {
				reqData[fetchType(scoringKey)][scoringKey] = data[scoringKey];
			} else {
				reqData[fetchType(scoringKey)] = {
					[scoringKey]: data[scoringKey]
				};
			}
		});
		return reqData;
	};

	const fetchType = (key) => {
		var rType = null;
		Object.keys(scoring).map((type) => {
			scoring[type].map((val) => {
				if (key == val.k) {
					rType = type;
				}
			});
		});
		return rType;
	};

	return (
		<form className="d-flex flex-column flex1 scrollHidden" onSubmit={handleSubmit(onSubmit)}>
			<LoadingOverlay className="d-flex flex-column flex1 scrollHidden" active={loader} spinner text="Saving..." fadeSpeed={1000}>
				<Header onBackHandler={onBackHandler} name="Scoring Settings" />
				<PerfectScrollbar>
					<div className="row flex1 align-items-center justify-content-center p-4 m-0">
						<div className="col-md-6 col-sm-12">
							{
								isValid ? <Alert
									toggle={function noRefCheck() {
										setIsValid(!isValid);
									}}
									className="d-inline-block"
									color="danger">
									Oops! Something went wrong... Try again
								</Alert> :
								null}
							<Nav tabs className="text-center">
								{Object.keys(scoring).map((tabTitle) => (
									<NavItem
										style={{
											backgroundColor: Colors.bgDark,
											flex: 1
										}}>
										<NavLink
											style={{
												backgroundColor: Colors.bgDark,
												borderTop: 'none',
												borderLeft: 'none',
												borderRight: 'none'
											}}
											className={classnames({
												active: currentActiveTab === tabTitle
											})}
											onClick={() => {
												toggle(tabTitle);
											}}>
											{tabTitle.toUpperCase()}
										</NavLink>
									</NavItem>
								))}
							</Nav>
							<TabContent activeTab={currentActiveTab} style={{}}>
								{Object.keys(scoring).map((scoringKey) =>
									scoring[scoringKey].map((scoring) => (
										<TabPane
											className=""
											tabId={scoringKey}
											style={{
												backgroundColor: Colors.bgDark,
												borderBottom: `2px solid ${Colors.darkBgBorder}`
											}}>
											<div className="d-flex">
												<div className="flex1 justify-content-center m-auto">
													<span
														className=" "
														style={{
															fontSize: 18
														}}>
														{scoring.title}
													</span>
													<span
														style={{
															fontSize: 8,
															marginVertical: -20
														}}>
														{errors[scoring['k']] && <ErrorText msg="Numeric only" />}
													</span>
												</div>
												<div
													style={{
														width: '60px',
														flexDirection: 'row'
													}}>
													<Controller
														control={control}
														render={({ field: { onChange, onBlur, value } }) => (
															<Input
																maxLength="3"
																required={true}
																className="text-center"
																value={value}
																onChange={(value) => onChange(value)}
																onBlur={onBlur}
																style={

																		errors[scoring['k']] ? {
																			border: '1px dashed red'
																		} :
																		null
																}
															/>
														)}
														name={scoring['k']}
														rules={{
															required: true,
															pattern: {
																value: /[0-9]/,
																message: 'Only Number'
															}
														}}
														defaultValue={scoring['v'].toString()}
													/>
												</div>
											</div>
										</TabPane>
									))
								)}
							</TabContent>
						</div>
					</div>
				</PerfectScrollbar>
			</LoadingOverlay>
		</form>
	);
};

export default ScoringSettings;
