import React from 'react';
import store from '../store';
import { Route, Redirect } from 'react-router-dom';
import { setIntendedUrl } from '../helpers/auth';
import PropTypes from 'prop-types';
import { getStore } from '../helpers/common-methods';
import SimpleLayout from '../containers/SimpleLayout/SimpleLayout';

const propTypes = {
  component: PropTypes.func.isRequired,
  rest: PropTypes.object,
  location: PropTypes.object
};

const SinglePageAuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const { auth: { authenticated } } = getStore().getState();
      if (!authenticated) {
        setIntendedUrl(props.location.pathname+props.location.search);
      }

      return authenticated ? (
        <SimpleLayout Component={Component} {...props} />
      ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location }
            }}
          />
        );
    }
    }
  />
);

SinglePageAuthRoute.propTypes = propTypes;
SinglePageAuthRoute.displayName = 'Auth Route';

export default SinglePageAuthRoute;
