import React, { useEffect } from 'react'
// import { Colors } from '../../helpers/colors';
import { bettleLogo } from '../../helpers/icons';
import { motion } from 'framer-motion/dist/framer-motion';
import { animateSidebar, swipeSidebarTabPill, textActiveToSecondary } from '../../pages/NewUserOnboarding/animationValues';
import {default as MatchSidebar} from '../../containers/MatchmakerLayout/Sidebar/Sidebar';
import {default as LeagueSidebar} from '../../containers/V2Layout/Sidebar/Sidebar';
import MainSidebar from '../../containers/MainSidebar/MainSidebar';
import { useHistory } from 'react-router-dom';
import { useColors } from '../../helpers/CustomHooks/useColors';

const Sidebar = () => {
    const Colors = useColors();
    const [activeExp, setActiveExp] = React.useState('matches');
    const history  = useHistory();
    useEffect(() => {
      console.log('RERENDERED');
    }, [])

    const handleAppSwitch = (exp) => {
        setActiveExp(exp);
        if(exp == 'matches'){
            history.push('/newHome');
        }
        else if(exp == 'leagues'){
            history.push('/dashboard');
        }
    }
    
    return (
        <div className="sidebar-wrapper text-white d-flex flex-column" style={{backgroundColor:Colors.sidebarBody_v3, width:'20%', minWidth:'250px', overflow:'hidden'}}>
            {/* Header */}
            <div className='d-flex align-items-center' style={{height:70, backgroundColor:Colors.bgDark_v3}}>
                <div className='mx-4' style={{width:100}}>
                    {bettleLogo()}
                </div>
            </div>

            {/* Body */}
            <div className='my-4 mx-4 flex1 d-flex flex-column h-100'>
                <div>
                    <div className='d-flex align-items-center text-center position-relative' style={{backgroundColor:Colors.bgSecondary_v3, borderRadius:50, overflow:'hidden'}}>
                        <motion.h6 className='flex1 m-0 py-2 pointer' style={{zIndex:1}} animate={activeExp == 'matches' ? 'active' : 'secondary'}  transition={{duration:0.5}} variants={textActiveToSecondary} onClick={() => handleAppSwitch('matches')}>Matches</motion.h6>
                        <motion.h6 className='flex1 m-0 py-2 pointer' style={{zIndex:1}} animate={activeExp == 'leagues' ? 'active' : 'secondary'}  transition={{duration:0.5}} variants={textActiveToSecondary} onClick={() => handleAppSwitch('leagues')}>Leagues</motion.h6>

                        {/* Selected Pill */}
                        <motion.div 
                            className="w-50 position-absolute h-100" 
                            style={{backgroundColor:Colors.colorPrimary, borderRadius:50}}
                            animate={activeExp} initial={false}  transition={{duration:0.5}} variants={swipeSidebarTabPill}
                            >
                            </motion.div>
                    </div>
                </div>

                <div className='h-100 flex1 position-relative' style={{overflow:'hidden'}}>
                    <motion.div 
                    className='h-100 w-100 position-absolute'
                    animate={activeExp == 'matches' ? 'show' : 'hideLeft'} initial={false}  transition={{duration:0.5}} variants={animateSidebar}
                    >
                        <MainSidebar Component={MatchSidebar} />
                    </motion.div>
                    <motion.div 
                        className='h-100 w-100 position-absolute'
                        animate={activeExp == 'leagues' ? 'show' : 'hideRight'} initial={false}  transition={{duration:0.5}} variants={animateSidebar}
                    >
                        <MainSidebar Component={LeagueSidebar} />
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar