import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Modal, Input } from "reactstrap";
import ErrorText from "../../components/ErrorText";
// import { Colors } from "../../helpers/colors";
import { STYLES } from "../../helpers/stylesHelper";
  
  export const GidxCVVModal = (props) => {
      const {modal, toggle, setCVV} = props
      const { formState: { errors } , handleSubmit, control } = useForm()

      const onSubmit = (data) => {
        setCVV("securityCode", data["securityCode"])
        toggle()
      }

      return (        
       <Modal isOpen={modal}>
            {/* <i onClick={toggle} class="cui-circle-x icons pointer modalClose"></i> */}
            <div className="my-4" style={{padding:15, borderRadius: 10, height: 300}}>
                <div className="d-flex flex-column" style={{alignItems: "center", flex: 1, marginTop: 10}} >
                    <div className="lead my-3" >For security reason, please insert your CVV</div>
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => {
                        return <Input type="number" onChange={e => onChange(e.target.value)} value={value} onBlur={onBlur} />
                        }}
                        name="securityCode"
                        rules={{ required: {value: true, message: "CVV is required"} }}
                    />
                    {errors.securityCode && <ErrorText msg={errors.securityCode.message}  />}
                    <Button onClick={handleSubmit(onSubmit)} className="my-3" style={STYLES.BUTTON_PRIMARY_TRANSPARENT} >Continue</Button>
                </div>
            </div>                
        </Modal>
    );
  };
  