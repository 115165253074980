import React, { useState, useEffect, useContext } from 'react'
import { plusIcon } from '../../../helpers/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getLeagues } from '../../../apis/Sidebar/LeagueAPI';
import { motion } from 'framer-motion/dist/framer-motion';
import { shrinkVertical } from '../../../pages/NewUserOnboarding/animationValues';
import { ColorContext } from '../../../context/ColorProvider';
import { getSportColor } from '../../../helpers/stylesHelper';
function LeaguesHeader(props) {
  
  const Colors = useContext(ColorContext);

  const [leagues, setLeagues] = useState([]);

  const handleLeagueChange = (league) => {
    props.saveActiveLeague(league);
    props.updateActiveLeagueId(league.id, null);
  };

  useEffect(() => {
    getLeagues().then((resp) => {
      setLeagues(resp?.on?.sl);
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  return (
    <div className='d-flex flex-column' style={{ overflow: 'hidden' }}>
      <div className='d-flex flex-grow-1 pl-4 align-items-end scrollHidden' style={{ background: Colors.arenaHeader_v3, height: "112px" }}>
        <div className='d-flex align-items-center' style={{ height: "76px" }} >
          <div style={{ width: 10 }}></div>
          <div className='d-flex align-items-center justify-content-center my-auto pointer' style={{ height: 50, width: 50, borderRadius: 50, backgroundColor: Colors.bgDark_v3, padding: "10px" }} onClick={() => props.history.push("/new-user-onboarding")}>
            {plusIcon()}
          </div>
          <div style={{ width: 10 }}></div>
        </div>

        <PerfectScrollbar className='d-flex align-items-end' style={{ overflowX: "auto" }}>
          {leagues?.map((league, index) => (
            <div key={index} className='d-flex pointer' style={{ height: "76px" }} onClick={() => handleLeagueChange(league)}>
              {/* <div style={{ width: 8, backgroundColor: props.activeLeague.id == league.id ? Colors.bgDark_v3 : Colors.arenaHeader_v3 }}>
                <div className='h-100' style={{ backgroundColor: Colors.arenaHeader_v3, borderBottomRightRadius: '16px' }}></div>
              </div> */}
              <div style={{ background: props.activeLeague.id == league.id ? Colors.bgDark_v3 : "", borderTopRightRadius: "16px", borderTopLeftRadius: "16px" }}>
                <div className='d-flex flex-column justify-content-center align-items-center h-100' style={{ width: "88px" }} >
                  <img className='img-fluid roundImgMed' src={league.image} style={{borderColor: `${getSportColor(league.sport)}`}} />
                </div>
              </div>
              {/* <div style={{ width: 8, backgroundColor: props.activeLeague.id == league.id ? Colors.bgDark_v3 : Colors.arenaHeader_v3 }}>
                <div className='h-100' style={{ backgroundColor: Colors.arenaHeader_v3, borderBottomLeftRadius: '16px' }}></div>
              </div> */}
            </div>
          ))}
        </PerfectScrollbar>
      </div>

      <div className='d-flex align-items-center' style={{ overflow: 'hidden', backgroundColor: Colors.bgDark_v3 }}>
        <motion.div
          className='d-flex align-items-center px-5'
          style={{ height: '112px' }}
          animate={props.activeLeague.id == 'all' ? 'collapse' : 'expand'}
          transition={{ duration: 0.5 }}
          initial={false}
          variants={shrinkVertical}
        >
          {
            props.activeLeague.id == 'all'
              ?
              <div className='d-flex align-items-center justify-content-center my-auto roundImgMed' style={{ height: 70, width: 70, borderRadius: 50, backgroundColor: Colors.bgDark_v3 }}><h4>{props?.activeLeague?.league_name}</h4></div>
              :
              <img className='roundImgMed' style={{ height: 55, width: 55, borderColor: `${getSportColor(props.activeLeague?.sport)}` }} src={props?.activeLeague?.image}/>
          }
          <div className='ml-3'>
            <h5 className='m-0 roboto'>{props?.activeLeague?.league_name}</h5>
            <p className='m-0 small-font roboto' style={{ color: Colors.textSecondary_v3 }}>{props?.activeLeague?.team_name}</p>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default LeaguesHeader;