import React, { useState } from 'react';
import { generateMessage } from '../../../../helpers/MessageHelper'

const SidebetProcessed = (props) => {
    let { data } = props.data.attachments[0];
    let { id } = props.auth.user;
    const message = {
            winner: "🤑 Boom! You won your bet with",
            loser: "😬 Can't win them all! You lost your bet with",
            generic: "lost the bet with"
          }

  return (
    <>
      {/* <p>{generateMessage(data, props.auth.user && props.auth.user.id, props.history, message)}</p> */}
      <p>{generateMessage(data, id, props.history, message)}</p>
        <p><span className='bold'>Bet Type: </span>{data.betType}</p>
        <p><span className='bold'>{data.winner} Points:</span> {data.winnerPoints}</p>
        <p><span className='bold'>{data.looser} Points:</span> {data.looserPoints}</p>
        {
            data.betType == 'Player vs Player' &&
            <>
                <p><span className='bold'>{data.winner} Players:</span> {data.winnerPlayers}</p>
                <p><span className='bold'>{data.looser} Players:</span> {data.looserPlayers}</p>
            </>
        }
        <p><span className='bold'>Timeframe:</span> {data.duration}</p>
        <p><span className='bold'>Amount:</span> ${data.amount}</p>
    </>
  );
};

// const mapStateToProps = (state) => ({auth: state.auth});

export default SidebetProcessed;