export const tradePrposals = (leagueId) => {
    return window.axios.get('/sl/pendingTrades?league_id='+leagueId).then((response) => {
      return Promise.resolve(response.data.data);
    }).catch(error => {
      return Promise.reject(error);
    });
  }

  export const pendingClaims = (reqData) => {
    return window.axios.post('/sl/fetchPendingClaims', reqData).then((response) => {
      return Promise.resolve(response.data.data);
    }).catch(error => {
      return Promise.reject(error);
    });
  }

  export const swapClaims = (reqData) => {
    return window.axios.post('/sl/swapClaims', reqData).then((response) => {
      return Promise.resolve(response.data.data);
    }).catch(error => {
      return Promise.reject(error);
    });
  }

  export const editClaim = (reqData) => {
    return window.axios.post('/sl/updateClaimBid', reqData).then((response) => {
      return Promise.resolve(response.data.data);
    }).catch(error => {
      return Promise.reject(error);
    });
  }

  export const cancelClaim = (id) => {
    return window.axios.post('/sl/cancelClaim', id).then((response) => {
      return Promise.resolve(response.data.data);
    }).catch(error => {
      return Promise.reject(error);
    });
  }
  export const deleteTrade = (reqData) => {
    return window.axios.post('/sl/updateTradeState', reqData).then((response) => {
      return Promise.resolve(response.data.data);
    }).catch(error => {
      return Promise.reject(error);
    });
  }

  export const updateTradeOffer = (reqData) => {
    return window.axios.post('sl/updateTradeOffer', reqData).then((response) => {
      return Promise.resolve(response.data.data);
    }).catch(error => {
      return Promise.reject(error);
    });
  }
  
  