export const destructServerErrors = error => {
  if (error.response && error.response.data) {
    if (error.response.data.errors) {
      return error.response.data.errors;
    } else {
      return error.response.data;
    }
  }

  return '';
};

export const generalError = (response) => {
  return response.data.message;
};

export const hasError = (errors, field) => {
  return !window.window._.isEmpty(errors[field]);
};

export const getError = (errors, field) => {
  let error = errors[field];
  return error;
};


export const hasSuccess = (successes, field) => {
  return !window.window._.isEmpty(successes[field]);
};

export const getSuccess = (successes, field) => {
  let success = successes[field];
  return success;
};