import React, { Component } from 'react';
import { Button } from "reactstrap"
import attribution from '../../../../assets/images/Poweredby_100px-Black_VertLogo.png'
import { IMAGES_S3 } from '../../../../config/app';
import { randomGif } from '../../../../apis/GiphyAPI/GiphyAPI';
import { toastError } from '../../../../helpers/toasts';
import { ColorContext } from '../../../../context/ColorProvider';


class Giphy extends Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultUri: IMAGES_S3 + 'giphy-loading.gif',
            uri: this.props.data.attachments[0] && this.props.data.attachments[0].data.uri,
            keyword: this.props.data.message_content
        }

        this.shuffle = this.shuffle.bind(this);
        this.cancel = this.cancel.bind(this);
        this.send = this.send.bind(this);
    }

    static contextType = ColorContext;

    componentDidMount() {
        // If giphy component already has a GIF we don't need to shuffle (It is already a sent message)
        if (this.state.uri == null) {
            this.shuffle()
        }
    }

    componentDidUpdate(prevProps, prevState) {

    }

    // Send the GIF to server 
    send = () => {
        let data = this.props.data
        data['attachments'] = [{
            data: {
                uri: this.state.uri
            },
            template: 'GIPHY'
        }]
        this.props.handleCommand(data)
    }

    // Shuffle to fetch a new GIF
    shuffle = () => {

        this.setState({
            uri: this.state.defaultUri
        })

        randomGif(this.state.keyword && this.state.keyword.replace('/giphy', ''))
            .then((data) => {
                this.setState({
                    uri: data.images.downsized_medium.url
                })
            })
            .catch(error => {
                toastError("Something went wrong! Can't shuffle");
            })
        // Fetch a new GIF based on current keyword
    }

    // Abort the GIF to be sent
    cancel() {
        this.props.removeMessageFromState()
        this.setState({
            defaultUri: null,
            uri: null,
            keyword: null
        })
    }

    state = {}



    render() {
        const Colors = this.context;
        return (
            <>
                <p className='mb-2'>{this.props.data.message_content}</p>

                <div className='d-flex'>
                        <span className='mr-2' style={{ borderLeft: `5px solid ${Colors.darkBgBorder}`, borderRadius: 50 }}></span>
                    <div>
                        {/* GIPHY GIF */}
                        <img className=' rounded' src={this.state.uri} height='250px'></img>

                        {/* ATTRIBUTION */}
                        <img className='d-block mt-1' src={attribution} style={{ width: 50 }} alt='powered by GIPHY'></img>
                    </div>
                </div>

                {
                    !this.props.data.attachments[0].data.uri ?
                        <div className='d-block mt-2'>
                            <Button onClick={this.send} className='mx-2' style={{ width: '10%', minWidth: 100, color: Colors.colorPrimary, backgroundColor: Colors.colorPrimaryTransparent, border: `1px solid ${Colors.colorPrimary}` }}>
                                Send
                            </Button>

                            <Button onClick={this.shuffle} className='mx-2' style={{ width: '10%', minWidth: 100, color: Colors.colorPrimary, backgroundColor: Colors.colorPrimaryTransparent, border: `1px solid ${Colors.colorPrimary}` }}>
                                Shuffle
                            </Button>

                            <Button onClick={this.cancel} className='mx-2' style={{ width: '10%', minWidth: 100, color: Colors.colorPrimary, backgroundColor: Colors.colorPrimaryTransparent, border: `1px solid ${Colors.colorPrimary}` }}>
                                Cancel
                            </Button>
                        </div>
                        :
                        null
                }

            </>
        );
    }
}

export default Giphy;