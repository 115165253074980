import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { updateActiveLeagueId, saveActiveLeague } from '../../apis/Sidebar/LeagueAPI';
import { Colors } from '../../helpers/colors';
import { ColorContext } from '../../context/ColorProvider';

class LeagueListPopover extends Component {
	constructor(props) {
		super(props);
		this.state = {
			leaguePopover: false
		};
		this.toggleLeagueList = this.toggleLeagueList.bind(this);
	}
	static contextType = ColorContext;

	toggleLeagueList() {
		this.setState({
			leaguePopover: !this.state.leaguePopover
		});
	}

	handleUpdateActiveLeagueId(leagueId) {
		if (leagueId != this.props.leagueId) {

			// Redirect to dashboard before updating active league to
			// prevent any unwanted/broken API calls on current component
			this.props.history.push('/dashboard');
			this.props
				.updateActiveLeagueId(leagueId)
				.then((response) => {
					let active = this.props.allLeagues.find((league) => {
						return league.id == leagueId;
					});
					this.props.saveActiveLeague(active);
					this.props.switchApp('SEASON_LONG');
					this.setState({
						leaguePopover: false
					});
				})
				.catch((error) => this.setState({ errors: error.response }));
		} else {
			this.props.switchApp('SEASON_LONG');
		}
	}

	render() {
		const Colors = this.context;
		return (
			<div className="d-flex align-items-center my-1" style={{ cursor: 'pointer' }} id="leagueListPopover" onClick={this.toggleLeagueList}>
				<div className="d-flex text-center" style={{ height: 30, width: 30, borderRadius: 5, backgroundColor: Colors.colorPrimary }}>
					<i class="fa fa-chevron-up align-self-center m-auto" aria-hidden="true" />
				</div>
				<p className="ml-2 my-0 bold">View Leagues</p>
				<Popover class="w-100" innerClassName="bg-transparent" style={{ backgroundColor: Colors.bgLight}} toggle={this.toggleLeagueList} isOpen={this.state.leaguePopover} placement="top" target="leagueListPopover">
					<PopoverBody style={{ backgroundColor: Colors.bgLight, borderRadius: 10, maxHeight:'80vh', overflow:'auto' }}>
						{this.props.allLeagues.map((league) => {
							return (
								<div
									className="d-flex my-1 p-2"
									style={{
										backgroundColor: `${
											this.props.leagueId == league.id ? Colors.colorPrimary :
											Colors.bgDark}`,
										cursor: 'pointer',
										borderRadius: 5
									}}
									onClick={() => this.handleUpdateActiveLeagueId(league.id)}>
									<div style={{ overflow: 'hidden', width: 40, height: 40, borderRadius: 5 }}>
										<img className="img-responsive" src={league.image} width="100%" height="100%" style={{ objectFit: 'cover' }} />
									</div>

									<p
										className={`ml-2 flex1 my-0 text-truncate ${
											this.props.leagueId == league.id ? '' :
											'text-white'}`}>
										<span className="bold text-truncate">{league.league_name}</span>
										<br />
										<span>{league.sport}</span>
									</p>
									{/* <UncontrolledTooltip placement="top"  target={`league-${league.league_name}`}>{league.league_name}</UncontrolledTooltip> */}
								</div>
							);
						})}
					</PopoverBody>
				</Popover>
				{/* <LeagueListPopover isOpen={true} toggle={this.toggleLeagueList}/> */}
			</div>
		);
	}
}
const mapDispatchToProps = { updateActiveLeagueId, saveActiveLeague };
export default connect(null, mapDispatchToProps)(withRouter(LeagueListPopover));
