import React from 'react'
import { Colors } from '../../helpers/colors'
import { StickyTable, Row, Cell } from 'react-sticky-table'
import moment from 'moment-timezone'
const ArenaNbaGameLogs = (props) => {

    const { gamelogs } = props
  return (
    <StickyTable className="my-2 scrollbar ff-scrollbar" style={{borderRadius: 10}}>
        {/* Header */}
        <Row className=' px-1' leftStickyColumnCount={0} style={{backgroundColor: Colors.playerCardTableHeader}}>
            <Cell className=' mr-1 small border-0' style={{backgroundColor: Colors.playerCardTableHeader}}>Date</Cell>
            <Cell className=' mr-1 small border-0' style={{backgroundColor: Colors.playerCardTableHeader}}>OPP</Cell>
            {
                gamelogs.headers.map((header, index) => {
                    return (
                        <Cell className=' mr-1 small border-0' style={{backgroundColor: Colors.playerCardTableHeader}} key={index}>{gamelogs.statAbbr[header]}</Cell>
                    )
                })
            }
        </Row>
        {
            gamelogs.logs.map((log, index) => {
                return (
                    <Row className=' px-1' key={index} >
                        <Cell className=' mr-1 small border-0' style={{backgroundColor: index%2 == 1 ? Colors.bgDark_v3: Colors.bg400}}>{moment(log.meta.date).format("MMM D")}</Cell>
                        <Cell className=' mr-1 small border-0' style={{backgroundColor: index%2 == 1 ? Colors.bgDark_v3: Colors.bg400}}>@{Object.keys(log.meta.opp)[0]}</Cell>
                        {
                            gamelogs.headers.map((header, idx) => {
                                return (
                                    <Cell className=' mr-1 small border-0' key={idx} style={{backgroundColor: index%2 == 1 ? Colors.bgDark_v3: Colors.bg400}}>{ isNaN(parseFloat(log.stats[header]).toFixed(2)) ? 0 : Number(parseFloat(log.stats[header]).toFixed(2))}</Cell>
                                )
                            })
                        }
                    </Row>
                )
            })
        }
    </StickyTable>
  )
}

export default ArenaNbaGameLogs