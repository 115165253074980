/**
 * @file useColors.js
 * @description Custom hook to use the color context.
 * @see https://reactjs.org/docs/hooks-custom.html
 */
import { useContext } from 'react';
import { ColorContext } from '../../context/ColorProvider';

export const useColors = () => {
    return useContext(ColorContext);
};