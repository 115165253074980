import React, { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import ComparionView from '../../components/Matchmaker/ComparisonView';

const ComparisonViewModal = (props) => {
    const {modal, toggle} = props
	return (
        <Modal isOpen={modal} size="lg" >
            <i onClick={() => toggle()} class="cui-circle-x icons pointer modalClose"></i>
            <ComparionView {...props} />
        </Modal>
	);
}
export default ComparisonViewModal;
