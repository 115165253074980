import React, { Component } from 'react';
import logo from '../../../assets/images/bettle-icon.png';
import { ListGroup } from 'reactstrap';
import { ColorContext } from '../../../context/ColorProvider';

class MentionsShortcut extends Component {

  constructor(props) {
    super(props);
  };

  static contextType = ColorContext;

  // mounting
  componentDidMount() {
    document.addEventListener('keydown', this.handleShortcutWithArrows);
    this.props.updateParentState({popup_enabled: true})
  };

  // unmounting
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleShortcutWithArrows);
  }

  handleShortcutWithArrows = (e) => {
    const getList = document.getElementsByClassName('users-list');

    // ESCAPE KEY
    if (e.key === 'Escape') {
      // getMentionSection.style.visibility = "hidden";
      // this.props.updateParentState({ message: '', current_word: '' });
      this.props.updateParentState({popup_enabled: false})
    };

    // ARROW DOWN KEY
    if (e.key === 'ArrowDown' && getList) {
      if (this.props.shortcutType === 'mention' && this.props.shortcutState < getList.length) {
        getList[this.props.shortcutState].focus();
        this.props.updateParentState({ shortcutState: this.props.shortcutState + 1 });
      };
    };

    // ENTER KEY
    if (e.key === 'Enter' && this.props.shortcutType === 'mention') {
      e.preventDefault() // Prevent new lines from being added
      // Get all the users and find the selected user from popup
      const getUsers = Object.values(this.props.users);
      let target = getList[this.props.shortcutState-1];
      target.focus();

      const getMatchedUser = getUsers.find(user => target.getAttribute('id') == user.id);
      this.props.handleMention(getMatchedUser);
      this.props.returnFocus()
    };

    // ARROW DOWN KEY 
    if (e.key === 'ArrowUp' && getList) {
      if (this.props.shortcutType === 'mention' && this.props.shortcutState > 1) {
        getList[this.props.shortcutState-1].focus();
        this.props.updateParentState({ shortcutState: this.props.shortcutState - 1 });
      };
    };
  };

  render() {
    const Colors = this.context;
    return (
      <div className='position-absolute mentions' id='mention-section' style={{ transform: 'translateY(-50px)', bottom: '50%', zIndex: 999, backgroundColor: Colors.darkColor, borderRadius: 5, border: '3px solid #2A2D31' }}>
        <ul className='px-0' id='shortcuts' style={{ listStyle: 'none', maxHeight: '180px', overflowY: 'scroll' }}>
          {
            Object.values(this.props.users).filter(user => user.team_name.toLowerCase().includes(this.props.current_word.toLowerCase().replace('@', ''))).map((foundUser, index) => (
              <div key={foundUser.id} className='d-flex flex-row p-2 align-items-center' style={{ background: this.props.shortcutState == index + 1 && `${Colors.colorLink}` }}>
                <div>
                  <img src={foundUser.image} width='auto' height='15px' alt='Bettle Inc...' />
                </div>
                <div tabIndex='-1' id={foundUser.id} className='flex1 border-0 ml-2 users-list' style={{ cursor: 'pointer', listStyleType: 'none', overflowY: 'scroll' }} onClick={() => this.props.handleMention(foundUser)}>
                  
                      {foundUser.team_name} <span className='small' style={{ color: Colors.colorSecondary }}>({foundUser.name})</span>

                </div>
              </div>
            ))
          }
        </ul>
        <div style={{ backgroundColor: '#2A2D31' }} className='d-flex flex-row justify-content-between'>
          <div className='ml-2'><i class="fa fa-sort" aria-hidden="true"></i> to navigate</div>
          <div><span className='bold'>ENTER</span> to select</div>
          <div className='mr-2'><span className='font-weight-bold'>esc</span> to dismiss</div>
        </div>
      </div>
    )
  }
};

export default MentionsShortcut;