import React, { useState, useEffect } from 'react';
import { loadAttachment } from '../../../../apis/Chat/ChatAPI';
import { useColors } from '../../../../helpers/CustomHooks/useColors';
import PreviewImageModal from '../../../../modals/ChatModals/PreviewImageModal';

const ImagePreview = (props) => {

  const Colors = useColors();

  const [image, setImage] = useState(props.data.attachment_url);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (props.data.attachment_url == null && props.data.attachment_type == 'image') {
      loadAttachment(props.data.attachment, props.data.msg_id).then(img => {
        setImage(img);
      });
    };
  }, []);

  const handleImageModal = () => {
    setModalOpen(!modalOpen);
  };

  // style={{ maxWidth: '400px', borderLeft: `5px solid ${Colors.darkBgBorder}` }}

  return (
    <div className='pl-2 mt-2 mb-3'>
      {!image ? (
        <div className='d-flex justify-content-center align-items-center' style={{ background: `${Colors.bg400}`, width: '300px', height: '200px', borderRadius: 5 }}>
          Loading...
        </div>
      ) : (
        <img src={image} style={{ maxWidth: '300px', maxHeight: '300px', borderRadius: 10, cursor: 'pointer', border: `1px solid ${Colors.bg400}` }} onClick={handleImageModal} alt="" />
      )}
      {modalOpen && <PreviewImageModal isOpen={modalOpen} toggle={handleImageModal} onClose={handleImageModal} image={image} {...props} />}
    </div>
  )
};

export default ImagePreview;