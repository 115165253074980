import React, { Component } from 'react';
import Loader from 'react-loaders';
import LoadingOverlay from 'react-loading-overlay';
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { addClaim, fetchAddPlayerData } from '../../../apis/SeasonLong/PlayerAPI/PlayerAPI';
// import { Colors } from '../../../helpers/colors';
import { ColorContext } from '../../../context/ColorProvider';

class AddPlayer extends Component {
    constructor(props) {
        super(props);

        this.state = { 
            // Internal States
            dropPlayer:"",
            bidAmount:"",
            claimLoading: false,
            infoLoading: true,

            // API data states
            roster_size:0,
            my_roster_size:0,
            waiver_type:null,
            player:{},
            players:[
                {
                    full_name: "",
                    id: "",
                    image: "",
                    locked: false,
                    pos: "",
                    ppg: 0,
                    team: "",
                }
            ]
        }
        this.claimPlayer = this.claimPlayer.bind(this)
        this.dismissModal = this.dismissModal.bind(this)
    }
    static contextType = ColorContext;

    handleChange(e) {
        this.setState({
            [e.target.name]:e.target.value.trim() //Trim to make sure there's no whitespaces in amount
        })
    }

    componentDidMount() {
        this.fetchAddPlayerData()
    }

    // Make an API call to fetch fresh data about the player to be added
    fetchAddPlayerData() {
        let data = {
                player_id: this.props.data.player_id,
                league_id: this.props.data.league_id
            }
            
    fetchAddPlayerData(data)
        .then(response => {
            this.setState({
                roster_size: response.data.roster_size,
                my_roster_size: response.data.myRosterSize,
                player: response.data.player,
                players: response.data.players,
                waiver_type: response.data.waiver_type,
                infoLoading:false
            })
        })
        .catch(({message}) => {
            this.toast('error',message)
        })
    }

    // Claim the requested player
    claimPlayer() {

        // Required data
        let data = {
            league_id: this.props.data.league_id,
            player_id:this.props.data.player_id
        }

        // Conditional Data
        let dropPlayer = this.state.dropPlayer
        let bidAmount = this.state.bidAmount

        // Append to request data
        data["drop_player_id"] = dropPlayer
        if(this.state.waiver_type == "fau"){
            data["bid_amount"] = bidAmount
        }

        // Execute this logic if data from form is validated
        if(this.validateData(dropPlayer, bidAmount)){
            
            this.setState({
                claimLoading: true
            })

            addClaim(data)
                .then(response => {
                    // Toast to let user know about the success status (Claimed/Already Claimed)
                    if (response.data.msg) {
                        this.toast('info', response.data.msg)
                    }
                    else {
                        this.toast('success','Claim added succesfully')
                    }
                    this.dismissModal()
                    
                })
                .catch(error => {
                    this.setState({
                        claimLoading: false
                    })
                    this.toast('error','Something went wrong! Try Again.')
                })
        }
    }
    
    dismissModal() {
        this.props.toggle()
    }

    

    validateData(dropPlayer, bidAmount) {
        let validated = false
        const rosterIsFull = (this.state.my_roster_size >= this.state.roster_size)  // a
        const requireBidAmount = this.state.waiver_type == 'fau'                    // b 

        // add player without bid amount and dropping player (not a and not b)
        if (!rosterIsFull && !requireBidAmount) {
            validated = true
        }

        // Drop the player but no bid amount (a and not b)
        else if (rosterIsFull && !requireBidAmount) {
            if (dropPlayer != "") {
                validated = true
            }
            else {
                this.toast('error','Please select a player to drop')
                return false
            }
        }

        // Enter bid amount but no player drop (not a and b)
        else if(!rosterIsFull && requireBidAmount) {
            if (bidAmount != "") {
                if (isNaN(bidAmount)) {
                    this.toast('error','Invalid bid amount')
                    return false
                }
                else if (parseFloat(bidAmount) == 0) {
                    this.toast('error','Bid amount cannot be 0')
                    return false
                }
                else {
                    validated = true
                }
            }
            else {
                this.toast('error','Enter a Bid Amount')
                return false
            }
        }

        // Add player only if a player is selected to drop and bid amount is added (a and b)
        else if(rosterIsFull && requireBidAmount) {
            if (dropPlayer != "") {
                validated = true
            }
            else {
                this.toast('error','Please select a player to drop')
                return false
            }
            if (bidAmount != "") {
                if (isNaN(bidAmount)) {
                    this.toast('error','Invalid bid amount')
                    return false
                }
                else if (parseFloat(bidAmount) == 0) {
                    this.toast('error','Bid amount cannot be 0')
                    return false
                }
                else {
                    validated = true
                }
            }
            else {
                this.toast('error','Enter a Bid Amount')
                return false
            }
        }
        else {
            this.toast('error','Unhandled error')
            return false
        }

        return validated
    }

    dropPlayer() {
        if (this.state.my_roster_size >= this.state.roster_size) {
            return (
                <FormGroup>
                    <Label for="dropPlayer">Drop a Player</Label>
                    <Input type="select" name="dropPlayer" id="dropPlayer" onChange={this.handleChange.bind(this)} value={this.state.dropPlayer}>
                        <option value="">Select a Player</option>
                        {
                            this.state.players.map(player => {
                                return (
                                    <option value={player.id} key={player.id}>{player.full_name}</option>
                                )
                            })
                        }
                    </Input>
                </FormGroup>
            )
        }
    }

    bidAmount() {
        if(this.state.waiver_type == 'fau') {
            return (
                <FormGroup>
                    <Label for="bidAmount">Enter Bid Amount</Label>
                    <Input type="text" placeholder="50.5" name="bidAmount" id="bidAmount" onChange={this.handleChange.bind(this)} value={this.state.bidAmount}></Input>
                </FormGroup>
            )
        }
    }

    /**
     * Toast an error
     * @param {string} error - error to be toasted
     */
     toast(type,message) {
        const properties = {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            }
        switch(type) {
            case 'error':
                toast.error(message,properties)
                break
            case 'success':
                toast.success(message,properties)
                break
            case 'info':
                toast.info(message,properties)
                break
        }
        
        
    }


    render() { 
        const Colors = this.context;
        return (
            <LoadingOverlay 
            className='p-3'
                active={this.state.infoLoading}
                spinner
                text='Fetching...'
                fadeSpeed={500}
                styles={{
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(0,0,0,0.5)'
                    })
                }}
            >
                <p>You are making a claim for <span style={{color: Colors.colorPrimary}}>{this.props.data.player_name}</span></p>
                <Form>
                    {this.dropPlayer()}
                    {this.bidAmount()}
                    <Button className='w-100 my-1' style={{color: Colors.colorPrimary, backgroundColor: Colors.colorPrimaryTransparent ,border: `1px solid ${Colors.colorPrimary}`}} onClick={this.claimPlayer} disabled={this.state.claimLoading}>{this.state.claimLoading?<Loader type="ball-pulse" color={Colors.colorPrimary} />:'Claim'}</Button>
                    <Button className='w-100 my-1' style={{color: Colors.colorDanger, backgroundColor: Colors.colorDangerTransparent ,border: `1px solid ${Colors.colorDanger}`}} onClick={this.dismissModal}>Cancel</Button>
                </Form>
            </LoadingOverlay>
         );
    }
}
 
export default AddPlayer;