import React, { Component } from 'react';
import './richtext.css'
import { atIcon, cameraIcon, cancelIcon, gifIcon, mentionsIcon, pictureIcon, slashIcon, smiley, smileyIcon } from '../../helpers/icons';
import 'emoji-mart/css/emoji-mart.css'
import { Picker, Emoji } from 'emoji-mart'
import { Modal, Progress, UncontrolledTooltip } from 'reactstrap';
import { withCLickOutside } from 'react-click-outside'
import { featureIsEnabled, formatMentions } from '../../helpers/MessageHelper';
import MentionsShortcut from './Shortcuts/MentionsShortcut';
import CommandsShortcut from './Shortcuts/CommandsShortcut';
import { getStore } from '../../helpers/common-methods';
import { toastError, toastInfo } from '../../helpers/toasts';
import PreviewImageModal from '../../modals/ChatModals/PreviewImageModal';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ColorContext } from '../../context/ColorProvider';
class RichText extends Component {

    // Globals
    inCall = false;
    countInterval = null;
    clearInterval = 3000;
    thresholdInterval = 900;

    constructor(props) {
        super(props);
        this.state = {

            // Local states
            isOpened: false,
            message: '',
            current_word: '',
            picker: false,
            modal: false,
            chosenEmoji: null,
            shortcutType: null,
            shortcutState: 1,
            uploadImage: null,
            file: null,
            progressBar: null,
            modalOpen: false,

            // Shortcut State
            popup_enabled: true,

            enabledLeagues: [
                {
                    id: 454
                },
                {
                    id: 843
                },
                {
                    id: 876
                }
            ],

            shortcuts: [],

            // TEMP STATE
            shortcutsData: [
                {
                    id: 1,
                    name: 'giphy',
                },
                {
                    id: 2,
                    name: 'trade',
                },
                {
                    id: 3,
                    name: 'sidebet',
                },
                {
                    id: 4,
                    name: 'tradeblock',
                },
            ],
            shortcut: null,

            // Pusher states
            typingData: null
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleShortcut = this.handleShortcut.bind(this);
        this.toggle = this.toggle.bind(this);
        this.disableShortcutPopup = this.disableShortcutPopup.bind(this)
    }

    static contextType = ColorContext;

    componentDidMount() {

        // Subscribe typing channel on mount
        if (this.props.pusherChannel != null) {
            this.subscribeTypingChannel()
        }

        this.setState({
            shortcuts: [
                {
                    id: 1,
                    syntax: '/giphy ',
                    placeholder: '[text]',
                    details: 'send a gif',
                    enabled: true
                },
                {
                    id: 2,
                    syntax: '/trade',
                    placeholder: null,
                    details: 'send a trade proposal',
                    enabled: featureIsEnabled(this.props.leagueId, this.state.enabledLeagues)
                },
                {
                    id: 3,
                    syntax: '/sidebet',
                    placeholder: null,
                    details: 'create a sidebet',
                    enabled: true
                },
                {
                    id: 4,
                    syntax: '/tradeblock',
                    placeholder: null,
                    details: 'Trade away player positions',
                    enabled: featureIsEnabled(this.props.leagueId, this.state.enabledLeagues)
                },
            ]
        })

    }

    componentDidUpdate(prevProps, prevState) {

        // Subscribe typing channel on update
        if (this.props.pusherChannel != null && prevState.message != this.state.message) {
            this.subscribeTypingChannel()
        }

        if (prevProps.loader == true && this.props.loader == false) {
            if (this.props.params && this.props.params.openCommand == 'sidebet')
            this.handleShortcut('/sidebet');
        };
    }

    componentWillUnmount() {
        clearInterval(this.countInterval)
    }

    //   make them functional "/" and "@" and "/giphy"
    makeShortcutsFunctional = (shortcut) => {
        let el = document.getElementById('rtb-input');
        el.innerText = shortcut;
        this.setState({ message: shortcut, current_word: shortcut });
        if (shortcut == '/') {
            this.setState({ shortcutType: 'shortcut', shortcutState: 1 });
        } else if (shortcut == '@') {
            this.setState({ shortcutType: 'mention', shortcutState: 1 });
        };
    };

    /**
     * Handle text change in richtext box
     * @param {event} e - Rich Text Box handle changes
     */
    handleChange(e) {
        this.setState({
            message: e.target.innerText,
            current_word: this.getLastWordBeforeCaret(),
            picker: false,
        });

        // Enable the popup again if it was closed by escape or click listener and the current word is changed now
        if (this.state.current_word == '' && !this.state.popup_enabled) {
            this.setState({
                popup_enabled: true
            })
        }

        this.fireTypingEvent()
    }

    // FIRE THIS EVENT WHILE TYPING
    fireTypingEvent() {
        if (this.inCall == false) {
            this.inCall = true;
            setTimeout(() => {
                this.props.pusherChannel.trigger("client-typing", {
                    text: this.props.current_team.team_name + " is typing...",
                    id: this.props.current_team.id,
                });
                this.inCall = false;
            }, 2000);
        }
    };

    // Subscribe the typing channel for current active chat
    subscribeTypingChannel() {
        // UPDATE TYPING INDICATOR
        var count
        this.props.pusherChannel.bind("client-typing", (response) => {
            var temp = {}
            count++
            temp[response.id] = response.text
            this.setState({
                typingData: temp
            })

            // Add threasholdInterval to clearInterval everytime we have a response
            this.clearInterval += this.thresholdInterval

            // remove is typing indicator after clearInterval threshold ends
            setTimeout(() => {
                if (count == 0) {
                    var temp2 = { ...temp }
                    delete temp2[response.id]
                    this.setState({
                        typingData: temp2
                    })
                }
            }, this.clearInterval);
        })

        this.countInterval = setInterval(() => {
            count = 0
        }, this.clearInterval)

    }

    /**
     * Handle all the '/' shortcut commands
     * @param {string} shortcut - shortcut strng to be handled
     */
    handleShortcut(command) {
        let el = document.getElementById('rtb-input');
        if (command.includes('giphy')) // Don't send immediately, require user to type more
        {
            this.parameterizedCommand(command)
        }
        else if (command.includes('tradeblock') || command.includes('trade') || command.includes('sidebet')) // Send immediately, doesn't require user input
        {
            this.directCommand(command)
        }
        else {
            this.setState({
                message: '',
            })
            el.innerText = '';
            // this.props.handleMessage(shortcut, shortcut);
        }

        this.setState({
            current_word: '',
        })

    }

    /**
     * Handle single/multiple mentions inside the richtext box
     * @param {object} user - user object with user info 
     */
    handleMention = (user, Colors = this.context) => {
        let el = document.getElementById('rtb-input');
        el.innerHTML = el.innerHTML.replace(this.state.current_word, `<span style="color:${Colors.colorLink}">${'@' + user.name}</span> `);
        // el.innerText = el.innerText.replace(this.state.current_word,'') + '@['+user.name+']('+user.id+')';
        this.setState({
            message: el.innerHTML,
            current_word: ''
        })
    }

    /**
     * Commands that require user to input some more information go here
     * @param {string} command - command to be interpreted
     */
    parameterizedCommand(command) {
        this.setCommand(command)
    }

    /**
     * Commands that invoke immediately after being selected go here
     * @param {string} command - command to be interpreted
     */
    directCommand(command) {
        this.setCommand(command)

        // Wait for the command to be set in case of command clicked instead of typed
        setTimeout(() => {
            this.handleSubmit(this.state.message)
        }, 0);
    }

    /**
     * Set command to richtext box
     * @param {string} command - Command to be set into textbox
     */
    setCommand(command) {
        let el = document.getElementById('rtb-input');
        el.innerText = el.innerText.replace(this.state.current_word, '') + command;
        this.setState({
            message: el.innerText,
        })
        // console.log(this.state.message);
    }

    // Reset the richtext box input
    resetInput() {
        this.setState({
            message: '',
            current_word: '',
            shortcut: null,
            uploadImage: null,
            file: null
        })
        document.getElementById('rtb-input').innerText = '';
        this.setState({ picker: false })
    }

    // Handle submit on send
    handleSubmit(message) {
        let { isShortcut, command } = this.parseMessage(message)

        if (isShortcut) {
            if (command == 'giphy' && '/' + command == message.trim()) {
                toastError('Please enter giphy text')
                return 0
            }
        } else {
            let el = document.getElementById('rtb-input')
            let rawText = el.innerText.trim() //Trim to avoid any leading whitespaces
            
            // Check for possibility of containing mentions
            if (rawText.includes('@')) {
                message = formatMentions(rawText, this.props.users)

            }
            else {
                message = rawText
            }
        }
        if (this.state.uploadImage !== null) {
            const config = {
                onUploadProgress: (progressEvent) => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    if (percentCompleted == 100) {
                        getStore().dispatch({ type: "SET_MEDIA_UPLOAD", payload: null });
                    } else {
                        getStore().dispatch({ type: "SET_MEDIA_UPLOAD", payload: percentCompleted });
                    }
                }
            };
            this.props.handleMessage(message, null, this.state.file, this.state.uploadImage, config);
        } else {
            this.props.handleMessage(message, command);
        };
        this.resetInput();
    }

    // Parse the message to check if there is any shortcut
    parseMessage(message) {
        let isShortcut = false
        let command = null

        // For a command to work the first charachter in message must be a slash (/) and popup must've been enabled
        if (message[0] == '/' && this.state.popup_enabled) {
            let message = this.state.message
            if (message.includes('/giphy')) {
                isShortcut = true
                command = 'giphy'
            }
            else if (message == '/tradeblock') {
                isShortcut = true
                command = 'tradeblock'
            }
            else if (message == '/trade') {
                isShortcut = true
                command = 'trade'
            }
            else if (message == '/sidebet') {
                isShortcut = true
                command = 'sidebet'
            }
        }
        return { isShortcut, command };
    }

    //Get current word to find any shortcut text
    getLastWordBeforeCaret() {
        const containerEl = document.getElementById('rtb-input');
        if (containerEl.innerText.includes('/') && this.state.message == this.state.current_word) {
            this.setState({ shortcutType: 'shortcut', shortcutState: 1 });
        } else if (containerEl.innerText.includes('@')) {
            this.setState({ shortcutType: 'mention', shortcutState: 1 });
        };

        let preceding = '';
        let sel;
        let range;
        if (window.getSelection) {
            sel = window.getSelection();
            if (sel && sel.rangeCount > 0) {
                range = sel.getRangeAt(0).cloneRange();
                range.collapse(true);
                range.setStart(containerEl, 0);
                preceding = range.toString();
            }
        }
        let queryMatch = preceding.match(/([^\s]+)$/i);
        if (queryMatch) {
            return queryMatch[1];
        } else {
            return '';
        }
    }

    handleEnter(e) {
        let sendEnter = false;

        if (this.state.message.trim() == '') {
            sendEnter = false
            if (this.state.uploadImage) {
                sendEnter = true
            }
        }
        else if (this.state.message.trim() != '' && this.state.current_word[0] != '/' && this.state.current_word[0] != '@') {
            sendEnter = true
        }
        else if ((this.state.current_word[0] == '/' || this.state.current_word[0] == '@') && (!this.state.popup_enabled)) {
            sendEnter = true
        }

        // Check if message is not empty, also current word in not a command neither a mention
        if (sendEnter) {
            if (e.keyCode == 13 && !e.shiftKey) {
                e.preventDefault();
                this.handleSubmit(this.state.message)
                this.resetInput();
            }
        }
    }

    /**
     * Focus the Richtext Box in any situation when the focus is away for 
     * additional functionality such as creating mentions or firing commands
     * this method can be called after that to set focus at the end
     */
    focusRichtextBox() {
        let rtb = document.getElementById("rtb-input")
        rtb.focus();
        if (typeof window.getSelection != "undefined"
            && typeof document.createRange != "undefined") {
            var range = document.createRange();
            range.selectNodeContents(rtb);
            range.collapse(false);
            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        } else if (typeof document.body.createTextRange != "undefined") {
            var textRange = document.body.createTextRange();
            textRange.moveToElementText(rtb);
            textRange.collapse(false);
            textRange.select();
        }
    }



    addEmoji(emojiObject) {
        this.setState({ chosenEmoji: emojiObject.native })
        this.setState({ picker: false })
        // console.log(this.state.chosenEmoji)
        // this.setState({message: emojiObject.native});
        // console.log(this.state.message);
    }

    appendEmoji = (emoji) => {
        var message = this.state.message
        if (message) {
            message = message + emoji.native + " "
        } else {
            message = emoji.native + " "
        }
        var chatInput = document.getElementById("rtb-input")
        chatInput.innerText = message
        this.setState({ message: message, modal: false })
    }

    onToggleEmojiWindow = () => {
        this.setState({ picker: !this.state.picker });
    };

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    };

    updateParentState = (state) => {
        this.setState(state);
    };

    disableShortcutPopup() {
        this.setState({
            popup_enabled: false
        })
    };

    handleImageChange = (e) => {

        let sizeLimit = 5; // MB
        let reader = new FileReader();
        let file = e.target.files[0];
        let size = file.size / 1024 ** 2 // Size(B)/1024**2 = Size(MB)

        if (size <= sizeLimit) {
            reader.onloadend = () => {
                this.setState({ file: file });
                this.setState({ uploadImage: reader.result });
            };
            reader.readAsDataURL(file);
        }
        else {
            toastInfo(`File size cannot be larger than ${sizeLimit}MB`)
        }

        // Return focus to the richtext box
        e.target.value = null;
        this.focusRichtextBox();
    };

    handleImageModal = () => {
        this.setState({ modalOpen: !this.state.modalOpen });
    };

    render() {
        const Colors = this.context;
        return (
            <>
                <span className='ml-4' id='picker' >
                    {this.state.picker && <Picker set="apple" color={Colors.colorPrimary} autoFocus={true} onSelect={(emojiObject) => this.appendEmoji(emojiObject)} />}
                </span>
                {/* Input Container */}
                <div className={"row m-0 bottom " + this.props.className} id="input" onBlur={() => this.setState({ picker: false })}>

                    <div className="col-12 py-2 w-100" style={{ border: `1px solid ${Colors.darkBgBorder}`, backgroundColor: Colors.bgLight, borderRadius: 10 }}>
                        {this.state.progressBar &&
                            <Progress value={this.state.progressBar}>
                                {this.state.progressBar}
                            </Progress>
                        }

                        {/* Input box */}
                        <div className="row position-relative">
                            <div className="col px-4 position-relative  w-100 m-auto">

                                {/* Shortcuts */}
                                {
                                    !this.props.arena && this.state.popup_enabled && this.state.message == this.state.current_word && this.state.current_word[0] == '/'?
                                        <CommandsShortcut {...this.props} {...this.state} updateParentState={this.updateParentState} handleSubmit={this.handleSubmit} handleShortcut={this.handleShortcut} returnFocus={this.focusRichtextBox} /> : null
                                }
                                {/* !Shortcuts */}

                                {/* Mentions */}
                                {
                                    this.state.current_word[0] == '@' && this.state.popup_enabled ?
                                        <MentionsShortcut {...this.props} {...this.state} updateParentState={this.updateParentState} handleMention={this.handleMention} returnFocus={this.focusRichtextBox} /> : null
                                }
                                {/* !Mentions */}

                                {/* Rich textbox */}
                                <div className='my-2 text-break text-wrap scrollbar ff-scrollbar' role='textbox' id='rtb-input' onInput={this.handleChange.bind(this)} onKeyDown={(e) => this.handleEnter(e)} onClick={this.state.popup_enabled && ['/', '@'].some(shortcut => this.state.current_word.includes(shortcut)) ? this.disableShortcutPopup : ''} placeholder={this.props.placeholder} style={{ minHeight: '5vh', maxHeight: '40vh', whiteSpace: 'pre-wrap', overflowY: 'scroll' }} contentEditable>
                                </div>

                                {this.state.uploadImage !== null &&
                                    <div style={{ position: 'relative', display: 'inline-block' }}>
                                        <div style={{ overflow: 'hidden', maxWidth: 70, maxHeight: 70, border: `0.01em   solid ${Colors.darkBgBorder}`, borderRadius: 10 }}>
                                            <img className='img-fluid' onClick={this.handleImageModal} src={this.state.uploadImage} style={{ objectFit: 'cover', cursor: 'pointer' }} />
                                            <div className='d-flex rounded-circle' id='file-attachment' style={{ position: 'absolute', top: -7, right: -7, display: 'inline-block', cursor: 'pointer', border: `2px solid ${Colors.bgDark}` }} onClick={() => this.setState({ uploadImage: null, file: null })}>{cancelIcon()}</div>
                                            <UncontrolledTooltip target='file-attachment' delay={{ show: 0, hide: 0 }}>Remove File</UncontrolledTooltip>
                                        </div>
                                    </div>
                                }
                                {this.state.modalOpen && <PreviewImageModal isOpen={this.state.modalOpen} toggle={this.handleImageModal} onClose={this.handleImageModal} image={this.state.uploadImage} />}

                                {/* <input  rows='3' id='rtb-input' role='textbox' onChange={this.handleChange.bind(this)} value={this.state.message} className="py-3 w-100 border-0  text-white" /> */}
                            </div>
                        </div>
                        {/* !Input Box */}

                        {/* Bottom Icons */}
                        <div className="row w-100 m-auto" style={{ color: Colors.colorSecondary }}>
                            <div className="p-0 mr-1 d-flex align-items-center mx-1 col">
                                <span className='flex1'>
                                    <i className='mx-2' id='command' onClick={() => this.makeShortcutsFunctional('/')} style={{ cursor: 'pointer' }}>{slashIcon()}</i>
                                    <i className='mx-2' style={{ cursor: 'pointer' }} onClick={this.toggle} >{smileyIcon()}</i>

                                    <i className='mx-2' onClick={() => this.makeShortcutsFunctional('@')} style={{ cursor: 'pointer' }}>{atIcon()}</i>
                                    <i className='mx-2' onClick={() => this.makeShortcutsFunctional('/giphy')} style={{ cursor: 'pointer' }}>{gifIcon()}</i>
                                    <i className='mx-2' style={{ cursor: 'pointer', position: 'absolute' }}>{pictureIcon()}</i>
                                    <input type="file" accept="image/jpg, image/jpeg, image/png, image/gif" style={{ cursor: "pointer", position: 'relative', width: '30px', height: '20px', opacity: "0.0" }} defaultValue={this.state.uploadImage} onChange={this.handleImageChange} />
                                </span>
                                <span className="pull-right p-2" style={{ backgroundColor: ` ${this.state.message.trim() != '' || this.state.uploadImage !== null ? Colors.colorPrimary : ''}`, borderRadius: 3, color: '#fff', cursor: `${this.state.message.trim() !== '' || this.state.uploadImage !== null ? 'pointer' : 'not-allowed'}` }} onClick={this.state.message.trim() != '' || this.state.uploadImage !== null ? this.handleSubmit : null}>
                                    <i className="fa fa-send mx-1" />
                                </span>
                            </div>
                        </div>
                        <Modal isOpen={this.state.modal} toggle={this.toggle} style={{ position: 'absolute', top: '90px', right: '350px', bottom: 0, left: 0, zIndex: 10040, overflow: 'auto', overflowY: 'auto' }}>
                            <div className='modal-body' style={{ backgroundColor: Colors.bgLight, maxHeight: 'calc(100vh - 200px)', minHeight: 'calc(100vh - 300px)', overflowY: 'auto' }}>
                                <Picker style={{ width: '100%' }} set="apple" color={Colors.colorPrimary} autoFocus={true} onSelect={(emojiObject) => this.appendEmoji(emojiObject)} />
                            </div>
                        </Modal>
                        {/* !Bottom Icons */}
                    </div>
                    <p className='w-100' style={{ minHeight: 20, maxHeight: 20 }}>
                        {
                            this.state.typingData != null && this.state.typingData != undefined ?
                                Object.values(this.state.typingData).map((user, index) => (
                                    <p className='w-100 small' key={index} style={{ marginRight: 5 }}>{user}</p>

                                ))
                                :
                                null
                        }
                    </p>
                </div>
                {/* !Input Container */}

            </>
        );
    }
}

export default RichText;