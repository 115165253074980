import moment from "moment-timezone"
import { updateActiveExperience, updateUnreadNotificationApi } from "../../apis/User/UserAPI"
import store from "../../store"
import { experienceFromSport, getStore, MATCH_MAKER_BATTING_POS, MATCH_MAKER_PITCHING_POS, mergeAndSum } from "../common-methods"
import { mlbArenaIcon, nbaArenaIcon, nflArenaIcon } from "../icons"
import localforage from 'localforage';
import { setUserData } from "../../actions/auth"

export const updateDataInUsers = (vals) => {
    var currentUserState = JSON.parse(JSON.stringify(store.getState().arenaReducer.userData))
    Object.keys(vals).map((key) => {
        currentUserState[key] = vals[key]
    })
    store.dispatch({
        type: "SET_ARENA_USER_DATA",
        payload: currentUserState
    })
}

export const prepareArenaOpps = (opps, withCount = true, onlyGames = false) => {
    // var experience = STORE.getState().navReducer.experience
    var oppString = ""
    if(opps && opps instanceof Object && !opps.opp){
        var total = 0
        if(onlyGames){
            Object.values(opps).map((val) => {
                total = total + val["count"]
            })
            return total+ " games"
        }
        var oppCopy = Object.keys(JSON.parse(JSON.stringify(opps)))
        // const chunkSize = 2
        // for (let i = 0; i < oppCopy.length; i += chunkSize) {
            // const chunk = oppCopy.slice(i, i + chunkSize)
            oppString = oppString + oppCopy.map((team) => {
                if(opps[team]?.probable){
                    if(opps[team]?.atHome){
                        return " "+opps[team]["count"]+" ^"+team
                    }else{
                        return " "+opps[team]["count"]+" @ ^"+team
                    }
                }else{
                    if(withCount){
                        if(opps[team]?.atHome){
                            return " "+opps[team]["count"]+" "+team
                        }else{
                            return " "+opps[team]["count"]+" @ "+team
                        }
                    }else{
                        if(opps[team]?.atHome){
                            return " "+team
                        }else{
                            return " @"+team
                        }
                    }
                }
            }).join(",")
            // if(withCount && i != oppCopy.length-1 && oppCopy.length != 2){
            //     oppString = oppString + "\n"
            // }
        //}
    }else if (opps){
        if(opps.date){
            return "@"+opps.opp+" | "+opps.date
            // return "@"+opps.opp
        }else{
            return opps.opp
        }
    }else{
        return "NO GAME"
    }
    return oppString
}

export const toggleSideNotifications = () => {
    getStore().dispatch({
        type: "REFRESH_SIDE_NOT",
        payload: !getStore().getState().NavReducer.refreshSideNotification
    })
}

export const countMatches = (all_teams, type) => {
    var count = 0
    let key = type == "confirmed" ? "confirmedMatchCount" : "pendingCount";

    if(all_teams){
        Object.values(all_teams).map((team) => {
                count += team[key]
        })
    }
    return count
}

export const playerTotalStats = (player, statType, sport) => {
    if(statType == "total"){
        return mergeAndSum(player.totalStats, player.currentStats) 
    }else{
        return player.currentStats
    }
}

export const oppLength = (opps) => {
    if(opps && opps instanceof Object){
        let gamesCount = 0;
        gamesCount = Object.values(opps).reduce(((a, b) => a + b.count), 0)
        return gamesCount;
        // return Object.values(opps).length
    }
}

export const summedArr = (arr) => {
    return arr.reduce((a, b) => a + b);
}

export const currentTimeframe = () => {
    const timeFrame = store.getState().arenaReducer.selectedTimeframe
    if(timeFrame?.key){
        return timeFrame?.key
    }
    return "TD"
}

export const getScoringPeriod = () => {
    const timeFrame = store.getState().arenaReducer.selectedTimeframe
    return parseScoringPeriod(timeFrame.start, timeFrame.end)
}

export const parseScoringPeriod = (start, end) => {
    return moment(start).tz("America/New_York").format("ddd, MMM D") + " - " + moment(end).tz("America/New_York").format("ddd, MMM D")
}

export const enableSports= () => {
    return [
        "NBA",
        "MLB"
    ]
}

export const sportIcon = (sport) => { 
if (sport == "NFL") {
    return nflArenaIcon();
} else if (sport == "MLB") {
    return mlbArenaIcon();
} else if (sport == "NBA") {
    return nbaArenaIcon();
};
};

export const defaultTimezone = () => {
    return "America/New_York"
}

export const mlbPlayersByPos = (players, type) => {
    var positions = []
    var finalPlayers = []
    var players = JSON.parse(JSON.stringify(players));
    if(type == "hitters"){
        positions = MATCH_MAKER_BATTING_POS
    }else if (type == "pitchers"){
        positions = MATCH_MAKER_PITCHING_POS
    }
    positions.map((pos) => {
        for(var i= 0; i<players.length; i++){
            if(players[i].defaultPos == pos){
                finalPlayers.push(players[i])
                players.splice(i, 1)
                break
            }
        }
    })
    return finalPlayers
}

export const normalizedBalance  = (bal) => {
    return parseInt(String(bal)?.replace('.00', '')?.replace(/\./g,'')?.replace(',',''))
}

export const splittedBalance  = (bal) => {
    return [...String(bal)?.replace('.00', '')?.replace(/\./g,'')?.replace(',','')]
}

export const sportFromStorage =  () => {
    return localforage.getItem('sport')
}

export const setSportInStorage =  (sport) => {
    return localforage.setItem('sport', sport)
}

export const updateUnreadNotification = (user) => {
    const copiedUser = JSON.parse(JSON.stringify(user));
    copiedUser.unread_notification = null;
    
    updateUnreadNotificationApi(() => {
        store.dispatch(setUserData(copiedUser));
    }).catch((err) => {
        // console.log(err);
    });
}

export const findEligiblePositionsNfl = (nativePos) => {
    if(nativePos == "QB"){
        return ["QB"]
    }else if(nativePos == "RB"){
        return ["RB", "FLX"]
    }else if(nativePos == "WR"){
        return ["WR", "FLX"]
    }else if(nativePos == "TE"){
        return ["TE", "FLX"]
    }else if(nativePos == "FLX"){
        return ["RB", "WR", "TE"]
    }
    return []
}