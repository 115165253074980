import React, { useState } from "react"
import { oppLength, prepareArenaOpps } from "../../helpers/Arena/ArenaHelper"
import { parseInjuryIcons } from "../../helpers/common-methods"
import { useColors } from "../../helpers/CustomHooks/useColors"
import { injuryStatusDoubtful, injuryStatusOut, injuryStatusQuestionable } from "../../helpers/icons"
import ArenaPlayerModal from "../../modals/Arena/ArenaPlayerModal"

const PlayerMatchupRowNba = (props) => {
    const {data, sport} = props

    const Colors = useColors()

    const [playerModal, setPlayerModal] = useState(false)
    const [playerId, setPlayerId] = useState()

    const toggle = () => {
        setPlayerModal(!playerModal)
    }

    const openModal = (playerId) => {
        setPlayerId(playerId)
        toggle()
    }

    return (<div className="d-flex align-items-center mx-3 mb-1" >  
        <div className="d-flex flex-column" style={{flex: 1, padding: 5, borderRadius: 3}} >
        {data.team1 ?  <div  onClick={() => openModal(data.team1.playerId)} className="d-flex align-items-center" style={{borderBottom:`1px solid ${Colors.colorSecondary_v3}`}} >
                {/* <div className="position-relative">
                    <div style={{position: "absolute", right: -5, bottom: -5}}  >
                        {parseInjuryIcons(data.team1.injuryStatus)}
                    </div>
                </div> */}
                <div className="w-100" style={{marginLeft: 5}} >
                    <div className="d-flex align-items-center justify-content-between" >
                        <div style={{fontWeight: "bold", marginRight: 5}} >{data.team1.fullName} <span className="small ml-1" style={{color: Colors.colorSecondary}}>{data.team1.team}</span></div>
                        <div className="text-danger">{data.team1.injuryStatus}</div>
                    </div>
                    <div className="d-flex flex1 justify-content-between align-items-center" style={{ marginTop: 1}} >
                        <p className="small m-0" style={{color: Colors.colorPrimary}}>{data.team1.opps?.totalGames ?? oppLength(data.team1.opps)} games</p>
                        <p className="m-0 small">
                            {/* {prepareArenaOpps(data.team1.opps)} */}
                            {data.team1.opps?.hasB2b > 0 && `${data.team1.opps?.hasB2b} Back to Back` }
                        </p>
                    </div>
                </div>
            </div>: (
                <div style={{minHeight: "35px", borderBottom:`1px solid ${Colors.colorSecondary_v3}`}} className="d-flex align-items-center px-3"><div>Empty</div></div>
                )}
        </div>
        <div className="d-flex flex-column mx-3 justify-content-center" style={{width: 35, height:35, alignItems: "center", borderRadius:'50%', backgroundColor:Colors.colorSecondary_v3}} ><div style={{color: Colors.colorSecondary}} >{data.pos}</div></div>
        <div style={{flex: 1, padding: 5, borderRadius: 3}} >
            {data.team2 ? <div  onClick={() => openModal(data.team2.playerId)} className="d-flex align-items-center" style={{borderBottom:`1px solid ${Colors.colorSecondary_v3}`}}>
                {/* <div className="position-relative">
                    <div style={{position: "absolute", right: -5, bottom: -5}}  >
                        {parseInjuryIcons(data.team2.injuryStatus)}
                    </div>
                </div> */}
                <div className="w-100" style={{marginLeft: 5}} >
                    <div className="d-flex align-items-center justify-content-between" >
                        <div style={{fontWeight: "bold", marginRight: 5}} >{data.team2.fullName} <span className="small ml-1" style={{color: Colors.colorSecondary}}>{data.team2.team}</span></div>
                        <div className="text-danger">{data.team2.injuryStatus}</div>
                        {/* {data.team2.isStarting ? <PlayerActive w={10} h={10} i={8} /> : <PlayerNonActive w={10} h={10} i={8} />} */}
                    </div>
                    <div className="d-flex flex1 justify-content-between align-items-center" style={{ marginTop: 1}} >
                        <p className="small m-0" style={{color: Colors.colorPrimary}}>{data.team2.opps?.totalGames ?? oppLength(data.team1.opps)} games</p>
                        <p className="m-0 small">
                            {/* {prepareArenaOpps(data.team2.opps)} */}
                            {data.team2?.opps?.hasB2b > 0 && `${data.team2?.opps?.hasB2b} Back to Back` }
                        </p>
                    </div>
                </div>
            </div> : (
                <div style={{minHeight: "35px", borderBottom:`1px solid ${Colors.colorSecondary_v3}`}} className="d-flex align-items-center px-3" ><div>Empty</div></div>
            )}
        </div>
        {playerModal && <ArenaPlayerModal
            modal={playerModal}
            playerId={playerId}
            toggle={toggle}
        />}
    </div>)
}

export default PlayerMatchupRowNba