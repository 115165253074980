import React, { useEffect, useState } from "react"
import { Button, Collapse } from "reactstrap";
// import { mmRejectMatch } from "../../apis/Arena/MatchMakerApis";
// import { LoaderCenter } from "../../components/Loader";
// // import { Colors } from "../../helpers/colors";
// import { errorMessage, successMessage } from "../../helpers/common-methods";
// import { STYLES } from "../../helpers/stylesHelper";
import { mmRejectMatch } from "../../../apis/Arena/MatchMakerApis";
import { LoaderCenter } from "../../../components/Loader";
import { Colors } from "../../../helpers/colors";
import { errorMessage, successMessage } from "../../../helpers/common-methods";
import { backArrowIcon } from "../../../helpers/icons";
import { STYLES } from "../../../helpers/stylesHelper";
import RejectAction from "../ProposalActions/RejectAction";
import { useColors } from "../../../helpers/CustomHooks/useColors";

const RejectMatchCollapse = (props) => {
    const Colors = useColors()
    const {isOpen, toggle} = props

    const handleCancel = () => {
        toggle(true)
    }

    return (
        <Collapse isOpen={isOpen} style={{ backgroundColor: Colors.black_90 }}>
            <div className='d-flex align-items-center align-items-center pt-2'  style={{ color: Colors.colorLight }}>
                <div className='mx-2 pointer align-items-center d-flex' style={{width:20}} onClick={handleCancel}>{backArrowIcon()}</div>
                <p className='m-0'>REJECT</p>
            </div>
            <RejectAction {...props } />
        </Collapse>
    )
}

export default RejectMatchCollapse