import React from 'react';
import { motion } from "framer-motion/dist/framer-motion"
import { slideInOut } from './animationValues';
import { Colors } from '../../helpers/colors';
import { importIcon } from '../../helpers/icons';

const Welcome = (props) => {

    const { step, updateStep, hasLeague } = props

    const styles = {
        heading: {
            fontSize: "40px",
            fontWeight: "600"
        },
        s1: {
            fontSize: "20px",
            fontWeight: "600"
        },
        s2: {
            fontSize: "40px",
            fontWeight: "600"
        },
        s3: {
            fontSize: "16px",
            fontWeight: "500",
            color: "#B2B2B2"
        }
    }

    return (
		<motion.div
            className='flex-column text-center'
            animate={step == 'default' ? "show" : "hideLeft"}
            transition={{ duration: 0.5 }}
            initial={false}
            style={{display: "flex", flex: 0}}
            variants={slideInOut}
        >

            {step == "default" && <div className='alex' >
                <div style={{lineHeight: "45px"}} >
                    <p style={styles.heading} className='green-font mb-0' >Welcome to the</p>
                    <p style={styles.heading} className='green-font' >Bettle Universe.</p>
                </div>
                {!hasLeague && <div style={{marginTop: "50px"}} >
                    <p style={styles.s1} className="mb-0" >Get some action with</p>
                    <p style={styles.s2} >$10 of House Money.</p>
                    <p style={styles.s3} className="mb-0" >It’s risk-free to try, keep the winnings in cash.</p>
                    <p style={styles.s3} >(NO credit card info needed to get started!)</p>
                </div>}
                {hasLeague && <div style={{marginTop: "50px"}} >
                    <p style={styles.s1} className="mb-0" >Having you as a part of Bettle is a great honor!</p>
                </div>}
                <div>
                    <button
                        className="btn-pill green-btn"
                        style={{width: "496px", height: "56px", marginTop: "70px", color: "black"}}
                        onClick={() => updateStep("startImport")}>
                            <div className='d-flex justify-content-center align-items-center' >
                                {importIcon()}
                                <span className='alex' style={{fontSize: "16px", marginLeft: "5px"}} >IMPORT TEAM</span>
                            </div>
                    </button>
                </div>
            </div>}
        </motion.div>
    )
};

export default Welcome;