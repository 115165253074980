import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { ARENA } from '../helpers/arena_sports_mapping';
import { nflColors, mlbColors, nbaColors, defaultColors } from '../helpers/colors';

export const ColorContext = React.createContext();

const ColorProvider = ({children, app_status, activeLeagueSport, activeTeamSport}) => {
    const [colors, setColors] = React.useState({});

    useEffect(() => {
        if(app_status) {
            let status = app_status;

            if(app_status === 'SEASON_LONG') {
                status = parseSeasonLongExperience();
            }
            else {
                status = parseArenaExperience();
            }

            switch (status) {
                case 'SEASON_LONG_NBA':
                case 'BASKETBALL_ARENA':
                    setColors(nbaColors);
                    break;
                case 'SEASON_LONG_NFL':
                case 'FOOTBALL_ARENA':
                    setColors(nflColors);
                    break;
                case 'SEASON_LONG_MLB':
                case 'BASEBALL_ARENA':
                    setColors(mlbColors);
                    break;
                default:
                    setColors(defaultColors);
                    break;
            }
        }else{
            setColors(defaultColors);
        }
    }, [app_status, activeLeagueSport, activeTeamSport])
    
    const parseSeasonLongExperience = () => {
        let parsedExp = 'SEASON_LONG';
        switch (activeLeagueSport) {
            case "NFL":
                parsedExp = 'SEASON_LONG_NFL';
                break;
            case "MLB":
                parsedExp = 'SEASON_LONG_MLB';
                break;
            case "NBA":
                parsedExp = 'SEASON_LONG_NBA';
                break;
            default:
        }
        return parsedExp;
    }

    const parseArenaExperience = () => {
        return `${ARENA.SPORT_NAME_FROM_SPORT[activeTeamSport]}_ARENA`
    }

    return (
        <ColorContext.Provider value={colors}>
            {children}
        </ColorContext.Provider>
    );
};

const mapStateToProps = (state) => ({app_status: state.leagueJoin.app_status, activeLeagueSport: state.leagueJoin.activeLeagueSport, activeTeamSport: state.arenaReducer.sport})
export default connect(mapStateToProps, null) (ColorProvider);