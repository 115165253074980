import { toastError } from "../../helpers/toasts";

const ENDPOINTS = {
    // Sidebets
    SIDEBET_DATA: "/contest/sidebetPlayers",
    CREATE_SIDEBET: "/contest/createSidebet",
    ACCEPT_SIDEBET: "/contest/acceptSidebet",
    CANCEL_SIDEBET: "/contest/cancelContest",
    REJECT_SIDEBET: "/contest/rejectSidebet",
    UPDATE_MSG_ID: "/contest/updateMsgId",
    INVITE_VIA_SIDEBET: "/contest/inviteViaSidebet",
    PENDING_SIDEBET_INVITES: "/contest/pendingSidebetInvites",
    SIDEBET_STATUS: "/contest/updateSidebetInvite",

    // Contests
    LIVE_ACCEPTED: "/contest/acceptedLive",
    PENDING_LOBBY: "/contest/pendingLobby",
    FETCH_CONTEST_HOME: "/mlb/challenge/home",
    H2H_HOME: "contest/h2h",
    CONTEST_LEADERBOARD: "contest/leaderboard"

};

// SIDEBET APIs
export const fetchSidebetData = (params) => {
    return window.axios.get(ENDPOINTS.SIDEBET_DATA, {params})
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const createSidebet = (data) => {
    return window.axios.post(ENDPOINTS.CREATE_SIDEBET, data)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            if (error.response) {
                return Promise.reject(error.response.data);
            } else if (error.request) {
            return Promise.reject(error.request._response);
            } else {
            return Promise.reject(error.message);
            }
        })
} 

export const acceptSidebet = (data) => {
    return window.axios.post(ENDPOINTS.ACCEPT_SIDEBET, data)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const cancelSidebet = (data) => {
    return window.axios.post(ENDPOINTS.CANCEL_SIDEBET, data)
        .then(response =>{
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const rejectSidebet = (data) => {
    return window.axios.post(ENDPOINTS.REJECT_SIDEBET, data)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const updateMsgId = (data) => {
    return window.axios.post(ENDPOINTS.UPDATE_MSG_ID, data)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const inviteViaSidebet = (data) => {
    return window.axios.post(ENDPOINTS.INVITE_VIA_SIDEBET, data)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const pendingSidebetInvites = (params) => {
    return window.axios.get(ENDPOINTS.PENDING_SIDEBET_INVITES,{params})
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const updateSidebetInvite = (data) => {
    return window.axios.post(ENDPOINTS.SIDEBET_STATUS, data)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

// CONTEST APIs
export const liveAccepted = data => {
    return window.axios.get(ENDPOINTS.LIVE_ACCEPTED, data)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const pendingLobby = data => {
    return window.axios.get(ENDPOINTS.PENDING_LOBBY, data)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const fetchContestHome = (id, user_id = null) => {
    let url = ''
    if(user_id){
        url = ENDPOINTS.FETCH_CONTEST_HOME+'?ch_id='+id+"&user_id="+user_id
    }else{
        url = ENDPOINTS.FETCH_CONTEST_HOME+'?ch_id='+id
    }

    return window.axios.get(url)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}


export const h2hHome = (id, week) => {

    var url = ENDPOINTS.H2H_HOME+'?ch_id='+id
    if(week){
        url = url+"&week="+week
    }

    return window.axios.get(url)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error)
        })
};

export const nflH2H = (id, week) => {

    var url = 'contest/nfl/h2h?ch_id='+id
    if(week){
      url = url+"&week="+week
    }

    return window.axios.get(url).then((resp) => {
      // REST WILL HANDLE CODE INSIDE AUTH SERVICE
      return Promise.resolve(resp.data.data);
    }).catch((error) => {
      if (error.response) {
          return Promise.reject(error.response.data);
      } else if (error.request) {
        toastError(error.request._response)
      } else {
        toastError(error.message)
      }
    })
  };

export const contestLeaderboard = data => {
    return window.axios.get(ENDPOINTS.CONTEST_LEADERBOARD+"?ch_id="+data.ch_id+"&limit="+data.limit+"&offset="+data.offset+"&scoreboard="+data.scoreboard)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}