import React, { useState, useEffect } from 'react';

export const TeamVTeam = (props) => {
    return (
        <>
            <div className='d-flex align-items-center mb-2'>
                {/* My Team */}
                <div className='flex1 d-flex'>
                    <img className='img-fluid' style={{ height: 40 }} src={props.data.myteamMeta.teamImage} />
                    <div className='d-flex flex-column ml-2'>
                        <span className='d-block'>{props.data.myteamMeta.teamName}</span>
                        <span>Rec: {props.data.myteamMeta.teamRecord}</span>
                    </div>
                </div>

                {/* VS */}
                <div className='mx-3 bold'>vs</div>

                {/* Opp Team */}
                <div className='flex1 d-flex'>
                    {props.data.oppteamMeta && (
                        <>
                            <img className='img-fluid' style={{ height: 40 }} src={props.data.oppteamMeta.teamImage} />
                            <div className='d-flex flex-column ml-2'>
                                <span className='d-block'>{props.data.oppteamMeta.teamName}</span>
                                <span>Rec: {props.data.oppteamMeta.teamRecord}</span>
                            </div>
                        </>
                    )
                    }
                </div>
            </div>
        </>
    )
}
