import React, { Component } from 'react';
import NormalText from './NormalText/NormalText';
import Giphy from './Giphy/Giphy';
import Trade from './Trade/Trade';
import moment from 'moment-timezone';
import { smileyIcon } from '../../../helpers/icons';
import PlayerNews from './PlayerNews/PlayerNews';
import './message.css';
import { Badge } from 'reactstrap';
import ReactionModal from './RactionModal';
import Tradeblock from './Tradeblock/Tradeblock';
import Sidebet from './Sidebet/Sidebet';
import UrlPreview from './UrlPreview/UrlPreview';
import { ColorContext } from '../../../context/ColorProvider';

class Message extends Component {
    constructor(props) {
        super(props);
        this.state = {
            template: this.props.data?.attachments?.[0] ? this.props.data?.attachments?.[0].template : null,
            picker: false,
            pickerPosition: {}
        }

        this.removeMessageFromState = this.removeMessageFromState.bind(this)
        this.toggleReactions = this.toggleReactions.bind(this)
        this.handleCommand = this.handleCommand.bind(this)
        this.sendReaction = this.sendReaction.bind(this)
    }
    state = {}

    static contextType = ColorContext;

    removeMessageFromState() {
        this.props.removeMessageFromState(this.props.data.msg_id)
        //Code here (This will be a Main.js method passed as prop)
    }

    handleCommand(data, msg_type = null) {
        this.props.handleCommand(data, msg_type)
        //Code here (This will be a Main.js method passed as prop)
    }
    toggleReactions() {
        this.setState({
            picker: !this.state.picker
        })

        // // Coordinates
        // let coordinates = {
        //     x: e.clientX,
        //     y: e.clientY
        // }

        // setTimeout(() => {
        //     if (this.state.picker) {

        //         // Body
        //         let container = document.getElementById('picker-backdrop')

        //         // Picker
        //         let picker = container.nextSibling

        //         container = {
        //             height: container.offsetHeight,
        //             width: container.offsetWidth
        //         }

        //         picker = {
        //             height: picker.offsetHeight,
        //             width: picker.offsetWidth
        //         }


        //         console.log(container, picker, coordinates);
        //         // console.log("PICKER: ", container.offsetHeight, container.offsetWidth);
        //         // console.log(e);

        //         // Generate position of picker
        //         this.getPickerPlacement(container, picker, coordinates)
        //     }
        // }, 0);
        // }

        // getPickerPlacement(container, picker, coordinates) {
        //     let style = {position:'absolute', zIndex:100}

        //     let bottom = coordinates.y - picker.height
        //     let left = coordinates.x - picker.width

        //     if(bottom > 0) {
        //         bottom = container.height - bottom
        //         if(container.height - bottom < picker.height) {
        //             bottom = coordinates.y - picker.height
        //         }
        //     }

        //     if(left > 0) {
        //         left = container.width - left
        //         if(container.width - left < picker.width) {
        //             left = coordinates.x - picker.width
        //         }
        //     }

        //     console.log('BOTTOM: ', bottom);
        //     console.log('LEFT: ', left);
        //     if(bottom >= 0 && left >= 0) {
        //         // Check if top is above actual height
        //         // Check if left more than actual left
        //         style['bottom'] = bottom
        //         style['left'] = left
        //     }
        //     else if(bottom >= 0 && left < 0) {

        //         style['bottom'] = bottom
        //         style['right'] = 20
        //     }
        //     else if(bottom < 0 && left >= 0) {
        //         style['top'] = 0
        //         style['left'] = left
        //     }
        //     else if(bottom < 0 && left < 0){
        //         style['top'] = Math.abs(bottom)
        //         style['right'] = 20
        //     }

        //     this.setState({
        //         pickerPosition: style
        //     })
    }

    handleReactionClick(emoji) {
        let emojiData = {
            unified: emoji.codePointAt(0).toString(16),
            native: emoji
        }

        this.sendReaction(emojiData)
    }

    sendReaction(emojiObject) {

        this.setState({
            picker: false
        })

        let data = {
            emoji: emojiObject.unified.split('-')[0],
            emoji_icon: emojiObject.native,
            type: 'convo',
            id: this.props.data.msg_id
        }
        this.props.sendReaction(data)
        // console.log(emojiObject.unified);
        // let hex = emojiObject.native.codePointAt(0).toString(16)
        // console.log(hex);
    }

    render() {

        const Colors = this.context
        return (
            <>

                <div className="d-flex flex-row mt-2 text-white" id='message'>

                    {/* Dont Write code inside the block below this comment */}

                    {/* {
                    this.state.picker.primary &&
                    <>
                        <div className='position-absolute h-100 w-100' id='picker-backdrop' onClick={this.toggleReactionsPrimary} style={{top:0, left:0, zIndex:99}}>
                        </div>
                        <Picker set='apple' color={Colors.colorPrimary} autoFocus={true} style={{position:'absolute', zIndex:100, right:20, top:50}} onSelect={(emojiObject) => this.sendReaction(emojiObject)} />
                    </>
                }

                {
                    this.state.picker.secondary &&
                    <>
                        <div className='position-absolute h-100 w-100' id='picker-backdrop' onClick={this.toggleReactionsSecondary} style={{top:0, left:0, zIndex:99}}>
                        </div>
                        <Picker set='apple' color={Colors.colorPrimary} autoFocus={true} style={{position:'absolute', zIndex:100, left:20, top:50}} onSelect={(emojiObject) => this.sendReaction(emojiObject)} />
                    </>
                } */}

                    {/* Don't write code inside the block above this comment */}

                    <div className="ml-0 mr-2 p-0 position-relative">
                        {/* <i  className='fa fa-circle position-absolute' style={{color:Colors.colorPrimary,top:26, right:1}}></i> */}
                        <img width='40px' className="rounded" src={ this.props.users[this.props.data.user.id]?.teamImage || this.props.data.user.image} ></img>
                    </div>
                    <div className="m-0 flex1 p-0">

                        <h6 className='m-0 bold' >{ this.props.users[this.props.data.user.id]?.teamName || this.props.data.user.username }
                            <span className='ml-2 small' style={{ color: Colors.colorSecondary }}>
                                {moment(this.props.data.ts).local().format('LT')}
                            </span>
                        </h6>
                        {
                            (() => {
                                switch (this.state.template) {
                                    case 'GIPHY':
                                        return (
                                            <Giphy data={this.props.data} removeMessageFromState={this.removeMessageFromState} handleCommand={this.handleCommand} />
                                        )
                                    case 'TRADE_PROPOSAL':
                                    case 'TRADE_SENT':
                                    case 'TRADE_CANCELLED':
                                    case 'TRADE_ACCEPTED':
                                    case 'TRADE_REJECTED':
                                        return (
                                            <Trade bots={this.props.bots} status={this.state.template} data={this.props.data} removeMessageFromState={this.removeMessageFromState} handleCommand={this.handleCommand} />
                                        )
                                    case 'TRADE_BLOCK_CREATION':
                                    case 'TRADE_BLOCK_SENT':
                                        return (
                                            <Tradeblock status={this.state.template} data={this.props.data} removeMessageFromState={this.removeMessageFromState} handleCommand={this.handleCommand} />
                                        )
                                    case 'SIDEBET_CREATED':
                                    case 'SIDEBET_SENT':
                                    case 'SIDEBET_ACCEPTED':
                                    case 'SIDEBET_REJECTED':
                                    case 'SIDEBET_REMINDER':
                                    case 'SIDEBET_PROCESSED':
                                        return (
                                            <Sidebet status={this.state.template} data={this.props.data} removeMessageFromState={this.removeMessageFromState} handleCommand={this.handleCommand} />
                                        )
                                    case 'ROTO_WIRE':
                                    case 'PLAYER_DROPPED':
                                    case 'ADD_PLAYER':
                                        return (
                                            <PlayerNews data={this.props.data} status={this.state.template} sendMessage={this.handleCommand} />
                                        )
                                    case 'URL_PREVIEW':
                                    case 'URL_LOCAL':
                                        return (
                                            <UrlPreview data={this.props.data} status={this.state.template} />
                                        )
                                    default:
                                        return (
                                            <NormalText data={this.props.data} />
                                        )
                                }
                            })()
                        }
                    </div>
                    <div className='p-1 mr-2' id='message-actions' style={{ height: 30, borderRadius: 5, border: `1px solid ${Colors.darkBgBorder}`, backgroundColor: Colors.bgLight, transform: 'translateY(-50%)' }}>
                        <div className='m-auto mx-1' onClick={(e) => this.toggleReactions(e)} style={{ cursor: 'pointer', zIndex: 99 }}>
                            {smileyIcon()}
                        </div>
                    </div>
                </div>
                <div className='ml-5 mb-1'>
                    {
                        Object.values(this.props.data.reactions).map(reaction => {
                            // console.log(reaction);
                            return (
                                <span className='py-1 px-2 mr-1' style={{ backgroundColor: Colors.bgDark, borderRadius: 15, cursor: 'pointer' }} onClick={() => this.handleReactionClick(reaction.emoji_icon)}>{reaction.emoji_icon} {reaction.count}</span>
                            )
                        })
                    }
                    {
                        Object.keys(this.props.data.reactions).length != 0 && <i class="fa fa-plus-circle py-1 px-2" aria-hidden="true" onClick={(e) => this.toggleReactions(e)} style={{ backgroundColor: Colors.bgDark, borderRadius: 15, cursor: 'pointer' }}></i>
                    }

                </div>
                <ReactionModal isOpen={this.state.picker} toggle={this.toggleReactions} sendReaction={this.sendReaction}></ReactionModal>
            </>
        );
    }
}

export default Message;