import React, { useState } from 'react';
import {  Button } from 'reactstrap';
import store from '../store';
import { cognitoConfirmRegister, cognitoLogin } from '../actions/auth';
import { setInForage } from '../helpers/common-methods';
import { Controller, useForm } from 'react-hook-form';
import { useColors } from '../helpers/CustomHooks/useColors';
import ErrorText from '../components/ErrorText';

const EmailVerification = (props) => {
	const {email, password} = props
	const [error, setError] = React.useState();
	const [loading, setLoading] = React.useState(false);
	const { formState: { errors } , handleSubmit, control } = useForm(); // initialize the hook
	const color = useColors()
	const [otp, setOtp] = useState(['', '', '', '', '', '']);

	const handleOtpChange = (value, index, onChange) => {
	  const newOtp = [...otp];
	  newOtp[index] = value;
	  setOtp(newOtp);
	  onChange(newOtp.join(''))
	};

	const handleFoucus = (key, index) => {
	  if(key == "Backspace" && index > 0){
		  inputs[index - 1].focus();
	  }else if(index < 5 && key != 'Backspace'){
		  inputs[index + 1].focus();
	  }
	}

	const inputs = [];

	const verifyEmail = (data) => {
		setLoading(true)
		store
			.dispatch(cognitoConfirmRegister({ code: data.code, username: email }))
			.then((resp) => {
				store
					.dispatch(cognitoLogin({ email: email, password: password }))
					.then(async(resp) => {
						await setInForage('newUser', true);
						window.location.href = "/expCheck";
					})
					.catch((err) => {
						setError( err.message)
						setLoading(false)
					});
			})
			.catch((err) => {
				setError( err.message)
				setLoading(false)
			});
	}

	return (
		<div className='' >

			<div className="login-head-container mx-md-5 mx-2">
				<h1 style={{fontSize: "16px", fontWeight: "500"}} >Verification</h1>
			</div>

			<div className='my-4 px-md-5 px-2' >
				<p className="welcome-heading" style={{lineHeight: "37px"}} >For your security, please enter the 6-digit verification code that we’ve sent to <span className='green-font welcome-heading' >{email}</span></p>
				

				<div className='d-flex mb-2'>
					<span style={{ color: color.colorDanger }}>{error}</span>
				</div>

				<Controller
					control={control}
					render={({ field: { onChange, onBlur, value } }) =>
					{

						return <div className='my-4 d-flex'  >
							{otp.map((digit, index) => (
								<input
								className='d-flex flex1'
								key={index}
								style={{
									color: "white",
									width: 0,
									height: "76px",
									border: "none",
									background: "#1A1A1A",
									marginRight: "15px",
									textAlign: 'center',
									fontSize: "24px"
								}}
								maxLength={1}
								onChange={(e) => {handleOtpChange(e.target.value, index, onChange);}}
								onKeyUp = {(e) => handleFoucus(e.key, index)}
								value={digit}
								ref={(input) => {
									inputs[index] = input;
								}}
								/>
							))}
						</div>
					}}
					name="code"
					rules={
						{required:"Code is required"}
					}
					defaultValue={''}
				/>

				{errors?.code && <div>
					 <ErrorText msg={errors?.code?.message} />
				</div>}

				<Button onClick={handleSubmit(verifyEmail)} style={{ width: "100%", height: "48px", fontSize: "16px" }}  disabled={loading} className="btn-pill green-btn mb-3 mt-3">
					{
						loading ? 'Verifying...' :
						'Verify'}
				</Button>
			</div>
		</div>
	);
}

export default EmailVerification;
