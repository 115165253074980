import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'reactstrap';
// import { Colors } from '../../helpers/colors';
import { toggleGidxErrorModal } from '../../helpers/Payments/GidxHelper';
import { STYLES } from '../../helpers/stylesHelper';
import {useHistory} from "react-router-dom"
import store from '../../store';
import { useColors } from '../../helpers/CustomHooks/useColors';

const GidxErrorModal = (props) => {
    const Colors = useColors();
    const history = useHistory()
    const errorModal = store.getState().gidxErrorReducer.errorModal
    return (
        <div>
            <Modal size="lg" isOpen={errorModal.show} toggle={() => toggleGidxErrorModal()}>
               <div className="p-4 d-flex flex-column justify-content-center align-items-center" style={{minHeight: "300px"}} >
               <i onClick={() => toggleGidxErrorModal()} class="cui-circle-x icons pointer modalClose"></i>
               <h4 className='bold mb-3' >Oh, that's an error</h4>
                <i style={{fontSize: "5rem", color: Colors.colorDanger}} class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <div className='mt-4 text-center' >
                    <p className='mb-4' >{errorModal.data.msg}</p>
                    <Button onClick={() => {toggleGidxErrorModal(); errorModal.data.path ? history.push(errorModal.data.path) : ""}} style={STYLES.BUTTON_PRIMARY_TRANSPARENT} >Continue</Button>
                </div>
               </div>
            </Modal>
        </div>
    );
}

export default GidxErrorModal