import React, { useContext, useEffect, useState } from 'react';
import LineupPlayerRow from './LineupPlayerRow';
import { loadPlayers, mmTeamComparison } from '../../../apis/Arena/MatchMakerApis';
import { MLB_POS, NBA_POS, NFL_UNIQUE_POS } from '../../../helpers/common-methods';
import { LoaderCenter } from '../../Loader';
import ComparisonViewModal from '../../../modals/Arena/ComparisonViewModal';
import ArenaPlayerModal from '../../../modals/Arena/ArenaPlayerModal';
import { ColorContext } from '../../../context/ColorProvider';
import ComparionView from '../ComparisonView';
import { AppSwitch } from '@coreui/react';
import {StickyTable, Row, Cell} from 'react-sticky-table';
import moment from 'moment-timezone';
import ArenaPlayerSchedule from '../ArenaOnboarding/ArenaPlayerSchedule';
import { useSelector } from 'react-redux';
import CustomToggle from '../../subcomponents/CustomToggle';
import { mlbPlayersByPos } from '../../../helpers/Arena/ArenaHelper';
import MatchamakerSchedule from '../MatchamakerSchedule';
import MatchmakerSeasonAvg from '../MatchmakerSeasonAvg';

const DECLINE_COLOR = "rgba(245, 42, 33, 0.45)";
const ACCEPT_COLOR = "rgba(3, 242, 158, 0.45)";
const COUNTER_COLOR = "rgba(178, 140, 1, 0.4)";

const MatchMakerLineup = (props) => {
	const Colors = useContext(ColorContext);

	const { teamType, teamId, teamStatus, leagueId, proposedData, sport, direction, gameStarted } = props
	const [ players, setPlayers ] = useState([])
	const [ data, setData ] = useState()
	const [ loading, setLoading ] = useState(true)
	const [comparisonModal, setComparisonModal] = useState(false)
	const [ activeView, setActiveView ] = useState('h2h')
	const [comparisonData, setComparisonData] = useState()
	const [comparisonDataLoading, setComparisonDataLoading] = useState(true)
	const [teamView, setTeamView] = useState('my') //my or opp

	const timeFrame = useSelector(state => state.arenaReducer.selectedTimeframe)
	// const activeTeam = useSelector(state => state.arenaReducer?.active_team)

	useEffect(() => {
		// console.log('loading card number ' + props.cardIndex);

		var qs = '?teamType=' + teamType + '&teamId=' + teamId + '&teamStatus=' + teamStatus + '&leagueId=' + leagueId + '&sport=' + sport

		//if(sport == "NBA" || sport == "MLB"){
			if(proposedData){
				qs = qs+"&timeFrame="+proposedData?.timeFrameKey
			}else{
				qs = qs+"&timeFrame="+timeFrame?.key
			}
		//}

		if(proposedData?.matchId){
		    qs = qs + "&matchId="+proposedData.matchId 
		}

		loadPlayers(qs).then((resp) => {
			// console.log(resp);
			// ORDER PLAYERS
			var orderedPlayers = [];
			var defaultPos = [];
			if (sport == 'MLB') {
				defaultPos = MLB_POS;
			} else if (sport == 'NFL') {
				defaultPos = NFL_UNIQUE_POS;
			} else if (sport == 'NBA') {
				defaultPos = NBA_POS;
			}
			defaultPos.map((pos) => {
				resp.players?.map((pl) => {
					if (pl?.pos == pos) {
						orderedPlayers.push(pl);
					}
				});
			});
			setPlayers(orderedPlayers)
			setData(resp)
			setLoading(false);
		});
		return () => {
			console.log('unmounting', props.cardIndex);
		};
	}, [])

	useEffect(() => {
		var qs = "?sport="+sport;
        if(proposedData){
            qs = qs+"&matchId="+proposedData.matchId
        }else{
            qs = qs+"&teamId="+teamId
        }

		//if(sport == "NBA" || sport == "MLB"){
			if(proposedData){
				qs = qs+"&timeFrame="+proposedData?.timeFrameKey
			}else{
				qs = qs+"&timeFrame="+timeFrame?.key
			}
		//}

        mmTeamComparison(qs).then((resp) => {
            setComparisonData(resp)
			setComparisonDataLoading(false)
        })
	}, [teamId])

	const getOverlayText = () => {
		if(direction == "up"){
			return "Countered"
		}else if (direction == "right"){
			return "Proposed Matchup"
		}else if (direction == "left"){
			return "Decline"
		}
	}

	const getOverlayColor = () => {
		if(direction == "up"){
			return COUNTER_COLOR
		}else if (direction == "right"){
			return ACCEPT_COLOR
		}else if (direction == "left"){
			return DECLINE_COLOR
		}
	}

	const handleViewChange = (view) => {
		setActiveView(view)
		setTeamView("my");
	}

	return comparisonDataLoading ? (<LoaderCenter />) :
		(<div className="d-flex flex-column flex1 scrollHidden position-relative" style={{ backgroundColor: Colors.arenaLineupBg, borderRadius: 15 }}>

			<div className={
				direction ? "position-absolute h-100 w-100 d-flex flex-column justify-content-center align-items-center" : 
				"d-none"
				}
				style={{
					backgroundColor: getOverlayColor(),
					top: 0
					}}
				>
				<h1 className='bold' style={{color: direction == "left" ? "red" : Colors.colorPrimary}} >{getOverlayText()}</h1>
			</div>
			
			{proposedData && (
				<div style={{borderBottom: `.5px solid ${Colors.colorPrimary}`, background: Colors.black_90 }} >
					<p className='greenText my-2 text-center bold' >PROPOSED MATCHUP</p>
				</div>
			)}
			{sport != "NFL" && <div 
				// onClick={() => setComparisonModal(!comparisonModal)}
				className="pointer d-flex align-items-center"
				style={{ color: Colors.colorSecondary, backgroundColor: Colors.black_90, height: "48px"}}>
					<p className='m-0 flex1 p-2 pointer bold text-center' onClick={() => handleViewChange('h2h')} style={{fontSize: 16, color: activeView == 'h2h' ? Colors.colorPrimary : null, borderBottom: activeView == 'h2h' ? `2px solid ${Colors.colorPrimary}` : '0'}}>H2H</p>
					<p className='mb-0 flex1 p-2 pointer bold text-center' onClick={() => handleViewChange('schedule')} style={{fontSize: 16, color: activeView == 'schedule' ? Colors.colorPrimary : null, borderBottom: activeView == 'schedule' ? `2px solid ${Colors.colorPrimary}` : '0'}} >Schedule</p>
					<p className='mb-0 flex1 p-2 pointer bold text-center' onClick={() => handleViewChange('season')} style={{fontSize: 16, color: activeView == 'season' ? Colors.colorPrimary : null, borderBottom: activeView == 'season' ? `2px solid ${Colors.colorPrimary}` : '0'}} >{sport == "MLB" ? "Projections" : "Season Avg."}</p>
			</div>}
			{/* Single Player Row */}
			{
				{
					'h2h': 
						(
							<ComparionView 
								proposedData={proposedData}
								teamId={teamId}
								sport={sport}
								comparisonData={comparisonData}
							/>
						)
					,
					'schedule': (
						<MatchamakerSchedule
							teamView={teamView}
							setTeamView={setTeamView}
							comparisonData={comparisonData}
							sport={sport}
							comparisonDataLoading={comparisonDataLoading}
						/>
					),
					'season': (
						<MatchmakerSeasonAvg 
							teamView={teamView}
							comparisonData={comparisonData}
							sport={sport}
							comparisonDataLoading={comparisonDataLoading}
							setTeamView={setTeamView}
						/>
					)
					
				}[activeView]
			}
			{/* !Single Player Row */}
			{comparisonModal && <ComparisonViewModal
				modal={comparisonModal}
				toggle={() => setComparisonModal(!comparisonModal)}
				proposedData={proposedData}
				teamId={teamId}
				sport={sport}
			/>}
		</div>)
};

export default MatchMakerLineup;
