import React, { useEffect } from 'react'
import { motion } from 'framer-motion/dist/framer-motion'
import { expand, progressPercentage } from '../../pages/NewUserOnboarding/animationValues'
import { useColors } from '../../helpers/CustomHooks/useColors';

const LeaderboardsRow = (props) => {
    const { data, index, highestScore, leaderboardsType } = props;

    const Colors = useColors();
    
    const arenaColors = [
        '#03F29E',
        '#03DA8E',
        '#02C27E',
        '#02A96F',
        '#02915F',
        '#01794F',
        '#01613F',
        '#01492F',
        '#013020',
        '#001810',
    ]

    const referralColors = [
        '#FFDD7C',
        '#E5C770',
        '#CCB163',
        '#B39B57',
        '#99854A',
        '#806F3E',
        '#665832',
        '#4D4225',
        '#332C19',
        '#19160C',
    ]

    useEffect(() => {
        if(leaderboardsType === 'arena'){
            setRankColors(arenaColors)
        }
        else if(leaderboardsType === 'referral'){
            setRankColors(referralColors)
        }
    }, [leaderboardsType])
    

    const [rankColors, setRankColors] = React.useState(arenaColors);

    const getWidthPercentage = (currentScore, limit = 100) => {
        let totalPercentage = 100;
        let relativePercentage =  (currentScore / highestScore) * limit;
        let absolutePercentage = Math.ceil(totalPercentage-limit+relativePercentage);
        return absolutePercentage;
    }

    return (
        <div className='d-flex align-items-center my-2' style={{height:index == 0 ? 120 : 100, width:'100%', fontFamily:'Fritch'}}>
            <div style={{width:'5%'}}><h1 className='m-0 text-right px-3'>{index+1}</h1></div>
            <div className='bg-secondary' style={{maxWidth: index == 0 ? '15%' : '10%', height:'100%', overflow:'hidden'}}>
                <img className='img-fluid' style={{ width:'100%', objectFit:'cover'}} src={data.image}/>
            </div>
            {/* Bar */}
            <div style={{width: index == 0 ? '75%' : '80%', height:'100%'}}>
                <motion.div className='d-flex bold' style={{color:index == 0 ? '#000000' : '#ffffff' ,minWidth:'300px', height:'100%'}} animate={`${getWidthPercentage(data.winnings,50)}`} transition={{duration:0.5}} variants={progressPercentage}>
                    <div className="d-flex align-items-center justify-content-between m-0" style={{ height:'100%',backgroundColor:`${rankColors[index]}`, width:'100%'}}>
                        <h1 className='my-0 mx-2'>{data.name}</h1>
                        {leaderboardsType == "arena" ? (
                            <h1 className='my-0 mx-4'>${data.winnings}</h1>
                        ) : (
                            <h1 className='my-0 mx-4'>{data.winnings}</h1>
                        )}
                    </div>
                </motion.div>
            </div>
            
            {/* Winnings */}
            <div className='mx-3' style={{width:'5%', color:Colors.colorSecondary}}>
                {/* {data.winnings == 0 && <h3>+{data.winnings}</h3>} */}
            </div>
        </div>
    )
}

export default LeaderboardsRow