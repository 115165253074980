import React, { Component } from "react";
import { Card } from "reactstrap";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PerfectScrollbar from 'react-perfect-scrollbar';
class ResponsibleGaming extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            data: []
        }
    }

    componentDidMount(){
        window.scrollTo(0, 0);
    }


    render() {
        return (
            <div className="d-flex flex-column" style={{ height: '100vh', overflow: 'hidden' }}>
                <Header />
                <div className="container d-flex flex-column flex1 my-2" style={{overflow: "hidden"}} >
                    <Card className="d-flex flex-column flex1" style={{ padding: "20px", overflow: "hidden"}}>
                        <PerfectScrollbar>
                            <h1 class="c14 title"><span class="c12">RESPONSIBLE GAMING POLICY</span></h1>
                            <p class="c2 c9"><span class="c8"></span></p>
                            <p class="c1"><span class="c0">The following provides information regarding the measures Bettle, Inc. (&ldquo;Bettle,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our&rdquo;) has put in place in order to ensure a fair, safe, and responsible gaming environment:</span></p>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <h3 class="c1"><span class="c7">Self-Exclusion:</span></h3>
                            <p class="c1 c2"><span class="c7"></span></p>
                            <p class="c1"><span class="c0">You may voluntarily self-exclude yourself from accessing the Website and Services at any time. You may ask for your account to be closed or restricted by emailing us at support@bettle.co. Your request must include your full name, full residential address, and email address so we may verify your account and block your access to the Services. The self-exclusion will last for a period of at least three (3) months, and will only be lifted seven (7) days after we receive your written email requesting to reinstate your account.</span></p>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <p class="c1"><span class="c0">By voluntarily self-excluding, you agree to provide full and accurate personal details, now and in the future, so as to allow access to, and/or use of, our Services to be restricted. If you choose to self-exclude, we will use all reasonable endeavors to ensure we comply with your self-exclusion. However in agreeing to self-exclude you accept that you have a parallel undertaking not to attempt to circumvent voluntary self-exclusion. Accordingly, we have no responsibility or liability for any subsequent consequences or losses, howsoever caused, that you may suffer or incur if you commence or continue to access or make use of the Website through additional online accounts in circumstances where you have changed any of the registration details or you provide misleading, inaccurate or incomplete details or otherwise seek to circumvent the voluntary self-exclusion to which you agreed.</span></p>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <p class="c1"><span class="c0">During a period of self-exclusion, we will use reasonable efforts to not send you any marketing material and we will not accept any deposits or entries from you. During a period of self-exclusion, you may not open a new account. We will not reopen any self-excluded account prior to the three (3) month mandatory self-exclusion period; however, after the expiry of the exclusion period you may contact us and ask for your account to be re-activated.</span></p>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <h3 class="c1"><span class="c7">Self-Limitation:</span></h3>
                            <p class="c1 c2"><span class="c7"></span></p>
                            <p class="c1"><span class="c0">Bettle recommends that you set daily limits in order to avoid abusing the Services. Bettle provides you the option to set daily, weekly, or monthly deposit limitations. You may lower a limit at any time by contacting us at support@bettle.co. This election, to lower your deposit limit, will take effect thereafter. You may request the previous limit set limit to be increased or removed. Self-limitation is in addition to the limitations imposed by Bettle in accordance with applicable state law(s), which limitations will be enforced if they are exceeded, regardless of your own self-imposed limitations.</span></p>
                            <p class="c1 c2"><span class="c7"></span></p>
                            <h3 class="c1"><span class="c7">Problem Gaming Resources:</span></h3>
                            <p class="c1 c2"><span class="c7"></span></p>
                            <p class="c1"><span class="c0">Bettle encourages responsible gameplay. Bettle will never induce or encourage players to play beyond their limits or chase losses. </span></p>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <p class="c1"><span class="c0">Problem gaming may result in financial hardship. If you feel you have an excessive gaming problem, we urge you to seek assistance. Excessive gaming warning signs include:</span></p>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <ul class="c4 lst-kix_list_1-0 start">
                                <li class="c1 c3 li-bullet-0"><span class="c0">Playing in excess of your limits.</span></li>
                                <li class="c1 c3 li-bullet-0"><span class="c0">Losing sleep or skipping duties and obligations in order to participate in contests.</span></li>
                                <li class="c1 c3 li-bullet-0"><span class="c0">Replacing hobbies with excessive gaming.</span></li>
                                <li class="c1 c3 li-bullet-0"><span class="c0">Losing contact with friends and family due to gaming.</span></li>
                                <li class="c1 c3 li-bullet-0"><span class="c0">Avoiding meetings, hangouts, and gatherings in order to continue playing contests. </span></li>
                                <li class="c1 c3 li-bullet-0"><span class="c0">Feelings of anger when approached about your gaming habits.</span></li>
                                <li class="c1 c3 li-bullet-0"><span class="c0">Friends and family questioning your gaming habits. </span></li>
                                <li class="c1 c3 li-bullet-0"><span class="c0">Sacrificing time from family or work in order to participate in contests.</span></li>
                                <li class="c1 c3 li-bullet-0"><span class="c0">Repeated inability to stop or control participation.</span></li>
                                <li class="c1 c3 li-bullet-0"><span class="c0">Borrowing money to participate or to pay off money spent playing.</span></li>
                                <li class="c1 c3 li-bullet-0"><span class="c0">Lying or not being truthful about time or money spent playing.</span></li>
                                <li class="c1 c3 li-bullet-0"><span class="c0">Selling or pawning personal property to play.</span></li>
                            </ul>
                            <p class="c16"><span class="c0">Feelings of hopelessness, depression, or suicide</span><span class="c8">.</span></p>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <p class="c1"><span class="c0">If you or someone you know is or may be experiencing any of the warning signs listed above, it is important that you or the person you know immediately seek advice and support.</span></p>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <p class="c1"><span class="c0">Resources that are committed to providing professional and compassionate service to all interested persons include:</span></p>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <ul class="c4 lst-kix_list_2-0 start">
                                <li class="c1 c3 li-bullet-0"><span class="c0">Call: 1.800-426-2537 or access </span><span class="c0">&nbsp;</span></li>
                                <li class="c1 c3 li-bullet-0"><span class="c0">Call: 1.800-522-4700 National Council on Problem Gambling or access </span><span class="c5"><a class="c6" href="https://www.google.com/url?q=http://www.ncpgambling.org&amp;sa=D&amp;source=editors&amp;ust=1660748603388141&amp;usg=AOvVaw3wAyMTquKPMKUYJzHW2BmL">www.ncpgambling.org</a></span></li>
                                <li class="c1 c3 li-bullet-0"><span class="c0">Visit: </span><span class="c5"><a class="c6" href="https://www.google.com/url?q=https://www.gam-anon.org/&amp;sa=D&amp;source=editors&amp;ust=1660748603388568&amp;usg=AOvVaw0y2Z2bVCZn-8Jg9PCtR6KI">https://www.gam-anon.org/</a></span><span class="c0">&nbsp;</span></li>
                            </ul>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <h3 class="c1"><span class="c13">If you live in Arizona:</span></h3>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <p class="c1"><span class="c0">For Arizona residents, help is available via the toll-free statewide Arizona helpline at 1-800-NEXT STEP or you can text NEXT-STEP to 53342.</span></p>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <p class="c1"><span class="c11">Help is also available via the Arizona Department of Gaming&#39;s Problem Gambling website at: </span><span class="c10"><a class="c6" href="https://www.google.com/url?q=https://problemgambling.az.gov/&amp;sa=D&amp;source=editors&amp;ust=1660748603389382&amp;usg=AOvVaw0A3a5a0zorgrlWMf99kEWf">https://problemgambling.az.gov/</a></span><span class="c0">&nbsp;</span></p>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <h3 class="c1"><span class="c13">If you live in Iowa: </span></h3>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <p class="c1"><span class="c0">Iowa residents with additional concerns about managing their play can also contact the Iowa Gambling Treatment Program at 1-800-BETS-OFF.</span></p>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <h3 class="c1"><span class="c13">If you live in Pennsylvania:</span></h3>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <p class="c1"><span class="c0">Pennsylvania residents may seek assistance from the following responsible gaming resources:</span></p>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <p class="c1"><span class="c11">For information on state specific self-exclusion and treatment information, visit </span><span class="c10"><a class="c6" href="https://www.google.com/url?q=https://responsibleplay.pa.gov/&amp;sa=D&amp;source=editors&amp;ust=1660748603390437&amp;usg=AOvVaw1TNYSPXKL6hjk9yjaOTboV">https://responsibleplay.pa.gov/</a></span><span class="c11">&nbsp;and/or </span><span class="c10"><a class="c6" href="https://www.google.com/url?q=https://www.pacouncil.com/wp-content/uploads/Self_Exclusion_Brochure.pdf&amp;sa=D&amp;source=editors&amp;ust=1660748603390640&amp;usg=AOvVaw1iMnE7vAOjWE1iV6WJ8Sdu">https://www.pacouncil.com/wp-content/uploads/Self_Exclusion_Brochure.pdf</a></span><span class="c0">&nbsp;</span></p>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <p class="c1"><span class="c11">The Pennsylvania Department of Drug and Alcohol Programs&rsquo; gambling disorder treatment provider list can be found at </span><span class="c10"><a class="c6" href="https://www.google.com/url?q=https://apps.ddap.pa.gov/gethelpnow/ProviderSearch.aspx?zip&amp;sa=D&amp;source=editors&amp;ust=1660748603391080&amp;usg=AOvVaw2Tq3n9mkcXHQIKrOolojUB">https://apps.ddap.pa.gov/gethelpnow/ProviderSearch.aspx?zip</a></span><span class="c0">. </span></p>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <p class="c1"><span class="c11">To contact the Council on Compulsive Gambling of Pennsylvania, visit </span><span class="c10"><a class="c6" href="https://www.google.com/url?q=http://www.pacouncil.com/&amp;sa=D&amp;source=editors&amp;ust=1660748603391415&amp;usg=AOvVaw1YHcCIXe2pSk_0_j1j-z3A">http://www.pacouncil.com/</a></span><span class="c0">. </span></p>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <p class="c1"><span class="c11">To contact Gambler&rsquo;s Anonymous of Pennsylvania, visit </span><span class="c10"><a class="c6" href="https://www.google.com/url?q=http://www.gamblersanonymous.org/ga/locations/state/table/PA/na/na/na/na/10?%23gmap-nodemap-gmap0&amp;sa=D&amp;source=editors&amp;ust=1660748603391824&amp;usg=AOvVaw1p2UactwRZraQ4zUivs6AD">http://www.gamblersanonymous.org/ga/locations/state/table/PA/na/na/na/na/10?#gmap-nodemap-gmap0</a></span><span class="c0">. &nbsp; </span></p>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <p class="c1"><span class="c11">For information on Pennsylvania Self-Exclusion Enrollment, visit </span><span class="c10"><a class="c6" href="https://www.google.com/url?q=https://gamingcontrolboard.pa.gov/SelfExclusion/Default.aspx?ReturnUrl%3D%252fSelfExclusion%252f&amp;sa=D&amp;source=editors&amp;ust=1660748603392217&amp;usg=AOvVaw3GbuvFOa1y_dXYzsRaY9B6">https://gamingcontrolboard.pa.gov/SelfExclusion/Default.aspx?ReturnUrl=%2fSelfExclusion%2f</a></span><span class="c0">&nbsp;</span></p>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <p class="c1"><span class="c0">Additional problem gaming resources may be available in your jurisdiction. If you have any questions, or require assistance in providing you with appropriate support in your area, please contact us at support@bettle.co.</span></p>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <h3 class="c1"><span class="c7">Third Party Exclusion Requests:</span></h3>
                            <p class="c1 c2"><span class="c7"></span></p>
                            <p class="c1"><span class="c0">If you believe that a member of your family is a problem gamer, please contact us at support@bettle.co</span></p>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <p class="c1"><span class="c0">Third parties may request that a family member be excluded from the Website or the Services, or request the setting of deposit or loss limits for the family member. In order to process such requests, Bettle will require any of the following information: (1) evidence of sole or joint financial responsibility for the source of any funds deposited with Bettle; (2) proof that the requesting party is jointly obligated on the credit or debit card associated with the family member&rsquo;s Bettle account; (3) proof of legal dependency of the family member on the requesting party under federal or state law; (3) proof of the existence of a court order that makes the requesting party wholly or partially obligated for the debts of the family member; or (4) proof of the existence of a court order requiring the family member to pay unmet child support obligations, alimony payments, and/or any other familial-related financial obligations. Bettle may also request additional identifiable verification documentation validating the relationship between the requesting party and the family member.</span></p>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <p class="c1"><span class="c0">Upon receipt of a valid request, Bettle will use best efforts to contact the user via email associated with the account requested to be excluded and/or limited. The user will have five (5) business days (&ldquo;Dispute Period&rdquo;) from the date we send email notification of the request to dispute the request. If the user does not dispute the request within the Dispute Period or Bettle deems, in its sole discretion, that the information provided is insufficient to refute the request, it will be honored and the exclusion or restriction shall be enforced.</span></p>
                            <p class="c1 c2"><span class="c7"></span></p>
                            <h3 class="c1"><span class="c7">Parental Controls:</span></h3>
                            <p class="c1 c2"><span class="c7"></span></p>
                            <p class="c1"><span class="c0">Bettle verifies all accounts for age and eligibility to participate in contests. Bettle does not permit users under the age of 18 (or higher in certain jurisdictions) to register an account to access the Website or Services. In the event you believe a user is under the age of majority or is abusing the Website or Services, please contact us at support@bettle.co. </span></p>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <p class="c1 c2"><span class="c0"></span></p>
                            <p class="c1 c2"><span class="c7"></span></p>
                        </PerfectScrollbar>
                    </Card>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapDispatchToProps = {
};

export default connect(null, mapDispatchToProps)(withRouter(ResponsibleGaming));