import React from 'react';
import { Button, Modal, Table } from 'reactstrap';
import SendInviteModal from "../../modals/send_invite";

class JoinedTeamsModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div >
                <Modal size="lg" isOpen={this.props.modal} toggle={() => this.props.toggle()}>
                   <div className="text-center p-4" >
                   <i onClick={() => this.props.toggle()} class="cui-circle-x icons pointer modalClose"></i>
                   <div className="text-center" >
                       <h4 className="mb-4" >Teams Claimed</h4>
                       <Table borderless className="text-left" >
                           <thead style={{borderBottom: "1px solid dimgrey"}}>
                               <th>Name</th>
                               <th>Claimed</th>
                               <th></th>
                           </thead>
                           <tbody>
                               {this.props.lg.members && this.props.lg.members.map((member) => {
                                   return <tr style={{borderBottom: "1px solid dimgrey", height: "58px"}}>
                                        <td>{member.teamName}</td>
                                        <td>{member.claimed ? "Yes" : "No"}</td>
                                        <td>{!member.claimed && <SendInviteModal memberId={member.memberId} invited={member.invited} league_id={this.props.lg.id} teams={this.props.lg.metaData.emptySpots} lg={this.props.lg} />}</td>
                                    </tr>
                               })}
                               
                           </tbody>
                       </Table>
                   </div>
                   </div>
                </Modal>
            </div>
        );
    }
}
export default JoinedTeamsModal;