import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { activeMatches } from '../../../../apis/Matchmaker/ArenaScoreboard/ArenaScoreboard'
import store from '../../../../store'
import { LoaderCenter, SmallLoader } from '../../../Loader'
import SingleMatchup from './SingleMatchup'
import { sportFromExperience } from '../../../../helpers/common-methods'

const ViewMatchups = (props) => {

    const { teamId, sbMeta, activeTab, matchesCount, rematchStateCallback } = props

    const [matches, setMatches] = useState([])
    const [loading, setLoading] = useState(true)
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(5)
    const [hasMore, setHasMore] = useState(true)
    const experience = store.getState().leagueJoin.app_status;
    const sport = sportFromExperience(experience, true);

    useEffect(() => {
        setLoading(true)
        setHasMore(true)
        if(teamId != undefined) {
            callApi(activeTab, 0, teamId)
        }
    }, [teamId, activeTab])

    const callApi = (newTab = activeTab, off = offset, teamInd = teamId, append = false ) => {
        // const teamIndex = teamInd != '0' ? teamInd.value : 'all';
        activeMatches("?offset=" + off + "&limit="+limit+"&type=" + newTab + "&teamId=" + teamInd + "&sport=" + sport).then((resp) => {
            if (!append) {
                store.dispatch({ type: "SAVE_MATCHUPS_COUNT", payload: resp.data.matchesCount });
            }
            setOffset(off + limit)
            if (append) {
                setMatches([...matches, ...resp.data.matches])
            } else {
                setMatches(resp.data.matches)
            }
            if (resp.data.matches.length < 5) {
                setHasMore(false)
            }
            setLoading(false)
        }).catch((e) => {
            setLoading(false)
        })
    };
    
    const loadMore = () => {
        callApi(activeTab, offset, teamId, true)
    }

  return (
        loading 
        ?
        <LoaderCenter />
        :
        matches.length > 0 ?
            <InfiniteScroll
                dataLength={matches.length}
                next={loadMore}
                hasMore={hasMore}
                loader={<div className='d-flex justify-content-center'><SmallLoader/></div>}
                endMessage={<div className='text-center py-3 small-font'><i class="fa fa-check-circle" aria-hidden="true"></i> You are all caught up!</div>}
                scrollableTarget={'arena-body-container'}
            >
                <div className='my-2'>
                    {
                        !loading && matches.map((match) => (
                            <SingleMatchup key={match.matchId} match={match} rules={match.rules} activeTab={activeTab} sport={match.sport} rematchStateCallback={rematchStateCallback} />
                        )
                    )}
                </div>
            </InfiniteScroll>
            :
            <div className='mb-5'></div>
  )
}

export default ViewMatchups