import React, { Component } from 'react';
// import { Colors } from '../../../helpers/colors';
import { LIVE_STATS_INTERVAL } from '../../../helpers/common-methods';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { fetchRotoStandings, liveRotoStandings } from '../../../apis/SeasonLong/Standings/Standings';
import { calcTeamDailyPoints } from '../../../helpers/MLB/Points';
import { mergeAndSum } from '../../../helpers/common-methods';
import { toastError } from '../../../helpers/toasts';
import LoadingOverlay from 'react-loading-overlay';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { LoaderCenter } from '../../../components/Loader';
import { ColorContext } from '../../../context/ColorProvider';

class RotoStandings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            standings: {},
            reqData: {},
            headers: [],
            loading: true,
            statTypeIndex: 0,
        }
    }

    static contextType = ColorContext;
    liveInterval = null
    statTypes = ["points", "stats"]

    componentDidMount() {
        let queryString = '?leagueId=' + this.props.leagueId
        fetchRotoStandings(queryString)
            .then(response => {

                this.prepareStandings(response)
                this.prepareHeaders(response)

                this.setState({
                    reqData: response,
                    loading: false
                })

                this.liveInterval = setInterval(() => {
                    liveRotoStandings(queryString).then((dailyResp) => {
                        var reqDataCopy = JSON.parse(JSON.stringify(response))
                        reqDataCopy["dailyStandings"] = dailyResp
                        this.prepareStandings(reqDataCopy)
                    })
                }, LIVE_STATS_INTERVAL)

            })
            .catch(({ message }) => {
                toastError(message)
            })
    }

    componentWillUnmount() {
        clearInterval(this.liveInterval)
    }


    prepareHeaders(response) {
        var selectedCats = []
        Object.values(response.scoringSettings).map((val) => {
            if (val.display) {
                selectedCats.push(val.stat)
            }
        })
        this.setState({
            headers: selectedCats
        })
    }


    prepareStandings(response) {
        let prepareStandings = {}
        let selectedCatsDaily = []
        let statsCatWise = {}

        Object.values(response.teams).map(team => {
            let cummulativeStats = response.cummulative[team.userId].stats
            let dailyStats = response.dailyStandings[team.userId]
            // MERGE ABOVE STATS
            let merged = mergeAndSum(cummulativeStats, dailyStats)
            // PREPARE OBJECT AND ALSO PUT TOTAL POINTS
            prepareStandings[team.userId] = {
                ...team, ...{
                    stats: merged,
                    points: {}
                }
            }
        })
        // GET SELCTED CATS FOR TODAY
        Object.values(response.scoringSettings).map((val) => {
            if (val.display) {
                selectedCatsDaily.push(val.stat)
            }
        })

        selectedCatsDaily.map((selectedCat) => {
            // NOW WE HAVE SELECTED CATS READY
            Object.keys(prepareStandings).map((userId) => {
                if (statsCatWise[selectedCat]) {
                    statsCatWise[selectedCat].push(
                        {
                            userId: userId,
                            stats: prepareStandings[userId]["stats"][selectedCat]
                        }
                    )
                } else {
                    statsCatWise[selectedCat] = []
                    statsCatWise[selectedCat].push(
                        {
                            userId: userId,
                            stats: prepareStandings[userId]["stats"][selectedCat]
                        }
                    )
                }
            })
        })
        // NEED TO SORT ARRAY SO THAT WE WILL GET POINTS
        var statsCatWiseCopy = { ...statsCatWise }
        Object.keys(statsCatWise).map((statKey) => {
            // SORT BASED ON ASC ORDER SO THAT MAX POINTS GANINER CAN GET 12 POINTS
            statsCatWiseCopy[statKey] = statsCatWiseCopy[statKey].sort((team1, team2) => parseFloat(team1.stats) > parseFloat(team2.stats) ? 1 : -1)
        })
        // NOW WE NEED TO GO THOUGH SORTED ARRAY AND GENERATE POINTS
        var standingsCopy = { ...prepareStandings }
        Object.keys(statsCatWiseCopy).map((statKey) => {
            Object.keys(prepareStandings).map((userId) => {
                var teamPoint = 0
                if (response.scoringSettings[statKey].lower_total) {
                    // THIS WILL REVERS THE SORTING AND VALUES WILL BE ACCORDING TO DESC ORDER, IN THAT CASE MIN STAT TEAM WILL GET 12 POINTS
                    var statReversed = [...statsCatWiseCopy[statKey]].reverse()
                    teamPoint = calcTeamDailyPoints(userId, statReversed)
                    standingsCopy[userId]["points"][statKey] = teamPoint
                } else {
                    teamPoint = calcTeamDailyPoints(userId, statsCatWiseCopy[statKey])
                    standingsCopy[userId]["points"][statKey] = teamPoint
                }
                if (standingsCopy[userId]["totalPoints"] != undefined) {
                    standingsCopy[userId]["totalPoints"] = standingsCopy[userId]["totalPoints"] + teamPoint
                } else {
                    // standingsCopy[userId]["totalPoints"] = 0
                    standingsCopy[userId]["totalPoints"] = teamPoint
                }
            })
        })
        var standingSorted = Object.values(standingsCopy).sort((team1, team2) => team1.totalPoints < team2.totalPoints ? 1 : -1)
        this.setState({
            standings: standingSorted
        })
    }

    handleStatTypeIndex(statIndex) {
        this.setState({
            statTypeIndex: statIndex
        })
    }

    render() {
        const Colors = this.context; 
        return (
            <div className="d-flex flex-column flex1 h-100" >
                <div className='d-flex justify-content-between align-items-center mb-2' style={{ minHeight: 0, borderBottom: `0.01em solid ${Colors.darkBgBorder}` }} >
                    <h2 style={{ padding: '7px 0px 7px 0px' }} className='pl-3'>Standings</h2>
                    <UncontrolledDropdown className='mx-2'>
                        <DropdownToggle caret color='dark'>
                            {this.statTypes[this.state.statTypeIndex]}
                        </DropdownToggle>
                        <DropdownMenu  >
                            <DropdownItem onClick={() => this.handleStatTypeIndex(0)}>Points</DropdownItem>
                            <DropdownItem onClick={() => this.handleStatTypeIndex(1)}>Stats</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                <PerfectScrollbar style={{ minHeight: 0 }} className='container'>
                    <StickyTable stickyHeaderCount={1} leftStickyColumnCount={3} borderWidth={'1px'} borderColor={Colors.darkBgBorder}>
                        {/* <LoadingOverlay
                            active={this.state.loading}
                            text='Loading...'
                            fadeSpeed={0}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: Colors.bgDark
                                })
                            }}
                            spinner
                        >
                        </LoadingOverlay> */}
                        {this.state.loading ? <LoaderCenter msg='Loading...' /> :
                            <>
                                <Row style={{ borderBottom: `0.01em solid ${Colors.darkBgBorder}` }}>
                                    <Cell className='p-0 bg-transparent' >{headerColumn('RANK')}</Cell>
                                    <Cell className='p-0 bg-transparent' >{headerColumn('TEAMS')}</Cell>
                                    <Cell className='p-0 bg-transparent'>{headerColumn('TOT')}</Cell>
                                    {
                                        !this.state.loading &&
                                        Object.values(this.state.headers).map(heading => {
                                            return (
                                                <Cell className='p-0 bg-transparent' >{headerColumn(this.state.reqData.statAbbr[heading])}</Cell>
                                            )
                                        })
                                    }
                                </Row>
                                {!this.state.loading &&
                                    Object.values(this.state.standings).map((standing, index) => {
                                        let { teamName, username, teamImage, totalPoints } = standing
                                        let scores = standing[this.statTypes[this.state.statTypeIndex]]
                                        return (
                                            <>
                                                <Row style={{ backgorundColor: Colors.bgDark, borderBottom: `0.01em solid ${Colors.darkBgBorder}` }}>
                                                    <Cell className='p-0 align-middle bg-transparent' >{darkCell(index + 1)}</Cell>
                                                    <Cell className='p-0 align-middle bg-transparent' >{player(teamName, username, teamImage)}</Cell>
                                                    <Cell className='p-0 align-middle bg-transparent' >{darkCell(totalPoints)}</Cell>
                                                    {
                                                        this.state.headers.map(header => {
                                                            return (
                                                                <Cell className='p-0 align-middle text-center bg400' >{parseFloat(scores[header])}</Cell>
                                                            )
                                                        })
                                                    }
                                                </Row>
                                            </>
                                        )
                                    })
                                }
                            </>
                        }
                    </StickyTable>
                </PerfectScrollbar>
            </div>
        );
    }
}

export function player(name, username, image) {
    return (
        <div className='m-0' style={{ backgroundColor: Colors.bgDark, padding: '0.5rem 0.75rem' }}>
            <img className='img-responsive my-1' src={image} height='40px' /> <span className='ml-1 bold d-inline-block align-middle text-truncate'>{name} <br /> {username}</span>
        </div>
    )
}

export function darkCell(value) {
    return (
        <div className='m-0 text-center' style={{ backgroundColor: Colors.bgDark, padding: '1.2rem' }}>
            {value}
        </div>
    )
}

export function headerColumn(name) {
    return (
        <div className='m-0 text-center' style={{ backgroundColor: Colors.bgDark, padding: '0.5rem 1.0rem' }}>
            {name}
        </div>
    )
}

export default RotoStandings;