export const ARENA = {
    EXPERIENCE_FROM_SPORT: {
        'NBA': 'BASKETBALL_ARENA',
        'BASKETBALL': 'BASKETBALL_ARENA',
        'NFL': 'FOOTBALL_ARENA',
        'FOOTBALL': 'FOOTBALL_ARENA',
        'MLB': 'BASEBALL_ARENA',
        'BASEBALL': 'BASEBALL_ARENA',
    },
    SPORT_NAME_FROM_EXPERIENCE: {
        'BASKETBALL_ARENA': 'BASKETBALL',
        'FOOTBALL_ARENA': 'FOOTBALL',
        'BASEBALL_ARENA': 'BASEBALL',
    },
    SPORT_FROM_EXPERIENCE: {
        'BASKETBALL_ARENA': 'NBA',
        'FOOTBALL_ARENA': 'NFL',
        'BASEBALL_ARENA': 'MLB',
    },
    SPORT_NAME_FROM_SPORT: {
        'NBA': 'BASKETBALL',
        'NFL': 'FOOTBALL',
        'MLB': 'BASEBALL',
    },
    SPORT_FROM_SPORT_NAME: {
        'BASKETBALL': 'NBA',
        'FOOTBALL': 'NFL',
        'BASEBALL': 'MLB',
    },
}
