const ENDPOINTS = {
    SCOREBOARD: '/sl/fetchScoreboard',
    BASIC_SCOREBOARD: '/sl/fetchBasicScoreboard',
    MATCHUP: '/sl/fetchMatchup',
    MY_MATCHUP: "/sl/myMatchup",
    MLB_MATCHUP: "/sl/mlb/matchup",
    MLB_MY_MATCHUP: "/sl/mlb/myMatchup"
}

export const fetchScoreboard = data => {
    return window.axios.post(ENDPOINTS.SCOREBOARD,data)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const fetchBasicScoreboard = params => {
    return window.axios.get(ENDPOINTS.BASIC_SCOREBOARD,{params})
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}


export const fetchMatchup = data => {
    return window.axios.post(ENDPOINTS.MATCHUP, data)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const fetchMlbMatchup = queryString => {
    return window.axios.get(ENDPOINTS.MLB_MATCHUP+queryString)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const myMatchup = queryString => {
    return window.axios.get(ENDPOINTS.MY_MATCHUP+queryString)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const fetchMlbMyMatchup = queryString => {
    return window.axios.get(ENDPOINTS.MLB_MY_MATCHUP+queryString)
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}