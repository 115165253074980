import React from 'react';
import HeaderWeb from './Web/HeaderWeb';
import HeaderMobile from './Mobile/HeaderMobile';

const NewOnboardingHeader = (props) => {
	return (
		<>
			<HeaderWeb {...props}/>
			<HeaderMobile {...props} />
		</>
	);
};
export default NewOnboardingHeader;
