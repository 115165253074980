import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody } from 'reactstrap'
import { mmFetchChallengerLineup } from '../../apis/Arena/MatchMakerApis'
import { LoaderCenter } from '../../components/Loader'
import ComparionView from '../../components/Matchmaker/ComparisonView'
// import { Colors } from '../../helpers/colors'
import { STYLES } from '../../helpers/stylesHelper'
import { toastError } from '../../helpers/toasts'
import ArenaMatchModal from './ArenaMatchModal'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { arenaBackIcon } from '../../helpers/icons'
import { useColors } from '../../helpers/CustomHooks/useColors';

const AcceptChallengeModal = (props) => {
    const Colors = useColors();
    const { arenaData } = props
    const { fromId, challenge_sport } = props?.challengerData

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const [proposeModal, setProposeModal] = useState(false)

    useEffect(() => {
            callApi()
    }, [])

    const toggleProposeModal = () => {
        setProposeModal(!proposeModal)
    }

    const callApi = () => {
        setLoading(true)
        mmFetchChallengerLineup({fromId: fromId, sport: challenge_sport.toUpperCase()})
            .then((resp) => {
                setData(resp)
                setLoading(false)
            })
            .catch((e) => {
                toastError("Something Went Wrong")
            })
    }

    const challengeSentSuccessCallback = () => {
        toggleProposeModal()
        props.toggle()
    }

    const challengeCancelCallback = () => {
        toggleProposeModal()
        props.toggle()
    }
        
    return (
        <div >
            <Modal size="lg" isOpen={props.isOpen} toggle={() => props.toggle()} style={{overflow:'hidden'}}>
                <ModalBody className="m-0 p-0 d-flex flex-column" style={{height: 'calc(100vh - 70px)', backgroundColor: proposeModal ? Colors.bgDark: ''}}>
                    {
                    loading 
                    ? 
                    <LoaderCenter /> 
                    :
                    proposeModal 
                    ? 
                        <ArenaMatchModal
                            arenaData={arenaData}
                            isCounter={false}
                            counterMatchId={null}
                            selectedLineup={data}
                            modal={proposeModal}
                            successCallback={challengeSentSuccessCallback}
                            cancelCallback={challengeCancelCallback}
                            sport={data["sport"]}
                        />
                    :
                        <div className="text-center py-4 d-flex flex-column flex1" style={{overflow:'hidden'}} >
                            <i onClick={() => props.toggle()} class="cui-circle-x icons pointer modalClose"></i>
                            <h2 className='mt-4'>Challenge {data["teamName"]}</h2>
                            <p>
                                You have accepted challenge request from {data["teamName"]}, send them a proposal.
                            </p>
                            <PerfectScrollbar style={{overflow:'auto'}}>
                                <ComparionView sport={data['sport']} teamId={data['teamId']} />
                            </PerfectScrollbar>
                        </div>
                    }
                    { !loading && !proposeModal && <div className='d-flex justify-content-end p-4'>
                        <Button style={STYLES.BUTTON_PRIMARY} onClick={toggleProposeModal}>Challenge</Button>
                    </div>
                    }

                    { !loading && proposeModal && <div className='d-flex justify-content-start p-4'>
                        <div className='pointer' onClick={toggleProposeModal}>
                            {arenaBackIcon()}
                        </div>
                    </div>
                    }
                </ModalBody>
            </Modal>
        </div>
    )
}

export default AcceptChallengeModal