import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalPlayerAdd from '../../../../components/Chat/Templates/PlayerNews/ModalPlayerAdd';
import ModalPlayerDrop from '../../../../components/Chat/Templates/PlayerNews/ModalPlayerDrop';
// import { Colors } from '../../../../helpers/colors';
import { arrowDownIcon, myBetsIcon, plusIcon, swapIcon } from '../../../../helpers/icons';
import { useColors } from '../../../../helpers/CustomHooks/useColors';

const PlayerCardActions = (props) => {
    const Colors = useColors();
    const [playerLocked, setPlayerLocked] = useState(false)
    const [allowSidebet, setAllowSidebet] = useState(false)
    const [allowTrade, setAllowTrade] = useState(false)
    const [allowDrop, setAllowDrop] = useState(false)
    const [allowClaim, setAllowClaim] = useState(false)
    const [dropPlayerModal, setDropPlayerModal] = useState(false)
    const [addPlayerModal, setAddPlayerModal] = useState(false)

    useEffect(() => {
      prepareAllowedActions()
    }, [])
    
    const prepareAllowedActions = () => {
        // Diable actions if player is locked actively
        if (props.data.locked) {
            setPlayerLocked(true)
        }

        // Prepare options in case if player is drafted
        if (props.data.isDrafted) {
            setAllowSidebet(true)
            if (props.data.draftedByMe) {
                setAllowDrop(true)
            }
            else {
                setAllowTrade(true)
            }
        }
        // Prepare options if player is free
        else {
            setAllowClaim(true)
        }
    }

    const toggleDropPlayerModal = () => {
        setDropPlayerModal(!dropPlayerModal)
    }

    const toggleAddPlayerModal = () => {
        setAddPlayerModal(!addPlayerModal)
    }

    const initiateTradeSequence = () => {
        props.history.push('/chat/user/'+props.data.draftedTeamId, { playerId: props.data.playerId, playerName: props.data.fullName })
    }

    return (
        <div className="d-flex" style={{position:'sticky', bottom:0}}>
            {/* {
                allowSidebet 
                &&
                <div onClick={playerLocked ? "" : ""} className='flex1 align-content-center text-center p-3 text-dark' style={{backgroundColor: playerLocked ? Colors.colorSecondary : Colors.colorPrimary, borderRight:`0.01em solid ${Colors.bgDark}`, borderLeft:`0.01em solid ${Colors.bgDark}`, cursor:'pointer'}}>
                    <i className="mr-2">
                        {myBetsIcon()}
                    </i>
                    New Sidebets
                </div>
            } */}
            
            {/* {
                allowTrade 
                &&
                <div onClick={playerLocked ? "" : () => initiateTradeSequence()} className='flex1 align-content-center text-center p-3 text-dark' style={{backgroundColor: playerLocked ? Colors.colorSecondary : Colors.colorPrimary, borderRight:`0.01em solid ${Colors.bgDark}`, borderLeft:`0.01em solid ${Colors.bgDark}`, cursor:'pointer'}}>
                    <i className="mr-2">
                        {swapIcon()}
                    </i>
                    Make Trade
                </div>
            }  */}
            
            {/* This button below is dummy and should be removed while uncommenting the trade button above */}
            {
                allowTrade 
                &&
                <div className='flex1 align-content-center text-center p-3 text-dark' style={{backgroundColor:  Colors.colorSecondary, borderRight:`0.01em solid ${Colors.bgDark}`, borderLeft:`0.01em solid ${Colors.bgDark}`, cursor:'not-allowed'}}>
                    <i className="mr-2">
                        {swapIcon()}
                    </i>
                    Make Trade
                </div>
            }    
            {/* This button above is dummy and should be removed while uncommenting the trade button above this block */}

            {/* {
                allowDrop 
                &&
                <div onClick={playerLocked ? "" : () => toggleDropPlayerModal()} className='flex1 align-content-center text-center p-3' style={{backgroundColor: playerLocked ? Colors.colorSecondary : Colors.colorDanger, borderRight:`0.01em solid ${Colors.bgDark}`, borderLeft:`0.01em solid ${Colors.bgDark}`, cursor:'pointer'}}>
                    <i className="mr-2">
                        {arrowDownIcon()}
                    </i>
                    Drop
                </div>  
            } */}

            {/* {
                allowClaim
                &&
                <div onClick={playerLocked ? "" : () => toggleAddPlayerModal()} className='flex1 m-auto text-center p-3 text-dark' style={{backgroundColor: playerLocked ? Colors.colorSecondary : Colors.colorPrimary, borderRight:`0.01em solid ${Colors.bgDark}`, borderLeft:`0.01em solid ${Colors.bgDark}`, cursor:'pointer'}}>
                    <i className="mr-2 p-0">
                        {plusIcon()}
                    </i>
                    <span>Claim</span>
                </div> 
            } */}
            <ModalPlayerDrop isOpen={dropPlayerModal} toggle={() => toggleDropPlayerModal()} leagueId={props.leagueId} playerId={props.data.playerId} />
            <ModalPlayerAdd data={{player_id: props.data.playerId, player_name: props.data.fullName, league_id: props.leagueId}} isOpen={addPlayerModal} toggle={() => toggleAddPlayerModal()} />
        </div>
    )
}

const mapStateToProps = (state) => ({leagueId: state.leagueJoin.leagueId})
export default connect(mapStateToProps, null) (withRouter(PlayerCardActions))