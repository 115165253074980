import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactSelect from 'react-select';
import { Button } from 'reactstrap';
import { tradeBlockData } from '../../../../apis/SeasonLong/Trade/TradeAPI';
import { ColorContext } from '../../../../context/ColorProvider';
import { STYLES } from '../../../../helpers/stylesHelper';
import { toastError } from '../../../../helpers/toasts';
import TradeblockPlayers from './TradeblockPlayers';
import TradeblockPositions from './TradeblockPositions';

class Tradeblock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // Internal States (Creation)
            tradeblockOptions: [
                {
                    value:'players',
                    label:'Add Player to trade block'
                },
                {
                    value:'positions',
                    label:'Improve my Roster'
                }
            ],
            selectedType: null,
            selectedPlayers: null,
            selectedPositions: null,
            
            //Internal States (Sent)
            type: null,
            data: null,

            // API States
            data: {
                players:[
                    {}
                ],
                positions:[
                    {}
                ]
            }

        }

        this.handleTradeblockType = this.handleTradeblockType.bind(this)
        this.handleSelectedPlayers = this.handleSelectedPlayers.bind(this)
        this.handleSelectedPositions = this.handleSelectedPositions.bind(this)
        this.handleTradeblockCreation = this.handleTradeblockCreation.bind(this)
    }
    
    static contextType = ColorContext;

    componentDidMount() {
        this.initComponent()
    }

    /**
     * initialize the component based on the template
     * @param {string} template - decides the view of component
     * @param {object} data - all the data sent as attachment
     */
    initComponent() {
        let { data, template } = this.props.data.attachments[0]
        let params = {
            leagueId:data.leagueId
        }
        if(template == 'TRADE_BLOCK_CREATION') {
            tradeBlockData(params)
                .then(response => {
                    this.setState({
                        data: response.data
                    })
                })
                .catch(({message}) => {
                    toastError(message)
                }) 
        }
        else if(template == 'TRADE_BLOCK_SENT') {
            this.setState({
                type: data.type,
                data: data[data.type]
            })
        }
    }
    /**
     * handle the selection of tradeblock to render respective form
     * @param {object} selectedType - type of tradeblock to create players/positions
     */
    handleTradeblockType(selectedType) {
        this.setState({
            selectedType,
            selectedPlayers: null,
            selectedPositions: null
        })
    }

    /**
     * handle the selection of players
     * @param {object} selectedPlayers - players
     */
     handleSelectedPlayers(selectedPlayers) {
        this.setState({
            selectedPlayers
        })
    }

    /**
     * handle the selection of positions
     * @param {object} selectedPositions - positions
     */
     handleSelectedPositions(selectedPositions) {
        this.setState({
            selectedPositions
        })
    }
    
    // Create Tradeblock if all the required data is fulfilled
    handleTradeblockCreation() {
        let data = this.props.data
        data['attachments'] = this.prepareAttachments(this.state.selectedType.value)
        this.props.handleCommand(data)
    }

    /**
     * Generate message related to current status of tradeblock
     * @returns jsx - status of trade
     */
    tradeblockStatusInfo(){
        return (
            <p>
                {
                this.props.status == 'TRADE_BLOCK_CREATION' ? 
                    "It's time to make some changes to the squad. Are you putting one of your players on the trade block or are you looking for to improve your roster?"
                :
                    this.state.type == 'positions' ? 
                        `I need the following positions ${this.state.data}. Have one to spare?`
                    :
                        `${this.props.data.user.username} has added the following players to the trade block`
                }
            </p>
        )
    }

    createTradeblockButton(){
        let style = STYLES.BUTTON_SECONDARY_TRANSPARENT
        let type, data, allowAcion = false
        // Check if user has 
        if(this.state.selectedType) {
            type = true
            if(this.state.selectedType.value == 'players' && this.state.selectedPlayers) {
                data= true
            }
            else if(this.state.selectedType.value == 'positions' && this.state.selectedPositions) {
                data = true
            }
            else {
                data = false
            }
        }
        allowAcion = type && data
        if(allowAcion) {
            style = STYLES.BUTTON_PRIMARY_TRANSPARENT
        }

        return (
            <Button onClick={allowAcion?this.handleTradeblockCreation:''} className='w-100 mt-3' style={style}>Create</Button>
        )
    }

    renderTradeTypeForm(){
        if(this.state.selectedType) {
            if(this.state.selectedType.value == 'players') {
                return (
                    <ReactSelect menuPortalTarget={document.body} getOptionValue={option => option.playerId} getOptionLabel={option => option.playerName} options={this.state.data.players} value={this.state.selectedPlayers} onChange={this.handleSelectedPlayers} className=" mx-1 my-2 text-white" styles={STYLES.REACT_SELECT} closeMenuOnSelect={false} isMulti={true} isClearable={false} />
                )
            }
            else {
                return (
                    <ReactSelect menuPortalTarget={document.body} getOptionValue={option => option} getOptionLabel={option => option} options={this.state.data.positions} value={this.state.selectedPositions} onChange={this.handleSelectedPositions} className=" mx-1 my-2 text-white" styles={STYLES.REACT_SELECT} closeMenuOnSelect={false} isMulti={true} isClearable={false} />
                )
            }
        }
    }

    prepareAttachments(type) {
        let data =  {attachments: [{
            data: {
                type: type,
                leagueId: this.props.data.attachments[0].data.leagueId,
                teamName: this.props.data.user.username,
                userId: this.props.data.user.id
              },
            template: "TRADE_BLOCK_SENT"
        }]}

        if(type == 'players') {
            data.attachments[0].data.players = this.state.selectedPlayers
        }
        else if(type == 'positions') {
            data.attachments[0].data.positions = this.state.selectedPositions.join(', ')
        }

        return data.attachments
    }

    render() { 
        const Colors = this.context
        return ( 
            <div className='pl-2 mt-2 mb-3' style={{maxWidth:'400px',borderLeft:`5px solid ${Colors.darkBgBorder}`}}>
                {this.tradeblockStatusInfo()}

                {
                    {
                        'TRADE_BLOCK_CREATION':
                            <>
                                <ReactSelect menuPortalTarget={document.body} options={this.state.tradeblockOptions} value={this.state.selectedType} onChange={this.handleTradeblockType} className=" mx-1 text-white my-2" styles={STYLES.REACT_SELECT} closeMenuOnSelect={true} isClearable={false} />
                                {this.renderTradeTypeForm()}
                                {this.createTradeblockButton()}
                            </>,
                        'TRADE_BLOCK_SENT':
                        <>
                            {
                                {
                                    'players':
                                        <TradeblockPlayers data={this.state.data} myId={this.props.auth.user.id} senderId={this.props.data.user.id}/>,
                                    'positions':
                                        <TradeblockPositions data={this.state.data} myId={this.props.auth.user.id} sender={this.props.data.user}/>
                                }[this.state.type]
                            }
                        </>
                    }[this.props.status]
                }
            </div>
         );
    }
}
const mapStateToProps = (state) => ({auth: state.auth})
export default connect(mapStateToProps, null) (Tradeblock);