import React, { Component, useEffect, useState } from 'react';
import { Container, Col, Row, Carousel, Button, Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem } from 'reactstrap';
import DocumentTitle from 'react-document-title';
import Header from '../components/Header/Header';
import { STYLES } from '../helpers/stylesHelper';
import ArenaIntro from "../assets/images/ArenaIntro.png"
import BettleIntro from "../assets/images/BettleIntro.png"
import UsaMap from "../assets/images/map_USA.svg"
import Footer2022 from '../components/Footer/Footer2022';
import landingBg from "../assets/images/landingBg.png"
import logo from '../assets/images/logo_trans.png'
import androidLogo from '../assets/images/android.png';
import iosLogo from '../assets/images/ios.png';
import bettleBee from '../assets/images/bettleBee.png';
import bettleMobile from "../assets/images/Phones-w-Screens-p-500.png"
import ESPN from '../assets/images/ESPN_wordmark.svg';
import YAHOO from '../assets/images/yahoo_fantasy_en-US_h_w_fantasyv2_2.png';
import sleeper from "../assets/images/sleeper.png"
import { Colors } from '../helpers/colors';
import { useHistory, useLocation } from 'react-router-dom';
import BettleTranscode1 from '../assets/videos/Bettle-transcode.mp4';
import BettleTranscode2 from '../assets/videos/Bettle-transcode.webm';
import "./NewLanding.css"
import pd1 from '../assets/images/PlusImages/pd1.png';
import mobIosLogo from '../assets/images/ios.png';
import mobPlus from '../assets/images/mobPlus.svg';
import queryString from 'query-string';
import SignInModal from '../modals/Auth/SignInModal';
import store from '../store';
import { useSelector } from 'react-redux';
import Marquee from '../components/Marquee';
import bettleIcon from '../assets/images/bettle-icon.png';

const LandingHeader = () => {
    
    const history = useHistory()
    const location = useLocation()
    const parsed = queryString.parse(location.search)
    const [signInModal, setSignInModal] = useState(false)
    const [authScreen, setAuthScreen] = useState()
    const {authenticated} = useSelector(state => state.auth);

    const handleRegister = () => {
        if(authenticated){
            history.push("/expCheck")
        }{
            setAuthScreen("signup")
            setSignInModal(true)
        }
        // if(parsed.viaPromoLink){
        //     history.push("/register?viaPromoLink=1")
        // }else{
        //     history.push("/register")
        // }
    }

    const handleLogin = () => {
        if(authenticated){
            history.push("/expCheck")
        }{
            setAuthScreen("signin")
            setSignInModal(true)
        }
    }

    return (
        <div>
            <div className='d-flex align-items-center' style={{ background: Colors.secondary_30, height: "35px" }}>
                <Marquee icon={bettleIcon} handleRegister={handleRegister} />
            </div>
            <div className='d-none d-lg-block' >
                <Navbar className='shadow-sm' expand={"lg"}  >
                    <NavbarBrand href="/">
                        <img src={logo} width="220" height="auto" alt="Bettle Inc" className="ml-4" />
                    </NavbarBrand>
                    <Nav className="ml-auto text-right" navbar>
                        <NavItem className="px-3">
                            <Button style={STYLES.BUTTON_WHITE_ROUND} onClick={handleRegister} >Import Your Team</Button>
                        </NavItem>
                        <NavItem className="px-3">
                            <Button style={STYLES.BUTTON_PRIMARY_ROUND} onClick={handleLogin} >Log In</Button>
                        </NavItem>
                    </Nav>
                </Navbar>
            </div>
            <div className='d-flex align-items-center justify-content-between d-block d-lg-none py-3' >
                <img src={bettleBee} width="60" height="auto" alt="Bettle Inc" className="ml-4" />
                <div>
                    <Button onClick={handleRegister} style={
                        {
                            color: Colors.bgDark,
                            backgroundColor: "white",
                            border: `1px solid white`,
                            fontWeight: "500",
                            borderRadius: "20px",
                            fontFamily: "Alexandria",
                        }
                    } >Import Your Team</Button>
                    <Button onClick={handleLogin} className="mx-3" style={
                        {
                            color: Colors.bgDark,
                            backgroundColor: Colors.colorPrimary,
                            border: `1px solid ${Colors.colorPrimary}`,
                            fontWeight: "500",
                            borderRadius: "20px",
                            fontFamily: "Alexandria",
                        }
                    } >Log In</Button>
                </div>
            </div>
            {signInModal && <SignInModal screen={authScreen} isVisible={signInModal} closeModal={() => setSignInModal(!signInModal)} />}
        </div>
    )
}

const NewLanding = (props) => {

    const history = useHistory()

    useEffect(() => {
    }, []);

    return (
        <DocumentTitle title={`Bettle Fantasy Sports 2023`}>
            <div className="app" style={{ fontFamily: "Poppins" }} >
                <div className="flex1">
                    <div className="app-body landAppBody">
                        <main className="main roboto" >
                            <div>
                                <div className="mt-xs-5 d-flex flex-column" style={{ backgroundImage: `url(${landingBg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} >

                                    <LandingHeader />

                                    {/* mob view */}
                                    <div className='d-block d-sm-none d-md-none text-center'>
                                        <h1 style={{ fontFamily: "Alexandria", fontWeight: "500", fontSize: "36px" }} className="landHeading mb-1" >Bet on <span className='greenText' style={{ fontWeight: "bold", fontSize: "30px" }}>Yourself.</span></h1>
                                        <div className="land_desc mt-2 mt-md-2 text-center" style={{ lineHeight: 1.3 }}>
                                            <p className='landLead bold px-4' style={{ fontFamily: "Barlow", fontSize: "20px" }}>
                                                Import your beloved season-long fantasy teams and <span className='greenText' style={{ fontSize: "24px" }}>challenge <br /> anyone</span> to a match, every single <br /> week.
                                            </p>
                                        </div>
                                    </div>
                                    {/* !mob view */}

                                    <Row className={"flex-grow-1"} style={{ width: "1200px", maxWidth: "100%", margin: "auto" }} >

                                        <Col className="pl-3 p-md-0 d-flex flex-column flex1 justify-content-center m-0 p-0" >

                                            <div className="mt-0 mt-md-5 ml-md-4 mb-md-4" >
                                                {/* web view */}
                                                <div className='d-none d-sm-block d-md-block'>
                                                    <h1 style={{ fontFamily: "Alexandria", fontWeight: "bold", fontSize: "44px" }} className="landHeading mb-1" >Bet on <span className='greenText'>yourself</span>.</h1>
                                                    {/* <h1 style={{ fontFamily: "Fritch", fontWeight: "normal", fontSize: "44px" }} className="landHeading mb-1 greenText" >NOW GET PAID.</h1> */}
                                                    <div className="land_desc mt-4 mt-md-2" >
                                                        <p className='landLead bold' style={{ fontFamily: "Barlow", fontSize: "24px" }}>
                                                            Import your beloved season-long fantasy teams and <br /> <span className='greenText'>challenge anyone</span> to a match, every single week.
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* !web view */}
                                                <div className='d-flex align-items-center'>
                                                    {/* web view */}
                                                    <div className='align-items-center d-none d-md-flex' >
                                                        <div className='mr-3' >
                                                            <a target={"_blank"} href='https://apps.apple.com/us/app/bettle-fantasy-sports/id1591274931' ><img src={iosLogo} className="img-fluid" /></a>
                                                        </div>
                                                        <div>
                                                            <a target={"_blank"} href='https://play.google.com/store/apps/details?id=com.bettle.bettlelive&hl=en_US&gl=US' ><img src={androidLogo} className="img-fluid" /></a>
                                                        </div>
                                                    </div>
                                                    {/* !web view */}
                                                    <div className='d-flex flex1 justify-content-center' style={{ marginTop: "-40px" }}>
                                                        <div className="d-xs-flex d-sm-flex justify-content-center d-md-none overflow-hidden">
                                                            <div className="d-flex justify-content-center position-relative" style={{ overflow: 'hidden' }}>
                                                                <div className='sm-div-block-5'></div>
                                                                <div data-autoplay="true" data-loop="true" data-wf-ignore="true" className="sm-background-video-2 w-background-video sm-w-background-height w-background-video-atom w-100" style={{ marginTop: -10 }}>
                                                                    <video id="54b127ce-1440-a95b-7303-3714ce03db97-video" autoPlay="true" loop muted playsInline data-wf-ignore="true" data-object-fit="cover">
                                                                        <source src={BettleTranscode1} data-wf-ignore="true" />
                                                                        <source src={BettleTranscode2} data-wf-ignore="true" />
                                                                    </video>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* mob view */}
                                                <div className='h-25 d-xs-block d-sm-none' style={{ background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 71.34%)" }}>
                                                    <div className='d-flex d-md-none align-items-center justify-content-center mb-3'>
                                                            <div className='mr-4'>
                                                                <a target={"_blank"} href='https://apps.apple.com/us/app/bettle-fantasy-sports/id1591274931' ><img src={mobIosLogo} className="img-fluid" style={{ height: "48px" }} /></a>
                                                            </div>
                                                            <div className='' >
                                                                <a target={"_blank"} href='https://play.google.com/store/apps/details?id=com.bettle.bettlelive&hl=en_US&gl=US' ><img src={androidLogo} className="img-fluid" style={{ height: "48px" }} /></a>
                                                            </div>
                                                        </div>
                                                </div>
                                                    {/* !mob view */}
                                            </div>
                                        </Col>
                                        {/* <Col xs={'12'} className="d-xs-block d-sm-none">
                                <div className="image-contain d-xs-flex d-sm-flex flex1 d-md-none m-0 p-0 overflow-hidden">
                                    <div className="d-flex justify-content-center position-relative" style={{overflow:'hidden'}}>
                                    <div className='sm-div-block-5'></div>
                                    <div data-autoplay="true" data-loop="true" data-wf-ignore="true" className="background-video-2 w-background-video w-background-video-atom" style={{marginTop:-10}}>
                                        <video id="54b127ce-1440-a95b-7303-3714ce03db97-video" autoPlay="true" loop muted playsInline data-wf-ignore="true" data-object-fit="cover">
                                            <source src={BettleTranscode1} data-wf-ignore="true" />
                                            <source src={BettleTranscode2} data-wf-ignore="true" />
                                        </video>
                                    </div>
                                    </div>
                                </div>
                            </Col> */}
                                        <Col xs="12" md="7" className="d-none d-md-flex flex-column flex1 scrollHidden" >
                                            <div className="image-contain d-sm-none d-md-flex top">
                                                <div className="div-block-5"></div>
                                                <div data-autoplay="true" data-loop="true" data-wf-ignore="true" className="background-video-2 w-background-video w-background-height">
                                                    <video id="54b127ce-1440-a95b-7303-3714ce03db97-video" autoPlay="true" loop muted playsInline data-wf-ignore="true" data-object-fit="cover">
                                                        <source src={BettleTranscode1} data-wf-ignore="true" />
                                                        <source src={BettleTranscode2} data-wf-ignore="true" />
                                                    </video>
                                                </div>
                                            </div>
                                            {/* <img alt="Win Illustration" className="img-fluid" src={ArenaIntro} /> */}
                                        </Col>
                                    </Row>
                                    {/* <div className='green-bg py-3 mt-0'>
                                        <div className='row' style={{ width: "1200px", maxWidth: "100%", margin: "auto" }} >
                                            <Col md="8" xs="12" >
                                                <h4 className='bold darkText mb-2 landLead' >Get $25 FREE just for signing up!</h4>
                                                <p className='mb-0 darkText landLead' >Already have an account? Refer a friend for $10 bonus when they claim their team.</p>
                                            </Col>
                                            <Col md="4" xs="12" className={"text-md-right text-left mt-2 mt-md-0"} >
                                                <Button onClick={() => history.push('/register')} style={STYLES.BUTTON_DARK_ROUND} >Get Started</Button>
                                            </Col>
                                        </div>
                                    </div> */}
                                </div>
                                {/* web view */}
                                <div className='d-none d-xl-block' style={{ height: "90px", background: `linear-gradient(to right, #372F1B, ${Colors.secondary_100} 50%, #372F1B`, fontFamily: "Alexandria" }}>
                                    <div className='d-flex justify-content-center align-items-center h-100'>
                                        <div className='d-flex align-items-center mx-3' style={{ border: `2px solid ${Colors.secondary_30}` }}>
                                            <div className='d-flex align-items-center px-4' style={{ background: Colors.secondary_30 }}>
                                                <p className='m-0 p-0' style={{ fontSize: 41, fontWeight: "bold", color: Colors.secondary_100 }}>$10</p>
                                                <p className='m-0 px-3' style={{ fontSize: 20, fontWeight: "bold", color: Colors.secondary_100,lineHeight: 1 }}>FREE <br /> PLAY</p>
                                            </div>  
                                            <p className='m-0 px-4' style={{ fontSize: 20, fontWeight: "bold", color: Colors.secondary_30, lineHeight: 1 }}>SIGN-UP <br />BONUS</p>
                                        </div>
                                        <div className='mx-3'>
                                            <img src={pd1} alt="" />
                                        </div>
                                        <div className='d-flex align-items-center mx-3' style={{ border: `2px solid ${Colors.secondary_30}` }}>
                                            <p className='m-0 px-4' style={{ fontSize: 20, fontWeight: "bold", color: Colors.secondary_30, lineHeight: 1 }}>DEPOSIT <br /> MATCH</p>
                                            <div className='d-flex align-items-center px-4' style={{ background: Colors.secondary_30 }}>
                                                <p className='m-0 px-3' style={{ fontSize: 20, fontWeight: "bold", color: Colors.secondary_100, lineHeight: 1 }}>UP<br />TO</p>
                                                <p className='m-0 p-0' style={{ fontSize: 41, fontWeight: "bold", color: Colors.secondary_100 }}>$100</p>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                                {/* !web view */}

                                {/* mob view */}
                                <div className="d-block d-lg-none d-xs-none px-4 py-4" style={{background: "linear-gradient(180deg, #000000 0%, #1A1A1A 100%)", fontFamily: "Alexandria" }}>
                                    <div className='d-flex justify-content-center align-items-center h-75'>
                                        <div className='d-flex flex-column flex1' style={{ border: `3px solid ${Colors.secondary_30}` }}>
                                            <p className='m-0 text-center py-3' style={{ background: Colors.secondary_100, fontSize: 20, fontWeight: "bold", color: Colors.secondary_30, lineHeight: 1 }}>DEPOSIT <br /> MATCH</p>
                                            <div className='d-flex flex-column align-items-center' style={{ background: Colors.secondary_30 }}>
                                                <p className='m-0 pt-3' style={{ fontSize: 20, fontWeight: "bold", color: Colors.secondary_100, lineHeight: 1 }}>UP TO</p>
                                                <p className='m-0 p-0' style={{ fontSize: 48, fontWeight: "bold", color: Colors.secondary_100 }}>$100</p>
                                            </div>  
                                        </div>
                                        <div className='mx-2'>
                                            <img src={mobPlus} alt="" />
                                        </div>
                                        <div className='d-flex flex-column flex1' style={{ border: `3px solid ${Colors.secondary_30}` }}>
                                            <div className='d-flex flex-column align-items-center' style={{ background: Colors.secondary_30 }}>
                                                <p className='m-0 pt-3' style={{ fontSize: 20, fontWeight: "bold", color: Colors.secondary_100, lineHeight: 1 }}>FREE PLAY</p>
                                                <p className='m-0 p-0' style={{ fontSize: 48, fontWeight: "bold", color: Colors.secondary_100 }}>$10</p>
                                            </div>  
                                            <p className='m-0 py-3 text-center' style={{ fontSize: 20, fontWeight: "bold", color: Colors.secondary_30, lineHeight: 1, background: Colors.secondary_100 }}>SIGN-UP <br />BONUS</p>
                                        </div>
                                    </div>
                                    <div className='text-center' style={{ padding: "48px 0px 0px 0px" }}>
                                        <h1 style={{ fontFamily: "Alexandria", fontWeight: "500", fontSize: "36px" }} className="landSubHeading mb-1" >Unlock Your </h1>
                                        <h1 style={{ fontFamily: "Alexandria", fontWeight: "500", fontSize: "36px" }} className="landSubHeading mb-1 greenText" >Full Potential.</h1>
                                    </div>
                                </div>
                                <div className='text-center px-3 d-block d-lg-none d-xs-none' style={{background: "#1a1a1a"}} >
                                    <p className='px-2 mb-5' style={{ fontFamily: "Barlow", fontSize: "20px", lineHeight: 1.3 }}>
                                        Bettle lets you have more fun with <br /> your fantasy football, fantasy <br /> baseball, and fantasy basketball <br /> teams. Create side pots with your <br /> league mates, challenge a stranger to <br /> a head to head match, or just talk <br /> shop with a fellow fantasy guru.
                                    </p>
                                    <div className="d-flex align-items-center justify-content-around my-4">
                                        <div ><img className='img-responsive' src={YAHOO} loading="lazy" width="150" alt=""></img></div>
                                        <div ><img className='img-responsive' src={ESPN} loading="lazy" width="60" alt="" /></div>
                                        <div ><img className='img-responsive' src={sleeper} loading="lazy" width="100" alt="" /></div>
                                    </div>
                                    <Col xs="12" md="4" className={"text-center pb-5 pt-3"} >
                                        <img alt="Win Illustration" className="img-fluid w-75" src={bettleMobile} />
                                    </Col>
                                </div>
                                {/* !mob view */}

                                <div className="p-3 p-md-5 d-none d-md-block" style={{ background: "#1D2226" }} >
                                    <div style={{ width: "1200px", maxWidth: "100%", margin: "auto" }}>

                                        {/* <Row className={"d-flex d-md-none mt-4"} >
                                            <Col xs="12" md="8">
                                                <div className="mt-0 mt-md-5" >
                                                    <h1 style={{ fontFamily: "Fritch", fontWeight: "normal", fontSize: "36px" }} className="landSubHeading mb-1" >UNLOCK YOUR </h1>
                                                    <h1 style={{ fontFamily: "Fritch", fontWeight: "normal", fontSize: "36px" }} className="landSubHeading mb-1 greenText" >FULL POTENTIAL.</h1>
                                                    <div className="land_desc mt-4 mt-md-4" >
                                                        <p className='landLead' >
                                                            Got a powerhouse you need to ride like Seabiscuit?  Early injuries or bad matchups tank your record?  Want to cash in before your studs get shutdown?  Break out of the league structure and into the Bettle universe, where you can have unlimited weekly matchups, choose who you compete against, and give or get odds.                                            </p>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-around my-4">
                                                    <div ><img className='img-responsive' src={YAHOO} loading="lazy" width="150" alt=""></img></div>
                                                    <div ><img className='img-responsive' src={ESPN} loading="lazy" width="60" alt="" /></div>
                                                    <div ><img className='img-responsive' src={sleeper} loading="lazy" width="100" alt="" /></div>
                                                </div>
                                            </Col>
                                            <Col xs="12" md="4" className={"text-center mb-4"} >
                                                <img alt="Win Illustration" className="img-fluid w-75" src={bettleMobile} />
                                            </Col>
                                        </Row> */}

                                        <Row className={"d-none d-md-flex"} >
                                            <Col className="my-auto" xs="12" md="4" >
                                                <img alt="Win Illustration" className="img-fluid" src={bettleMobile} />
                                            </Col>
                                            <Col xs="12" md="8" className="d-md-block d-none" >
                                                <div className="mt-0 mt-md-5" >
                                                    <h1 style={{ fontFamily: "Alexandria", fontWeight: "bold", fontSize: "44px" }} className="landSubHeading mb-1" >Unlock your <span style={{ fontFamily: "Alexandria", fontWeight: "bold", fontSize: "44px" }} className="landSubHeading mb-1 greenText" >full potential.</span></h1>
                                                    <div className="land_desc mt-4 mt-md-4" >
                                                        <p className='landLead bold' style={{ fontFamily: "Barlow", fontSize: "24px" }}>
                                                            Bettle lets you have more fun with your fantasy football, fantasy baseball, and fantasy basketball teams. Create side pots with your league mates, challenge a stranger to a head to head match, or just talk shop with a fellow fantasy guru.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row mt-5">
                                                    <div className="col-md-4 align-self-center col-12 my-md-0 my-1"><img className='img-fluid' src={YAHOO} loading="lazy" width="230" alt=""></img></div>
                                                    <div className="col-md-4 align-self-center col-12 my-md-0 my-1 text-center"><img className='img-fluid' src={ESPN} loading="lazy" width="150" alt="" /></div>
                                                    <div className="col-md-4 align-self-center col-12 my-md-0 my-1"><img className='img-fluid' src={sleeper} loading="lazy" width="230" alt="" /></div>
                                                </div>
                                            </Col>
                                        </Row>


                                    </div>
                                </div>
                                <div style={{ background: "#013020" }} >
                                    <Row className={"align-items-center"} style={{ width: "1300px", maxWidth: "100%", margin: "auto" }} >
                                        <Col xs="12" md="8" >
                                            <div className="p-md-5 p-0 mt-5" >
                                                <h1 style={{ fontFamily: "Alexandria", fontWeight: "500", fontSize: "44px" }} className="bold landSubHeading mb-4 mx-3" >Licensed in Over <span style={{ fontFamily: "Alexandria", fontWeight: "bold" }} className="bold landSubHeading mb-3 greenText" >28 states.</span></h1>
                                                <Row className="landLead w-100" style={{ fontFamily: "Barlow", fontWeight: "500", fontSize: "16px" }}>
                                                    {/* <Col lg="3" md="4" sm="6" xs="6"> */}
                                                        <ul className='w-100' style={{columnCount:'3', columnWidth:'120px', columnGap:'50px'}}>
                                                            <li className='mb-2' >Arkansas</li>
                                                            <li className='mb-2'>California</li>
                                                            <li className='mb-2'>Colorado</li>
                                                            <li className='mb-2'>Connecticut</li>
                                                            <li className='mb-2'>Florida</li>
                                                            <li className='mb-2'>Georgia</li>
                                                            <li className='mb-2'>Illinois</li>
                                                            <li className='mb-2'>Kansas</li>
                                                        {/* </ul>
                                                    </Col>
                                                    <Col lg="3" md="4" sm="6" xs="6">
                                                        <ul> */}
                                                            <li className='mb-2' >Kentucky</li>
                                                            <li className='mb-2'>Maine</li>
                                                            <li className='mb-2'>Massachusetts</li>
                                                            <li className='mb-2'>Michigan</li>
                                                            <li className='mb-2'>Minnesota</li>
                                                            <li className='mb-2'>Nebraska</li>
                                                        {/* </ul>
                                                    </Col>
                                                    <Col lg="3" md="4" sm="6" xs="6">
                                                        <ul> */}
                                                            <li className='mb-2' >New Jersey</li>
                                                            <li className='mb-2' >North Carolina</li>
                                                            <li className='mb-2'>North Dakota</li>
                                                            <li className='mb-2'>Ohio</li>
                                                            <li className='mb-2'>Oklahoma</li>
                                                            <li className='mb-2'>Oregon</li>
                                                            <li className='mb-2'>Rhode Island</li>
                                                            <li className='mb-2'>South Carolina</li>
                                                        {/* </ul>
                                                    </Col>
                                                    <Col lg="3" md="4" sm="6" xs="6" >
                                                        <ul> */}
                                                            <li className='mb-2' >Texas</li>
                                                            <li className='mb-2'>Tennessee</li>
                                                            <li className='mb-2'>Utah</li>
                                                            <li className='mb-2'>West Virginia</li>
                                                            <li className='mb-2'>Wisconsin</li>
                                                            <li className='mb-2'>Wyoming</li>
                                                        </ul>
                                                    {/* </Col> */}
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col xs="12" md="4"  >
                                            <div className="mt-5 text-center mb-0 mb-4" >
                                                <img src={UsaMap} className="img-fluid" />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
                <Footer2022 />
            </div>
        </DocumentTitle>
    )
}

export default NewLanding;