import React, { useEffect, useState } from 'react';
import { mmNotifications, winProbability } from '../../../apis/Arena/MatchMakerApis';
import { LoaderCenter } from '../../../components/Loader';
// import { Colors } from '../../../helpers/colors';
import { getStore, sportFromExperience } from '../../../helpers/common-methods';
import { arrowRightIcon, chevronRightIcon } from '../../../helpers/icons';
import store from '../../../store';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import './ArenaAccordian.css';
import { MatchNotificationModal } from '../../../modals/Arena/MatchNotificationModal';
import { useSelector } from 'react-redux';
import SeeAllNotificationsModal from '../../../modals/Arena/SeeAllNotificationsModal';
import DownloadOurApp from '../../../components/CTA/DownloadOurApp';
import { useColors } from '../../../helpers/CustomHooks/useColors';
import CounterProposalCollapse from '../../../components/Matchmaker/Collapse/CounterProposalCollapse';
import MatchProposalCollapse from '../../../components/Matchmaker/Collapse/MatchProposalCollapse';
import moment from 'moment-timezone';
import { motion } from 'framer-motion/dist/framer-motion';
import { parseScoringPeriod } from '../../../helpers/Arena/ArenaHelper';
import ArenaMatchModal from '../../../modals/Arena/ArenaMatchModal';

const ArenaNotifications = (props) => {
	const { arenaData, activeTab, proposalModal, setProposalModal, isCounter, counterMatchId, selectedLineup, propsWinProbabData, propsWinProbabStatus, stateSetterCallback } = props
	
	const Colors = useColors();
	const [ loading, setLoading ] = useState(true);
	const [ data, setData ] = useState({});
	const experience = store.getState().leagueJoin.app_status;
	const sport = sportFromExperience(experience, true)
	const refreshSideNotification = useSelector(state => state.NavReducer.refreshSideNotification)
	const [allNotificationModal, setAllNotificationModal] = useState(false)
	const [filter, setFilter] = useState(null)
	const activeTeam = useSelector(state => state.arenaReducer.active_team)
    const [winProbabData, setWinProbabData] = useState();
	const [winProbabStatus, setWinProbabStatus] = useState();
	const [actionMatchId, setActionMatchId] = useState(null);

	const styles = {
		divider: {
			borderBottom: `1px solid ${Colors.darkBgBorder}`
		},
		dividerWarning: {
			borderBottom: `1px solid rgba(148, 122, 48, 0.5)`
		},
		dividerSuccess: { borderBottom: `1px solid rgba(27, 147, 104, 0.5)` }
	};

	useEffect(() => {
		callApi()
		// PUSHER RELATED WORK
		const auth = store.getState().auth
		window.Echo.private('arenaNotify.'+auth.user.id)
        .listen('NotificationEvent', (e) => {
            // REFRESH IT
			callApi()
        });
	}, [refreshSideNotification, activeTeam])

	const callApi = () => {
		setLoading(true);
		var qs;
		if (activeTeam?.teamId !== "all") {
			qs = '?sport=' + sport+"&teamId="+activeTeam?.teamId+"&teamWise=true";
		} else {
			qs = "?teamId="+activeTeam?.teamId;
		}

		mmNotifications(qs).then((resp) => {
			setData(resp);
			winProbabilityAPI(resp);
			setLoading(false);
		})
	}

	const openActionModal = (matchData) => {
		// setActionData(matchData)
		// setActionModal(!actionModal)
		const prepData = {
			status: matchData.propStatus,
			matchId: matchData.matchId,
		};
		// CLOSING THE CREATE PROPOSAL MODAL IN CASE IF IT IS OPEN ALREADY
		setProposalModal(false)
		setActionMatchId(prepData);
		getStore().dispatch({ type: "OPEN_MIDDLE_MODAL", payload: true });
		getStore().dispatch({ type: "SAVE_ACTION_MATCH_DATA", payload: matchData });
	}

	const toggleAllNotificationModal = (filter = null) => {
		setFilter(filter)
		setAllNotificationModal(!allNotificationModal)
	}

	const refreshData = () => {
		// Reload this current page
		history.go(0)
	}

	const winProbabilityAPI = (response) => {
		if (response.needActions.length > 0) {
			winProbability({ matchId: response.needActions[0]?.matchId, sport: response.needActions[0]?.sport, timeFrame: response?.needActions[0]?.timeFrameKey }).then((resp) => {
			setWinProbabData(resp.data);
			setWinProbabStatus(resp.data.status);
		}).catch((err) => {
			console.log(err);
		});
		};
	};

	return (
		</*div className="flex1 w-100 scrollHidden p-0" style={{ borderLeft: `1px solid ${Colors.darkBgBorder}` }}*/>
			{/* <div className="d-flex align-items-center justify-content-between py-3 px-3" style={{ background: Colors.bgDark, borderBottom: `1px solid ${Colors.darkBgBorder}` }}> */}
				{/* <div>
					<div className="infoText" style={{ letterSpacing: '2px' }}>
						NOTIFICATIONS
					</div>
				</div> */}
				{/* <div className="" >
            <i class="fa fa-heart-o greenText" style={{fontSize: "1.3rem"}} aria-hidden="true"></i>
          </div> */}
			{/* </div> */}
			<div className='position-relative d-flex flex-column flex1 scrollHidden w-100'>
				{
					loading 
					? 
					<LoaderCenter /> 
					:
					data.needActionsCounts > 0 || data.counteredCounts > 0 || data.pendingCounts > 0 
					?
					<div className='h-100'>
						<div className='d-flex flex-column flex1 scrollHidden justify-content-between h-100 w-100' style={{ position: "relative" }}>
							<div className="p-3" style={{overflow:'auto'}}>
								{data.needActions.length > 0 && (
									<div>
										<div className="d-flex align-items-center justify-content-between py-2 px-3" style={{ background: Colors.colorDark }}>
											<div style={{ fontSize: 12, fontWeight: 600, letterSpacing: 2 }}>NEED ACTIONS</div>
											<div className="d-flex align-items-center justify-content-center p-2" style={{ background: 'white', borderRadius: '50%', width: 25, height: 25 }}>
												<div className="primaryText bold">{data.needActionsCounts}</div>
											</div>
										</div>
										<div style={styles.divider} />
									</div>
								)}

								<Accordion style={{ background: Colors.colorDark }} allowZeroExpanded={true}>
									{data.needActions.map((match) => {
										return (
											<div onClick={() => openActionModal(match)} className="pointer" >
												<div className="d-flex align-items-center justify-content-between py-2 px-3">
													<div className='d-flex align-items-center' >
														<div
															className="px-2 primaryText bold"
															style={{
																background:

																		match.propStatus == 'P' ? Colors.mlbPrimaryColor :
																		Colors.colorWarning,
																		marginTop: -18
															}}>
															{match.propStatus}
														</div>
														<div className='d-flex flex-column'>
															<div className="ml-2 p-0"><span className='bold' style={{ color: match.propStatus == 'P' ? Colors.mlbPrimaryColor : Colors.colorWarning  }}>FROM </span><span className='bold' >{match.selectedLineup.teamName}</span><span className='bold p-1 ml-1' style={{ backgroundColor: match.propStatus == 'P' ? Colors.colorPrimaryTransparent_v2 : Colors.colorPrimaryTransparent_v3, color: match.propStatus == 'P' ? Colors.mlbPrimaryColor : Colors.colorWarning, borderRadius: 22, width: "36px", height: "24px" }}>{match.entryFee !== null ? `$${match.entryFee}` : "Free"}</span></div>
															<div className='ml-2 p-0'>{moment(match?.selectedLineup?.proposedData?.timeFrame?.start).tz("America/New_Yor").format("ddd, MMM D")} – {moment(match?.selectedLineup?.proposedData?.timeFrame?.end).tz("America/New_Yor").format("ddd, MMM D")} </div>
														</div>
													</div>
													<motion.div 
														animate={{ x: [0, 6, 0] }}
														transition={{
															duration: 0.5,
															repeat: Infinity,
														}}
													>
														<div style={{ width: 20, height: 20, aspectRatio: 1 }}>{arrowRightIcon()}</div>	
													</motion.div>
												</div>
												<div style={styles.divider} />
											</div>
										);
									})}
									{/* See All */}
									{data.needActionsCounts > 5 && <div onClick={() => toggleAllNotificationModal('NEED_ACTION')} className="pointer" >
										<div className="d-flex align-items-center justify-content-between py-2 px-3">
												<div className='d-flex align-items-center' >
													<div
														className="primaryText bold"
														style={{
															background:

																	Colors.colorPrimary
														}}>
													</div>
													<div className="" ><span className='bold' >See All (+{data.needActionsCounts-5})</span></div>
												</div>
												<div style={{ width: 20, height: 20, aspectRatio: 1 }}>{arrowRightIcon()}</div>
											</div>
											<div style={styles.divider} />
										</div>
									}
									{/* !See All */}
								</Accordion>

								{data.countered.length > 0 && (
									<div>
										<div className="d-flex align-items-center justify-content-between py-2 px-3" style={{ background: Colors.colorWarningTransparent }}>
											<div style={{ fontSize: 12, fontWeight: 600, letterSpacing: 2 }}>COUNTER PROPOSAL</div>
											<div className="d-flex align-items-center justify-content-center p-2" style={{ background: Colors.colorWarning, borderRadius: '50%', width: 25, height: 25 }}>
												<div className="primaryText bold">{data.counteredCounts}</div>
											</div>
										</div>
										<div style={styles.dividerWarning} />
									</div>
								)}
								<Accordion style={{ background: Colors.colorWarningTransparent }} allowZeroExpanded={true}>
									{data.countered.map((pending) => {
										return (
											<AccordionItem>
												<AccordionItemHeading className="pointer">
													<AccordionItemButton>
														<div className="d-flex align-items-center justify-content-between py-2 px-3">
															<div className='d-flex'>
															<p className='m-0 bold' style={{ color: Colors.colorWarning }}>TO</p>
															<p className='m-0 bold pl-1'>{pending.oppData.teamName}</p>
															<p className='bold m-0 ml-1 text-center' style={{ background: Colors.colorWarningTransparent, color: Colors.colorWarning, borderRadius: 22, width: "36px", height: "24px" }}>{pending.entryFee !== null ? `$${pending.entryFee}` : "Free"}</p>
															</div>
															{pending.isPaid && 
																<div style={{ width: 15, height: 15, aspectRatio: 1 }}>{chevronRightIcon()}</div>
															}
														</div>
													</AccordionItemButton>
												</AccordionItemHeading>
												{pending.isPaid && <AccordionItemPanel>
													<div className='d-flex align-items-center'>
														<p className='m-0' style={{ color: Colors.colorWarning, fontSize: 13 }}>Start Date</p>
														<p className='m-0 ml-2' style={{ fontSize: 15 }}>{parseScoringPeriod(pending.timeFrame.start, pending.timeFrame.end)}</p>
													</div>
													<div className='d-flex justify-content-between align-items-center my-2'>
														<div>
															<p className='m-0' style={{ color: Colors.colorWarning, fontSize: 12 }}>Bet Amount</p>
															<p className='m-0 py-1 px-4' style={{ background: Colors.colorWarningTransparent, color: Colors.colorWarning, borderRadius: 4 }}>${pending.entryFee}</p>
														</div>
														<div>
															<p className='m-0' style={{ color: Colors.colorWarning, fontSize: 12 }}>Odds Boost</p>
															<p className='m-0 py-1 px-4' style={{ background: Colors.colorWarningTransparent, color: Colors.colorWarning, borderRadius: 4 }}>${pending.possibleWins.oddBoost}</p>
														</div>
														<div>
															<p className='m-0' style={{ color: Colors.colorWarning, fontSize: 12 }}>Winnings</p>
															<p className='m-0 py-1 px-4' style={{ background: Colors.colorWarningTransparent, color: Colors.colorWarning, borderRadius: 4 }}>${pending.possibleWins.possibleWin}</p>
														</div>
													</div>
												</AccordionItemPanel>}
												<div style={styles.dividerSuccess} />
											</AccordionItem>
										);
									})}

									{/* See All */}
									{data.counteredCounts > 5 && <div onClick={() => toggleAllNotificationModal('COUNTERED')} className="pointer" >
										<div className="d-flex align-items-center justify-content-between py-2 px-3">
												<div className='d-flex align-items-center' >
													<div
														className="primaryText bold"
														style={{
															background:

																	Colors.colorPrimary
														}}>
													</div>
													<div className="" ><span className='bold' >See All (+{data.counteredCounts-5})</span></div>
												</div>
												<div style={{ width: 20, height: 20, aspectRatio: 1 }}>{arrowRightIcon()}</div>
											</div>
											<div style={styles.divider} />
										</div>
									}
									{/* !See All */}
								</Accordion>

								{data.pending.length > 0 && (
									<div>
										<div className="d-flex align-items-center justify-content-between py-2 px-3" style={{ background: Colors.darkGreen_v3 }}>
											<div style={{ fontSize: 12, fontWeight: 600, letterSpacing: 2 }}>PROPOSALS</div>
											<div className="d-flex align-items-center justify-content-center p-2" style={{ background: Colors.mlbPrimaryColor, borderRadius: '50%', width: 25, height: 25 }}>
												<div className="primaryText bold">{data.pendingCounts}</div>
											</div>
										</div>
										<div style={styles.dividerSuccess} />
									</div>
								)}
								<Accordion style={{ background: Colors.darkGreen_v3 }} allowZeroExpanded={true}>
									{data.pending.map((pending) => {
										return (
											<AccordionItem>
												<AccordionItemHeading className="pointer">
													<AccordionItemButton>
														<div className="d-flex align-items-center justify-content-between py-2 px-3">
															<div className='d-flex align-items-center'>
																<span style={{ fontWeight: 600, fontSize: 14, color: Colors.mlbPrimaryColor }}>TO</span> <span className="ml-1" style={{ fontWeight: 600, fontSize: 14 }}>{pending.oppData.teamName}</span>
																<p className='m-0 ml-1 text-center' style={{ background: Colors.colorDark, width: "36px", height: "24px", borderRadius: 22, fontWeight: 600, color: Colors.mlbPrimaryColor }}>{pending.entryFee !== null ? `$${pending.entryFee}` : "Free"}</p>
															</div>
															<div style={{ width: 15, height: 15, aspectRatio: 1 }}>{chevronRightIcon()}</div>
														</div>
													</AccordionItemButton>
												</AccordionItemHeading>
												{pending.isPaid ? (<AccordionItemPanel>
													<div className='d-flex align-items-center'>
														<p className='m-0' style={{ color: Colors.mlbPrimaryColor, fontSize: 13 }}>Start Date</p>
														<p className='m-0 ml-2' style={{ fontSize: 15 }}>{parseScoringPeriod(pending.timeFrame.start, pending.timeFrame.end)}</p>
													</div>
													<div className='d-flex justify-content-between align-items-center my-2'>
														<div>
															<p className='m-0' style={{ color: Colors.mlbPrimaryColor, fontSize: 12 }}>Bet Amount</p>
															<p className='m-0 py-1 px-4' style={{ background: Colors.colorPrimaryTransparent_v2, color: Colors.mlbPrimaryColor, borderRadius: 4 }}>${pending.entryFee}</p>
														</div>
														<div>
															<p className='m-0' style={{ color: Colors.mlbPrimaryColor, fontSize: 12 }}>Odds Boost</p>
															<p className='m-0 py-1 px-4' style={{ background: Colors.colorPrimaryTransparent_v2, color: Colors.mlbPrimaryColor, borderRadius: 4 }}>${pending.possibleWins.oddBoost}</p>
														</div>
														<div>
															<p className='m-0' style={{ color: Colors.mlbPrimaryColor, fontSize: 12 }}>Winnings</p>
															<p className='m-0 py-1 px-4' style={{ background: Colors.colorPrimaryTransparent_v2, color: Colors.mlbPrimaryColor, borderRadius: 4 }}>${pending.possibleWins.possibleWin}</p>
														</div>
													</div>
												</AccordionItemPanel>) : (
													<AccordionItemPanel>
														<p>Free Match</p>
													</AccordionItemPanel>
												)}
												<div style={styles.dividerSuccess} />
											</AccordionItem>
										);
									})}

									{/* See All */}
									{data.pendingCounts > 5 && <div onClick={() => toggleAllNotificationModal('PENDING')} className="pointer" >
										<div className="d-flex align-items-center justify-content-between py-2 px-3">
												<div className='d-flex align-items-center' >
													<div
														className="primaryText bold"
														style={{
															background:

																	Colors.colorPrimary
														}}>
													</div>
													<div className="" ><span className='bold' >See All (+{data.pendingCounts-5})</span></div>
												</div>
												<div style={{ width: 20, height: 20, aspectRatio: 1 }}>{arrowRightIcon()}</div>
											</div>
											<div style={styles.divider} />
										</div>
									}
									{/* !See All */}
								</Accordion>

							</div>
						</div>

						{/* TRIGGERS WHEN CLICK ON NEED ACTION NOTIFICATIONS */}
						<motion.div
							// className="h-100"
							animate={{ y: actionMatchId !== null ? -25 : 200 }}
							transition={{ duration: .5 }}
							style={{ position: 'absolute', bottom: 0, left: 0, right: 0, zIndex: 1 }}
							>
							{actionMatchId !== null &&
								<div className='d-flex flex-column flex1 scrollHidden'>
									{
										data.needActions.length > 0 
										&&
										(
											<>
												{actionMatchId?.status == "P" ?
												<MatchProposalCollapse matchData={data.needActions[0]} sport={sport} arenaData={arenaData} refresh={refreshData} winProbData={winProbabData} status={winProbabStatus} propMatchId={actionMatchId?.status == "P" ? actionMatchId?.matchId : null} setActionMatchId={setActionMatchId} />
												:
												<CounterProposalCollapse matchData={data.needActions[0]} sport={sport} arenaData={arenaData} refresh={refreshData} winProbData={winProbabData} status={winProbabStatus} propMatchId={actionMatchId?.status == "C" ? actionMatchId?.matchId : null} setActionMatchId={setActionMatchId} />
												}
											</>
										)
									}
								</div>
							}
						</motion.div>
					</div>
					:
						<h5 className='my-4 text-center w-100'>No notifications here.</h5>
					}

					{/* MODAL TO START NEW PROPOSAL FROM SCRATCH */}
					<motion.div
						// className="h-100"
						animate={{ y: proposalModal ? -20 : 200 }}
						transition={{ duration: .5 }}
					>
						{proposalModal &&
								<div className='d-flex flex-column flex1 scrollHidden' style={{ borderRadius: 10 }}>
									<ArenaMatchModal 
										arenaData={arenaData}
										isCounter={isCounter}
										counterMatchId={counterMatchId}
										selectedLineup={selectedLineup}
										modal={proposalModal}
										sport={sport}
										winProbData={propsWinProbabData} 
										status={propsWinProbabStatus}
										slug={"arenaHome"}
										stateSetterCallback={stateSetterCallback}
									/>	
								</div>
						}
					</motion.div>
			</div>


				{allNotificationModal && <SeeAllNotificationsModal 
					modal={allNotificationModal} 
					toggle={toggleAllNotificationModal} 
					sport={sport}
					filter={filter}
					styles={styles}
				/>
				}
		</ /*div*/>
	);
};

export default ArenaNotifications;
