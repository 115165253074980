
export const SAVE_ACTIVE_LEAGUE_ID = 'SAVE_ACTIVE_LEAGUE_ID'
export const SAVE_ACTIVE_LEAGUE = 'SAVE_ACTIVE_LEAGUE'
export const SAVE_ALL_LEAGUES = 'SAVE_ALL_LEAGUES'
export const SAVE_CURRENT_LEAGUE_CHANNELS = 'SAVE_CURRENT_LEAGUE_CHANNELS'
export const SAVE_NEW_CHANNEL = 'SAVE_NEW_CHANNEL'
export const SAVE_CURRENT_LEAGUE_USERS = 'SAVE_CURRENT_LEAGUE_USERS'
export const SAVE_BOTS = 'SAVE_BOTS'
export const SAVE_MY_TEAM_DETAILS = 'SAVE_MY_TEAM_DETAILS'
export const SAVE_CURRENT_TEAM = 'SAVE_CURRENT_TEAM'
export const SET_ONLINE_STATUS = 'SET_ONLINE_STATUS'
export const UPDATE_UNREAD_COUNT_CHANNEL = 'UPDATE_UNREAD_COUNT_CHANNEL'
export const UPDATE_UNREAD_COUNT_USER = 'UPDATE_UNREAD_COUNT_USER'
export const SAVE_APPLICATION_STATUS = 'SAVE_APPLICATION_STATUS'

const ENDPOINTS = {
  GET_LEAGUES: '/dfs/leagues',
  UPDATE_ACTIVE_LEAGUE_ID:'user/updateActiveLeague',
  FETCH_MY_TEAM:'/sl/myTeam'
}
/////////////////////////
// ACTIONS START HERE //
///////////////////////
export const setActiveLeagueIdAction = league_id => ({
  type: SAVE_ACTIVE_LEAGUE_ID,
  leagueId:league_id
});

export const saveActiveLeagueAction = activeLeague => ({
  type: SAVE_ACTIVE_LEAGUE,
  activeLeague: activeLeague
});

export const saveAllLeaguesAction = allLeagues => ({
  type: SAVE_ALL_LEAGUES,
  allLeagues: allLeagues
});

export const saveCurrentLeagueChannelsAction = channels => ({
  type: SAVE_CURRENT_LEAGUE_CHANNELS,
  channels:channels
})

export const saveNewChannelAction = channel => ({
  type: SAVE_NEW_CHANNEL,
  channel: channel
})

export const saveCurrentLeagueUsersAction = users => ({
  type: SAVE_CURRENT_LEAGUE_USERS,
  users: users
})

export const saveBotsAction = bots => ({
  type: SAVE_BOTS,
  bots: bots
})

export const saveMyTeamDetailsAction = myTeam => ({
  type: SAVE_MY_TEAM_DETAILS,
  my_team: myTeam
})

export const saveCurrentTeamAction = currentTeam => ({
  type: SAVE_CURRENT_TEAM,
  current_team: currentTeam
})

export const setOnlineStatusAction = users => ({
  type: SET_ONLINE_STATUS,
  users: users
})

export const updateUnreadCountChannelAction = channels => ({
  type: UPDATE_UNREAD_COUNT_CHANNEL,
  channels: channels
})

export const updateUnreadCountUserAction = users => ({
  type: UPDATE_UNREAD_COUNT_USER,
  users: users
})

export const saveApplicationStatusAction = app_status => ({
  type: SAVE_APPLICATION_STATUS,
  app_status: app_status
})

/////////////////////////
// ACTIONS END HERE ////
///////////////////////


export const getLeagues = ()  => {
    return window.axios.get(ENDPOINTS.GET_LEAGUES)
      .then((response) => {
        return Promise.resolve(response.data.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

export const updateActiveLeagueId = (league_id, dontSetId = null) => dispatch => {
  return window.axios.post(ENDPOINTS.UPDATE_ACTIVE_LEAGUE_ID,{leagueId:league_id})
  .then((response) => {
    if(dontSetId == null){
      dispatch(setActiveLeagueIdAction(league_id));
    }
    return Promise.resolve(response.data.data);
  })
  .catch(error => {
    return Promise.reject(error);
  });
}

// export const fetchMyTeam = (league_id) => {
//   return window.axios.get(ENDPOINTS.FETCH_MY_TEAM,{
//       params:{
//         league_id:league_id
//       }
//     })
//     .then((response) => {
//       return Promise.resolve(response.data);
//     })
//     .catch(error => {
//       return Promise.reject(error);
//     })
// }

export const saveActiveLeague = (activeLeague) => dispatch => {
    dispatch(saveActiveLeagueAction(activeLeague));
}

export const saveAllLeagues = (allLeagues) => dispatch => {
  dispatch(saveAllLeaguesAction(allLeagues));
}

export const saveCurrentLeagueChannels = (channels) => dispatch => {
  dispatch(saveCurrentLeagueChannelsAction(channels));
}

export const saveNewChannel = (channel) => dispatch => {
  dispatch(saveNewChannelAction(channel));
}
export const saveCurrentLeagueUsers = (users) => dispatch => {
  dispatch(saveCurrentLeagueUsersAction(users));
}

export const saveBots = (bots) => dispatch => {
  dispatch(saveBotsAction(bots));
}

export const saveCurrentTeam = (currentTeam) => dispatch => {
  dispatch(saveCurrentTeamAction(currentTeam));
}

export const setOnlineStatus = (users) => dispatch => {
  dispatch(setOnlineStatusAction(users));
}

export const updateUnreadCount = (data,type) => dispatch => {
  if (type == 'group') {
    dispatch(updateUnreadCountChannelAction(data));
  }
  else if(type == 'user') {
    dispatch(updateUnreadCountUserAction(data));
  }
  
}

export const saveApplicationStatus = (app_status) => dispatch => {
  dispatch(saveApplicationStatusAction(app_status));
}