import React, { useState, useEffect } from "react"
import PlayerCardHeader from "./PlayerCardHeader"
import PlayerCardNav from "./PlayerCardNav"
import PlayerCardTabs from "./PlayerCardTabs"
import { STORE, TOAST } from "../../../../helpers/common-methods"
import { mlbPlayerDetail } from "../../../../apis/SeasonLong/PlayerAPI/PlayerAPI"
import { toastError } from "../../../../helpers/toasts"
import PlayerCardActions from "./PlayerCardActions"
import { LoaderCenter } from "../../../../components/Loader"
import { playerDetail } from "../../../../apis/SeasonLong/PlayerAPI/PlayerAPI"

const PlayerCard = (props) => {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('overview');
    const [usefulData, setUsefuData] = useState(null);

    const handleActiveTab = (tab) => {
        let parsedTab = 'overview'
        switch (tab) {
            case 1:
                parsedTab = 'overview';
                break;
            case 2:
                parsedTab = 'gamelog';
                break;
            case 3:
                parsedTab = 'stats';
                break;
            case 4:
                parsedTab = 'news';
                break;
            case 5:
                parsedTab = 'bio';
                break;
            default:
                toastError('Something went wrong!');
        }
        setActiveTab(parsedTab)
    }

    useEffect(() => {
        if (props.sport == 'MLB') {
            mlbPlayerDetail("?playerId=" + props.playerId + "&league_id=" + props.leagueId)
                .then(response => {
                    let { stats, statAbbr, statHeaders, statsTab, news, pos } = response.data
                    let { playerId, leagueId } = props
                    // console.log(fetchedUsefulData);
                    setData(response.data)
                    setUsefuData({ stats, statAbbr, statHeaders, statsTab, news, playerId, leagueId, pos })
                    setLoading(false)
                })
                .catch(({ message }) => {
                    toastError(message)
                })
        }
        else (
            playerDetail({ playerId: props.playerId, league_id: props.leagueId }).then((response) => {
                // console.log(response.data);
                let { stats, statAbbr, statHeaders, statsTab, news, pos } = response.data
                let { playerId, leagueId } = props
                setData(response.data)
                setUsefuData({ stats, statAbbr, statHeaders, statsTab, news, playerId, leagueId, pos })
                setLoading(false)
            }).catch((err) => {
                TOAST.error(err)
            })
        )

    }, []);


    return (
        loading ? (<LoaderCenter />) : (<div className="d-flex flex-column" style={{ height: '100%' }}>
            <PlayerCardHeader data={data} teams={props.teams} />
            {/* <PlayerCardStats points={data.points} /> */}
            <PlayerCardNav handleActiveTab={handleActiveTab} />
            <PlayerCardTabs bio={data} tab={activeTab} data={usefulData} sport={props.sport} />
            <PlayerCardActions data={data} />
        </div>)
    )
}

export default PlayerCard