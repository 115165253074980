import React from 'react'
// import {Colors} from '../../../helpers/colors'
import Header from './Header'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useColors } from '../../../helpers/CustomHooks/useColors';

const MemberSettings = ({onBackHandler, steps_data}) => {
    const Colors = useColors();
    return (
        <div className='d-flex flex-column flex1 scrollHidden'>
            <Header onBackHandler={onBackHandler} name='Member Settings'/>
            <PerfectScrollbar className='d-flex flex-column align-items-center' >
                <div className="text-center col-sm-6">
                    <div
                        className='container'
                        style={{
                        backgroundColor: Colors.bgDark,
                        borderRadius: 10
                    }}>
                        {steps_data
                            .teams_data
                            .map(member => (
                                <div
                                    className='row align-items-center my-1'
                                    style={{
                                    padding: '0.5rem 0.75rem'
                                }}>
                                    <div className='col-8  d-flex justify-content-start'>
                                        <img
                                            className='img-responsive rounded-circle my-1'
                                            src={member.image}
                                            height='40px'
                                            style={{
                                            border: `2px solid ${Colors.colorPrimary}`
                                        }}/>
                                        <div className='d-flex flex1 flex-row justify-content-center'>
                                            <span
                                                className='ml-1 mt-1 d-inline-block align-middle text-truncate'
                                                style={{
                                                maxWidth: 200,
                                                fontSize: 15,
                                                fontWeight: 'bold'
                                            }}>{member.teamName}
                                                <span
                                                    className='d-block'
                                                    style={{
                                                    fontSize: 13,
                                                    fontWeight: 'lighter'
                                                }}>{member.userName}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col'>{member.isCommissioner
                                            ? 'Commish'
                                            : 'Member'}</div>
                                </div>
                            ))}
                    </div>
                </div>
            </PerfectScrollbar>
        </div>
    )
}

export default MemberSettings