import React from 'react'
// import { Colors } from '../../../helpers/colors'
import BetsMatchup from './BetsMatchup'
import EachCatBet from './EachCatBet'
import RotoBet from './RotoBet'
import { useColors } from '../../../helpers/CustomHooks/useColors';

const BetUpcoming = (props) => {
    const Colors = useColors();
    // const handleNavigate = () => {
    //     props.history.push('/my-bets/h2h-matchup', {state: {
    //       id: bet.ch_id,
    //       team1Totals: findMyObject(bet.from.userId).totalPoints,
    //       team2Totals: findMyObject(bet.to.userId).totalPoints,
    //     }})
    //   }

    // const findMyObject = (userId) => {
    //     var myObj = {}
    //     standings.map((obj) => {
    //         if(obj.userId == userId){
    //             myObj = obj
    //         }
    //     })
    //     return myObj
    // }
  return (

    <>
        <tbody>
            {
                props.data.map((challenge, index) => {
                    return (
                        {
                            'roto':
                                <RotoBet bet={challenge} league={props.activeLeague} />,
                            'h2h_weekly_points':
                                <BetsMatchup bet={challenge} league={props.activeLeague} />,
                            'h2h_each_category':
                                <EachCatBet bet={challenge} league={props.activeLeague} />

                        }[props.activeLeague.scoringFormat]
                    )
                })
            }
        </tbody>
    </>
    // <tbody>
    //     {
    //         props.data && props.data.map((challenge, index) => {
    //             return (
    //                     <tr style={{ borderBottom: `0.01em solid ${Colors.darkBgBorder}` }} key={challenge.ch_id}>
    //                         <td className="align-middle bold">{index+1}</td>
    //                         <td className="align-middle">
    //                             <div className='d-flex flex-column'>
    //                                 <div className='d-flex align-items-center my-1'>
    //                                     <div className='rounded-circle mr-2' style={{width:40, height:40, border: `2px solid ${Colors.colorPrimary}`}}>
    //                                         <img className="img-fluid m-auto p-1" src={challenge.from.image}  />
    //                                     </div>
    //                                     <div>
    //                                         <h5 className='p-0 m-0'>{challenge.from.name} </h5>
    //                                         <span> 5-0 </span> 
    //                                     </div>
    //                                 </div>
                                    
    //                                 <div className='d-flex align-items-center my-1'>
    //                                     <div className='rounded-circle mr-2' style={{width:40, height:40, border: `2px solid ${Colors.colorPrimary}`}}>
    //                                         <img className="img-fluid m-auto p-1" src={challenge.to.image}  />
    //                                     </div>
    //                                     <div>
    //                                         <h5 className='p-0 m-0'>{challenge.to.name} </h5>
    //                                         <span> 1-4 </span> 
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </td>
    //                         <td className="align-middle bold">
    //                             <div className="my-3">
    //                                 {/* <h5 className="ml-1 bold">91.24</h5>
    //                                 <br />
    //                                 <h5 className="ml-1 bold">104.84</h5> */}
    //                                 -
    //                             </div>
    //                         </td>
    //                         <td className="align-middle bold">
    //                             {challenge.sidebet_type}
    //                         </td>
    //                         <td className="align-middle">
    //                             {challenge.sidebet_duration}
    //                         </td>
    //                         <td className="align-middle">
    //                             <span style={{ color: Colors.colorPrimary }} className="bold">${challenge.winner_gets}</span>
    //                         </td>
    //                         <td>

    //                         </td>
    //                     </tr>
    //             )
    //         })
    //     }
                  
    // </tbody>
  )
}

export default BetUpcoming