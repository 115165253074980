import { AppSwitch } from '@coreui/react';
import React, { Component } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Table, UncontrolledDropdown } from 'reactstrap';
import { Colors } from '../../helpers/colors';
import { StickyTable, Row, Cell } from 'react-sticky-table'
import { fetchMlbPlayers, searchPlayer } from '../../apis/SeasonLong/PlayerAPI/PlayerAPI';
import { connect } from 'react-redux';
import { toastError } from '../../helpers/toasts';
import { BATTERS_POS, PITCHERS_POS } from '../../helpers/common-methods';
import ReactSelect from 'react-select';
import { indexOf } from 'lodash';
import LoadingOverlay from 'react-loading-overlay';
import Loader from 'react-loaders';
import InfiniteScroll from 'react-infinite-scroll-component';
import { STYLES } from '../../helpers/stylesHelper';
import PerfectScrollbar from 'react-perfect-scrollbar'
import ModalPlayerAdd from '../../components/Chat/Templates/PlayerNews/ModalPlayerAdd';
import PlayerCardModal from '../../modals/SeasonLong/PlayerCard/PlayerCardModal';
import { LoaderCenter } from '../Loader';
import { ColorContext } from '../../context/ColorProvider';

class MLBPlayers extends Component {
    constructor(props) {
        super(props);
        this.state = {

            mainTab: 'stats',
            modalVisible: false,
            loadMore: false,
            dataEnd: false,
            loading: true,
            offset: 0,
            dataSort: 'default',
            defaultData: null,
            durationsData: [
                { value: 1, label: 'Week' },
                { value: 2, label: 'Season' },
                // {value: 3, label: 'Last Week'},
                // {value: 4, label: 'Last Three Week'},
                // {value: 5, label: 'Last Season'},
                // {value: 6, label: 'Career'},
            ],
            selectedDuration: {},
            playersData: {
                players: []
            },
            teamsData: [],
            teamIndex: 0,
            available: true,
            tab: 'BATTERS',
            mainTab: 'ranks',
            modalData: '',
            addPlayerModal: false,
            durationIndex: 2,
            sortType: 'DESC',
            sortField: 'overall_rank',
            player_loading: false,
            headers: [],

            // Search States
            searching: false,
            resultsVisible: false,
            searchResults: [],

            // Player Card
            playerCardModal: false,
            selectedPlayer: null,

            // Claim Player
            addPlayerModal: false,
            selectedPlayerName: null,
        }

        this.handleTeamSelect = this.handleTeamSelect.bind(this);
        this.handleDuration = this.handleDuration.bind(this);
        this.handleLoadMore = this.handleLoadMore.bind(this);
        this.sortPlayers = this.sortPlayers.bind(this);
        this.togglePlayerCardModal = this.togglePlayerCardModal.bind(this);
        this.preparePlayer = this.preparePlayer.bind(this);
        this.toggleAddPlayerModal = this.toggleAddPlayerModal.bind(this);
    }

    static contextType = ColorContext;

    componentDidMount() {
        this.callAPI()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.tab != this.state.tab || prevState.teamIndex != this.state.teamIndex || prevState.available != this.state.available || prevState.durationIndex != this.state.durationIndex) {
            this.callAPI()
        }
        if (prevState.mainTab != this.state.mainTab) {
            this.saveHeaders()
        }
        if (prevProps.leagueId != this.props.leagueId) {
            this.callAPI()
        }
    }

    prepareDuration(index) {
        if (index == 1) {
            return "week";
        }
        if (index == 2) {
            return "season";
        }
        if (index == 3) {
            return "lastWeek";
        }
        if (index == 4) {
            return "lastThreeWeek";
        }
        if (index == 5) {
            return "lastSeason";
        }
        if (index == 6) {
            return "career";
        }
    };


    callAPI(reqOffset = 0, durationInd = this.state.durationIndex, pos = this.state.tab, teamInd = this.state.teamIndex, sf = this.state.sortField, st = this.state.sortType, isAvail = this.state.available) {
        let team = teamInd ? this.state.teamsData[teamInd].team_id : "ALL";
        let queryString = "?leagueId=" + this.props.leagueId;
        queryString = queryString + "&pos=" + pos.toUpperCase();
        queryString = queryString + "&offset=" + reqOffset;
        queryString = queryString + "&duration=" + this.prepareDuration(durationInd);
        queryString = queryString + "&team=" + team;
        queryString = queryString + "&sortField=" + sf;
        queryString = queryString + "&sortType=" + st;
        queryString = queryString + "&isAvailable=" + isAvail;

        this.setState({
            loading: true
        })
        fetchMlbPlayers(queryString)
            .then((response) => {
                var teams = response.data.teams;
                if (teams) {
                    teams = [...[{ team_id: "ALL", name: "ALL" }], ...teams];
                }
                this.setState({
                    playersData: response.data,
                    defaultData: response.data.players,
                    offset: 40,
                    loading: false,
                    dataEnd: response.data.players.length == 0 ? true : false,
                    player_loading: false,
                    sortField: sf,
                    sortType: st == 'ASC' ? 'DESC' : 'ASC',
                    teamsData: teams
                })
                this.saveHeaders(response.data)
            })
            .catch((err) => {
                this.setState({
                    player_loading: false,
                    loading: false
                })
                toastError("Something went wrong");
            });
    }

    searchPlayers(sport, query) {
        this.setState({
            searching: true
        })
        searchPlayer({ sport: sport, q: query })
            .then(response => {
                console.log(response);
                this.setState({
                    searchResults: response.data,
                    searching: false
                })
            })
            .catch(({ message }) => {
                this.setState({
                    searching: false
                })
                toastError(message)
            })
    }

    saveHeaders() {
        var header = []
        var mt = this.state.mainTab
        var posTab = this.state.tab
        var pd = this.state.playersData

        // FETCH FIRST RECORD FROM PLAYERS ARRAY
        if ((mt == "ranks" || mt == "news") && pd.players.length > 0) {
            header = Object.keys(pd.players[0][mt])
            this.setState({
                headers: header
            })
        } else if (mt == "stats") {
            // NEED TO CHECK SELECTED POSITION
            // tab == pos
            if (posTab == "BATTERS" || BATTERS_POS.includes(posTab)) {
                header = pd.headers.batters
            } else if (posTab == "PITCHERS" || PITCHERS_POS.includes(posTab)) {
                header = pd.headers.pitchers
            }
            this.setState({
                headers: header
            })
        }
    }

    handleLoadMore() {
        if (!this.state.dataEnd && !this.state.loadMore) {
            this.setState({
                loadMore: true,
            })
            var team = this.state.teamIndex ? this.state.teamsData[this.state.teamIndex - 1].team_id : "ALL";
            // var team = this.state.teamsData[teamInd].team_id
            var queryString = "?leagueId=" + this.props.leagueId;
            queryString = queryString + "&pos=" + this.state.tab.toUpperCase();
            queryString = queryString + "&offset=" + this.state.offset;
            queryString = queryString + "&duration=" + this.prepareDuration(this.state.durationIndex);
            queryString = queryString + "&team=" + team;
            queryString = queryString + "&sortField=" + this.state.sortField;
            queryString = queryString + "&sortType=" + this.state.sortType;
            queryString = queryString + "&isAvailable=" + this.state.available;

            fetchMlbPlayers(queryString).then((response) => {
                var players = JSON.parse(JSON.stringify(this.state.defaultData));
                players = [...players, ...response.data.players];

                this.setState({
                    defaultData: players,
                    playersData: { ...this.state.playersData, ...{ players: players } },
                    offset: this.state.offset + 40,
                    loading: false,
                    loadMore: false
                })

                // Wait for the state to mutate before proceeding
                setTimeout(() => {
                    if (this.state.dataSort != 'default') {
                        this.sortPlayers(true)
                    }
                }, 0);

                this.saveHeaders()

                // setLoadMore(false);
                if (response.data.players.length < 40) {
                    this.setState({
                        dataEnd: true
                    })
                }
            });
        }
    };

    handleTeamSelect(selectedTeam) {
        this.setState({
            teamIndex: indexOf(this.state.teamsData, selectedTeam)
        })
    }

    handleIsAvailable(e) {
        this.setState({
            available: e.target.checked
        })
    }

    handleDuration(selectedDuration) {
        this.setState({
            durationIndex: selectedDuration.value,
        })
    }

    /**
     * Handle search query
     * @param {event} e - event to read data from
     */
    handleSearch(e) {
        this.setState({
            resultsVisible: true
        })

        if (e.target.value.length >= 2) {
            this.searchPlayers(this.props.activeLeague.sport, e.target.value)
        }

        if (!e.target.value) {
            this.setState({
                resultsVisible: false,
                searchResults: []
            })
        }
    }

    /**
     * Handle search results when search input is focused in/out
     * @param {bool} focused - true if focused and false is blured
     */
    handleSearchFocus(focused) {
        if (focused && this.state.searchResults.length) {
            this.setState({
                resultsVisible: true
            })
        }
        else {
            setTimeout(() => {
                this.setState({
                    resultsVisible: false
                })
            }, 300);
        }
    }

    preparePlayer(player_id, player_name = null) {
        this.setState({
            selectedPlayer: player_id,
            selectedPlayerName: player_name,
        })

        setTimeout(() => {
            if (player_id && player_name) {
                this.toggleAddPlayerModal()
            }
            else {
                this.togglePlayerCardModal()
            }
        }, 0);
    }

    togglePlayerCardModal() {
        this.setState({
            playerCardModal: !this.state.playerCardModal
        })
    }

    toggleAddPlayerModal() {
        this.setState({
            addPlayerModal: !this.state.addPlayerModal
        })
    }

    /**
     * Sort data based on players name
     * @param {bool} newData - indicate (true) if newData is added by LoadMore or (false) if data is updated completely
     */
    sortPlayers(newData = false) {
        let sortNext = this.state.dataSort;

        console.log(this.state.defaultData);
        // Make deep copies of data
        let sortedData = JSON.parse(JSON.stringify(this.state.defaultData))
        let playersData = JSON.parse(JSON.stringify(this.state.playersData))

        // Change next sort only if no new data is inserted
        if (!newData) {
            console.log('no new data');
            switch (this.state.dataSort) {
                case 'default':
                    sortNext = 'asc';
                    break;
                case 'asc':
                    sortNext = 'desc';
                    break;
                default:
                    sortNext = 'default'
                    break;
            }
        }

        if (sortNext == 'asc') {
            sortedData = sortedData.sort((a, b) => (a.full_name > b.full_name) ? 1 : ((b.full_name > a.full_name) ? -1 : 0))
        }
        else if (sortNext == 'desc') {
            sortedData = sortedData.sort((a, b) => (a.full_name < b.full_name) ? 1 : ((b.full_name < a.full_name) ? -1 : 0))
        }
        else {
            sortedData = this.state.defaultData
        }

        playersData.players = sortedData

        console.log(sortedData);
        this.setState({
            dataSort: sortNext,
            playersData: playersData
        })

    }

    handleSorting(sortField = null, sortType = this.state.sortType) {
        let restrictedFields = ['recent news']
        let allowAPI = false

        if (sortField.toUpperCase() === sortField) {
            if (!restrictedFields.includes(sortField.toLowerCase())) {
                allowAPI = true
                sortField = this.getFieldSlug(sortField)
            }
            // this.callAPI(0, this.state.durationIndex,this.state.tab, this.state.teamIndex, sortField, sortType, this.state.available)
        }
        else {
            allowAPI = true
        }

        if (allowAPI) {
            this.callAPI(0, this.state.durationIndex, this.state.tab, this.state.teamIndex, sortField, sortType, this.state.available)
        }
    }

    getFieldSlug(field) {
        let sortField = '';
        if (field == "RNK") {
            sortField = "overall_rank";
        } else if (field == "PRNK") {
            sortField = "pos_rank";
        } else if (field == "SBWIN") {
            sortField = "sb_win";
        } else if (field == "ROST") {
            sortField = "rostered";
        } else {
            sortField = field.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '_')
        }
        return sortField


    }

    focusSearch() {
        console.log('FOCUSED');
        document.getElementById('search-bar').focus()
    }

    player(fullname, team, position, image, id, drafted, locked) {
        const Colors = this.context
        let status = null
        if (locked) {
            status = 'locked'
        }
        else if (drafted) {
            status = 'drafted'
        }
        else {
            status = 'available'
        }

        return (
            <div className='d-flex flex1 m-0' style={{ backgroundColor: Colors.fixedCell, padding: '1vh 0.75rem', borderTop: `0.01em solid ${Colors.darkBgBorder}` }}>
                <img className='img-responsive rounded-circle my-1' src={image} height='40px' style={{ border: `2px solid ${Colors.colorPrimary}` }} />
                <span className='ml-1 flex1 bold d-inline-block align-middle' style={{ cursor: 'pointer' }} onClick={() => this.preparePlayer(id)}>
                    {fullname}
                    <br />
                    {position} • {team}
                </span>
                {
                    {
                        'locked':
                            <i class="fa fa-lock align-self-center ml-2" aria-hidden="Rowue"></i>,
                        'drafted':
                            <i className='align-self-center ml-2'> drafted </i>,
                        'available':
                        <i class="fa fa-plus-circle align-self-center ml-2" style={{color:Colors.colorPrimary, cursor:'pointer'}} aria-hidden="true" onClick={() => this.preparePlayer(id,fullname)}></i>
                    }[status]
                }
            </div>
        )
    }

    render() {
        const Colors = this.context
        return (

            <>
                {/* {
                    this.state.loading ?
                        <div class="container d-flex flex-column justify-content-center h-100">
                            <LoadingOverlay
                                className='p-3'
                                active={this.state.loading}
                                spinner={<Loader className='m-auto' type="ball-pulse" color={Colors.colorPrimary} />}
                                text={'Loading...'}
                                fadeSpeed={500}
                                styles={{
                                    overlay: (base) => ({
                                        ...base,
                                        background: 'rgba(0,0,0,0)'
                                    })
                                }}
                            >


                            </LoadingOverlay>
                        </div>
                        : */}
                <>
                    <div className='d-flex justify-content-between px-3' style={{ borderBottom: `0.01em solid ${Colors.darkBgBorder}` }}>
                        <h2 className='d-flex align-items-center'>Players</h2>
                        <div className="input-group" style={{ border: `0.01em solid ${Colors.darkBgBorder}`, borderRadius: 4, maxWidth: '25%', minWidth: 274, margin: '7px 0px 7px 0px' }} id='search-bar'>
                            <span className="input-group-prepend">
                                <span className="btn  border-0" >
                                    <i className="fa fa-search" />
                                </span>
                            </span>
                            <input className="form-control border-0 " type="search" id="player-search" placeholder='Search a Player' onChange={this.handleSearch.bind(this)} onBlur={() => this.handleSearchFocus(false)} onFocus={() => this.handleSearchFocus(true)} />
                        </div>
                    </div>
                    <div class="container d-flex flex-column h-100">

                        {
                            this.state.resultsVisible &&
                            <div className='position-absolute' style={{ border: `0.01em solid ${Colors.darkBgBorder}`, borderRadius: 4, right: 20, top: 60, zIndex: 999 }} >
                                <div className='pull-right border-danger' style={{ maxWidth: '30%', minWidth: 274 }} onClick={this.focusSearch}>
                                    <ul>
                                        {
                                            this.state.searchResults.map(player => {
                                                return (
                                                    <li key={player.player_id} style={{ cursor: 'pointer', backgroundColor: Colors.bgDark, padding: '0.5rem 0.75rem' }} onClick={() => this.preparePlayer(player.player_id)}>
                                                        {/* <div className=' d-flex m-0' style={{backgroundColor:Colors.bgDark, padding:'0.5rem 0.75rem'}}> */}
                                                        <img className='img-responsive rounded-circle my-1' src={player.image} height='40px' style={{ border: `2px solid ${Colors.colorPrimary}` }} />
                                                        <span className='ml-1 flex1 bold d-inline-block align-middle' style={{ cursor: 'pointer' }} onClick={() => this.preparePlayer(player.player_id)}>
                                                            {player.full_name}
                                                            <br />
                                                            {player.pos} • {player.team}
                                                        </span>
                                                        {/* </div> */}
                                                    </li>
                                                )
                                                // this.player(player.full_name,player.team,player.pos,player.image, player.player_id, player.drafted)
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        }


                        <div className='my-2 d-flex flex-row justify-content-between'>
                            <div>
                                <h3 onClick={() => this.setState({ mainTab: 'ranks' })} className='d-inline mr-3' style={{ borderBottom: this.state.mainTab == "ranks" && `2px solid ${Colors.colorPrimary}`, cursor: 'pointer', fontWeight: this.state.mainTab == "ranks" && 'bold' }}>Ranks</h3>
                                <h3 onClick={() => this.setState({ mainTab: 'stats' })} className='d-inline mr-3' style={{ borderBottom: this.state.mainTab == "stats" && `2px solid ${Colors.colorPrimary}`, cursor: 'pointer', fontWeight: this.state.mainTab == "stats" && 'bold' }}>Stats</h3>
                                <h3 onClick={() => this.setState({ mainTab: 'news' })} className='d-inline mr-3' style={{ borderBottom: this.state.mainTab == "news" && `2px solid ${Colors.colorPrimary}`, cursor: 'pointer', fontWeight: this.state.mainTab == "news" && 'bold' }}>News</h3>
                            </div>

                            <div className='d-flex flex1 flex-row justify-content-end align-items-center'>

                                {/*  Switch */}
                                <div className='row d-flex flex-row-reverse align-items-center mx-2'>
                                    {/* Heading */}
                                    <h6 className='bold p-0 my-0 mr-1'>Available Only</h6>
                                    {/* Switch */}
                                    <AppSwitch
                                        className={"mx-1 vertical-middle mt-1"}
                                        variant={("outline", "pill")}
                                        color="success"
                                        outline={"alt"}
                                        label
                                        checked={this.state.available}
                                        onChange={this.handleIsAvailable.bind(this)}
                                    />
                                </div>
                                {/* ! Switch */}

                                <ReactSelect getOptionValue={option => option.team_id} getOptionLabel={option => option.name} options={this.state.teamsData} value={this.state.teamsData[this.state.teamIndex]} onChange={this.handleTeamSelect} className=" mx-1 text-white" styles={STYLES.REACT_SELECT} closeMenuOnSelect={true} isClearable={false} />
                                <ReactSelect options={this.state.durationsData} value={this.state.durationsData.filter(data => { if (this.state.durationIndex == data.value) { return data.label } })} onChange={this.handleDuration} className=" mx-1 text-white" styles={STYLES.REACT_SELECT} closeMenuOnSelect={true} isClearable={false} />
                            </div>
                        </div>
                        <div className='my-2 d-flex flex-row'>
                            <h5 onClick={() => this.setState({ tab: 'BATTERS' })} className='d-inline mr-3' style={{ borderBottom: this.state.tab == "BATTERS" && `2px solid ${Colors.colorPrimary}`, cursor: 'pointer', fontWeight: this.state.tab == "BATTERS" && 'bold' }}>BATTERS</h5>
                            <h5 onClick={() => this.setState({ tab: 'PITCHERS' })} className='d-inline mr-3' style={{ borderBottom: this.state.tab == "PITCHERS" && `2px solid ${Colors.colorPrimary}`, cursor: 'pointer', fontWeight: this.state.tab == "PITCHERS" && 'bold' }}>PITCHERS</h5>
                            {
                                !this.state.loading ?
                                    Object.values(this.state.playersData.positions).map(position => {
                                        return (
                                            <h5 onClick={() => this.setState({ tab: position })} className='d-inline mx-3' style={{ borderBottom: this.state.tab == position && `2px solid ${Colors.colorPrimary}`, cursor: 'pointer', fontWeight: this.state.tab == position && 'bold' }}>{position}</h5>
                                        )
                                    })
                                    : null
                            }
                        </div>
                        {/* {this.state.loading ? <h4 className='d-flex justify-content-center align-items-center h-100'>Loading...</h4>  */}

                        {this.state.loading ? <LoaderCenter msg='Loading...' />
                            :


                            <div className="d-flex flex-column flex1 scrollHidden" style={{ backgroundColor: Colors.bgDark, borderRadius: 15, border: `0.01em solid ${Colors.darkBgBorder}`, marginBottom: '5rem' }}>
                                <PerfectScrollbar id='players-table-container'>
                                    {/* <div className="d-flex flex1 flex-column" id='players-table'> */}
                                    <InfiniteScroll
                                        className=''
                                        dataLength={this.state.playersData.players.length}
                                        next={() => this.handleLoadMore()}
                                        hasMore={!this.state.dataEnd}
                                        loader={this.state.loadMore && <div className='d-flex'><LoaderCenter /></div>}
                                        endMessage={<div className='text-center py-3'><i class="fa fa-check-circle" aria-hidden="true"></i> No More Players. You are all caught up!</div>}
                                        scrollableTarget={'players-table-container'}
                                    >
                                        {/* Sticky Table */}
                                        <StickyTable className='w-100 h-100' stickyHeaderCount={1} leftStickyColumnCount={1} headerZ={3} borderWidth={'0.01em'} borderColor={Colors.darkBgBorder} style={{ backgorundColor: Colors.darkColor }}>

                                            <Row style={{ backgroundColor: Colors.bgLight, borderBottom: `0.01em solid ${Colors.darkBgBorder}`, backgorundColor: Colors.darkColor }}>
                                                <Cell className='p-0 bg-transparent' onClick={() => this.sortPlayers()} style={{ cursor: 'pointer' }}><div className='w-100 d-flex align-items-center justify-content-between' style={{ backgroundColor: Colors.bgDark }}>{headerColumn('PLAYER')} <i class={`flex1 p-2 text-right fa fa-sort${this.state.dataSort != 'default' ? '-' + this.state.dataSort.toLowerCase() : ''}`} aria-hidden="true"></i></div></Cell>
                                                {
                                                    this.state.headers.map(column => {
                                                        if (this.state.mainTab == 'stats') {
                                                            return (
                                                                <Cell className='p-0 bg-transparent' style={{ cursor: 'pointer' }} onClick={() => this.handleSorting(column)}>{headerColumn(this.state.playersData.statAbbr[column])}</Cell>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <Cell className='p-0 bg-transparent' style={{ cursor: 'pointer' }} onClick={() => this.handleSorting(column)}>{headerColumn(column)}</Cell>
                                                            )
                                                        }

                                                    })

                                                }

                                            </Row>
                                            {
                                                this.state.playersData.players.map(player => {
                                                    return (
                                                        <Row style={{ backgorundColor: Colors.colorPrimary, borderBottom: `0.01em solid ${Colors.darkBgBorder}` }}>
                                                            <Cell className='p-0 shadow align-middle d-flex flex-column border-0 align-items-stretch align-content-stretch bg-danger'>{this.player(player.full_name, player.team, player.pos, player.image, player.player_id, player.drafted, player.locked)}</Cell>
                                                            {
                                                                this.state.mainTab == 'stats' ?
                                                                    this.state.headers.map(value => {
                                                                        return (
                                                                            <Cell className='align-middle bg400'>{parseFloat(player.stats[value]) || 0}</Cell>
                                                                        )
                                                                    })
                                                                    :

                                                                    Object.values(player[this.state.mainTab]).map(data => {
                                                                        return (
                                                                            <Cell className='align-middle bg400' style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{data}</Cell>
                                                                        )
                                                                    })
                                                            }
                                                        </Row>
                                                    )
                                                })
                                            }
                                        </StickyTable>
                                    </InfiniteScroll>
                                    {/* </div> */}
                                </PerfectScrollbar>
                            </div>
                        }
                        <PlayerCardModal modal={this.state.playerCardModal} toggle={this.togglePlayerCardModal} playerId={this.state.selectedPlayer} leagueId={this.props.leagueId} sport={this.props.activeLeague.sport} teams={this.state.teamsData} />
                        <ModalPlayerAdd data={{ player_id: this.state.selectedPlayer, player_name: this.state.selectedPlayerName, league_id: this.props.leagueId }} isOpen={this.state.addPlayerModal} toggle={this.toggleAddPlayerModal} success={this.actionSuccess} />
                    </div>
                </>
                {/* } */}
            </>

        );
    }
}

export function headerColumn(name) {
    return (
        <div className='m-0' style={{ backgroundColor: Colors.bgDark, padding: '0.5rem 0.75rem' }}>
            {name}
        </div>
    )
}

const mapStateToProps = (state) => ({ leagueId: state.leagueJoin.leagueId, activeLeague: state.leagueJoin.activeLeague })
export default connect(mapStateToProps, null)(MLBPlayers);