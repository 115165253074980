import React, { Component } from 'react';

export const PlayerVPlayer = (props) => {
  return (
    <>
        <div className='d-flex mb-2'>
            <div className='flex1'>
                <h5>My Players:</h5>
                {props.data.myBetPlayer && props.data.myBetPlayer.map(player => {
                    return (
                        <div className='flex1 d-flex my-2 align-items-center'>
                            <img className='img-fluid' style={{width:40}} src={player.image}/>
                            <div className='d-flex flex-column ml-2'>
                                <span className='d-block'>{player.fullName}</span>
                                <span className='small'>{player.team} • {player.pos} - Proj. {player.projPoints}</span>
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className='flex1'>
                <h5>Their Players:</h5>
                {props.data.theirBetPlayer && props.data.theirBetPlayer.map(player => {
                    return (
                        <>
                            <div className='flex1 d-flex my-2 align-items-center'>
                                <img className='img-fluid' style={{width:40}} src={player.image}/>
                                <div className='d-flex flex-column ml-2'>
                                    <span className='d-block'>{player.fullName}</span>
                                    <span className='small'>{player.team} • {player.pos} - Proj. {player.projPoints}</span>
                                </div>
                            </div>
                        </>
                    )
                })}

                {props.myPlayers && props.myPlayers.map(player => {
                    return (
                        <>
                            <div className='flex1 d-flex my-2 align-items-center'>
                                <img className='img-fluid' style={{width:40}} src={player.image}/>
                                <div className='d-flex flex-column ml-2'>
                                    <span className='d-block'>{player.fullName}</span>
                                    <span className='small'>{player.team} • {player.pos} - Proj. {player.projPoints}</span>
                                </div>
                            </div>
                        </>
                    )
                })}
            </div>
        </div>
    </>
  )
}
