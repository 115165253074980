import { SET_AUTHENTICATED, SET_USER_DATA } from '../actions/auth';

export default (state = {
  user: {
    id: null,
    username: null,
    email: null,
    avatar: null,
    first_name: null,
    last_name: null,
    image: null,
    idVerified: "NOT_VERIFIED",
    slogan: null,
  },
  authenticated: false, user_basic: null
}, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return Object.assign({}, state, { user: action.user });
    case SET_AUTHENTICATED:
      return Object.assign({}, state, { authenticated: action.authenticated });
    default:
      return state; 
  };
};
