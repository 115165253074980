import React from "react"
import { mlbColors as Colors } from "../../../../helpers/colors"
import { parseTotalStat } from "../../../../helpers/common-methods"

const MLBEachCatRow = (props) => {
    const {team1Total, team2Total, statCat, statKey} = props

    const catWinnerDecider = (type) => {
        const team1 = parseFloat(parseTotalStat(statKey, team1Total));
        const team2 = parseFloat(parseTotalStat(statKey, team2Total));
        if (type == "team1") {
            if (team1 > team2) {
                return Colors.schedulePlayerColor;
            };
        } else {
            if (team1 < team2) {
                return Colors.schedulePlayerColor;
            };   
        };
    };

    return (
        <div className="d-flex" style={{ fontSize: 16, fontWeight: 600 }}>
            <div className="flex1 d-flex align-items-center w-100 d-flex justify-content-center">
                <h5 className="m-0 text-center w-25 py-2" style={{ background: catWinnerDecider("team1") }}>
                {/* {parseFloat(team1Total || 0)} */}
                {parseTotalStat(statKey, team1Total)}
                </h5>
            </div>
            <div className="px-4 py-3 text-center" style={{minWidth:100, maxWidth:100, color: Colors.textColorSecondary}}>
                {statCat}
            </div>
            <div className="flex1 d-flex align-items-center w-100 d-flex justify-content-center">
                <h5 className="m-0 text-center w-25 py-2" style={{ background: catWinnerDecider("team2") }}>
                {/* {parseFloat(team1Total || 0)} */}
                {parseTotalStat(statKey, team2Total)}
                </h5>
            </div>
        </div>
    )
}

export default MLBEachCatRow