import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { dropPlayer } from '../../../../apis/SeasonLong/PlayerAPI/PlayerAPI';
import { ColorContext } from '../../../../context/ColorProvider';
import { toastError, toastSuccess } from '../../../../helpers/toasts';
import ModalPlayerAdd from './ModalPlayerAdd';
import ModalPlayerDrop from './ModalPlayerDrop';

class PlayerNews extends Component {
    constructor(props) {
        super(props);

        this.state = {
            readMore: false,
            playerName: null,
            teamAbbr: null,
            playerImage: null,
            notes: null,
            headline: null,
            analysis: null,

            dropPlayerModal: false,
            addPlayerModal: false,
            actionPerformed: false // Disable the add/drop player button
        }

        this.toggleDropPlayerModal = this.toggleDropPlayerModal.bind(this)
        this.toggleAddPlayerModal = this.toggleAddPlayerModal.bind(this)
        this.toggleReadMore = this.toggleReadMore.bind(this)
        this.actionSuccess = this.actionSuccess.bind(this)
        this.dropPlayer = this.dropPlayer.bind(this)
    }

    static contextType = ColorContext;

    componentDidMount() {
        let data = this.props.data.attachments[0].data

        if (this.props.status == 'ROTO_WIRE') {
            this.setState({
                playerName: data.Player.FirstName + ' ' + data.Player.LastName,
                teamAbbr: data.team_abbr,
                playerImage: data.image,
                notes: data.notes,
                headline: data.headline,
                analysis: data.analysis,
                draftedByMe: data.draftedByMe,
                playerAvailable: data.playerAvailable,
                playerId: data.player_id
            })
        }
        else if (this.props.status == 'PLAYER_DROPPED') {
            this.setState({
                message_content: this.props.data.message_content,
                playerName: data.playerName,
                user: data.user
            })
        }
        // else if('ADD_PLAYER')
    }

    // Read more/less news
    toggleReadMore() {
        this.setState({
            readMore: !this.state.readMore
        })
    }

    // Toggle the modal to drop selected player
    toggleDropPlayerModal() {
        this.setState({
            dropPlayerModal: !this.state.dropPlayerModal
        })
    }

    // Toggle the modal to add selected player
    toggleAddPlayerModal() {
        this.setState({
            addPlayerModal: !this.state.addPlayerModal
        })
    }

    // Indicate if user has performed a positive action on Modal
    actionSuccess() {
        this.setState({
            actionPerformed: true
        })
    }

    // Make an API call to drop player
    dropPlayer() {
        dropPlayer({ league_id: this.props.leagueId, player_id: this.state.playerId, is_commissioner: false })
            .then(response => {

                let data = {
                    message_content: 'Player Dropped',
                    attachments: [{
                        template: 'PLAYER_DROPPED',
                        data: {
                            user: response.data.user,
                            playerName: response.data.playerName
                        }
                    }]
                }
                this.toggleDropPlayerModal()
                if (response.data != undefined) {
                    this.actionSuccess()
                    this.props.sendMessage(data)

                    toastSuccess('Player: ' + response.data.playerName + ' dropped successfully')
                }
            })
            .catch(({ message }) => {
                toastError(message)
                this.toggleDropPlayerModal()
            })
    }

    // Actions for news
    getPlayerAction(Colors = this.context) {
        if (this.state.playerAvailable) {
            return (
                // Uncomment this button after removing the button below
                // <Button className='w-100 my-1' style={{color: Colors.colorPrimary,backgroundColor: Colors.colorPrimaryTransparent,border: `1px solid ${Colors.colorPrimary}`}} onClick={this.toggleAddPlayerModal} disabled={this.state.actionPerformed}>Add Player</Button>

                // Remove this button before uncommenting the button above
                <Button className='w-100 my-1' style={{ color: Colors.colorSecondary, backgroundColor: Colors.colorSecondaryTransparent, border: `1px solid ${Colors.colorSecondary}`, cursor: 'not-allowed' }} disabled={true}>Add Player</Button>
            )
        }
        else if (this.state.draftedByMe) {
            return (
                // Uncomment this button after removing the button below
                // <Button className='w-100 my-1' style={{color: Colors.colorDanger,backgroundColor: Colors.colorDangerTransparent,border: `1px solid ${Colors.colorDanger}`}} onClick={this.toggleDropPlayerModal} disabled={this.state.actionPerformed}>Drop Player</Button>

                // Remove this button before uncommenting the button above
                <Button className='w-100 my-1' style={{ color: Colors.colorSecondary, backgroundColor: Colors.colorSecondaryTransparent, border: `1px solid ${Colors.colorSecondary}`, cursor: 'not-allowed' }} disabled={true}>Drop Player</Button>
            )
        }
    }

    render() {
        const Colors = this.context
        return (
            (() => {
                switch (this.props.status) {
                    case 'ROTO_WIRE':
                        return (
                            <div style={{ maxWidth: '400px' }}>
                                <div className='d-flex'>
                                    <span className='mt-2 mb-1 mx-2' style={{ borderLeft: `5px solid ${Colors.darkBgBorder}`, borderRadius: 50 }}></span>
                                    <div>
                                        <div className='d-flex'>
                                            <img height='60px' src={this.props.data.attachments[0].data.image} />
                                            <p className='flex1 mb-0'><span>{this.state.playerName} ({this.state.teamAbbr}): </span> {this.state.headline}</p>

                                        </div>
                                        <p className='mt-1'>{this.state.notes}</p>
                                        <p className={`mt-2 ${this.state.readMore ? '' : 'd-none'}`}>{this.state.analysis}</p>
                                        <Button className='w-100 my-1' style={{ color: Colors.colorPrimary, backgroundColor: Colors.colorPrimaryTransparent, border: `1px solid ${Colors.colorPrimary}` }} onClick={this.toggleReadMore}>{this.state.readMore ? 'Read Less' : 'Read More'}</Button>
                                        {this.getPlayerAction()}
                                        <ModalPlayerDrop isOpen={this.state.dropPlayerModal} toggle={this.toggleDropPlayerModal} leagueId={this.props.leagueId} playerId={this.state.playerId} />
                                        <ModalPlayerAdd data={{ player_id: this.state.playerId, player_name: this.state.playerName, league_id: this.props.leagueId }} isOpen={this.state.addPlayerModal} toggle={this.toggleAddPlayerModal} success={this.actionSuccess} />
                                    </div>
                                </div>
                            </div>
                        )
                    case 'PLAYER_DROPPED':
                        return (
                            <>
                                <p>{this.props.data.message_content}</p>
                                <div className='pl-2 mt-2' style={{ maxWidth: '400px', borderLeft: `5px solid ${Colors.darkBgBorder}` }}>
                                    <div className='d-flex'>
                                        <p className='flex1 mb-0'><span className='bold'>{this.state.user} </span> dropped <span className='bold'>{this.state.playerName}</span></p>
                                    </div>
                                </div>
                            </>
                        )
                }
            })()

        );
    }
}

const mapStateToProps = (state) => ({ leagueId: state.leagueJoin.leagueId })
export default connect(mapStateToProps, null)(PlayerNews);