import React, { useEffect, useState } from "react";
import { deleteGroup, updateNotificationPref } from "../../apis/Chat/ChatAPI";
import { getChatDetail } from "../../apis/SeasonLong/Settings/SettingsAPI";
import { Modal, ModalBody, ModalFooter, ModalHeader, Col, Row, Container, Button } from 'reactstrap';
// import { Colors } from '../../helpers/colors';
import { toastError, toastSuccess } from "../../helpers/toasts";
import { STYLES } from "../../helpers/stylesHelper";
import { withRouter } from "react-router-dom";
import { useColors } from '../../helpers/CustomHooks/useColors';

const ChatSettingsModal = (props) => {
    const Colors = useColors();
    const [currentViewModal, setCurrentViewModal] = useState('info');
    const [selectedNotify, setSelectedNotify] = useState('every');
    const [chatDetail, setChatDetail] = useState({ notification_pref: 'every' });
    const [members, setMembers] = useState();
    const [loading, setLoading] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [isOwner, setIsOwner] = useState(false);

    useEffect(() => {
        getChatDetail(props.leagueId, props.match.params.id, props.match.params.type).then((resp) => {
            setChatDetail(resp);
        })
    }, []);

    /**
   * Get data of current chat
   * @param {string} type - Type of active chat
   * @param {string} id - id of chat
   */
    const fetchChatData = () => {
        let data = null;
        let type = props.match.params.type;
        let id = props.match.params.id;

        if (type == 'group') {
            data = props.channels[id]
        }
        else {
            data = props.users[id]
        }
        setMembers(data);
        setCurrentViewModal('members');
    }

    const toggleConfirmModal = () => {
        setConfirmModal(!confirmModal)
    }

    const deleteChannel = () => {
        var reqData = {}
        reqData["id"] = props.match.params.id

        deleteGroup(reqData)
            .then(response => {
                toggleConfirmModal();
                props.toggleModal();
                navigateToGeneral();
            })
            .catch(({message}) => {
                toastError(message)
            })
    }

    const navigateToGeneral = () => {
        let general = Object.values(props.channels).find(channel => channel.name == 'General');
        if (general) {
            props.history.push(`/chat/group/${general.id}`);    
        }
        else{
            let firstChannelFromChannels = Object.keys(props.channel)
            props.history.push(`/chat/group/${firstChannelFromChannels[0]}`)
        }
        
    }

    const updateChatDetail = (data) => {
        var chatDetailCopy = { ...chatDetail }
        Object.keys(chatDetailCopy).map((key) => {
            chatDetailCopy[key] = data[key]
        })
        setChatDetail(chatDetailCopy);
    }

    const handleUpdateSettings = (data) => {
        setLoading(true);
        setSelectedNotify(data);
        var reqData = {};
        reqData["league_id"] = props.leagueId;
        reqData["message_to"] = props.match.params.id;
        reqData["notification_pref"] = data;
        reqData["type"] = props.match.params.type;
        updateNotificationPref(reqData).then(() => {
            // UPDATE PARENT ARRAY
            updateChatDetail({ notification_pref: data });
            setLoading(false);
        })
    }

    return (
        <>
        <Modal isOpen={props.modal} toggle={props.toggleModal} centered>
            <ModalHeader className='border-0 m-0 px-3 py-0 pt-3' style={{ backgroundColor: Colors.bgLight }} cssModule={{ 'modal-title': 'w-100' }}>
                <h4 className='bold text-truncate'>Channel Preferences<i className='fa fa-close pull-right' onClick={props.toggleModal} style={{ cursor: 'pointer' }}></i></h4>
            </ModalHeader>
            <ModalBody style={{ backgroundColor: Colors.bgLight, borderRadius:5 }}>
                <Container md>
                    <Col>
                        <Row className='px-0 my-2' style={{ borderRadius: 10, backgroundColor: Colors.bgLight, border: `0.01em solid ${Colors.darkBgBorder}` }}>
                            {currentViewModal === 'info' &&
                                <>
                                    <div className='mx-0 p-3 w-100' style={{ borderBottom: `0.01em solid ${Colors.darkBgBorder}`, cursor: 'pointer' }}>
                                        <span><h5 onClick={() => setCurrentViewModal('notifications')} className='p-0 m-0'><i class="fa fa-bell text-white mr-3" aria-hidden="true"></i>Notifications</h5></span>
                                    </div>
                                    <div className='mx-0 p-3 w-100' style={{ borderBottom: `0.01em solid ${Colors.darkBgBorder}`, cursor: 'pointer' }}>
                                        <span><h5 onClick={fetchChatData} className='p-0 m-0'><i class="fa fa-users text-white mr-3" aria-hidden="true"></i>Members</h5></span>
                                    </div>
                                    {
                                        props.channels[props.match.params.id] && props.channels[props.match.params.id].is_owner &&
                                        <div className='mx-0 p-3 w-100' style={{color: Colors.colorDanger, backgroundColor: Colors.colorDangerTransparent ,cursor: 'pointer' }}>
                                            <span><h5 onClick={toggleConfirmModal} className='p-0 m-0'> <i class="fa fa-trash mr-3" aria-hidden="true"></i> Delete Channel</h5></span>
                                        </div>
                                    }
                                    
                                </>
                            }
                            {currentViewModal === 'notifications' &&
                                <>
                                    {loading ? <p className="mx-0 p-3 w-100 text-center" style={{ height: '145px' }}>Processing....</p> : <>
                                        <div className='mx-0 p-3 w-100' style={chatDetail.notification_pref === 'every' ? { borderBottom: `0.01em solid ${Colors.darkBgBorder}`, color: Colors.colorPrimary, cursor: 'pointer' } : { borderBottom: `0.01em solid ${Colors.darkBgBorder}`, cursor: 'pointer' }}>
                                            <h5 className='p-0 m-0' onClick={() => handleUpdateSettings("every")}><i class="fa fa-comments-o text-white mr-3" aria-hidden="true"></i>Every new message</h5>
                                        </div>
                                        <div className='mx-0 p-3 w-100' style={chatDetail.notification_pref === 'mentions' ? { borderBottom: `0.01em solid ${Colors.darkBgBorder}`, color: Colors.colorPrimary, cursor: 'pointer' } : { borderBottom: `0.01em solid ${Colors.darkBgBorder}`, cursor: 'pointer' }}>
                                            <h5 className='p-0 m-0' onClick={() => handleUpdateSettings("mentions")}><i class="fa  fa-at text-white mr-3" aria-hidden="true"></i>Just @mentions</h5>
                                        </div>
                                        <div className='mx-0 p-3 w-100' style={chatDetail.notification_pref === 'nothing' ? { borderBottom: `0.01em solid ${Colors.darkBgBorder}`, color: Colors.colorPrimary, cursor: 'pointer' } : { borderBottom: `0.01em solid ${Colors.darkBgBorder}`, cursor: 'pointer' }}>
                                            <h5 className='p-0 m-0' onClick={() => handleUpdateSettings("nothing")}><i class="fa fa-bell-slash text-white mr-3" aria-hidden="true"></i>Nothing</h5>
                                        </div>
                                    </>}
                                </>
                            }
                            {currentViewModal === 'members' &&
                                < >
                                    {
                                        members.members.map((member, index) =>
                                            <div key={index} className=" w-100 p-2 my-1" style={{ borderRadius: 10, backgroundColor: Colors.bgDark, cursor: 'pointer' }} >
                                                <div className="d-flex flex-row align-items-center text-white ">
                                                    <div>
                                                        <i class="fa fa-user text-white mr-2 ml-1" aria-hidden="true"></i>
                                                    </div>
                                                    <div className="m-0 pl-2 align-items-center flex1 p-0" >
                                                        <h5 className='m-0 text-left text-truncate'>{member.team_name}
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </>
                            }
                        </Row>
                    </Col>
                </Container>
            </ModalBody>
            <ModalFooter className='border-0 py-1 pb-3' style={{ backgroundColor: Colors.bgLight, borderRadius:5 }}>
                <Button
                    onClick={() => setCurrentViewModal('info')}
                    className={`border-0 ${currentViewModal !== 'info' ? null : 'd-none'}`}
                    style={{ backgroundColor: Colors.colorPrimary }}
                >Back</Button>
            </ModalFooter>
        </Modal>
        <Modal isOpen={confirmModal}>
            <ModalBody>
                <h5>This action cannot be undone. Are you sure you want to delete this channel?</h5>
                <div className="pull-right">
                    <Button className="mx-1" style={STYLES.BUTTON_DANGER_TRANSPARENT} onClick={deleteChannel}>Yes</Button>
                    <Button className="mx-1" style={STYLES.BUTTON_SECONDARY_TRANSPARENT} onClick={toggleConfirmModal}>No</Button>
                </div>
            </ModalBody>
            
        </Modal>
        </>
    )
}

export default withRouter(ChatSettingsModal)