import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import LineupData from './LineupData'
import LineupInfo from './LineupInfo'
import LineupsHeader from './LineupsHeader'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useColors } from '../../../helpers/CustomHooks/useColors'
import { getStore } from '../../../helpers/common-methods'
import { MatchNotificationModal } from '../../../modals/Arena/MatchNotificationModal'

const ArenaBody = (props) => {
  const Colors = useColors();
  const { active_team, arenaHomeData, handleOnboarding, disableOnboarding, sport, middleModal, actionMatchData, rematchData } = props;
  const [showRematchh2h, setShowRematchh2h] = useState(false);

  const rematchStateCallback = () => setShowRematchh2h(!showRematchh2h);
  let matchData = actionMatchData;
  if (showRematchh2h) {
    matchData = rematchData;
  };

  const toggle = () => {
    if (middleModal) {
      getStore().dispatch({ type: "OPEN_MIDDLE_MODAL", payload: false })
    } else {
      setShowRematchh2h(!showRematchh2h);
    };
  };

  // closing h2h modal
  useEffect(() => {
    if (rematchData == null) {
      setShowRematchh2h(false);
    }
  }, [rematchData]);
  
  return (
    <div className='flex1 d-flex flex-column scrollHidden position-relative' style={{backgroundColor:Colors.bgDark_v3}} >
        <div className='d-flex flex-column flex1 scrollHidden'>
          <LineupsHeader active_team={active_team} all_teams={arenaHomeData?.teams} teams_order={arenaHomeData?.teamsOrder}/>
          <div className='px-5 d-flex flex-column flex1 scrollHidden'>
          {/* Body */}
              <LineupInfo
                arenaHomeData={arenaHomeData}
                handleOnboarding={handleOnboarding}
                active_team={active_team}
                disableOnboarding={disableOnboarding}
                sport={sport}
                {...props}
              />
              <LineupData activeTeam={active_team} sport={sport} handleOnboarding={handleOnboarding} disableOnboarding={disableOnboarding} {...props} rematchStateCallback={rematchStateCallback} />
          </div>
        </div>
        <div className={`d-flex flex-column flex1 scrollHidden justify-content-center Modal position-absolute h-100 modalBackdropFilter ${(middleModal || showRematchh2h) ? 'Show' : ''}`} style={{ background: "rgba( 0, 0, 0, 0.3 )" }}>
          {(middleModal || showRematchh2h) && 
            <div className='d-flex flex-column flex1 scrollHidden justify-content-center modalBackdropFilter' style={{ background: Colors.bgDark, margin: "0px 40px 0px 40px", maxHeight: "600px", borderRadius: 10 }}>
              <MatchNotificationModal matchData={matchData} toggle={toggle} sport={sport} />
            </div>
          }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({active_team: state.arenaReducer.active_team, middleModal: state.arenaReducer.middleModal, actionMatchData: state.arenaReducer.actionMatchData, rematchData: state.arenaReducer.rematchData});
export default connect(mapStateToProps, null)(ArenaBody)