import React from 'react'
import { connect } from 'react-redux'
import { useColors } from '../../helpers/CustomHooks/useColors'
import SendInvitesModal from '../../modals/SeasonLong/SendInvites/SendInvitesModal'

const InviteMembersBanner = (props) => {

    const Colors = useColors();

    const [inviteModal, setInviteModal] = React.useState(false)
    
    const toggleModal = () => {
        setInviteModal(!inviteModal)
    }

    return (
        props.activeLeague?.joinedTeams < props.activeLeague?.max_teams
        ? 
        <>
            <div 
                className='py-1 text-center pointer' 
                onClick={toggleModal}
                style={{backgroundColor: props.activeLeague.joinedTeams < Math.ceil(props.activeLeague?.max_teams/2) ? Colors.colorDanger : Colors.sidebarBody }}
            >
                { props.activeLeague.joinedTeams == 1 ? "No one is here yet! Click here to send invites." : "Only "+props.activeLeague.joinedTeams+" people have joined. Click here to send invites."}
            </div> 
            <SendInvitesModal isOpen={inviteModal} toggle={toggleModal} activeLeague={props.activeLeague} />
        </>
        : 
        null
    )
}

const mapStateToProps = (state) => ({activeLeague: state.leagueJoin.activeLeague})
export default connect(mapStateToProps, null) (InviteMembersBanner)