import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useColors } from '../../helpers/CustomHooks/useColors';
import { STYLES } from '../../helpers/stylesHelper';

const ArenaError = (props) => {
    const {msg, routeTo, buttonp, errorCallback, cancelCallback, title, hideSuccessBtn} = props

    const Colors = useColors();

    const [loading, setLoading] = useState(false)
    const history = useHistory()

    const doNavigation = () => {
      if(routeTo){
        if(errorCallback){
          errorCallback()
        }
        history.push(routeTo.path)
      }else{
        errorCallback()
        // navigation.navigate("Home") 
      }
    }

    const handleCancel = () => {
      setLoading(true)
      cancelCallback()
    }

    return (
      <div className='d-flex flex-column' style={{justifyContent: "center", alignItems: "center", flex: 1, backgroundColor: Colors.bgDark, paddingTop: "5rem"}} >
          <div className='d-flex flex-column' style={{flex: 1, padding: "0 30px", justifyContent: "center", paddingBottom: 20}}>
              <div className='d-flex flex-column justify-content-center flex1' >
                  <h2 style={{margin: "10px 0", textAlign: "center"}} >{title ? title : "Oh oh, that's an error"}</h2>
                  {/* <p style={{marginBottom: 20, marginTop: 10}}  >{msg}</p> */}
                  <div className='d-flex flex-column flex1 mt-4' style={{alignItems: "center"}} >
                    <i style={{fontSize:"5rem", color: Colors.colorDanger}} class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  </div>
                    <div className='d-flex flex-column align-items-center mt-4' >
                        <p className='lead' >{msg}</p>
                        <div className='mt-4' >
                          {!hideSuccessBtn && <Button block onClick={doNavigation} style={STYLES.BUTTON_PRIMARY_TRANSPARENT} >{buttonp}</Button>}
                          {cancelCallback && <Button block disabled={loading} onClick={handleCancel} style={STYLES.BUTTON_DANGER_TRANSPARENT} >Cancel</Button>}                    
                        </div>
                    </div>
              </div>
          </div>
        </div>
    )
}

export default ArenaError;