import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'reactstrap';
import AuthLandingWeb from '../../components/Auth/AuthLandingWeb/AuthLandingWeb';
import SignUpCompV2 from '../../components/Auth/SignUp/SignUpCompV2';
import Header from '../../components/Header/Header';
import { Colors } from '../../helpers/colors';
import PerfectScrollbar from 'react-perfect-scrollbar'
import background from '../../assets/images/field-sepia.jpg';

const SignUpV2 = () => {

    return (
        <div className='d-flex flex-column' style={{ height: '100vh', backgroundImage: `url(${background})`}}>
            <Header />
            <div className='flex1 d-flex justify-content-center' style={{overflow:'hidden', backgroundColor:Colors.colorDark}}>
                <PerfectScrollbar className='flex1 d-flex' style={{maxWidth: "700px", width: "100%"}}>
                    <SignUpCompV2 />
                </PerfectScrollbar>
                {/* <div className='flex1 d-none d-sm-flex h-100 align-items-center justify-content-center'>
                    <div className='d-none d-sm-block'>
                        <AuthLandingWeb />
                    </div>
                </div> */}
            </div>
            {/* <Footer /> */}
        </div>
    )
};

export default SignUpV2;