import React from 'react'
import { connect } from 'react-redux'
import store from '../../../store'
import { setActiveTeam } from '../../../apis/Arena/MatchMakerApis'

const AllTeamsList = (props) => {
    const { all_teams } = store.getState().arenaReducer;
    const { setActiveTeam } = props;

    const handleTeamSelect = (team) => {
        setActiveTeam(team)
    }
    return (
        <div className='d-flex flex-column p-4'>
            {Object.values(all_teams).map((team) => (

                <div className="align-items-center d-flex my-2">
                    <div className="custom-control custom-radio">
                        <input type="radio" id={team.teamId} name="customRadio" className="custom-control-input" onClick={() => handleTeamSelect(team)} />
                        
                        <label className="custom-control-label pointer" for={team.teamId}>
                            <div className='d-flex flex-row align-items-center'>
                                <img className='roundImgSmall' src={team.teamImage}/>
                                <div className='ml-3'>
                                    <p className='m-0 bold'>{team.teamName}</p>
                                    <p className='m-0'>{team.leagueName}</p>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>

                // <div className='d-flex flex-row align-items-center justify-content-between p-2' key={team.id} onClick={() => handleTeamSelect(team)}>
                //     <div className='d-flex flex-row align-items-center'>
                //         <img className='roundImgMed' style={{height:70, width:70}} src={team.teamImage}/>
                //         <div className='ml-3'>
                //             <h3 className='m-0 heading-1'>{team.teamName}</h3>
                //         </div>
                //     </div>
                //     <div className='d-flex flex-row align-items-center'>
                //         <h3 className='m-0 heading-1'>{team.sport}</h3>
                //     </div>
                // </div>
            ))}
        </div>
    )
}

const mapDispatchToProps = { setActiveTeam }
export default connect(null, mapDispatchToProps) (AllTeamsList)