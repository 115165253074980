import React, { Component } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, Button, Table, UncontrolledDropdown } from 'reactstrap';
// import { Colors } from "../../../helpers/colors";
import * as Icons from '../../../helpers/icons'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { acceptedLive, pendingLobby, recentBets } from "../../../apis/MyBets/MyBetsAPI";
import { connect } from "react-redux";
import { toastError } from "../../../helpers/toasts";
import BetProposals from "./BetProposals";

import BetLive from "./BetLive";
import BetUpcoming from "./BetUpcoming";
import BetRecent from "./BetRecent";
import { withRouter } from "react-router-dom";
import ReactSelect from "react-select";
import { STYLES } from "../../../helpers/stylesHelper";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "react-loaders";
import { ColorContext } from '../../../context/ColorProvider';

class MyBets extends Component {
  constructor(props) {
    super(props);
    this.state = {

      // Loading states
      liveLoading: true,
      lobbyLoading: true,

      // Data states
      liveData: null,
      acceptedData: null,
      lobbyData: null,
      recentBets: null,

      // Counts
      proposalCount: '...',
      upcomingCount: '...',
      liveCount: '...',
      recentCount: '...',

      // Load more
      loadMoreLive: true,
      loadMoreAccepted: true,
      loadMoreLobby: true,
      loadMoreRecent: true,

      dataLength: 0,
      hasMore: true,

      // Limit-Offset
      limit: 5,
      offsetLive: 0,
      offsetLobby: 0,
      offsetAccepted: 0,
      offsetRecent: 0,

      tab: {
        value: 'proposal',
        label: 'Proposal(...)'
      },

      tabs: []
    }

    this.refreshView = this.refreshView.bind(this)
    this.redirectToSidebetsChannel = this.redirectToSidebetsChannel.bind(this)
    this.handleTab = this.handleTab.bind(this)

    this.loadMoreBetProposals = this.loadMoreBetProposals.bind(this);
    this.loadMoreAcceptedLive = this.loadMoreAcceptedLive.bind(this);
    this.loadMoreRecentBets = this.loadMoreRecentBets.bind(this);

    this.dataLength = this.dataLength.bind(this);
    this.hasMore = this.hasMore.bind(this);
    this.next = this.next.bind(this);

    this.updateLoadMoreData = this.updateLoadMoreData.bind(this);
  }
  static contextType = ColorContext;

  componentDidMount() {
    this.callAPIs()
  }

  callAPIs() {

    // Live bets
    acceptedLive({ leagueId: this.props.leagueId, type: 'live', offset: this.state.offsetLive, limit: this.state.limit })
      .then(response => {
        this.setState({
          liveData: response.data,
          liveLoading: false,
          loadMoreLive: response.data.length >= this.state.limit,
          offsetLive: this.state.offsetLive + this.state.limit
        })
        this.prepareLiveBetsLength(response.data)
      })
      .catch(({ message }) => {
        toastError(message)
      })

    // Accepted but not Live
    acceptedLive({ leagueId: this.props.leagueId, type: 'accepted', offset: this.state.offsetAccepted, limit: this.state.limit })
      .then(response => {
        this.setState({
          acceptedData: response.data,
          liveLoading: false,
          loadMoreAccepted: response.data.length >= this.state.limit,
          offsetAccepted: this.state.offsetAccepted + this.state.limit
        })
        this.prepareUpcomingBetsLength(response.data)
      })
      .catch(({ message }) => {
        toastError(message)
      })

    pendingLobby({ leagueId: this.props.leagueId, offset: this.state.offsetLobby, limit: this.state.limit })
      .then(response => {
        this.setState({
          lobbyData: response.data,
          lobbyLoading: false,
          loadMoreLobby: response.data.pending_challenges.length >= this.state.limit,
          offsetLobby: this.state.offsetLobby + this.state.limit
        })
        this.prepareProposalBetsLength(response.data)
      })
      .catch(({ message }) => {
        toastError(message)
      })

    recentBets({ leagueId: this.props.leagueId, offset: this.state.offsetRecent, limit: this.state.limit })
      .then(response => {
        this.setState({
          recentBets: response.data,
          recentCount: response.data.length,
          loadMoreRecent: response.data.length >= this.state.limit,
          offsetRecent: this.state.offsetRecent + this.state.limit,
          tabs: [...this.state.tabs, { value: 'recent', label: `Recent(${response.data.length})` }]

        })
      })
      .catch(({ message }) => {
        toastError(message)
      })
  }

  /**
   * Load more bet proposals
   * @param {Object} params - leagueId, limit, offset
   */
  loadMoreBetProposals() {
    pendingLobby({ leagueId: this.props.leagueId, offset: this.state.offsetLobby, limit: this.state.limit })
      .then(response => {
        this.setState({
          lobbyData: [...this.state.lobbyData, ...response.data],
          loadMoreLobby: response.data.length >= this.state.limit,
          offsetLobby: this.state.offsetLobby + this.state.limit,
        })
        this.updateLoadMoreData("proposal")
      })
      .catch(({ message }) => {
        toastError(message)
      })
  }

  /**
   * Load more recent bets
   * @param {Object} params - leagueId, limit, offset
   */
  loadMoreRecentBets() {
    recentBets({ leagueId: this.props.leagueId, offset: this.state.offsetRecent, limit: this.state.limit })
      .then(response => {
        this.setState({
          recentBets: [...this.state.recentBets, ...response.data],
          loadMoreRecent: response.data.length >= this.state.limit,
          offsetRecent: this.state.offsetRecent + this.state.limit,
        })
        this.updateLoadMoreData("recent")
      })
      .catch(({ message }) => {
        toastError(message)
      })
  }

  /**
   * Load more accepted or live bets
   * @param {Object} params - LeagueId, type[accepted/live], limit, offset
   */
  loadMoreAcceptedLive(type) {
    acceptedLive({ leagueId: this.props.leagueId, type: type, offset: this.state.offsetLobby, limit: this.state.limit })
      .then(response => {
        if (type == 'live') {
          this.setState({
            liveData: [...this.state.liveData, ...response.data],
            loadMoreLive: response.data.length >= this.state.limit,
            offsetLive: this.state.offsetLive + this.state.limit,
          })
          this.updateLoadMoreData("live")
        }
        else {
          this.setState({
            acceptedData: [...this.state.acceptedData, ...response.data],
            loadMoreAccepted: response.data.length >= this.state.limit,
            offsetAccepted: this.state.offsetAccepted + this.state.limit,
          })
          this.updateLoadMoreData("upcoming")
        }

      })
      .catch(({ message }) => {
        toastError(message)
      })
  }

  refreshView() {
    this.callAPIs()
    this.setState({
      lobbyLoading: true,
      liveLoading: true
    })
  }
  prepareLiveBetsLength(liveData) {
    let liveCount = 0
    if (liveData) {
      liveCount = liveData.length
    }
    this.setState({
      liveCount: liveCount,
      tabs: [...this.state.tabs, { value: 'live', label: `Live(${liveCount})` }]
    })
  }

  prepareUpcomingBetsLength(liveData) {
    let upcomingCount = 0
    if (liveData) {
      upcomingCount = liveData.length
    }
    this.setState({
      upcomingCount: upcomingCount,
      tabs: [...this.state.tabs, { value: 'upcoming', label: `Upcoming(${upcomingCount})` }]
    })
  }

  prepareProposalBetsLength(lobbyData) {
    let proposalCount = 0
    if (lobbyData.pending_challenges) {
      proposalCount = lobbyData.pending_challenges.length
    }
    this.setState({
      proposalCount: proposalCount,
      tabs: [...this.state.tabs, { value: 'proposal', label: `Proposal(${proposalCount})` }],
      tab: { value: 'proposal', label: `Proposal(${proposalCount})` }
    })
  }
  dataLength(tab = this.state.tab.value) {
    switch (tab) {
      case 'proposal':
        this.setState({
          dataLength: this.state.lobbyData.pending_challenges.length
        })
        break;
      case 'live':
        this.setState({
          dataLength: this.state.liveData.length
        })
        break;
      case 'upcoming':
        this.setState({
          dataLength: this.state.acceptedData.length
        })
        break;
      case 'recent':
        this.setState({
          dataLength: this.state.recentBets.length
        })
        break;
    }
  }

  next(tab = this.state.tab.value) {
    switch (tab) {
      case 'proposal':
        this.loadMoreBetProposals();
        break;
      case 'live':
        this.loadMoreAcceptedLive("live");
        break;
      case 'upcoming':
        this.loadMoreAcceptedLive("accepted");
        break;
      case 'recent':
        this.loadMoreRecentBets();
        break;
    }
    // this.updateLoadMoreData(tab) 

  }

  hasMore(tab = this.state.tab.value) {
    switch (tab) {
      case 'proposal':
        this.setState({
          hasMore: this.state.loadMoreLobby
        })
        break;
      case 'live':
        this.setState({
          hasMore: this.state.loadMoreLive
        })
        break;
      case 'upcoming':
        this.setState({
          hasMore: this.state.loadMoreAccepted
        })
        break;
      case 'recent':
        this.setState({
          hasMore: this.state.loadMoreRecent
        })
        break;
    }
  }

  setTab(tab) {
    this.setState({
      tab: tab
    })
  }

  handleTab(tab) {
    this.setState({ tab })
    this.updateLoadMoreData(tab.value)
  }

  updateLoadMoreData(tab) {
    this.hasMore(tab)
    this.dataLength(tab)
  }

  redirectToSidebetsChannel() {
    let { id } = Object.values(this.props.channels).filter(channel => channel.name == 'Sidebets')[0]

    if (id) {
      this.props.history.push('/chat/group/' + id + '?openCommand=sidebet')
    }
    else {
      toastError('Something went wrong!')
    }
  }

  render() {
    const Colors = this.context;
    return (
      <>
        <div className="mb-2" style={{ borderBottom: `0.01em solid ${Colors.darkBgBorder}`, paddingTop: '5px', paddingBottom: '5px' }}>
          <h2 className='px-3'>My Bets</h2>
        </div>
        <div className="container d-flex flex-column flex1" >

          <div className="d-flex justify-content-end mb-2">
            <ReactSelect options={this.state.tabs} value={this.state.tab} onChange={this.handleTab} className=" mx-1 text-white" styles={STYLES.REACT_SELECT} closeMenuOnSelect={true} isClearable={false} />
            {/* The Selects below are dummy */}
            {/* <ReactSelect options={[{ value: 'team', label: 'Team' }]} value={{ value: 'team', label: 'Team' }} onChange={'this.handleTab'} className=" mx-1 text-white" styles={STYLES.REACT_SELECT} closeMenuOnSelect={true} isClearable={false} />
            <ReactSelect options={[{ value: '3 weeks', label: 'Last 3 Weeks' }]} value={{ value: '3 weeks', label: 'Last 3 Weeks' }} onChange={'this.handleTab'} className=" mx-1 text-white" styles={STYLES.REACT_SELECT} closeMenuOnSelect={true} isClearable={false} /> */}
            {/* The Selects above are dumy */}
            {/* <h4 className="flex1 pb-2 pt-2" onClick={() => this.setTab('proposal')} style={{cursor:'pointer',borderBottom:`${this.state.tab == 'proposal' ? "1px" : 0} solid ${Colors.colorPrimary}`, color:this.state.tab == 'proposal' ? Colors.colorPrimary: ''}}>Proposal{`(${this.state.proposalCount})`}</h4>
            <h4 className="flex1 pb-2 pt-2" onClick={() => this.setTab('upcoming')} style={{cursor:'pointer',borderBottom:`${this.state.tab == 'upcoming' ? '1px' : 0} solid ${Colors.colorPrimary}`, color:this.state.tab == 'upcoming' ? Colors.colorPrimary: ''}}>Upcoming{`(${this.state.upcomingCount})`}</h4>
            <h4 className="flex1 pb-2 pt-2" onClick={() => this.setTab('live'    )} style={{cursor:'pointer',borderBottom:`${this.state.tab == 'live'     ? '1px' : 0} solid ${Colors.colorPrimary}`, color:this.state.tab == 'live'     ? Colors.colorPrimary: ''}}>Live{`(${this.state.liveCount})`}</h4>
            <h4 className="flex1 pb-2 pt-2" onClick={() => this.setTab('recent'  )} style={{cursor:'pointer',borderBottom:`${this.state.tab == 'recent'   ? '1px' : 0} solid ${Colors.colorPrimary}`, color:this.state.tab == 'recent'   ? Colors.colorPrimary: ''}}>Recent{`(${this.state.recentCount})`}</h4> */}
          </div>
          <div className="d-flex flex-column flex1 mb-4" style={{ backgroundColor: Colors.bgDark, borderRadius: 15, border: `0.01em solid ${Colors.darkBgBorder}` }}>
            <PerfectScrollbar className="flex1" id='bets-table' style={{ position: "relative" }} >
              {/* Table */}
              <InfiniteScroll
                className='w-100 h-100'
                dataLength={this.state.dataLength}
                next={this.next}
                hasMore={this.state.hasMore}
                loader={<div className='d-flex py-3'><Loader className='m-auto' type="ball-pulse" color={Colors.colorPrimary} /></div>}
                endMessage={<div className='text-center py-3'><i class="fa fa-check-circle" aria-hidden="true"></i> No More Bets. You are all caught up!</div>}
                scrollableTarget={'bets-table'}
              >
                <table className='table table-borderless text-nowrap' style={{ position: "absolute" }} >
                  <thead
                    className="bold" style={{ position: 'sticky', top: 0, color: Colors.colorSecondary }} >
                    <tr style={{ backgroundColor: Colors.bgLight, borderBottom: `0.01em solid ${Colors.darkBgBorder}` }} >
                      <th></th>
                      <th className="align-middle">Matchup</th>
                      <th className="align-middle">Score</th>
                      <th className="align-middle">Type</th>
                      <th className="align-middle">Duration</th>
                      <th className="align-middle">Amount</th>
                      <th className=" d-flex justify-content-end">

                        <Button className='d-flex align-items-center' style={{ color: Colors.colorPrimary, backgroundColor: Colors.colorPrimaryTransparent, border: `1px solid ${Colors.colorPrimary}` }} onClick={this.redirectToSidebetsChannel}>
                          <div className="d-inline-block mr-2" style={{ height: 15, width: 15 }}>
                            <Icons.myBetsIcon />
                          </div>
                          New Bet
                        </Button>
                      </th>
                    </tr>
                  </thead>
                  {
                    {
                      'proposal':
                        !this.state.lobbyLoading && <BetProposals data={this.state.lobbyData} loading={this.state.lobbyLoading} leagueId={this.props.leagueId} refreshView={this.refreshView} loadMore={this.loadMoreBetProposals} hasMore={this.state.loadMoreLobby} />,
                      'upcoming':
                        !this.state.liveLoading && <BetUpcoming data={this.state.acceptedData} loading={this.state.liveLoading} activeLeague={this.props.activeLeague} loadMore={this.loadMoreAcceptedLive} hasMore={this.state.loadMoreAccepted} />,
                      'live':
                        !this.state.liveLoading && <BetLive data={this.state.liveData} loading={this.state.liveLoading} activeLeague={this.props.activeLeague} loadMore={this.loadMoreAcceptedLive} hasMore={this.state.loadMoreLive} />,
                      'recent':
                        this.state.recentBets != null && <BetRecent data={this.state.recentBets} activeLeague={this.props.activeLeague} loadMore={this.loadMoreRecentBets} hasMore={this.state.loadMoreRecent} />
                    }[this.state.tab.value]
                  }
                </table>
              </InfiniteScroll>
            </PerfectScrollbar>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ leagueId: state.leagueJoin.leagueId, activeLeague: state.leagueJoin.activeLeague, channels: state.leagueJoin.channels })
export default connect(mapStateToProps, null)(withRouter(MyBets));