import React, { Component } from 'react';
import Main from '../../components/Chat/Main';

class ChatWindow extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        fullScreen: true,
    }

   

    render() {
        return (
            <>
                <Main {...this.props} />
            </>

        );
    }
}

export default ChatWindow;