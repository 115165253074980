import React from 'react';
import { Button, Modal } from 'reactstrap';
import { STYLES } from '../../helpers/stylesHelper';
import {useHistory} from "react-router-dom"
import { toggleGidxSuccessModal } from '../../helpers/Payments/GidxHelper';
import store from '../../store';
import { useColors } from '../../helpers/CustomHooks/useColors';
import { walletIcon, walletMoneyIcon } from '../../helpers/icons';

const GidxSuccessModal = (props) => {
    const { isOpen, modalToggle, type, newBalance, depositClaimed, amount } = props;
    const Colors = useColors();
    const history = useHistory()
    const successModal = store.getState().gidxErrorReducer.successModal

    const toggle = () => {
        toggleGidxSuccessModal()
        if(successModal.data.path){
            history.push(successModal.data.path)
        }
    }

    const haveBonus = () => {
        if(!depositClaimed && amount <=100){
          return true
        }
        return false
    }
    
    return (
        <div >
            {type == "addFunds" ? 
            <Modal isOpen={isOpen} toggle={modalToggle}>
                <div className="d-flex flex1 justify-content-center align-items-center mb-3 py-2" style={{ background: Colors.mlbPrimaryColor, borderTopRightRadius: 20, borderTopLeftRadius: 20 }}>
                    <div className="mr-2" style={{width:30, height:30, aspectRatio:1, color: Colors.colorDark}} >
                        {walletIcon()}
                    </div>
                    <p className="m-0 bold" style={{ color: Colors.colorDark, fontSize: 20 }}>Wallet</p>
                </div>
                <div className='text-center'>
                    <p className='m-0 bold' style={{ color: Colors.mlbPrimaryColor, fontSize: 24 }}>Your funds have been added.</p>
                    <p className='m-0 bold' style={{ fontSize: 14 }}>You are now locked and loaded. Giddyup.</p>
                    <div className='my-4' style={{ lineHeight: 1.3 }}>
                        <p className='m-0 bold'>Your new balance is</p>
                        <p className='m-0 bold' style={{ color: Colors.mlbPrimaryColor, fontSize: 40 }}>${newBalance}</p>
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center mx-3 pt-2 pb-1 mb-3' style={{ borderTop: `2px solid ${Colors.darkBgBorder}`, borderBottom: `2px solid ${Colors.darkBgBorder}` }}>
                    <div className='d-flex' style={{ lineHeight: 1 }}>
                        <p className='m-0'>{walletMoneyIcon()}</p>
                        <div className='pl-2'>
                            <p className='m-0 bold' style={{ fontSize: 17 }}>Funds Added</p>
                            <p className='m-0 bold mt-1' style={{ fontSize: 12, color: Colors.textColorSecondary }}>{!depositClaimed ? "Deposit + Match" : "Deposit"}</p>
                        </div>
                    </div>
                    <div style={{ lineHeight: 1 }}>
                        <p className='m-0 bold' style={{ fontSize: 19 }}>+${haveBonus() ? amount * 2 : amount}</p>
                        {haveBonus() && <p className='m-0 bold mt-1' style={{ fontSize: 12, color: Colors.textColorSecondary }}>${amount} + ${amount}</p>}
                    </div>
                </div>
            </Modal> :
                <Modal size="lg" isOpen={successModal.show} toggle={toggle}>
                <div className="p-4 d-flex flex-column justify-content-center align-items-center" style={{minHeight: "300px"}} >
                <i onClick={toggle} class="cui-circle-x icons pointer modalClose"></i>
                <h4 className='bold mb-3' >Success</h4>
                    <i style={{fontSize: "5rem", color: Colors.colorPrimary}} class="fa fa-check-square-o" aria-hidden="true"></i>
                    <div className='mt-4 text-center' >
                        <p className='mb-4' >{successModal.data.msg}</p>
                        <Button onClick={toggle} style={STYLES.BUTTON_PRIMARY_TRANSPARENT} >Continue</Button>
                    </div>
                </div>
                </Modal>
            }
        </div>
    );
}

export default GidxSuccessModal