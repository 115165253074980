import moment from 'moment-timezone'
import React, {useState} from 'react'
import ReactDatePicker from 'react-datepicker'
import Datetime from 'react-datetime'
import {Controller, useForm} from 'react-hook-form'
import LoadingOverlay from 'react-loading-overlay'
import {Alert, Button, UncontrolledAlert} from 'reactstrap'
import {updateLeagueSettings} from '../../../apis/SeasonLong/Settings/SettingsAPI'
// import {Colors} from '../../../helpers/colors'
import Header from './Header'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useColors } from '../../../helpers/CustomHooks/useColors';

const DraftSettings = ({onBackHandler, steps_data, options_data, leagueId}) => {
    const Colors = useColors();
    const [loader,
        setLoader] = useState(false)
    const [error,
        setError] = useState([])
    const [isValid,
        setIsValid] = useState(false);
    const {register, handleSubmit, control, formState: {
            errors
        }} = useForm()

    const onSubmit = data => {
        if (data.draft_date && data.draft_time) {
            let dateTime = moment(data.draft_date)
                .local()
                .format("YYYY-MM-DD") + "T" + moment(data.draft_time)
                .local()
                .format("HH:mm:ssZ")
            data["draft_date"] = moment(dateTime)
            delete data["draft_time"]
        }

        var reqData = {}
        // setShowSpinner(true)
        setLoader(true)
        reqData["leagueId"] = leagueId
        reqData["type"] = "draft"
        data["offline_draft"] = false
        reqData["input"] = data

        updateLeagueSettings(reqData).then(() => {
            setLoader(false)
            setIsValid(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
            setError(err)
            setIsValid(true)
        })
    }

    return (
        <form
            className='d-flex flex-column flex1 scrollHidden'
            onSubmit={handleSubmit(onSubmit)}>
            <LoadingOverlay
                className='d-flex flex-column flex1 scrollHidden'
                active={loader}
                spinner
                text='Saving...'
                fadeSpeed={1000}>
                <Header onBackHandler={onBackHandler} name='Draft Settings'/>
                <PerfectScrollbar>
                    <div className="row flex1 align-items-center justify-content-center p-4 m-0">
                        <div className="text-center col-md-6 col-sm-12">
                            {/* Draft toggle */}
                            <div className='row d-flex flex-column align-items-start my-3'>
                                {isValid
                                    ? <Alert
                                            toggle={function noRefCheck() {
                                            setIsValid(!isValid)
                                        }}
                                            className='d-inline-block'
                                            color="danger">Oops! Something went wrong... Try again</Alert>
                                    : null}
                                {/* Heading */}
                                <h6 className='bold mt-1'>Will you be drafting on Bettle</h6>
                                {/* Actions Container */}
                                <Controller
                                    control={control}
                                    render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value
                                    }
                                }) => (
                                    <div className='flex1 mt-1'>
                                        <Button
                                            onClick={() => onChange(true)}
                                            className='mr-1'
                                            style={{
                                            border: `1px solid ${Colors.bgDark}`,
                                            borderRadius: 25,
                                            backgroundColor: value
                                                ? Colors.colorPrimary
                                                : Colors.bgLight,
                                            color: value
                                                ? Colors.bgLight
                                                : Colors.colorLight
                                        }}>Yes</Button>
                                        <Button
                                            onClick={() => onChange(false)}
                                            className='ml-1'
                                            style={{
                                            border: `1px solid ${Colors.bgDark}`,
                                            borderRadius: 25,
                                            backgroundColor: value == false
                                                ? Colors.colorPrimary
                                                : Colors.bgLight,
                                            color: value == false
                                                ? Colors.bgLight
                                                : Colors.colorLight
                                        }}>No</Button>
                                    </div>
                                )}
                                    name="has_draft"
                                    defaultValue={steps_data.draft.has_draft}/>
                            </div>
                            {/* !Draft toggle*/}

                            {/* Draft Type */}
                            <div className='row d-flex flex-column align-items-start my-3'>
                                {/* Heading */}
                                <h6 className='bold mt-1'>Draft Type</h6>
                                {/* Actions Container */}
                                <Controller
                                    control={control}
                                    render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value
                                    }
                                }) => (
                                    <div className='row'>
                                        {Object
                                            .keys(options_data.draft.draft_type)
                                            .map((key) => (
                                                <div className='col-2 mr-5 mt-1'>
                                                    <Button
                                                        onBlur={onBlur}
                                                        onClick={() => onChange(key)}
                                                        className=''
                                                        style={value === key
                                                        ? {
                                                            border: `1px solid ${Colors.bgDark}`,
                                                            borderRadius: 25,
                                                            backgroundColor: Colors.colorPrimary,
                                                            color: Colors.bgLight
                                                        }
                                                        : {
                                                            border: `1px solid ${Colors.bgDark}`,
                                                            borderRadius: 25,
                                                            backgroundColor: Colors.bgLight,
                                                            color: Colors.colorLight
                                                        }}>{options_data.draft.draft_type[key]}</Button>
                                                </div>
                                            ))}
                                    </div>
                                )}
                                    name="draft_style"
                                    defaultValue={steps_data.draft.draft_style}/>
                            </div>
                            {/* !Draft Type*/}

                            {/* Seconds per pick */}
                            <div className='row d-flex flex-column align-items-start my-3'>
                                {/* Heading */}
                                <h6 className='bold mt-1'>Seconds per pick</h6>
                                {/* Actions Container */}
                                <Controller
                                    control={control}
                                    render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value
                                    }
                                }) => (
                                    <div
                                        style={{
                                        flexDirection: "row"
                                    }}>
                                        {Object
                                            .values(options_data.draft.time_per_pick)
                                            .map((timePerPick, index) => (
                                                <Button
                                                    className='mx-1'
                                                    onBlur={onBlur}
                                                    onClick={() => onChange(index + 1)}
                                                    style={{
                                                    border: `1px solid ${Colors.bgDark}`,
                                                    borderRadius: 25,
                                                    backgroundColor: value == index + 1
                                                        ? Colors.colorPrimary
                                                        : Colors.bgLight,
                                                    color: value == index + 1
                                                        ? Colors.bgLight
                                                        : Colors.colorLight
                                                }}>{timePerPick}</Button>
                                            ))}
                                    </div>
                                )}
                                    name="draft_time_per_pick"
                                    defaultValue={steps_data.draft.draft_time_per_pick}/>

                                <div className='row d-flex flex-column align-items-start mt-4'>
                                    {/* Heading */}
                                    <h6 className='bold my-1'>Draft Date</h6>
                                    {/* Actions Container */}
                                    <Controller
                                        control={control}
                                        render={({
                                        field: {
                                            onChange,
                                            onBlur,
                                            value
                                        }
                                    }) => (<ReactDatePicker
                                        className='picker my-1'
                                        onBlur={onBlur}
                                        placeholder="Trade Deadline Date"
                                        selected={value}
                                        onChange={d => onChange(d)}/>)}
                                        name="draft_date"
                                        defaultValue={moment(steps_data.draft.draft_date).toDate()}/>
                                </div>

                                <div className='row d-flex flex-column align-items-start mt-4'>
                                    {/* Heading */}
                                    <h6 className='bold my-1'>Draft Time</h6>
                                    {/* Actions Container */}
                                    <Controller
                                        control={control}
                                        render={({
                                        field: {
                                            onChange,
                                            onBlur,
                                            value
                                        }
                                    }) => (<Datetime
                                        className="timePicker"
                                        dateFormat={false}
                                        onBlur={onBlur}
                                        value={value}
                                        onChange={e => onChange(e)}/>)}
                                        name="draft_time"
                                        defaultValue={moment(steps_data.draft.draft_date).toDate()}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </PerfectScrollbar>
            </LoadingOverlay>
        </form>
    )
}

export default DraftSettings