import React, { useEffect, useState } from 'react';
import { useColors } from '../../../../helpers/CustomHooks/useColors';
import { messageUrlPreviewHandler, makeUrlClickable, detectUrls, parseMessage } from '../../../../helpers/MessageHelper';
import ImagePreview from '../Image/ImagePreview';

const UrlPreview = (props) => {
  const [message, setMessage] = useState('');
  const Colors = useColors()
  let links = messageUrlPreviewHandler(props.data.message_content, props.data.attachments);
  let data = props.data.attachments[0].data[0];
  const clickAbleUrls = makeUrlClickable(props.data.message_content, props.data.attachments[0].data);

  useEffect(() => {
    setMessage(parseMessage(props.data.message_content, props.history));
  }, []);

  return (
    (() => {
      switch (props.status) {
        case 'URL_PREVIEW':
          return (
            data && props.data.attachment == null ? (
              <>
                <p>{links}</p>
                <div className='pl-2 mt-2 mb-3' style={{ maxWidth: '400px', borderLeft: `5px solid ${Colors.darkBgBorder}` }}>
                  <div className='d-flex'>
                    {data.favicon !== null &&
                      <img src={data.favicon} width='20px' height='20px' />
                    }
                    {data.providerName &&
                      <p className='ml-2 mb-1'>{data.providerName}</p>
                    }
                  </div>
                  {data.title &&
                    <p className='mb-2' >{data.title}</p>
                  }
                  {data.description &&
                    <p>{data.description}</p>
                  }
                  {data.image !== null &&
                    <img src={data.image} alt="" style={{ maxWidth: "300px", borderRadius: 5 }} />
                  }
                </div>
              </>
            ) : (
              props.data.message_content.includes('@') && props.data.attachment !== null ? (
                <>
                  <p>{message}</p>
                  <ImagePreview {...props} />
                </>
              ) : (
                <>
                  <p>{detectUrls(props.data.message_content)}</p>
                  <ImagePreview {...props} />
                </>
              )
            )
          )
        case 'URL_LOCAL':
          return (
            data && props.data.attachment == null ? (
              <p>{clickAbleUrls}</p>
            ) : (
              <>
                <p>{clickAbleUrls}</p>
                <ImagePreview {...props} />
              </>
            )
          )
      }
    })())
};

export default UrlPreview;