import React from 'react';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
// import { Colors } from '../../helpers/colors';
import { arenaSettingsEditIcon, cancelIcon } from '../../helpers/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useColors } from '../../helpers/CustomHooks/useColors';

const AllTeamsModal = (props) => {
    const Colors = useColors();
  const {isOpen, toggle, callback, teams} = props

	return (
		<Modal isOpen={isOpen} toggle={toggle} size='lg'>
            <ModalBody style={{height:'calc(100vh - 70px)', overflow:'hidden'}}>
                {/* <div className='h-100 pb-3'> */}
                <div className='d-flex justify-content-end mb-2' style={{ cursor: 'pointer' }} onClick={toggle}>
                {cancelIcon()}
                </div>
                <PerfectScrollbar className='row h-100 pb-3' style={{height:'100%', overflowX:'hidden'}}>
                {/* <Row> */}
                {teams.map((team, index) => (
                    <Col key={index} className={"py-2 mx-auto text-center"} md={"3"}>
                    <div className="mx-auto" style={{ position: "relative", width:'70px' }}>
                        <div style={{ overflow: 'hidden', maxWidth: '70px', maxHeight: '100%' }}>
                        <img className='img-responsive rounded-circle my-1' src={team.teamImage} width='70px' height='70px' style={{ objectFit: 'cover' }} />
                        </div>
                        <p className='pointer' style={{ position: "absolute", bottom: -20, right: 0 }} onClick={() => callback(team.teamId)}>{arenaSettingsEditIcon()}</p>
                    </div>
                    <h6 className='m-0 bold' style={{ fontSize: 15 }}>{team.teamName}</h6>
                    <h6 className='m-0 small' style={{ color: Colors.textColorSecondary, fontSize: 13 }}>{team.leagueName}</h6>
                    </Col>
                ))}
                {/* </Row> */}
                </PerfectScrollbar>
                {/* </div> */}
            </ModalBody>
	    </Modal>
	);
};

export default AllTeamsModal;
