import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
// import { Colors } from '../../helpers/colors';
import { arenaLineupIcon, cancelIcon } from '../../helpers/icons';
import { useColors } from '../../helpers/CustomHooks/useColors';

function StarterModal(props) {
  const Colors = useColors();

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
      <ModalBody className='d-flex flex-column flex1' >
        <div className='d-flex justify-content-end' style={{ cursor: 'pointer' }} onClick={() => props.toggle()}>
          {cancelIcon()}
        </div>
        <div className='d-flex flex-column flex1'>
          <div className='pt-2' style={{ aspect: 1, width: 100, height: 100 }}>{arenaLineupIcon()}</div>
          <h1 className='bold flex-grow-1' style={{ color: Colors.colorPrimary }}>Please confirm <br /> your starters</h1>
          <p>You are currently using yesterday's lineup by default. Now it is time to make changes to for today.</p>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default StarterModal;