import React from 'react';
import { Button, UncontrolledCollapse, Card, CardBody } from 'reactstrap';
import { Colors } from '../../../../helpers/colors';
import PerfectScrollbar from 'react-perfect-scrollbar';

function MultipleAccounts(props) {
  return (
    <div className='d-flex flex-column flex1 scrollHidden'>
      {props.slug == "MatchMakerRules" ?
        <>
          <h5 className='bold' style={{ fontSize: 20 }}>Multiple Accounts & Suspicious Activity</h5>
          <div className='ml-3'>
            <div>
              <p>An individual user is permitted to maintain one account on Bettle. <span style={{ color: 'red' }}>“Multi-accounting”</span> is expressly prohibited. Users who have opened, maintained, and used more than one account may be terminated or suspended and may Bettle revoke or withhold any prizes won or are eligible to win.</p>
            </div>
            <div>
              <p>Furthermore, if you undertake any actions that are detrimental to Bettle or other users on Bettle, we may suspend your account. For accounts that have been suspended and wish to be restored, <span style={{ color: Colors.colorPrimary }}>please contact support@bettle.co.</span></p>
            </div>
          </div>
        </>
        :
        <>
          <div className='d-flex justify-content-start align-items-center px-3 my-2' style={{ borderBottom: `1px solid ${Colors.darkBgBorder}`, cursor: 'pointer' }} onClick={() => props.setView('default')}>
            <h5><span> <i className='mr-2 fa fa-angle-left'></i></span>Multiple Accounts & Suspicious Activity</h5>
          </div>
          <PerfectScrollbar>
            <Card className='border-0 mb-0 mx-3' style={{ background: Colors.bgLight }}>
              <CardBody>
                <h5 className='bold' style={{ fontSize: 20 }}>Multiple Accounts & Suspicious Activity</h5>
                <div>
                  <p>An individual user is permitted to maintain one account on Bettle. <span style={{ color: 'red' }}>“Multi-accounting”</span> is expressly prohibited. Users who have opened, maintained, and used more than one account may be terminated or suspended and may Bettle revoke or withhold any prizes won or are eligible to win.</p>
                </div>
                <div>
                  <p>Furthermore, if you undertake any actions that are detrimental to Bettle or other users on Bettle, we may suspend your account. For accounts that have been suspended and wish to be restored, <span style={{ color: Colors.colorPrimary }}>please contact support@bettle.co.</span></p>
                </div>
              </CardBody>
            </Card>
          </PerfectScrollbar>
        </>
      }
    </div>
  )
}

export default MultipleAccounts;