import { words } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Colors } from './colors';
import { linkify } from './common-methods';

const IDENTIFIERS = {
    MENTION: '@[',
    USERNAME_START: '[',
    USERNAME_END: ']',
    ID_START: '(',
    ID_END: ')',
    MENTION_START: '@',
    MENTION_END: ')'
}

/**
 * Parse the message to mark all the mentions clickable inside chat
 * @param {string} message - The Message to be shown in chat
 * @returns The parsed message with all the mentions inside
 */
export const parseMessage = (message, history) => {
    if (message && message.includes(IDENTIFIERS.MENTION)) {
        let mentions = organizeMentions(parseMentions(message))
        return createMessageWithActionableMentions(message, mentions, history)
    }
    else {
        return message
    }
}

/**
 * Generate a message with formatted mentions with ability to be parsed on both sides
 * @param {string} message - a raw string containing mentions
 * @param {array} users - and array of onjects containing user details that might be in mentions
 * @returns formatted message with actionable mentions
 */
export const formatMentions = (message, users) => {
    let words = breakMessage(message)
    let parsedMessage = generateMentions(words, users)
    return parsedMessage
}

const breakMessage = (message) => {
    message = message.split(/(\s+)/) //Split the message but keep spaces 
    return message
}

const generateMentions = (words, users) => {
    let message = ''
    words.map((word, index) => {
        // console.log(word);
        if (word[0] == '@') {
            // console.log(word);
            let user = Object.values(users).find(user => user.name == word.replace('@', ''))
            if (user) {
                words[index] = IDENTIFIERS.MENTION_START + IDENTIFIERS.USERNAME_START + user.name + IDENTIFIERS.USERNAME_END + IDENTIFIERS.ID_START + user.id + IDENTIFIERS.ID_END
            }
        }
    })
    message = words.join('')
    return message
}

/**
 * Parse all the mentions from message as strings
 * @param {string} message - message to be checked for mentions
 * @param {object} mentions - array of all the mentions  found inside the message
 * @returns array of mentions as string
 */
const parseMentions = (message, mentions = []) => {
    let mention = message.split(IDENTIFIERS.MENTION_START).pop().split(IDENTIFIERS.MENTION_END)[0] // Extract the first found mention
    // If the mention was found execute the below lines
    if (mention.includes(IDENTIFIERS.USERNAME_START)) {
        message = message.replace(IDENTIFIERS.MENTION_START + mention + IDENTIFIERS.MENTION_END, '') // Remove the found mention from the message
        mentions = [...mentions, mention] // Append the found mention to all found mentions
        return parseMentions(message, mentions) // Execute recursively untill the message has no more mentions
    }
    else {
        return mentions // Return all the found mentions
    }
}

/**
 * Organize the separated mentions as array of username and IDs
 * @param {object} mentions - array of all the mentions as individual objects with username and IDs sepated
 * @returns array
 */
const organizeMentions = (mentions) => {
    let user = mentions.map(mention => {
        return {
            username: mention.substring(mention.indexOf("[") + 1, mention.lastIndexOf("]")),
            id: mention.substring(mention.indexOf('(') + 1,)
        }
    })
    return user
}

const createMessageWithActionableMentions = (message, mentions, history) => {
    let found = ''
    message = message.split(/(\s+)/) //Split the message and keep spaces
    message.map((substr, index) => {
        if (substr.includes(IDENTIFIERS.MENTION)) {
            found = mentions.find(mention => mention.username == substr.split(IDENTIFIERS.USERNAME_START).pop().split(IDENTIFIERS.USERNAME_END)[0])
            if (found) {
                message[index] = <span style={{ color: Colors.colorLink, cursor: "pointer" }} onClick={() => history.push('/chat/user/' + found.id)}>@{found.username}</span>
            }
        }
    })
    return message
}


/**
 * Navigate to a 1-to-1 chat of user with ID passed
 * @param {string} id - ID of the user to navigate to
 * @param {object} history - navigator helper
 */
const navigateToChat = (id, history) => {
    history.push(`/chat/user/${id}`);
};

/**
 * Generate a message with actionable mentions
 * @param {object} data - message data
 * @param {string} authId - ID of the user currently authenticated
 * @param {object} history - navigation helper to create actionable links in mentions
 * @param {jsx} message - the actual message after mentions parsing
 * @returns jsx
 */
export const generateMessage = (data, authId, history, message) => {

        if(data.winnerId == authId){
            return  <p> {message.winner} {createMention({ id: data.looserId, name: data.looser, authId: authId, history: history })}</p>
        }
        else if(data.looserId == authId) {
            return  <p> {message.loser} {createMention({ id: data.winnerId, name: data.winner, authId: authId, history: history })}</p>
        }
        else {
            return <p> {createMention({ id: data.looserId, name: data.looser, authId: authId, history: history })} {message.generic} {createMention({ id: data.winnerId, name: data.winner, authId: authId, history: history })}</p>
        }

};

/**
 * Create mention with actionable link
 * @param {Object} teamData - Data realted to the team to link the mention
 * @returns jsx with navigatable link
 */
const createMention = (teamData) => {
    return (
        <span
            style={{ color: Colors.colorLink, cursor: teamData.authId != teamData.id && 'pointer' }}
            onClick={() => teamData.authId != teamData.id && navigateToChat(teamData.id, teamData.history)}>
            @{teamData.name}
        </span>
    );
};


/**
 * Find the user who reacted in case message is of type reaction
 * @param {Object} reactionData - object of reactions with users under each reaction
 * @param {string} windowType - user or group (can be multiple users in case of group)
 * @param {Object} users - Users list to generate namestamps from
 * @param {string} currentTeamId - ID of current user to exclude them from final results
 * @returns string/array of strings in case of user/group vice verse
 */
export const reactedBy = (reactionsData, windowType, users, currentTeamId) => {
    let other_team = Object.values(reactionsData).map(reactions => {
        return Object.keys(reactions.users).filter(user => user != currentTeamId)
    })

    let reacted_by = 'Someone'

    switch (windowType) {
        case 'user':
            // alert(other_team[0])
            reacted_by = users[other_team] && users[other_team[0]] ? users[other_team[0]].team_name : reacted_by
            return reacted_by
        case 'group':
            other_team = [].concat.apply([], other_team) // Flatten the array of arrays of IDs
            let final_teams = distinct(other_team) // Remove duplicated IDs in the array

            // let final_teams = distinct([].concat.apply([], other_team)) // This line is same as above 2 lines combined
            let others = final_teams.length == 1 ? '' : final_teams.length == 2 ? ' & 1 other' : ` & ${(final_teams.length - 1)} others`

            reacted_by = users[final_teams[0]] ? users[final_teams[0]].team_name : reacted_by
            return reacted_by + others
        default:
            console.log('Invalid Window Type: ' + windowType);
    }
}

const distinct = (redundantArray) => {
    let uniqueArray = redundantArray.filter(makeUnique)
    return uniqueArray
}

function makeUnique(value, index, self) {
    return self.indexOf(value) === index;
};

export const messageUrlPreviewHandler = (message, attachments) => {
    let links = message.split(/(\s+)/); // split and keep spaces
    let providerName = Array.isArray(attachments[0]) && attachments[0].data.map(data => { return data.providerName }); // get [0] providers name
    if(providerName){
        links.map((link, index) => {
            if (providerName.some(name => link.includes(name))) {
                // console.log('link: ', link);
                links[index] = <a href={link} target="_blank" style={{ color: Colors.colorLink, cursor: 'pointer' }}>{link}</a>
            };
        });
    }
    return links;
};

/**
 * Check if the current league is from the enabled leagues or not.
 * @param {Object} currentLeague - curently active league data
 * @param {Object} enabledLeagues - all leagues to match the active leagues data
 * @returns boolean
 */
export const featureIsEnabled = (currentLeagueId, enabledLeagues) => {
    if(enabledLeagues.some(league => league.id == currentLeagueId)) {
        return true
    }
    return false
}

export const makeUrlClickable = (message, urls) => {
    let links = message.split(/(\s+)/); // split and keep spaces
    links.map((link, index) => {
        if (Array.isArray(urls) && urls.some(url => link.includes(url))) {
            links[index] = <a href={link} target="_blank" style={{ color: Colors.colorLink, cursor: 'pointer' }}>{link}</a>
        }
    });
    return links;
}

export const detectUrls = (message) => {
    let urls = [];
    let urlIdentifiers = ['https', 'http', ''];
    if (message && urlIdentifiers.some(identifier => message.includes(identifier))) {
        const links = linkify(message);
        urls = makeUrlClickable(message, links);
    } else {
        urls = parseMessage(message);
    }
    return urls;
};
