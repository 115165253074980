
import React, { Component, useContext } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { arenaHome, checkTeamStatus, hasChallengeMe, hideChallengeMe, switchTeam, updateTeams } from '../apis/Arena/MatchMakerApis';
import ArenaBody from '../components/V3/Arena/ArenaBody';
import ArenaRightSidebar from '../components/V3/Arena/ArenaRightSidebar';
import Sidebar from '../components/V3/Sidebar';
import { errorMessage } from '../helpers/common-methods';
import ArenaOnboardModal from '../modals/Arena/ArenaOnboardModal';
import store from '../store';
import { Button } from 'reactstrap';
import { ColorContext } from '../context/ColorProvider';
import { _ } from 'core-js';
import ArenaTimeframeModal from '../modals/Arena/ArenaTimeframeModal';
import { currentTimeframe } from '../helpers/Arena/ArenaHelper';
import AcceptChallengeModal from '../modals/Arena/AcceptChallengeModal';

class NewHome extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 1,
            currentSport: 'NFL', // [TODO]
            startOnboarding: false,
            onboardingTeamId: null,
            arenaData: [],
            modal: false,
            disableButton: false,
            arenaHomeData: {
                teams: [],
                teamsOrder: [],
            },
            timeFrame: false,

            loading: true,
            default_active_team: {
                "teamId": "all",
                "teamName": "ALL",
                "leagueName": "View All Teams Data",
                "pendingCount": 0,
                "confirmedMatchCount": 0,
              },
            challengerData: null,
            acceptChallengeModal: false,
            updateTeamsLoading: false
        }
        
        this.startOnboarding = this.startOnboarding.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.onboardingComplete = this.onboardingComplete.bind(this);
        this.toggleTimeFrameModal = this.toggleTimeFrameModal.bind(this);
        this.timeFrameSelected = this.timeFrameSelected.bind(this);
        this.prepareForOnboarding = this.prepareForOnboarding.bind(this);
        this.redirectToOnboarding = this.redirectToOnboarding.bind(this);
    }

    static contextType = ColorContext;

    callArenaHomeApi(){
        arenaHome({sport: this.props.sport}) // [TODO]
        .then((response) => {
            if(response.data.defaultTeam){
                // store.dispatch({ type: 'SET_ACTIVE_TEAM', active_team: response.data.defaultTeam });
                store.dispatch({ type: 'SAVE_ARENA_HOME_DATA', payload: response.data });
            }
            else {
                // Edge case if users is an old user  and had teams
                // but the teams are now archived and there is 
                //no team left to set default.
                store.dispatch({ type: 'SET_ACTIVE_TEAM', active_team: this.state.default_active_team });
            }
            this.setState({
                arenaHomeData: response.data,
                loading: false
            })
        })
        .catch((error) => {
            errorMessage("Something went wrong")
            console.log(error)
        })
    }


    componentDidMount(){
        this.setArenaData()
        hasChallengeMe().then((resp) => {
            if(resp.hasCm){
                this.setState({
                    challengerData: {fromId: resp.fromId, challenge_sport: this.props.sport},
                    acceptChallengeModal: !this.state.acceptChallengeModal
                })
                hideChallengeMe().catch(() => {
                    console.log("something wong in hiding challenge me")
                })
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.startOnboarding && !prevProps.startOnboarding){
            this.prepareForOnboarding(this.props.active_team.teamId);
        }
    }

    /**
     * If active team is not set then it is first mount
     * we need to call api to get the data
     * else the required data is already in store and can be reused
     */
    setArenaData() {
        const { active_team, all_teams, teamsOrder } = store.getState().arenaReducer;
        const { forceRefresh } = this.props;

        if(_.isEmpty(all_teams) || forceRefresh){
            forceRefresh && store.dispatch({ type: 'FORCE_REFRESH_ARENA_DATA', payload: false})
            this.callArenaHomeApi()
        }
        else {
            const arenaData = {
                teams: all_teams,
                teamsOrder: teamsOrder,
                defaultTeam: active_team
            }
            this.setState({
                arenaHomeData: arenaData,
                loading: false
            })
        }
    }

    startOnboarding(teamId, sport =  this.props.sport, requireLineupSelect = false, timeFrame = undefined) {
        
        this.setState({
            disableButton: true
        })

        checkTeamStatus({teamId, sport, timeFrame })    
            .then(response => {
                    if(response.data.entered) {
                        this.switchTeamAndRedirect(teamId)
                    }
                    else {
                        this.setState({
                            arenaData: [response.data],
                            startOnboarding: !response.data.entered,
                            onboardingTeamId: teamId,
                            modal: !response.data.entered
                        })
                    }
                this.setState({
                    disableButton: false
                })
            })
            .catch(error => {
                this.setState({
                    disableButton: false
                })
                console.log(error);
            })
    }

    switchTeamAndRedirect(teamId) {
        switchTeam({teamId, sport: this.props.sport})
            .then((response) => {
                this.props.history.push('/arena/home')
            })
            .catch(error => {
                console.log(error);
            })
    }


    resetState() {
        this.setState({
            step:1,
            startOnboarding: false,
            onboardingTeamId: null,
            arenaData: [],
            modal: false,
            disableButton: false,
        })
    }

    updateStep(step) {
        this.setState({
            step: step
        })
    }

    toggleModal() {
        if(this.state.modal) {
            this.resetState()
        }
        else {
            this.setState({
                modal: !this.state.modal
            })
        }
    }

    toggleTimeFrameModal() {
        this.setState({
            timeFrame: !this.state.timeFrame
        })
    }

    onboardingComplete(onboardingData, teamId) {
        this.setState({ updateTeamsLoading: true });
        onboardingData["sport"] = this.props.sport;
        onboardingData["timeFrame"] = currentTimeframe()
        
        updateTeams(onboardingData)
        .then(response => {
                this.switchTeamAndRedirect(teamId)
                this.setState({ updateTeamsLoading: false });
            })
            .catch(error => {
                console.log(error);
            })

        // this.resetState()
        // TODO: Redirect to arena
    }

    timeFrameSelected(requireLineupSelection = true, timeFrame = null) {
        // this.setState({
        //     timeFrame: false
        // })
        let teamId = this.state.onboardingTeamId;

        if(teamId == 'all') {
            const { active_team } = store.getState().arenaReducer;
            teamId = active_team.teamId;
        }
        this.startOnboarding(teamId, this.props.sport, requireLineupSelection, timeFrame)
    }

    prepareForOnboarding(teamId) {
        this.setState({
            timeFrame: true,
            onboardingTeamId: teamId
        })
        store.dispatch({ type: 'SET_START_ONBOARDING', payload: false });
    }

    redirectToOnboarding(teamId) {
        this.props.history.push(`/arena/onboarding/${teamId}`)
    }

    render() { 
        const Colors = this.context;
        return ( 
            <div className='flex1 d-flex flex-grow-1 scrollHidden' >
                <div className='d-flex flex1 flex-column scrollHidden'  >
                <ArenaBody
                    arenaHomeData={this.state.arenaHomeData}
                    handleOnboarding={this.prepareForOnboarding}
                    disableOnboarding={this.state.disableButton}
                    sport={this.props.sport}
                    sportEnabled={sportEnabled[this.props.sport]}
                    showCompletedMatches={this.props.showCompletedMatches}
                />
                </div>
                <div style={{height: "100vh", backgroundColor:Colors.sidebarBody_v3, width:'32.88%', minWidth:'250px'}}>
                    <ArenaRightSidebar handleOnboarding={this.prepareForOnboarding} disableOnboarding={this.state.disableButton} sport={this.props.sport} sportEnabled={sportEnabled[this.props.sport]} showProposal={this.props.showProposal} />
                </div>
                { this.state.startOnboarding && <ArenaOnboardModal isOpen={this.state.modal} toggle={this.toggleModal} teamId={this.state.onboardingTeamId} arenaData={this.state.arenaData} callBack={this.onboardingComplete} sport={this.props.sport} updateTeamsLoading={this.state.updateTeamsLoading} />  }
                { this.state.timeFrame && <ArenaTimeframeModal modal={this.state.timeFrame} toggle={this.toggleTimeFrameModal} teamId={this.state.onboardingTeamId} arenaData={this.state.arenaData} callBack={this.timeFrameSelected} sport={this.props.sport} lineupModalState={this.state.modal} /> }
                {this.state.acceptChallengeModal && <AcceptChallengeModal
                isOpen={this.state.acceptChallengeModal}
                toggle={() => this.setState({acceptChallengeModal: !this.state.acceptChallengeModal})}
                arenaData={this.props.arenaData}
                challengerData={this.state.challengerData}
                /> }
            </div>
         );
    }
}
 
const mapStateToProps = (state) => ({sport: state.arenaReducer.sport, forceRefresh: state.arenaReducer.forceRefresh, startOnboarding: state.arenaReducer.startOnboarding, active_team: state.arenaReducer.active_team, selectedTimeframe: state.arenaReducer.selectedTimeframe})
export default connect(mapStateToProps) (withRouter(NewHome));

const sportEnabled = {
    'NFL': true,
    'NBA': true,
    'MLB': true,
}