import React, { Component, useEffect, useState } from 'react';
// import '../assets/css/webflow.css';
// import '../assets/css/bettle.webflow.css';
import DocumentTitle from 'react-document-title';
import PlayFieldSVG from '../assets/images/Play-the-Field.svg';
import BettleLogoLandscape from '../assets/images/logo_trans.png';
import BettleTranscode1 from '../assets/videos/Bettle-transcode.mp4';
import BettleTranscode2 from '../assets/videos/Bettle-transcode.webm';
import PhonesWScreens from '../assets/images/Phones-w-Screens-p-500.png';
import ESPN from '../assets/images/ESPN_wordmark.svg';
import YAHOO from '../assets/images/yahoo_fantasy_en-US_h_w_fantasyv2_2.png';
import sleeper from "../assets/images/sleeper.png"
import GreenStates from '../assets/images/Green-States.svg';
import BlackStates from '../assets/images/Black-States-01.svg';
import Twitter from '../assets/images/Twitter-logo.svg';
import Instagram from '../assets/images/Instagram_logo_2022.svg';
import Discord from '../assets/images/Discord_logo.svg';
import LinkedIn from '../assets/images/LinkedIn_Logo.svg';
import androidLogo from '../assets/images/android.png';
import iosLogo from '../assets/images/ios.png';
import parallaxBackground from '../assets/images/Cheer-BW.jpg'
import Swiper from '../assets/js/Swipe.json';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { useLocation } from 'react-router-dom';
import FantasyPros from '../assets/images/fantasy_pros_logo.png';
import Lottie from 'react-lottie';
import { Col, Row } from 'reactstrap';

const Landing2022 = (props) => {
    const location = useLocation();

    useEffect(() => {
    
        window.addEventListener('scroll', highlightGreenStatesOnScroll);

        return () => {
            window.removeEventListener('scroll', highlightGreenStatesOnScroll);
        };
    }, []);

    const highlightGreenStatesOnScroll = () => {
        var h = document.documentElement,
            b = document.body,
            st = 'scrollTop',
            sh = 'scrollHeight';
        let scrollPercentage = ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100);
        let greenStates = document.getElementById('green-states');
        if(greenStates) {
            greenStates.style.opacity = ((scrollPercentage - 70) * 5 / 100) + 0.35
        }
    };

    const togglePromo = () => {
        let promoWebHover = document.getElementById('promo-hover');
        let promoMobileHover = document.getElementById('promo-hover-m');
        let promoMobileDefault = document.getElementById('promo-default-m');

        promoWebHover.classList.toggle('d-none');

        if(promoMobileHover.classList.contains('d-none')) {
            promoMobileHover.classList.add('d-flex');
            promoMobileHover.classList.remove('d-none');
            promoMobileDefault.classList.toggle('d-none');
            promoMobileDefault.classList.remove('d-flex');
        } else {
            promoMobileHover.classList.remove('d-flex');
            promoMobileHover.classList.add('d-none');
            promoMobileDefault.classList.remove('d-none');
            promoMobileDefault.classList.add('d-flex');
        }
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Swiper,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <ParallaxProvider>
            <DocumentTitle title={`Bettle Fantasy Sports 2023`}>
                <div>

                    <div className='d-md-none'>
                        {/* MOBILE EXPANDED */}
                        <div id='promo-hover-m' data-w-id="a2a99da7-8c4c-d8de-c46a-e9cb36bd5dad" className="promo-mobile open d-none" onClick={togglePromo}>
                            <div className="div-block-21">
                                <div className="text-block-6">GET $25</div>
                            </div>
                            <div className="div-block-22">
                                <div className="d-flex flex-column align-items-center">
                                    <div style={{fontSize: ".75rem", lineHeight: "16px"}} className="">Sign up and get $25 bonus which will be deposited into your Bettle Wallet and is eligible for use in your next match(es).</div>
                                    <div style={{ height: "40px", borderRadius:25, color:'#06F19E', background:'linear-gradient(180deg, rgba(22,163,113,1) 0%, rgba(18,19,18,1) 100%)'}} className="d-block responsive pointer m-0 pt-2 mt-2 px-4" onClick={() => props.history.push("/register/?viaPromoLink=1")}>
                                        <div style={{fontSize: "14px"}} className="button-text p-0">GET STARTED</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* !MOBILE EXPANDED */}

                        {/* MOBILE DEFAULT */}
                        <div id='promo-default-m' data-w-id="39637e9e-7921-ec9d-96ba-da8d441f66ae" className="promo-mobile default" onClick={togglePromo}>
                            <div className="div-block-21">
                                <div className="text-block-6">GET $25</div>
                            </div>
                        </div>
                        {/* !MOBILE DEFAULT */}
                    </div>
                    {/* WEB DEFAULT */}
                    <div id='promo-default' className="promo default" onClick={togglePromo}>
                        <div className="contain-promo">
                            <div className="text-block-3"><strong className="bold-text">Get $25</strong></div>
                        </div>
                        <div className="contain-details default"></div>
                    </div>
                    {/* !WEB DEFAULT */}


                    {/* WEB EXAPNDED */}
                    <div id='promo-hover' className="promo hover d-none" onClick={togglePromo}>
                        <div className="contain-promo">
                            <div className="text-block-3"><strong className="bold-text">Get $25</strong></div>
                        </div>
                        <div className="contain-details hover">
                            <div className="div-block-19"></div>
                            <div className="div-block-20 d-flex align-items-center">
                                <div style={{fontSize: ".75rem", lineHeight: "16px"}} className="text-block-5 flex1">Sign up and get $25 bonus which will be deposited into your Bettle Wallet and is eligible for use in your next match(es).</div>
                                <div style={{ height: "40px", borderRadius:25, color:'#06F19E', background:'linear-gradient(180deg, rgba(22,163,113,1) 0%, rgba(18,19,18,1) 100%)'}} className="responsive pointer m-0 pt-2 mt-2 px-3 ml-2" onClick={() => props.history.push("/register/?viaPromoLink=1")}>
                                    <div style={{fontSize: "14px"}} className="button-text p-0">GET STARTED</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* !WEB EXPANDED */}
                    <div className="hero wf-section">
                        <div className="hero-animation">
                            <Lottie options={defaultOptions} />
                        </div>
                        <div className="hero-text">
                            {/* Bettle x Fantasy Pros Mobile */}
                            <div className='my-auto d-md-none'>
                            {
                                location.pathname.includes('fantasypros') &&
                                <div className='d-flex align-items-center'>
                                    <img src={BettleLogoLandscape} loading="lazy" height="40" alt="" />
                                    <div>
                                        <i class="fa fa-times mx-2" aria-hidden="true"></i>
                                    </div>
                                    <img src={FantasyPros} loading="lazy" height="35" alt="" />
                                </div>
                            }
                            </div>
                            {/* Bettle x Fantasy Pros Mobile */}
                            <img src={PlayFieldSVG} loading="lazy" width="635" alt="" className="image" />
                            <div className="button outline mobile" onClick={() => props.history.push("/register/?viaPromoLink=1")}>
                                <div className="button-text outline">Import Your Team</div>
                            </div><img src={BettleLogoLandscape} loading="lazy" width="188" alt="" className="image-2" />
                        </div>
                        <div className="div-block-8">
                            <div className="div-block-9">
                                <img src={BettleLogoLandscape} loading="lazy" width="180" alt="" />
                                {
                                    location.pathname.includes('fantasypros') &&
                                    <div className='d-none d-md-flex align-items-center'>
                                        <div>
                                            <i class="fa fa-times mx-2" aria-hidden="true"></i>
                                        </div>
                                        <img src={FantasyPros} loading="lazy" height="35" alt="" />
                                    </div>
                                }
                            </div>
                            <div className="div-block-10">
                                {/* <div className="button outline responsive" onClick={() => props.history.push("/register/?viaPromoLink=1")} style={{ cursor: 'pointer' }}>
                                    <div className="button-text outline">Import Your Team</div>
                                </div> */}
                                <div className="button" onClick={() => props.history.push("/signin")} style={{ cursor: 'pointer' }}>
                                    <div className="button-text text-dark">Login</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    <div className="content _1 wf-section">
                        <div className="container-main">
                            <div className="container-50 content1">
                                <div className="container-text">
                                    <h3 className="heading content1">Challenge or be challenged</h3>
                                    <p className="paragraph">Import your season-long lineup to challenge teams across our entire network to weekly matches for real money. So go ahead – take the leap and put yourself out there.</p>
                                    <div>
                                        <a target={'blank'} href="https://play.google.com/store/apps/details?id=com.bettle.bettlelive&hl=en_US&gl=US">
                                            <img src={androidLogo} alt="" />
                                        </a>
                                        <a target={'blank'} href="https://apps.apple.com/us/app/bettle-fantasy-sports/id1591274931">
                                            <img src={iosLogo} alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='d-md-none d-sm-block' style={{marginTop:70}}>
                                <div className="image-contain top">
                                    <div className="div-block-5"></div>
                                    <div data-autoplay="true" data-loop="true" data-wf-ignore="true" className="background-video-2 w-background-video w-background-video-atom">
                                        <video id="54b127ce-1440-a95b-7303-3714ce03db97-video" autoPlay="true" loop muted playsInline data-wf-ignore="true" data-object-fit="cover">
                                            <source src={BettleTranscode1} data-wf-ignore="true" />
                                            <source src={BettleTranscode2} data-wf-ignore="true" />
                                        </video>
                                    </div>
                                </div>
                            </div>
                            <div className="image-contain d-sm-none d-md-flex top">
                                <div className="div-block-5"></div>
                                <div data-autoplay="true" data-loop="true" data-wf-ignore="true" className="background-video-2 w-background-video w-background-video-atom">
                                    <video id="54b127ce-1440-a95b-7303-3714ce03db97-video" autoPlay="true" loop muted playsInline data-wf-ignore="true" data-object-fit="cover">
                                        <source src={BettleTranscode1} data-wf-ignore="true" />
                                        <source src={BettleTranscode2} data-wf-ignore="true" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='content _2'>
                    <ParallaxBanner className="m-0 p-0 aspect-[2/1]">
                            <ParallaxBannerLayer speed={-25}>
                                <img src={parallaxBackground} style={{ opacity: '0.1' }} />
                            </ParallaxBannerLayer>
                            <ParallaxBannerLayer>
                                <div className='container' >
                                    <Row >
                                        <Col xs="12" md="6" className="image-contain">
                                            <img src={PhonesWScreens} alt="Level Up Your Fantasy" style={{ width: '70%', marginBottom: -70 }} />
                                        </Col>
                                        <Col xs="12" md="6" className="container-text my-auto">
                                            <h3 className="heading content2">Level up your fantasy league</h3>
                                            <p className="paragraph">Bettle is the only social-first fantasy app designed to seamlessly integrate with your season long fantasy league to offer intra-league contests for real money and a richer chat experience.</p>
                                            <div className="row">
                                                <div className="col-md-4 col-12 my-md-0 my-3"><img src={YAHOO} loading="lazy" width="190" alt=""></img></div>
                                                <div className="col-md-4 col-12 my-md-0 my-3 text-center"><img src={ESPN} loading="lazy" width="108" alt="" /></div>
                                                <div className="col-md-4 col-12 my-md-0 my-3"><img src={sleeper} loading="lazy" width="190" alt="" /></div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                
                            </ParallaxBannerLayer>
                        </ParallaxBanner>
                    </div>
                    {/* <div className="content _2">
                        <ParallaxBanner className="m-0 p-0 aspect-[2/1]">
                            <ParallaxBannerLayer speed={-25}>
                                <img src={parallaxBackground} style={{ opacity: '0.1' }} />
                            </ParallaxBannerLayer>
                            <ParallaxBannerLayer>
                                <div className='container h-100 d-flex align-items-center' >
                                    <div className="image-contain">
                                        <img src={PhonesWScreens} alt="Level Up Your Fantasy" style={{ width: '70%', marginBottom: -70 }} />
                                    </div>
                                    <div className="container-text">
                                        <h3 className="heading content2">Level up your fantasy league</h3>
                                        <p className="paragraph">Bettle is the only social-first fantasy app designed to seamlessly integrate with your season long fantasy league to offer intra-league contests for real money and a richer chat experience.</p>
                                        <div className="div-block-12">
                                            <div className="div-block-11"><img src={YAHOO} loading="lazy" width="160" alt="" className="image-6"></img></div>
                                            <div><img src={ESPN} loading="lazy" width="108" alt="" /></div>
                                        </div>
                                    </div>
                                </div>
                            </ParallaxBannerLayer>
                        </ParallaxBanner>
                        <div data-w-id="d76ac37b-6e16-2747-6d0f-5afc964c14a9" className="div-block-7"></div>
                    </div> */}

                    <div className="content _3 wf-section">
                        <div className="container-main content3">
                            <div className="container-50 content3 states">
                                <div className="container-text content3 responsive">
                                    <h3 className="heading content3">Licensed in over 20 states</h3>
                                    <p className="paragraph">With more states to come!</p>
                                    <div className="container-states">
                                        <div className="container-6">
                                            <div className="state-name">Arkansas</div>
                                            <div className="state-name">California</div>
                                            <div className="state-name">Colorado</div>
                                            <div className="state-name">Connecticut</div>
                                            <div className="state-name">Georgia</div>
                                            <div className="state-name">Illinois</div>
                                            <div className="state-name">Florida</div>
                                        </div>
                                        <div className="container-6">
                                            <div className="state-name">Kansas</div>
                                            <div className="state-name">Kentucky</div>
                                            <div className="state-name">Maine</div>
                                            <div className="state-name">Massachusetts</div>
                                            <div className="state-name">Michigan</div>
                                            <div className="state-name">Minnesota</div>
                                            <div className="state-name">Nebraska</div>
                                        </div>
                                        <div className="container-6">
                                            <div className="state-name">North Carolina</div>
                                            <div className="state-name">North Dakota</div>
                                            <div className="state-name">Oklahoma</div>
                                            <div className="state-name">Oregon</div>
                                            <div className="state-name">Rhode Island</div>
                                            <div className="state-name">South Carolina</div>
                                        </div>
                                        <div className="container-6">
                                            <div className="state-name">Texas</div>
                                            <div className="state-name">Tenessee</div>
                                            <div className="state-name">Utah</div>
                                            <div className="state-name">West Virginia</div>
                                            <div className="state-name">Wisconsin</div>
                                            <div className="state-name">Wyoming</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-text content3 mobile">
                                    <h3 className="heading content3">Licensed in over 20 states</h3>
                                    <p className="paragraph">With more states to come!</p>
                                    <div className="container-states">
                                        <div className="container-6 _33">
                                            <div className="state-name">Arkansas</div>
                                            <div className="state-name">California</div>
                                            <div className="state-name">Colorado</div>
                                            <div className="state-name">Connecticut</div>
                                            <div className="state-name">Florida</div>
                                            <div className="state-name">Georgia</div>
                                            <div className="state-name">Illinois</div>
                                            <div className="state-name">Kansas</div>
                                            <div className="state-name">Kentucky</div>
                                        </div>
                                        <div className="container-6 _33">
                                            <div className="state-name">Maine</div>
                                            <div className="state-name">Massachusetts</div>
                                            <div className="state-name">Michigan</div>
                                            <div className="state-name">Minnesota</div>
                                            <div className="state-name">Nebraska</div>
                                            <div className="state-name">North Carolina</div>
                                            <div className="state-name">North Dakota</div>
                                            <div className="state-name">Oklahoma</div>
                                            <div className="state-name">Oregon</div>
                                        </div>
                                        <div className="container-6 _33">
                                            <div className="state-name">Rhode Island</div>
                                            <div className="state-name">South Carolina</div>
                                            <div className="state-name">Texas</div>
                                            <div className="state-name">Tenessee</div>
                                            <div className="state-name">Utah</div>
                                            <div className="state-name">West Virginia</div>
                                            <div className="state-name">Wisconsin</div>
                                            <div className="state-name">Wyoming</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="image-contain content3">
                                <img src={BlackStates} loading="lazy" alt="" className="image-4" />
                                <img src={GreenStates} id="green-states" loading="lazy" data-w-id="276ab168-ba7f-40ec-9b0a-ef3c03702cd5" alt="" className="image-5"></img>
                            </div>
                        </div>
                    </div>
                    <div className="section wf-section">
                        <div className="div-block-13">
                            <div className="div-block-14"><img src={BettleLogoLandscape} loading="lazy" width="137" alt="" /></div>
                            <div className="div-block-15">
                                <div className="div-block-16">
                                    <div className='my-4'>
                                        <a className="text-block">About Us</a> <br />
                                    </div>
                                    <div className='my-4'>
                                        <a className="text-block" target='blank' href='https://bettlesupport.zendesk.com/hc/en-us'>Help Center</a> <br />
                                    </div>
                                    <div className='my-4'>
                                        <a className="text-block">MatchMaker Rules</a> <br />
                                    </div>
                                    <div className='my-4'>
                                        <a className="text-block" target='blank' href='https://bettle.co/responsiblegaming'>Responsible Gaming Policy</a>
                                    </div>
                                </div>
                                <div className="div-block-17 d-flex align-items-center">
                                    <div className='pt-2 m-0'>
                                        <a target='blank' href="https://mobile.twitter.com/bettlefantasy">
                                            <img src={Twitter} loading="lazy" height="26" alt="" className="image-8" />
                                        </a>
                                    </div>
                                    <div className='pt-2 m-0'>
                                        <a target='blank' href="https://www.linkedin.com/company/bettleinc/">
                                            <img src={LinkedIn} loading="lazy" height="32" alt="" />
                                        </a>
                                    </div>
                                    <div className='pt-4 m-0'>
                                        <a target='blank' href="https://discord.gg/5jk2uUhEVq">
                                            <img src={Discord} loading="lazy" height="24" alt="" className="image-10" />
                                        </a>
                                    </div>
                                    <div className='m-0'>
                                        <a target='blank' href="https://www.instagram.com/bettlefantasy/?hl=en">
                                            <img src={Instagram} loading="lazy" height="32" alt="" className="image-9" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="line"></div>
                        <div className="div-block-18">
                            <div className="text-block-2">Please play responsibly and only bet what you can afford. If you or someone you know has a gambling problem, help is available. Call 1-800-GAMBLER or go to <a target="_blank" href="https://ncpgambling.org/">ncpgambling.org</a>. © 2021 - 2022 Bettle Inc. All Rights Reserved.<span className="text-span">
                                <a href="/privacy" classNameName='mx-1'>Privacy Policy</a>
                                <a href="/terms-and-conditions" classNameName='mx-1'>Terms &amp; Conditions</a></span></div>
                        </div>
                    </div>
                </div>
            </DocumentTitle>
        </ParallaxProvider>
    );
};

export default Landing2022;