import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { fetchReferralHistory } from '../apis/User/UserAPI'
import { LoaderCenter } from '../components/Loader'
// import { Colors } from '../helpers/colors'
import { rewardIcon } from '../helpers/icons'
import { useColors } from '../helpers/CustomHooks/useColors';

const InviteHistoryModal = (props) => {
    const Colors = useColors();
    const {isOpen, toggle} = props
    const [loading, setLoading] = useState(true)
    const [total, setTotal] = useState(0)
    const [completed, setCompleted] = useState(0)
    const [pending, setPending] = useState(0)
    const [users, setUsers] = useState([])

    useEffect(() => {
        callApi()
    }, [])

    const callApi = () => {
        fetchReferralHistory()
        .then(response => {
            setTotal(response.total)
            setCompleted(response.completed)
            setPending(response.pending)
            setUsers(response?.users)
            setLoading(false)
        })
        .catch(err => {
            console.log(err)
        })
    }

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader className='border-0'>
                <i onClick={toggle} class="cui-circle-x icons pointer modalClose"></i>
            </ModalHeader>
            {
                loading ?
                <LoaderCenter />
                :
                
            <ModalBody className='mt-2 px-0'>
                <h3 style={{fontFamily:'Fritch'}} className="text-center my-2"> Your Invite History </h3>

                <div className='d-flex text-center pb-3 mt-3 w-100 mx-0' style={{borderBottom: `5px solid ${Colors.bgDark}`}}>
                    <div className='flex1'>
                        <h4>${total}</h4>
                        <span style={{color: Colors.colorSecondary}}>Total Earned</span>
                    </div>

                    <div className='flex1'>
                        <h4>{completed}</h4>
                        <span style={{color: Colors.colorSecondary}}>Complete</span>
                    </div>

                    <div className='flex1'>
                        <h4>{pending}</h4>
                        <span style={{color: Colors.colorSecondary}}>Pending</span>
                    </div>
                </div>

                <div className='d-flex flex-column'>
                    {
                        users && users.map((user, index) => {
                            return (
                                <div key={index} className='d-flex align-items-center my-2 p-3' style={{borderBottom:`0.01em solid ${Colors.bgDark}`}}>
                                    <div style={{ overflow: 'hidden', maxWidth: '40px', maxHeight: '100%' }}>
                                        {rewardIcon()}
                                    </div>
                                    <div className='flex1'>
                                        <h5 className='ml-2 my-0'>{`${user.first_name} ${user.last_name}`}</h5>
                                        <span className='ml-2 font-italic' style={{color: Colors.colorSecondary}}>{user.status}</span>
                                    </div>
                                    <div>
                                        <h4 className='m-0' style={{color:Colors.colorPrimary}}>${user.reward}</h4>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </ModalBody>
            }
        </Modal>
    )
}

export default InviteHistoryModal