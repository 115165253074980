import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
// import { Colors } from '../../helpers/colors';
import { arenaIcon, arenaBackIcon } from '../../helpers/icons';
import { useColors } from '../../helpers/CustomHooks/useColors';

function EnterArenaModal(props) {
  const Colors = useColors();
  
  const handleSkip = () => {
    window.location.href = "/dashboard"
  }

  const handleSuccess = () => {
    window.location.href = "/arena/home"
  }

  return (
    <Modal isOpen={props.isOpen}>
      <ModalBody>
        <div className='d-flex flex-column flex1'>
          <div className='pt-2' style={{ aspect: 1, width: 100, height: 100 }}>{arenaIcon()}</div>
          <h5 className='bold'>Your lineup is set</h5>
          <h1 className='bold flex-grow-1' style={{ color: Colors.colorPrimary }}>You're now ready to <br /> enter the arena.</h1>
          <p>Hit the back button if you want to make more <br /> lineup adjustments</p>
          <div className='d-flex justify-content-between align-items-center px-1 mt-4'>
            <p style={{ cursor: 'pointer' }} onClick={handleSkip}>{arenaBackIcon()}</p>
            <Button
              className='mb-2 bold'
              style={{
                color: Colors.colorPrimary,
                backgroundColor: Colors.colorPrimaryTransparent,
                border: `1px solid ${Colors.colorPrimary}`,
                padding: '6px 23px 6px 23px'
              }}
              onClick={handleSuccess}
            >
              Enter!
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default EnterArenaModal;