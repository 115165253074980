import React, { useEffect } from 'react'
import { motion } from "framer-motion/dist/framer-motion"
import { connect, useSelector } from 'react-redux'
import background from '../../assets/images/field-sepia.jpg';
import { getInForage, removeInForage } from '../../helpers/common-methods'
import { useHistory, useLocation } from 'react-router-dom'
import { LoaderCenter } from '../../components/Loader'
import Media from 'react-media'
import NewClaimBody from './NewClaimBody'
import NewClaimHeader from './NewClaimHeader'
import { flexDirection } from '../NewUserOnboarding/animationValues';
import queryString from 'query-string';

const NewClaimTeamIndex = (props) => {
    const location = useLocation()
    let params = queryString.parse(location.search)
    const fromImport = params?.fromImport ? true : false
    const [step, setStep] = React.useState( fromImport ? "startClaim" : "default")
    const [loading, setLoading] = React.useState(true)
    const [hasClaimData, setHasClaimData] = React.useState(false)
    const [claimData, setClaimData] = React.useState({})
    const history = useHistory()
    const hasLeague = useSelector(state => state.leagueJoin.hasLeague)

    useEffect(() => {
        // CHECK IF USER IS TRYING TO CLAIM A TEAM
        getInForage('claimTeam')
        .then(async (val) => {
            await removeInForage('claimTeam')
            if(val) {
                setClaimData(val)
                setHasClaimData(true)
                setLoading(false)
            }else{
                history.push("/dashboard")
            }
        }).catch(async (err) => {
            // IN CASE SOME ERROR HAPPENS
            await removeInForage('claimTeam')
            history.push("/dashboard")
        })
    }, [])

    const updateStep = (step) => {
        setStep(step)
    }

    const getUserName = () => {
        let name = ''
        if (props.auth?.user) {
            name = props?.auth?.user?.first_name

        }
        return name
    }

    return (
            
            <Media query="(max-width: 767px)">
            {matches => matches ? (
                <motion.div 
                    className='d-flex flex-column flex1 scrollHidden d-md-none'
                    style={{background: "black"}}
                    // style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
                >
                    {loading ? <LoaderCenter/>  : (
                    <>
                        <NewClaimHeader hasLeague={hasLeague} claimData={hasClaimData} step={step} updateStep={updateStep} getUserName={getUserName} />
                        <NewClaimBody forMob={true} hasLeague={hasLeague} claimData={claimData} step={step} updateStep={updateStep} />
                    </>)}
                </motion.div>
            ) : (
            <motion.div 
                className='flex1 scrollHidden d-none d-md-flex'
                style={{background: "black"}}
                // style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
                animate={ "column" }
                transition={{ duration: 0.5 }}
                initial={false}
                variants={flexDirection}
            >
                {loading ? <LoaderCenter/>  : (
                    <>
                        <NewClaimHeader hasLeague={hasLeague} claimData={hasClaimData} step={step} updateStep={updateStep} getUserName={getUserName} />
                        <NewClaimBody hasLeague={hasLeague} claimData={claimData} step={step} updateStep={updateStep} />
                    </>
                )}
            </motion.div>
            )}
            </Media>
                
    )
}

const mapStateToProps = (state) => ({ auth: state.auth });
export default connect(mapStateToProps, null) (NewClaimTeamIndex)