import React from 'react'
import { Button } from 'reactstrap'
// import { Colors } from '../../../helpers/colors'
import { STYLES } from '../../../helpers/stylesHelper';
import { toastError, toastSuccess } from '../../../helpers/toasts';
import { acceptSidebet, cancelSidebet, rejectSidebet } from '../../../apis/Sidebet/SidebetAPI';
import { LoaderCenter } from '../../../components/Loader';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'react-loaders';
import { useColors } from '../../../helpers/CustomHooks/useColors';

const BetProposals = (props) => {
    const Colors = useColors();
     // Accept sidebet as a receiver
     const accept = (ch_id) => {
        let reqData = {
            cId: ch_id,
            league_id: props.leagueId,
            notifyByMsg: true
        }
        acceptSidebet(reqData)
            .then(response => {
                props.refreshView()
            })
            .catch(({message}) => {
                toastError(message)
            })
    }

    // Delete the sidebet as a sender
    const deleteSidebet = (ch_id) => {
        let reqData = {
            ch_id: ch_id,
            mob: true
        }
        cancelSidebet(reqData)
            .then(response => {
                props.refreshView()
            })
    }

    // Reject a sidebet as a receiver
    const reject = (ch_id) => {
        let reqData = {
            ch_id: ch_id,
            mob: true
        }
        rejectSidebet(reqData)
            .then(response => {
                props.refreshView()
            })
            .catch(({message}) => {
                toastError(message)
            })
    }

  return (
      <>
        {
            props.laoding ? 
            <LoaderCenter/>
            :
            <tbody>
                {
                    props.data && props.data.pending_challenges.map((challenge, index) => {
                        return (
                                <tr style={{ borderBottom: `0.01em solid ${Colors.darkBgBorder}` }} key={challenge.ch_id}>
                                    <td className="align-middle bold">{index}</td>
                                    <td className="align-middle">
                                        <div className='d-flex flex-column'>
                                            <div className='d-flex align-items-center my-1'>
                                                <div className='rounded-circle mr-2' style={{width:40, height:40, border: `2px solid ${Colors.colorPrimary}`}}>
                                                    <img className="img-fluid m-auto p-1" src={challenge.from.image}  />
                                                </div>
                                                <div>
                                                    <h5 className='p-0 m-0'>{challenge.from.name} </h5>
                                                    <span> 5-0 </span> 
                                                </div>
                                            </div>
                                            
                                            <div className='d-flex align-items-center my-1'>
                                                <div className='rounded-circle mr-2' style={{width:40, height:40, border: `2px solid ${Colors.colorPrimary}`}}>
                                                    <img className="img-fluid m-auto p-1" src={challenge.to.image}  />
                                                </div>
                                                <div>
                                                    <h5 className='p-0 m-0'>{challenge.to.name} </h5>
                                                    <span> 1-4 </span> 
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="align-middle bold">
                                        <div className="my-3">
                                            {/* <h5 className="ml-1 bold">91.24</h5>
                                            <br />
                                            <h5 className="ml-1 bold">104.84</h5> */}
                                            -
                                        </div>
                                    </td>
                                    <td className="align-middle bold">
                                        {challenge.sidebet_type}
                                    </td>
                                    <td className="align-middle">
                                        {challenge.sidebet_duration}
                                    </td>
                                    <td className="align-middle">
                                        <span style={{ color: Colors.colorPrimary }} className="bold">${challenge.winner_gets}</span>
                                    </td>
                                    <td className="align-middle">
                                        <div className='d-flex flex-column'>
                                            {
                                                challenge.sender ?
                                                <Button className='my-1' onClick={() => deleteSidebet(challenge.ch_id)} style={STYLES.BUTTON_DANGER_TRANSPARENT}>Delete</Button>
                                                :
                                                <>
                                                    <Button className='my-1' onClick={() => accept(challenge.ch_id)} style={STYLES.BUTTON_PRIMARY_TRANSPARENT}>Accept</Button>
                                                    <Button className='my-1' onClick={() => reject(challenge.ch_id)} style={STYLES.BUTTON_DANGER_TRANSPARENT}>Reject</Button>
                                                </>
                                            }
                                        </div>
                                        {/* <Button style={{ color: Colors.colorSecondary, backgroundColor: Colors.darkColor, border: `1px solid ${Colors.colorSecondary}`, width: 120 }}>
                                        De
                                        </Button>
                                        <Button style={{ color: Colors.colorSecondary, backgroundColor: Colors.darkColor, border: `1px solid ${Colors.colorSecondary}`, width: 120 }}>
                                        Cancel
                                        </Button>
                                        <Button style={{ color: Colors.colorPrimary, backgroundColor: Colors.bgLight, border: `1px solid ${Colors.colorPrimary}`, width: 120 }}>
                                        Settle up
                                        </Button> */}
                                    </td>
                                </tr>
                        )
                    })
                }
                        
            </tbody>
        }
      </>
  )
}

export default BetProposals