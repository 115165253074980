import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Collapse, Input } from 'reactstrap';
import Truncate from 'react-truncate';
import moment from 'moment-timezone';
import LoadingOverlay from 'react-loading-overlay';
import { mmCounterMatch, mmCreateMatch, mmTeamOnboarded } from '../../../apis/Arena/MatchMakerApis';
import { parseProfleStatus, verifyUserStatus } from '../../../helpers/Payments/GidxHelper';
import ArenaError from '../../../components/Matchmaker/ArenaError';
import ErrorText from '../../../components/ErrorText';
import { successMessage } from '../../../helpers/common-methods';
import GidxErrorModal from '../../../modals/GidxModals/GidxErrorModal';
import CounterPlayerSelectModal from '../../../modals/Arena/CounterPlayerSelectModal';
import OnboardedPlayerModal from '../../../modals/Arena/OnboardPlayerModal';
import { useColors } from '../../../helpers/CustomHooks/useColors';
import { backArrowIcon, chevronDownIcon } from '../../../helpers/icons';
import { defaultColors } from '../../../helpers/colors';
import CounterActions from '../ProposalActions/CounterActions';



const betAmounts = [5,10,25,50,100]

function CounterMatchCollapse(props) {
    const Colors = useColors();
	const { isOpen, cancelCallback} = props

    const fetchTodayDate = () => {
        if(sport == "NFL"){
            // if(arenaData.nflWeekStatus == "THU_NOT_STARTED"){
            //     return "TM"
            // }else if(arenaData.nflWeekStatus == "THU_STARTED" || arenaData.nflWeekStatus == "SUN_NOT_STARTED"){
            //     return "S"
            // }else if(arenaData.nflWeekStatus == "SUN_STARTED"){
            //     return "TM"
            // }
            return null
        }else if (sport == "MLB" || sport == "NBA"){
            if(arenaData?.todayGamesStarted){
                return "TM"
            }else{
                return "TD"
            }
        }
    }
    
	return (
        <Collapse isOpen={isOpen}>
            <div className='d-flex align-items-center align-items-center py-1' style={{ color: Colors.colorLight, background: Colors.black_90 }}>
                <div className='mx-2 pointer align-items-center d-flex' style={{width:20}} onClick={cancelCallback}>{backArrowIcon()}</div>
                <p className='m-0'>COUNTER</p>
            </div>
            <CounterActions {...props} />
        </Collapse>
	);
}

export default CounterMatchCollapse;
