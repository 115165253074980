import React from 'react';
import { Button, Modal, FormGroup, Label, Col, Input, Row } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import profile from "../assets/images/profile.png";
import { hasError, getError } from '../helpers/error';
import { isYahooCommissioner } from "../actions/Import/api";
import { LoaderCenter } from '../components/Loader';

class CommishInviteModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            container: 3,
            email: null,
            errors: [],
            loading: true
        };
    }

    componentDidMount(){
        if(this.props.league.league_type == "private"){
            this.props.isYahooCommissioner({lg_key: this.props.league.key}).then((resp) => {

            }).catch((err) => {
                console.log(err)
                alert("error")
            })
        }else{
            this.setState({ container: 1 })
        }
    }

    handleInputChange(e) {
        this.setState({
            email: e.target.value,
            errors: []
        });
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validateForm() {
        let isFormValid = true
        let errors = {}
        if (this.state.email == null || this.state.email == "" || !this.validateEmail(this.state.email)) {
            errors["email"] = "Email is Invalid"
            isFormValid = false
        }
        this.setState({
            errors: errors
        });
        return isFormValid
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true })
        if(this.validateForm()){
            alert("yes")
        }
        // this.props.sendInviteEmails(this.props.league_id, this.state.emails)
        //     .then(response => this.success())
        //     .catch(error => console.log(error))
    }

    render() {
        return (
            <div className="faraz" >
                <Modal size="md" isOpen={this.props.modal} toggle={() => this.props.toggle("close")}>
                {(() => {
                    switch (this.state.container) {
                        case 1:
                            return (
                                <div className="text-center p-4" >
                                    <i onClick={() => this.props.toggle("close")} class="cui-circle-x icons pointer modalClose"></i>
                                    <h3 className="bold mb-3 mt-3" >Commissioner Invite</h3>
                                    <p>You are only able to import leagues where you are commissioner. Here is the commissioner for <b>{this.props.league.name}</b></p>
                                    <div>
                                        <img style={{verticalAlign: "top"}} src={profile} className="roundImg" />
                                    </div>
                                    <p className="bold mb-1 mt-3" >{this.props.league.team_name}</p>
                                    <p>{this.props.league.commissioner}</p>
        
                                    <p>Would you like to invite them to import this league to Bettle?</p>
        
                                    <div className="d-flex justify-content-around mt-4" >
                                        <Button onClick={() => this.props.toggle("close")} className=" dark-btn" >No</Button>
                                        <Button className=" green-btn" onClick={() => this.setState({ container: 2 })}  >Yes</Button>
                                    </div>
                                </div>
                            )
                        case 2: 
                            return (
                                <div className="text-center p-4" >
                                <i onClick={() => this.setState({ container: 1 })} class="cui-arrow-left icons pointer modalBack"></i>
                                <i onClick={() => this.props.toggle("close")} class="cui-circle-x icons pointer modalClose"></i>
                                <h3 className="bold mb-3 mt-3" >Commissioner Invite</h3>
                                <p>Send league import invitation to <b>{this.props.league.name}</b>?</p>
    
                                <FormGroup row>
                                    <Label sm={2}>Email</Label>
                                    <Col sm={10}>
                                        <Input type="email"
                                            required
                                            name={"email"}
                                            onChange={e => this.handleInputChange(e)}
                                            className={`${hasError(this.state.errors, "email") ? 'is-invalid' : ''}`}
                                        />
                                        {hasError(this.state.errors, "email") &&
                                            <div className="invalid-feedback">{getError(this.state.errors, "email")}</div>
                                        }
                                    </Col>
                                </FormGroup>
    
                                <div className="d-flex justify-content-around mt-4" >
                                    <Button onClick={() => this.props.toggle("close")} className=" dark-btn" >Cancel</Button>
                                    <Button className="green-btn" onClick={(e) => this.handleSubmit(e)} >Send Invite</Button>
                                </div>
                            </div>
                            )
                        case 3: 
                            return (
                                <div className="text-center p-4" >
                                    <i onClick={() => this.props.toggle("close")} class="cui-circle-x icons pointer modalClose"></i>
                                    <h5 className="center mb-4 bold" >Preparing league</h5>
                                    
                                        <LoaderCenter />
                                        <p>Fetching league meta data...</p>
                                                                          
                                </div>
                            )
                        default :
                            return null
                    }
                    })()}
                </Modal>
            </div>
        );
    }
}

const mapDispatchToProps = {
    isYahooCommissioner
};

export default connect(null, mapDispatchToProps)(withRouter(CommishInviteModal));