import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { inviteViaEmail } from '../apis/User/UserAPI';
// import { Colors } from '../helpers/colors';
import { STYLES } from '../helpers/stylesHelper';
import { toastError, toastSuccess } from '../helpers/toasts';

const InviteEmailModal = (props) => {
    const {isOpen, toggle, referralLink} = props
    const [email, setEmail ] = useState()
    const [loading, setLoading] = useState(false)
    const [emailList, setEmailList] = useState([])

    
    const handleChange = (value) => {
        value = value ?? []
        setEmailList(value)
    };

    const handleInputChange = (inputValue) => {
        setEmail(inputValue)
    };

    const handleKeyDown = (event) => {
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                if (valueIsEmail()) {
                    if(!emailList.includes(email)) {
                        setEmailList([...emailList, email])
                    }
                    setEmail('')
                }
                else {
                    toastError('Invalid Email Address')
                }
                event.preventDefault();
                break;
            default:
                break;
        }
    };

    const valueIsEmail = () => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    const emailAlreadyExistsInList = () => {
        return emailList.includes(email)
    }

    const sendInvitations = () => {
        setLoading(true)
        let data = {emails: emailList, referralLink: referralLink()}

        inviteViaEmail(data)
            .then(response => {
                toastSuccess('Invitations Sent Succesfully')
                toggle()
                setLoading(false)
            })
            .catch(err => {
                toastError('Something Went Wrong, Try Again')
                setLoading(false)
            }
        )
    }

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader className='border-0'>
                <i onClick={toggle} class="cui-circle-x icons pointer modalClose"></i>
            </ModalHeader>
            <ModalBody className='mt-2 mx-5'>
                <h1 className='text-center'>📮</h1>
                <h4 style={{fontFamily:'Fritch'}} className="text-center my-2"> Invite Your Friends Via Email</h4>
            <CreatableSelect
                style={STYLES.REACT_CREATABLE_SELECT}
                className="my-4"
                inputValue={email}
                getOptionValue={option => option} 
                getOptionLabel={option => option}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={handleChange}
                onInputChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Type email and press Enter"
                value={emailList}
            />
            <Button disabled={loading} className="pull-right" onClick={sendInvitations} style={STYLES.BUTTON_PRIMARY_TRANSPARENT}>{loading ? "Sending..." : "Send"}</Button>
            </ModalBody>
        </Modal>
    )
}

export default InviteEmailModal