import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import PlayerCard from '../../../pages/SeasonLong/Players/PlayerCard/PlayerCard';
import './colors.css';
const PlayerCardModal = (props) => {

    return (
        <div>
          <Modal isOpen={props.modal} fade={false} toggle={props.toggle} cssModule={{'modal-content' : 'modal-content-light modal-fixed-height'}} >
              <i style={{color: "#fff"}} onClick={() => props.toggle("close")} class="cui-circle-x icons pointer modalClose"></i>
              <ModalBody style={{ padding: 0 }} >
                  <PlayerCard {...props} />
              </ModalBody>
          </Modal>
        </div>
      )
}

export default PlayerCardModal