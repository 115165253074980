import React, { Component } from "react";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { UncontrolledAlert } from "reactstrap";
import { autoLogin, triggerESPNImport } from "../../actions/Import/api"
import { setInForage, TOAST } from "../../helpers/common-methods";
import fantrax from '../../assets/images/fantrax_x.svg';
import ImportProgress from "../../components/LeagueImport/ImportProgress";
import ErrorText from "../../components/ErrorText";
import { ColorContext } from "../../context/ColorProvider";
import { LoaderCenter } from "../../components/Loader";
import { chromeBettleIcon, chromeIcon, extIcon, fantarxX } from "../../helpers/icons";

class FantraxImport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            container: 1,
            resp: [],
            teamId: "",
            teamName: "",
            players: [],
            errors: [],
            sport: this.props.sport || null,
            importSuccess: false,
            response: {},
            selectedLeague: null,
            showToast: false,
            step: "import",
            selectedLeagueId: null,
            recoveryData: null,
            apiProcessing: false
        }
    }
    static contextType = ColorContext;

    componentDidMount(){
        if(this.props.sport){
            this.handleSport(this.props.sport)
        }
    }


    triggerImport(league){
        this.setState({ selectedLeagueId: league.id})
        // Call Import API here
        this.props.triggerESPNImport({ lg_key: league.id, name: league.name, sport: this.state.sport, platform: "fantrax" }).then((resp) => {
            this.setState({container: 6})
        }).catch(async (err) => {
            if(err == "IMPORTED"){
                // NAVIGATE TO CLAIM FLOW
                await setInForage("claimTeam", {
                    lgId: league.id,
                    platform: "fantrax",
                    lgName: decodeURIComponent(league.name),
                    sbId: null,
                    leagueId: league.leagueId,
                    grudgeId: null
                })
                this.props.history.push("/newClaimTeam?fromImport=true")
            }else{
                TOAST.error(err)
            }
        })
        this.setState({selectedLeague: league})
    }

    updateContainer(index){
        this.setState({ container: index })
    }

    handleInputChange(e) {
        this.setState({
          [e.target.name]: e.target.value
        });
      }

      autoLogin(sport = this.state.sport){
        this.setState({ loading: true })
        this.props.autoLogin({ platform: "fantrax", sport: sport }).then(( resp ) => {
            if(resp?.success == false){
                this.setState({ loading: false, container: 1})
            }else{
                this.setState({ loading: false, resp: resp, container: 3})
            }
        }).catch((err) => {
            console.log(err)
            this.setState({ loading: false, container: 2})
        })  
      }

      handleSport(sport){
          this.setState({ sport: sport, container: 7})
          this.autoLogin(sport)
      }

    updateStep = (step) => {
        this.setState({step: step})
    }

    isChrome = () => {
        var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        if (!isChrome) {
            return false
        }
        return true
    }

    render() {
        const Colors = this.context;
        return (
            <div className="d-flex flex-column flex1">
                <div className="d-flex flex-column flex1">
                    {this.state.loading ? (<LoaderCenter />) : (
                        this.state.error ? (<ErrorText msg={this.state.error} />) : (
                            <div>
                                { this.state.errors.exception != undefined && <UncontrolledAlert color="danger">
                                    {this.state.errors.exception}
                                </UncontrolledAlert>}
                                    {(() => {
                                    switch (this.state.container) {
                                        case 1:
                                            return (
                                                <div className="mb-5"  >
                                                    {this.isChrome() ? (
                                                        <div className="" >
                                                            <div className="py-3 px-4 d-flex justify-content-between align-items-center" style={{height: "56px", background: "#333333"}} >
                                                                <p className="bold mb-0" >FANTRAX SYNC</p>
                                                                {fantarxX()}
                                                            </div>

                                                            <div className="my-4 mx-5" >
                                                                <p className="alex bold" style={{fontSize: "20px"}} >Please download the <span className="alex bold green-font" style={{fontSize: "20px"}} >Bettle Chrome Extension</span></p>
                                                                <p className="mb-0" >In order for Fantrax to seamlessly sync with Bettle, it is necessary to install and enable this Chrome extension.  Installing this extension will also allow you to access Fantrax from your mobile app.</p>
                                                                <p>Do it once and you're good. It's worth it.</p>
                                                            </div>

                                                            <div className="d-flex align-items-center justify-content-center flex1" >
                                                                <button onClick={() => {
                                                                    window.open("https://chrome.google.com/webstore/detail/bettle-league-importer/fdaboplfklloipnhkjlmpefamkhkihih",'_blank');
                                                                }} className="mr-4 px-0 d-flex align-items-center justify-content-between" style={{width: "255px", height: "40px", borderRadius: "40px", background: "#1A1A1A", border: "none"}} >
                                                                    <div className="d-flex align-items-center" >
                                                                        {chromeBettleIcon()}
                                                                    </div>
                                                                    <p className="bold mb-0" style={{color: "white"}} >Bettle - League Importer</p>
                                                                    <div></div>
                                                                </button>
                                                                {extIcon()}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="" >
                                                            <div className="py-3 px-4 d-flex justify-content-between align-items-center" style={{height: "56px", background: "#3E1118"}} >
                                                                <p className="bold mb-0" style={{color: "#F24C65"}} >FANTRAX SYNC</p>
                                                                <div className="d-flex align-items-center" >
                                                                    <div className="mr-2" >
                                                                        {chromeIcon()}
                                                                    </div>
                                                                    {fantarxX()}
                                                                </div>
                                                            </div>

                                                            <div className="my-4 mx-5" >
                                                                <p className="alex bold" style={{fontSize: "20px"}} >
                                                                    Please sign in to Bettle using <span className="alex bold" style={{fontSize: "20px", color: "#F24C65"}} >Google Chrome</span> on desktop and download the <span className="alex bold" style={{fontSize: "20px", color: "#F24C65"}}>Bettle Chrome Extension</span>
                                                                </p>
                                                                <p className="mb-0" >In order for Fantrax to seamlessly sync with Bettle, it is necessary to install and enable this Chrome extension.  Installing this extension will also allow you to access Fantrax from your mobile app.</p>
                                                                <p>Do it once and you're good. It's worth it.</p>
                                                            </div>

                                                            <div className="d-flex align-items-center justify-content-center flex1" >
                                                                <button onClick={() => {
                                                                    window.open("https://chrome.google.com/webstore/detail/bettle-league-importer/fdaboplfklloipnhkjlmpefamkhkihih",'_blank');
                                                                }} className="mr-4 px-0 d-flex align-items-center justify-content-between" style={{width: "255px", height: "40px", borderRadius: "40px", background: "#1A1A1A", border: "none"}} >
                                                                    <div className="d-flex align-items-center" >
                                                                        {chromeBettleIcon()}
                                                                    </div>
                                                                    <p className="bold mb-0" style={{color: "white"}} >Bettle - League Importer</p>
                                                                    <div></div>
                                                                </button>
                                                                {extIcon()}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        case 3: 
                                            return (
                                                <div className="d-flex flex-column flex1"  >
                                                    {this.state.resp.length > 0 ? (
                                                        <div className="d-flex flex-column flex1">
                                                            { this.props?.for != 'newUser' && <h3 style={{ marginBottom: "20px", fontWeight: "bold" }} className="greenText" >Which league you would like to import:</h3> }
                                                            <div className="d-flex flex-column">
                                                                {this.state.resp.map((league) => {
                                                                    return (
                                                                        <div className="text-left pointer my-1" style={{padding: "10px", borderRadius:10, color: this.state.selectedLeagueId == league.id ? "black": "white", backgroundColor:this.state.selectedLeagueId == league.id ? Colors.colorPrimary: '' }} tag="button" onClick={() => this.triggerImport(league)} action>
                                                                            <div className="d-flex align-items-center" >
                                                                                <img className="importImg" src={fantrax} />
                                                                                <div>
                                                                                    <p className="bold m-0" >{league.name}</p>
                                                                                    <p className="m-0" >{league.team_name}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="d-flex flex-column justify-content-center align-items-center my-4" >
                                                            <h4 className="text-center" style={{lineHeight: "30px"}} >We couldn't find any league in your Fantrax account</h4>
                                                            <div>
                                                                <a style={{fontSize: "18px"}} className="d-block default_a mt-5" href="https://www.fantrax.com/" target="_blank" >Go to Fantrax</a>
                                                            </div>
                                                        </div>
                                                        
                                                    )}
                                                </div>
                                            )
                                        case 6:
                                            return (
                                               <ImportProgress
                                                    handleSuccess={this.props?.handleSuccess}
                                                    showInvite={() => this.setState({container: 5, step: "invite"})}
                                                    platform={"fantrax"}
                                                    lg={this.state.selectedLeague}
                                                    sport={this.state.sport}
                                               />
                                            )
                                        case 7:
                                            return (
                                                <div className="d-flex flex-column justify-content-center align-items-center" >
                                                    <h4 className="text-center" style={{lineHeight: "30px"}} >Something went wrong, please try again later.</h4>
                                                </div>
                                            )
                                        default :
                                            return null
                                    }
                                    })()}
                            </div>
                        ))}
                </div>               
            </div>
        )
    }
}

const mapDispatchToProps = {
    autoLogin,
    triggerESPNImport
};
export default connect(null, mapDispatchToProps)(withRouter(FantraxImport));