import React from 'react';
import { motion } from "framer-motion/dist/framer-motion"
import { slideInOut } from './animationValues';
import { Colors } from '../../helpers/colors';
import { mlbHelmet, nbaVest, nflHelmet } from '../../helpers/icons';
import { setSportInStorage } from '../../helpers/Arena/ArenaHelper';

const SelectSport = (props) => {

    const { handleSportSelect, step } = props
    const handleSport = async (sport) => {
        await setSportInStorage(sport)
        // ALSO SAVE IN LOCAL STORAGE SO THAT IN CASE OF CHROME EXTENSION BASED IMPORTERS
        handleSportSelect(sport)
    }

    return (
		<motion.div
            className='flex-column scrollHidden'
            animate={step == 'startImport' ? "show" : step == 'sportSelected' ? "hideLeft" : "hideRight"}
            transition={{ duration: 0.5 }}
            initial={false}
            style={{display: "flex"}}
            variants={slideInOut}
        >

            {step == "startImport" && <div className='d-flex flex-column text-center' >
            <h1 className='mb-3 alex bold' style={{ color: Colors.colorPrimary }}>Select Game</h1>
            {/* {
                // Count all the enabled sports and show them like this sport1, sport2 or sport3 
                // if two or more are enabled else show sport1 only
                sports.filter(sport => sport.enabled).length > 1 ?
                    <p>
                        {
                            sports.filter(sport => sport.enabled).map((sport, index) => {
                                return (
                                    <span key={index}>
                                        {sport.name}{index == sports.filter(sport => sport.enabled).length - 2 ? ' or ' : index == sports.filter(sport => sport.enabled).length - 1 ? '' : ', '}
                                    </span>
                                )
                            })
                        }
                    </p>
                    :
                    <p>
                        {sports.filter(sport => sport.enabled)[0].name}
                    </p>
            } */}
            <div className='d-flex flex-column flex-md-row mt-md-4 mx-auto'>
                {
                    sports.map((sport, index) => {
                        return (
                            sport.enabled ? <motion.div
                                onClick={() => handleSport(sport.name)}
                                whileHover={{
                                    color: sport.color,
                                }}
                                className="flex1 mx-md-4 my-3 my-0"
                                style={{ width: 80, height: 80, aspect: 1, color: 'white', cursor: 'pointer' }}
                            >
                                {sport.icon}
                            </motion.div>
                            :
                            <div
                                className="flex1 mx-md-4 my-3 my-0"
                                style={{ width: 80, height: 80, aspect: 1, color: 'white', cursor: 'pointer', opacity: 0.5 }}
                            >
                                {sport.icon}
                            </div>
                        )
                    })
                }
            </div>
            </div>}
        </motion.div>
    )
};

export default SelectSport;

const sports = [
    {
        name: 'NFL',
        icon: nflHelmet(),
        color: Colors.nflPrimaryColor,
        enabled: true
    },
    {
        name: 'MLB',
        icon: mlbHelmet(),
        color: Colors.mlbPrimaryColor,
        enabled: false
    },
    {
        name: 'NBA',
        icon: nbaVest(),
        color: Colors.nbaPrimaryColor,
        enabled: false
    }
]