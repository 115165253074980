import React, { useEffect, useState } from "react"
import { Input } from "reactstrap"
import { useColors } from "../../../helpers/CustomHooks/useColors";
import { ccFormat } from "../../../helpers/Payments/GidxHelper";
import ErrorText from "../../ErrorText";

var valid = require("card-validator");

const GidxCCFields = (props) => {
    const {setBillingModal, billingModal, errors, handleInput, handleSaveCheck, formVals, setCardNetworkParent, address, checkVal} = props
    
    const Colors = useColors()

    const [cardNetwork, setCardNetwork] = useState()
    const [cardError, setCardError] = useState()

    useEffect(() => {
    }, [])

    const validateCard = (text) => {
        var numberValidation = valid.number(text)
        if (numberValidation.card){
            setCardNetwork(numberValidation.card.type)
            setCardNetworkParent(numberValidation.card.type)
        }
        if(!numberValidation.isPotentiallyValid){
            setCardError("Card number is invalid")
        }else{
            setCardError(null)
        }
        handleInput("cardNumber", ccFormat(text))
    }

    const prepareAddressString = () => {
        return address.address+ " " + address.city + " " + address.state.shortname + " " + address.country.shortname  
    }
    
    return (
        <div>
            <div className="mb-3 mt-3" >
                <h6 className="bold" >Cardholder Name</h6>
                <Input placeholder="John Doe" value={formVals.displayName} onChange={(e) => handleInput("displayName", e.target.value )} />
                {errors.displayName && <ErrorText msg={errors.displayName} />}
            </div>
            <div className="mb-3 mt-3" >
                <div className="d-flex align-items-center justify-content-between" >
                    <h6 className="bold" >Card Number</h6>
                    <p className="m-0" >{cardNetwork}</p>
                </div>
                <Input placeholder="0000 0000 0000 0000"
                value={formVals.cardNumber}
                onChange={(e) => validateCard(e.target.value )}
                />
                {errors.cardNumber && <ErrorText msg={errors.cardNumber} />}
                {cardError && <ErrorText msg={errors.cardError} />}
            </div>
            <div className="mb-2 mt-2 d-flex align-items-center" >
            <div className="mb-3 mt-3 mr-2" >
                    <h6 className="bold" >Expiration Date</h6>
                    <Input placeholder="mm/yyyy"
                    maxLength={7}
                    value={formVals.expireDate}
                    onChange={(e) => handleInput("expireDate", e.target.value)}
                    />
                    {errors.expireDate && <ErrorText msg={errors.expireDate} />}
                </div>
                <div className="mb-3 mt-3" >
                    <h6 className="bold" >CVV</h6>
                    <Input placeholder="000"
                    maxLength={4}
                    value={formVals.securityCode}
                    onChange={(e) => handleInput("securityCode", e.target.value)}
                    />
                    {errors.securityCode && <ErrorText msg={errors.securityCode} />}
                </div>
            </div>

            {address ? (
                <div className="mb-3 mt-3 pointer"  >
                    <h6 className="bold" >Billing Address</h6>
                    <div className="p-2 text-center" style={{border: `1px solid ${Colors.darkBgBorder}`}} >
                        <p className="m-0" >{prepareAddressString()}</p>
                    </div>
                </div>
            ) : (
                <div className="mb-3 mt-3 pointer" onClick={() => setBillingModal(!billingModal)}  >
                    <h6 className="bold" >Billing Address</h6>
                    <div className="p-2 text-center" style={{border: `1px solid ${Colors.darkBgBorder}`}} >
                        <i class="fa fa-plus-square-o" style={{fontSize: "1.5rem"}} aria-hidden="true"></i>
                    </div>
                </div>
            )}
            {errors.address && <ErrorText msg={errors.address} />}
            <div className="mb-2 mt-2 d-flex align-items-center" >
                <div className="mb-3 mt-3 mr-2" >
                    <input type="checkbox" checked={checkVal} onChange={(e) => handleSaveCheck(e.target.value)} />
                </div>
                <div className="mb-3 mt-3" >
                    <h6 className="bold" >Remember this card for future use.</h6>
                </div>
            </div>
        </div>
    )
}

export default GidxCCFields