import React from 'react';
import { Button, Modal, ListGroupItem, ListGroup, Row, Col } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchTeams, claimTeam } from "../actions/Import/api"
import { toast } from 'react-toastify';
import ios from "../assets/images/ios.png";
import android from "../assets/images/android.png";
import bettleApp from "../assets/images/mobile-app.png";
import espn from '../assets/images/nfl/espn.svg';
import yahoo from '../assets/images/nfl/yahoo.png';
import claimTeamImage from '../assets/images/claimTeam.png';
import claimSuccess from '../assets/images/claimSuccess.png';
import { LoaderCenter } from '../components/Loader';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { errorMessage, removeInForage } from '../helpers/common-methods';
import store from "../store"
import { SAVE_APPLICATION_STATUS, updateActiveLeagueId } from '../apis/Sidebar/LeagueAPI';
import { STYLES } from '../helpers/stylesHelper';
import androidLogo from '../assets/images/android.png';
import iosLogo from '../assets/images/ios.png';
import { BASE_FE_URL, BASE_URL } from '../config/app';
import { updateActiveExperience } from '../apis/User/UserAPI';

class ClaimTeamModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: this.props.modal,
            user: this.props.auth.user,
            teams: [],
            loading: true,
            container: 1,
            selectedTeam: "",
            claimedData: null,
            homeLoading: false,
            copyText: "Copy Link",
            arenaLoading: false
        };
    }

    getCopyText = () => {
        return encodeURI(BASE_FE_URL+"/sl/lg/invite/"+this.props.claimData.lgId+"/"+this.props.claimData.platform+"/"+this.props.claimData.lgName)
    }

    handleUpdateActiveLeagueId = () => {
        this.setState({homeLoading: true})
		store.dispatch(updateActiveLeagueId(this.props.claimData.leagueId, true))
        .then(async (response) => {
            await removeInForage('claimTeam')
            window.location.href = "/dashboard"
        })
        .catch((error) => {
            this.setState({homeLoading: true})
            errorMessage("Can't open league home, please try again~")
        });
	}

    componentDidMount(){
        this.props.fetchTeams({lgId: this.props.claimData.lgId}).then((resp) => {
            this.setState({ teams: resp, loading: false })
        }).catch((err) => {
        })
    }

    claimTeamAPI(team){
        this.setState({ loading: true, selectedTeam: team.teamName})
        var reqData = {
            lgId: this.props.claimData.lgId,
            teamId: team.teamId
        }
        reqData["sb_id"] = this.props.claimData.sbId
        this.props.claimTeam(reqData).then((resp) => {
            // toast.success("Team Claimed Successfully")
            this.setState({container: 3, claimedData: resp})
            // this.props.toggle()
            // this.props.history.push("/sl/league/home/"+resp.lgId)
        }).catch((err) => {
            toast.error(err)
            this.props.toggle()
        })
    }

    switchApp = () => {
        if(this.state.claimedData?.sport){
            var destination = this.state.claimedData.sport == "NFL" ? "FOOTBALL_ARENA" : "BASEBALL_ARENA"
            this.setState({arenaLoading: true})
            updateActiveExperience({
                activeExp: destination
            }).then(() => {
                store.dispatch({ type: SAVE_APPLICATION_STATUS, app_status: destination })
                window.location.href = '/arena/home'
                // this.setState({arenaLoading: false})
            }).catch((e) => {
                errorMessage("Something went wrong, please try again later!")
                this.setState({arenaLoading: false})
            })
        }
	}

    closeModal = () => {
        if(this.state.container == 3){
            window.location.reload()
            this.props.toggle("close")
        }else{
            this.props.toggle("close")
        }
    }

    render() {
        return (
            <div className="mt-4" >
                <Modal size="lg" isOpen={this.state.modal} toggle={this.closeModal}>
                <i onClick={this.closeModal} class="cui-circle-x icons pointer modalClose"></i>
                {(() => {
                    switch (this.state.container ) {
                        case 1:
                            return (
                                <div className="text-center p-4" >
                                    <h3 className="bold" >Welcome, {this.state.user.username}!</h3>
                                    <div className="mt-4 mb-4" >
                                        <img style={{width: "30%"}} src={claimTeamImage} className="img-fluid" />
                                        <div className="d-flex justify-content-center" >
                                        <p className="mt-4 lead" style={{width: "70%"}} >It’s time to claim your team in <b className="bold">{decodeURI(this.props.claimData.lgName)}</b> league. Click ‘Claim my team’ below to continue.</p>
                                        </div>
                                    </div>
                                    <Button onClick={() => this.setState({container: 2})} style={STYLES.BUTTON_PRIMARY_TRANSPARENT} >Claim my team</Button>
                                </div>
                            )
                        case 2: 
                            return (
                                <div className="p-4" >
                                        <i onClick={() => this.props.toggle("close")} class="cui-circle-x icons pointer modalClose"></i>
                                        <h3 className="bold greenText" >Claim your team</h3>
                                        <p>Click your team below in <b>{decodeURI(this.props.claimData.lgName)}</b> to claim roster and join league</p>
                                        { this.state.loading ? (
                                            <LoaderCenter />
                                        ) : (
                                            <ListGroup style={{maxHeight: "60vh", overflow: "auto"}} >
                                            {this.state.teams.map((team) => {
                                                return (
                                                    <ListGroupItem style={{color: "white"}} tag="button" onClick={() => this.claimTeamAPI(team)} action>
                                                        <div className="d-flex justify-content-between align-items-center" >
                                                            <div className="d-flex align-items-center" >
                                                                <img className="roundImgSmall mr-2"  src={team.teamImage } />
                                                                <div>
                                                                    {/* <p className="bold m-0" >{league.name}</p> */}
                                                                    <p className="m-0" >{team.teamName}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    
                                                    </ListGroupItem>
                                                )
                                            })}
                                        </ListGroup>
                                        ) } 
                                </div>
                            )
                        case 3: 
                            return (
                                <div className="p-4" >
                                    {/* <i onClick={() => this.props.toggle("close")} class="cui-circle-x icons pointer modalClose"></i> */}
                                    
                                    {this.state.claimedData.sb && <div>
                                        <h2 className="mb-2 bold" >Download the Bettle App</h2>
                                        <div className="mt-4 mb-4" >
                                            <img style={{width: "30%"}} src={claimSuccess} className="img-fluid" />
                                        </div>
                                        <div className="d-flex justify-content-center" >
                                            <p className="mt-4 lead" style={{width: "70%"}} >To accept your {this.state.claimedData.betType} sidebet with <b className="bold" >{this.state.claimedData.senderName}</b> for <b className="bold">${this.state.claimedData.amount}</b> you will need to download the Bettle app</p>
                                        </div>

                                        <div className="d-flex justify-content-center align-items-center mt-4" >
                                            <a target="_blank" href="https://apps.apple.com/us/app/bettle-fantasy-sports/id1591274931" >
                                                <img src={ios} className="img-fluid" />
                                            </a>
                                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.bettle.bettlelive" >
                                                <img src={android} className="img-fluid" />
                                            </a>
                                        </div>
                                    </div>}

                                    {!this.state.claimedData.sb && <div className="mx-auto d-flex flex-column flex1 align-items-center" >
                                        <h1 className="mt-2 mb-4 text-center" style={{fontFamily: "Fritch"}} >Success!</h1>
                                        <p className='lead' >You have claimed the following team in <b className='greenText' >{this.props.claimData.lgName}</b></p>
                                        <div className="d-flex align-items-center" >
                                                <div><img style={{border: "3px solid #03F29E"}} className="roundImgMed mr-3" src={this.props.claimData.platform == "espn" ? espn : yahoo} /></div>
                                                <p className="lead bold mb-0" >{this.state.selectedTeam}</p>
                                        </div>
                                        <div>
                                            {/* <h2 className="bold greenText mt-2" >Time to Get Started!</h2> */}
                                            {/* <p className='mt-4' >The MatchMaker arena is open! Get your team in there ahead of this week's action.</p> */}
                                            {/* <ul> */}
                                            <div className="mt-4"  >
                                                <p className="mb-2 lead d-md-none d-block text-center">
                                                    You are ready to go! To enter the arena on your mobile device, you can download our apps here:
                                                </p>
                                                <p className="mb-2 lead d-none d-md-block">
                                                    The MatchMaker arena is open! Set your lineup. Make a Match. <span className="greenText lead">Win cash.</span>
                                                </p>
                                                {/* <p className="mb-2">The MatchMaker arena is open! Get your team in there ahead of this week's action.</p> */}
                                                <div className="text-center mt-4 d-none d-md-block" >
                                                    <Button disabled={this.state.arenaLoading} onClick={this.switchApp} style={STYLES.BUTTON_PRIMARY_TRANSPARENT}>
                                                        {this.state.arenaLoading ? "Processing..." : "Enter the Arena"}
                                                    </Button>
                                                </div>
                                                <div className='d-flex justify-content-center align-items-center d-block d-md-none' >
                                                    <div style={{width: "36%"}}>
                                                    <a target={"_blank"} href='https://apps.apple.com/us/app/bettle-fantasy-sports/id1591274931' ><img src={iosLogo} className="img-fluid" /></a>
                                                    </div>
                                                    <div style={{width: "46%"}} >
                                                    <a target={"_blank"} href='https://play.google.com/store/apps/details?id=com.bettle.bettlelive&hl=en_US&gl=US' ><img src={androidLogo} className="img-fluid" /></a>
                                                    </div>
                                                </div>
                                                <p className="mb-2 lead d-block d-md-none text-center mt-3">
                                                    or hop on your computer and play at <span className='greenText lead' >bettle.co</span>
                                                </p>
                                                </div>
                                                {/* <li>
                                                    <p>Invite the rest of your league, if they're not here yet. You can do that by simply sending them this link, where they can sign up and claim their team in your league.</p>
                                                    <CopyToClipboard text={this.getCopyText()}
                                                        onCopy={() => {this.setState({ copyText: "Copied!"})}}>
                                                        <Button style={STYLES.BUTTON_PRIMARY_TRANSPARENT} ><i class="fa fa-clone" aria-hidden="true"></i>  { this.state.copyText }</Button>
                                                    </CopyToClipboard>
                                                </li> */}
                                                {/* <li className="mt-2" >
                                                    <p>Download our mobile app for the optimal Bettle experience</p>
                                                    <div className='d-flex align-items-center' >
                                                        <div style={{width: "25%"}}>
                                                        <a target={"_blank"} href='https://apps.apple.com/us/app/bettle-fantasy-sports/id1591274931' ><img src={iosLogo} className="img-fluid" /></a>
                                                        </div>
                                                        <div style={{width: "31%"}} >
                                                        <a target={"_blank"} href='https://play.google.com/store/apps/details?id=com.bettle.bettlelive&hl=en_US&gl=US' ><img src={androidLogo} className="img-fluid" /></a>
                                                        </div>
                                                    </div>
                                                </li> */}
                                                <div className='d-none d-md-block' style={{marginTop: "4rem"}} >
                                                    <p className='text-center' >Take me to {this.props.claimData.lgName} <span
                                                    disabled={this.state.homeLoading}
                                                    onClick={this.handleUpdateActiveLeagueId}
                                                    className='greenText pointer' >League Home</span>.</p>
                                                </div>
                                            {/* </ul> */}
                                        </div>
                                        {/* <div className="mt-4" >
                                            <p className="lead" >One last step. Download the <b className="greenText" >Bettle App!</b></p>
                                            <Row style={{maxHeight: "250px", overflow: "hidden"}} >
                                                <Col>
                                                <div className="text-center" >
                                                    <a target="_blank" href="https://apps.apple.com/us/app/bettle-fantasy-sports/id1591274931" >
                                                        <img src={ios} className="img-fluid" />
                                                    </a>
                                                </div>
                                                <div className="text-center" >
                                                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.bettle.bettlelive" >
                                                        <img src={android} className="img-fluid" />
                                                    </a>
                                                </div>
                                                </Col>
                                                <Col>
                                                <img src={bettleApp} className="img-fluid" />
                                                </Col>
                                            </Row>
                                        </div> */}
                                    </div>}
                                </div>
                            )
                        default :
                            return null
                    }
                    })()}
                </Modal>
            </div>
        );
    }
}
const mapStateToProps = ({ auth }) => ({ auth});
const mapDispatchToProps = {
    fetchTeams,
    claimTeam
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClaimTeamModal));