import { errorMessage } from "../../../helpers/common-methods";
const ENDPOINTS = {
  UPDATE_ARENA_SETTINGS: "/mm/updateArenaSettings",
  SETTINGS: "/mm/fetchArenaSettings",
  SETUP_AVATAR: "/mm/setupAvatar",
  TEAM_HAS_CUSTOM_IMG: "/teamHasCustomImage",
};

export const updateArenaSettings = (reqData) => {
  return window.axios.post(ENDPOINTS.UPDATE_ARENA_SETTINGS, reqData).then((response) => {
    return Promise.resolve(response);
  })
    .catch((error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      } else if (error.request) {
        errorMessage(error.request._response);
      } else {
        errorMessage(error.message);
      }
    });
}

export const arenaSettings = (qs) => {
  try {
    return window.axios.get(ENDPOINTS.SETTINGS+qs).then((response) => {
      return Promise.resolve(response.data);
    })
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    } else if (error.request) {
      errorMessage(error.request._response);
    } else {
      errorMessage(error.message);
    }
  }
}

export const setupAvatar = (reqData) => {
  try {
    return window.axios.post(ENDPOINTS.SETUP_AVATAR, reqData).then((response) => {
      return Promise.resolve(response.data);
    })
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    } else if (error.request) {
      errorMessage(error.request._response);
    } else {
      errorMessage(error.message);
    }
  }
}

export const teamHasCustomImage = (params) => {
  try {
    return window.axios.get(ENDPOINTS.TEAM_HAS_CUSTOM_IMG, {params}).then((response) => {
      return Promise.resolve(response.data);
    })
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    } else if (error.request) {
      errorMessage(error.request._response);
    } else {
      errorMessage(error.message);
    }
  }
}
