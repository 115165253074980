const ENDPOINTS = {
    ACCEPTED_LIVE: '/contest/acceptedLive',
    PENDING_LOBBY: '/contest/pendingLobby',
    RECENT_BETS: '/contest/recent',
};

/**
 * Get all the accepted sidebets that are live
 * @param {object} params - leagueId, type, limit, offset
 * type is live or accepted
 * @returns Promise
 */
export const acceptedLive = (params) => {
    return window.axios.get(ENDPOINTS.ACCEPTED_LIVE,{params})
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

/**
 * Get all the sidebets that are not accepted yet
 * @param {object} params - leagueId
 * @returns Promise
 */
export const pendingLobby = (params) => {
    return window.axios.get(ENDPOINTS.PENDING_LOBBY, {params})
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

/**
 * Get all the recent bets that are completed
 * @param {object} params  leagueId
 * @returns Promise
 */
export const recentBets = (params) => {
    return window.axios.get(ENDPOINTS.RECENT_BETS, {params})
        .then(response => {
            return Promise.resolve(response.data)
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

// acceptedLive = async (lgId) => {
//     try{
//         const {data} =  await this.apiClient.get(ENDPOINTS.ACCEPTED_LIVE+"?leagueId="+lgId)
//         return data.data
//     }catch(error){
//         if (error.response) {
//         return Promise.reject(error.response.data);
//         } else if (error.request) {
//             errorMessage(error.request._response)
//         } else {
//             errorMessage(error.message)
//         }
//     }
// }

// pendingLobby = async (lgId) => {
//     try{
//         const {data} =  await this.apiClient.get(ENDPOINTS.PENDING_LOBBY+"?leagueId="+lgId)
//         return data.data
//     }catch(error){
//         if (error.response) {
//         return Promise.reject(error.response.data);
//         } else if (error.request) {
//             errorMessage(error.request._response)
//         } else {
//             errorMessage(error.message)
//         }
//     }
// }

// recentBets = async (lgId) => {
//     try{
//         const {data} =  await this.apiClient.get(ENDPOINTS.RECENT_BETS+"?leagueId="+lgId)
//         return data.data
//     }catch(error){
//         if (error.response) {
//         return Promise.reject(error.response.data);
//         } else if (error.request) {
//             errorMessage(error.request._response)
//         } else {
//             errorMessage(error.message)
//         }
//     }
// }