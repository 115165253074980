import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import SidebetCreated from './SidebetCreated';
import SidebetProcessed from './SidebetProcessed';
import SidebetSent from './SidebetSent';
import { connect } from 'react-redux';

class Sidebet extends Component {
    constructor(props) {
        super(props);

        this.state = {
                type:'',
                theirPlayer:'',
                yourPlayer:'',
                position:'',
                timeframe:'',
                betAmount:'',
        }
    }

    handleBetChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    state = {  }
    render() { 
        return ( 
            <>
                <div className='pl-2 mt-2 mb-3' style={{maxWidth:'400px'}}>
                {(() => {
                switch(this.props.status) {
                    case 'SIDEBET_CREATED':
                        return(
                            <SidebetCreated {...this.props} leagueId={this.props.data.attachments[0].data.leagueId} user={this.props.data.user.id}/>
                        )
                    case 'SIDEBET_SENT':
                    case 'SIDEBET_ACCEPTED':
                    case 'SIDEBET_REJECTED':
                        return (
                            <SidebetSent {...this.props} />
                        )
                    case 'SIDEBET_REMINDER':
                    case 'SIDEBET_PROCESSED':
                        return (
                        <SidebetProcessed {...this.props} />
                        )
                }
                })()}
                    {/* {
                        {
                            'SIDEBET_CREATED':
                                <SidebetCreated {...this.props} leagueId={this.props.data.attachments[0].data.leagueId} user={this.props.data.user.id}/>,
                            'SIDEBET_SENT':
                                <SidebetSent {...this.props} />,
                            'SIDEBET_ACCEPTED':'',
                            'SIDEBET_REJECTED':'',
                            'SIDEBET_REMINDER':'',
                            'SIDEBET_PROCESSED':''
                        }[this.props.status]
                    } */}
                </div>
            </>
         );
    }
}

const mapStateToProps = (state) => ({ auth: state.auth });
export default connect(mapStateToProps, null) (withRouter(Sidebet));