import React, { useEffect } from 'react'
import { motion } from "framer-motion/dist/framer-motion"
import { flexDirection } from './animationValues'
import { connect, useSelector } from 'react-redux'
import NewOnboardingHeader from './NewOnboardingHeader'
import NewOnboardingBody from './NewOnboardingBody'
import Media from 'react-media'
import { Colors } from '../../helpers/colors'

const NewUserOnboarding = (props) => {
    const [step, setStep] = React.useState("default")
    const [colors, setColors] = React.useState(Colors)
    // const [loading, setLoading] = React.useState(false)
    // const history = useHistory()
    const hasLeague = useSelector(state => state.leagueJoin.hasLeague)

    const updateStep = (step) => {
        setStep(step)
    }

    const getUserName = () => {
        let name = ''
        if (props.auth?.user) {
            name = props?.auth?.user?.first_name

        }
        return name
    }

    return (
            <Media query="(max-width: 767px)">
            {matches => matches ? (
                <motion.div 
                    className='d-flex flex-column flex1 scrollHidden d-md-none'
                    style={{background: "black"}}
                    // style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
                >
                    {/* {loading ? <LoaderCenter/>  : (
                    <> */}
                        <NewOnboardingHeader hasLeague={hasLeague} step={step} updateStep={updateStep} getUserName={getUserName} Colors={colors} />
                        <NewOnboardingBody forMob={true} hasLeague={hasLeague}  step={step} updateStep={updateStep} Colors={colors}/>
                    {/* </>)} */}
                </motion.div>
            ) : (
            <motion.div 
                className='flex1 scrollHidden d-none d-md-flex' 
                style={{background: "black"}}
                // style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
                animate={"column"}
                transition={{ duration: 0.5 }}
                initial={false}
                variants={flexDirection}
            >
                {/* {loading ? <LoaderCenter/>  : (
                    <> */}
                        <NewOnboardingHeader hasLeague={hasLeague} step={step} updateStep={updateStep} getUserName={getUserName} Colors={colors} />
                        <NewOnboardingBody hasLeague={hasLeague} step={step} updateStep={updateStep} Colors={colors} setColors={setColors}/>
                    {/* </> */}
            </motion.div>
            )}
            </Media>
    )
}

const mapStateToProps = (state) => ({ auth: state.auth });
export default connect(mapStateToProps, null) (NewUserOnboarding)