import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { useColors } from '../../../helpers/CustomHooks/useColors';
import { cancelIcon } from '../../../helpers/icons';

function InviteFriendModal(props) {
  const Colors = useColors();

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
      <ModalBody>
        <div className='d-flex justify-content-end mb-2' style={{ cursor: 'pointer' }} onClick={() => props.toggle()}>
          {cancelIcon()}
        </div>
        <h3 className='bold'>Get a 50% Prize Boost when you invite a friend to Bettle!</h3>
        <p style={{ color: Colors.textColorSecondary }}>Get a friend to join Bettle and get a 50% Prize Boost when they make their first Arena match.</p>
        <div className='d-flex justify-content-center align-items-center'>
          <Button
            className='px-5 bold'
            style={{ color: Colors.colorPrimary, backgroundColor: Colors.colorPrimaryTransparent, border: `1px solid ${Colors.colorPrimary}` }}>
            Invite Friend
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default InviteFriendModal;