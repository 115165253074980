import React from 'react';
import store from '../store';
import { Route, Redirect } from 'react-router-dom';
import { setIntendedUrl } from '../helpers/auth';
import PropTypes from 'prop-types';
import V2Layout from '../containers/V2Layout/V2Layout';
import { getStore } from '../helpers/common-methods';
import MainLayout from '../containers/MainLayout/MainLayout';

const propTypes = {
  component: PropTypes.func.isRequired,
  rest: PropTypes.object,
  location: PropTypes.object
};

const AuthRoute = ({ component: Component, exp: exp, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const { auth: { authenticated } } = getStore().getState();
      if (!authenticated) {
        setIntendedUrl(props.location.pathname+props.location.search);
      }else{
      }

      return authenticated ? (
        // <V2Layout Component={Component} {...props} />
        <MainLayout Component={Component} exp={exp} {...props} />
      ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location }
            }}
          />
        );
    }
    }
  />
);

AuthRoute.propTypes = propTypes;
AuthRoute.displayName = 'Auth Route';

export default AuthRoute;
