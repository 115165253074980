import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {  Button } from 'reactstrap';
import { GATEWAY_URL } from '../../../config/app';
import FloatingInput from '../../FloatingInput/FloatingInput';
import { useColors } from '../../../helpers/CustomHooks/useColors';

const ResetPasswordCompV2 = (props) => {
    const [loader, setLoader] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState();
    const { control, register, handleSubmit, formState: { errors } } = useForm();
    const color = useColors()

    const onSubmit = (data) => {
        data['email'] = props.email
        setLoader(true)
        window.axios.post(GATEWAY_URL + '/resetPassword', data)
            .then(({ message }) => {
                setSuccess(true)
            }).catch(error => {
                setLoader(false)
                // TOAST.error(error.response.data.message)
                setError(error.response.data.message);
            });
    };

    return (
        <div className='d-flex flex-column'>
            {/* {success ? (
                <Alert color="primary">
                    Password has been reset, <Link className="default_a" to="/signin" ><u> Click here to login</u></Link>
                </Alert>
            ) : ( */}
                <>
                    <div className='my-4 px-md-5 px-2' >
                        <p className='welcome-heading' >Reset Password</p>
                        <div className='d-flex'>
                            <p>Enter new password with verification code sent to your email</p>
                        </div>
                        {success && <div className='d-flex mb-2'>
                            <span className='green-font bold' >{success}</span>
                        </div>}
                        {error && <div className='mb-2'>
                            <span style={{ color: color.colorDanger }}>{error}</span>
                        </div>}
                        <form onSubmit={handleSubmit(onSubmit)}>

                                <Controller
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) =>
                                    {
                                        return <FloatingInput
                                            error={errors?.code?.message}
                                            type="text"
                                            label="Verification Code"
                                            onChange={(e) => {
                                                onChange(e)
                                            }}
                                            onBlur={onBlur}
                                            value={value}
                                        />
                                    }}
                                    name="code"
                                    rules={
                                        {required:"Code is required"}
                                    }
                                    defaultValue={''}
                                />

                                {/* <input style={{ backgroundColor: Colors.inputBackground, color: Colors.inputText }} className='form-control border-0' type="text" placeholder='Enter Verification Code' {...register("code", { required: true })} />
                                {errors.code && <ErrorText msg='Verification Code field is required' />} */}
                                
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) =>
                                    {
                                        return <FloatingInput
                                            error={errors?.password?.message}
                                            type="password"
                                            label="New Password"
                                            onChange={(e) => {
                                                onChange(e)
                                            }}
                                            onBlur={onBlur}
                                            value={value}
                                        />
                                    }}
                                    name="password"
                                    rules={
                                        {required:"Password is required"}
                                    }
                                    defaultValue={''}
                                />
                                
                                {/* <input style={{ backgroundColor: Colors.inputBackground, color: Colors.inputText }} className='form-control border-0 mt-2' type="password" placeholder='Enter new password' {...register("password", { required: true })} />
                                {errors.password && <ErrorText msg='New Password field is required' />} */}
                                
                                <Button disabled={loader} style={{ width: "100%", height: "48px", fontSize: "16px" }} className="green-btn btn-pill mb-3 mt-3" type="submit">
                                    {loader ? (
                                        <i class="fa fa-refresh fa-spin"></i>
                                    ) : (
                                        "RESET"
                                    )}
                                </Button>
                        </form>
                    </div>
                </>
            {/* )} */}
        </div>

    )
};

export default ResetPasswordCompV2;