import { combineReducers } from 'redux';
import auth from './auth';
import loading from './loading';
import leagueJoin from './league';
import gidxErrorReducer from "./Payments/GidxReducer"
import loader from './loader';
import arenaReducer from './Arena/ArenaReducer';
import NavReducer from './Navigation/NavReducer';

export default combineReducers({
    auth,
    loading,
    leagueJoin,
    gidxErrorReducer,
    loader,
    arenaReducer,
    NavReducer,
});
