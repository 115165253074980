import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Modal, ModalBody, InputGroup, InputGroupText } from 'reactstrap';
import { updateDob } from '../apis/User/UserAPI';
import ErrorText from '../components/ErrorText';
import { getAge } from '../helpers/common-methods';
import { STYLES } from "../helpers/stylesHelper";
import { useColors } from '../helpers/CustomHooks/useColors';

const UpdateDobModal = (props) => {
    const Colors = useColors();
    const { register, handleSubmit, formState: { errors }, setError } = useForm()
    const [loading, setLoading] = useState(false)
    const [localError, setLocalError] = useState(null)

    const onSubmit = (data) => {
        setLocalError(null)
        var dateString = moment(data.dob).format("MM/DD/YYYY")
        if(parseInt(getAge(dateString)) < 19){
            setError("dob", { type: 'custom', message: 'You must be at least 19 years old'})
        }else{
            setLoading(true)
            updateDob({dob: dateString}).then((resp) => {
                // setLoading(false)
                props.onSuccess()
            }).catch((err) => {
                setLoading(false)
                setLocalError("We were not able update your date of birth, make sure you entered correct dob")
            })
        }
    }

    return (
        <div>
            <Modal isOpen={props.modal} >
            <ModalBody>
                <div className='p-2' >
                    <h2 style={{color: Colors.colorPrimary, fontWeight:"bold"}} >{"Please set your\nDate of Birth"}</h2>
                    <p className='lead' >Oops!! 😬 We were not able to get your date of birth. We require it for verification.</p>
                    <div className="mb-4" >
                        <InputGroup>
                            <InputGroupText className='input-text-logo' style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}><i className='fa fa-calendar' /></InputGroupText>
                            <input {...register("dob", { required: "Dob is required" })} type="date" className='login-inputs form-control' style={{ backgroundColor: '#FFFFFF', color: "black", border: '1px solid #E4E7EA' }} />
                        </InputGroup>
                        {errors.dob && <ErrorText msg={errors.dob.message} />}
                        {localError && <ErrorText msg={localError} />}
                    </div>
                    {localError && <ErrorText msg={localError} />}
                    <div className="justify-content-end d-flex mt-4" >
                        <Button disabled={loading} onClick={handleSubmit(onSubmit)} style={STYLES.BUTTON_PRIMARY_TRANSPARENT} >
                            {loading ? "Updating..." : "Update"}
                        </Button>
                    </div>
                </div>
            </ModalBody>
            </Modal>
        </div>
    );
    
  }
  
  export default UpdateDobModal;