import React, { Component } from 'react';
import logo from '../../../assets/images/bettle-icon.png';
import { ListGroup } from 'reactstrap';
import { ColorContext } from '../../../context/ColorProvider';

class CommandsShortcut extends Component {
  constructor(props) {
    super(props);
  };

  static contextType = ColorContext;

  componentDidMount() {
    console.log("MOUNT")
    document.addEventListener('keydown', this.handleShortcutWithArrows);
  };

  componentWillUnmount(){
    console.log("UNMOUNT")
    document.removeEventListener('keydown', this.handleShortcutWithArrows);
  }

  handleShortcutWithArrows = (e) => {

    // ESCAPE KEY
    if (e.key === 'Escape') {
      this.props.updateParentState({popup_enabled: false})
    };

    const getList = document.getElementsByClassName('shortcuts-list');
    // ARROW DOWN KEY
    if (e.key === 'ArrowDown' && getList) {
      if (this.props.shortcutType === 'shortcut' && this.props.shortcutState < getList.length) {
        getList[this.props.shortcutState].focus();
        this.props.updateParentState({ shortcutState: this.props.shortcutState + 1 });
      };
    };

    // ENTER KEY
    if (e.key === 'Enter' && this.props.shortcutType === 'shortcut') {
      e.preventDefault(); // Prevent new lines from being added
      let target = getList[this.props.shortcutState-1];
      target.focus();
      const foundShortcut = this.props.shortcuts.find(shortcut => shortcut.id == target.getAttribute('id'));
      console.log(foundShortcut);
      if(foundShortcut.enabled) {
        this.props.handleShortcut(foundShortcut.syntax)
        this.props.returnFocus()
      }  
    }

    // ARROW UP KEY
    if (e.key === 'ArrowUp' && getList) {
      if (this.props.shortcutType === 'shortcut' && this.props.shortcutState > 1) {
        getList[this.props.shortcutState-1].focus();
        this.props.updateParentState({ shortcutState: this.props.shortcutState - 1 });
      }; 
    };
  };


  render() {
    const Colors = this.context;
    
    return (
      <div className='position-absolute' id="commands" style={{ transform: 'translateY(-50px)', bottom: '50%', zIndex: 999, backgroundColor: Colors.darkColor, borderRadius: 5, border: '3px solid #2A2D31' }}>
        <ul className='px-0' style={{ maxHeight: '180px', overflowY: 'scroll' }} id='shortcuts'>

          {this.props.shortcuts.filter(shortcut => shortcut.syntax.includes(this.props.current_word)).map((foundShortcut, index) => (
            <div id={`element-${index}`} key={foundShortcut.id} className='d-flex flex-row p-2 align-items-center' style={{ background: this.props.shortcutState == index + 1 && foundShortcut.enabled && `${Colors.colorLink}` || !foundShortcut.enabled && Colors.colorSecondary }}>
              <div>
                <img src={logo} width='auto' height='15px' alt='Bettle Inc...' />
              </div>
              <div tabIndex="-1" id={foundShortcut.id} className='flex1 border-0 ml-2 shortcuts-list' style={{ cursor: 'pointer', listStyleType: 'none', color:`${!foundShortcut.enabled ? 'black' : ''}` }} onClick={() => `${foundShortcut.enabled ? this.props.handleShortcut(foundShortcut.syntax) : ''}`}>
                    {foundShortcut.syntax} {foundShortcut.placeholder} <span className='small' style={{ color: Colors.colorSecondary }}>({foundShortcut.details})</span>
              </div>
            </div>
          ))
          }
        </ul>
      </div>
    )
  }
}

export default CommandsShortcut;