import React, { useState } from 'react';
import { Modal, ModalBody} from 'reactstrap';
import { useColors } from '../../helpers/CustomHooks/useColors';
import LoginCompV2 from '../../components/Auth/Login/LoginCompV2';
import SignUpCompV2 from '../../components/Auth/SignUp/SignUpCompV2';
import ForgetPasswordCompV2 from '../../components/Auth/ForgetPassword/ForgetPasswordCompV2';

const SignInModal = (props) => {
    const {isVisible, closeModal, screen} = props
    const [currentScreen, setCurrentScreen] = useState(screen)
    const Colors = useColors();

    const switchScreen = (sc) => {
        setCurrentScreen(sc)
    }

    const parseScreen = () => {
        if(!currentScreen){
            return <LoginCompV2 switchScreen={switchScreen} internal={true} />
        }else if (currentScreen == "signin"){
            return <LoginCompV2 switchScreen={switchScreen} internal={true} />
        }else if (currentScreen == "signup"){
            return <SignUpCompV2 switchScreen={switchScreen} internal={true} />
        }else if (currentScreen == "forgot"){
            return <ForgetPasswordCompV2 switchScreen={switchScreen} internal={true} />
        }
    }

    return (
        <Modal isOpen={isVisible} size="lg"  >
        <i onClick={closeModal} class="cui-circle-x icons pointer modalClose" style={{ color: Colors.modalCloseIconColor }} />
        <ModalBody style={{background: Colors.colorDark, borderRadius: 16}} >
            <div>
                {parseScreen()}
            </div>
        </ModalBody>
        </Modal>
    );
  }
  
  export default SignInModal;