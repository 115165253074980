import React, { Component } from 'react';
import { Button, Col, Navbar, NavbarBrand, Row } from 'reactstrap';
import { connect } from 'react-redux';
// import Sidebar from './Sidebar/Sidebar';
// import { Colors } from '../../helpers/colors';
import { withRouter } from 'react-router-dom';
import Media from 'react-media';
import { getStore } from '../../helpers/common-methods';
import { logoutUser } from '../../actions/auth';
import logo from '../../assets/images/logo_trans.png';
import MainSidebar from '../MainSidebar/MainSidebar';
import { updateActiveExperience } from '../../apis/User/UserAPI';
import store from "../../store"
import { SAVE_APPLICATION_STATUS } from '../../apis/Sidebar/LeagueAPI';
import InviteMembersBanner from '../../components/LeagueImport/InviteMembersBanner';
import Sidebar from '../../components/V3/Sidebar';
import { ColorContext } from '../../context/ColorProvider';
class V2Layout extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	static contextType = ColorContext;

	componentDidMount(){
		updateActiveExperience({
			activeExp: "SEASON_LONG"
		}).then(() => {
			store.dispatch({type: SAVE_APPLICATION_STATUS, app_status: "SEASON_LONG"});
		})
	}

	render() {
		const Colors = this.context;
		return (
			<div className="app d-flex" style={{ overflow: 'hidden', height: '100vh' }}>
				{/* {false ? <h4 className='p-3' >Loading...</h4> : */}

						<Media query="(max-width: 767px)">
							{matches =>
								matches ? 
									<Row className='d-flex flex-column px-2' style={{ height: '100vh', overflow:'scroll' }}>
										<Navbar style={{ backgroundColor: "#1D2226" }} className='w-100 shadow-sm d-flex justify-content-between'>
											<NavbarBrand href="/">
												<img src={logo} width="180" height="auto" alt="Bettle Inc" className="ml-4" />
											</NavbarBrand>
											<Button
												style={{fontSize: '16px' }} 
												className="green-btn btn-pill bold btn btn-secondary px-4"
												onClick={() => {
													getStore().dispatch(logoutUser(() => {})).then(() => {
														this.props.history.push("/signin")
													})
												}}
												>
													Logout
											</Button>
										</Navbar>
										<Col className="d-flex flex1 flex-column" style={{ overflow:'scroll',height: '100vh', backgroundColor: Colors.bgDark }}>
											<this.props.Component {...this.props} />
										</Col>
									</Row>
									:
									<div className='d-flex' style={{ height: '100vh' }}>
										{/* <Sidebar /> */}
										{/* <MainSidebar {...this.props} Component={Sidebar} /> */}
										{/* <Sidebar /> */}
										<div className="d-flex flex1 flex-column pl-0" style={{ height: '100vh', backgroundColor: Colors.bgDark }}>
											<InviteMembersBanner/>
											{!this.props.nav_loading && <this.props.Component {...this.props} />}
										</div>
									</div>
							}
						</Media>
					{/* } */}
			</div>
		);
	}
}

const mapStateToProps = state => ({ nav_loading: state.NavReducer.loading });
export default connect(mapStateToProps, null)(withRouter(V2Layout));
