import React, { useState } from 'react';
import { Alert, Button, Input } from 'reactstrap';
// import { Colors } from '../../../helpers/colors';
import { useForm } from 'react-hook-form';
import { changePassword } from '../../../apis/User/UserAPI';
import ErrorText from '../../ErrorText';
import SuccessText from '../../SuccessText';
import { toastSuccess } from '../../../helpers/toasts';
import { useColors } from '../../../helpers/CustomHooks/useColors';

const ChangePassword = (props) => {
	const Colors = useColors();
	const [ loading, setLoading ] = useState(false);
	const [ error, setErrors ] = useState('');
	const [ success, setSuccess ] = useState(false);
	const { register, handleSubmit, control, formState: { errors } } = useForm();
	const [ newPassword, setNewPassword ] = useState('');

	const submitChanges = (reqData) => {
		setLoading(true);
		changePassword(reqData)
			.then((responseData) => {
				setSuccess(true);
				setLoading(false);
				toastSuccess('Password changed successfully');
				props.onBack();
			})
			.catch((err) => {
				setErrors(err.message);
				setLoading(false);
			});
	};

	const fulfilledStyle = {
		color: Colors.colorPrimary,
	}

	const passwordFormatChecks = () => {
		let { charachters, numbers, lowercase, uppercase, validated } = [false, false, false, false, false];

		charachters = newPassword.length >= 8;
		var hasNumber = /\d/;
		var hasLowercase = /[a-z]/;
		var hasUppercase = /[A-Z]/;

		numbers = hasNumber.test(newPassword);
		lowercase = hasLowercase.test(newPassword);
		uppercase = hasUppercase.test(newPassword);
		validated = charachters && numbers && lowercase && uppercase;


		return (
			<>
				<h6 className='d-flex align-items-center' style={{color: charachters ? Colors.colorPrimary: Colors.colorDanger}}><i class="fa fa-circle small mr-2" aria-hidden="true"></i> Must be 8 characters long</h6>
				<h6 className='d-flex align-items-center' style={{color: numbers ? Colors.colorPrimary: Colors.colorDanger}}><i class="fa fa-circle small mr-2" aria-hidden="true"></i>Must contain at least one number</h6>
				<h6 className='d-flex align-items-center' style={{color: lowercase ? Colors.colorPrimary: Colors.colorDanger}}><i class="fa fa-circle small mr-2" aria-hidden="true"></i>Must contain at least one lowercase letter</h6>
				<h6 className='d-flex align-items-center' style={{color: uppercase ? Colors.colorPrimary: Colors.colorDanger}}><i class="fa fa-circle small mr-2" aria-hidden="true"></i>Must contain at least one uppercase letter</h6>

				<div className="text-center">
					<Button
						onClick={handleSubmit(submitChanges)}
						className="mx-2 mt-4"
						disabled={loading || !validated}
						style={{
							color: Colors.colorPrimary,
							backgroundColor: Colors.colorPrimaryTransparent,
							border: `1px solid ${Colors.colorPrimary}`
						}}>
						Save Changes
					</Button>
					<Button
						className="mx-2 mt-4"
						style={{
							color: Colors.colorPrimary,
							backgroundColor: Colors.colorPrimaryTransparent,
							border: `1px solid ${Colors.colorPrimary}`
						}}
						onClick={props.onBack}
						onChange={props.setModal}>
						Back
					</Button>
				</div>
			</>
		)
	}

	return (
		<div>
			{!success && error && <ErrorText msg={error} />}
			{/* {success && <SuccessText msg={'Password changed successfully!'} />} */}
			<div className="mx-5">
				<input className="flex1 form-control my-2 mt-4" {...register('current_password', { required: true })} type="password" placeholder="Current Password" />
				{errors.current_password && <ErrorText msg="*Required" />}
				<input className="flex1 form-control my-2 mt-4" {...register('new_password', { required: true })} type="password" onChange={(e) => setNewPassword(e.target.value)} placeholder="New Password" />
				{errors.new_password && <ErrorText msg="*Required" />}
				{passwordFormatChecks()}

			</div>
		</div>
	);
};

export default ChangePassword;
