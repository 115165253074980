import React from 'react'
import { useColors } from '../../../../helpers/CustomHooks/useColors';
import SingleMatchupNfl from './SingleMatchupNfl'
// import SingleMatchupMlb from './SingleMatchupMlb'
import SingleMatchupNba from './SingleMatchupNba'

const SingleMatchup = (props) => {
  const { sport } = props

  return (
    {
      'NFL': <SingleMatchupNfl {...props} />,
      'MLB': <SingleMatchupNba {...props} />,
      'NBA': <SingleMatchupNba {...props} />,
    }[sport]
  )
}
export default SingleMatchup