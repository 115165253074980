import React from 'react'
import { motion } from 'framer-motion/dist/framer-motion';
import { slideInLeft } from './animationValues';

const OnboardSteps = (props) => {
    const { step, hasLeague, forMob} = props;
    
    const styles = {
        circle: {
            width: "30px", height: "30px", borderRadius: "50%", color: "white", background: "black"
        }
    }

    const getAnimation = () => {
        if(forMob){
            return step != 'default' ? 'show' : 'hide'
        }else{
            return "show"
        }
    }

    return (
        <motion.div className="d-flex flex-column align-items-center justify-content-center flex1" initial={false} animate={getAnimation()}
            transition={{ duration: .5, delay: .2 }}
            variants={slideInLeft} >
                {((step != "default" && forMob) || !forMob) &&  <div className='d-flex align-items-center' >
                    {hasLeague ? (
                        <>
                            <div className='mx-2 d-flex flex-column align-items-center' style={{
                                opacity: ["startImport", "default", "sportSelected", "platformSelected"].includes(step) ? 1 : .5
                            }} >
                                <p className='lead' >Import</p>
                                <div>
                                    {["leagueImported", "avatarSelected", "lineupSelected", "allDone"].includes(step) ?
                                    (<i class="fa fa-check-circle" style={{fontSize: "2rem"}} aria-hidden="true"></i>) : 
                                    (<div className='d-flex align-items-center justify-content-center bold' style={styles.circle} >
                                        1
                                    </div>)}
                                </div>
                            </div>


                            <div className='mx-2 d-flex flex-column align-items-center' style={{
                                opacity: step == "leagueImported" ? 1 : .5
                            }} >
                                <p className='lead' >Avatar</p>
                                <div>
                                    {[ "leagueImported", "avatarSelected", "lineupSelected", "allDone"].includes(step) ?
                                    (<i class="fa fa-check-circle" style={{fontSize: "2rem"}} aria-hidden="true"></i>) : 
                                    (<div className='d-flex align-items-center justify-content-center bold' style={styles.circle} >
                                        2
                                    </div>)}
                                </div>
                            </div>

                            <div className='mx-2 d-flex flex-column align-items-center' style={{
                                opacity: step == "avatarSelected" ? 1 : .5
                            }} >
                                <p className='lead' >Arena</p>
                                <div>
                                    {["avatarSelected", "lineupSelected", "allDone"].includes(step) ?
                                    (<i class="fa fa-check-circle" style={{fontSize: "2rem"}} aria-hidden="true"></i>) : 
                                    (<div className='d-flex align-items-center justify-content-center bold' style={styles.circle} >
                                        3
                                    </div>)}
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                        <div className='mx-2 d-flex flex-column align-items-center' style={{
                            opacity: ["startImport", "default"].includes(step) ? 1 : .5
                        }} >
                            <p className='lead' >Import</p>
                            <div>
                                {["platformSelected", "leagueImported", "avatarSelected", "lineupSelected", "allDone"].includes(step) ?
                                (<i class="fa fa-check-circle" style={{fontSize: "2rem"}} aria-hidden="true"></i>) : 
                                (<div className='d-flex align-items-center justify-content-center bold' style={styles.circle} >
                                    1
                                </div>)}
                            </div>
                        </div>
                        <div className='mx-2 d-flex flex-column align-items-center' style={{
                            opacity: step == "platformSelected" ? 1 : .5
                        }} >
                            <p className='lead' >League</p>
                            <div>
                                {["leagueImported", "avatarSelected", "lineupSelected", "allDone"].includes(step) ?
                                (<i class="fa fa-check-circle" style={{fontSize: "2rem"}} aria-hidden="true"></i>) : 
                                (<div className='d-flex align-items-center justify-content-center bold' style={styles.circle} >
                                    2
                                </div>)}
                            </div>
                        </div>

                        <div className='mx-2 d-flex flex-column align-items-center' style={{
                            opacity: step == "leagueImported" ? 1 : .5
                        }} >
                            <p className='lead' >Avatar</p>
                            <div>
                            {["avatarSelected", "lineupSelected", "allDone"].includes(step) ?
                                (<i class="fa fa-check-circle" style={{fontSize: "2rem"}} aria-hidden="true"></i>) : 
                                (<div className='d-flex align-items-center justify-content-center bold' style={styles.circle} >
                                    3
                                </div>)}
                            </div>
                        </div>

                        {/* <div className='mx-2 d-flex flex-column align-items-center' style={{
                            opacity: step == "avatarSelected" ? 1 : .5
                        }} >
                            <p className='lead' >Lineup</p>
                            <div>
                            {["lineupSelected", "allDone"].includes(step) ?
                                (<i class="fa fa-check-circle" style={{fontSize: "2rem"}} aria-hidden="true"></i>) : 
                                (<div className='d-flex align-items-center justify-content-center bold' style={styles.circle} >
                                    4
                                </div>)}
                            </div>
                        </div> */}

                        <div className='mx-2 d-flex flex-column align-items-center' style={{
                            opacity: step == "lineupSelected" ? 1 : .5
                        }} >
                            <p className='lead' >Funds</p>
                            <div>
                            {["allDone"].includes(step) ?
                                (<i class="fa fa-check-circle" style={{fontSize: "2rem"}} aria-hidden="true"></i>) : 
                                (<div className='d-flex align-items-center justify-content-center bold' style={styles.circle} >
                                    4
                                </div>)}
                            </div>
                        </div>
                    </>
                        )}
                </div>}
    </motion.div>
    )
}

export default OnboardSteps
