const ENDPOINTS = {
    UPDATE_GEO_DATA: "/updateGeoLocation"
  };

export const updateGeoLocation = (reqData) => {
    return window.axios.post(ENDPOINTS.UPDATE_GEO_DATA, reqData)
        .then((response) => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
        return Promise.reject(error);
    });
}
