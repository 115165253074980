import React, { useState } from 'react';
import { useColors } from '../../helpers/CustomHooks/useColors';
import CustomToggle from '../subcomponents/CustomToggle';
import { mlbPlayersByPos } from '../../helpers/Arena/ArenaHelper';
import LineupPlayerRow from './MatchMaker/LineupPlayerRow';

const MatchmakerSeasonAvg = (props) => {
  const Colors = useColors();
  const [posType, setPosType] = useState("hitters");
  const { teamView, comparisonData, sport, comparisonDataLoading, setComparisonModal, comparisonModal, setTeamView } = props;

  const handleTeamViewToggle = (view) => {
    setTeamView(view ? "opp" : "my");
  }

  return (
    <>
      <div className='d-flex justify-content-between align-items-center px-3 pt-3' style={{ background: Colors.black_90 }}>
        <div className='d-flex flex1 align-items-center'>
          <p className='m-0 bold' style={{ color: teamView == "my" ? Colors.colorLight : Colors.textColorSecondary_v2 }}>{comparisonData?.my?.teamName}</p>
          <CustomToggle onChange={handleTeamViewToggle} />
          <p className='m-0 bold' style={{ color: teamView == "opp" ? Colors.colorPrimary : Colors.textColorSecondary_v2 }}>{comparisonData?.opp?.teamName}</p>
        </div>
        <div className='d-flex'>
          <p className='m-0 mx-2 px-4 py-3 pointer' style={{ background: posType == "hitters" && Colors.colorDark, borderTopLeftRadius: 10, borderTopRightRadius: 10, fontWeight: 600, fontSize: 14 }} onClick={() => setPosType("hitters")}>Batters</p>
          <p className='m-0 mx-2 px-4 py-3 pointer' style={{ background: posType == "pitchers" && Colors.colorDark, borderTopLeftRadius: 10, borderTopRightRadius: 10, fontWeight: 600, fontSize: 14 }} onClick={() => setPosType("pitchers")}>Pitchers</p>
        </div>
        {sport !== "MLB" &&
          <div className='d-flex flex1 justify-content-end align-items-center'>
            <p className='m-0' style={{ color: Colors.colorPrimary }}>Season Avg.</p>
          </div>
        }
      </div>

      <div className='pb-2 pt-4 d-flex bold' style={{ background: Colors.colorDark }}>
        <div style={{ width: 90 }}></div>
        <div className='d-flex text-left flex1'>
          {
            !comparisonDataLoading
            &&
            Object.values(comparisonData?.seasonAvgHeader)?.map((header, index) => (
              <p className='m-0 flex1 text-center' key={index}>{header}</p>
            ))
          }
        </div>
      </div>
      <div style={{ overflow: "auto", background: teamView == "opp" ? Colors.colorPrimaryTransparent : Colors.arenaLineupPlayerRow }} className="d-flex flex-column flex1">
        {!comparisonDataLoading && mlbPlayersByPos(comparisonData[teamView]?.players, posType).map((player, index) => {
          return <LineupPlayerRow player={player} key={player.playerId} sport={sport} seasonAvgKeys={Object.keys(comparisonData?.seasonAvgHeader)} teamView={teamView} />;
        })}
      </div>
      {sport == "NFL" && <div onClick={() => setComparisonModal(!comparisonModal)}
        className="pointer p-2 px-4 d-flex align-items-center justify-content-between">
        <p className='mb-0 greenText bold' >PROJECTION</p>
        <div className='greenText bold' >
          {data.totalPoints}
        </div>
      </div>}
    </>
  )
}

export default MatchmakerSeasonAvg;