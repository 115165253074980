import React, { useEffect, useState } from 'react'
// import { Colors } from '../../../../helpers/colors'
import Divider from '../../../subcomponents/Divider'
import { motion } from 'framer-motion/dist/framer-motion'
import { collapseHorizontal, collapseLineupVertical, collapseVertical, matchupCardBgColor } from '../../../../pages/NewUserOnboarding/animationValues'
import { closeIcon } from '../../../../helpers/icons'
import { nflMatchup } from '../../../../apis/Matchmaker/ArenaScoreboard/ArenaScoreboard'
import { generatePoints, playerPoints } from '../../../../helpers/matchup-helpers'
import Skeleton from 'react-loading-skeleton'
import { NFL_UNIQUE_POS } from '../../../../helpers/common-methods'
import { nflColors } from '../../../../helpers/colors'

const SingleMatchupExpandedNfl = (props) => {
  const Colors = nflColors
  const { match_id, sport } = props
  
  const [collapsed, setCollapsed] = useState(true)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if(!collapsed && data == null) {
      callApi()
    }
  }, [collapsed])
  
  const callApi = () => {
    let qs = "?matchId=" + match_id
    nflMatchup(qs)
      .then((resp) => {
        setData(resp)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
      })
  }

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  const sortByPosMobile = () => {
    var pos = sport == NFL_UNIQUE_POS
    var playersOneArray = data.teams[0] != undefined ? Object.values(data.teams[0].players) : []
    var playersTwoArray = data.teams[1] != undefined ? Object.values(data.teams[1].players) : []
    var sortedPlayers = []
    sortedPlayers = pos && pos.map((pos, key) => {
      var playerOnes = []
      var playerTwos = []
      for (var i = 0; i < playersOneArray.length; i++) {
        if (playersOneArray[i].pos == pos) {
          playerOnes.push(playersOneArray[i])
        }
      }
      for (var i = 0; i < playersTwoArray.length; i++) {
        if (playersTwoArray[i].pos == pos) {
          playerTwos.push(playersTwoArray[i])
        }
      }
      var max = playerOnes.length > playerTwos.length ? playerOnes.length : playerTwos.length
      var posExists = playerOnes.length > 0 || playerTwos.length > 0
      if (posExists) {
        return [...Array(max).keys()].map((ind) => {
          var playerOne = playerOnes[ind] ? playerOnes[ind] : null
          var playerTwo = playerTwos[ind] ? playerTwos[ind] : null

          return (
            <div className='w-100'>
              <div className='d-flex'>
                {/* Player 1*/}
                <div className='flex1'>
                  <div>
                    <Divider/>
                  </div>
                  <div className='d-flex align-items-center my-1'>
                    {playerOne.image && <img className='img-responsive rounded-circle' src={playerOne.image} style={{ height: 24, width: 24, objectFit:'cover' }} /> }
                    <div className='flex1 mx-1 bold'>
                      <p className='m-0'>{playerOne.playerName}<span className='small' style={{color:Colors.textSecondary_v3}}> {playerOne.team+' | '+playerOne.pos} </span></p>
                      {/* <p className='m-0 small' style={{color:Colors.textSecondary_v3}}>Sun. 1:00 PM vs NYJ</p> */}
                    </div>
                    <div>
                      <p className=" m-0 bold text-right">{playerPoints(playerOne.stats, { mapping: data.meta.rules })}</p>
                      {/* <p className='m-0 small text-right'>{playerPoints(playerOne.stats, { mapping: data.meta.rules })}</p> */}
                    </div>
                  </div>
                </div>
                {/* !Player 1*/}

              {/* POS */}
              <div className='mx-4 align-self-center'>
                <h3 className='m-0 bold font-roboto-condensed' style={{color:Colors.arenaLineupPlayerRow, fontSize:24}}>{pos}</h3>
              </div>
              {/* !POS */}

              {/* Player 2*/}
              <div className='flex1'>
                  <div>
                    <Divider/>
                  </div>
                  <div className='d-flex align-items-center my-1'>
                    {playerOne.image && <img className='img-responsive rounded-circle' src={playerTwo.image} style={{ height: 24, width: 24, objectFit:'cover' }} /> }
                    <div className='flex1 mx-1 bold'>
                      <p className='m-0'>{playerTwo.playerName}<span className='small' style={{color:Colors.textSecondary_v3}}> {playerTwo.team+' | '+playerTwo.pos} </span></p>
                      {/* <p className='m-0 small' style={{color:Colors.textSecondary_v3}}>Sun. 1:00 PM vs NYJ</p> */}
                    </div>
                    <div>
                      <p className=" m-0 bold text-right">{playerPoints(playerTwo.stats, { mapping: data.meta.rules })}</p>
                      {/* <p className='m-0 small text-right'>{playerPoints(playerTwo.stats, { mapping: data.meta.rules })}</p> */}
                    </div>
                  </div>
                </div>
              {/* !Player 2*/}
              </div>
            </div>
          )
        })
      }
    })
    return sortedPlayers;
  };


  const showSkeleton = () => {
    return (
      <>
      <div className='d-flex flex1 mt-2 mx-3'>
          <div>
            <Skeleton className='rounded-circle m-0 p-0 mr-2' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor} height="30px" width="30px"/> 
          </div>

          <div className='flex1'>
            <Skeleton className='p-0' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor}/> 
            <Skeleton className='p-0' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor}/> 
          </div>
          
          <div style={{width:30, height:40}}>
            <Skeleton className='w-100 ml-3 p-0' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor}/> 
            <Skeleton className='w-100 ml-3 p-0' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor}/> 
          </div>
        </div>

        <div className='mt-2 mx-3' style={{width:40, height:40}}>
          <Skeleton className='d-inline-block h-100' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor}/>
        </div>

        <div className='d-flex flex1 mt-2 mx-3'>
          <div>
            <Skeleton className='rounded-circle m-0 p-0 mr-2' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor} height="30px" width="30px"/> 
          </div>

          <div className='flex1'>
            <Skeleton className='p-0' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor}/> 
            <Skeleton className='p-0' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor}/> 
          </div>
          
          <div style={{width:30, height:40}}>
            <Skeleton className='w-100 ml-3 p-0' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor}/> 
            <Skeleton className='w-100 ml-3 p-0' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor}/> 
          </div>
        </div>

      </>
    )
  }

  return (
    <motion.div 
      className='p-2 my-2' 
      style={{ backgroundColor: Colors.timerBg_v3, borderRadius: 10, overflow:'hidden' }}
      animate={collapsed ? 'collapsed' : 'expanded' }
      transition={{duration: 0.5}}
      initial={false}
      variants={matchupCardBgColor}
    >

      {/* Matchup Head */}
      <div className='d-flex align-items-center'>
        <motion.div
          className='d-flex align-self-center'
          onClick={toggleCollapsed}
          style={{ cursor: 'pointer' }}
          animate={collapsed ? 'collapse' : 'expand'}
          transition={{ duration: 0.5 }}
          initial={false}
          variants={collapseHorizontal}
        >
          {closeIcon()}
        </motion.div>
        <div>
          <p className='m-0 bold letter-spacing-2'>MATCHUP</p>
        </div>
        {/* <div className='d-flex flex1 align-items-center justify-content-between px-3 py-2 bold' style={{ backgroundColor: Colors.darkGreen_v3, borderRadius: 5 }}>
          <p className='m-0 small-font'><span style={{ color: Colors.colorPrimary }}>${match.isPaid ? match.possibleWinnings.totalFee : '0'}</span> Winnings</p>
          <p className='m-0 small-font'>{activeTab == 'past' ? 'Completed' : match.startDate+' - '+ match.endDate}</p>
        </div> */}
      </div>

      {/* Matchup Expanded */}
      <div
      >

        <div className='d-flex flex-column pt-2'>

          {/* HEAD */}
          <div className='d-flex' style={{backgroundColor:Colors.timerBg_v3}}>

            {/* Team 1 */}
            <div className='d-flex flex1 flex-column align-items-center justify-content-center text-center' style={{backgroundColor:Colors.arenaContentBg_v3,borderBottomRightRadius:10}}>
              <img className='roundImgMed' src={data.teams[0].teamImage} />
              <h4>{data.teams[0].teamName}</h4>
              <h3>{data && generatePoints({ mapping: data.meta.rules }, data.teams[0].players)}</h3>
              {/* <p className='small' style={{ color: Colors.textSecondary_v3 }}>Projected</p> */}
              
              {/* PMR */}
              {/* <div className='d-flex align-items-center' style={{ minWidth: 200 }}>
                <p className='my-0 mx-2'>PMR</p>
                <div className='d-flex flex1 position-relative align-items-center justify-content-end' style={{ height: 5, backgroundColor: Colors.colorSecondary_v3 }}>
                  <div className='podition-absolute' style={{ height: 10, backgroundColor: 'white', width: '30%', borderRadius: 5, zIndex: 1 }}></div>
                </div>
                <p className='my-0 mx-2'>{data && generatePoints({ mapping: data.meta.rules }, data.teams[0].players)}</p>
              </div> */}
              {/* !PMR */}

              {/* Rounded Corner */}
              {/* <div className=" w-100 m-0" style={{backgroundColor:Colors.timerBg_v3, height:10}}>
                <div className='h-100' style={{backgroundColor:Colors.arenaContentBg_v3, borderBottomRightRadius:10}}></div>
              </div> */}
            </div>
            {/* !Team 1 */}

            <div className='d-flex' style={{backgroundColor:Colors.arenaContentBg_v3}}>
              <div style={{borderTopLeftRadius:10, borderTopRightRadius:10, backgroundColor:Colors.timerBg_v3}}>
                  {/* <div style={{width:10, backgroundColor:Colors.timerBg_v3, borderBottomRightRadius:10}}></div> */}
                  <h1 className='p-4 bold roboto'>VS</h1>
                  {/* <div style={{width:10, backgroundColor:Colors.timerBg_v3, borderBottomLeftRadius:10}}></div> */}
              </div>
            </div>
            
            {/* Team 2 */}
            <div className='d-flex flex1 flex-column align-items-center justify-content-center text-center' style={{backgroundColor:Colors.arenaContentBg_v3,borderBottomLeftRadius:10}}>
              <img className='roundImgMed' src={data.teams[1].teamImage}/>
              <h4>{data.teams[1].teamName}</h4>
              <h3>{data && generatePoints({ mapping: data.meta.rules }, data.teams[1].players)}</h3>
              {/* <p className='small' style={{ color: Colors.textSecondary_v3 }}>Projected</p> */}
              
              
              {/* PMR */}
              {/* <div className='d-flex align-items-center' style={{ minWidth: 200 }}>
                <p className='my-0 mx-2'>PMR</p>
                <div className='d-flex flex1 position-relative align-items-center justify-content-end' style={{ height: 5, backgroundColor: Colors.colorSecondary_v3 }}>
                  <div className='podition-absolute' style={{ height: 10, backgroundColor: 'white', width: '30%', borderRadius: 5, zIndex: 1 }}></div>
                </div>
                <p className='my-0 mx-2'>{data && generatePoints({ mapping: data.meta.rules }, data.teams[1].players)}</p>
              </div> */}
              {/* !PMR */}

              {/* Rounded Corner */}
              {/* <div className=" w-100 m-0" style={{backgroundColor:Colors.timerBg_v3, height:10}}>
                <div className='h-100' style={{backgroundColor:Colors.arenaContentBg_v3, borderBottomLeftRadius:10}}></div>
              </div> */}
            </div>
            {/* !Team 2 */}

          </div>
          {/* !HEAD */}

          {/* BODY */}
          <div className='' style={{backgroundColor:Colors.timerBg_v3, borderRadius:10}}>
            {/* Lineup Player Row */}
              {
                loading ?
                (
                  <div className='d-flex align-items-center m-2'>
                    {showSkeleton() }
                  </div>
                )
                
                :
                (
                  <div className='d-flex flex-column align-items-center m-2'>
                    {sortByPosMobile()}
                  </div>
                )
              }
            {/* !Lineup Player Row */}
          </div>
          {/* !BODY */}
        </div>


      </div>
      {/* !Mathchup Expanded */}


    </motion.div>
  )
}

export default SingleMatchupExpandedNfl