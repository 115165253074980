import moment from 'moment-timezone'
import React, { useState } from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import { useColors } from '../../helpers/CustomHooks/useColors'
import { reactedBy } from '../../helpers/MessageHelper'
import ReactionModal from '../Chat/Templates/RactionModal'

const UserMention = (props) => {

    const Colors = useColors();

    const [isOpen, setIsOpen] = useState(false)

    const sendReaction = (emojiObject) => {
        // console.log(emojiObject)
        let data = {
            window_type:'user',
            id: props.data.msgId,
            to: props.data.userId
        }
        props.sendReaction(emojiObject, data)
        if(isOpen){
            setIsOpen(!isOpen)
        }
    }

    const handleReactionClick = (emoji) => {
        let emojiData = {
            unified: emoji.codePointAt(0).toString(16),
            native: emoji
        }

        sendReaction(emojiData)
    }

    const prepareInfo = () => {
        if(props.data.type == 'reaction') {
            return <p><span className='bold'>{props.current_team.id && reactedBy(props.data.reactionsData,'user',props.users,props.current_team.id)}</span> reacted to your message</p>
        }
        else {
            return <p><span className='bold'>{props.data.mentionData.mentionFromName} </span>  mentioned you</p>
        }
    }

  return (
    <div>
        {/* <p className='m-0 p-0' style={{color:Colors.colorSecondary}}>
            <span className="bold d-block" style={{color:'white'}}><i class="fa fa-circle-o" aria-hidden="true"></i> {props.data.teamName}</span>
            You and {props.data.teamName}
        </p> */}
        <div className="d-flex flex-column py-2 px-3 mb-2" style={{ overflow:'scroll', backgroundColor: Colors.bgDark, borderRadius: 10, border:`0.1em solid ${Colors.darkBgBorder}` }}>
            <p className='m-0' style={{color:Colors.colorSecondary}}>{prepareInfo()}</p>
            {/* Mention */}
            <div className="flex1 d-flex flex-row text-white">
                <div className="ml-0 mr-2 p-0 position-relative">
                    <img width='40px' className="rounded" src={props.data.senderImage}/>
                </div>
                <div className="m-0 flex1 p-0 pl-2">
                    <h6 className='m-0 bold' >{props.data.teamName} <span className='small' style={{color:Colors.colorSecondary}}>{moment(props.data.time).fromNow()}</span></h6>
                    <p>{props.message}</p>
                    {/* <img src="https://media1.giphy.com/media/O7ri9HBKvAUuc/giphy.gif?cid=ecf05e47a9k7kuj2aowdszcgnsprhhgpawjym4bqp0m04ign&rid=giphy.gif&ct=g" height='250px'></img> */}
                    <div className='d-flex align-content-start flex-wrap'>
                    {
                        props.data.reactionsData && Object.values(props.data.reactionsData).map(reaction => {
                            // console.log(reaction);
                            return (
                                <>
                                    <span className='py-1 px-2 mr-1 my-1' key={props.data.msgId} id={`emoji-${reaction.emoji_icon.codePointAt(0).toString(16)}-${props.data.msgId}`} style={{backgroundColor:Colors.bgLight, borderRadius:15, cursor:'pointer'}} onClick={() => handleReactionClick(reaction.emoji_icon)}>{reaction.emoji_icon} {reaction.count}</span>
                                    <UncontrolledTooltip placement='top' target={`emoji-${reaction.emoji_icon.codePointAt(0).toString(16)}-${props.data.msgId}`} delay={{show:0, hide:0}}>{props.users && Object.keys(reaction.users).map(user => {return <span className='d-block'>{props.users[user] ? props.users[user].team_name : 'You'}</span>})}</UncontrolledTooltip>
                                </>
                            )
                        })    
                    }
                    <span className='py-1 px-2 mr-1 my-1' onClick={() => setIsOpen(!isOpen)} style={{backgroundColor:Colors.bgLight, borderRadius:15, cursor:'pointer'}}><i class="fa fa-plus-circle" aria-hidden="true"></i></span>
                    </div>
                </div>
            </div>
            {/* !Mention */}

            {/* Reply */}
            {/* <div className="d-flex flex-row my-2 text-white">
                <div className="ml-0 mr-2 p-0 position-relative">
                    <i  className='fa fa-circle position-absolute' style={{color:Colors.colorPrimary,top:26, right:1}}></i>
                    <img width='40px' className="rounded" src="https://picsum.photos/200"/>
                </div>
                <div className="m-0 flex1 p-0">
                    <h6 className='m-0 bold' >Dan Gentile <span className='small' style={{color:Colors.colorSecondary}}>7:00 PM</span></h6>
                    <p>This made me laugh so hard! Then I realized he was in my lineup...</p>
                </div>
            </div> */}
            {/* !Reply */}
        </div>
        <ReactionModal isOpen={isOpen} sendReaction={sendReaction} toggle={() => setIsOpen(!isOpen)} />
    </div>
  )
}

export default UserMention