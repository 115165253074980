import React, { useEffect, useState } from 'react'
import { useColors } from '../../helpers/CustomHooks/useColors';
import { motion, Reorder, useMotionValue } from 'framer-motion/dist/framer-motion'
import { expand, progressPercentage } from '../../pages/NewUserOnboarding/animationValues'
import { duration } from 'moment-timezone'
import LeaderboardsRow from './LeaderboardsRow'

const LeaderboardsRanking = (props) => {

    let { type, data, highestScore, week } = props;
    const Colors = useColors();

    const sortRanks = (arrayOfObjects) => {
        arrayOfObjects.sort((a, b) => {
            return b.score - a.score
        })
    }

    return (
        <div className='flex1 d-flex flex-column m-2 px-2'>
            <h4 className='text-right' style={{color:Colors.colorSecondary}}>NFL 2022 <span className='text-white'>Week {week}</span></h4>
            <div className='flex1' style={{overflow:'auto'}}>
            {
               data && data.map((team, index) => {
                    return(
                        <LeaderboardsRow key={data.id} data={team} index={index} highestScore={highestScore} leaderboardsType={type} />
                    )
                }
            )}
            </div>
        </div>
    )
}

export default LeaderboardsRanking