import React, { useEffect, useState } from "react"
import { motion } from "framer-motion/dist/framer-motion"
import { slideInOut } from "./animationValues"
// import { Colors } from "../../helpers/colors";
import { useHistory } from "react-router-dom";
import GidxErrorModal from "../../modals/GidxModals/GidxErrorModal";
import GidxSuccessModal from "../../modals/GidxModals/GidxSuccessModal";
import { useSelector } from "react-redux";
import { verifyUserStatus } from "../../helpers/Payments/GidxHelper";
import LoadingOverlay from "react-loading-overlay";
import { userBalance } from "../../apis/Gidx/GidxApi";
import { shareIcon } from "../../helpers/icons";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    MailruShareButton,
    RedditShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
  } from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button } from "reactstrap";
import { STYLES } from "../../helpers/stylesHelper";
import { BASE_FE_URL } from "../../config/app";
import store from "../../store";
const OnboardingWallet = (props) => {
    const {step, updateStep, Colors} = props
    const history = useHistory()
    const [addFunds, setAddFunds] = useState(false)
    const user = useSelector(state => state.auth.user)
    const [copyText, setCopyText] = useState('Copy')
    const errorModal = useSelector(state => state.gidxErrorReducer.errorModal)
    const successModal = useSelector(state => state.gidxErrorReducer.successModal)
    const [paymentLoading, setPaymentLoading] = useState(false)
    const [balanace, setBalance] = useState({
        a: 0,
        b: 0
    })

    const openPaymentModal = () => {
        setPaymentLoading(true)
        verifyUserStatus("/payments/single").then((resp) => {
            if (resp && resp.verified) {
                setAddFunds(true)
            }
            setPaymentLoading(false)
        })
      }

      useEffect(() => {
        userBalance().then((resp) => {
            setBalance({
                a: resp.balance,
                b: resp.bonusBalance
            })
        })
    }, [])

    const getCopyText = () => {
        let invite_text = "Let's battle this week on Bettle! There is a FREE $10 waiting for you once you create an account and import your team."
		let url = BASE_FE_URL+'/'+user?.ref_id+'/challenge'+user?.username
        invite_text += url
        return invite_text
	}

    const shareUrl = () => {
        return BASE_FE_URL+"/"+user?.ref_id+"/challenge+"+user?.username
    }

    return (
        <motion.div
			className="flex-column text-center col-md-5 col-12 col-centered"
            animate={ step == "lineupSelected" ? 'lineupShow' : step == "allDone" ? 'hideLeft' :'hideRight'}
			transition={{ duration: 0.5, delay: .5 }}
            // style={{flex: step == "lineupSelected" ? 1 : 0}}
			initial={false}
            style={{ display: "flex", flex: 0}}
			variants={slideInOut}>
                {step == "lineupSelected" && (
                <LoadingOverlay
                    className={`d-flex flex-column flex1 scrollHidden`}
                    active={paymentLoading}
                    spinner
                    text='Processing...'
                    fadeSpeed={500}
                >
                <div className="d-flex flex-column flex1 mt-2 scrollHidden" >
                    {!addFunds && <div className="d-flex align-items-center justify-content-between w-100 mx-auto p-3" style={{background: "#333333", borderRadius: "5px"}} >
                        <p className="mb-0 lead" >Available</p>
                        <h1 className="bold mb-0" >${balanace.a}</h1>
                    </div>}

                    <div className="pt-5 pb-4" style={{background: "#1A1A1A", width: "100%"}} >
                        
                        <div className="d-flex align-items-center justify-content-center mb-4" >
                            {shareIcon()}
                            <p className="mb-0 alex green-font bold ml-2" style={{fontSize: "32px"}} >Share Invite</p>
                        </div>

                        <FacebookShareButton
                            url={shareUrl()}
                            className="mx-2"
                        >
                            <FacebookIcon size={62} round />
                        </FacebookShareButton>
                        <TwitterShareButton
                            url={shareUrl()}
                            className="mx-2"
                        >
                            <TwitterIcon size={62} round />
                        </TwitterShareButton>
                        <FacebookMessengerShareButton
                            url={shareUrl()}
                            className="mx-2"
                        >
                            <FacebookMessengerIcon size={62} round />
                        </FacebookMessengerShareButton>
                        <WhatsappShareButton
                            url={shareUrl()}
                            className="mx-2"
                        >
                            <WhatsappIcon size={62} round />
                        </WhatsappShareButton>
                        <EmailShareButton
                            subject="Let's battle this week on Bettle!"
                            body={shareUrl()}
                            url={shareUrl()}
                            className="mx-2"
                        >
                            <EmailIcon size={62} round />
                        </EmailShareButton>

                        <div className='mt-5 mx-md-5 mx-3 d-flex px-2 align-items-center' style={{border:`1px solid ${Colors.lightColor}`, backgroundColor: Colors.bgLight, borderRadius: 5}}>
                            <p className='m-0 text-left flex1 text-truncate'>{`${BASE_FE_URL}/${user?.ref_id}/challenge${user?.username}`}</p>
                            <CopyToClipboard text={getCopyText()} onCopy={() => setCopyText('Copied!')}>
                                <Button className="my-2" style={STYLES.BUTTON_PRIMARY_TRANSPARENT_RESPONSIVE}>
                                    <i class="fa fa-clone" aria-hidden="true" /> {copyText}
                                </Button>
                            </CopyToClipboard>
                        </div>

                        <div className="mt-5" >
                            <p onClick={() => {
                                store.dispatch({type: "HAS_LEAGUE", payload: true})
                                history.push("/newhome")
                            }} className="green-font bold pointer mb-0" >Skip for now, go to Arena</p>
                        </div>
                    </div>

                    {/* <div className="d-flex flex-column justify-content-center my-5" >
                        <h1 className="greenText mb-4" style={{fontFamily: "Fritch"}} >You are now battle ready.</h1>
                        <p className="lead w-100 mx-auto" >Your lineup’s set.</p>
                        <p className="lead w-100 mx-auto">You’ve got your wad.</p>
                        <p className="lead w-100 mx-auto" >Now go win some money.</p>
                    </div> */}

                    {/* <div className="d-flex flex-column w-100 mx-auto" >
                        <Button onClick={() => {
                            store.dispatch({type: "HAS_LEAGUE", payload: true})
                            history.push("/newhome")
                        }} style={STYLES.BUTTON_PRIMARY_TRANSPARENT} >Let’s Gooooo!</Button>
                    </div> */}

                </div>
                </LoadingOverlay>)}
                {errorModal.show && <GidxErrorModal />}
                {successModal.show && <GidxSuccessModal />}
            </motion.div>

    )
}

export default OnboardingWallet