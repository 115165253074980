import React, { useEffect } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { useColors } from '../../helpers/CustomHooks/useColors';
import { depositMeterRed, depositMeterYellow, walletIcon } from '../../helpers/icons';
import { useHistory } from 'react-router-dom';
import { normalizedBalance, splittedBalance } from '../../helpers/Arena/ArenaHelper';

const DepositFlowModal = (props) => {
    const Colors = useColors();
    const history = useHistory();
    const { isOpen, toggle, userBalance, onSubmit } = props;
    var splitBal = splittedBalance(userBalance?.balance);


    useEffect(() => {
        userBalance.depositClaimed = true
    });

    const rectangleColor = () => {
        var bal = normalizedBalance(userBalance?.balance)
        if (bal == 0) {
            return "#F67A8C"
        } else {
            return Colors.colorWarning;
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalBody>
                <div className="d-flex justify-content-between align-items-center pb-2" style={{ borderBottom: `1px solid ${Colors.darkBgBorder}` }}>
                    <div className='d-flex flex1 align-items-center'>
                        <div className="mr-1 mt-1" style={{ width: 20, height: 20, aspectRatio: 1 }} >
                            {walletIcon()}
                        </div>
                        <p className="text-truncate m-0 bold" style={{ fontSize: 20 }}>Wallet</p>
                    </div>
                    <p className='m-0 bold' style={{ fontSize: 20, color: userBalance?.balance == 0 ? Colors.colorDanger : Colors.colorPrimary }}>${userBalance?.balance}</p>
                </div>
                <div className='text-center my-3'>
                    <p className='m-0 bold' style={{ fontSize: 18 }}>Your Lineup has been successfully updated.</p>
                </div>
                {!userBalance?.depositClaimed &&
                    <div className='d-flex justify-content-center flex-column flex1 align-items-center my-3'>
                        <div style={{ border: `2px solid ${Colors.colorWarning}`, borderRadius: 10 }}>
                            <p className='m-0 px-5 py-2 bold' style={{ fontSize: 18 }}>Deposit Match</p>
                            <div className='d-flex justify-content-center align-items-center py-2' style={{ lineHeight: 1, background: Colors.colorWarning, borderBottomLeftRadius: 8, borderBottomRightRadius: 8, color: Colors.colorDark }}>
                                <p className='m-0 bold' style={{ fontSize: 15 }}>UP <br />TO</p>
                                <p className='m-0 bold ml-2' style={{ fontSize: 35 }}>$100</p>
                            </div>
                        </div>
                        <p className='m-0 text-center mt-3 px-3' style={{ fontSize: 18 }}>Make your first deposit to start playing and we’ll match <span className='bold' style={{ color: Colors.mlbPrimaryColor }}>up to $100</span></p>
                    </div>
                }
                {userBalance?.depositClaimed &&
                    <>
                        <div className='d-flex justify-content-center' style={{ position: "relative" }}>
                            {normalizedBalance(userBalance?.balance) > 0 && depositMeterYellow()}
                            {normalizedBalance(userBalance?.balance) == 0 && depositMeterRed()}
                            <div className='d-flex justify-content-between align-items-center' style={{ position: "absolute", top: 98 }} >
                                <div style={{ background: rectangleColor(), width: 18, height: 24, borderRadius: 3, margin: "2px 1px 0px 0px" }} >
                                    <p className='m-0 bold text-center' style={{ color: Colors.colorDark }}>$</p>
                                </div>
                                {[1, 2, 3, 4].map((val, index) => {
                                    var isValid = splitBal?.length >= 4 || (4 - index) <= splitBal?.length
                                    return <div key={index} style={{ background: rectangleColor(), width: 18, height: 24, borderRadius: 3, margin: "2px 1px 0px 0px" }} >
                                        <p className='m-0 bold text-center' style={{ color: Colors.colorDark }}>{isValid ? splitBal[splitBal?.length - (4 - index)] : 0}</p>
                                    </div>
                                })}
                            </div>
                        </div>
                        {normalizedBalance(userBalance?.balance) == 0 ?
                            <p className='text-center m-0 pt-3 bold' style={{ color: Colors.mlbPrimaryColor, fontSize: 18 }}>You’re running on E. <br /> It’s time to gas up.</p>
                            :
                            <div className='text-center'>
                                <p className='m-0 pt-3 bold' style={{ color: Colors.mlbPrimaryColor, fontSize: 18 }}>You’re running <br /> low on funds.</p>
                                <p className='m-0'>Reload to keep the good times rolling.</p>
                            </div>
                        }
                    </>
                }
                <div className='d-flex justify-content-between align-items-center mt-4'>
                    <Button className='mb-2' style={{ color: Colors.mlbPrimaryColor, backgroundColor: Colors.sidebarHeader, border: `1px solid ${Colors.mlbPrimaryColor}`, padding: '6px 23px 6px 23px' }} onClick={() => history.push("/wallet")}>Add Funds</Button>
                    <Button className='mb-2' style={{ color: Colors.mlbPrimaryColor, backgroundColor: Colors.sidebarHeader, border: `1px solid ${Colors.mlbPrimaryColor}`, padding: '6px 23px 6px 23px' }} onClick={() => onSubmit(false)}>Go to Arena</Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default DepositFlowModal;