import React from 'react';
import {closeIcon, myProfileIcon} from '../../../helpers/icons'
import EditView from '../../../components/subcomponents/EditView';
import { useColors } from '../../../helpers/CustomHooks/useColors';
import UserProfile from '../../../components/Matchmaker/UserProfile/UserProfile'; 
import { Modal } from 'reactstrap';

function ArenaProfile (props) {
    const {isOpen, toggle} = props;
    const color = useColors()
    const [activeView,setActiveView] = React.useState("profile")
  
        return (
            <Modal isOpen={isOpen} toggle={toggle} contentClassName="customModal">
                <div className='d-flex flex-column flex1 scrollHidden' style={{backgroundColor:color.bgDark_v3, borderRadius: 15}} >
                    <div className="px-2 my-3">
                        <div className={`d-flex align-items-center justify-content-between`} >
                            <p className='m-0 pointer' onClick={toggle}>{closeIcon()}</p>
                            <div className='d-flex align-items-center'>
                                <div>{myProfileIcon()}</div>
                                <h2 className="alex ml-2" style={{ fontSize: 16, fontWeight: 600 }}>My Profile</h2>
                            </div>
                        </div>
                    </div>
                    <div className="tabs d-flex align-items-center justify-content-around" style={{backgroundColor:color.black_90 }}>
                        <div className="profile-view py-2 flex1 text-center" style={{borderBottom: activeView === "profile" ? `2px solid ${color.mlbPrimaryColor}` : ""}} onClick={() => setActiveView("profile")}>
                            <p className='m-0 text-secondry' style={{fontSize:"16px",fontFamily:"barlow",fontweight:"600",cursor:"pointer",color: activeView === "profile" ? color.mlbPrimaryColor : "white"}} >Profile</p>
                        </div>
                        <div className="edit py-2 flex1 text-center" style={{borderBottom: activeView === "edit" ? `2px solid ${color.mlbPrimaryColor}` : ""}} onClick={() => setActiveView("edit")}>
                            <p className='m-0' style={{fontSize:"16px",fontFamily:"barlow",fontweight:"600",cursor:"pointer",color: activeView === "edit" ? color.mlbPrimaryColor : "white"}} >Edit</p>
                        </div>
                    </div>
                    <div className='container d-flex flex-column pt-2 flex1 scrollHidden'>
                        { activeView === "profile" ? <UserProfile/> : <EditView />}
                    </div>
                </div>
            </Modal>
        );
    
}


export default ArenaProfile;