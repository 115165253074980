import {
	SAVE_ACTIVE_LEAGUE_ID,
	SAVE_ACTIVE_LEAGUE,
	SAVE_ALL_LEAGUES,
	SAVE_CURRENT_LEAGUE_CHANNELS,
	SAVE_CURRENT_LEAGUE_USERS,
	SAVE_MY_TEAM_DETAILS,
	SAVE_CURRENT_TEAM,
	SAVE_NEW_CHANNEL,
	SET_ONLINE_STATUS,
	UPDATE_UNREAD_COUNT_CHANNEL,
	UPDATE_UNREAD_COUNT_USER,
	SAVE_BOTS,
	SAVE_APPLICATION_STATUS
} from '../apis/Sidebar/LeagueAPI';

export default (state = { step: 1,
      leagueId: 0,
      activeLeague: {},
      allLeagues: [],
      channels: [],
      users: [],
      my_team: {},
      current_team: {},
      is_playoff: false,
      app_status: null,
	  hasLeague: false,
      importSuccess: false,
	  onboardBySport: {}
      }, action) => {
	switch (action.type) {
		case SAVE_ACTIVE_LEAGUE_ID:
			return Object.assign({}, state, { leagueId: action.leagueId });
		case SAVE_ACTIVE_LEAGUE:
			return Object.assign({}, state, { activeLeague: action.activeLeague, activeLeagueSport: action.activeLeague.sport });
		case SAVE_ALL_LEAGUES:
			return Object.assign({}, state, { allLeagues: action.allLeagues });
		case 'SET_PLAYOFF_LEAGUE':
			return Object.assign({}, state, { is_playoff: true });
		case UPDATE_UNREAD_COUNT_CHANNEL:
		case SAVE_CURRENT_LEAGUE_CHANNELS:
			return Object.assign({}, state, { channels: {...action.channels} });
		case SAVE_NEW_CHANNEL:
			return { ...state, channels: { ...state.channels, ...action.channel } };
		case SAVE_CURRENT_LEAGUE_USERS:
		case UPDATE_UNREAD_COUNT_USER:
		case SET_ONLINE_STATUS:
			return Object.assign({}, state, { users: {...action.users} });
		case SAVE_BOTS:
			return Object.assign({}, state, { bots: action.bots });
		case SAVE_MY_TEAM_DETAILS:
			return Object.assign({}, state, { my_team: action.my_team });
		case SAVE_CURRENT_TEAM:
			return Object.assign({}, state, { current_team: action.current_team });
		case SAVE_APPLICATION_STATUS:
			return Object.assign({}, state, { app_status: action.app_status })
		case "UPDATE_IMPORT_SUCCESS":
				return Object.assign({}, state, { importSuccess: action.payload })
		case "HAS_LEAGUE":
			return Object.assign({}, state, { hasLeague: action.payload })
		case "SAVE_ACTIVE_LEAGUE_SPORT":
			return Object.assign({}, state, { activeLeagueSport: action.payload })
		case "ONBOARD_BY_SPORT":
			return Object.assign({}, state, { onboardBySport: action.payload })
		default:
			return state;
	}
};
