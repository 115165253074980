import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { getIntendedUrl, handleSocialAuth, setHttpToken, setIntendedUrl } from '../../helpers/auth';
import {Row, Col, Card, CardTitle,CardText, Button} from "reactstrap"
import { hasDob } from '../../apis/User/UserAPI';
import { errorMessage } from '../../helpers/common-methods';
import UpdateDobModal from '../../modals/UpdateDobModal';
import { useLocation } from 'react-router-dom';

const CognitoGoogleAuth = (props) => {   

    const [error, setError] = useState(null);
    const [showDobModal, setShowDobModal] = useState(false)
    const [prms, setPrms] = useState()
    const location = useLocation()
    let params = queryString.parse(props.location.hash)

    useEffect(() => {
        if(params.access_token){
            setTokens(params)
        }
        if(params.error_description){
            if(params.error_description == "PreSignUp failed with error User already exist with this email. "){
                setError("Looks like your account was registered without google auth, please try to login with username and password.")
            }
        }
      }, []);

      const setTokens = (params) => {
        // SET HTTP TOKEN
        window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + params.access_token;
        var qs = "?c=1"
        if(params.state){
            var splitted = params.state.split(",")
            splitted.map((splitStr) => {
                var equalSplitted = splitStr.split("=")
                qs = qs+ "&"+ equalSplitted[0]+"="+equalSplitted[1]
            })
        }
        hasDob(qs).then(async(resp) => {
            if(resp.hasDob){
                await handleSocialAuth(params)
                //getIntendedUrl().then((url) => {
                    //setIntendedUrl("/dashboard")
                    window.location.href = "/expCheck"
                //})
            }else{
                // SHOW DOB MODAL
                setPrms(params)
                setShowDobModal(!showDobModal)
            }
        }).catch((err) => {
            window.axios.defaults.headers.common['Authorization'] = null;
            errorMessage("Something went wrong, please try again later")
        })
      }

      const onSuccess = async () => {
        await handleSocialAuth(prms)
        //getIntendedUrl().then((url) => {
          //  setIntendedUrl("/dashboard")
            window.location.href = "/expCheck"
        //})
      }

      return (                
          <div>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
            {error ? (<Row>
                <Col sm="4" className="ht-center text-center" >
                    <Card body>
                        <CardTitle className="bold" tag="h5">Whoops</CardTitle>
                        <CardText>{error}</CardText>
                        <Button onClick={() => props.history.push("/signin")} className="green-btn" >Go to login</Button>
                    </Card>
                </Col>
            </Row>) : (<p>Loading....</p>)}
            {showDobModal && <UpdateDobModal modal={showDobModal} toggle={() => setShowDobModal(!showDobModal)} onSuccess={onSuccess} />}
          </div>
      )
}                                                                                                                                                                                                                                                       

export default CognitoGoogleAuth