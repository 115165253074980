import React, { useState } from "react";
// import { View, ScrollView, Pressable, TouchableOpacity, RefreshControl } from "react-native";
// import { Avatar,IndexPath,Select,SelectItem,Text, withStyles } from "@ui-kitten/components";
// import ProgressHeader from "../../../components/TeamProgress/ProgressHeader";
import { dstPoints, playerPoints } from "../../../helpers/matchup-helpers";
// import { wp } from "../../../components/shared/Responsiveness";
// import { errorMessage, globalStyles, IRStatuses, parsePlayerName, toastSuccess } from "../../../helpers";
import { IRStatuses, parseInjuryIcons, parsePlayerName, prepareLiveDSTData } from "../../../helpers/common-methods";
import { toastError, toastSuccess } from "../../../helpers/toasts";
import { LoaderCenter } from "../../../components/Loader";
import { swapPlayersAPI } from "../../../apis/SeasonLong/MyTeam/MyTeamAPI";
import { nflColors as Colors } from "../../../helpers/colors";
import { Row, Cell, StickyTable } from 'react-sticky-table';
import LoadingOverlay from "react-loading-overlay";
import TeamStatsView from "./TeamStatsView";
import PlayerCardModal from '../../../modals/SeasonLong/PlayerCard/PlayerCardModal';
import PerfectScrollbar from 'react-perfect-scrollbar';
// import PlayerModal from "../../../components/Players/PlayerModal";
// import TeamStatsView from "../../../components/Team/TeamStatsView";

class MyTeamNFL extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      player_id: null,
      opp_id: null,
      swapPlayerId: null,
      swapPlayerPos: null,
      isBench: null,
      swapPlayerIndex: null,
      addRow: false,
      rowId: null,
      players: props.players,
      sc_rules: props.sc_rules,
      disableDiv: false,
      league_id: props.leagueId,
      modalVisible: false,
      selectedPlayerId: null,
      actualPos: null,
      playerStatus: null,
      dropDownValues: ["Overview", "Stats", "Scoring", "Schedule"],
      viewIndex: 1,
      swapping: false,
      empty_cells: null
    }
  }

  componentDidMount() {
    this.generateChannels(this.props.players)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.viewIndex != this.props.viewIndex) {
      this.setState({ viewIndex: this.props.viewIndex })
    }
  }

  generateChannels(players) {
    // There are Two channels for updating real time data, Game Channel, Player Channel
    if (players.non_be != undefined) {
      players.non_be.map((player, playerIndex) => {
        if (player.is_live) {
          if (player.pos == "DST") {
            window.pusher.subscribe("private-dst." + player.team).bind(
              "DSTUpdate",
              (e) => {
                let players = this.state.players
                let currentStats = null;
                currentStats = prepareLiveDSTData(e, player.team);
                players.non_be[playerIndex]["current_week_stats"] = currentStats
                this.setState({ players: players })
              }
            )
          } else {
            window.pusher.subscribe('private-player.' + player.id)
              .bind('PlayerUpdate', (e) => {
                let players = this.state.players
                players.non_be[playerIndex]["current_week_stats"] = e.stats
                players.non_be[playerIndex]["stat_string"] = e.stats_string
                this.setState({ players: players })
              })
          }
          window.pusher.subscribe('private-game.' + player.game_result.game_id)
            .bind('GameUpdate', (e) => {
              let players = this.state.players
              players.non_be[playerIndex]["game_result"]["home_score"] = e.game.team1_score
              players.non_be[playerIndex]["game_result"]["away_score"] = e.game.team2_score
              players.non_be[playerIndex]["game_result"]["last_play"] = e.game.last_play
              this.setState({ players: players })
            })
        }
      })
    }

    if (players.be != undefined) {
      players.be.map((player, playerIndex) => {
        if (player.is_live) {
          if (player.pos == "DST") {
            window.pusher.subscribe("private-dst." + player.team).bind(
              "DSTUpdate",
              (e) => {
                let players = this.state.players
                let currentStats = null;
                currentStats = prepareLiveDSTData(e, player.team);
                players.be[playerIndex]["current_week_stats"] = currentStats
                this.setState({ players: players })
              }
            )
          } else {
            window.pusher.subscribe('private-player.' + player.id)
              .bind('PlayerUpdate', (e) => {
                let players = this.state.players
                players.be[playerIndex]["current_week_stats"] = e.stats
                players.be[playerIndex]["stat_string"] = e.stats_string
                this.setState({ players: players })
              })
          }
          window.pusher.subscribe('private-game.' + player.game_id)
            .bind('GameUpdate', (e) => {
              let players = this.state.players
              players.be[playerIndex]["game_result"]["home_score"] = e.game.team1_score
              players.be[playerIndex]["game_result"]["away_score"] = e.game.team2_score
              players.be[playerIndex]["game_result"]["last_play"] = e.game.last_play
              this.setState({ players: players })
            })
        }
      })
    }
  }

  updateSwapInfo(playerPos, pos, rowId, playerId, isBench, index, playerStatus) {
    let addRow = false
    if (pos != "BE") {
      addRow = true
    }
    this.setState({ swapPlayerPos: playerPos, rowId: rowId, swapPlayerId: playerId, isBench: isBench, swapPlayerIndex: index, addRow: addRow, actualPos: pos, playerStatus: playerStatus })
  }

  resetSwapInfo() {
    // remove empty spots from bench
    let players = this.state.players
    if (players.be != undefined) {
      players.be.map((player, index) => {
        if (player.is_empty == 1) {
          delete players.be[index]
        }
      })
    }
    this.setState({ swapPlayerPos: null, swapPlayerId: null, isBench: null, swapPlayerIndex: null, addRow: false, players: players })
  }

  swapPlayers(lPos, isEmpty, pl1, pl2, pli1, pli2, index) {
    this.setState({ disableDiv: true, addRow: false, swapping: true })
    let delBench = false
    let addBench = false
    let addIR = false
    if (lPos != "BE" && isEmpty && this.state.actualPos == "BE") {
      // delete row from bench
      // move player from bench to starter
      delBench = true
    } else if (lPos == "BE" && isEmpty) {
      addBench = true
    } else if ((lPos == "IR" || this.state.actualPos == "IR") && isEmpty) {
      addIR = true
    }

    swapPlayersAPI(pl1, pl2, pli1, pli2, delBench, addBench, this.state.league_id, addIR).then((resp) => {
      let playersCopy = this.state.players
      playersCopy = resp.players
      if (delBench) {
        this.setState({ swapPlayerPos: null, swapPlayerId: null, isBench: null, players: playersCopy, rowId: null, disableDiv: false, swapping: false })
        toastSuccess("Players have been swapped!")
        return 0
      }
      if (addBench) {
        this.setState({ swapPlayerPos: null, swapPlayerId: null, isBench: null, players: playersCopy, rowId: null, addRow: false, disableDiv: false, swapping: false })
        toastSuccess("Players have been swapped!")
        return 0
      }
      this.setState({ swapPlayerPos: null, swapPlayerId: null, isBench: null, players: playersCopy, addRow: false, rowId: null, disableDiv: false, swapping: false })
      toastSuccess("Players have been swapped!")
    }).catch(({ message }) => {
      this.setState({ disableDiv: false, swapping: false })
      toastError(message)
    })
  }

  getSwapCircle(player, index) {
    return <div onClick={() => this.swapPlayers(player.pos, player.is_empty, player.l_id, this.state.rowId, player.id, this.state.swapPlayerId, index)} style={{ alignItems: "center" }} >
      <div className='d-flex' style={{ border: "2px solid " + Colors.green, borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center" }} >
        <p className='m-0' > {player.pos} </p>
      </div>
    </div>
  }

  getSwapButton = (player, disable, index = null, isEmpty = false) => {
    let isBench = player.pos == "BE" ? true : false
    if (disable == null) {
      // normal case
      if (!isEmpty) {
        return <div onClick={() => this.updateSwapInfo(player.a_pos, player.pos, player.l_id, player.id, isBench, index, player.status)} style={{ alignItems: "center" }} >
          <div className='d-flex' style={{ border: "2px solid " + Colors.green, borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center" }} >
            <p className='m-0' > {player.pos} </p>
          </div>
        </div>
      }
    } else {
      if (this.state.actualPos == "IR") {
        if (player.pos == "IR" && player.id == this.state.swapPlayerId) {
          return <div onClick={() => this.resetSwapInfo()} style={{ alignItems: "center" }} >
            <div className='d-flex' style={{ border: "2px solid " + Colors.green, borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center" }} >
              <p className='m-0'> {player.pos} </p>
            </div>
          </div>
        } else if (player.a_pos == this.state.swapPlayerPos && !isEmpty && IRStatuses().includes(player.status)) {
          return <div onClick={() => this.swapPlayers(player.pos, player.is_empty, player.l_id, this.state.rowId, player.id, this.state.swapPlayerId, index)} style={{ alignItems: "center" }} >
            <div className='d-flex' style={{ border: "2px solid " + Colors.green, borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center" }} >
              <p className='m-0' > {player.pos} </p>
            </div>
          </div>
        } else if (player.a_pos == this.state.swapPlayerPos && !isEmpty && !IRStatuses().includes(this.state.playerStatus)) {
          return <div onClick={() => this.swapPlayers(player.pos, player.is_empty, player.l_id, this.state.rowId, player.id, this.state.swapPlayerId, index)} style={{ alignItems: "center" }} >
            <div className='d-flex' style={{ border: "2px solid " + Colors.green, borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center" }} >
              <p className='m-0' > {player.pos} </p>
            </div>
          </div>
        }
        else if (isEmpty && player.pos == this.state.swapPlayerPos) {
          return <div onClick={() => this.swapPlayers(player.pos, player.is_empty, player.l_id, this.state.rowId, player.id, this.state.swapPlayerId, index)} style={{ alignItems: "center" }} >
            <div className='d-flex' style={{ border: "2px solid " + Colors.green, borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center" }} >
              <p > {player.pos} </p>
            </div>
          </div>
        } else if (isEmpty && (player.pos == "FLX" && (this.state.swapPlayerPos == "RB" || this.state.swapPlayerPos == "WR" || this.state.swapPlayerPos == "TE"))) {
          return <div onClick={() => this.swapPlayers(player.pos, player.is_empty, player.l_id, this.state.rowId, player.id, this.state.swapPlayerId, index)} style={{ alignItems: "center" }} >
            <div className='d-flex' style={{ border: "2px solid " + Colors.green, borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center" }} >
              <p className='m-0' > {player.pos} </p>
            </div>
          </div>
        }
        else if (isEmpty && (player.pos == "SFLX" && (this.state.swapPlayerPos == "QB" || this.state.swapPlayerPos == "RB" || this.state.swapPlayerPos == "WR" || this.state.swapPlayerPos == "TE"))) {
          return <div onClick={() => this.swapPlayers(player.pos, player.is_empty, player.l_id, this.state.rowId, player.id, this.state.swapPlayerId, index)} style={{ alignItems: "center" }} >
            <div className='d-flex' style={{ border: "2px solid " + Colors.green, borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center" }} >
              <p className='m-0' > {player.pos} </p>
            </div>
          </div>
        } else if (isEmpty && player.pos == "BE") {
          return <div onClick={() => this.swapPlayers(player.pos, player.is_empty, player.l_id, this.state.rowId, player.id, this.state.swapPlayerId, index)} style={{ alignItems: "center" }} >
            <div className='d-flex' style={{ border: "2px solid " + Colors.green, borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center" }} >
              <p className='m-0' > {player.pos} </p>
            </div>
          </div>
        }
      } else {
        if (disable == true) {
          // disable all bench position except selected player
          // enable all SL players
          if (player.pos == "BE" && player.id == this.state.swapPlayerId) {
            return <div onClick={() => this.resetSwapInfo()} style={{ flex: 1, alignItems: "center" }} >
              <div className='d-flex' style={{ border: "2px solid " + Colors.green, borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center" }} >
                <p className='m-0' > {player.pos} </p>
              </div>
            </div>
          } else if (player.pos != "BE" && player.pos == this.state.swapPlayerPos) {
            return this.getSwapCircle(player, index)
          } else if (player.pos != "BE" && (player.pos == "FLX" && (this.state.swapPlayerPos == "RB" || this.state.swapPlayerPos == "WR" || this.state.swapPlayerPos == "TE"))) {
            return this.getSwapCircle(player, index)
          }
          else if (player.pos != "BE" && (player.pos == "SFLX" && (this.state.swapPlayerPos == "QB" || this.state.swapPlayerPos == "RB" || this.state.swapPlayerPos == "WR" || this.state.swapPlayerPos == "TE"))) {
            return this.getSwapCircle(player, index)
          } else if (player.pos == "IR" && IRStatuses().includes(this.state.playerStatus)) {
            return <div onPress={() => this.swapPlayers(player.pos, player.is_empty, player.l_id, this.state.rowId, player.id, this.state.swapPlayerId, index)} style={{ flex: 1, alignItems: "center" }} >
              <div style={{ borderWidth: 2, borderColor: Colors.colorPrimary, borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center" }} >
                <p category="p2" style={{ color: Colors.colorSecondary }}> {player.pos} </p>
              </div>
            </div>
          }
          else if (player.pos != "BE" && (player.pos == "IDP" && (this.state.swapPlayerPos == "DB" || this.state.swapPlayerPos == "NT" || this.state.swapPlayerPos == "LB" || this.state.swapPlayerPos == "DT"))) {
            return this.getSwapCircle(player, index)
          }
        } else {
          // disable all SL position except selected player
          // enable all BE players
          if (player.pos != "BE" && player.id == this.state.swapPlayerId) {
            return <div onClick={() => this.resetSwapInfo()}>
              <div className='d-flex' style={{ border: "2px solid " + Colors.colorDanger, borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center" }} >
                <p className='m-0' > {player.pos} </p>
              </div>
            </div>
          }
          else if (player.pos != "BE" && (this.state.actualPos != "FLX" && player.pos == "FLX" && (this.state.swapPlayerPos == player.a_pos)) && !isEmpty) {
            return this.getSwapCircle(player, index)
          }
          else if (player.pos != "BE" && this.state.actualPos == "FLX" && (player.pos == "RB" || player.pos == "WR" || player.pos == "TE") && (this.state.swapPlayerPos == player.pos) && !isEmpty) {
            return this.getSwapCircle(player, index)
          }
          else if (player.pos == "BE" && player.a_pos == this.state.swapPlayerPos && !isEmpty) {
            return this.getSwapCircle(player, index)
          } else if (player.pos == "IR" && IRStatuses().includes(this.state.playerStatus)) {
            return this.getSwapCircle(player, index)
          }
          else if (isEmpty && player.pos == "BE") {
            return this.getSwapCircle(player, index)
          }
        }
      }
    }
  }

  showPlayerModal = (player) => {
    this.setState({ modalVisible: !this.state.modalVisible, selectedPlayerId: player.id });
    // this.setState({ modalVisible: !this.state.modalVisible });
  };
  // THIS IS FOR OVERVIEW PLAYERS ROW
  overviewPlayer = (swapButton, player, dynamic) => {

    // return (
    //   <div style={{ alignItems: "center", flexDirection: "row", height: 80, paddingVertical: 10, borderTopWidth: 1, borderTopColor: Colors.colorLight}} >
    //     {!dynamic && <div style={{width: 200, alignItems: "center", flexDirection: "row"}} >
    //     {swapButton && !this.props.hasOpp && !player.game_started ? swapButton : (
    //       <div style={{flex: 1, alignItems: "center"}} >
    //         <div style={{borderWidth: 2, borderColor: Colors.colorLight, borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center"}} >
    //           <p category="p2" style={{ color: Colors.colorSecondary }}> {player.pos} </p>
    //         </div>
    //       </div>
    //     )}
    //     <div style={{flex: 1}} >
    //       <img size={"medium"} src={player.image} />
    //     </div>
    //     <div onPress={() => this.showPlayerModal(player)} style={{flex: 2}} >
    //       <p category="s2" style={{ color: Colors.colorSecondary, marginLeft: 2 }} >
    //         { player.is_empty == 1 ? "Empty" : parsePlayerName(player.full_name)}
    //       </p>
    //       {IRStatuses().includes(player.status) && <p category="c1" style={{ color: Colors.colorDanger, marginLeft: 2, marginTop: 3 }}>IR</p>}   
    //     </div>
    //     </div>}
    //     {dynamic && this.dynamicColumns(player)}
    // </div>)

    return (
      <Row>
        <Cell style={{ backgroundColor: Colors.bgDark }}>
          <div className='d-flex align-self-center my-4' style={{ cursor: 'pointer', height: '50px' }}>
            {swapButton && !this.props.hasOpp && !player.game_started ? swapButton : (
              <div>
                <div className='d-flex' style={{ border: "2px solid dimgrey", borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center" }} >
                  <p className='m-0' > {player.pos} </p>
                </div>
              </div>
            )}
            <div className="d-flex" onClick={() => { player.id && this.showPlayerModal(player) }}>
              <div className='ml-2 position-relative' style={{ overflow: 'hidden', maxWidth: '40px', maxHeight: '100%' }} >
                <img className='img-responsive rounded-circle' src={player.image} width='100%' height='40px' style={{ objectFit: 'cover' }} />
                <div style={{position:'absolute', bottom:0, right:0}}>
                  {parseInjuryIcons(player.injuryStatus)}
                </div>
              </div>
              <div className="ml-2 mt-2" style={{ flex: 2 }}>
                <p className='bold m-0' style={{ marginLeft: "2px" }} >
                  {player.is_empty == 1 ? "Empty" : player.full_name}
                </p>
                {IRStatuses().includes(player.status) && <p className="m-0">IR</p>}
              </div>
            </div>
          </div>
        </Cell>
        {this.dynamicColumns(player)}
      </Row>
    )
  }

  dynamicColumns = (player) => {
    if (this.state.viewIndex == 1) {
      // THIS IS OVERVIEW
      return <>
        {player.game_started ? (
          <Cell style={{ backgroundColor: Colors.bgDark }} >
            <div style={{ marginVertical: 3 }} >
              {player.game_result ? (
                <p className='m-0' >
                  {player.game_result.home_team} <span className='bold' style={{color: this.detectWinner(player.game_result.home_score, player.game_result.away_score) ? Colors.colorPrimary : ''}} >{player.game_result.home_score}</span> @ {player.game_result.away_team} <span className='bold ml-1 mr-1' style={{color: this.detectWinner(player.game_result.home_score, player.game_result.away_score) ? '' : Colors.colorPrimary}} >{player.game_result.away_score}</span>
                </p>
              ) : ("--")}
            </div>
          </Cell>
        ) : (
          player.opp != null ? (
            <Cell style={{ backgroundColor: Colors.bgDark }}>
              {player.opp.bye && !player.game_started ? (
                "BYE"
              ) : (
                <div>
                  <p className='m-0'>{player.opp.team1} @ {player.opp.team2}</p>
                  <p className='m-0'>{player.opp.date}</p>
                </div>
              )}
            </Cell>
          ) : (
            <Cell style={{ backgroundColor: Colors.bgDark }}>
              --
            </Cell>
          ))}
        <Cell style={{ backgroundColor: Colors.bgDark }}>
          <p style={{ fontWeight: "bold", textAlign: 'right' }}>
            <h2 className="d-block" style={{ color: Colors.colorPrimary }}>
              {player.a_pos == "DST" ? dstPoints(this.state.sc_rules, player.current_week_stats) : playerPoints(player.current_week_stats, this.state.sc_rules, player.a_pos)}
            </h2>

            <span className="d-block">{player.stat_string}</span>

          </p>
        </Cell>
      </>
    } else if (this.state.viewIndex != 1) {
      // THIS IS STATS
      var obj = {}
      if (this.state.viewIndex == 3) {
        obj = player.scoring
      } else if (this.state.viewIndex == 4) {
        obj = player.schedule
      }
      return <>
        {obj && Object.values(obj).map((statVal) => {
          return <Cell style={{ backgroundColor: Colors.bgDark }}>
            {this.state.viewIndex == 4 && <p>{statVal.opp}</p>}
            {this.state.viewIndex != 4 && <p>{statVal}</p>}
          </Cell>
        })}
      </>
    }
  }

  playerRow(player, disable = null, index = null, dynamic) {
    var isEmpty = player.is_empty == 1 ? true : false
    var swapButton = this.getSwapButton(player, disable, index, isEmpty)
    return this.overviewPlayer(swapButton, player, dynamic)
  }

  detectWinner(my, opp) {
    return my > opp ? "success" : "basic"
  }

  prepareRoster(dynamic = false) {
    var rosterTemplate = ["QB", "RB", "WR", "TE", "FLX", "SFLX", "DST", "IDP", "K"]
    var roster = []
    if (this.state.players != undefined || this.state.players != null) {
      if (this.state.addRow && !dynamic) {
        this.state.players.be.push({ a_pos: null, full_name: "Empty", id: 0, image: null, l_id: null, locked: false, points: "", pos: "BE", ppg: "", team: "", is_empty: 1 })
      }
      // BENCH PLAYERS
      rosterTemplate.map((val) => {
        this.state.players.non_be != undefined && this.state.players.non_be.map((player, index) => {
          if (player.pos == val) {
            if (this.state.swapPlayerId != null) {
              // means swap called
              if (this.state.isBench) {
                // disable bench and enable swap action for SL
                roster.push(
                  this.playerRow(player, true, index, dynamic)
                )
              } else {
                // disable SL and enable bench for swap action
                roster.push(
                  this.playerRow(player, false, index, dynamic)
                )
              }
            } else {
              // normal case
              roster.push(
                this.playerRow(player, null, index, dynamic)
              )
            }
          }
        })
      })

      roster.push(<Row style={{ backgroundColor: Colors.bgLight }}>
        <Cell style={{ backgroundColor: Colors.bgLight, fontWeight: 'bold' }} > {dynamic ? "" : "BENCH"}</Cell>
        {{
          1: (
            <>
              <Cell style={{ backgroundColor: Colors.bgLight }}></Cell>
              <Cell style={{ backgroundColor: Colors.bgLight }}></Cell>
            </>
          ),
          3: (
            Object.values(this.state.players.non_be[0].scoring).map((player, index) => (
              <Cell key={index} style={{ backgroundColor: Colors.bgLight }}></Cell>
            ))
          ),
          4: (
            Object.values(this.state.players.non_be[0].schedule).map((player, index) => (
              <Cell key={index} style={{ backgroundColor: Colors.bgLight }}></Cell>
            ))
          )
        }[this.state.viewIndex]}
      </Row>)

      // NON BENCH PLAYERS
      this.state.players.be.map((player, index) => {
        if (this.state.swapPlayerId != null) {
          // means swap called
          if (this.state.isBench) {
            // disable bench and enable swap action for SL
            roster.push(
              this.playerRow(player, true, index, dynamic)
            )
          } else {
            // disable SL and enable bench for swap action
            roster.push(
              this.playerRow(player, false, index, dynamic)
            )
          }
        } else {
          // normal case
          roster.push(
            this.playerRow(player, null, index, dynamic)
          )
        }
      })

      roster.push(<Row style={{ backgroundColor: Colors.bgLight }} >
        <Cell style={{ fontWeight: "bold", backgroundColor: Colors.bgLight }} > {dynamic ? "" : "INJURED RESERVED"}</Cell>
        {{
          1: (
            <>
              <Cell style={{ backgroundColor: Colors.bgLight }}></Cell>
              <Cell style={{ backgroundColor: Colors.bgLight }}></Cell>
            </>
          ),
          3: (
            Object.values(this.state.players.non_be[0].scoring).map((player, index) => (
              <Cell key={index} style={{ backgroundColor: Colors.bgLight }}></Cell>
            ))
          ),
          4: (
            Object.values(this.state.players.non_be[0].schedule).map((player, index) => (
              <Cell key={index} style={{ backgroundColor: Colors.bgLight }}></Cell>
            ))
          )
        }[this.state.viewIndex]}
      </Row>)

      this.state.players.non_be != undefined && this.state.players.non_be.map((player, index) => {
        if (player.pos == "IR") {
          if (this.state.swapPlayerId != null) {
            // means swap called
            if (this.state.isBench) {
              // disable bench and enable swap action for SL
              roster.push(
                this.playerRow(player, true, index, dynamic)
              )
            } else {
              // disable SL and enable bench for swap action
              roster.push(
                this.playerRow(player, false, index, dynamic)
              )
            }
          } else {
            // normal case
            roster.push(
              this.playerRow(player, null, index, dynamic)
            )
          }
        }
      })

    } else {
      roster.push(<Cell>You have'nt drafted team yet!</Cell>)
    }
    return roster
  }

  onSelectViewDropdown = (index) => {
    this.setState({ viewIndex: index })
  }

  dynamicHeader = () => {
    if (this.state.viewIndex == 1) {
      return (
        <>
          <Cell style={{ backgroundColor: Colors.bgDark }}>Game</Cell>
          <Cell style={{ backgroundColor: Colors.bgDark }}>Points</Cell>
        </>
      )

      // <div  style={{ display:'flex',  flexDirection: "row", height: 12, backgroundColor: "#1D2226", alignItems: "center"}}>
      //   <div style={{flex:2}} >
      //     <p category="s1" style={{ color: Colors.colorSecondary }}>
      //       Game
      //     </p>
      //   </div>

      //   <div style={{ flex: 1, alignItems: "flex-end", justifyContent: "center", paddingRight: 2}}>
      //     <p category="s1" style={{ color: Colors.colorSecondary }}>
      //       Points
      //     </p>
      //   </div>
      // </div>
    } else if (this.state.viewIndex != 1) {
      // THIS IS STATS
      var obj = {}
      // console.log(obj);
      if (this.state.viewIndex == 3) {
        obj = this.state.players.non_be[0].scoring
        console.log('SCORING:', obj);
      } else if (this.state.viewIndex == 4) {
        obj = this.state.players.non_be[0].schedule
        console.log('SCHEDULE:', obj);
      }
      return (
        Object.keys(obj).map((statVal) => {
          return <Cell style={{ backgroundColor: Colors.bgDark }} >
            {this.state.viewIndex == 4 && obj[statVal].week}
            {this.state.viewIndex != 4 && statVal}
          </Cell>
        })
      )
    }
  }

  render() {
    return (
      <div className="d-flex flex-column flex1 scrollHidden mb-4" style={{ minHeight: 0, backgroundColor: Colors.bgDark, borderRadius: 15, border: `0.01em solid ${Colors.darkBgBorder}` }}>
        <PerfectScrollbar>
          <LoadingOverlay
            active={this.state.swapping}
            spinner
            text='Swapping...'
            className="d-flex flex-column flex1 min-h-0"
          >
            {/* { !this.props.hasOpp &&<div onClick={()=>{this.props.navigation.navigate('PendingTransaction', {league_id: this.state.league_id})}} >
            <div style={{display: 'flex', padding: 10, borderWidth: 1, borderColor: Colors.borderColor,flexDirection:'row', alignItems:'center',justifyContent:'space-between'}} >
              <div style={{flex: 1}} >
                <select
                  value={this.state.dropDownValues[this.state.viewIndex-1]}
                  selectedIndex={this.state.viewIndex}
                  onSelect={(index) => this.onSelectViewDropdown(index)}
                >
                  {this.state.dropDownValues.map((val, index) => (
                    <option value={val} key={index}>{val}</option>
                  ))}
                </select>
              </div>
              <div style={{display:'flex', flexDirection:'row', alignItems:'center', flex: 1,justifyContent: "flex-end"}}>
                <p style={{}} >Pending Transactions</p>
                {this.props.trx.waiverClaims > 0 && <div style={{marginLeft:5,width:20,height:20,backgroundColor:Colors.colorDanger,borderRadius:10,alignItems:'center',justifyContent:'center'}}>
                  <p style={{fontSize:12}}>{this.props.trx.waiverClaims}</p>
                </div>}
              </div>
            </div>
          </div>} */}


            {this.state.viewIndex == 2 ? (
              <TeamStatsView players={this.state.players} showPlayerModal={this.showPlayerModal} />
            ) : (
              <div>
                <StickyTable leftStickyColumnCount={1} stickyHeaderCount={1} borderWidth={'1px'} borderColor={Colors.darkBgBorder}>
                  <Row style={{ backgroundColor: Colors.bgDark }}>
                    <Cell style={{ backgroundColor: Colors.bgDark }}>Player</Cell>
                    {this.dynamicHeader()}
                  </Row>
                  {/* <ProgressHeader /> */}
                  {this.prepareRoster().map((player, index) => {
                    return player
                  })}
                </StickyTable>
              </div>
            )}
          </LoadingOverlay>
        </PerfectScrollbar>
        <PlayerCardModal modal={this.state.modalVisible} toggle={this.showPlayerModal} playerId={this.state.selectedPlayerId} leagueId={this.props.leagueId} sport={"NFL"} />
      </div>
    );
  }
};

export default MyTeamNFL;