const ENDPOINTS = {
    FETCH_TEAM: '/sl/myTeam',
    FETCH_LIVE_MY_TEAM: '/sl/mlb/liveMyTeam',
    SWAP_PLAYERS: "/sl/swapPlayers"
  };
  
  export const fetchMyTeam = (queryString) => {
    return window.axios.get(ENDPOINTS.FETCH_TEAM+queryString)
      .then((response) => {
        return Promise.resolve(response.data.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  export const mlbLiveMyTeam = (data) => {
    return window.axios.post(ENDPOINTS.FETCH_LIVE_MY_TEAM, data)
        .then((response) => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            return Promise.reject(error);
        });
  }

  export const swapPlayersAPI = (pl1, pl2, pli1, pli2, delBench, addBench, lg_id, addIR) => {
    return window.axios.post(ENDPOINTS.SWAP_PLAYERS, { pl1, pl1, pl2: pl2, pli1: pli1, pli2: pli2, del_be: delBench, add_be: addBench, lg_id: lg_id, addIR })
        .then((response) => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            return Promise.reject(error);
        });
  }

  