import React, { useState } from 'react';
import gAuth from '../../../assets/images/gAuth.png';
import aAuth from '../../../assets/images/apple.png';
import { Button} from 'reactstrap';
import "./LoginCompV2.scss";
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { cognitoLogin } from '../../../actions/auth';
import { COGNTIO_URL } from '../../../config/app';
import { errorMessage, getStore } from '../../../helpers/common-methods';
import EmailVerificationModal from '../../../modals/EmailVerification';
import FloatingInput from '../../FloatingInput/FloatingInput';
import { useColors } from '../../../helpers/CustomHooks/useColors';
import queryString from 'query-string';

const LoginCompV2 = (props) => {

    const [spinner, setSpinner] = useState(false);
    const [error, setError] = useState();
    const history = useHistory();
    const { control, register, handleSubmit, formState: { errors } } = useForm()
    const [showVerification, setShowVerification] = useState(false);
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const {switchScreen, internal} = props
    const color = useColors()
    const parsed = queryString.parse(location.search)

    // login handler
    const onSubmit = (data) => {
        setSpinner(true);
        getStore().dispatch(cognitoLogin({ email: data.email, password: data.password })).then((resp) => {
            window.location.href = "/expCheck";
        }).catch((err) => {
            if(err?.message == "User is not confirmed."){
                setEmail(data.email);
                setPassword(data.password);
                setShowVerification(true);
              }
            errorMessage(err)
            setError(err.message);
            setSpinner(false);
        });
    };

    const handleSignUp = () => {
        if(internal){
            switchScreen("signup")
        }else{
            history.push('/register')
        }
    }

    const handleForgot = () => {
        if(internal){
            switchScreen("forgot")
        }else{
            history.push('/forgot-password')
        }
    }

    const getCogntioUrl = () => {

        let url = COGNTIO_URL + "&state=c=1"

        if(parsed?.ref){
            url += ",ref="+parsed.ref
        }

        if(parsed?.refType){
            url += ",ref_type="+parsed.refType
        }

        if(parsed?.pid){
            url += ",pid="+parsed.pid
        }
        
        return url
    }


    return (
        <div className='login-main-section d-flex flex-column justify-content-center flex1'>
            <div className='d-flex justify-content-center'>
                <div className='flex-grow-1 my-3'>

                    <div className="login-head-container mx-md-5 mx-2">
                        <h1 style={{fontSize: "16px", fontWeight: "500"}} >Login</h1>
                    </div>

                    <div className='my-4 px-md-5 px-2' >

                        <p className='welcome-heading' >Welcome back! Now let's get at it.</p>

                        <div className='d-flex mb-2'>
                            <span style={{ color: color.colorDanger }}>{error}</span>
                        </div>
                        <div className='input-section'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) =>
                                    {
                                        return <FloatingInput
                                            error={errors?.email?.message}
                                            type="text"
                                            label="Email"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                        />
                                    }}
                                    name="email"
                                    rules={
                                        {required:"Email is required"}
                                    }
                                    defaultValue={''}
                                />
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) =>
                                    {
                                        return <FloatingInput
                                            error={errors?.password?.message}
                                            type="password"
                                            label="Password"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                        />
                                    }}
                                    name="password"
                                    rules={
                                        {required:"Password is required"}
                                    }
                                    defaultValue={''}
                                />
                               
                                <Button disabled={spinner} style={{ width: "100%", height: "48px", fontSize: "16px" }} className="green-btn btn-pill mb-2 mt-3" type="submit"  id="loginHomepage">
                                    {spinner ? (
                                        <i class="fa fa-refresh fa-spin"></i>
                                    ) : (
                                        "LOGIN"
                                    )}
                                </Button>
                            </form>
                        </div>

                        <Button id="googleSignIn" onClick={() => window.location.href = getCogntioUrl()} type="button" className="btn-pill mb-2 loginBtn" >
                            <div className="d-flex align-items-center" >
                                <div><img height="40" src={gAuth} /></div>
                                <span style={{ margin: "auto", fontSize: "16px" }} >Login with Google</span>
                            </div>
                        </Button>
                        <br />
                        <Button id="appleSignIn" onClick={() => window.location.href = getCogntioUrl()} type="button" className="btn-pill mb-2 loginBtn" >
                            <div className="d-flex align-items-center" >
                                <div><img height="40" src={aAuth} /></div>
                                <span style={{ margin: "auto", fontSize: "16px" }} >Login with Apple</span>
                            </div>
                        </Button>
                    </div>

                    <div className="d-flex justify-content-center" >
                        {/* <div className='mr-3' >
                            <div><p className='green-font m-0' onClick={handleSignUp} style={{ cursor: 'pointer' }}>New to Bettle?</p></div>
                        </div> */}
                        <div className='ml-3' >
                            <div><p className='green-font m-0' onClick={handleForgot} style={{ cursor: 'pointer' }}>Forgot Password?</p></div>
                        </div>
                    </div>
                </div>
                {showVerification && (
                    <EmailVerificationModal
                        password={password}
                        modal={showVerification}
                        toggle={() => setShowVerification(!showVerification)}
                        email={email}
                    />
                )}
            </div>
        </div>
    )
};

export default LoginCompV2;