import React, { useEffect, useState } from 'react'
import { Colors } from '../../../helpers/colors';
import { chevronDownIcon, arenaRejectIcon, arenaAcceptIcon } from '../../../helpers/icons';
import { motion } from 'framer-motion/dist/framer-motion';
import { Button, Collapse } from 'reactstrap';
import { defaultColors } from '../../../helpers/colors';
import counterIcon from '../../../assets/images/counter.png'
import { MatchNotificationModal } from '../../../modals/Arena/MatchNotificationModal';
import RejectMatchCollapse from './RejectMatchCollapse';
import AcceptMatchCollapse from './AcceptMatchCollapse';
import CounterMatchCollapse from './CounterMatchCollapse';
import WinProbabilityModal from '../../../modals/Arena/WinProbabilityModal';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';

const MatchProposalCollapse = (props) => {
    
    const { matchData, sport, arenaData, refresh, winProbData, status, propMatchId, setActionMatchId } = props

    const isPaid = matchData.selectedLineup.proposedData.isPaid
    const entryFee = matchData.selectedLineup.proposedData.entryFee
    const possibleWinnings = matchData.selectedLineup.proposedData.possibleWin
    const oppData = matchData.selectedLineup
    const startDate = matchData.slot
    const oddsBoost = matchData.oddsBoost
    const matchId = matchData.selectedLineup.proposedData.matchId
    const periods = {FW: "Full Week", WD: "Weekend"};
    const middleModal = useSelector((state) => state.arenaReducer.middleModal);
    
    const Colors = defaultColors;
    const [isOpen, setIsOpen] = useState(propMatchId !== null ? true : false);

    const [targetCollapse, setTargetCollapse] = useState('default')

    const collapseStep = (title) => {
        setTargetCollapse(title)
    }

    const toggleCollapse = () => {
        setIsOpen(!isOpen)
        // setActionMatchId(null);
    }
    
    useEffect(() => {
        if (!middleModal) {
            setActionMatchId(null);
        }
    }, [middleModal]);

    return (
        <div className='d-flex flex-column flex1 scrollHidden' style={styles.container}>
            <div style={{ background: Colors.colorPrimary }}>
                <motion.div 
                    className='mx-auto pointer' style={{width: 25}}
                    onClick={toggleCollapse}
                    animate={{ rotate: isOpen ? 0 : 180 }}
                    transition={{ duration: 0.5 }}
                >
                    { chevronDownIcon() }
                </motion.div>
                <div className='d-flex flex1 justify-content-between align-items-center pb-2 px-3'>
                    <h3 className='m-0 flex1'>Proposal</h3>
                    <div className='d-flex align-items-center'>
                        <p className='m-0'>from</p>
                        <img src={oppData.teamImage} className='roundImgSmall mx-2' />
                        <p className='m-0 bold' >{oppData.teamName}</p>
                    </div>
                </div>
            </div>
            <PerfectScrollbar>
                <Collapse isOpen={targetCollapse == 'default' && isOpen}>
                    <div className='d-flex flex-column justify-content-between flex1 h-100'>
                        <div style={{ background: "linear-gradient(180deg, #03F29E 24.93%, rgba(3, 242, 158, 0) 100%)" }}>
                            <div className='py-2 px-2 mx-2' style={{ background: Colors.colorDark, color: Colors.colorLight }}>
                                <WinProbabilityModal winProbData={winProbData} status={status} slug={"counterProposal"} />
                            </div>
                        </div>

                        {/* Stats */}
                        <div className='px-2 pt-1' style={{ background: Colors.black_90, color: Colors.colorLight }}>
                            <div className='align-items-center d-flex' style={{ marginBottom: "7px" }}>
                                <p className='m-0 flex1' style={{ fontSize: 13, letterSpacing: 2 }}>BET AMOUNT</p>
                                <p className='m-0' style={styles.statBox}>{isPaid  ? `${entryFee}$` : "FREE"}</p>
                            </div>
                            { 
                                oddsBoost != null 
                                &&
                                <div className='align-items-center d-flex' style={{ marginBottom: "7px" }}>
                                    <p className='m-0 flex1' style={{ fontSize: 13, letterSpacing: 2 }}>ODDS BOOST</p>
                                    <p className='m-0' style={styles.statBox}>{oddsBoost}</p>
                                </div>
                            }

                            <div className='align-items-center d-flex' style={{ marginBottom: "7px" }}>
                                <p className='m-0 flex1' style={{ fontSize: 13, letterSpacing: 2 }}>START DATE</p>
                                <p className='m-0' style={styles.statBox}>{periods[startDate]}</p>
                            </div>

                            {
                                isPaid 
                                &&
                                <div className='align-items-center d-flex' style={{ marginBottom: "7px" }}>
                                    <p className='m-0 flex1' style={{ fontSize: 13, letterSpacing: 2 }}>POSSIBLE WINNINGS</p>
                                    <p className='m-0' style={styles.statBox}>{possibleWinnings.possibleWin}</p>
                                </div>
                            }
                        </div>
                    </div>
                    

                    {/* Actions */}
                    <div className='pb-2 mx-2' style={{ background: Colors.black_90 }}>
                        <div className="d-flex">
                            <Button className='d-flex align-items-center flex1 mr-1 justify-content-center bold' style={{color:Colors.colorDanger, backgroundColor: Colors.colorDangerTransparent, border: `1px solid ${Colors.rejectColor}`}} onClick={() => collapseStep('reject')}> 
                                <div style={styles.buttonIcon}>{arenaRejectIcon()}</div>
                                Reject
                            </Button>

                            <Button className='d-flex align-items-center flex1 ml-1 justify-content-center bold' style={{color:Colors.colorPrimary, backgroundColor: Colors.colorPrimaryDark, border: `1px solid ${Colors.mlbPrimaryColor}`}} onClick={() => collapseStep('accept')}> 
                                <div style={styles.buttonIcon}>{arenaAcceptIcon()}</div>
                                Accept
                            </Button>
                        </div>
                        <Button className='bold w-100 mt-2 mb-2' style={{color:Colors.colorWarning, backgroundColor: Colors.colorWarningTransparent, border: `1px solid ${Colors.colorWarningLight}`}} onClick={() => collapseStep('counter')}> 
                            <img src={counterIcon} style={{width: 20, height: 20, marginRight: 5}} />
                            Counter
                        </Button>
                    </div>
                </Collapse>
            </PerfectScrollbar>


            {/* Reject Collapse */}
            <RejectMatchCollapse isOpen={targetCollapse == 'reject' && isOpen} selectedLineup={oppData} toggle={() => collapseStep('default')} successCallback={refresh} />
            {/* Accept Collapse */}

            <AcceptMatchCollapse isOpen={targetCollapse == 'accept' && isOpen} selectedLineup={oppData} toggle={() => collapseStep('default')} successCallback={refresh} />

            {/* Counter Collapse */}
            <CounterMatchCollapse isOpen={targetCollapse == 'counter' && isOpen} selectedLineup={oppData} arenaData={arenaData} sport={sport} successCallback={refresh} cancelCallback={() => collapseStep('default')}  counterMatchId={matchId} isCounter={true} />
        </div>
    )
}

export default MatchProposalCollapse

const styles = {
    container: {
        // backgroundColor: Colors.colorPrimary,
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
        color:'#000'
    },
    statBox: {
        minWidth: 70,
        backgroundColor: Colors.arenaLineupPlayerRow,
        padding: 5,
        borderRadius: 5,
        textAlign: 'center',
        fontWeight: 600,
    },
    previousOffer: {
        backgroundColor: Colors.colorPrimaryLight,
        padding: 5,
        borderRadius: 5,
        marginBottom: 10
    },
    buttonIcon: {
        width: 20,
        height: 20,
        marginRight: 5
    }
}