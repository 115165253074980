import React from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Card } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ArenaGeneralRule from '../../components/Matchmaker/ArenaSettings/Rules/ArenaGeneralRule';
import LineupsScoring from '../../components/Matchmaker/ArenaSettings/Rules/LineupsScoring';
import IneligibleLocation from '../../components/Matchmaker/ArenaSettings/Rules/IneligibleLocation';
import MultipleAccounts from '../../components/Matchmaker/ArenaSettings/Rules/MultipleAccounts';
import LineupSwaps from '../../components/Matchmaker/ArenaSettings/Rules/LineupSwaps';
import ContentIntegrity from '../../components/Matchmaker/ArenaSettings/Rules/ContentIntegrity';
import ExperienceBadges from '../../components/Matchmaker/ArenaSettings/Rules/ExperienceBadges';
import Glossary from '../../components/Matchmaker/ArenaSettings/Rules/Glossary';

const MatchmakerRules = () => {
  return (
    <div className="d-flex flex-column" style={{ height: '100vh', overflow: 'hidden' }}>
      <Header />
      <div className="container d-flex flex-column flex1 scrollHidden my-2" style={{ overflow: "hidden" }} >
        <Card className="d-flex flex-column flex1" style={{ padding: "20px", overflow: "hidden" }}>
          <h1>Matchmaker Rules</h1>
          <PerfectScrollbar>
            <ArenaGeneralRule slug={"MatchMakerRules"} />
            <LineupsScoring slug={"MatchMakerRules"} />
            <IneligibleLocation slug={"MatchMakerRules"} />
            <MultipleAccounts slug={"MatchMakerRules"} />
            <LineupSwaps slug={"MatchMakerRules"} />
            <ContentIntegrity slug={"MatchMakerRules"} />
            <ExperienceBadges slug={"MatchMakerRules"} />
            <Glossary slug={"MatchMakerRules"} />
          </PerfectScrollbar>
        </Card>
      </div>
      <Footer />
    </div>
  )
}

export default MatchmakerRules;