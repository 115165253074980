import React from 'react'
import { connect } from 'react-redux'
import MLBPlayers from '../../../components/Players/MLBPlayers'
import NFLPlayers from '../../../components/Players/NFLPlayers'

const Players = (props) => {
  return (
    props.activeLeague.sport == 'MLB' ?
    <MLBPlayers {...props}/>
    :
    <NFLPlayers {...props} />
  )
}

const mapStateToProps = (state) => ({activeLeague: state.leagueJoin.activeLeague})
export default connect(mapStateToProps,null) (Players)