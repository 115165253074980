import React from 'react'
import { motion } from 'framer-motion/dist/framer-motion';
import { fadeOutUp, headerVariants, text40to16, text50to36 } from '../animationValues';
import OnboardSteps from '../OnboardSteps';
import { bettleLogoSvg } from '../../../helpers/icons';

const HeaderWeb = (props) => {
    const { step, updateStep, getUserName, hasLeague, Colors} = props

    const getAnimation = () => {
        return 'collapsed'
        // if(step == "default"){
        //     return "expanded"
        // }else{
        //     return "collapsed"
        // }
    }

    return (
        <motion.div
        className="text-dark  d-md-flex flex-column justify-content-center d-none"
        animate={getAnimation()}
        transition={{ duration: 0.5 }}
        initial={false}
        variants={headerVariants}
        style={{ backgroundColor: Colors.colorPrimary }}>
        
            <div className='d-flex align-items-center' style={{marginLeft: "4rem", marginRight: "4rem"}} >
                <div className="flex1 d-flex flex-column justify-content-center" >
                    <motion.h1
                        animate={step == 'default' ? 'from' : 'to'}
                        transition={{ duration: 0.5 }}
                        initial={false}
                        variants={text50to36}>
                        {/* {getUserName()} */}
                        <div className='w-25' style={{color: "black"}} >
                            {bettleLogoSvg()}
                        </div>
                    </motion.h1>
                </div>
                
                <div className='d-flex flex-column' style={{flex: .5}} >
                    <motion.div initial={false} animate={step == 'default' ? 'show' : 'hide'} transition={{ duration: 0.2 }} variants={fadeOutUp} >
                    </motion.div>
                    {<OnboardSteps {...props} />}

                </div>
            </div>

        </motion.div>
    )
}

export default HeaderWeb