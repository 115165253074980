import React from 'react';
import { Button, UncontrolledCollapse, Card, CardBody } from 'reactstrap';
import { Colors } from '../../../../helpers/colors';
import PerfectScrollbar from 'react-perfect-scrollbar';

function Glossary(props) {
  return (
    <div className='d-flex flex-column flex1 scrollHidden'>
      {props.slug == "MatchMakerRules" ?
        <>
          <h5 className='bold' style={{ fontSize: 20 }}>Glossary</h5>
          <div className='ml-3'>
            <div>
              <p><span className='bold'>MatchMaker: </span>A game that matches two season-long teams in a standardized contest.</p>
            </div>
            <div>
              <p><span className='bold'>Arena: </span>The place on Bettle where MatchMaker matches are conceived and played.</p>
            </div>
            <div>
              <p><span className='bold'>Match: </span>A contest between two or more teams.</p>
            </div>
            <div>
              <p><span className='bold'>Proposed Match: </span>A match that has been proposed by one user to another user.</p>
            </div>
            <div>
              <p><span className='bold'>Accepted Match: </span>Two teams have agreed to create a match.</p>
            </div>
            <div>
              <p><span className='bold'>Confirmed Match: </span>The terms of the match have been finalized and match is ready!</p>
            </div>
            <div>
              <p><span className='bold'>Max Bet: </span>The maximum dollar amount (USD) you are willing to spend on a single bet.</p>
            </div>
            <div>
              <p><span className='bold'>Active Bankroll: </span>The maximum dollar amount (USD) you are willing to spend in the MatchMaker arena.</p>
            </div>
            <div>
              <p><span className='bold'>Wallet Balance: </span>The total funds in your Bettle Wallet.</p>
            </div>
            <div>
              <p><span className='bold'>Match Start Time: </span>The start date for a match.</p>
            </div>
            <div>
              <p><span className='bold'>Odds Boost: </span>Odds of a match are increased or decreased on certain wagering opportunities. Increased odds boost means less risk for the same reward. Decreased odds boost means more risk for the same reward.</p>
            </div>
            <div>
              <p><span className='bold'>Prize Boost: </span>The winning prize of the match is increased on certain wagering opportunities.</p>
            </div>
          </div>
        </>
        :
        <>
          <div className='d-flex justify-content-start align-items-center px-3 my-2' style={{ borderBottom: `1px solid ${Colors.darkBgBorder}`, cursor: 'pointer' }} onClick={() => props.setView('default')}>
            <h5><span> <i className='mr-2 fa fa-angle-left'></i></span>Glossary</h5>
          </div>
          <PerfectScrollbar>
            <Card className='border-0 mb-0 mx-3' style={{ background: Colors.bgLight }}>
              <CardBody>
                <h5 className='bold' style={{ fontSize: 20 }}>Glossary</h5>
                <div>
                  <p><span className='bold'>MatchMaker: </span>A game that matches two season-long teams in a standardized contest.</p>
                </div>
                <div>
                  <p><span className='bold'>Arena: </span>The place on Bettle where MatchMaker matches are conceived and played.</p>
                </div>
                <div>
                  <p><span className='bold'>Match: </span>A contest between two or more teams.</p>
                </div>
                <div>
                  <p><span className='bold'>Proposed Match: </span>A match that has been proposed by one user to another user.</p>
                </div>
                <div>
                  <p><span className='bold'>Accepted Match: </span>Two teams have agreed to create a match.</p>
                </div>
                <div>
                  <p><span className='bold'>Confirmed Match: </span>The terms of the match have been finalized and match is ready!</p>
                </div>
                <div>
                  <p><span className='bold'>Max Bet: </span>The maximum dollar amount (USD) you are willing to spend on a single bet.</p>
                </div>
                <div>
                  <p><span className='bold'>Active Bankroll: </span>The maximum dollar amount (USD) you are willing to spend in the MatchMaker arena.</p>
                </div>
                <div>
                  <p><span className='bold'>Wallet Balance: </span>The total funds in your Bettle Wallet.</p>
                </div>
                <div>
                  <p><span className='bold'>Match Start Time: </span>The start date for a match.</p>
                </div>
                <div>
                  <p><span className='bold'>Odds Boost: </span>Odds of a match are increased or decreased on certain wagering opportunities. Increased odds boost means less risk for the same reward. Decreased odds boost means more risk for the same reward.</p>
                </div>
                <div>
                  <p><span className='bold'>Prize Boost: </span>The winning prize of the match is increased on certain wagering opportunities.</p>
                </div>
              </CardBody>
            </Card>
          </PerfectScrollbar>
        </>
      }
    </div>
  )
}

export default Glossary;