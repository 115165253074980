import React, { useState } from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { useColors } from '../../../helpers/CustomHooks/useColors';
import { arenaBackIcon, arenaUploadAvatarIcon } from '../../../helpers/icons';
import { setupAvatar } from '../../../apis/Matchmaker/ArenaSettings/ArenaSettings';
import { LoaderCenter } from '../../../components/Loader';
import { toastSuccess, toastError } from '../../../helpers/toasts';
import { getStore } from '../../../helpers/common-methods';

function UploadAvatarModalSett(props) {

  const Colors = useColors()

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const handleImage = (e) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      props.setImage(e.target.files[0]);
      props.setImageUrl(reader.result);
    }
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleUpdateImage = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('image', props.image);
    formData.append('teamId', props.userData.teamId);
    setupAvatar(formData).then((response) => {
      const userDataCopy = JSON.parse(JSON.stringify(props.userData));
      userDataCopy['teamImage'] = response.message;
      getStore().dispatch({ type: 'SET_ARENA_USER_DATA', payload: userDataCopy });
      toastSuccess('Avatar Updated!');
      setLoading(false);
      props.toggle();
      props.setImage(null);
    }).catch(err => {
      toastError(err.message);
      setLoading(false);
    })
  };

  return (
    <Modal isOpen={props.isOpen} toggle={loading ? '' : props.toggle}>
      <ModalBody style={{ overflow: 'scroll' }}>
        <div className='d-flex flex-column h-100 px-3'>
          <h1 className='bold' style={{ color: Colors.colorPrimary }}>Please, upload your <br /> Avatar.</h1>
          <h6 className='mt-3' style={{ color: Colors.textColorSecondary }}>IN JPEG, PNG and GIF</h6>
          {loading ? <LoaderCenter msg='Uploading...' /> :
            <>
              <div>
                <div className='d-flex justify-content-center align-items-center py-2' style={{ background: Colors.bg400, borderRadius: 5, border: `1px solid ${Colors.colorSecondaryTransparent}` }}>
                  {props.imageUrl == null ?
                    <>
                      <p className='pt-2' style={{ position: 'absolute' }}>{arenaUploadAvatarIcon()}</p>
                      <input type="file" accept="image/jpg, image/jpeg, image/png, image/gif" style={{ cursor: "pointer", position: 'relative', width: '300px', height: '100px', opacity: "0.0" }} onChange={handleImage} />
                    </>
                    :
                    <div className='d-flex justify-content-between align-items-center px-3 w-100'>
                      <div className='d-flex'>
                        <img src={props.imageUrl} alt='' style={{ maxWidth: '90px', maxHeight: '90px', borderRadius: 5 }} />
                        <h5 className='pl-2'>{props.image && props.image.name}</h5>
                      </div>
                      <p className='mt-2 bold' style={{ color: Colors.colorPrimary, cursor: 'pointer' }} onClick={() => {
                        props.setImage(null);
                        props.setImageUrl(null);
                      }}>Change</p>
                    </div>
                  }
                </div>
              </div>
              <div className='d-flex justify-content-between align-items-center px-1 mt-3'>
                <p disabled={loading} style={{ cursor: 'pointer' }} onClick={() => props.toggle()}>{arenaBackIcon()}</p>
                <Button
                  className='mb-2'
                  disabled={props.image == null}
                  style={{
                    color: Colors.colorPrimary,
                    backgroundColor: Colors.colorPrimaryTransparent,
                    border: `1px solid ${Colors.colorPrimary}`,
                    padding: '6px 23px 6px 23px'
                  }}
                  onClick={handleUpdateImage}
                >
                  Upload
                </Button>
              </div>
            </>
          }
        </div>
      </ModalBody>
    </Modal>
  )
}

export default UploadAvatarModalSett;