import React, { useState } from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
// import { Colors } from '../../helpers/colors';
import { STYLES } from '../../helpers/stylesHelper';
import moment from 'moment-timezone';
import { closeIcon } from '../../helpers/icons';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const PreviewImageModal = (props) => {

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} centered style={{ maxWidth: '96%' }}>
      <ModalBody style={{ height: '90vh' }}>
        <div className='d-flex flex-column h-100'>
          <div className='d-flex justify-content-between align-items-center px-3'>
            {props.data &&
              <div className='d-flex justify-content-start'>
                {props.data.user.image &&
                  <img src={props.data.user.image} className="roundImgMed" alt="" />
                }
                <div className='ml-2' >
                  {props.data.user.username &&
                    <h5 className='pt-1 pl-1 bold mb-1'>{props.data.user.username}</h5>
                  }
                  {props.data.attachment &&
                    <p className='pl-1' style={{ fontSize: 13 }}>{moment(props.data.ts).local().format('LT')} - {props.data.attachment}</p>
                  }
                </div>
              </div>
            }
            {props.data ?
              <p onClick={props.onClose} style={{ cursor: 'pointer' }}>{closeIcon()}</p>
              :
              <p className='d-flex justify-content-center align-items-end' onClick={props.onClose} style={{ cursor: 'pointer' }}>{closeIcon()}</p>
            }
          </div>
          <TransformWrapper
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <div className='d-flex flex-column flex1'>
                <div className='d-flex flex1 justify-content-center align-items-center'>
                  <TransformComponent>
                    {props.image &&
                      <img className='rounded' src={props.image} height='300px' alt="" />
                    }
                    <br />
                  </TransformComponent>
                </div>
                <div className='d-flex justify-content-center my-3'>
                  <Button className='ml-2' onClick={() => zoomIn()} style={STYLES.BUTTON_PRIMARY_TRANSPARENT}>
                    <i class="fa fa-search-plus" aria-hidden="true"></i>
                  </Button>
                  <Button className='ml-2' onClick={() => zoomOut()} style={STYLES.BUTTON_PRIMARY_TRANSPARENT}>
                    <i class="fa fa-search-minus" aria-hidden="true"></i>
                  </Button>
                  <Button className='ml-2' onClick={() => resetTransform()} style={STYLES.BUTTON_PRIMARY_TRANSPARENT}>
                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                  </Button>
                </div>
              </div>
            )}
          </TransformWrapper>
        </div>
      </ModalBody>
    </Modal>
  )
};

export default PreviewImageModal;