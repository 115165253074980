import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Truncate from 'react-truncate';
import { getStore, STORE } from '../../helpers/common-methods';
import check from "../../assets/images/check.png"
import { Button } from 'reactstrap';
import espn from '../../assets/images/nfl/espn.svg';
import yahoo from '../../assets/images/nfl/yahoo.png';
import { LoaderCenter } from '../Loader';
import ImportSuccess from './ImportSuccess';
import store from '../../store';

const ImportProgress = (props) => {
    const [msg, setMessage] = useState("Fetching league details...")
    const [status, setStatus] = useState("inProgress")
    const [bettleLeagueId, setBettleLeagueId] = useState()
    const {handleSuccess} = props

    const handleToast = (userId) => {
        window.Echo.private('lgImport.'+userId)
        .listen('LeagueImportUpdate', (e) => {
            e = e.data
            setMessage(e.msg)
            if(e.status == "done"){
                if(handleSuccess){
                    props.handleSuccess(e.bettleId)
                }else{
                    setBettleLeagueId(e.bettleId)
                    store.dispatch({ type: "UPDATE_IMPORT_SUCCESS", payload: true });
                }
            }
            setStatus(e.status)
        });
    }

    useEffect(() => {
        // check if user is authenticated
        const state = getStore().getState()
        if(state.auth.authenticated){
            handleToast(state.auth.user.id)
        }
    }, []);
    
    return (
        <div className="p-2"  >
                {(() => {
                    switch (status) {
                        case "inProgress":
                            return (
                                <div className="d-flex align-items-center justify-content-center" >
                                    <div style={{flex:1}} >
                                        <LoaderCenter />
                                        <h4 className="bold mb-1 text-center" >
                                            <Truncate lines={1} >
                                                Importing {props.lg.name}
                                            </Truncate>
                                        </h4>
                                        <p className="mt-3 text-center lead" >
                                            <Truncate lines={1} >
                                                {msg}
                                            </Truncate>
                                        </p>
                                        <p className="text-right mt-5" >
                                            This can take up to two minutes. Please do not refresh page
                                        </p>
                                    </div>
                                </div>
                            )
                        case "done": 
                            return (
                                // <div className="text-center" >
                                //     <h4>League Successfully Imported!</h4>
                                //     <div className="mt-4 mb-4" >
                                //         <img src={check} className="img-fluid" />
                                //     </div>

                                //     <p>You have successfully imported <b>{props.lg.name}</b> league to Bettle! You have also claimed the following team:</p>
                                //     <div className="d-flex align-items-center mt-4 justify-content-center" >
                                //         <div><img style={{border: "3px solid #03F29E"}} className="roundImgSmall mr-3" src={props.plaform == "espn" ? espn : yahoo} /></div>
                                //         <p className="bold mb-0" >{props.lg.team_name}</p>
                                //     </div>

                                //     <p className="mt-4" >You’re almost finished. Let’s invite your league members.</p>

                                //     <div className="mt-5 text-center" >
                                //         <Button onClick={() => props.showInvite()} className="green-btn default-btn btn-pill" >Invite</Button>
                                //     </div>
                                // </div>
                                <ImportSuccess bettleLeagueId={bettleLeagueId} {...props} />
                            )
                        case "failed": 
                            return (
                                <div className="text-center" >
                                    <h4>Uh oh! Something went wrong.</h4>
                                    <p>It looks like the import for {props.lg.name} didn't work. Click below to try again.</p>
                                    <div className="mt-2 text-right" >
                                        <Button onClick={() => {
                                            if(handleSuccess){
                                                window.location.href = '/new-user-onboarding/?platform='+props.platform+'&sport='+props.sport
                                            }else{
                                                window.location.href = '/home?platform='+props.platform
                                            }
                                        }} className="green-btn default-btn btn-pill" >Import Again</Button>
                                    </div>
                                </div>
                            )
                        default :
                            return null
                    }
                    })()}
            
        </div>
    )
}
  
export default connect(null, null)(ImportProgress);
