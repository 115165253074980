import { setUserData } from "../../actions/auth"
import { verifyUserIdentity } from "../../apis/Gidx/GidxApi"
import store from "../../store"
import { getStore, STORE } from "../common-methods"

export const verifyUserStatus = (customSlug = undefined) => {
    return verifyUserIdentity().then((resp) => {
        return parseProfleStatus(resp.status, customSlug)
    }).catch((err) => {
        toggleGidxErrorModal({
            msg: "Something went wrong, please contact support@bettle.co",
            path: exp == "SEASON_LONG" ? "/dashboard" : "/arena/home"
        })
    })
}

export const parseProfleStatus = (status, customSlug) => {
    var path = ""
    // GET EXPERIENCE
    const exp = store.getState().leagueJoin.app_status
    if(customSlug){
        path = customSlug
    }else{
        if(exp == "SEASON_LONG"){
            path = "/payments"
        }else{
            path = "/payments/mm"
        }
    }

    if(status == "NOT_VERIFIED"){  
        toggleGidxErrorModal({
            msg: "We were not able to verify your Identity, please verify your Identity before making transactions",
            path: path+"/verifyIdentity"
        })
    }else if (status == "NEED_ID"){
        toggleGidxErrorModal({
            msg: "We were not able to verify your Identity, please upload your ID photo",
            path: path+"/idUpload"
        })
    }else if (status == "ID_UPLOADED"){
        toggleGidxErrorModal({
            msg: "Your Identity verification is in process, we will notify your as soon as we verify it.",
            path: exp == "SEASON_LONG" ? "/payments/wallet" : ""
        })
    }else if (status == "BLOCKED"){
        toggleGidxErrorModal({
            msg: "Your account has blocked, please contact support@bettle.co",
            path: exp == "SEASON_LONG" ? "/payments/wallet" : ""
        })
    }else if (status == "LOCATION_BLOCKED"){
        toggleGidxErrorModal({
            msg: "Your location is blocked, you can't create paid matches.",
            path: ""
        })
    }else if (status == "DUPLICATE_PROFILES"){
        toggleGidxErrorModal({
            msg: "We found multiple accounts with your information, please contact support@bettle.co",
            path: exp == "SEASON_LONG" ? "/payments/wallet" : ""
        })
    }else if (status == "INVALID_DATA"){
        toggleGidxErrorModal({
            msg: "We found invalid information for your account, please contact support@bettle.co",
            path: exp == "SEASON_LONG" ? "/payments/wallet" : ""
        })
    }else if (status == "VERIFIED"){
        return {verified: true}
    }else{
        toggleGidxErrorModal({
            msg: "Something went wrong, please contact support@bettle.co",
            path: exp == "SEASON_LONG" ? "/dashboard" : "/arena/home"
        })
    }
}

export const toggleGidxErrorModal = (data = null) => {
    getStore().dispatch({
        type: "ERROR_MODAL",
        payload: {show: !getStore().getState().gidxErrorReducer.errorModal.show, data: data}
    })
}

export const toggleGidxSuccessModal = (data = null) => {
    getStore().dispatch({
        type: "GIDX_SUCCESS_MODAL",
        payload: {show: !getStore().getState().gidxErrorReducer.successModal.show, data: data}
    })
}

export const updateUserStatus = (status) => {
    var user = {...getStore().getState().auth.user}
    user.idVerified = status
    getStore().dispatch(setUserData(user))
}

export const ccFormat = (value) => {
    if(value){
      var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
      var matches = v.match(/\d{4,16}/g);
      var match = matches && matches[0] || ''
      var parts = []
  
      for (var i=0, len=match.length; i<len; i+=4) {
          parts.push(match.substring(i, i+4))
      }
  
      if (parts.length) {
          return parts.join(' ')
      } else {
          return value
      }
    }
    return ''
}