import React from 'react'
import { motion } from 'framer-motion/dist/framer-motion';
import ClaimTeamSteps from './CalimTeamSteps';
import { fadeOutUp, headerVariants, text40to16, text50to36 } from '../NewUserOnboarding/animationValues';
import { Colors } from '../../helpers/colors';
import freeBadge from "../../assets/images/freeBadge.png"
import { bettleLogoSvg } from '../../helpers/icons';

const ClaimHeaderWeb = (props) => {
    const { step, updateStep, getUserName, hasLeague} = props

    const getAnimation = () => {
        return 'collapsed'
        // if(step == "default"){
        //     return "expanded"
        // }else{
        //     return "collapsed"
        // }
    }

    return (
        <motion.div
        className="text-dark flex-column justify-content-center d-none d-md-flex"
        animate={getAnimation()}
        transition={{ duration: 0.5 }}
        initial={false}
        variants={headerVariants}
        style={{ backgroundColor: Colors.colorPrimary }}>
        
            <div className='d-flex align-items-center' style={{marginLeft: "4rem", marginRight: "4rem"}} >
                <div className="flex1 d-flex flex-column justify-content-center" >
                    {/* <motion.p
                        animate={step == 'default'? 'from' : 'to'}
                        transition={{ duration: 0.5 }}
                        initial={false}
                        variants={text40to16}
                        className="mb-0"
                        >
                        Welcome to the Bettle Universe.
                    </motion.p> */}
                    <motion.h1
                        animate={step == 'default' ? 'from' : 'to'}
                        transition={{ duration: 0.5 }}
                        initial={false}
                        variants={text50to36}>
                        {/* {getUserName()} */}
                        <div className='w-25' style={{color: "black"}} >
                            {bettleLogoSvg()}
                        </div>
                    </motion.h1>
                </div>
                
                <div className='d-flex flex-column' style={{flex: .5}} >
                    <motion.div initial={false} animate={step == 'default' ? 'show' : 'hide'} transition={{ duration: 0.2 }} variants={fadeOutUp} >
                        {/* {!hasLeague && <div className="position-absolute" style={{right: 0, top: "3%"}} >
                            <img src={freeBadge} />
                        </div>}

                        {!hasLeague && (
                            <div>
                                <p className='mb-0 bold' >Get some action with</p>
                                <h3 className='bold my-3' >$25 of House Money.</h3>
                                <p>It’s risk-free to try, keep the winnings in cash. (NO credit card info needed to get started!)</p>
                            </div>
                        )}
                        {hasLeague && <div>
                            <p className='mb-2 bold' >Let's Get Started</p>
                            <p className='mb-2' >Having you as a part of Bettle is a great honor!</p>
                        </div>}
                        <button
                            className="py-2 px-3 btn-block"
                            style={{ borderRadius: 50, color: Colors.colorPrimary, backgroundColor: Colors.bgDark }}
                            onClick={() => updateStep("startClaim")}>
                            <i class="fa fa-download" aria-hidden="true" />{" "}Claim Team
                        </button> */}
                    </motion.div>
                    <ClaimTeamSteps {...props} />
                </div>
            </div>

        </motion.div>
    )
}

export default ClaimHeaderWeb