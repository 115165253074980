import React, { Component } from 'react';
import { Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
// import { Colors } from '../../../helpers/colors';
import Account from './Account';
import ChangePassword from './ChangePassword';
import NotificationPref from './NotificationPref';
import { ColorContext } from '../../../context/ColorProvider';

export default class ProfileModal extends Component {
	constructor(props) {
		super(props);
	}
  static contextType = ColorContext;

	render() {
    const Colors = this.context;
		return (
			<Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className={this.props.className}>
        <div className='d-flex justify-content-between align-items-center' style={{padding: "15px 30px 0px 30px"}} >
          <h4 className="bold text-truncate">Account</h4>
          <i className="cui-circle-x pointer" onClick={this.props.onClick} style={{ fontSize: '25px' }} />
        </div>
				<ModalBody >
              {this.props.currentViewModal == "info" && <div>
                <div className="mx-0 p-3 w-100" style={{ borderBottom: `0.01em solid ${Colors.darkBgBorder}`, cursor: 'pointer' }}>
                  <span>
                    <h5 onClick={this.props.onClickedit} className="p-0 m-0">
                      <i class="fa fa-pencil-square text-white mr-3" aria-hidden="true" />Edit Profile
                    </h5>
                  </span>
                </div>
                <div className="mx-0 p-3 w-100" style={{ borderBottom: `0.01em solid ${Colors.darkBgBorder}`, cursor: 'pointer' }}>
                  <span>
                    <h5 onClick={this.props.onClicknotify} className="p-0 m-0">
                      <i class="fa fa-bell text-white mr-3" aria-hidden="true" />Notifications Preferences
                    </h5>
                  </span>
                </div>
                <div className="mx-0 p-3 w-100" style={{ borderBottom: `0.01em solid ${Colors.darkBgBorder}`, cursor: 'pointer' }}>
                  <span>
                    <h5 onClick={this.props.onClickpass} className="p-0 m-0">
                      <i class="fa fa-repeat text-white mr-3" aria-hidden="true" />Change Password
                    </h5>
                  </span>
                </div>
                <div className="mx-0 p-3 w-100" style={{ borderBottom: `0.01em solid ${Colors.darkBgBorder}`, cursor: 'pointer' }}>
                  <span>
                    <h5 onClick={this.props.logoutUser} className="p-0 m-0">
                      <i class="fa fa-sign-out text-white mr-3" aria-hidden="true" />Logout
                    </h5>
                  </span>
                </div>
              </div>}
            {this.props.currentViewModal === 'editprofile' && <Account auth={this.props.auth} onOkay={this.props.onOkay} onBack={this.props.onBack} />}
            {this.props.currentViewModal === 'notifications' && <NotificationPref onOkay={this.props.onOkay} onBack={this.props.onBack} auth={this.props.auth} />}
            {this.props.currentViewModal === 'password' && <ChangePassword onOkay={this.props.onOkay} onBack={this.props.onBack} auth={this.props.auth} />}
				</ModalBody>
			</Modal>
		);
	}
}
