import React, { Component } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar';
import { arenaChartIcon, arenaHeartIcon,  arenaIcon,  chatIcon, matchupIcon, newArenaIcon, referralFlowSmIcon, settingsIcon, walletIcon, myProfileIcon } from '../../../helpers/icons';
import { withRouter } from 'react-router-dom';
import { getStore, sportFromExperience } from '../../../helpers/common-methods';
import store from "../../../store"
import { connect } from 'react-redux';
import { ColorContext } from '../../../context/ColorProvider';
import FullWidthButton from '../../../components/subcomponents/FullWidthButton';
import { enableSports } from '../../../helpers/Arena/ArenaHelper';
import { userBalance } from '../../../apis/Gidx/GidxApi';
import ReferralFlowModal from '../../../modals/Arena/ReferralFlowModal';


class Sidebar extends Component {
    // const Colors = useContext(ColorContext);
    // ARENA HOME

    constructor(props) {
        super(props);
        this.state = {
            experience: store.getState().leagueJoin.app_status,
            copyText: <i class="fa fa-clone" aria-hidden="true"></i>,
            balance: 0,
            referralFlow: false
        };
    }

    static contextType = ColorContext;

    getMenuItemStatus(path, type = 'url'){
        const Colors = this.context;
        if(type == 'url'){
            if(this.props.match.path.includes(path)){
                return Colors.colorPrimary;
            }
            else{
                return ''
            }
        }
        else if(type == 'param'){
            if(this.props.location.search.includes(path)){
                return Colors.colorPrimary;
            }
            else{
                return ''
            }
        }
        else{
            return ''
        }
        
    }

    isArenaHomeActive(){
        if(this.props.match.path.includes('newHome')){
            return true;
        }
        else{
            return false;
        }
    }

    callApi = () => {
        userBalance().then((resp) => {
            getStore().dispatch({ type: "SET_USER_BALANCE", payload: resp });
            this.setState({ balance: resp.balance });
        }).catch((err) => {
            console.log(err);
        })
    };
    
    componentDidMount(){
        this.callApi()
    }

    // getCopyText(){
    //     let auth = getStore().getState().auth;
    //     let sport = sportFromExperience(store.getState().leagueJoin.app_status);
    //     return `${BASE_FE_URL}/${auth?.user?.ref_id}/challengeme/${auth?.user?.username}/${sport}`;
    // }

    render() {
        const Colors = this.context;
        const sport = store.getState().arenaReducer.sport
        const {activeTeam} = this.props
        return ( 
            <div className="d-flex flex1 flex-column pr-0 h-100">
                    <PerfectScrollbar className='flex1' >
                        {/* Sidebar Nav Container */}
                            {/* Main Menu */}
                            <div>
                                <div className='d-flex flex-column justify-content-center align-items-center text-center mb-4 pointer' style={{ background: Colors.bgDark_v3, border: `1px solid ${Colors.mlbPrimaryColor}`, borderRadius: 12, height: "82px" }} onClick={() => this.setState({ referralFlow: true })}>
                                    <div className='d-flex justify-content-center pt-2 mb-0'>
                                        <p className='m-0' style={{ fontSize: 10, fontWeight: 600, letterSpacing: 2 }}>REFERRAL</p>
                                        <p className='m-0 mx-1'>{referralFlowSmIcon()}</p>
                                        <p className='m-0' style={{ fontSize: 10, fontWeight: 600, letterSpacing: 2 }}>PROGRAM</p>
                                    </div>
                                    <p className='m-0 alex' style={{ fontSize: 28, fontWeight: 600, color: Colors.mlbPrimaryColor }}>Give $10, Get $10!</p>
                                </div>
                                {/* Active Arena */}
                                {/* <div className="d-flex align-items-center pl-3 pr-3 mb-2 mt-2">
                                    <div className="" style={{width:40, height:40, backgroundColor:Colors.bgDark, borderRadius:5}}>
                                        <img src={experience == 'BASEBALL_ARENA' ? baseballArena : footballArena} style={{height:'100%', width:'100%', borderRadius:5}}/>
                                    </div>
                                    <p className='ml-2 m-0 flex1'>
                                        <p className='bold mb-0'>{experience == 'BASEBALL_ARENA' ?  "Baseball" : "Football"}</p>
                                        <p className='text-muted small mb-0'>Arena H2H</p>
                                    </p>
                                    <div style={{width:20, height:20, aspectRatio:1, cursor:'pointer'}} onClick={() => this.props.history.push('/arena/'+sport+'/settings')} >
                                        {settingsIcon()}
                                    </div>
                                </div> */}
                                {/* !Active Arena */}


                                {/* Single Menu Item */}
                                {/* <div className="d-flex mx-2 mt-4 mb-1 px-2" onClick={() => this.props.history.push('/arena/home')} style={{borderRadius:5, backgroundColor:`${this.getMenuItemStatus('dashboard')}`, cursor:'pointer' }}>
                                    <div className="d-flex align-items-center">
                                        <div className="mr-2" style={{width:20, height:20, aspectRatio:1}} >
                                            {arenaIcon()}
                                        </div>
                                        <p className="text-truncate m-0"  style={{cursor:'pointer' }}>Arena</p>
                                    </div>
                                </div> */}
                                {/* !Single Menu Item */}

                                {/* Single Menu Item */}
                                <div className="d-flex mb-3 px-2 align-items-center" onClick={() => this.props.history.push('/newHome?activeTab=matchups')} style={{color:`${this.getMenuItemStatus('matchups', 'param')}`, cursor:'pointer'}}>
                                    <div className="d-flex flex1 align-items-center">
                                        <div className="mr-2" style={{width:20, height:20, aspectRatio:1}} >
                                            {matchupIcon()}
                                        </div>
                                        <p className="text-truncate m-0 big-font"  style={{ cursor:'pointer', fontWeight: "600", fontFamily: "Barlow" }}>Matchups</p>
                                    </div>
                                    <div>
                                        {activeTeam?.confirmedMatchCount > 0 && <h6 className='p-1 m-0 bold text-center text-white' style={{borderRadius:15, backgroundColor:Colors.skeletonHighlightColor, minWidth:25, minHeight:25}}>{activeTeam?.confirmedMatchCount}</h6> }
                                    </div>
                                </div>
                                {/* !Single Menu Item */}

                                {/* Single Menu Item */}
                                <div className="d-flex mt-4 mb-1 px-2 align-items-center" onClick={() => this.props.history.push('/newHome?activeTab=pending')} style={{color:`${this.getMenuItemStatus('pending', 'param')}`, cursor:'pointer' }}>
                                    <div className="d-flex flex1 align-items-center">
                                        <div className="mr-2" style={{width:20, height:20, aspectRatio:1}} >
                                            {arenaHeartIcon()}
                                        </div>
                                        <p className="text-truncate m-0 big-font"  style={{ cursor:'pointer', fontWeight: "600", fontFamily: "Barlow"}}>Pending</p>
                                    </div>
                                    <div>
                                        {activeTeam?.pendingCount > 0 && <h6 className='p-1 m-0 text-dark bold text-center text-white' style={{borderRadius:15, backgroundColor:Colors.colorPrimary, minWidth:25, minHeight:25}}>{activeTeam?.pendingCount}</h6> }
                                    </div>
                                </div>
                                {/* !Single Menu Item */}

                                {/* Single Menu Item */}
                                {/* <div className="d-flex mx-2 mt-4 mb-1 px-2 align-items-center" onClick={() => this.props.history.push('#')} style={{color:`${this.getMenuItemStatus('performance')}`, cursor:'pointer' }}>
                                    <div className="d-flex flex1 align-items-center">
                                        <div className="mr-2" style={{width:20, height:20, aspectRatio:1}} >
                                            {arenaChartIcon()}
                                        </div>
                                        <p className="text-truncate m-0 big-font"  style={{cursor:'pointer' }}>Performance</p>
                                    </div>
                                    <div>
                                        <p className='px-1 m-0 sub-font' style={{color:Colors.colorInfo, border:`1px solid ${Colors.colorInfo}`}}>Coming Soon!</p>
                                    </div>
                                </div> */}
                                {/* !Single Menu Item */}

                                {/* Single Menu Item */}
                                <div className="d-flex mt-4 mb-1 px-2 align-items-center" style={{color:`${this.getMenuItemStatus('managers')}`, cursor:'pointer' }}>
                                    <div className="d-flex flex1 align-items-center" onClick={() => this.props.history.push('/arena/managers')}>
                                        <div className="mr-2" style={{width:20, height:20, aspectRatio:1}} >
                                            {chatIcon()}
                                        </div>
                                        <p className="text-truncate m-0 big-font"  style={{ cursor:'pointer', fontWeight: "600", fontFamily: "Barlow" }}>Managers</p>
                                    </div>
                                    {/* <div>
                                        <p className='px-1 m-0' style={{backgroundColor: Colors.mlbPrimaryColor, fontSize: 14, fontWeight: 600, color: Colors.colorDark, borderRadius: 6}} onClick={() => this.setState({ referralFlow: true })}>Give $10, Get $10</p>
                                    </div> */}
                                </div>
                                {/* !Single Menu Item */}

                                {/* Single Menu Item */}
                                <div className="d-flex mt-4 mb-1 px-2 align-items-center" onClick={() => this.props.history.push('/wallet')} style={{color:`${this.getMenuItemStatus('wallet')}`, cursor:'pointer' }}>
                                    <div className="d-flex flex1 align-items-center">
                                        <div className="mr-2" style={{width:20, height:20, aspectRatio:1}} >
                                            {walletIcon()}
                                        </div>
                                        <p className="text-truncate m-0 big-font"  style={{ cursor:'pointer', fontWeight: "600", fontFamily: "Barlow" }}>Wallet</p>
                                    </div>
                                    <div>
                                        <h5 className='m-0 alex' style={{color:Colors.colorPrimary, fontWeight: 600}}>${this.state.balance}</h5>
                                    </div>
                                </div>
                                {/* !Single Menu Item */}

                                {/* Profile Menu Item */}
                                <div className="d-flex mt-4 mb-1 px-2 align-items-center" onClick={() => this.props.stateCallback("profileModal", true)} style={{color:`${this.getMenuItemStatus('profile')}`, cursor:'pointer' }}>
                                    <div className="d-flex flex1 align-items-center">
                                        <div className="mr-2" style={{width:20, height:20, aspectRatio:1}} >
                                            {myProfileIcon()}
                                        </div>
                                        <p className="text-truncate m-0 big-font"  style={{ cursor:'pointer', fontWeight: "600", fontFamily: "Barlow",color:"white" }}>Profile</p>
                                    </div>
                                    <div>
                                        {/* <h5 className='m-0 alex' style={{color:Colors.colorPrimary, fontWeight: 600}}>${this.state.balance}</h5> */}
                                    </div>
                                </div>
                                {/* Profile Menu Item */}

                                {/* Single Menu Item */}
                                <div className="d-flex mt-4 mb-1 px-2 align-items-center" onClick={() => this.props.history.push(`/arena/${sport}/settings`)} style={{color:`${this.getMenuItemStatus('settings')}`, cursor:'pointer' }}>
                                    <div className="d-flex flex1 align-items-center">
                                        <div className="mr-2" style={{width:20, height:20, aspectRatio:1}} >
                                            {settingsIcon()}
                                        </div>
                                        <p className="text-truncate m-0 big-font"  style={{ cursor:'pointer', fontWeight: "600", fontFamily: "Barlow" }}>Settings</p>
                                    </div>
                                </div>
                                {
                                    this.isArenaHomeActive() && 
                                    <div className='my-4'>
                                        {/* TODO: HACK: Make Dynamic */}
                                        { enableSports().includes(sport) && <FullWidthButton className='bold font-lg' sport={sport.toUpperCase()} text={`ENTER THE ${sport || ''} ARENA`} style={{color:'#000000'}} borderType={"circular"} onClick={() => store.dispatch({type: "SET_START_ONBOARDING", payload: true})}/>}
                                    </div>
                                }
                                {/* !Single Menu Item */}

                                {/* Single Menu Item */}
                                {/* <div
                                    className="d-flex mx-2 my-1 px-2 py-2"
                                    onClick={() => this.props.history.push('/refer')}
                                    style={{ borderRadius: 5, backgroundColor: `${this.getMenuItemStatus('/refer')}`, cursor: 'pointer' }}>
                                    <div className="d-flex align-items-center flex1">
                                        <div className="mr-2" style={{ width: 15, height: 15, aspectRatio: 1 }}>
                                            {inviteIcon()}
                                        </div>
                                        <p className="text-truncate m-0 flex1" style={{ cursor: 'pointer' }}>
                                            Refer a Friend
                                        </p>
                                        <Badge className='pull-right' pill style={{backgroundColor: Colors.colorPrimary}}>$10</Badge>
                                    </div>
                                </div> */}
                                {/* !Single Menu Item */}

                                {/* Single Menu Item */}
                                {/* <CopyToClipboard text={this.getCopyText()} onCopy={() => this.setState({copyText: 'Copied!'})} >
                                    <div
                                        className="d-flex mx-2 my-1 px-2 py-2"
                                        style={{ borderRadius: 5, backgroundColor: `${this.getMenuItemStatus('/refer')}`, cursor: 'pointer' }}>
                                        <div className="d-flex align-items-center flex1">
                                                <>
                                                    <div className="mr-2" style={{ width: 15, height: 15, aspectRatio: 1 }}>
                                                        {challengeIcon()}
                                                    </div>
                                                    <p className="text-truncate m-0 flex1" style={{ cursor: 'pointer' }}>
                                                        Challenge Me
                                                    </p>
                                                    <Badge className='pull-right' pill style={{backgroundColor: Colors.colorPrimary}}>{this.state.copyText}</Badge>
                                                </>
                                        </div>
                                    </div>
                                </CopyToClipboard> */}
                                {/* !Single Menu Item */}

                            </div>
                    </PerfectScrollbar>
                    {this.state.referralFlow && <ReferralFlowModal isOpen={this.state.referralFlow} toggle={() => this.setState({ referralFlow: !this.state.referralFlow })} />}
            </div>
         );
    }
}
 
const mapStateToProps = (state) => ({activeTeam: state.arenaReducer.active_team})
export default connect(mapStateToProps, null) (withRouter(Sidebar));