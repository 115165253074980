import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
// import { Colors } from '../../../helpers/colors'
import { mergeAndSum } from '../../../helpers/common-methods'
import { rotoStandingsFormula } from '../../../helpers/MLB/Points'
import { useColors } from '../../../helpers/CustomHooks/useColors';

const RotoBet = (props) => {
    const Colors = useColors();
    const { bet, recent } = props
  

    const handleNavigate = () => {
      props.history.push('/my-bets/h2h-matchup', {
        league: props.league,
        id: bet.ch_id,
        team1Totals: findMyObject(bet.from.userId).totalPoints,
        team2Totals: findMyObject(bet.to.userId).totalPoints,
      })
    }

    const prepareStandings = () => {
      var prepareStandings = {}
      var selectedCatsDaily = []
      // PREPARE OBJECT AND ALSO PUT TOTAL POINTS
      prepareStandings[bet.from.userId] = {
          stats: mergeAndSum(bet.lineups.from_lineup.total, bet.lineups.from_lineup.current),
          points: {},
          userId: bet.from.userId
      }
      prepareStandings[bet.to.userId] = {
          stats: mergeAndSum(bet.lineups.to_lineup.total, bet.lineups.to_lineup.current),
          points: {},
          userId: bet.to.userId
      }
      // GET SELCTEC CATS FOR TODAY
      Object.values(bet.scRules).map((val) => {
          if(val.display){
              selectedCatsDaily.push(val.stat)
          }
      })
      return rotoStandingsFormula(selectedCatsDaily, prepareStandings, bet.scRules)
    }
  
    const [standings, setStandings] = useState(prepareStandings())
  
    const findMyObject = (userId) => {
        var myObj = {}
        standings.map((obj) => {
          if(obj.userId == userId){
              myObj = obj
          }
        })
        return myObj
    }

  return (

    <tr style={{ borderBottom: `0.01em solid ${Colors.darkBgBorder}`, cursor:'pointer' }} onClick={handleNavigate} key={bet.ch_id}>
            <td className="align-middle bold">{/*Sr. Number Goes here*/}</td>
            <td className="align-middle">
                <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center my-1'>
                        <div className='rounded-circle mr-2' style={{width:40, height:40, border: `2px solid ${Colors.colorPrimary}`}}>
                            <img className="img-fluid m-auto p-1" src={bet.from.image}  />
                        </div>
                        <div>
                            <h5 className='p-0 m-0'>{bet.from.name} </h5>
                        </div>
                    </div>
                    
                    <div className='d-flex align-items-center my-1'>
                        <div className='rounded-circle mr-2' style={{width:40, height:40, border: `2px solid ${Colors.colorPrimary}`}}>
                            <img className="img-fluid m-auto p-1" src={bet.to.image}  />
                        </div>
                        <div>
                            <h5 className='p-0 m-0'>{bet.to.name} </h5>
                        </div>
                    </div>
                </div>
            </td>
            <td className="align-middle bold">
                <div className="my-3">
                    <h5 className="ml-1 bold">{findMyObject(bet.from.userId).totalPoints}</h5>
                    <h5 className="ml-1 bold">{findMyObject(bet.to.userId).totalPoints}</h5>
                </div>
            </td>
            <td className="align-middle bold">
                {bet.sidebet_type}
            </td>
            <td className="align-middle">
                {bet.sidebet_duration}
            </td>
            <td className="align-middle">
                <span style={{ color: Colors.colorPrimary }} className="bold">${bet.winner_gets}</span>
            </td>
            <td>

            </td>
          </tr>
  )
}

export default withRouter(RotoBet)