import React, { useContext, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux';
import { loadPlayers, winProbability } from '../../../apis/Arena/MatchMakerApis';
import { arenaHeartIcon, arenaLeftSidebarIcon, nbaArenaLineupIcon, thunderBolt, thunderBoltRight } from '../../../helpers/icons';
import store from '../../../store';
import Divider from '../../subcomponents/Divider';
import FullWidthButton from '../../subcomponents/FullWidthButton';
import { motion} from 'framer-motion/dist/framer-motion';
import { fadeIn, fadeOut } from '../../../pages/NewUserOnboarding/animationValues';
import DownloadOurApp from '../../CTA/DownloadOurApp';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useHistory, withRouter } from 'react-router-dom';
import { ColorContext } from '../../../context/ColorProvider';
import { currentTimeframe, oppLength, prepareArenaOpps } from '../../../helpers/Arena/ArenaHelper';
import { STYLES } from '../../../helpers/stylesHelper';
import ReactSelect from 'react-select';
import MatchupNotifications from './Matchups/MatchupNotifications';
import queryString from 'query-string';
import { MATCH_MAKER_BATTING_POS, MATCH_MAKER_PITCHING_POS, } from '../../../helpers/common-methods';
import { textActiveToSecondaryRightSidebar } from '../../../pages/NewUserOnboarding/animationValues';
import { LoaderCenter } from '../../Loader';
import ArenaMatchModal from '../../../modals/Arena/ArenaMatchModal';

const ArenaRightSidebar = (props) => {
    const { active_team, updateTeamPlayers, handleOnboarding, disableOnboarding, sport, sportEnabled, timeframe, showProposal } = props;
    // Get auth info
    const { user } = store.getState().auth;
    const history = useHistory();
    const Colors = useContext(ColorContext);
    
    const [players, setPlayers] = React.useState([]);
    const [totalPoints, setTotalPoints] = React.useState(0);
    const [week, setWeek] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const [activeTab, setActiveTab] = useState("lineups");
    const [allTeams, setAllTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState("");
    const [winProbabData, setWinProbabData] = useState();
    const [winProbabStatus, setWinProbabStatus] = useState();
    const [winProbabLoading, setWinProbabLoading] = useState(true);
    const rematchData = useSelector((state) => state.arenaReducer.rematchData);
    console.log("RIGHT SIDEBAR");
    // only for mlb case: hitters and pitchers
    const [mlbPlayerType, setMlbPlayerType] = useState("hitters");
    
    useEffect(() => {
        if(active_team.teamId != undefined && active_team.teamId != 'all') {
            setLoading(true);
            callApi()
            prepareTeams(props.all_teams);
        } else {
            setPlayers([]);
            setSelectedTeam("");
            prepareTeams(props.all_teams);
            setLoading(false);
        };
    }, [active_team])

    useEffect(() => {
        let locationTab = queryString.parse(location.search).activeTab;
        if(locationTab == "pending") {
            setActiveTab(locationTab);
        }
    }, [location.search])

    useEffect(() => {
        if (rematchData !== null) {
            if (rematchData?.proposalDrawer) {
                const { matchId, sport, timeFrame } = rematchData;
                winProbability({ matchId, sport, timeFrame }).then((resp) => {
                    setWinProbabData(resp.data);
                    setWinProbabStatus(resp.data.status);
                    setWinProbabLoading(false);
                }).catch((err) => {
    
                });
            }
        }

        return (() => {
            setWinProbabData(null);
            setWinProbabStatus(null);
            setWinProbabLoading(true);
        });
    }, [rematchData]);

    useEffect(() => {
        if (showProposal) {
            setActiveTab("pending");
        }
    }, [showProposal]);
    
    const callApi = (flag = false, selectedTeamId = null) => {

        let {teamType, teamId, leagueId } = active_team;

        if (flag) {
            teamId = selectedTeamId;
        };
        let challengeTeamType = teamType == "NATIVE" ? "CHALLENGEE" : "CHALLENGER";

        var qs = "?teamType="+challengeTeamType+
        "&teamId="+teamId+
        "&teamStatus="+"NPR"+
        "&leagueId="+leagueId+
        "&sport="+sport+
        "&timeFrame="+currentTimeframe()

        loadPlayers(qs)
            .then((response) => {
                setPlayers(response.players);
                setTotalPoints(response.totalPoints);
                setWeek(response.week);
                setLoading(false);
                // updateTeamPlayers(response.players);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const tabHandler = (tab) => setActiveTab(tab);

    const prepareTeams = (teams) => {
        // console.log(teams);
        const prepTeams = Object.values(teams).map((team) => { return { value: team.teamId, label: team.teamName } });
        setAllTeams(prepTeams);
    };

    const handleChangeTeam = (team) => {
        setSelectedTeam(team);
        setLoading(true);
        callApi(true, team.value);
    };

    const mlbPlayersByPos = (players, type) => {
        var finalPlayers = []
        players?.map((player) => {
            if (type == "hitters") {
                if(player.pos != 'P'){
                    finalPlayers.push(player)
                };
            } else if (type == "pitchers") {
                if(player.pos == 'P'){
                    finalPlayers.push(player)
                };
            };
        });
        return finalPlayers;
    }

    // const btnVariants = {
    //     active: {color: Colors.black_90, backgroundColor: Colors.colorPrimary},
    //     secondary: {color: Colors.black_90, background: Colors.arenaLineupPlayerRow}
    // }

    return (
        <div className='d-flex flex-column position-relative' style={{height:'100vh'}}>
                <div className='px-4 d-flex align-items-center' style={{ backgroundColor: Colors.colorPrimary, color: "black", height: "112px" }}>
                    {
                        props.active_team?.teamId == "all" ?
                            <h3><span>{arenaLeftSidebarIcon()}</span> All Teams</h3>
                        :
                        <div className='d-flex align-items-center mt-4'>
                            <img className='img-fluid' style={{ width: "50px", height: "50px", objectFit: "cover", borderRadius: 50 }} src={active_team?.teamImage} alt="" />
                            <div className='d-flex flex-column' style={{ paddingLeft: "16px" }}>
                                <p className='m-0 text-truncate' style={{ fontFamily: "Alexandria", fontWeight: 600, fontSize: 24, maxWidth: "280px"}}>{active_team?.teamName}</p>
                                <p className='m-0 text-truncate' style={{ fontWeight: 500, fontSize: 14, maxWidth: "280px", paddingTop: "4px"}}>{active_team?.leagueName}</p>
                            </div>
                        </div>
                    }
                    {/* <p className='big-font m-0'>Welcome back,</p>
                    <h1 className='bold heading-1 roboto' >{user?.first_name}.</h1>
                    {
                        ( active_team.pendingCount > 0 || active_team.confirmedMatchCount > 0 )
                        && 
                        <>
                            <p className='big-font'>You've got a few matches that need your attention</p>
                            <Divider color={Colors.borderPrimary_v3}/>
                        </>
                    }
                    
                    {
                        active_team.pendingCount > 0
                        &&
                        <>
                            <div className='d-flex my-2 pointer'>
                                <div className='px-2 py-2' onClick={() => history.push('/newHome?activeTab=pending')}>
                                    <p className='m-0 mr-2 big-font'><span className=' px-2 py-1 mr-2 bold' style={{backgroundColor:Colors.bgDark_v3, borderRadius: "50%",
                                    width: "50px", height: "50px", color: "white" }}>{active_team.pendingCount}</span> Need Action</p>
                                </div>
                            </div>
                            <Divider color={Colors.borderPrimary_v3}/>
                        </>
                    }

                    {
                        active_team.confirmedMatchCount > 0
                        &&
                        <>
                            <div className='d-flex my-2 pointer' onClick={() => history.push('/newHome?activeTab=matchups')}>
                                <div className='px-2 d-flex'>
                                    <p className='m-0'>
                                        <span className=' px-2 py-1 mr-2 bold big-font' style={{ backgroundColor: Colors.bgDark, borderRadius: "50%", width: "50px", height: "50px", color:'white' }}>{active_team.confirmedMatchCount}</span></p>
                                    <p className='m-0 big-font'>Matches have been accepted. Viewed in Matchups.</p>
                                </div>
                            </div>
                            <Divider color={Colors.borderPrimary_v3}/>
                        </>
                    } */}
                </div>
                {/* {
                    active_team.teamId != 'all'
                    && */}
                    <div className="d-flex flex-column flex1 scrollHidden">
                        <div className='pt-3 d-flex justify-content-between align-items-center' style={{ background: Colors.bgDark_v3 }}>
                            {
                                (sport == "NBA" || "MLB") ?
                                <div className='d-flex justify-content-around align-items-center flex1' style={{ backgroundColor: Colors.bgDark_v3 }}>
                                    <h5 className='m-0 pointer d-flex flex1 justify-content-center pb-3' style={{ borderBottom: activeTab == "lineups" ? `2px solid ${Colors.colorPrimary}` : "" }} onClick={() => tabHandler("lineups")}>{nbaArenaLineupIcon()} <span className='px-2'>Lineups</span></h5>
                                    <h5 className='m-0 d-flex flex1 justify-content-center align-items-center pointer pb-3' style={{ borderBottom: activeTab == "pending" ? `2px solid ${Colors.colorPrimary}` : "" }} onClick={() => tabHandler("pending")}>{arenaHeartIcon()} <span className='px-2'>Pending</span> <h5 className='m-0 px-2 d-flex align-items-center' style={{ color:Colors.colorLight, backgroundColor:Colors.colorDanger, minHeight:25, minWidth:25, borderRadius:50, fontWeight: 600 }}>{props.active_team.pendingCount}</h5></h5>
                                </div>
                                :
                                <div className='d-flex align-items-center px-3' style={{ background: Colors.bgDark_v3 }}>
                                    <p className='big-font letter-spacing-2'>WEEK {week}</p>
                                    {/* <p className='m-0 bold sub-font letter-spacing-2' style={{ color: Colors.textSecondary_v3 }}>OPTIMIZED LINEUP</p> */}
                                </div>
                            }
                        </div>
                        {   
                            activeTab == "lineups" ? 
                            <PerfectScrollbar className='d-flex flex-column flex1'>
                                <div style={{ padding: "0px 25px 0px 25px" }}>
                                    {props.active_team?.teamId == "all" &&
                                        <div className='d-flex justify-content-around align-items-center my-3'>
                                            <h5 className='m-0 bold sub-font letter-spacing-2' style={{ color: Colors.colorSecondary }}>SELECT <br /> TEAM</h5>
                                            <div className='flex1 ml-2'>
                                                <ReactSelect styles={STYLES.REACT_SELECT} closeMenuOnSelect={true} isClearable={false} options={allTeams} value={selectedTeam} onChange={handleChangeTeam} />
                                            </div>
                                        </div>
                                    }
                                    <h5 className='m-0 bold letter-spacing-2 text-center w-100 mt-4 mb-3'><span>{thunderBolt()}</span> OPTIMIZED LINEUP <span>{thunderBoltRight()}</span></h5>
                                    <Divider color={Colors.arenaLineupPlayerRow} />
                                    {loading ? <LoaderCenter /> :
                                        (sport == "MLB") || ( props.all_teams[selectedTeam.value]?.sport == "MLB") ? 
                                            <div className='px-1 my-2'>
                                                <div className='d-flex justify-content-center align-items-center my-3' style={{ background: Colors.arenaLineupPlayerRow, borderRadius: 24 }}>
                                                    {/* <motion.h6 className='flex1 m-0 py-2 pointer text-center' style={{ zIndex:1, borderRadius: 24, fontWeight: 600 }} animate={mlbPlayerType == "hitters" ? 'active' : 'secondary'}  variants={btnVariants} onClick={() => setMlbPlayerType("hitters")}>Hitters</motion.h6>
                                                    <motion.h6 className='flex1 m-0 py-2 pointer text-center' style={{ zIndex:1, borderRadius: 24, fontWeight: 600 }} animate={mlbPlayerType == "pitchers" ? 'active' : 'secondary'}  variants={btnVariants} onClick={() => setMlbPlayerType("pitchers")}>Pitchers</motion.h6> */}
                                                    <h6 className='flex1 m-0 py-2 text-center pointer' style={{ background: mlbPlayerType == "hitters" && Colors.colorPrimary, borderRadius: 24, color: Colors.black_90, fontSize: 16, fontWeight: 600 }} onClick={() => setMlbPlayerType("hitters")}>Hitters</h6>
                                                    <h6 className='flex1 m-0 py-2 text-center pointer' style={{ background: mlbPlayerType == "pitchers" && Colors.colorPrimary, borderRadius: 24, color: Colors.black_90, fontSize: 16, fontWeight: 600 }} onClick={() => setMlbPlayerType("pitchers")}>Pitchers</h6>
                                                </div>
                                                {players && mlbPlayersByPos(players, mlbPlayerType).map((player, index) => {
                                                    return (
                                                    <motion.div 
                                                        key={player.playerId}
                                                        animate={loading ? 'hide' : 'show'}
                                                        transition={{ duration: 0.5 }}
                                                        initial={false}
                                                        variants={fadeIn}
                                                    >
                                                        <div className='d-flex align-items-center my-2 px-2 py-2' style={{ background: index % 2 == 0 && Colors.bgDark_v3 }}>
                                                            <div style={{width: 30}}>
                                                                <p className='m-0 bold small font-roboto-condensed text-center align-self-center'>{player.pos}</p>
                                                            </div>

                                                            <div>
                                                                <img className='rounded-circle img-responsive mx-2' src={player.image} style={{height:30, width:30, objectFit:'cover'}}/>
                                                            </div>

                                                            <div className='flex1'>
                                                                <p className='m-0 flex1 bold'>{player.fullName} <span className='small pl-1' style={{color: Colors.colorSecondary}}>{player.team}</span> <span className='small pl-1' style={{ color: Colors.colorDanger }}>{player.injuryStatus}</span></p>
                                                                <div className='d-flex justify-content-between'>
                                                                    <p className='m-0 small' style={{color: Colors.colorSecondary}}>{prepareArenaOpps(player.opps)}</p>
                                                                    {player.opps && <p className='m-0 small bold' style={{color: Colors.colorSecondary}}>{oppLength(player.opps)} games</p> }
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </motion.div>
                                                )})}
                                            </div>
                                        :
                                        players && players.map((player, index) => (
                                            <motion.div 
                                                key={player.playerId}
                                                animate={loading ? 'hide' : 'show'}
                                                transition={{ duration: 0.5 }}
                                                initial={false}
                                                variants={fadeIn}
                                            >
                                                {
                                                    ((sport == "NBA") || ( props.all_teams[selectedTeam.value]?.sport == "NBA")) ?
                                                    <div className='d-flex align-items-center my-2 px-2 py-2' style={{ background: index % 2 == 0 && Colors.bgDark_v3, borderRadius: 10 }}>
                                                        <div style={{width: 30, fontFamily: 'Fritch'}}>
                                                            <p className='m-0 bold small font-roboto-condensed text-center align-self-center'>{player.pos}</p>
                                                        </div>

                                                        <div>
                                                            <img className='rounded-circle img-responsive mx-2' src={player.image} style={{height:30, width:30, objectFit:'cover'}}/>
                                                        </div>

                                                        <div className='flex1'>
                                                            <p className='m-0 flex1 small bold'>{player.fullName}</p>
                                                            <div className='d-flex justify-content-between'>
                                                                {player.opps && <p className='m-0 small bold' style={{color: Colors.colorPrimary}}>{oppLength(player.opps)} games</p> }
                                                                <p className='m-0 small' style={{color: Colors.colorSecondary}}>{prepareArenaOpps(player.opps)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    // <div className='d-flex mt-2'>
                                                    //     <div className='d-flex align-items-center' style={{width:20}}>
                                                    //         <p className='m-0 bold small font-roboto-condensed'>{player.pos}</p>
                                                    //     </div>
                                                    //     <div className='d-flex align-items-center'>
                                                    //         <img className='rounded-circle img-responsive mx-2' src={player.image} style={{height:30, width:30, objectFit:'cover'}}/>
                                                    //     </div>
                                                    //     <div className='flex1 border w-100 d-flex align-items-center' style={{minWidth:120, maxWidth:'40%'}}>
                                                    //         <p className='m-0 flex1 small-font font-weight-500'>{player.fullName}</p>
                                                    //         <p className='my-0 mx-2 bold small sub-font flex1' style={{color:Colors.textSecondary_v3}}>v. {player.opps.opp}</p>
                                                    //         <h5 className='m-0 small-font flex1 font-weight-500'>{player.points}</h5>
                                                    //     </div>
                                                    // </div>
                                                    :
                                                    <div className='d-flex mt-2 p-2' style={{ background: index % 2 == 0 && Colors.bgDark_v3, borderRadius: 10 }}>
                                                        <div className='d-flex align-items-center' style={{width:20}}>
                                                            <p className='m-0 bold small font-roboto-condensed'>{player.pos}</p>
                                                        </div>
                                                        <div className='flex1 d-flex align-items-center'>
                                                            <img className='rounded-circle img-responsive mx-2' src={player.image} style={{height:30, width:30, objectFit:'cover'}}/>
                                                            <p className='m-0 small-font font-weight-500'>{player.fullName}</p>
                                                        </div>
                                                        <div className='d-flex align-items-center' style={{minWidth:120, maxWidth:'40%'}}>
                                                            <p className='my-0 mx-2 bold small sub-font flex1' style={{color:Colors.textSecondary_v3}}>v. {player.opps?.opp}</p>
                                                            <h5 className='m-0 small-font flex1 font-weight-500'>{player.points}</h5>
                                                        </div>
                                                    </div>
                                                }
                                            </motion.div>
                                        )
                                    )}  
                                </div>
                            </PerfectScrollbar>
                            :
                            <motion.div
                                className='w-100 d-flex flex-column flex1'
                                animate={activeTab == 'pending' ? 'show' : 'hide'}
                                transition={{ delay: 0.5 }}
                                initial={false}
                                variants={fadeOut}
                            >
                                <MatchupNotifications activeTeam={props.active_team} activeTab={activeTab} />
                            </motion.div>
                        }
                        {
                            activeTab == "lineups" &&
                                <div className='my-3 mx-2' style={{ paddingLeft: "25px", paddingRight: "25px" }}>
                                    { sportEnabled && <FullWidthButton className='bold font-lg' sport={sport} text={`ENTER THE ${sport || ''} ARENA`} style={{color:'#000000'}} onClick={() => handleOnboarding(active_team.teamId)} disabled={disableOnboarding} borderType={"circular"} /> }
                                </div>
                        }
                        {/* {
                            players 
                            &&
                            <div className='text-right small underline' style={{color:Colors.colorInfo}}>
                                <p><i>See bench</i></p>
                            </div>
                        } */}
                        {/* <div className='mt-4'>
                            { handleOnboarding && <FullWidthButton className='bold' icon={arenaIcon()} text={`ENTER ${sport} ARENA`} style={{color:'#000000'}} onClick={() => handleOnboarding(active_team.teamId)} disabled={disableOnboarding} /> }
                        </div> */}
                    </div>
                {/* } */}
                <motion.div
                    animate={{ y: rematchData?.proposalDrawer ? -15 : 200 }}
                    transition={{ duration: .5 }}
                >
                    {rematchData?.proposalDrawer &&
                            <div className='d-flex flex-column flex1 scrollHidden w-100' style={{ position: "absolute", bottom: 0, maxHeight: "560px", borderRadius: 10 }}>
                                {winProbabLoading ? <LoaderCenter /> :
                                    <ArenaMatchModal
                                        modal={rematchData?.proposalDrawer}
                                        sport={rematchData?.sport}
                                        winProbData={winProbabData} 
                                        status={winProbabStatus}
                                        slug={"arenaHome"}
                                        rematchData={rematchData}
                                    />	
                                }
                            </div>
                    }
                </motion.div>
            { !user?.app_downloaded && <DownloadOurApp/> }
        </div>

    )
}

const mapStateToProps = (state) => ({active_team: state.arenaReducer.active_team, all_teams: state.arenaReducer.all_teams, timeframe: state.arenaReducer.selectedTimeframe})

export default connect(mapStateToProps, null)(withRouter(ArenaRightSidebar));