import React, { useEffect } from 'react'
import { Component } from 'react'
import { ColorContext } from '../../context/ColorProvider';
import { sportIcon } from '../../helpers/Arena/ArenaHelper';
import { mlbArenaIcon, nbaArenaIcon, nflArenaIcon } from '../../helpers/icons';

const FullWidthButton = (props) => {
    const {text, onClick, className, style, disabled, borderType, sport} = props
    const Colors = React.useContext(ColorContext);

  return (
    <div className='d-flex justify-content-center'>
      <div className={`d-flex align-items-center flex1 ${className}`} style={{...style, letterSpacing: 2, backgroundColor:disabled ? Colors.colorSecondary : Colors.colorPrimary, borderRadius: borderType == "circular" ? 56 : 10, height:50, cursor: disabled ? 'not-allowed' : 'pointer', maxWidth: "560px"}} onClick={ disabled ? '' : onClick} disabled={disabled}>
          <div className='d-flex justify-content-center align-items-center text-center flex1'>
            {
              disabled ? 
              <i class="fa fa-refresh fa-spin"></i>
              :
              <>
                {sport != "NFL" && <div className='d-flex align-items-center p-2 ml-1 m-0 p-0' style={{ background: Colors.ballColorEntry, borderRadius: 56 }}>
                    {sportIcon(sport)}
                </div>}
                <p className='m-0 roboto d-flex flex1 justify-content-center' style={{ fontWeight: 600 }}>{text}</p>
              </>
            }
          </div>
      </div>
    </div>
  )
}

export default FullWidthButton