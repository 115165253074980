import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Colors } from '../../helpers/colors';
import { withRouter } from 'react-router-dom';
import { bettleLogo } from '../../helpers/icons';
import { Button } from 'reactstrap';
import store from '../../store';
import Sidebar from '../../components/V3/Sidebar';
import { ColorContext } from '../../context/ColorProvider';

class Layout extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	static contextType = ColorContext;

	render() {
		const Colors = this.context;
		return (
			<div className="app d-flex" style={{ overflow: 'hidden', height: '100vh' }}>
                <div className="d-flex flex1 pl-0" style={{ height: '100vh', backgroundColor: Colors.bgDark_v3 }}>
                    <Sidebar/>
                    <this.props.Component {...this.props} />
                </div>
			</div>
		);
	}
}

const mapStateToProps = state => ({ nav_loading: state.NavReducer.loading });
export default connect(mapStateToProps, null)(withRouter(Layout));
