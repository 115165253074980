import React from 'react';
import { Button, UncontrolledCollapse, Card, CardBody } from 'reactstrap';
import { Colors } from '../../../../helpers/colors';
import PerfectScrollbar from 'react-perfect-scrollbar';

function LineupsScoring(props) {

  const scoringCats = [
    "Passing Yards",
    "Passing Touchdowns",
    "Rushing Yards",
    "Rushing Touchdowns",
    "Receiving Yards",
    "Receiving Touchdowns",
    "Passing 2pt Conversion",
    "Rushing 2Pt Conversion",
    "Receiving 2Pt Conversion",
    "Interceptions",
    "Fumbles Lost"
  ];
  const pointsPerCat = [
    "Passing Yards = .04 Pt per 1 Passing Yard",
    "Passing Touchdowns = 4 Pt per Passing Touchdown",
    "Rushing Yards = .1 Pt per 1 Rushing Yard",
    "Rushing Touchdowns = 6 Pt. per Rushing Touchdown",
    "Receiving Yards = .1 Pt per 1 Receiving Yard",
    "Receiving Touchdowns = 6 Pt per Receiving Touchdown",
    "Passing 2pt Conversion = 2 Pt per successful Passing 2 pt conversion",
    "Rushing 2Pt Conversion = 2 Pt per successful Rushing 2 pt conversion",
    "Receiving 2Pt Conversion = 2 Pt per successful Receiving 2 pt conversion",
    "Interceptions = -2 Pts per Interception",
    "Fumbles Lost = -2 Pts per Fumble Lost"
  ];
  const hitters = [
    "Runs",
    "RBIs",
    "HR",
    "SB",
    "OBP"
  ];

  const pitchers = [
    "ERA",
    "WHIP",
    "QS",
    "SV+HLD",
    "K"
  ];

  const List = (props) => {
    return (
      props.data && props.data.map((list, index) => (
        <ul key={index}>
          <li>{list}</li>
        </ul>
      ))
    )
  };

  return (
    <div className='d-flex flex-column flex1 scrollHidden'>
      {props.slug == "MatchMakerRules" ?
        <>
          <h5 className='bold' style={{ fontSize: 20 }}>Lineups & Scoring</h5>
          <div className='ml-3'>
            <div>
              <p className='bold mb-0' style={{ fontSize: 18 }}>Football</p>
              <p className='mb-0'>Scoring: Football matches use traditional head-to-head points scoring.</p>
              <p className='bold'>Scoring Categories:</p>
              <List data={scoringCats} />
              <p className='bold'>Points per catgeory:</p>
              <List data={pointsPerCat} />
              <p className='bold mb-0'>Starting Lineup: </p>
              <p>QB, RB, RB, WR, WR, FLX, FLX</p>
            </div>
            <div>
              <p className='bold mb-0' style={{ fontSize: 18 }}>Baseball</p>
              <p>Scoring: Baseball matches use head-to-head categories scoring. Tiebreaker for matches will be OBP followed by WHIP</p>
              <p className='bold mb-0'>Scoring Categories: </p>
              <p>Hitters</p>
              <List data={hitters} />
              <p>Pitchers</p>
              <List data={pitchers} />
              <p className='bold mb-0'>Starting Lineup: </p>
              <p>P, P, P, P P, C, 1B, 2B, 3B, SS, OF, OF, OF, UTIL</p>
            </div>
            <div>
              <p>Bettle uses official league statistics provided by licensed partners. We will make our best effort to settle matches and distribute prizes immediately after the match timeframe concludes, but it is paramount that this does not happen until we ensure all stats and scoring is accurate.</p>
            </div>
            <div>
              <p>Final match scores will be confirmed within 72 hours after the final game within the timeframe is played. Bettle reserves the right in its sole discretion to revise scores after they have been released in the unlikely event of a scoring error by the provider or Bettle. In these rare circumstances, match results may be altered and payments may be reversed and redone.</p>
            </div>
            <div>
              <p>If a league declares a game has been suspended, then the statistics generated in the sporting event before that point will count toward the match. Any statistics generated after this point when play resumes will only count if it occurs within the match timeframe.</p>
            </div>
            <div>
              <p>MatchMaker lineups lock at the start of the first game within the match timeframe. Users have up until this time to swap out any inactive or injured players. Once the match locks, the lineups are set for the match.</p>
            </div>
          </div>
        </>
        :
        <>

          <div className='d-flex justify-content-start align-items-center px-3 my-2' style={{ borderBottom: `1px solid ${Colors.darkBgBorder}`, cursor: 'pointer' }} onClick={() => props.setView('default')}>
            <h5><span> <i className='mr-2 fa fa-angle-left'></i></span> Lineups & Scoring</h5>
          </div>
          <PerfectScrollbar>
            <Card className='border-0 mb-0 mx-3' style={{ background: Colors.bgLight }}>
              <CardBody>
                <div>
                  <h5 className='bold' style={{ fontSize: 20 }}>Lineups & Scoring</h5>
                  <p className='bold mb-0' style={{ fontSize: 18 }}>Football</p>
                  <p className='mb-0'>Scoring: Football matches use traditional head-to-head points scoring.</p>
                  <p className='bold'>Scoring Categories:</p>
                  <List data={scoringCats} />
                  <p className='bold'>Points per catgeory:</p>
                  <List data={pointsPerCat} />
                  <p className='bold mb-0'>Starting Lineup: </p>
                  <p>QB, RB, RB, WR, WR, FLX, FLX</p>
                </div>
                <div>
                  <p className='bold mb-0' style={{ fontSize: 18 }}>Baseball</p>
                  <p>Scoring: Baseball matches use head-to-head categories scoring. Tiebreaker for matches will be OBP followed by WHIP</p>
                  <p className='bold mb-0'>Scoring Categories: </p>
                  <p>Hitters</p>
                  <List data={hitters} />
                  <p>Pitchers</p>
                  <List data={pitchers} />
                  <p className='bold mb-0'>Starting Lineup: </p>
                  <p>P, P, P, P P, C, 1B, 2B, 3B, SS, OF, OF, OF, UTIL</p>
                </div>
                <div>
                  <p>Bettle uses official league statistics provided by licensed partners. We will make our best effort to settle matches and distribute prizes immediately after the match timeframe concludes, but it is paramount that this does not happen until we ensure all stats and scoring is accurate.</p>
                </div>
                <div>
                  <p>Final match scores will be confirmed within 72 hours after the final game within the timeframe is played. Bettle reserves the right in its sole discretion to revise scores after they have been released in the unlikely event of a scoring error by the provider or Bettle. In these rare circumstances, match results may be altered and payments may be reversed and redone.</p>
                </div>
                <div>
                  <p>If a league declares a game has been suspended, then the statistics generated in the sporting event before that point will count toward the match. Any statistics generated after this point when play resumes will only count if it occurs within the match timeframe.</p>
                </div>
                <div>
                  <p>MatchMaker lineups lock at the start of the first game within the match timeframe. Users have up until this time to swap out any inactive or injured players. Once the match locks, the lineups are set for the match.</p>
                </div>
              </CardBody>
            </Card>
          </PerfectScrollbar>
        </>
      }
    </div>
  )
}

export default LineupsScoring;