import React from 'react'
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import bettleDark from '../../assets/images/logo_trans.png'
import discord from "../../assets/images/Discord_logo.svg"
import androidLogo from '../../assets/images/android.png';
import iosLogo from '../../assets/images/ios.png';
import { useColors } from '../../helpers/CustomHooks/useColors';

const Footer2022 = () => {
    
    return (
        <div className='py-3' style={{ background: "#11161A", fontFamily: "Roboto" }}>
            <Row className="mx-3 my-3" >
                <Col xs="12" md="4" lg="3" className={"text-center mb-4"} >
                    <img src={bettleDark} width="190" />
                </Col>
                <Col xs="12" md="12" lg="6">
                    <Row>
                        <Col xs={"6"} md="6" sm="4" >
                            <p className='lead bold' style={{ fontFamily: "Barlow" }}>About Us</p>
                            <div>
                                <p className='mb-2' >
                                    <a target={"_blank"} href="https://bettlesupport.zendesk.com/hc/en-us" >
                                        Help Center
                                    </a>
                                </p>
                                <p className='mb-2'>
                                    <a target={"_blank"} href='https://bettle.co/matchmaker-rules'>MatchMaker Rules</a>
                                </p>
                                <p>
                                    <a target={"_blank"} href="https://bettle.co/responsiblegaming" >
                                        Responsible Gaming Policy
                                    </a>
                                </p>
                            </div>
                        </Col>
                        <Col xs={"6"} md="6" sm="8" >
                            <p className='lead bold' style={{ fontFamily: "Barlow" }}>Follow Us</p>
                            {/* <Row>
                                <Col> */}
                                    <p className='mb-2' >
                                        <a target={"_blank"} href="https://mobile.twitter.com/bettlefantasy" >
                                            <i class="fa fa-twitter" aria-hidden="true"></i> Twitter
                                        </a>
                                    </p>
                                    <p className='mb-2'>
                                        <a target={"_blank"} href="https://www.instagram.com/bettlefantasy/?hl=en" >
                                            <i class="fa fa-instagram" aria-hidden="true"></i> Instagram
                                        </a>
                                    </p>
                                {/* </Col>
                                <Col> */}
                                    <div>
                                        <p className='mb-2' >
                                            <a target={"_blank"} href="https://discord.gg/5jk2uUhEVq" >
                                                <img style={{ height: "12px" }} src={discord} /> Discord
                                            </a>
                                        </p>
                                        <p className='mb-2'>
                                            <a target={"_blank"} href="https://www.linkedin.com/company/bettleinc/" >
                                                <i class="fa fa-linkedin" aria-hidden="true"></i> Linkedin
                                            </a>
                                        </p>
                                    </div>
                                {/* </Col>
                            </Row> */}
                        </Col>
                    </Row>
                </Col>
                <Col sm="12" md="12" lg="3">
                    <div className='d-none d-md-block'>
                        <p className='lead bold mb-0' style={{ fontFamily: "Barlow" }}>Get Our App</p>
                        <div className='d-flex flex-wrap' >
                            <div className='mr-3'>
                                <a target={"_blank"} href='https://apps.apple.com/us/app/bettle-fantasy-sports/id1591274931' ><img className='my-1' src={iosLogo} /></a>
                            </div>
                            <div>
                                <a target={"_blank"} href='https://play.google.com/store/apps/details?id=com.bettle.bettlelive&hl=en_US&gl=US' ><img className='my-1' src={androidLogo}/></a>
                            </div>
                        </div>
                    </div>
                    <div className='d-block d-sm-none'>
                    <p className='lead bold mb-0' style={{ fontFamily: "Barlow" }}>Get Our App</p>
                        <div className='d-flex' >
                            <div className='mr-3'>
                                <a target={"_blank"} href='https://apps.apple.com/us/app/bettle-fantasy-sports/id1591274931' ><img className='my-1' src={iosLogo} width={"80%"} /></a>
                            </div>
                            <div>
                                <a target={"_blank"} href='https://play.google.com/store/apps/details?id=com.bettle.bettlelive&hl=en_US&gl=US' ><img className='my-1' width={"80%"} src={androidLogo}/></a>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className='d-flex flex-column justify-content-center align-items-center p-3' style={{ borderTop: "1px solid #34383C" }} >
                {/* <p className='mb-1 text-center' >Please play responsibly and only bet what you can afford. If you or someone you know has a gambling problem, help is available. Call 1-800-GAMBLER or go to <a className='footerLinks' target="_blank" href="https://ncpgambling.org" >ncpgambling.org</a>.</p> */}
                <p className='mb-0 text-center mt-2 mt-md-0' >© 2021 - 2024 Bettle Inc. All Rights Reserved. <a className='footerLinks' target={"_blank"} href="/privacy">Privacy Policy</a>  <a className='footerLinks' target={"_blank"} href="/terms-and-conditions">Terms & Conditions</a></p>
            </div>
        </div>
    )
}
export default Footer2022