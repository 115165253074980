import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Colors } from '../../helpers/colors';
import { withRouter } from 'react-router-dom';
import { updateActiveExperience } from '../../apis/User/UserAPI';
import { SAVE_APPLICATION_STATUS } from '../../apis/Sidebar/LeagueAPI';
import store from '../../store';
import { ColorContext } from '../../context/ColorProvider';

class FullPageLayout extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	static contextType = ColorContext;

	componentDidMount(){
		updateActiveExperience({
			activeExp: "FOOTBALL_ARENA"
		}).then(() => {
			store.dispatch({ type: SAVE_APPLICATION_STATUS, app_status: "FOOTBALL_ARENA" })
		})
	}

	render() {
		const Colors = this.context;
		return (
			<div className="app d-flex" style={{ overflow: 'hidden', height: '100vh' }}>
                <div className="d-flex flex1 flex-column pl-0" style={{ height: '100vh', backgroundColor: Colors.bgDark }}>
                    <this.props.Component {...this.props} />
                </div>
			</div>
		);
	}
}

const mapStateToProps = state => ({ nav_loading: state.NavReducer.loading });
export default connect(mapStateToProps, null)(withRouter(FullPageLayout));
