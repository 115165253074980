import { Picker } from 'emoji-mart';
import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { ColorContext } from '../../../context/ColorProvider';


class ReactionModal extends Component {
    constructor(props) {
        super(props);
        this.sendReaction = this.sendReaction.bind(this)
    }

    static contextType = ColorContext;

    sendReaction(emojiObject){
        this.props.sendReaction(emojiObject)
    }

    render() { 
        const Colors = this.context;
        return ( 
            <Modal size='sm' isOpen={this.props.isOpen} toggle={this.props.toggle}>
                <ModalBody className='p-0 border-0 m-auto'>
                    <Picker set='apple' color={Colors.colorPrimary} style={{zIndex:9999}} autoFocus={true} onSelect={(emojiObject) => this.sendReaction(emojiObject)} />
                </ModalBody>
            </Modal>
         );
    }
}
 
export default ReactionModal;