import React, { useState } from 'react';
import { Colors } from '../../../../helpers/colors';
import ArenaGeneralRule from './ArenaGeneralRule';
import LineupsScoring from './LineupsScoring';
import IneligibleLocation from './IneligibleLocation';
import MultipleAccounts from './MultipleAccounts';
import LineupSwaps from './LineupSwaps';
import ContentIntegrity from './ContentIntegrity';
import ExperienceBadges from './ExperienceBadges';
import Glossary from './Glossary';

function Rules(props) {
  const [view, setView] = useState('default');

  const rules = [
    { name: 'General', viewType: 'general' },
    { name: 'Lineups & Scoring', viewType: 'lineups-and-scoring' },
    { name: 'Ineligible Locations', viewType: 'ineligible-locations' },
    { name: 'Multiple Accounts & Suspicious Activity', viewType: 'multiple-accounts' },
    { name: 'Lineup Swaps', viewType: 'lineup-swaps' },
    { name: 'Content Integrity', viewType: 'content-integrity' },
    { name: 'Experience Badges', viewType: 'exp-badges' },
    { name: 'Glossary', viewType: 'glossary' }
  ];

  const RulesLayout = () => {
    return rules.map((rule, index) => (
      <div key={index} className='d-flex justify-content-between align-items-center px-3' style={{ borderBottom: `1px solid ${Colors.darkBgBorder}`, cursor: 'pointer' }} onClick={() => setView(rule.viewType)}>
        <h5 className='bold mb-0 my-3'>{rule.name}</h5>
        <i className='mr-2 fa fa-angle-right'></i>
      </div>
    ));
  };

  return (
    <div className='d-flex flex-column flex1 scrollHidden'>
      <div className='py-3 px-3' style={{ background: Colors.bg400 }}>
        <h5 className='m-0 bold'><span onClick={() => props.setPage('default')} style={{ cursor: 'pointer' }}><i className='mr-2 fa fa-angle-left'></i>Rules</span></h5>
      </div>
      {(() => {
        switch (view) {
          case 'default':
            return <RulesLayout />
          case 'general':
            return <ArenaGeneralRule setView={setView} slug={"ArenSettingRules"} />
          case 'lineups-and-scoring':
            return <LineupsScoring setView={setView} slug={"ArenSettingRules"} />
          case 'ineligible-locations':
            return <IneligibleLocation setView={setView} slug={"ArenSettingRules"} />
          case 'multiple-accounts':
            return <MultipleAccounts setView={setView} slug={"ArenSettingRules"} />
          case 'lineup-swaps':
            return <LineupSwaps setView={setView} slug={"ArenSettingRules"} />
          case 'content-integrity':
            return <ContentIntegrity setView={setView} slug={"ArenSettingRules"} />
          case 'exp-badges':
            return <ExperienceBadges setView={setView} slug={"ArenSettingRules"} />
          case 'glossary':
            return <Glossary setView={setView} slug={"ArenSettingRules"} />
        }
      })()}
    </div>
  )
}

export default Rules;