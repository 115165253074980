import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { motion } from "framer-motion/dist/framer-motion"
import { slideInOut } from "./animationValues"
import { Button } from 'reactstrap';
import { setupAvatar, teamHasCustomImage } from '../../apis/Matchmaker/ArenaSettings/ArenaSettings';
import { updateDataInUsers } from '../../helpers/Arena/ArenaHelper';
// import { Colors } from '../../helpers/colors';
import { arenaUploadAvatarIcon, arenaUploadedAvatarIcon } from '../../helpers/icons';
import ErrorText from '../../components/ErrorText';
import { errorMessage } from '../../helpers/common-methods';
import { LoaderCenter } from '../../components/Loader';
import TeamAvatar from '../../components/Matchmaker/ArenaOnboarding/TeamAvatar';
import { registerUserInArena } from '../../apis/Arena/MatchMakerApis';

const OnboardingTeamAvatar = (props) => {

    const {leagueId, step, successCallback, Colors, hasLeague} = props

    const [ avatarloading,  setAvatarLoading ] = useState(true)
    const [ loading, setLoading ] = useState(false)

    useEffect(() => {
      if(step == 'leagueImported'){
        teamHasCustomImage({leagueId})
          .then((response) => {
            if(response.data.hasImage){
              processAvatar()
            }else{
              setAvatarLoading(false)
            }
          })
          .catch((err) => {
            setAvatarLoading(false)
            errorMessage(err)
          })
        }
    }, [step])

    // NEW CHANGES 2023
    const processAvatar = () => {
      if(hasLeague){
        successCallback()
      }
      else
      {
        registerUserInArena({
          leagueId: leagueId,
        }).then((resp) => {
          successCallback()
        }).catch((err) => {
          successCallback()
        })
      }
    }

	return (
      <motion.div
          className="d-flex flex-column text-center col-md-5 col-12 col-centered"
          animate={ step == "leagueImported" ? 'show' : step == "avatarSelected" ? 'hideLeft' :'hideRight'}
          transition={{ duration: 0.5, delay: .5 }}
          // style={{flex: step == "lineupSelected" ? 1 : 0}}
          initial={false}
          style={{ display: "flex", flex: 0}}
          variants={slideInOut}>

          {step == "leagueImported" && (
          <LoadingOverlay
          active={loading}
          text='Uploading...'
          fadeSpeed={0}
          spinner
          >
            {
              avatarloading ?
              <div className='d-flex flex-column flex1'>
                <LoaderCenter />
                <p>We are checking your avatar, please be patient.</p>
              </div>
              :
              <>
                <div className="d-flex flex-column flex1 mt-5 scrollHidden" >
                    <h1 className='alex bold' style={{color: Colors.colorPrimary, fontSize: "32px"}} >Set your Avatar</h1>
                    <p className="w-100 mt-3 mx-auto" >You might want to set your team avatar for a better Arena experience for everyone.</p>
                </div>
                <div className='p-2' style={{ backgroundColor: Colors.bgLight, borderRadius: 10, overflow: "auto"}}>
                  <TeamAvatar onSuccess={processAvatar} setUploading={setLoading} {...props} sentFromOnboarding={true}/>
                    {/* {renderView()}
                    {error && <ErrorText customStyles={{textAlign: "center", marginBottom: 20}} msg={error} />} */}
                </div>
              </>
            }
          </LoadingOverlay>)}
      </motion.div>
	);
};

export default OnboardingTeamAvatar;
