import React from "react"
import { MLB_POS } from "../../../../helpers/common-methods"
import MLBPlayerPointsRow from "./MLBPlayerPointsRow"

const MLBWeeklyPointsPlayers = (props) => {
    const {data} = props
    var rosterTemplate = MLB_POS

    const findPlayer = (pos, index) => {
        var pls = []
        if(data.teams[index] && data.teams[index].players){
          data.teams[index].players.map((player) => {
            if(player.pos == pos){
              pls.push(player)
            }
          })
        }
        return pls
    }

    return (
        // <View>
        <div className="mb-3">
            {rosterTemplate.map((pos, index) => {
                var players1 = findPlayer(pos,0)
                var players2 = findPlayer(pos,1)
                return (
                    <div className="d-flex">
                        <MLBPlayerPointsRow pos={pos} sc_rules={data.scRules} players1={players1} players2={players2} key={index} />
                    </div>
                )
                // return (
                //     <h1>DONE</h1>
                // )
            })}
        </div>
        // </View>
    )
}

export default MLBWeeklyPointsPlayers