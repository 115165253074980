import React from 'react'
// import { Colors } from '../../helpers/colors'
import { useColors } from '../../helpers/CustomHooks/useColors';

const Divider = (props) => {
  const Colors = useColors();
  const {color} = props
  return (
    <div className='w-100' style={{height:1, backgroundColor: color ? color : Colors.bgSecondary_v3}}></div>
  )
}

export default Divider