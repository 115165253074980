import React from 'react';
import store from '../store';
import { Route, Redirect } from 'react-router-dom';
import { setIntendedUrl } from '../helpers/auth';
import PropTypes from 'prop-types';
import { getStore } from '../helpers/common-methods';
import Layout from '../containers/V3Layout/Layout';

const propTypes = {
  component: PropTypes.func.isRequired,
  rest: PropTypes.object,
  location: PropTypes.object
};

const V3Route = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const { auth: { authenticated } } = getStore().getState();
      if (!authenticated) {
        setIntendedUrl(props.location.pathname+props.location.search);
      }

      return authenticated ? (
        <Layout Component={Component} {...props} />
      ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location }
            }}
          />
        );
    }
    }
  />
);

V3Route.propTypes = propTypes;
V3Route.displayName = 'V3 Auth Route';

export default V3Route;
