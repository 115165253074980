import React, { useState } from 'react';
import './FloatingInput.css';

function FloatingInput(props) {

  const [activeState, setActiveState] = useState("default");
  const {label, type, disabled} = props

  const handleFocus = () => {
    setActiveState("focused");
  };

  const handleBlur = (e) => {
    props?.onBlur()
    if(!e.target.value) {
      setActiveState("default");
    }else{
      setActiveState("inserted");
    }
  };

  const activeClassName = () => {
    if(props?.error){
      return "error"
    }else if(activeState == "default"){
      return type == "date" || props?.value ? 'date' : ''
    }else if(activeState == "focused"){
      return 'active'
    }else if(activeState == "inserted"){
      return 'inserted'
    }
  }

  return (
    <div className="mb-3"  >
      <div className={`f-form-group ${activeClassName()}`}>
        <input
          className="float-input f-form-control"
          type={type}
          onChange={props?.onChange}
          value={props?.value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
        />
        {((type == 'date' && !props?.error) || type != "date") && <label className={`f-form-control-placeholder${props?.error ? '--invalid' : ''}`}>{label}</label>}
      </div>
       <p className='mb-0' style={{color: "#DC3444"}} >{props?.error ? props?.error : ""}</p>
    </div>
  );
}

export default FloatingInput;