import React, { useState, useEffect } from "react"
import styled, { css } from 'styled-components'
import PlayerCardHeaderNba from "../../../../components/V3/PlayerCard/PlayerCardHeaderNba";
// import { Colors } from "../../../../helpers/colors"
import { useColors } from '../../../../helpers/CustomHooks/useColors';
import store from "../../../../store";

const PlayerCardHeader = (props) => {
    const Colors = useColors();
    const { data } = props
    const { activeLeagueSport } = store.getState().leagueJoin

    const Image = styled.img`
        width: 100%;
        max-width: 140px;
    `

    const Divider = styled.div`
        border-left: .1rem solid #6c757d;
        margin-right: .5rem;
        margin-left: .5rem;
        margin-top: .5rem;
        margin-bottom: .5rem;
    `

    return (
        activeLeagueSport === "NBA" ?
        <PlayerCardHeaderNba {...props} />
        :
        <div className="d-flex flex-column pt-5 px-2" >
            {/* Player Info */}
            <div className="d-flex align-items-center mx-2">
                <div className='ml-2' style={{ overflow: 'hidden', maxWidth: '100px', maxHeight: '100%' }} >
                <img className='img-responsive rounded-circle my-1' src={data.image} width='100%' height='100px' style={{ objectFit: 'cover', border: `2px solid ${Colors.colorPrimary}` }} />
                </div>
                {/* <div className="w-25">
                    <img className='img-responsive rounded-circle my-1' src={data.image} width='100%' style={{ border: `2px solid ${Colors.colorPrimary}`, objectFit: 'cover' }} />
                </div> */}
                <div className="flex1 ml-3">
                    {/* Player Details */}
                    <h4 className="d-inline">{data.fullName}</h4> <span className="pull-right small pr-5"><i class="fa fa-circle" style={{ color: Colors.colorPrimary }} aria-hidden="true"></i> Active</span>
                    <h6 className="my-1">{props.teams && props.teams.filter(team => team.team_id == data.team).map(found => { return (found.name) })}</h6>
                    <h6 className="my-1">{data.pos}</h6>
                    {/* Player Details */}
                </div>
            </div>
            {/* Player Info */}

            {/* Player Main Stats */}
            <div className="d-flex p-2 my-2">
                {/* Single Stat */}
                <div className="flex1 text-center mx-1 py-2 p-1" style={{ backgroundColor: Colors.bgDark, borderRadius: 5 }}>
                    <h4 className="m-0" style={{ color: Colors.colorPrimary }}>{data.rank}</h4>
                    <span style={{ color: Colors.colorSecondary }}>Pos. Rnk</span>
                </div>

                {/* Single Stat */}
                <div className="flex1 text-center mx-1 py-2 p-1" style={{ backgroundColor: Colors.bgDark, borderRadius: 5 }}>
                    <h4 className="m-0" style={{ color: Colors.colorPrimary }}>{data.points}</h4>
                    <span style={{ color: Colors.colorSecondary }}>Avg Pts</span>
                </div>

                {/* Single Stat */}
                <div className="flex1 text-center mx-1 py-2 p-1" style={{ backgroundColor: Colors.bgDark, borderRadius: 5 }}>
                    <h4 className="m-0" style={{ color: Colors.colorPrimary }}>20.8</h4>
                    <span style={{ color: Colors.colorSecondary }}>Proj. (Weekly)</span>
                </div>

                {/* Single Stat */}
                <div className="flex1 text-center mx-1 py-2 p-1" style={{ backgroundColor: Colors.bgDark, borderRadius: 5 }}>
                    <h4 className="m-0" style={{ color: Colors.colorPrimary }}>20.8</h4>
                    <span style={{ color: Colors.colorSecondary }}>Proj. (2022)</span>
                </div>

            </div>
        </div>
    )
}

export default PlayerCardHeader