import React, { useContext, useEffect, useState } from 'react';
import { mmTeamComparison } from '../../apis/Arena/MatchMakerApis';
import { LoaderCenter } from '../../components/Loader';
import PlayerMatchupRow from '../../components/Matchmaker/ArenaPlayerMatchupRow';
import { ColorContext } from '../../context/ColorProvider';
import { MATCH_MAKER_BATTING_POS, MATCH_MAKER_PITCHING_POS, MATCH_MAKER_POS, NBA_POS, NFL_MM_POS, NFL_POS, getStore, parseInjuryIcons } from '../../helpers/common-methods';
import PlayerMatchupRowNba from './PlayerMatchupRowNba';
import PerfectScrollbar from 'react-perfect-scrollbar'
import moment from 'moment-timezone';
import { currentTimeframe, getScoringPeriod, parseScoringPeriod, prepareArenaOpps } from '../../helpers/Arena/ArenaHelper';
import { useSelector } from 'react-redux';
import { AppSwitch } from '@coreui/react';
import CustomToggle from '../subcomponents/CustomToggle';
import ArenaPlayerModal from '../../modals/Arena/ArenaPlayerModal';
import { thunderBolt } from '../../helpers/icons';

var Colors = {}
const styles = {
    posTab: {
        // flex: 1,
        display: "flex",
        flexDirection: "column",
        width: 100,
        height: 30,
        alignItems: "center",
        justifyContent: "center",
        border: `1px solid ${Colors.bgDark}`,
        borderRadius: 15,
        padding: 3
    },
    posTabActive: {
        // flex: 1,
        display: "flex",
        flexDirection: "column",
        width: 100,
        height: 30,
        alignItems: "center",
        justifyContent: "center",
        border: `1px solid ${Colors.colorPrimary}`,
        borderRadius: 15,
        padding: 3
    }
}

const ComparionView = (props) => {
    Colors = useContext(ColorContext);

    const { teamId, proposedData, sport, forNotification, comparisonData } = props

    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)
    const [playersByPos, setPlayersByPos] = useState([])
    const [sortedPos, setSortedPos] = useState( sport == "MLB" ? "Hitting" : "Starters")
    const [roasterView, setRoasterView] = useState("h2h");
    const [playerId, setPlayerId] = useState("");
    const [playerModal, setPlayerModal] = useState(false);

    // var timeFrame = null
    // if(sport == "NBA" || sport == "MLB"){
    //     timeFrame = useSelector(state => state.arenaReducer.selectedTimeframe)
    // }

    const togglePlayerModal = () => setPlayerModal(!playerModal);

    const openPlayerModal = (player_id) => {
        setPlayerId(player_id);
        togglePlayerModal();
    };

    useEffect(() => {

        if(comparisonData == undefined) {
            var qs = "?sport="+sport;
            if(proposedData){
                qs = qs+"&matchId="+proposedData.matchId
            }else{
                qs = qs+"&teamId="+teamId
            }
            //if(sport == "NBA" || sport == "MLB"){
                if(proposedData){
                    qs = qs+"&timeFrame="+proposedData?.timeFrameKey
                }else{
                    qs = qs+"&timeFrame="+currentTimeframe()
                }
            //}
            mmTeamComparison(qs).then((resp) => {
                arrangePlayersByPos(resp)
                setData(resp)
                setLoading(false)
                const prepData = {
                    comparisonData: resp,
                    loading: false
                };
                getStore().dispatch({ type: "SAVE_MM_LINEUP_DETAIL", payload: prepData });
            })

        }
        else {
            arrangePlayersByPos(comparisonData)
            setData(comparisonData)
            setLoading(false)
            
        }
    }, [])

    const arrangePlayersByPos = (resp = data, sPos = sortedPos) => {
        var arrangedData = []
        var team1Players = JSON.parse(JSON.stringify(resp["my"].players))
        var team2Players = JSON.parse(JSON.stringify(resp["opp"].players))

        // var isTeam1Native = resp["my"].isNative
        // var isTeam2Native = resp["opp"].isNative

        var positions = []
        if(sport == "MLB"){
            // if(sPos == "Hitting"){
            //     positions = MATCH_MAKER_BATTING_POS
            // }else if(sPos == "Pitching"){
            //     positions = MATCH_MAKER_PITCHING_POS
            // }else if (sPos == "All"){
                positions = MATCH_MAKER_POS
            //}
        }else if(sport == "NBA"){
            positions = NBA_POS
        }else{
            positions = NFL_MM_POS
        }
        var lastIndex = 0
        positions.map((pos, defaultIndex) => {
            var team1FinalPlayers = team1Players
            var team2FinalPlayers = team2Players

            arrangedData[defaultIndex] = {
                "team1": null,
                "team2": null,
                pos: pos
            }
            // if(isTeam1Native){
            //     team1FinalPlayers = team1Players.starters
            // }
            for(var i= 0; i<team1FinalPlayers.length; i++){
                if(team1FinalPlayers[i].defaultPos == pos){
                    arrangedData[defaultIndex]["team1"] = team1FinalPlayers[i]
                    team1FinalPlayers.splice(i, 1)
                    break
                }
            }
            // if(isTeam2Native){
            //     team2FinalPlayers = team2Players.starters
            // }
            //console.log(team2Players)
            for(var i= 0; i<team2FinalPlayers.length; i++){
                if(team2FinalPlayers[i].defaultPos == pos){
                    arrangedData[defaultIndex]["team2"] = team2FinalPlayers[i]
                    team2FinalPlayers.splice(i, 1)
                    break
                }
            }
            lastIndex = defaultIndex
        })
        lastIndex = lastIndex + 1
        var team1Bench = 0
        var team2Bench = 0
        // FIND MAX BENCH LENGTH
        team1Players.map((pl) => {
            if(pl.type == "bench"){
                team1Bench++
            }
        })
        team2Players.map((pl) => {
            if(pl.type == "bench"){
                team2Bench++
            }
        })
        var maxBench = team1Bench > team2Bench ? team1Bench : team2Bench
        // PUT BENCH PLAYERS NOW
        for(var i= 0; i<maxBench; i++){
            arrangedData[lastIndex + i] = {
                "team1": null,
                "team2": null,
                pos: "BE"
            }
            for(var j= 0; j<team1Players.length; j++){
                if(team1Players[j].type == "bench"){
                    arrangedData[lastIndex + i]["team1"] = team1Players[j]
                    team1Players.splice(j, 1)
                    break
                }
            }
            for(var k= 0; k<team2Players.length; k++){
                if(team2Players[k].type == "bench"){
                    if(arrangedData[lastIndex + i] != undefined){
                        arrangedData[lastIndex + i]["team2"] = team2Players[k]
                    }else{
                        arrangedData[lastIndex + i] = {
                            "team1": null,
                            "team2": null,
                            pos: "BE"
                        }
                        arrangedData[lastIndex + i]["team2"] = team2Players[k]
                    }
                    team2Players.splice(k, 1)
                    break
                }
            }
        }
        setPlayersByPos(arrangedData)
    }

    // const arrangePlayersByPos = (resp = data, sPos = sortedPos) => {
    //     var arrangedData = []

    //     var team1Players = JSON.parse(JSON.stringify( resp?.my?.players ?? {} ))
    //     var team2Players = JSON.parse(JSON.stringify(resp?.opp?.players ?? {}))
    //     var isTeam1Native = resp.my?.isNative ?? false
    //     var isTeam2Native = resp.opp?.isNative ?? false
    //     var positions = []
    //     var isBench = false
    //     if(sport == "MLB"){
    //         if(sPos == "Hitting"){
    //             positions = MATCH_MAKER_BATTING_POS
    //         }else if(sPos == "Pitching"){
    //             positions = MATCH_MAKER_PITCHING_POS
    //         }else if (sPos == "Bench"){
    //             isBench = true
    //         }
    //     }else if (sport == "NBA"){
    //         if(sPos == "Starters"){
    //             positions = NBA_POS
    //         }else if (sPos == "Bench"){
    //             isBench = true
    //         }
    //     }
    //     else{
    //         if(sPos == "Starters"){
    //             positions = NFL_MM_POS
    //         }else if (sPos == "Bench"){
    //             isBench = true
    //         }
    //     }
    //     if(isBench){
    //         var maxLength = 0
    //         if(isTeam1Native){
    //             maxLength = team1Players?.bench.length
    //         }
    //         if(isTeam2Native){
    //             if(team2Players.bench.length > maxLength){
    //                 maxLength = team2Players?.bench.length
    //             }
    //         }
    //         for(var j =0; j<maxLength; j++){
    //             arrangedData[j] = {
    //                 "team1": team1Players?.bench ? team1Players?.bench[j] : null,
    //                 "team2": team2Players?.bench ? team2Players?.bench[j] : null,
    //                 pos: "BE"
    //             }
    //         }
    //     }else{
    //         positions.map((pos, defaultIndex) => {
    //             var team1FinalPlayers = team1Players
    //             var team2FinalPlayers = team2Players

    //             arrangedData[defaultIndex] = {
    //                 "team1": null,
    //                 "team2": null,
    //                 pos: pos
    //             }
    //             if(isTeam1Native){
    //                 team1FinalPlayers = team1Players.starters || team1Players
    //             }
    //             for(var i= 0; i<team1FinalPlayers.length; i++){
    //                 if(team1FinalPlayers[i].defaultPos == pos){
    //                     arrangedData[defaultIndex]["team1"] = team1FinalPlayers[i]
    //                     team1FinalPlayers.splice(i, 1)
    //                     break
    //                 }
    //             }
    //             if(isTeam2Native){
    //                 team2FinalPlayers = team2Players.starters || team2Players
    //             }
    //             //console.log(team2Players)
    //             for(var i= 0; i<team2FinalPlayers.length; i++){
    //                 if(team2FinalPlayers[i].defaultPos == pos){
    //                     arrangedData[defaultIndex]["team2"] = team2FinalPlayers[i]
    //                     team2FinalPlayers.splice(i, 1)
    //                     break
    //                 }
    //             }
    //         })
    //     }
    //     setPlayersByPos(arrangedData)
    // }

    const mlbPlayersByPos = (type) => {
        var positions = []
        var finalPlayers = []
        var players = JSON.parse(JSON.stringify(data["opp"].players))
        if(type == "hitters"){
            positions = MATCH_MAKER_BATTING_POS
        }else if (type == "pitchers"){
            positions = MATCH_MAKER_PITCHING_POS
        }
        positions.map((pos) => {
            for(var i= 0; i<players.length; i++){
                if(players[i].defaultPos == pos){
                    finalPlayers.push(players[i])
                    players.splice(i, 1)
                    break
                }
            }
        })
        return finalPlayers
    }

    const togglePosCat = (posCat) => {
        setSortedPos(posCat)
        arrangePlayersByPos(data, posCat)
    }

    /**
     * Accept date in UTC format and return date in format "ddd, MMM D"
     * @param {string} date - Date
     * @returns Date in format "ddd, MMM D"
     */
    const getFormattedDate = (date) => {
        return moment(date).format("ddd, MMM D")
    }  

    const handleRoasterView = (view) => {
        setRoasterView(view ? "oppRoaster" : "h2h");
    };

    return (
        <>
            <div className="d-flex flex-column flex1 scrollHidden" style={{ background: Colors.black_90 }}>
                {loading ? <LoaderCenter /> : (
                    <div className='d-flex flex-column flex1 scrollHidden' >
                        <div className='d-flex align-items-center justify-content-between' style={{
                            // paddingBottom: 10,
                            // marginTop: 10,
                            }} >
                                {
                                    sport == "NBA" ?
                                    <>
                                    <div className='d-flex flex-column' style={{alignItems: "center", flex: 1}} >
                                            <p className='lead bold mb-0' style={{marginTop: 4, color: Colors.colorPrimary}} >{data.my?.teamName}</p>
                                            <p className='m-0 small' style={{color: Colors.colorSecondary}}>{data.my?.leagueName}</p>
                                        </div>
                                        <div><p className='bold m-0' >VS</p></div>
                                        <div className='d-flex flex-column' style={{alignItems: "center", flex: 1}} >
                                            <p className='lead bold mb-0' style={{marginTop: 4, color: Colors.colorPrimary}} >{data.opp?.teamName}</p>
                                            <p className='m-0 small' style={{color: Colors.colorSecondary}}>{data.opp?.leagueName}</p>
                                        </div>
                                    </>
                                    :
                                    <div className='d-flex flex1 align-items-center' style={{ height: "88px" }}>
                                        <div className='d-flex flex-column' style={{alignItems: "center", flex: 1}} >
                                            <p className='alex lead bold mb-0' style={{marginTop: 4, color: Colors.colorPrimary}} >{data.my?.teamName}</p>
                                            <p className='m-0 small' style={{color: Colors.colorSecondary}}>{data.my?.leagueName}</p>
                                        </div>
                                        <div><p className='bold m-0' >VS</p></div>
                                        <div className='d-flex flex-column' style={{alignItems: "center", flex: 1}} >
                                            <p className='alex lead bold mb-0' style={{marginTop: 4, color: Colors.colorPrimary}} >{data.opp?.teamName}</p>
                                            <p className='m-0 small' style={{color: Colors.colorSecondary}}>{data.opp?.leagueName}</p>
                                        </div>
                                    </div>
                                }
                        </div>
                        {/* { sport != "NBA" && <div className='d-flex align-items-center'>
                            <div className='d-flex flex-column mx-4 flex1' >
                                {sport == "MLB" ? (
                                    null
                                    // <div className='d-flex align-items-center justify-content-evently flex-grow-1'>
                                    //     <div className='d-flex flex-column flex1 pointer' onClick={() => togglePosCat("Hitting")} style={ sortedPos == "Hitting" ? styles.posTabActive : styles.posTab} >
                                    //         <p className='greenText lead mb-0' >Hitting</p>
                                    //     </div>
                                    //     <div className='d-flex flex-column flex1 pointer' onClick={() => togglePosCat("Pitching")} style={ sortedPos == "Pitching" ? styles.posTabActive : styles.posTab} >
                                    //         <p className='greenText lead mb-0' >Pitching</p>
                                    //     </div>
                                    //     {!forNotification && <div className='d-flex flex-column flex1 pointer' onClick={() => togglePosCat("Bench")} style={ sortedPos == "Bench" ? styles.posTabActive : styles.posTab} >
                                    //         <p className='greenText lead mb-0' >Bench</p>
                                    //     </div>}
                                    // </div>
                                ) : (
                                    <div className='d-flex align-items-center' style={{justifyContent: "space-evenly"}} >
                                        <div onClick={() => togglePosCat("Starters")} style={ sortedPos == "Starters" ? styles.posTabActive : styles.posTab} >
                                            <p  className='greenText lead mb-0 pointer' >Starters</p>
                                        </div>
                                        {!forNotification && <div onClick={() => togglePosCat("Bench")} style={ sortedPos == "Bench" ? styles.posTabActive : styles.posTab} >
                                            <p  className='greenText lead mb-0 pointer'>Bench</p>
                                        </div>}
                                    </div>
                                )}
                            </div>
                        </div>} */}
                        <div className='d-flex flex-column flex1' style={{overflow: "auto"}} >
                            { (sortedPos == "Starters" || sport == "MLB" || sport == "NFL") &&
                                <div className='text-center py-3 mx-3' style={{background: "linear-gradient(180deg, #2F0200 0%, rgba(45, 2, 0, 0) 103.12%)", borderRadius: 8}}>
                                    <p className='m-0' style={{ fontWeight: 600, fontSize: 14, letterSpacing: 2 }}><span className='pr-1'>{thunderBolt()}</span> OPTIMIZED LINEUPS <span className='pl-1'>{thunderBolt()}</span></p>
                                    {/* <div><span className='bold' style={{color:Colors.colorPrimary}}>Matchup Period</span> { proposedData ? parseScoringPeriod(proposedData?.timeFrame?.start, proposedData?.timeFrame?.end) : getScoringPeriod()}</div> */}
                                </div> 
                            }
                            {sport == "MLB" &&
                                <div className='d-flex justify-content-center align-items-center' style={{ margin: "10px 0px 10px 0px" }}>
                                    <p className='m-0' style={{ color: roasterView == "h2h" && Colors.colorLight }}>Head to Head</p>
                                    <CustomToggle onChange={handleRoasterView}/>
                                    <p className='m-0' style={{ color: roasterView == "oppRoaster" && Colors.colorPrimary }}>Opponent Roster</p>
                                </div>
                            }
                            {roasterView == "h2h" ? playersByPos.map((playerRow, index) => (
                                sport == 'NBA' ?
                                <PlayerMatchupRowNba sport={sport} key={index} data={playerRow} />
                                :
                                <PlayerMatchupRow sport={sport} key={index} data={playerRow} />
                            ))
                            :
                            <div className='d-flex justify-content-between align-items-start my-1' style={{ margin: "0px 20px 0px 20px" }}>
                                <div className='d-flex flex-column flex1 mr-4'>
                                    {mlbPlayersByPos("hitters").map((player) => {
                                        return (
                                        <div className='d-flex align-items-center pointer' onClick={() => openPlayerModal(player.playerId)}>
                                            <div className="d-flex align-items-start flex1">
                                                <div className="mr-2" style={{ marginTop: 4, fontSize: 12, fontWeight: 600, color: Colors.borderSecondary_v3 }}>{player.defaultPos == "UTIL" ? "UT" : player.defaultPos}</div>
                                                    <div className='flex1' style={{marginLeft: 5, borderBottom: `1px solid ${Colors.darkBgBorder}`}}>
                                                        <div className="d-flex justify-content-between align-items-center flex1">
                                                            <div>
                                                                <div style={{fontWeight: "bold", marginRight: 5, marginTop: 2, fontSize: 16}}>{player.fullName}</div>
                                                                {sport == "NFL" && <div>{player.points}</div>}
                                                            </div>
                                                            <p className='m-0'>{parseInjuryIcons(player.injuryStatus)}</p>
                                                        </div>
                                                        <div className='bold mb-1' style={{flexShrink: 1, marginTop: 1, color: Colors.colorDangerTransparent_v2, fontSize: 12}}>
                                                            {prepareArenaOpps(player.opps)}
                                                        </div>
                                                    </div>
                                                </div> 
                                        </div>
                                    )})}
                                </div>
                                <div className='d-flex flex-column flex1'>
                                    {mlbPlayersByPos("pitchers").map((player) => (
                                        <div className='d-flex align-items-center mb-1 pointer' onClick={() => openPlayerModal(player.playerId)}>
                                        <div className="d-flex align-items-start flex1">
                                        <div className="mr-2" style={{ marginTop: 4, fontSize: 12, fontWeight: 600, color: Colors.borderSecondary_v3 }}>{player.defaultPos}</div>
                                            <div className='flex1' style={{marginLeft: 5, borderBottom: `1px solid ${Colors.darkBgBorder}`}}>
                                                <div className="d-flex justify-content-between align-items-center flex1">
                                                    <div>
                                                        <div style={{fontWeight: "bold", marginRight: 5, marginTop: 2, fontSize: 16}}>{player.fullName}</div>
                                                        {sport == "NFL" && <div>{player.points}</div>}
                                                    </div>
                                                    <p className='m-0'>{parseInjuryIcons(player.injuryStatus)}</p>
                                                </div>
                                                <div className='bold mb-1' style={{flexShrink: 1, marginTop: 1, color: Colors.colorDangerTransparent_v2, fontSize: 12}}>
                                                    {prepareArenaOpps(player.opps)}
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                    ))}
                                </div>
                            </div>
                            }
                        </div>
                        {sport == "NFL" && <div className='d-flex justify-content-between align-items-center' >
                                <div className='text-center d-flex flex-column flex1 my-2 greenText' >  
                                    TOTAL {data.my?.totalPoints}
                                </div>
                                <div className='text-center d-flex flex1 flex-column my-2 greenText' >
                                    TOTAL {data.opp?.totalPoints}
                                </div>
                        </div>}
                    </div>
                )}
            </div>
            {playerModal && <ArenaPlayerModal modal={playerModal} playerId={playerId} toggle={togglePlayerModal} />}
        </>
    )
}

export default ComparionView