import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Collapse, Input } from 'reactstrap';
import Truncate from 'react-truncate';
import moment from 'moment-timezone';
import LoadingOverlay from 'react-loading-overlay';
import { mmCounterMatch, mmCreateMatch, mmTeamOnboarded } from '../../../apis/Arena/MatchMakerApis';
import { parseProfleStatus, verifyUserStatus } from '../../../helpers/Payments/GidxHelper';
import ArenaError from '../../../components/Matchmaker/ArenaError';
import ErrorText from '../../../components/ErrorText';
import { getStore, successMessage } from '../../../helpers/common-methods';
import GidxErrorModal from '../../../modals/GidxModals/GidxErrorModal';
import CounterPlayerSelectModal from '../../../modals/Arena/CounterPlayerSelectModal';
import OnboardedPlayerModal from '../../../modals/Arena/OnboardPlayerModal';
import { useColors } from '../../../helpers/CustomHooks/useColors';
import { backArrowIcon, chevronDownIcon } from '../../../helpers/icons';
import { defaultColors } from '../../../helpers/colors';
import { currentTimeframe } from '../../../helpers/Arena/ArenaHelper';
import WinProbabilityModal from '../../../modals/Arena/WinProbabilityModal';

const betAmounts = [5,10,25,50,100]

function CounterActions(props) {
    const Colors = useColors();
	const {sport, arenaData, winProbData, status, slug, rematchData} = props

    const fetchTodayDate = () => {
        if(sport == "NFL"){
            return currentTimeframe()
        }else if (sport == "NBA"){
            if(arenaData?.todayGamesStarted){
                return "TM"
            }else{
                return currentTimeframe()
            }
        }else if (sport == "MLB"){
            return currentTimeframe()
        }
    }
    
    const {successCallback, cancelCallback, selectedLineup, isCounter, counterMatchId, stateSetterCallback} = props
    const [formVals, setFormVals] = useState({
        odds: "E",
        startDate: fetchTodayDate(),
        amount: 5,
        isPaid: true
    })

    const [errors, setErrors] = useState({})
    const [loader, setLoader] = useState(false)
    const [cancelBtnLoading, setCancelBtnLoading] = useState(false)
    const [playersSelectionData, setPlayersSelectionData] = useState()
    const [playerSelectModal, setPlayerSelectModal] = useState(false)
    const [onboardedPlayerModal, setOnboardedPlayerModal] = useState(false)
    const [walletError, setWalletError] = useState(null)
    const [nextWeekScheduleModal, setNextWeekScheduleModal] = useState(false)
    const [weeklyBankrollError, setWeeklyBankrollError] = useState(null)
    const [generalError, setGeneralError] = useState(null)
    const [infoModal, setInfoModal] = useState(false)
    const [infoData, setInfoData] = useState()
    const [showOther, setShowOther] = useState(false)
    const userData = useSelector((state) => state.arenaReducer.userData)
    const errorModal = useSelector(state => state.gidxErrorReducer.errorModal)


    const styles = {
        container: {
            // backgroundColor: isCounter ? Colors.colorWarning : Colors.bgDark,
            // backgroundColor: Colors.bgDark,
            // borderRadius: "16px",
            // maxHeight: "80%",
            minHeight: "50%",
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
            flex: 1
          },
        amountBox: {
            // border: `1px solid ${Colors.darkBgBorder}`,
            padding: "5px 10px",
            // marginRight: "10px",
            borderRadius: "5px",
            backgroundColor: Colors.arenaLineupPlayerRow,
            color: Colors.colorSecondary,
        },
        amountBoxChecked: {
            borderRadius: "5px",
			// border: `1px solid ${isCounter ? Colors.bgDark : Colors.colorPrimary}`,
            backgroundColor: Colors.mlbPrimaryColor,
            padding: "5px 10px",
            // marginRight: "10px",
        }
    }

    const onSubmit = () => {
        setLoader(true)
        if(validateForm()){
            if(isCounter){
                // COUNTER FLOW
                handleCounterMatchFlow()
            }else{
                // NORMAL CREATE FLOW
                handleCreateMatchFlow()
            }
        }else{
            setLoader(false)
        }
    }

    // THIS IS FOR COUNTER FLOW
    const handleCounterMatchFlow = () => {
        let timeFrame = formVals.startDate
        mmTeamOnboarded("?matchId="+counterMatchId+"&timeFrame="+timeFrame).then((teamResp) => {
            setPlayersSelectionData(teamResp)
            if(teamResp.hadTeam){
                // SIMPLY CALL COUNTER API
                callCounterApi()
            }else{
                if(teamResp.hasPlayers){
                    setLoader(false)
                    setOnboardedPlayerModal(true)
                }else{
                    setLoader(false)
                    // TRIGGER SELECT PLAYER SCREEN
                    setPlayerSelectModal(true)
                }
            }
        }).catch((e) => {
            handleCounterException(e)
        })
    }

    const handleCounterPlayerSeelctionSuccess = () => {
        // AT THIS POINTS NON-ONOARDED PLAYERS ARE SET
        //setLoader(true)
        // isCounter
        callCounterApi()
    }

    // COUNTER API
    const callCounterApi = () => {
        setLoader(true)
        if(formVals.cAmount){
            formVals["amount"] = formVals.cAmount
            delete formVals["cAmount"]
        }
        delete formVals["cAmount"]
        formVals["matchId"] = counterMatchId
        formVals["sport"] = sport
        formVals["timeFrame"] = formVals.startDate

        mmCounterMatch(formVals).then(() => {
            // successCallback()
            stateSetterCallback("matchModalSuccessCallback", true);
            successMessage("You have proposed a counter match")
            setLoader(false)
        }).catch((e) => {
            handleCounterException(e)
        })
    }

    const handleCounterException = (e) => {
        if(e?.data?.ERROR_CODE){
            // PARESE ERRORS
            if(e?.data?.ERROR_CODE == "PNV"){
                // PROFILE NOT VERIFIED
                parseProfleStatus(e?.data?.status)
                setLoader(false)
                // verifyUserStatus().then(() => {
                //     setLoader(false)
                // })
                //cancelCallback()
            }else if(e?.data?.ERROR_CODE == "LB"){
                // LOW BALANCE FROM WALLET
                setWalletError(e?.data?.message)
                setLoader(false)
            }else if(e?.data?.ERROR_CODE == "LB_WB"){
                // LOW BALANCE FROM WEEKLY BALANCE
                setWeeklyBankrollError(e?.data?.message)
                setLoader(false)
            }
        }else{
            setGeneralError(e.message)
            setLoader(false)
        }
    }

    // THIS IS FOR CREATE MATCH
    const handleCreateMatchFlow = () => {
        if(formVals.cAmount){
            formVals["amount"] = formVals.cAmount
            delete formVals["cAmount"]
        }
        delete formVals["cAmount"]
        if (rematchData?.proposalDrawer) {
            formVals["oppTeamId"] = rematchData?.oppTeamId
            formVals["leagueId"] = rematchData?.oppTeamLgId
            formVals["sport"] = rematchData?.sport
        } else {
            formVals["oppTeamId"] = selectedLineup["teamId"]
            formVals["leagueId"] = selectedLineup["leagueId"]
            formVals["sport"] = sport
        }
        if (formVals["startDate"] == "TD" && formVals["sport"] == "MLB") {
            formVals["startDate"] = "FW"
        }

    	mmCreateMatch(formVals).then(() => {
            // successCallback()
            if (!rematchData?.proposalDrawer) {
                stateSetterCallback("matchModalSuccessCallback", true);
            }
            successMessage("Your proposal has been sent!")
            setLoader(false)
            getStore().dispatch({ type: "SET_REMATCH_DATA", payload: null });
            // LOG EVENT IN FIREBASE
            // logInFirebase("MATCHUP_PROPOSED", {
            //     screen: "MATCH_MAKER_ARENA",
            //     purpose: "User proposed a match",
            // })
        }).catch((e) => {
            console.log(e)
            if(e?.data?.ERROR_CODE){
                // PARESE ERRORS
                if(e?.data?.ERROR_CODE == "PNV"){
                    // PROFILE NOT VERIFIED
                    // cancelCallback()
                    parseProfleStatus(e?.data?.status)
                    setLoader(false)
                    // verifyUserStatus().then(() => {
                    //     setLoader(false)
                    // })
                }else if(e?.data?.ERROR_CODE == "LB"){
                    // LOW BALANCE
                    setWalletError(e?.data?.message)
                    setLoader(false)
                }else if(e?.data?.ERROR_CODE == "LB_WB"){
                    // LOW BALANCE FROM WEEKLY BALANCE
                    setWeeklyBankrollError(e?.data?.message)
                    setLoader(false)
                }
            }else{
                setGeneralError(e.message)
                setLoader(false)
            }
        })
    }

    const validateForm = () => {
        var newErrors = {}
        if(formVals.isPaid){
            if((formVals.amount == undefined || formVals.amount == '' || formVals.amount == null) &&
            (formVals.cAmount == undefined || formVals.cAmount == '' || formVals.cAmount == null)){
              newErrors["amount"] = "Please enter amount"
            }
            if(!formVals.odds){
                newErrors["odds"] = "Please select odds boost"
            }
        }
        if(sport == "MLB" || sport == "NBA"){
            if(!formVals.startDate){
                newErrors["startDate"] = "Please select start date"
            }
        }
        if(formVals.isPaid == undefined){
            newErrors["isPaid"] = "Please select match type"
        }
        if(Object.keys(newErrors).length > 0){
          setErrors(newErrors)
          return false
        }
        return true
    }

    const handleInput = (key, val) => {
        var formCopy = {...formVals}
        var newErrors = {...errors}
        if(newErrors[key] != undefined){
          delete newErrors[key]
        }
        if(key == 'cAmount'){
          formCopy["amount"] = ""
          formCopy["isPaid"] = true
          formCopy[key] = val
        }else if(key == 'amount'){
          formCopy["cAmount"] = ""
          formCopy["isPaid"] = true
          setShowOther(false)
          formCopy[key] = val
        }else{
          formCopy[key] = val
        }
        setErrors(newErrors)
        setFormVals(formCopy)
      }

    const fetchDates = () => {
        if(sport == "NFL"){
            var startDates = [{id: "TH", title: "Full Week"}, {id: "S", title: "Weekend Quick Hitter"}]
        }else if (sport == "NBA"){
            var startDates = [{id: "TD", title: "Today"}, {id: "TM", title: "Tomorrow"}, {id: "NW", title: "Next Monday"}]
            if(arenaData?.todayGamesStarted){
                // Check If today is Sunday
                if(moment().tz("America/New_York").isoWeekday() == 7){
                    startDates = [{id: "TM", title: "Tomorrow"}]
                }else{
                    startDates = [{id: "TM", title: "Tomorrow"}, {id: "NW", title: "Next Monday"}]
                }
            }
        }else if (sport == "MLB"){
            var startDates = [{id: "FW", title: "Full Week"}, {id: "WD", title: "Weekend Quick Hitter"}]
        }
        return startDates.map((date) => {
            return (<div className='pointer flex1 text-center mx-1' key={date.id} style={formVals.startDate == date.id ? {...styles.amountBoxChecked, ...{marginBottom: "10px"}} : {...styles.amountBox, ...{marginBottom: "10px"}}} onClick={() => handleInput("startDate", date.id)}  >
                <p className={`${getSelectedColor(formVals.startDate == date.id)} mb-0`} >{date.title}</p>
            </div>)
        })
    }

    const errorCallback = () => {
        // cancelCallback()
        if (!rematchData?.proposalDrawer) {
            stateSetterCallback("matchModalCancelCallback", false);
        } 
    }

    const handleCancel = () => {
        setWeeklyBankrollError(null)
        setWalletError(null)
        setCancelBtnLoading(true)
		// cancelCallback()
        if (!rematchData?.proposalDrawer) {
            stateSetterCallback("matchModalCancelCallback", false);
        }
    }

    // THIS FOR RE-ENTRY FLOW
    const handleConfirm = () => {
        setOnboardedPlayerModal(!onboardedPlayerModal)
        callCounterApi()
    }

    // THIS FOR RE-ENTRY FLOW
    const handleEdit = () => {
        setOnboardedPlayerModal(!onboardedPlayerModal)
        // TRIGGER SELECT PLAYER SCREEN
        setPlayerSelectModal(true)
    }

    const showInfoModal = (data) => {
        setInfoData(data)
        setInfoModal(true)
    }

    const getSelectedColor = (selected) => {
        if(selected){
            if(isCounter){
                // return "whiteText"
                return "primaryText"
            }else{
                return "primaryText"
            }
        }else{
            if(isCounter){
                // return "primaryText"
                return "whiteText"
            }else{
                return "whiteText"
            }
        }
    }

    const handlePaid = (val) => {
        var formCopy = {...formVals}
        if(val == false){
            formCopy["amount"] = ""
            formCopy["aAmount"] = ""
            setShowOther(false)
        }
        formCopy["isPaid"] = val
        setFormVals(formCopy)
    }

    const handleOtherAmount = (val) => {
        var formCopy = {...formVals}
        if(val == true){
            formCopy["amount"] = ""
            formCopy["aAmount"] = ""
            formCopy["isPaid"] = true
        }
        setShowOther(!showOther)
        setFormVals(formCopy)
    }

	return (
        // <Collapse isOpen={isOpen}>
        //     <div className='d-flex align-items-center align-items-center mx-3 py-1'  style={{ backgroundColor: `#00000030`, borderRadius: 5 }}>
        //         <div className='mx-2 pointer align-items-center d-flex' style={{width:20}} onClick={cancelCallback}>{backArrowIcon()}</div>
        //         <p className='m-0'>COUNTER</p>
        //     </div>
			<div className='d-flex flex-column flex1 scrollHidden' > 
				{/* <i class="cui-circle-x icons pointer modalClose" onClick={handleCancel} /> */}
				{/* MODAL BODY STARTS*/}
				<LoadingOverlay
					active={loader}
					text='Saving...'
					fadeSpeed={0}
					spinner
					className='d-flex flex-column flex1 scrollHidden'
				>
				{generalError && (
                <div style={styles.container}>
                    <ArenaError msg={generalError}
                    title={"Oops! We found an issue"}
                    hideSuccessBtn={true}
                    routeTo={null}
                    errorCallback = {errorCallback}
                    cancelCallback = {handleCancel}
                    />
                </div>
            )}
            {walletError && (
                <div style={styles.container}>
                    <ArenaError msg={walletError}
                    buttonp={"Deposit Funds"}
                    title={"You have low balance"}
                    routeTo={{
                        path: "/arena/"+sport+"/settings",
                        params: {}
                    }}
                    errorCallback = {errorCallback}
                    cancelCallback = {handleCancel}
                    />
                </div>
            )}
            {weeklyBankrollError && (
                <div style={styles.container}>
                    <ArenaError msg={weeklyBankrollError}
                    title={"You've reached your limit"}
                    buttonp={"Update Settings"}
                    routeTo={{
                        path: "/arena/"+sport+"/settings",
                        params: {}
                    }}
                    errorCallback = {errorCallback}
                    cancelCallback = {handleCancel}
                    />
                </div>
            )}
            {(!walletError && !weeklyBankrollError && !generalError) && (
                    <div style={styles.container} >
                    {/* {isCounter ? (
                        <h4 className='bold primaryText mx-3 my-2' >Counter Offer</h4>
                    ) : (
                        <h4 className='bold greenText mx-4 my-3'>Match Details</h4>
                    )} */}
                    <div className='d-flex flex-column flex1 scrollHidden' style={{ background: Colors.black_90, color: Colors.colorLight }}> 
                    <div style={{}} className="" >
                    <div style={{minHeight: 400}} >

                        {!isCounter && <div className='pb-2' style={{ background: Colors.darkGreen_v3, color: Colors.mlbPrimaryColor }}>
                            <div className='d-flex align-items-center'>
                                {
                                    isCounter ? (
                                        <h5 className='bold pb-1 flex1'>Counter Offer</h5>
                                    ):(
                                        <h5 className='bold greenText pl-2 flex1 m-0 text-center' style={{ fontSize: 20, fontWeight: 600 }}>Proposal Details</h5>
                                    )
                                }
                                <div className='d-flex align-items-center justify-content-around flex1' >
                                    <p className={` lead m-0 mx-2`} > to </p>
                                    <img className='img-fluid roundImgSmallNoOutline' src={rematchData?.proposalDrawer ? rematchData?.oppTeamImg : selectedLineup?.teamImage} style={{borderWidth: 1, borderColor: Colors.colorPrimary}} />
                                    <p className='bold mb-0 flex1' style={{marginLeft: 10, fontSize: 14, fontWeight: 600}} >{winProbData?.oppTeam}</p>
                                </div>
                            </div>
                        </div>}
                        {/* Win Probability Component */}
                        {(slug == "arenaHome" && sport != "NFL") &&
                            <div style={{ background: "linear-gradient(180deg, #013020 27.78%, rgba(1, 48, 32, 0) 100%)" }}>
                                <div className='mx-2 py-2 px-3' style={{ background: Colors.colorDark, color: Colors.colorLight }}>
                                    <WinProbabilityModal winProbData={winProbData} status={status} slug={"arenaHome"} />
                                </div>
                            </div>
                        }

                        <div className='px-2' style={{ background: Colors.black_90, paddingTop: "18px" }}>
                            <p className='mb-2' style={{ fontSize: 13, letterSpacing: 2 }}>BET AMOUNT</p>
                            <div className='d-flex'>
                                <div className='pointer flex1 text-center mr-1' style={!formVals.isPaid ? styles.amountBoxChecked : styles.amountBox} onClick={() => handlePaid(false)} >
                                    <p className={`${getSelectedColor(!formVals.isPaid)} mb-0`} >Free</p>
                                </div>
                                {betAmounts.map((amount) => {
                                    return <div className='pointer flex1 text-center mx-1' key={amount} style={formVals.amount == amount ? styles.amountBoxChecked : styles.amountBox} onClick={() => handleInput("amount", amount)} >
                                        <p className={`${getSelectedColor(formVals.amount == amount)} mb-0`} >${amount}</p>
                                    </div>
                                })}
                                <div className='pointer flex1 text-center ml-1' style={showOther ? styles.amountBoxChecked : styles.amountBox} onClick={() => handleOtherAmount(true)} >
                                    <p className={`${getSelectedColor(showOther)} mb-0`} >Other</p>
                                </div>
                            </div>
                        </div>

                        {showOther && <div className='mx-2'>
                            {/* <p className='infoText' >OTHER AMOUNT</p> */}
                                <Input className="mt-3" style={{backgroundColor: "#ffffff"}} placeholder="Enter Amount" value={formVals.cAmount} onChange={(e) => handleInput("cAmount", e.target.value)} />
                            {errors?.amount && <ErrorText  msg={errors.amount} />}
                        </div>}
                        {formVals.isPaid && <div className='px-2' style={{ background: Colors.black_90, paddingTop: "18px" }}>
                            <div className='d-flex align-items-center'>
                                <p className='m-0 mb-2' style={{marginRight: "5px", fontSize: 13, letterSpacing: 2 }} >ODDS BOOST</p>
								<i class="fa fa-info-circle" aria-hidden="true" onClick={() => showInfoModal({
                                    title: "Odds Boost",
                                    description: "You have the ability to increase or decrease the odds of a match. Increased odds boost means less risk for the same reward. Decreased odds boost means more risk for the same reward."
                                })} style={{color: Colors[isCounter ? "bgDark" : "colorPrimary"]}} ></i>
                            </div>
                            <div className='d-flex align-items-center scrollbar ff-scrollbar' style={{overflowX:'auto'}}>
                                <div className='pointer flex1 text-center mr-1' style={formVals.odds == "2:1" ? styles.amountBoxChecked : styles.amountBox} onClick={() => handleInput("odds", "2:1")} >
                                    <p className={`${getSelectedColor(formVals.odds == "2:1")} mb-0`} >2X for Me</p>
                                </div>
                                {/* <div className='pointer' style={formVals.odds == "3:2" ? styles.amountBoxChecked : styles.amountBox} onClick={() => handleInput("odds", "3:2")} >
                                    <p className={`${getSelectedColor(formVals.odds == "3:2")} mb-0`} >3:2</p>
                                </div> */}
                                <div className='pointer flex1 text-center mx-1' style={formVals.odds == "E" ? styles.amountBoxChecked : styles.amountBox} onClick={() => handleInput("odds", "E")} >
									<p className={`${getSelectedColor(formVals.odds == "E")} mb-0`} >Even</p>
                                </div>
                                {/* <div className='pointer' style={formVals.odds == "2:3" ? styles.amountBoxChecked : styles.amountBox} onClick={() => handleInput("odds", "2:3")} >
									<p className={`${getSelectedColor(formVals.odds == "2:3")} mb-0`} >2:3</p>
                                </div> */}
                                <div className='pointer flex1 text-center ml-1' style={formVals.odds == "1:2" ? styles.amountBoxChecked : styles.amountBox} onClick={() => handleInput("odds", "1:2")} >
									<p className={`${getSelectedColor(formVals.odds == "1:2")} mb-0`} >2X for Them</p>
                                </div>
                            </div>
                            {errors?.odds && <ErrorText  msg={errors.odds} />}
                        </div>}
                        {(isCounter) && <div className='pt-3 mx-2'>
                            <p className='m-0 mb-2' style={{ fontSize: 13, letterSpacing: 2 }}>SCORING PERIOD START DATE</p>
                            <div className='d-flex align-items-center' >
                                {fetchDates()}
								{/* <i class="fa fa-info-circle" aria-hidden="true" onClick={() => setNextWeekScheduleModal(!nextWeekScheduleModal)}
								style={{color: Colors[isCounter ? "bgDark" : "colorPrimary"]}}></i> */}
                            </div>
                            {errors?.startDate && <ErrorText  msg={errors.startDate} />}
                        </div>}
                        {/* <div>
                            <p className='infoText mt-3' >By clicking “Send” below, you will be approving this matchup proposal!</p>
                        </div> */}
                        <div className='d-flex align-items-center mx-2 justify-content-center' style={{ marginTop: "24px" }}>
                            <Button className='d-flex align-items-center justify-content-center bold flex1' style={{color:Colors.mlbPrimaryColor, backgroundColor: Colors.colorPrimaryDark, border: `1px solid ${Colors.mlbPrimaryColor}`}} onClick={onSubmit}> 
                                { isCounter ?  "Counter" : "Send Proposal" }
                            </Button>
                        </div>
                    </div>
                    </div>
                    </div>
                    </div>
            )}
            {/* {nextWeekScheduleModal &&
                <MlbNextWeekScheduleModal visible={nextWeekScheduleModal} toggle={() => setNextWeekScheduleModal(!nextWeekScheduleModal)} />
            } */}
            {/* {infoModal &&
                <ArenaInfoModal visible={infoModal}
                {...infoData}
                toggle={() => setInfoModal(!infoModal)} />
            } */}
                {onboardedPlayerModal && <OnboardedPlayerModal
                handleConfirm={handleConfirm}
                handleEdit={handleEdit}
                players={playersSelectionData.onboardedPlayers}
                proposedData={selectedLineup.proposedData}
                visible={onboardedPlayerModal}
                timeFrame={formVals.startDate}
                />}
                {playerSelectModal && <CounterPlayerSelectModal
                onSuccess={handleCounterPlayerSeelctionSuccess}
                counterMatchId={counterMatchId}
                toggle={() => setPlayerSelectModal(!playerSelectModal)}
                visible={playerSelectModal}
                teamData={playersSelectionData}
                timeFrame={formVals.startDate}
                />}
                {errorModal.show && <GidxErrorModal />}
				</LoadingOverlay>
				{/* MODAL BODY ENDS*/}
		</div>
        // </Collapse>
	);
}

export default CounterActions;
