import React, { useEffect, useState } from 'react';
import { useColors } from '../../../../helpers/CustomHooks/useColors';
import { Button, InputGroup, InputGroupText } from 'reactstrap';
import ErrorText from '../../../ErrorText';
import { LoaderCenter } from '../../../../components/Loader';

function ArenaSettingsComp(props) {

  const Colors = useColors();

  const handleBetInputChange = (e) => {
    props.setMaxBetInput(e.target.value);
    props.setMaxBet(e.target.value);
  };

  const handleBankrollInputChange = (e) => {
    props.setBankrollInput(e.target.value);
    props.setBankroll(e.target.value);
  };

  const handleMaxBet = (btn) => {
    props.setMaxBet(btn);
    props.setMaxBetInput('');
  };

  const handleBankroll = (btn) => {
    props.setBankroll(btn);
    props.setBankrollInput('');
  };

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center py-3 px-3' style={{ background: Colors.bg400 }}>
        <h5 className='m-0 bold'><span onClick={() => props.setPage('default')} style={{ cursor: 'pointer' }}><i className='mr-2 fa fa-angle-left'></i>Arena Settings</span></h5>
        <Button className='border-0 bold' disabled={(props.maxBet == '' && props.maxBetInput == '') || (props.bankroll == '' && props.bankrollInput == '') || (props.loading)} style={{ background: Colors.bg400, color: Colors.colorPrimary }} onClick={props.handleUpdateSettings}>Save</Button>
      </div>
      {props.loading ? <LoaderCenter msg='Loading...' /> :
        <div className='d-flex justify-content-center align-items-center'>
          <div className='mt-3'>
            <h6 className='bold'>Select Bet Amount</h6>
            {props.maxBetBtns.map((btn, index) => (
              <Button key={index} className='border-0 mr-2' style={{ background: btn == props.maxBet ? Colors.colorPrimary : Colors.bgDark, color: btn == props.maxBet ? 'black' : 'white', padding: '6px 23px 6px 23px' }} onClick={() => handleMaxBet(btn)}>{`$${btn}`}</Button>
            ))}
            <p className='mt-3 mb-1 bold'>Other Amount</p>
            <InputGroup>
              <InputGroupText style={{ background: Colors.bgLight, border: `1px solid ${Colors.darkBgBorder}`, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                USD ($)
              </InputGroupText>
              <input type="text" className='form-control' placeholder={props.maxBetInput !== '' ? props.maxBet : '0.00'} style={{ maxWidth: '322px', border: `1px solid ${Colors.darkBgBorder}` }} value={props.maxBetInput} onChange={handleBetInputChange} />
            </InputGroup>
            {props.maxBet == '' && props.maxBetInput == '' ? <ErrorText msg='Please Select Max Bet Amont' /> : ''}

            {/* <h6 className='bold mt-2'>Set Active Bankroll</h6>
            {props.bankrollBtns.map((btn, index) => (
              <Button key={index} className='border-0 mr-2' style={{ background: btn == props.bankroll ? Colors.colorPrimary : Colors.bgDark, color: btn == props.bankroll ? 'black' : 'white', padding: '6px 23px 6px 23px' }} onClick={() => handleBankroll(btn)}>{`$${btn}`}</Button>
            ))}
            <p className='mt-3 mb-1 bold'>Other Amount</p>
            <InputGroup>
              <InputGroupText style={{ background: Colors.bgLight, border: `1px solid ${Colors.darkBgBorder}`, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                USD ($)
              </InputGroupText>
              <input type="text" className='form-control' placeholder={props.bankrollInput !== '' ? props.bankroll : '50.00'} style={{ maxWidth: '322px', border: `1px solid ${Colors.darkBgBorder}` }} value={props.bankrollInput} onChange={handleBankrollInputChange} />
            </InputGroup>
            {props.bankroll == '' && props.bankrollInput == '' ? <ErrorText msg='Please Select Weekly Bankroll Amount' /> : ''} */}
          </div>
        </div>
      }
    </div>
  )
}

export default ArenaSettingsComp;