import React from 'react'
import { useColors } from '../../helpers/CustomHooks/useColors'
import { bettleSignupQR } from '../../helpers/icons'
import infoGraphic from '../../assets/images/get_bettle_refer_link.png'
import qrCode from "../../assets/images/qrCode.png"

const ReferralLeaderboardsInfo = (props) => {
    const { week } = props

    const Colors = useColors();

  return (
    <div className='m-2 d-flex px-3 flex-column justify-content-center' style={{overflow:'auto'}}>
        <h3 style={{fontWeight:'bold'}}>
            Week {week}
        </h3>
        <h1 style={{color:Colors.referralLeaderboardsPrimary, fontSize:48, fontWeight:'bold'}}>Referral<br/> Leaderboard</h1>

        <h5 className='my-4 text-uppercase' style={{color: Colors.referralLeaderboardsPrimary}}>
            Contest Instructions
        </h5>
        <ol style={{fontSize:18}}>
            <li>Scan QR Code to download the<br/>Bettle app.</li>
            <li>Sign up for an account and import<br/> your season-long team.</li>
            <li>Copy your referral link and send it<br/> to your league mates and friends!</li>
        </ol>
        <div className='d-flex justify-content-between'>
            <div className='text-white' style={{height:250, width:250, aspectRatio:1}}>
                <img src={qrCode} className="img-fluid" />
            </div>
            <div>
                <img src={infoGraphic} style={{height:150, width:150, aspectRatio:1}}/>
            </div>
        </div>
    </div>
  )
}

export default ReferralLeaderboardsInfo