import React from 'react'
import { Button } from 'reactstrap'
// import { Colors } from '../../../helpers/colors'
import { useColors } from '../../../helpers/CustomHooks/useColors';

const Header = ({ onBackHandler, name, disableButton, isCommisioner }) => {
  const Colors = useColors();
  return (
    <div className='py-3 px-5 text-light justify-content-between' style={{ backgroundColor: Colors.bgLight }} >
      <p className='m-0 bold'>
        <span onClick={onBackHandler} style={{ cursor: 'pointer', fontSize: 15 }}><i className='mr-2 fa fa-angle-left'></i>{name}</span>
        {disableButton ? null :
          <Button type='submit' className='pull-right' style={{ border: '0px solid', color: Colors.colorPrimary, backgroundColor: Colors.bgDark, fontWeight: 'bold', marginTop: -5 }} disabled={!isCommisioner}>SAVE</Button>
        }
      </p>
    </div>
  )
}

export default Header