import React from 'react';
import store from '../store';
import { Route, Redirect } from 'react-router-dom';
import { setIntendedUrl } from '../helpers/auth';
import PropTypes from 'prop-types';
import FullPageLayout from '../containers/FullPageLayout/FullPageLayout';
import { getStore } from '../helpers/common-methods';

const propTypes = {
  component: PropTypes.func.isRequired,
  rest: PropTypes.object,
  location: PropTypes.object
};

const AuthRouteFullPage = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const { auth: { authenticated } } = getStore().getState();
      if (!authenticated) {
        setIntendedUrl(props.location.pathname+props.location.search);
      }

      return authenticated ? (
        <FullPageLayout Component={Component} {...props} />
      ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location }
            }}
          />
        );
    }
    }
  />
);

AuthRouteFullPage.propTypes = propTypes;
AuthRouteFullPage.displayName = 'Auth Route';

export default AuthRouteFullPage;
