import { Colors } from "./colors";

export const STYLES = {
    REACT_SELECT:
        {
            option: (base) => ({
                ...base,
                backgroundColor: Colors.bgLight,
                color:'white'
                
            }),
            input: (base) => ({
                ...base,
                backgroundColor: Colors.bgLight,
                color:'white',
                minWidth: 100
            }),
            menu: (base) => ({
                ...base,
                backgroundColor: Colors.bgLight,
                marginTop:0,
                zIndex:1000
                }),
            menuList: (base) => ({
                ...base,
                backgroundColor: Colors.bgLight,
                borderRadius:8,
                minWidth:'100%'
            }),
            valueContainer: (base) => ({
                ...base,
                backgroundColor: Colors.bgLight,
            }),
            control: (base) => ({
                ...base,
                backgroundColor: Colors.bgLight,
            }),
            singleValue: (base) => ({
                ...base,
                color: 'white'
            }),
            multiValue: (base) => ({
                ...base,
                backgroundColor:Colors.bgDark,
            }),
            multiValueLabel: (base) => ({
                ...base,
                color:'white',
            })
        },

        REACT_SELECT_DARK: {
            option: (base) => ({
                ...base,
                backgroundColor: Colors.bgDark_v3,
                color:'white'
            }),
            input: (base) => ({
                ...base,
                backgroundColor: Colors.bgDark_v3,
                color:'white',
                minWidth: 100
            }),
            menu: (base) => ({
                ...base,
                backgroundColor: Colors.bgDark_v3,
                marginTop:0,
                zIndex:1000
                }),
            menuList: (base) => ({
                ...base,
                backgroundColor: Colors.bgDark_v3,
                borderRadius:8,
                minWidth:'100%'
            }),
            valueContainer: (base) => ({
                ...base,
                backgroundColor: Colors.bgDark_v3,
            }),
            control: (base) => ({
                ...base,
                backgroundColor: Colors.bgDark_v3,
            }),
            singleValue: (base) => ({
                ...base,
                color: 'white'
            }),
            multiValue: (base) => ({
                ...base,
                backgroundColor:Colors.bgDark_v3,
            }),
            multiValueLabel: (base) => ({
                ...base,
                color:'white',
            })
        },

        REACT_SELECT_DANGER: {
            option: (base) => ({
                ...base,
                backgroundColor: Colors.colorDanger,
                color:'black',
            }),
            input: (base) => ({
                ...base,
                // backgroundColor: Colors.bgDark_v3,
                color:'black',
                minWidth: 100
            }),
            menu: (base) => ({
                ...base,
                backgroundColor: Colors.colorDanger,
                marginTop:0,
                zIndex:1000
                }),
            menuList: (base) => ({
                ...base,
                backgroundColor: Colors.colorDanger,
                // borderRadius:8,
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
                minWidth:'100%'
            }),
            valueContainer: (base) => ({
                ...base,
                backgroundColor: "black",
            }),
            control: (base) => ({
                ...base,
                backgroundColor: "black",
                border: 0,
                boxShadow: "none",
            }),
            singleValue: (base) => ({
                ...base,
                color: Colors.colorDanger
            }),
            multiValue: (base) => ({
                ...base,
                // backgroundColor:Colors.colorDanger,
            }),
            multiValueLabel: (base) => ({
                ...base,
                color:'black',
            })
        },

        REACT_SELECT_DANGER_CONDITIONAL: {
            option: (base) => ({
                ...base,
                backgroundColor: Colors.colorDanger,
                color:'black',
            }),
            input: (base) => ({
                ...base,
                // backgroundColor: Colors.bgDark_v3,
                color:'black',
                minWidth: 100
            }),
            menu: (base) => ({
                ...base,
                backgroundColor: Colors.colorDanger,
                marginTop:0,
                zIndex:1000
                }),
            menuList: (base) => ({
                ...base,
                backgroundColor: Colors.colorDanger,
                // borderRadius:8,
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
                minWidth:'100%'
            }),
            valueContainer: (base) => ({
                ...base,
                backgroundColor: "black",
            }),
            control: (base) => ({
                ...base,
                backgroundColor: "black",
                border: 0,
                boxShadow: "none",
                borderBottom: `2px solid ${Colors.colorDanger}`,
                borderRadius: 0,
                '&:hover': {
                    borderBottom: `2px solid ${Colors.colorDanger}`,
                }
            }),
            dropdownIndicator: (provided) => ({
                ...provided,
                "svg": {
                  fill: Colors.colorDanger
                }
            }),
            singleValue: (base) => ({
                ...base,
                color: Colors.colorDanger
            }),
            multiValue: (base) => ({
                ...base,
                // backgroundColor:Colors.colorDanger,
            }),
            multiValueLabel: (base) => ({
                ...base,
                color:'black',
            })
        },

        REACT_SELECT_TOGGLES:
        {
            option: (base) => ({
                ...base,
                backgroundColor: Colors.bgLight,
                color:'white',
                padding:0
                
            }),
            input: (base) => ({
                ...base,
                backgroundColor: Colors.bgLight,
                color:'white',
                minWidth: 100
            }),
            menu: (base) => ({
                ...base,
                backgroundColor: Colors.bgLight,
                marginTop:0,
                zIndex:1000
                }),
            menuList: (base) => ({
                ...base,
                backgroundColor: Colors.bgLight,
                borderRadius:8,
                minWidth:'100%'
            }),
            valueContainer: (base) => ({
                ...base,
                backgroundColor: Colors.bgLight,
            }),
            control: (base) => ({
                ...base,
                backgroundColor: Colors.bgLight,
            }),
            singleValue: (base) => ({
                ...base,
                color: 'white'
            }),
            multiValue: (base) => ({
                ...base,
                backgroundColor:Colors.bgDark,
            }),
            multiValueLabel: (base) => ({
                ...base,
                color:'white',
            })
        },

        REACT_SELECT_SMALL:
        {
            option: (base) => ({
                ...base,
                backgroundColor: Colors.bgLight,
                color:'white'
                
            }),
            input: (base) => ({
                ...base,
                backgroundColor: Colors.bgLight,
                color:'white',
                minWidth: 100,
            }),
            menu: (base) => ({
                ...base,
                backgroundColor: Colors.bgLight,
                marginTop:0,
                zIndex: 10000,
                }),
            menuList: (base) => ({
                ...base,
                backgroundColor: Colors.bgLight,
                borderRadius:8,
                height:'10vh'
            }),
            valueContainer: (base) => ({
                ...base,
                backgroundColor: Colors.bgLight,
            }),
            control: (base) => ({
                ...base,
                backgroundColor: Colors.bgLight,
            }),
            singleValue: (base) => ({
                ...base,
                color: 'white'
            }),
            multiValue: (base) => ({
                ...base,
                backgroundColor:Colors.bgDark,
            }),
            multiValueLabel: (base) => ({
                ...base,
                color:'white',
            })
        },

        BUTTON_PRIMARY_TRANSPARENT: {
            color: Colors.colorPrimary,
            backgroundColor: Colors.colorPrimaryTransparent,
            border: `1px solid ${Colors.colorPrimary}`,
            minWidth: "200px",
            fontWeight: "bold"
        },

        BUTTON_PRIMARY_TRANSPARENT_RESPONSIVE: {
            color: Colors.colorPrimary,
            backgroundColor: Colors.colorPrimaryTransparent,
            border: `1px solid ${Colors.colorPrimary}`,
            minWidth: "100px",
            fontWeight: "bold"
        },

        BUTTON_PRIMARY: {
            color: Colors.colorPrimary,
            backgroundColor: Colors.colorPrimaryDark,
            border: `1px solid ${Colors.colorPrimary}`,
            minWidth: "200px",
            fontWeight: "bold"
        },

        BUTTON_PRIMARY_ROUND: {
            color: Colors.bgDark,
            backgroundColor: Colors.colorPrimary,
            border: `1px solid ${Colors.colorPrimary}`,
            minWidth: "200px",
            fontWeight: "bold",
            borderRadius: "20px",
            fontFamily: "Alexandria",
            fontSize: "16px"
        },

        BUTTON_DARK_ROUND: {
            color: "white",
            backgroundColor: Colors.bgDark,
            border: `1px solid ${Colors.bgDark}`,
            minWidth: "200px",
            height: "50px",
            fontWeight: "bold",
            borderRadius: "40px"
        },

        BUTTON_WHITE_ROUND: {
            color: Colors.bgDark,
            backgroundColor: "white",
            border: `1px solid white`,
            minWidth: "200px",
            fontWeight: "bold",
            borderRadius: "20px",
            fontFamily: "Alexandria",
            fontSize: "16px"
        },

        BUTTON_PRIMARY_TRANSPARENT_LARGE: {
            color: Colors.colorPrimary,
            backgroundColor: Colors.colorPrimaryTransparent,
            border: `1px solid ${Colors.colorPrimary}`,
            minWidth: "70%",
            fontWeight: "bold"
        },

        BUTTON_DANGER_TRANSPARENT: {
            color: Colors.colorDanger,
            backgroundColor: Colors.colorDangerTransparent,
            border: `1px solid ${Colors.colorDanger}`,
        },
        BUTTON_WARNING_TRANSPARENT: {
            color: Colors.colorWarning,
            backgroundColor: Colors.colorWarningTransparent,
            border: `1px solid ${Colors.bgDark}`,
            color: Colors.bgDark,
        },

        BUTTON_SECONDARY_TRANSPARENT: {
            color: Colors.colorSecondary,
            backgroundColor: Colors.colorSecondaryTransparent,
            border: `1px solid ${Colors.colorSecondary}`,
        },

        REACT_CREATABLE_SELECT: {
            option: (providedStyles, props) => ({
                ...providedStyles, // To keep the default style
                color: props.data.__isNew__ && 'red'
            }),
            control: (base, props) => ({
                ...base,
                backgroundColor: Colors.colorDark,
            }),
        }
    
    }

    export const getSportColor = (sport) => {
        switch (sport) {
            case "MLB":
                return Colors.colorDanger;
            case "NFL":
                return Colors.nflPrimaryColor;
            case "NBA":
                return Colors.nbaPrimaryColor;
            default: 
                return Colors.colorPrimary;

        }
    }

    export const getSportColorTransparent = (sport) => {
        switch (sport) {
            case "MLB":
                return Colors.mlbPrimaryColorTransparent;
            case "NFL":
                return Colors.nflPrimaryColorTransparent;
            case "NBA":
                return Colors.nbaPrimaryColorTransparent;
            default: 
                return Colors.colorPrimaryTransparent;

        }
    }