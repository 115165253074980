import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { AppSwitch } from '@coreui/react';
import moment from 'moment-timezone';
import { Alert, Button } from 'reactstrap';
// import { Colors } from '../../../helpers/colors';
import ReactDatePicker from 'react-datepicker';
import Datetime from 'react-datetime';
import LoadingOverlay from 'react-loading-overlay';
import { updateLeagueSettings } from '../../../apis/SeasonLong/Settings/SettingsAPI';
import Header from './Header';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ErrorText from "../../../components/ErrorText";
import "react-datepicker/dist/react-datepicker.css";
import { useColors } from '../../../helpers/CustomHooks/useColors';

const GeneralSettings = ({ options_data, steps_data, onBackHandler, leagueId, isCommisioner }) => {
	const Colors = useColors();
	const [showAuction, setShowAuction] = useState(steps_data.waiver.waiver_type);
	const [waiverDays, setWaiverDays] = useState(steps_data.waiver.waiver_days);
	const [ischecked, setisChecked] = useState(steps_data.divisions.allow_division);
	const [loader, setLoader] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [error, setError] = useState([]);
	const { register, handleSubmit, control, formState: { errors } } = useForm();

	const handleWaiverDays = (key) => {
		var waiverCopy = [...waiverDays];
		if (waiverCopy.includes(key)) {
			waiverCopy.splice(waiverCopy.indexOf(key), 1);
		} else {
			waiverCopy.push(key);
		}
		setWaiverDays(waiverCopy);
	};

	const onSubmit = (data) => {
		if (data.trade_date && data.trade_time) {
			let dateTime = moment(data.trade_date).local().format('YYYY-MM-DD') + 'T' + moment(data.trade_time).local().format('HH:mm:ssZ');
			data['trade_deadline'] = moment(dateTime);
			delete data['trade_time'];
			delete data['trade_date'];
		}

		var reqData = {};
		setLoader(true);
		reqData['leagueId'] = leagueId;
		reqData['type'] = 'general';
		data['waiver_days'] = waiverDays;
		reqData['input'] = data;
		if (showAuction == 1) {
			reqData['fa_budget'] = null;
		}
		updateLeagueSettings(reqData)
			.then(() => {
				setLoader(false);
				setIsValid(false);
				console.log('Settings Updated');
			})
			.catch((err) => {
				setLoader(false);
				setIsValid(true);
				setError(err);
			});
	};

	var keeperLoop = [];
	for (let key = 1; key <= steps_data.rosterSize; key++) {
		keeperLoop.push(key);
	}

	return (
		<form className="d-flex flex-column flex1 scrollHidden" onSubmit={handleSubmit(onSubmit)}>
			<LoadingOverlay className="d-flex flex-column flex1 scrollHidden" active={loader} spinner text="Saving..." fadeSpeed={1000}>
				<Header isCommisioner={isCommisioner} onBackHandler={onBackHandler} name="General Settings" />
				<PerfectScrollbar>
					<fieldset disabled={!isCommisioner}>
						<div className="row flex1 align-items-center justify-content-center p-4 m-0">
							<div className="text-center col-md-6 col-sm-12">
								{
									isValid ? <Alert
										toggle={function noRefCheck() {
											setIsValid(!isValid);
										}}
										className="d-inline-block"
										color="danger">
										Oops! Something went wrong... Try again
									</Alert> :
										null}

								{/* Input */}
								<div className="row">
									<label className="bold">League Name</label>
									<input className="form-control" {...register('leagueName', { required: true })} defaultValue={steps_data.leagueData.leagueName} style={{ background: !isCommisioner && Colors.bgLight }} /> {errors.leagueName && <ErrorText msg="League Name is Required" />}
								</div>
								{/* !Input */}

								{/* League Type */}
								<div className="row d-flex flex-column align-items-start mt-4">
									{/* Heading */}
									<h6 className="bold mt-1">League Type</h6>
									{/* Actions Container */}
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, value } }) => (
											<div className="row">
												{Object.keys(options_data.general.league_type).map((key) => (
													<div className="col-2 mr-4 mt-1">
														<Button
															onBlur={onBlur}
															onClick={() => onChange(key)}
															className=""
															style={

																value === key ? {
																	border: `1px solid ${Colors.bgDark}`,
																	borderRadius: 25,
																	backgroundColor: Colors.colorPrimary,
																	color: Colors.bgLight
																} :
																	{
																		border: `1px solid ${Colors.bgDark}`,
																		borderRadius: 25,
																		backgroundColor: Colors.bgLight,
																		color: Colors.colorLight
																	}
															}>
															{options_data.general.league_type[key]}
														</Button>
													</div>
												))}
											</div>
										)}
										name="league_type"
										defaultValue={steps_data.leagueData.leagueType}
									/>
								</div>

								{/* !League Type */}

								{/*  No of Teams */}
								<div className="row d-flex flex-column align-items-start mt-4">
									{/* Heading */}
									<h6 className="bold mt-1">Number of Teams</h6>
									{/* Actions Container */}
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, value } }) => (
											<div className="row">
												{options_data.general.teams.map((val, key) => (
													<div key={key} className="col-1 mt-1 mr-3 ">
														<Button
															onBlur={onBlur}
															onClick={() => onChange(val)}
															className=""
															style={

																value == val ? {
																	border: `1px solid ${Colors.bgDark}`,
																	borderRadius: 25,
																	backgroundColor: Colors.colorPrimary,
																	color: Colors.bgLight,
																	height: 50,
																	width: 50
																} :
																	{
																		border: `1px solid ${Colors.bgDark}`,
																		borderRadius: 25,
																		backgroundColor: Colors.bgLight,
																		color: Colors.colorLight,
																		height: 50,
																		width: 50
																	}
															}>
															{val}
														</Button>
													</div>
												))}
											</div>
										)}
										name="max_teams"
										defaultValue={steps_data.teams}
									/>
								</div>
								{/* Format */}
								<div className="row d-flex flex-column align-items-start mt-4">
									{/* Heading */}
									<h6 className="bold mt-1">Format</h6>
									{/* Actions Container */}
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, value } }) => (
											<div className="row">
												{Object.keys(options_data.general.leagueFormat).map((key) => (
													<div className="col-2 mr-3 mt-1">
														<Button
															onBlur={onBlur}
															onClick={() => onChange(key)}
															className=""
															style={

																value == key ? {
																	border: `1px solid ${Colors.bgDark}`,
																	borderRadius: 25,
																	backgroundColor: Colors.colorPrimary,
																	color: Colors.bgLight
																} :
																	{
																		border: `1px solid ${Colors.bgDark}`,
																		borderRadius: 25,
																		backgroundColor: Colors.bgLight,
																		color: Colors.colorLight
																	}
															}>
															{options_data.general.leagueFormat[key]}
														</Button>
													</div>
												))}
											</div>
										)}
										name="league_format_id"
										defaultValue={steps_data.leagueData.league_format_id}
									/>
								</div>
								{/* !Format */}

								{/* Waivers */}
								<div className="row d-flex flex-column align-items-start mt-4">
									{/* Heading */}
									<h6 className="bold mt-1">Waivers</h6>
									{/* Actions Container */}
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, value } }) => (
											<div className="row">
												{Object.keys(options_data.waiver.waiver_format).map((key) => (
													<div className="col-2 mt-1 mr-4">
														<Button
															onBlur={onBlur}
															onClick={() => {
																setShowAuction(key);
																onChange(key);
															}}
															className="mr-1"
															style={{
																border: `1px solid ${Colors.bgDark}`,
																borderRadius: 25,
																backgroundColor:

																	value === key ? Colors.colorPrimary :
																		Colors.bgLight,
																color:

																	value === key ? Colors.bgLight :
																		Colors.colorLight
															}}>
															{options_data.waiver.waiver_format[key]}
														</Button>
													</div>
												))}
											</div>
										)}
										name="waiver_type"
										defaultValue={steps_data.waiver.waiver_type}
									/>{' '}
									{showAuction == 2 && (
										<div className="flex1 m-2">
											<input className="flex1 form-control" {...register('fa_budget', { required: true, pattern: /^[0-9]/i })} defaultValue={steps_data.waiver.fa_budget} />{' '}
											{errors.fa_budget && <ErrorText msg="FA_budget is required [0-9]" />}
										</div>
									)}
								</div>

								{/* !Waivers */}

								{/* Waiver Processing Days */}
								<div className="row d-flex flex-column align-items-start mt-4">
									{/* Heading */}
									<h6 className="bold mt-1">Waiver Processing Days</h6>
									{/* Actions Container */}
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, value } }) => (
											<div className="row">
												{Object.keys(options_data.waiver.waiver_days).map((key) => (
													<Button
														className="mt-2 mr-2 ml-3"
														onClick={() => handleWaiverDays(key)}
														style={{
															border: `1px solid ${Colors.bgDark}`,
															borderRadius: 25,
															backgroundColor:
																waiverDays.includes(key) ? Colors.colorPrimary :
																	Colors.bgLight,
															color:
																waiverDays.includes(key) ? Colors.bgLight :
																	Colors.colorLight
														}}>
														{options_data.waiver.waiver_days[key]}
													</Button>
												))}
											</div>
										)}
										name="waiver_type"
										defaultValue={steps_data.waiver.waiver_days}
									/>
								</div>
								{/* !Waiver Processing Days */}

								{/* Trade Review Period */}
								<div className="row d-flex flex-column align-items-start mt-4">
									{/* Heading */}
									<h6 className="bold mt-1">Trade Review Period</h6>
									{/* Actions Container */}
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, value } }) => (
											<div className="row mt-1 mr-2 ml-1">
												{Object.keys(options_data.trading_rules.trade_review_period).map((key) => (
													<div>
														<Button
															onBlur={onBlur}
															onClick={() => onChange(key)}
															className="mr-1"
															style={{
																border: `1px solid ${Colors.bgDark}`,
																borderRadius: 25,
																backgroundColor:

																	value == key ? Colors.colorPrimary :
																		Colors.bgLight,
																color:

																	value == key ? Colors.bgLight :
																		Colors.colorLight
															}}>
															{options_data.trading_rules.trade_review_period[key]}
														</Button>
													</div>
												))}
											</div>
										)}
										name="trade_review_period"
										defaultValue={steps_data.trades.trade_review_period}
									/>
								</div>
								{/*  No of Playoff Teams */}
								<div className="row d-flex flex-column align-items-start mt-4">
									{/* Heading */}
									<h6 className="bold my-1">Number of Playoff Teams</h6>
									{/* Actions Container */}
									<div className="flex1 my-1">
										<input className="flex1 flex-row form-control" {...register('playoff_teams', { required: true, pattern: /^[0-9]/i })} defaultValue={steps_data.schedule_data.playoff_teams} style={{ background: !isCommisioner && Colors.bgLight }} />{' '}
										{errors.playoff_teams && <ErrorText msg="Teams Required [0-9]" />}
									</div>
								</div>
								{/* ! No of Playoff Teams */}

								<div className="row d-flex flex-column align-items-start mt-4">
									{/* Heading */}
									<h6 className="bold my-1">Trade Deadline Date</h6>
									{/* Actions Container */}
									<Controller
										className='border'
										control={control}
										render={({ field: { onChange, onBlur, value } }) => <ReactDatePicker className={!isCommisioner ?"disablePicker" : "picker my-1"} closeOnScroll='true' onBlur={onBlur} placeholder="Trade Deadline Date" selected={value} onChange={(d) => onChange(d)} readOnly={!isCommisioner} />}
										name="trade_date"
										defaultValue={moment(steps_data.trades.trade_deadline).toDate()}
									/>
								</div>

								<div className="row d-flex flex-column align-items-start mt-4">
									{/* Heading */}
									<h6 className="bold my-1">Trade Deadline Time</h6>
									{/* Actions Container */}
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, value } }) => <Datetime className='darkTimePicker' dateFormat={false} onBlur={onBlur} value={value} onChange={(e) => onChange(e)} closeOnClickOutside={true} />}
										name="trade_time"
										defaultValue={moment(steps_data.trades.trade_deadline).toDate()}
									/>
								</div>

								<div className="row d-flex flex-column align-items-start mt-4">
									{/* Heading */}
									<h6 className="bold mt-1">Maximum Number of Keeps</h6>
									{/* Actions Container */}
									{errors.number_keepers && <ErrorText msg="Keeper is required" />}
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, value } }) => (
											<div className="row">
												{keeperLoop.map((key) => (
													<Button
														onBlur={onBlur}
														onClick={() => onChange(key)}
														className="m-1 ml-3"
														style={{
															border: `1px solid ${Colors.bgDark}`,
															borderRadius: 25,
															backgroundColor:

																value == key ? Colors.colorPrimary :
																	Colors.bgLight,
															color:

																value == key ? Colors.bgLight :
																	Colors.colorLight,
															height: 50,
															width: 50
														}}>
														{key}
													</Button>
												))}
											</div>
										)}
										name="number_keepers"
										defaultValue={steps_data.keeper.number_keepers}
									/>
								</div>
								{/* ! Max No of Keeps */}

								{/*  Switch */}
								<div className="row d-flex flex-row align-items-center mt-4">
									{/* Heading */}
									<h6 className="bold p-0 my-0 mr-1">Allow Draft Pick Trading?</h6>
									{/* Switch */}
									<Controller
										control={control}
										render={({ field: { onChange, onBlur, value } }) => (
											<AppSwitch className={'mr-1 vertical-middle mt-1'} variant={('3d', 'pill')} color="success" checked={value} onChange={(value) => onChange(value)} onBlur={onBlur} />
										)}
										name="draft_pick_trading"
										defaultValue={steps_data.trades.draft_pick_trading}
									/>
								</div>
								{/* ! Switch */}
							</div>
						</div>
					</fieldset>
				</PerfectScrollbar>
			</LoadingOverlay>
		</form>
	);
};

export default GeneralSettings;
