// import { mergeAndSum, parseTotalStat } from "."
import { mergeAndSum } from "../common-methods"

export const mlbPlayerPoints = (stats, rules, scoringFormat) => {
    if(scoringFormat == "h2h_weekly_points"){
        return mlbNormalPoints(stats, rules)
    }else if (scoringFormat == "h2h_each_category"){
        return mlbEachCatPoints(stats, rules)
    }else{
        return 0
    }
}

export const mlbGeneratePoints = (rules, players) => {
    let total = 0
    let prevTotal = 0
    if(players != undefined){
        players.map((player) => {
            total = total + mlbNormalPoints(player.stats, rules)
            if(player.prev_stats!=null){
                prevTotal = prevTotal + mlbNormalPoints(player.prev_stats, rules)
            }
            if(player.wtdStats!=null){
                prevTotal = prevTotal + mlbNormalPoints(player.wtdStats, rules)
            }
        })
    }
    return (total+prevTotal).toFixed(2)
}

export const eachCatFormula = (team1Totals, team2Totals, rules) => {
    var selectedCats = []
    var team1Wlt = {
        w: 0,
        l: 0,
        t: 0
    }
    var team2Wlt = {
        w: 0,
        l: 0,
        t: 0
    }
    Object.values(rules.mapping).map((rule) => {
        if(rule.display){
            selectedCats.push(rule)
        }
    })
    selectedCats.map((cat) => {
        if(cat.lower_total){
            if(parseFloat(team1Totals[cat.stat]) < parseFloat(team2Totals[cat.stat])){
                team1Wlt["w"] = team1Wlt["w"] != undefined ? (team1Wlt["w"] + 1) : 1
                team2Wlt["l"] = team2Wlt["l"] != undefined ? (team2Wlt["l"] + 1) : 1
            }else if (parseFloat(team1Totals[cat.stat]) > parseFloat(team2Totals[cat.stat])){
                team2Wlt["w"] = team2Wlt["w"] != undefined ? (team2Wlt["w"] + 1) : 1
                team1Wlt["l"] = team1Wlt["l"] != undefined ? (team1Wlt["l"] + 1) : 1
            }
        }else{
            if(parseFloat(team1Totals[cat.stat]) > parseFloat(team2Totals[cat.stat])){
                team1Wlt["w"] = team1Wlt["w"] != undefined ? (team1Wlt["w"] + 1) : 1
                team2Wlt["l"] = team2Wlt["l"] != undefined ? (team2Wlt["l"] + 1) : 1
            }else if (parseFloat(team2Totals[cat.stat]) > parseFloat(team1Totals[cat.stat])){
                team2Wlt["w"] = team2Wlt["w"] != undefined ? (team2Wlt["w"] + 1) : 1
                team1Wlt["l"] = team1Wlt["l"] != undefined ? (team1Wlt["l"] + 1) : 1
            }
        }
        if(parseFloat(team1Totals[cat.stat]) == parseFloat(team2Totals[cat.stat])){
            team1Wlt["t"] = team1Wlt["t"] != undefined ? (team1Wlt["t"] + 1) : 1
            team2Wlt["t"] = team2Wlt["t"] != undefined ? (team2Wlt["t"] + 1) : 1
        }
    })
    return {team1Wlt: team1Wlt, team2Wlt: team2Wlt}
}

export const mlbH2HEachCatPoints = (rules, team1Players, team2Players) => {
    // FIRST SUM UP STAT CATEGORIES FOR EACH TEAM
    var team1Totals = {}
    var team2Totals = {}
    // TOTAL PLAYERS SUM FOR TEAM 1
    var team1Totals = {}
    team1Players.map((player) => {
        var currentStats = player.stats ? player.stats : {}
        var wtdStats = player.wtdStats ? player.wtdStats : {}
        var prevStats = player.prevStats ? player.prevStats : {}
        var firstSum = mergeAndSum(currentStats, wtdStats)
        var finalSum = mergeAndSum(prevStats, firstSum)
        team1Totals = mergeAndSum(team1Totals, finalSum)
    })
    var team2Totals = {}
    team2Players.map((player) => {
        var currentStats = player.stats ? player.stats : {}
        var wtdStats = player.wtdStats ? player.wtdStats : {}
        var prevStats = player.prevStats ? player.prevStats : {}
        var firstSum = mergeAndSum(currentStats, wtdStats)
        var finalSum = mergeAndSum(prevStats, firstSum)
        team2Totals = mergeAndSum(team2Totals, finalSum)
    })
    // NOW WE WILL DECIDE WHICH ONE IS THE WINNER OR LOSER IN EACH CATEGORY
    return eachCatFormula(team1Totals, team2Totals, rules)
}

export const mlbSinglePlayerPoints = (player, rules) => {
    var total = 0
    if(player.stats){
        total = total + mlbNormalPoints(player.stats, rules, player)
    }
    if(player.wtdStats){
        total = total + mlbNormalPoints(player.wtdStats, rules, player)
    }
    if(player.prevStats){
        total = total + mlbNormalPoints(player.prevStats, rules)
    }
    return total
}

export const mlbNormalPoints = (stats, rules) => {
    let points = 0.00
    let map = rules.mapping
        for (const [key, scoring] of Object.entries(map)) {
            if(scoring && scoring["stat"] != undefined){
                if( stats && (scoring !=undefined) && (stats[scoring["stat"]] !=undefined)){
                    if((scoring["sum"]) !=undefined){
                        if(scoring["sum"] == 0){
                            if(parseFloat(stats[scoring["stat"]]) <= parseFloat(scoring["sum"])){
                                points = points + parseInt(scoring["points"]);
                            }
                        }else{
                            if(parseFloat(stats[scoring["stat"]]) >= parseFloat(scoring["sum"])){
                                points = points + parseInt(scoring["points"]);
                            }
                        }
                    }else if((scoring["le"]) !=undefined){
                        // give bonus between speicifed yards
                        if( parseFloat(stats[scoring["stat"]]) >= parseFloat(scoring["ge"]) && parseFloat(stats[scoring["stat"]]) <= parseFloat(scoring["le"])){
                            points = points + parseInt(scoring["points"]);
                        }                                                                                                                           
                    }else{
                        // normal case
                        points = points + parseFloat(stats[scoring["stat"]]) * parseFloat(scoring["points"]);
                    }
                }
            }else if(scoring && scoring["statGroup"] != undefined){
                scoring["statGroup"].map((singleStat) => {
                    if(stats && stats[singleStat] != undefined){
                        points = points + parseFloat(stats[singleStat]) * parseInt(scoring["points"]);
                    }
                })
            }   
        }
    return parseFloat(points.toFixed(2))
}

const mlbEachCatPoints = () => {
    return 0
}

export const rotoStandingsFormula = (selectedCatsDaily, prepareStandings, rules) => {
    var statsCatWise = {}
    selectedCatsDaily.map((selectedCat) => {
        // NOW WE HAVE SELECTED CATS READY
        Object.keys(prepareStandings).map((userId) => {
            if(statsCatWise[selectedCat]){
                statsCatWise[selectedCat].push(
                    {
                        userId: userId,
                        stats: prepareStandings[userId]["stats"][selectedCat]
                    }
                )
            }else{
                statsCatWise[selectedCat] = []
                statsCatWise[selectedCat].push(
                    {
                        userId: userId,
                        stats: prepareStandings[userId]["stats"][selectedCat]
                    }
                )
            }
        })
    })
    // NEED TO SORT ARRAY SO THAT WE WILL GET POINTS
    var statsCatWiseCopy = {...statsCatWise}
    Object.keys(statsCatWise).map((statKey) => {
        // SORT BASED ON ASC ORDER SO THAT MAX POINTS GANINER CAN GET 12 POINTS
        statsCatWiseCopy[statKey] = statsCatWiseCopy[statKey].sort((team1, team2) => parseFloat(team1.stats) > parseFloat(team2.stats) ? 1 : -1)
    })
    // NOW WE NEED TO GO THOUGH SORTED ARRAY AND GENERATE POINTS
    var standingsCopy = {...prepareStandings}
    Object.keys(statsCatWiseCopy).map((statKey) => {
        Object.keys(prepareStandings).map((userId) => {
            var teamPoint = 0
            if(rules[statKey].lower_total){
                // THIS WILL REVERS THE SORTING AND VALUES WILL BE ACCORDING TO DESC ORDER, IN THAT CASE MIN STAT TEAM WILL GET 12 POINTS
                var statReversed = [...statsCatWiseCopy[statKey]].reverse()
                teamPoint = calcTeamDailyPoints(userId, statReversed)
                standingsCopy[userId]["points"][statKey] = parseFloat(teamPoint)
            }else{
                teamPoint = calcTeamDailyPoints(userId, statsCatWiseCopy[statKey])
                standingsCopy[userId]["points"][statKey] = parseFloat(teamPoint)
            }
            if(standingsCopy[userId]["totalPoints"] != undefined){
                standingsCopy[userId]["totalPoints"] =  parseFloat(standingsCopy[userId]["totalPoints"]) + parseFloat(teamPoint)
            }else{
                // standingsCopy[userId]["totalPoints"] = 0
                standingsCopy[userId]["totalPoints"] =  parseFloat(teamPoint)
            }
        })
    })
    return Object.values(standingsCopy).sort((team1, team2) => team1.totalPoints < team2.totalPoints ? 1 : -1)
}

// THIS WILL TAKE 
export const calcTeamDailyPoints = (userId, allTeamsStats) => {
    var points = 0
    var originalPosition = 1;
    var teamStatVal = 0;
    allTeamsStats.map((teamStat) => {
        if(teamStat.userId == userId){
            points = originalPosition
            teamStatVal = teamStat.stats
        }
        originalPosition++
    })
    // NOW WE WILL NEED TO CHECK HOW MANY OTHER TEAMS HAVE SIMILAR STAT VALUES
    var similarStatsCount = 0;
    var similarStatsSum = 0;
    allTeamsStats.map((teamStat, index) => {
        if(teamStat.stats == teamStatVal){
            // index + 1 = current team point
            similarStatsSum = parseFloat(similarStatsSum) + (parseInt(index)+1)
            similarStatsCount++
        }
    })
    // NOW CHECK IF WE HAVE MORE THAN 1 TEAMS WHO HAVE SIMILAR POINTS THEN TAKE AVERAGE
    if(similarStatsCount > 1){
        return parseFloat(similarStatsSum)/parseFloat(similarStatsCount)
    }
    return parseFloat(points)
}

const handleDivisonByZero = (nom, denom) => {
    if(denom){
        return parseFloat(nom/denom).toFixed(3)
    }
    return 0
}

 export const prepareStatValue = (rawStats, statKey) => {
    var IP = (parseInt(rawStats["pitching_ip_2"]) + ((rawStats["pitching_ip_2"] - parseInt(rawStats["pitching_ip_2"])) / 0.3) )
    if(statKey == "hitting_abhr"){
        return handleDivisonByZero(rawStats["hitting_ab"], rawStats["hitting_onbase_hr"]);
    }else if(statKey == "hitting_abk"){
        return handleDivisonByZero(rawStats["hitting_ab"], rawStats["hitting_outs_ktotal"]);
    }else if(statKey == "hitting_babip"){  
        return handleDivisonByZero(
            (rawStats["hitting_onbase_h"] - rawStats["hitting_onbase_hr"]),
            (rawStats["hitting_ab"] - rawStats["hitting_onbase_hr"] - rawStats["hitting_outs_ktotal"] + rawStats["hitting_outs_sacfly"])
            );
    }else if(statKey == "hitting_bbk"){
        return handleDivisonByZero(rawStats["hitting_onbase_bb"], rawStats["hitting_outs_ktotal"]);
    }else if(statKey == "hitting_bbpa"){
        return handleDivisonByZero(rawStats["hitting_onbase_bb"], rawStats["hitting_ap"]);
    }else if(statKey == "hitting_iso"){
        var nom = handleDivisonByZero(
            (rawStats["hitting_onbase_s"] + (rawStats["hitting_onbase_d"]*2) + (rawStats["hitting_onbase_t"]*3) + (rawStats["hitting_onbase_hr"]*4)),
            rawStats["hitting_ab"]
        );
        var denom = handleDivisonByZero(
            rawStats["hitting_onbase_h"],
            rawStats["hitting_ab"]
        );
        return nom - denom;
        
    }else if(statKey == "hitting_obp"){
        return handleDivisonByZero(
            (rawStats["hitting_onbase_h"] + rawStats["hitting_onbase_bb"] + rawStats["hitting_onbase_hbp"]),
            (rawStats["hitting_ab"] + rawStats["hitting_onbase_bb"] + rawStats["hitting_onbase_hbp"] + rawStats["hitting_outs_sacfly"])
        );
    }else if(statKey == "hitting_ops"){
        var nom = handleDivisonByZero(
            (rawStats["hitting_onbase_h"] + rawStats["hitting_onbase_bb"] + rawStats["hitting_onbase_hbp"]),
            (rawStats["hitting_ab"] + rawStats["hitting_onbase_bb"] + rawStats["hitting_onbase_hbp"] + rawStats["hitting_outs_sacfly"])
        );
        var demom = handleDivisonByZero(
            (rawStats["hitting_onbase_s"] + (rawStats["hitting_onbase_d"]*2) + (rawStats["hitting_onbase_t"]*3) + (rawStats["hitting_onbase_hr"]*4)),
            rawStats["hitting_ab"]
        );
        return nom + demom;
    }else if(statKey == "hitting_seca"){
        return handleDivisonByZero(
            (rawStats["hitting_onbase_tb"] - rawStats["hitting_onbase_h"] + rawStats["hitting_onbase_bb"] + rawStats["hitting_steal_stolen"] - rawStats["hitting_steal_caught"]),
            rawStats["hitting_ab"]);
    }else if(statKey == "hitting_slg"){
        return handleDivisonByZero(
            (rawStats["hitting_onbase_s"] + (rawStats["hitting_onbase_d"]*2) + (rawStats["hitting_onbase_t"]*3) + (rawStats["hitting_onbase_hr"]*4)),
            rawStats["hitting_ab"]);
    }else if(statKey == "hitting_avg"){
        return handleDivisonByZero(
            (rawStats["hitting_onbase_h"]),
            rawStats["hitting_ab"]);
    }else if(statKey == "hitting_gofo"){
        return handleDivisonByZero(
            (rawStats["hitting_outs_go"]),
            (rawStats["hitting_outs_fo"] + rawStats["hitting_outs_po"] + rawStats["hitting_outs_lo"])
        );
    }else if(statKey == "hitting_steal_pct"){
        return handleDivisonByZero(
            (rawStats["hitting_steal_stolen"]),
            (rawStats["hitting_steal_stolen"] + rawStats["hitting_steal_caught"])
        );
    }else if(statKey == "pitching_oba"){
        return handleDivisonByZero(
            (rawStats["pitching_onbase_h"]),
            ((rawStats["pitching_bf"] - rawStats["pitching_onbase_bb"] - rawStats["pitching_onbase_hbp"] - rawStats["pitching_outs_sachit"] - rawStats["pitching_outs_sacfly"] - rawStats["fielding_errors_interference"]))
        );
    }else if(statKey == "pitching_era"){
        return (handleDivisonByZero(
            (rawStats["pitching_runs_earned"]),
            IP
        ) * 9).toFixed(2)
    }else if(statKey == "pitching_k9"){
        return (handleDivisonByZero(
            (rawStats["pitching_outs_ktotal"]),
            IP
        ) * 9).toFixed(2);
    }else if(statKey == "pitching_whip"){
        return handleDivisonByZero(
            (rawStats["pitching_onbase_h"] + rawStats["pitching_onbase_bb"]),
            IP
        );
    }else if(statKey == "pitching_kbb"){
        return handleDivisonByZero(
            (rawStats["pitching_outs_ktotal"]),
            rawStats["pitching_onbase_bb"]
        );
    }else if(statKey == "pitching_gofo"){
        return handleDivisonByZero(
            (rawStats["pitching_outs_go"]),
            (rawStats["pitching_outs_fo"] + rawStats["pitching_outs_po"] + rawStats["pitching_outs_lo"])
        );
    }else if(statKey == "pitching_babip"){
        return handleDivisonByZero(
            (rawStats["pitching_onbase_h"] - rawStats["pitching_onbase_hr"]),
            (rawStats["pitching_oab"] - rawStats["pitching_onbase_hr"] - rawStats["pitching_outs_ktotal"] + rawStats["pitching_outs_sacfly"])
        );
    }else if(statKey == "pitching_bf_ip"){
        return handleDivisonByZero(
            (rawStats["pitching_bf"]),
            IP
        );
    }else if(statKey == "pitching_gbfb"){
        return handleDivisonByZero(
            (rawStats["pitching_in_play_groundball"]),
            rawStats["pitching_in_play_flyball"]
        );
    }else if(statKey == "pitching_slg"){
        return handleDivisonByZero(
            (rawStats["pitching_onbase_s"] + (rawStats["pitching_onbase_d"] * 2) + (rawStats["pitching_onbase_t"] * 3) + (rawStats["pitching_onbase_hr"] * 4)),
            rawStats["pitching_oab"]
        );
    }else if(statKey == "pitching_obp"){
        return handleDivisonByZero(
            (rawStats["pitching_onbase_h"] + 
            rawStats["pitching_onbase_bb"] +
            rawStats["pitching_onbase_hbp"] +
            rawStats["pitching_onbase_hr"] * 4),
            rawStats["pitching_oab"] + rawStats["pitching_onbase_bb"] + rawStats["pitching_onbase_hbp"] + rawStats["pitching_outs_sacfly"]
        );
    }else if(statKey == "pitching_onbase_h9"){
        return handleDivisonByZero(
            rawStats["pitching_onbase_h"],
            IP * 9
        );
    }else if(statKey == "pitching_onbase_hr9"){
        return handleDivisonByZero(
            rawStats["pitching_onbase_hr"],
            IP * 9
        );
    }else if(statKey == "pitching_pitches_per_bf"){
        return handleDivisonByZero(
            rawStats["pitching_pitches_count"],
            rawStats["pitching_bf"]
        );
    }else if(statKey == "fielding_rf"){
        return handleDivisonByZero(
            rawStats["fielding_po"] + rawStats["fielding_a"],
            rawStats["fielding_games_play"]
        );
    }else{
        return parseFloat(rawStats[statKey]).toFixed(2)
    }
}
// h2h cat fun
export const generateBetTotals = (total, current, sport, rules) => {
    // SUM STATS
    current = current ? current : []
    total = total ? total : []

    var merged = mergeAndSum(total, current)
    if(sport == "MLB"){
        // GET TOTAL POINTS
        return mlbNormalPoints(merged, {mapping: rules})
    }else if(sport == "NFL"){
      return 0
    }
}
