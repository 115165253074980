import React from "react"
import { Button } from "reactstrap"
import { STYLES } from "../../helpers/stylesHelper"

const AfterOnboardFooter = (props) => {
    const {onSubmit, closePlayerModal} = props
    return (
        <div className="d-flex justify-content-between align-items-center my-3 px-4" >
            <Button onClick={closePlayerModal} style={STYLES.BUTTON_DANGER_TRANSPARENT}>Cancel</Button>
            <Button onClick={onSubmit} style={STYLES.BUTTON_PRIMARY_TRANSPARENT}>Save</Button>
        </div>
        )
}
export default AfterOnboardFooter