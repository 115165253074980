import React, { Component } from 'react';
// import { Colors } from '../../helpers/colors';
import { ColorContext } from '../../context/ColorProvider';

class Threads extends Component {
    constructor(props) {
        super(props);
    }
    static contextType = ColorContext;
    render() {
        const Colors = this.context; 
        return ( 
                <div class="container d-flex flex-column h-100" style={{overflow:'scroll'} }>
                    <h1 className='my-2'>Threads</h1>
                    <div className="flex1 py-2 mb-4" style={{ overflow:'scroll'}}>
                        <div className="w-100" id='mentions'>
                            <div>
                                <p className='m-0 p-0' style={{color:Colors.colorSecondary}}>
                                    <span className="bold d-block" style={{color:'white'}}>#player-news</span>
                                    Baby Got Dak, Ty Masterson and 3 others
                                </p>
                                <div className="d-flex flex-column px-2 mb-4" style={{ overflow:'scroll', backgroundColor: Colors.bgDark, borderRadius: 15,border:`0.01em solid ${Colors.darkBgBorder}` }}>
                                    
                                    {/* Mention */}
                                    <div className="d-flex flex-row my-2 text-white">
                                        <div className="ml-0 mr-2 p-0 position-relative">
                                            <i  className='fa fa-circle position-absolute' style={{color:Colors.colorPrimary,top:26, right:1}}></i>
                                            <img width='40px' className="rounded" src="https://picsum.photos/200" ></img>
                                        </div>
                                        <div className="m-0 flex1 p-0 pl-2">
                                            <h6 className='m-0 bold' >Baby Got Dak <span className='small' style={{color:Colors.colorSecondary}}>6:47 PM</span></h6>
                                            <p style={{cursor:'pointer'}} >This felt painful!</p>
                                            <img src="https://media1.giphy.com/media/O7ri9HBKvAUuc/giphy.gif?cid=ecf05e47a9k7kuj2aowdszcgnsprhhgpawjym4bqp0m04ign&rid=giphy.gif&ct=g" height='250px'></img>
                                        </div>
                                    </div>
                                    {/* !Mention */}

                                    {/* Reply */}
                                    <div className="d-flex flex-row my-2 text-white">
                                        <div className="ml-0 mr-2 p-0 position-relative">
                                            <i  className='fa fa-circle position-absolute' style={{color:Colors.colorPrimary,top:26, right:1}}></i>
                                            <img width='40px' className="rounded" src="https://picsum.photos/200" ></img>
                                        </div>
                                        <div className="m-0 flex1 p-0">
                                            <h6 className='m-0 bold' >Dan Gentile <span className='small' style={{color:Colors.colorSecondary}}>7:00 PM</span></h6>
                                            <p>This made me laugh so hard! Then I realized he was in my lineup...</p>
                                        </div>
                                    </div>
                                    {/* !Reply */}
                                </div>
                            </div>

                            <div>
                                <p className='m-0 p-0' style={{color:Colors.colorSecondary}}>
                                    <span className="bold d-block" style={{color:'white'}}><i class="fa fa-circle-o" aria-hidden="true"></i> Baby Got Dak</span>
                                    You and Baby Got Dak
                                </p>
                                <div className="d-flex flex-column px-2 mb-4" style={{ overflow:'scroll', backgroundColor: Colors.bgDark, borderRadius: 15,border:`0.01em solid ${Colors.darkBgBorder}` }}>
                                    
                                    {/* Mention */}
                                    <div className="d-flex flex-row my-2 text-white">
                                        <div className="ml-0 mr-2 p-0 position-relative">
                                            <i  className='fa fa-circle position-absolute' style={{color:Colors.colorPrimary,top:26, right:1}}></i>
                                            <img width='40px' className="rounded" src="https://picsum.photos/200" ></img>
                                        </div>
                                        <div className="m-0 flex1 p-0 pl-2">
                                            <h6 className='m-0 bold' >Baby Got Dak <span className='small' style={{color:Colors.colorSecondary}}>6:47 PM</span></h6>
                                            <p style={{cursor:'pointer'}} >This felt painful!</p>
                                            <img src="https://media1.giphy.com/media/O7ri9HBKvAUuc/giphy.gif?cid=ecf05e47a9k7kuj2aowdszcgnsprhhgpawjym4bqp0m04ign&rid=giphy.gif&ct=g" height='250px'></img>
                                        </div>
                                    </div>
                                    {/* !Mention */}

                                    {/* Reply */}
                                    <div className="d-flex flex-row my-2 text-white">
                                        <div className="ml-0 mr-2 p-0 position-relative">
                                            <i  className='fa fa-circle position-absolute' style={{color:Colors.colorPrimary,top:26, right:1}}></i>
                                            <img width='40px' className="rounded" src="https://picsum.photos/200" ></img>
                                        </div>
                                        <div className="m-0 flex1 p-0">
                                            <h6 className='m-0 bold' >Dan Gentile <span className='small' style={{color:Colors.colorSecondary}}>7:00 PM</span></h6>
                                            <p>This made me laugh so hard! Then I realized he was in my lineup...</p>
                                        </div>
                                    </div>
                                    {/* !Reply */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
         );
    }
}
 
export default Threads;