import React, { useEffect, useState } from "react"
import { Button, Collapse } from "reactstrap";
import { mmAcceptMatch } from "../../../apis/Arena/MatchMakerApis";
import ErrorText from "../../../components/ErrorText";
import ArenaError from "../../../components/Matchmaker/ArenaError";
import { LoaderCenter } from "../../../components/Loader";
import { STYLES } from "../../../helpers/stylesHelper";
// import OnboardedPlayerModal from "./OnboardPlayerModal";
// import ArenaPlayerSelectModal from "./ArenaPlayerSelectModal";
import { errorMessage } from "../../../helpers/common-methods";
import Confetti from "../../../helpers/animations/Confetti";
import { parseProfleStatus } from "../../../helpers/Payments/GidxHelper";
import { Colors } from "../../../helpers/colors";
import { backArrowIcon } from "../../../helpers/icons";
import AcceptAction from "../ProposalActions/AcceptAction";

const styles = {
    container: {
        backgroundColor: "color-basic-1100",
        borderRadius: 16,
        minHeight: 300,
      }
}
const AcceptMatchCollapse = (props) => {
    const {isOpen, toggle} = props

    const handleCancel = () => {
        toggle(true)
    }

    return (
        <Collapse isOpen={isOpen} style={{ backgroundColor: Colors.black_90 }}>
            <div className='d-flex align-items-center align-items-center pl-2 pt-2'  style={{ color: Colors.colorLight }}>
                <div className='mx-2 pointer align-items-center d-flex' style={{width:20}} onClick={handleCancel}>{backArrowIcon()}</div>
                <p className='m-0'>ACCEPT</p>
            </div>
            <AcceptAction {...props} />
        </Collapse>
    )
}

export default AcceptMatchCollapse


            {/* Confetti After Match Accepted */}
            // { view == 'success' && <Confetti/>}
            // <div style={styles.container}>
                {/* <div style={{marginVertical: 5, flexDirection: "row", "justifyContent": "flex-end", alignItems: "center"}} >
                    <Pressable onPress={toggle} ><CloseIcon /></Pressable>
                </div>*/}
                {/* {error && <ErrorText customStyles={{textAlign: "center"}} msg={error} />}
                {getView()}
            </div>
            {onboardedPlayerModal && <OnboardedPlayerModal handleConfirm={handleConfirm} handleEdit={handleEdit} players={data.onboardedPlayers} proposedData={selectedLineup.proposedData} visible={onboardedPlayerModal} />}
            {playerSelectModal && <ArenaPlayerSelectModal onSuccess={handleSuccess} selectedLineup={selectedLineup} toggle={() => {setPlayerSelectModal(!playerSelectModal); setView("confirm")}} visible={playerSelectModal} teamData={data} /> }
        </div> */}