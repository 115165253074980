import React, { Component } from 'react'
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledAlert } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText } from 'reactstrap';
import classnames from 'classnames';
// import { Colors } from '../../../helpers/colors';
import { pendingClaims, swapClaims, tradePrposals, deleteTrade, updateTradeOffer, cancelClaim, editClaim } from '../../../apis/SeasonLong/PendingTransactions/PendingTransaction';
import { connect } from 'react-redux';
import { Container, Draggable } from 'react-smooth-dnd';
import { applyDrag, generateItems } from './utils';
import Header from '../Settings/Header';
import { toastError } from '../../../helpers/toasts';
import { LoaderCenter } from '../../Loader';
import { ColorContext } from '../../../context/ColorProvider';

class PendingTransactions extends Component {
  constructor(props) {

    super(props);
    this.state = {
      id: null,
      editOfferId: null,
      activeTab: 'waiver_claims',
      claims: [],
      trades: [],
      total_budget: 0,
      waiverType: 0,
      processData: {},
      loading: true,
      error: [],
      success: false,
      reject: false,
      cancelClaim: false,
      successClaim: false,
      cancelClaimError: false,
      tradeLoading: false,
      modal: false,
      editOffer: null,
      checkDisable: null,
      items: generateItems(5, (index) => {
        return {
          id: index,
          data: 'Draggable' + index
        };
      })
    };
    this.toggle = this.toggle.bind(this);
    this.toggleNav = this.toggleNav.bind(this);
  }
  static contextType = ColorContext;

  toggleNav(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  toggle() {
    this.setState({
      modal: !this.state.modal,
      editOffer: null
    });
  }

  componentDidMount() {
    this.handleTrade()
    var league_id = this.props.leagueId
    pendingClaims({ league_id }).then((resp) => {
      if (resp.w_type == 2) {
        var cliamsCopy = []
        resp.claims.map((grpByAmount) => {
          grpByAmount.map((singleClaim) => {
            cliamsCopy.push(singleClaim)
          })
        })
        this.setState({ claims: cliamsCopy })
        this.setState({ loading: false })
      } else {
        this.setState({ claims: resp.claims })
        this.setState({ loading: false })
      }
      this.setState({ total_budget: resp.total_budget })
      this.setState({ waiverType: resp.w_type })
      this.setState({ processData: resp.processData })
      setTimeout(() => {
        this.setState({ loading: false })
      }, 1000);

    })
  }

  handleTrade = () => {
    tradePrposals(this.props.leagueId).then((resp) => {
      this.setState({ trades: resp.tradeData })
    }).catch(({ message }) => {
      this.setState({ error: message })
    })
  }


  handleSwap = () => {
    let sortedClaims = []
    sortedClaims = this.state.claims.map((claim) => {
      return { p: claim.priority, cid: claim.id }
    })

    let reqData = {}
    reqData["lg_id"] = this.props.leagueId
    reqData["claims"] = sortedClaims

    swapClaims(reqData).then((resp) => {
      if (resp.w_type == 2) {
        var cliamsCopy = []
        resp.claims.map((grpByAmount) => {
          grpByAmount.map((singleClaim) => {
            cliamsCopy.push(singleClaim)
          })
        })
        this.setState({ claims: cliamsCopy })
      } else {
        this.setState({ claims: resp.claims })
      }
    }).catch(({ message }) => {
      toastError(message)
    })
  };

  handleCancelClaimApi = (id) => {
    this.setState({ cancelClaim: true })
    cancelClaim({ id: id }).then((resp) => {
      this.setState({ successClaim: true })
      this.setState({ cancelClaim: false })
      if (resp.w_type == 2) {
        var cliamsCopy = []
        resp.claims.map((grpByAmount) => {
          grpByAmount.map((singleClaim) => {
            cliamsCopy.push(singleClaim)
          })
        })
        this.setState({ claims: cliamsCopy })
        this.setState({ cancelClaim: false })
      } else {
        this.setState({ claims: resp.claims })
        this.setState({ cancelClaim: false })
      }
    }).catch((err) => {
      this.setState({ cancelClaimError: true })
      this.setState({ cancelClaim: false })
    })
  }

  deleteTrade(item) {
    this.setState({ tradeLoading: true })
    deleteTrade({ tid: item.tid, st: 5 })
      .then((resp) => {
        this.handleTrade();
        this.setState({ success: true })
        this.setState({ tradeLoading: false })
      })
      .catch((err) => {
        this.setState({ error: err, success: false })
        this.setState({ tradeLoading: false })
      });
  }

  rejectTrade(item) {
    this.setState({ tradeLoading: true })
    updateTradeOffer({ tid: item.tid, accept: false })
      .then((resp) => {
        this.handleTrade()
        this.setState({ reject: true })
        this.setState({ tradeLoading: false })
      }
      )
      .catch((err) => {
        this.setState({ error: err, reject: false })
        this.setState({ tradeLoading: false })
      });
  }
  componentWillUnmount() {
    this.setState({ error: [], success: false, reject: false })
  }

  handleEditClaimApi = (reqData) => {
    editClaim(reqData).then((resp) => {
      console.log("Claim has been updated")
      if (resp.w_type == 2) {
        var cliamsCopy = []
        resp.claims.map((grpByAmount) => {
          grpByAmount.map((singleClaim) => {
            cliamsCopy.push(singleClaim)
          })
        })
        this.setState({ claims: cliamsCopy })
      } else {
        this.setState({ claims: resp.claims })
      }
      this.setState({ total_budget: resp.total_budget })
      this.setState({ modal: false })
    }).catch(({ message }) => {
      console.log(message)
    })
  }

  onSubmitHandler(itemId) {
    this.handleEditClaimApi({
      amount: parseFloat(this.state.editOffer),
      id: itemId,
      league_id: this.props.leagueId
    });
    this.setState({ editOffer: null })

  }

  onEditOffer(id) {

    setTimeout(() => {
      this.setState({
        editOfferId: id,
        modal: !this.state.modal,
        checkDisable: null
      })
      // console.log(this.state.claims.filter(item => item.id == this.state.editOfferId))
      this.setState({ editOffer: this.state.claims.filter(item => item.id == this.state.editOfferId).map(item => item.amount) })
    }, 0);
  }

  render() {
    const Colors = this.context;
    return (
      <>
        <h2 className='my-2 pl-3'>Pending Transactions</h2>
        <Header className='' name="Back" onBackHandler={this.props.toggle} disableButton={true} />

        <div className='mt-5 container col-7' md style={{ backgroundColor: Colors.bgLight }}>

          <Nav tabs>
            <NavItem className='row flex1 align-items-center justify-content-center' style={{ backgroundColor: Colors.bgLight }} >
              <NavLink
                className='col flex1'
                style={{ backgroundColor: Colors.bgLight, borderTop: 'none', borderLeft: 'none', borderRight: 'none', borderBottom: this.state.activeTab == 'waiver_claims' ? `1px solid ${Colors.colorPrimary}` : 'none', color: this.state.activeTab == 'waiver_claims' ? Colors.colorPrimary : '' }}
                onClick={() => { this.toggleNav('waiver_claims'); }}
              >
                Waiver Claims
              </NavLink>
              <NavLink
                className='col flex1'
                style={{ backgroundColor: Colors.bgLight, borderTop: 'none', borderLeft: 'none', borderRight: 'none', borderBottom: this.state.activeTab == 'trade_transactions' ? `1px solid ${Colors.colorPrimary}` : 'none', color: this.state.activeTab == 'trade_transactions' ? Colors.colorPrimary : '' }}
                onClick={() => { this.toggleNav('trade_transactions'); }}
              >
                Trade Transactions
              </NavLink>
            </NavItem>

          </Nav>
          {/*Loader  */}
          {this.state.loading ? <LoaderCenter /> :
            <>

              {this.state.activeTab == 'waiver_claims' &&
                <div>
                  {this.state.successClaim && <UncontrolledAlert color="info"> Claim has been canceled! </UncontrolledAlert>}
                  {this.state.cancelClaimError && <UncontrolledAlert color="danger"> Something Went Wrong! </UncontrolledAlert>}

                  <Container
                    onDrop={e => (this.setState({ claims: applyDrag(this.state.claims, e) }), this.handleSwap())}
                    orientation="vertical"
                  >
                    {this.state.claims.length ?
                      this.state.claims.map((item, index) => (

                        <Draggable key={item.id}>
                          <Card body className='bg-dark text-white'>
                            <h6><b>{item.player ? item.player.full_name : null}</b> Drops <b>{item.drop_player ? item.drop_player.full_name : null}</b></h6>
                            <h6>Offers <b>${item.amount}</b></h6>
                            <h6 style={{ color: Colors.colorSecondary }}>Processes <b>{this.state.processData.date}</b> approx <b>{this.state.processData.time}</b></h6>

                            <div className='d-flex flex-row justify-content-end'>
                              <Button
                                onClick={() => this.handleCancelClaimApi(item.id)}
                                disabled={this.state.cancelClaim ? true : false}
                                className='mt-2 mx-3'
                                style={{
                                  color: Colors.colorPrimary,
                                  backgroundColor: Colors.colorPrimaryTransparent,
                                  border: `1px solid ${Colors.colorPrimary}`,
                                }}
                              >
                                Cancel
                              </Button>
                              {this.state.waiverType = 2 &&
                                <>
                                  <Button
                                    onClick={() => this.onEditOffer(item.id)}
                                    disabled={this.state.cancelClaim ? true : false}
                                    className='mt-2'
                                    style={{
                                      color: Colors.colorPrimary,
                                      backgroundColor: Colors.colorPrimaryTransparent,
                                      border: `1px solid ${Colors.colorPrimary}`,
                                    }}
                                  >
                                    Edit Offer
                                  </Button>

                                  {this.state.modal &&
                                    <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} centered>
                                      <ModalHeader className='border-0 m-0 px-3 py-0 pt-3' style={{ backgroundColor: Colors.bgLight }} cssModule={{ 'modal-title': 'w-100' }}>
                                        <h4 className='bold text-truncate'>Edit Claim For {this.state.claims.filter(item => item.id == this.state.editOfferId).map(item => item.player.full_name)} <i className='fa fa-close pull-right' onClick={this.toggle} style={{ cursor: 'pointer' }}></i></h4>
                                      </ModalHeader>
                                      <ModalBody style={{ backgroundColor: Colors.bgLight }}>
                                        <div className='d-flex flex-row ml-5'>
                                          <label style={{ fontSize: 20 }} className='bold mt-1 mr-2'>$</label>
                                          <form class="was-validated w-25">
                                            <input
                                              class="form-control is-valid"
                                              id="validationTextarea"
                                              type='number'
                                              className='form-control'
                                              required
                                              placeholder={this.state.editOffer}
                                              value={this.state.editOffer}
                                              onChange={(e) => this.setState(e.target.value <= 100 && e.target.value >= 0 ? { editOffer: e.target.value } : null, this.setState({ checkDisable: this.state.editOffer }))}
                                            ></input>
                                            <div class="invalid-feedback">Please Enter Offer.</div>
                                          </form>
                                          <label style={{ fontSize: 16 }} className='ml-5 mt-2'>Budget Available ${this.state.total_budget}</label>
                                        </div>
                                        <div className='d-flex flex-row justify-content-end'>
                                          <Button
                                            onClick={this.state.editOffer == {} ? () => this.setState({ modal: !this.state.modal, editOffer: null }) : () => this.onSubmitHandler(this.state.editOfferId)}
                                            disabled={this.state.checkDisable !== null ? false : true}
                                            className='mt-4 mx-3'
                                            type='submit'
                                            style={{
                                              color: Colors.colorPrimary,
                                              backgroundColor: Colors.colorPrimaryTransparent,
                                              border: `1px solid ${Colors.colorPrimary}`,
                                            }}
                                          >Save Offer</Button>

                                          <Button
                                            onClick={() => this.setState({ modal: !this.state.modal, editOffer: null })}
                                            disabled={this.state.cancelClaim ? true : false}
                                            className='mt-4'
                                            style={{
                                              color: Colors.colorPrimary,
                                              backgroundColor: Colors.colorPrimaryTransparent,
                                              border: `1px solid ${Colors.colorPrimary}`,
                                            }}
                                          >  Cancel </Button>
                                        </div>
                                      </ModalBody>
                                    </Modal>

                                  }
                                </>
                              }
                            </div>
                          </Card>
                        </Draggable>
                      ))
                      : <p className='d-flex justify-content-center align-items-center mt-2'>0 Claims</p>}
                  </Container>
                </div>
              }
              {this.state.activeTab == 'trade_transactions' &&
                <div>
                  {this.state.success && <UncontrolledAlert color="info"> Trade has been canceled! </UncontrolledAlert>}
                  {this.state.reject && <UncontrolledAlert color="danger"> Trade has been rejected! </UncontrolledAlert>}
                  {this.state.trades ?
                    <>
                      <Row>
                        <Col>
                          <Container onDrop={this.props.onDrop}>
                            {this.state.trades.map((item) => (
                              <>
                                <Draggable key={item.id} >
                                  <Card body className='bg-dark'>
                                    <h6>Trade <b>{item.send ? item.send[0] : null}</b> to <b>{item.receiverName}</b></h6>
                                    <h6>Trade <b>{item.send ? item.send[1] : null}</b> to <b>{item.receiverName}</b></h6>
                                    <h6>Receive <b>{item.receive ? item.receive[0] : null}</b> from <b>{item.senderName}</b></h6>
                                    <h6>Receive <b>{item.receive ? item.receive[1] : null}</b> from <b>{item.senderName}</b></h6>
                                    {/* checking sender ID to cancel */}
                                    {item.senderId === this.props.auth.user.id ?
                                      <Button
                                        onClick={() => this.deleteTrade(item)}
                                        disabled={this.state.tradeLoading ? true : false}
                                        className='mx-5 btn-sm mt-2'
                                        style={{
                                          color: Colors.colorPrimary,
                                          backgroundColor: Colors.colorPrimaryTransparent,
                                          border: `1px solid ${Colors.colorPrimary}`,
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                      :
                                      <Button
                                        onClick={() => this.rejectTrade(item)}
                                        disabled={this.state.tradeLoading ? true : false}
                                        className='mx-5 btn-sm bg-dark'
                                        style={{
                                          color: 'red',
                                          border: `1px solid red`,
                                        }}
                                      >
                                        Reject
                                      </Button>}
                                  </Card>
                                </Draggable>
                              </>
                            ))}
                          </Container>
                        </Col>
                      </Row>
                    </>
                    : <p className='d-flex justify-content-center align-items-center mt-2'>0 Trades</p>}
                </div>
              }
            </>
          }
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({ leagueId: state.leagueJoin.leagueId, auth: state.auth })
export default connect(mapStateToProps, null)(PendingTransactions);
