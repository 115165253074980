import { toast } from "react-toastify"

const TOAST_CONFIG = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export const toastError = (message) => {
    toast.error(message, TOAST_CONFIG)
}

export const toastInfo = (message) => {
    toast.info(message, TOAST_CONFIG)
}

export const toastWarn = (message) => {
    toast.warn(message, TOAST_CONFIG)
}

export const toastSuccess = (message) => {
    toast.success(message, TOAST_CONFIG)
}

export const toastDefault = (message) => {
    toast(message, TOAST_CONFIG)
}