import React, { useState } from 'react';
import { Colors } from '../../helpers/colors';
import { chevronDownIcon, thunderBoltRightWhite, thunderBoltWhite } from '../../helpers/icons';
import { LoaderCenter } from '../../components/Loader';

export default function WinProbabilityModal(props) {
    const { loading, winProbData, status, toggle, slug } = props;
    const [data, setData] = useState();

    const getHeight = (stat, max) => {
        if (max !== 0) {
            var height = stat / max * 100;
            return height + "%";
        }
        return "0%"
    };

    const getColor = () => {
        if (status == "favorite") {
            return Colors.mlbPrimaryColor;
        } else if (status == "underdog") {
            return Colors.colorDanger;
        } else if (status == "toss up") {
            return Colors.colorWarning;
        };
    };

    const handleTooltip = (data) => {
        setData(data);
    };

    const getTooltipHeight = () => {
        if (data) {
            if (slug == "arenaHome") {
                return parseFloat(getHeight(data.opp, data.max)) + 300;
            } else if (slug == "counterProposal") {
                return parseFloat(getHeight(data.opp, data.max)) + 260;
            } else {
                return parseFloat(getHeight(data.opp, data.max)) + 260;
            }
        };
    };

    const sortedKeys = () => {
        const statKeysOrder = {"R": 1, "HR": 2, "RBI": 3, "SB": 4, "AVG": 5, "W": 6, "SV": 7, "K": 8, "ERA": 9, "WHIP": 10};
        return winProbData?.data?.sort((a, b) => statKeysOrder[a.key] - statKeysOrder[b.key]);
    };

    return (
        loading ? <LoaderCenter /> :
            <div>
                <div style={{ flex: 1 }}>
                    <div className='d-flex align-items-center justify-content-center'>
                        <p className='m-0'>{thunderBoltWhite()}</p>
                        <p className='m-0 alex mx-2 mt-1' style={{ fontSize: 8, fontWeight: 600, letterSpacing: .1, textTransform: "uppercase" }}>Powered by Bettle Analytics</p>
                        <p className='m-0'>{thunderBoltRightWhite()}</p>
                    </div>
                    <div className='mb-3 d-flex justify-content-center align-items-center' style={{ border: `2px solid ${getColor()}`, height: 40, marginTop: 10 }}>
                        <p className='m-0 alex' style={{ color: getColor(), fontSize: 14, fontWeight: 500 }}>{winProbData?.status == "toss up" ? "This match is projected as " : "You are projected as "}</p>
                        <p className='m-0 px-1 alex' style={{ color: getColor(), fontWeight: "bold", fontSize: 16, fontWeight: 600, letterSpacing: 2 }}> {winProbData?.status == "toss up" ? `A ${winProbData?.status.toUpperCase()}` : `THE ${winProbData?.status.toUpperCase()}`}</p>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                        <div className='d-flex flex1 flex-column' style={{ minWidth: "100%" }}>
                            <div className='d-flex' style={{ overflowX: "auto" }}>
                                {sortedKeys()?.map((el, index) => {
                                    return (
                                        <div className='' key={index} style={{ height: 100, width: 70 }}>
                                            <div className='d-flex align-items-end flex-lg-grow-1 flex1 h-100 px-1 pointer' onClick={() => (handleTooltip(el))} style={{ position: "relative" }}>
                                                {/* <div style={{ minHeight: getHeight(el.my, el.max), width: "40%" }}>
                                                    <p style={{ fontSize: 11, fontWeight: "bold", textAlign: "right", marginTop: -13 }}>{el.my}</p>
                                                </div> */}
                                                <div style={{ minHeight: getHeight(el.my, el.max), width: "30%", background: getColor() }}></div>
                                                <div style={{ minHeight: getHeight(el.opp, el.max), width: "30%", background: Colors.textColorSecondary }}></div>
                                                {/* <div style={{ minHeight: getHeight(el.opp, el.max), width: "40%" }}>
                                                    <p style={{ fontSize: 11, fontWeight: "bold", textAlign: "left", marginTop: -3 }}>{el.opp}</p>
                                                </div> */}
                                            </div>
                                            {el.key == data?.key &&
                                                <div className='px-2 text-center' style={{ position: "absolute", bottom: getTooltipHeight(), background: Colors.bgSecondHeaderSidebar, borderRadius: 10 }}>
                                                    <p className='m-0 bold' style={{ color: getColor(), fontSize: 11 }}>{data.my}</p>
                                                    <p className='m-0 bold' style={{ color: Colors.textColorSecondary, fontSize: 11 }}>{data.opp}</p>
                                                </div>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='d-flex align-items-center pt-2'>
                                {sortedKeys()?.map((el, index) => {
                                    return <div key={index} style={{ width: 70 }}>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <div style={{ width: "5%" }}></div>
                                            <div className='d-flex justify-content-center align-items-center pr-1'>
                                                <p style={{ fontSize: 13, fontWeight: "bold" }}>{el.key}</p>
                                            </div>
                                            <div style={{ width: "5%" }}></div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className='d-flex align-items-center'>
                            <div style={{
                                background: getColor(),
                                width: 12,
                                height: 12,
                                marginRight: 5
                            }}></div>
                            <p className='m-0 bold text-truncate'>{winProbData?.myTeam}</p>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className='mx-2' style={{
                                background: Colors.textColorSecondary,
                                width: 12,
                                height: 12,
                            }}></div>
                            <p className='m-0 bold text-truncate'>{winProbData?.oppTeam}</p>
                        </div>
                    </div>
                </div>
            </div >
    )
};