
console.log(process.env.REACT_APP_ENV)

export let BASE_URL
export let IMAGE_BASE_URL
export let NFL_S3
export let NODE_SERVER
export let CHAT_SERVER
export let IMAGES_S3
export let GIPHY_KEY
export let PUSHER_APP_KEY
export let PUSHER_CLUSTER
export let CHAT_MEDIA_BUCKET
export let YAHOO_REDIRECT_URI_NEW_USER
export let YAHOO_REDIRECT_URI
export let SPORT_DATA = "SPORT_RADAR"
export let WORKER_URL
export let BASE_FE_URL
export let GATEWAY_URL
export let COGNTIO_URL

if(process.env.REACT_APP_ENV == "development"){
    console.log("inside dev")
    //  BASE_URL = "https://ag63j1xhkf.execute-api.us-east-2.amazonaws.com/staging"
     BASE_URL = "http://127.0.0.1:8000"
     GATEWAY_URL = "http://127.0.0.1:8000"
     BASE_FE_URL = "http://localhost:3000"
     WORKER_URL = "http://127.0.0.1:8001"
     IMAGE_BASE_URL = "http://127.0.0.1:8000/images/"
     NFL_S3 = "https://s3.amazonaws.com/dreamteam-nfl-players/"
     NODE_SERVER = "http://localhost:3000"
     CHAT_SERVER = "http://localhost:8004"
     IMAGES_S3 = "https://s3.us-east-2.amazonaws.com/dreamteam-images/"
     GIPHY_KEY = "pHT91gZ8mFclb0j0aDudtLhvcMBsxpLD"
     PUSHER_APP_KEY = "0cc1c526ea2b110d7162"
     PUSHER_CLUSTER = "us2"
     YAHOO_REDIRECT_URI_NEW_USER = "https://localhost:3000/new-user-onboarding/"
     YAHOO_REDIRECT_URI = "https://localhost:3000/import/y/"
     CHAT_MEDIA_BUCKET = "https://bettle-chat-media.s3.us-east-2.amazonaws.com/"
     COGNTIO_URL = "https://bettle-stage.auth.us-east-2.amazoncognito.com/login?response_type=token&client_id=1hsskla898cj4qoo3bikqc1n55&redirect_uri=http://localhost:3000/socialAuth"

}else if (process.env.REACT_APP_ENV == "staging"){
    console.log("inside stage")
     BASE_URL = "https://stage-api.bettle.co"
     GATEWAY_URL = "https://stage-api.bettle.co"
     BASE_FE_URL = "https://staging.bettle.co"
     IMAGE_BASE_URL = "http://staging.bettle.co/images/"
     NFL_S3 = "https://s3.amazonaws.com/dreamteam-nfl-players/"
     NODE_SERVER = "http://staging.node.bettle.co"
     CHAT_SERVER = "http://staging.chat.bettle.co"
     IMAGES_S3 = "https://s3.us-east-2.amazonaws.com/dreamteam-images/"
     GIPHY_KEY = "pHT91gZ8mFclb0j0aDudtLhvcMBsxpLD"
     PUSHER_APP_KEY = "0cc1c526ea2b110d7162"
     PUSHER_CLUSTER = "us2"
     YAHOO_REDIRECT_URI_NEW_USER = "https://staging.bettle.co/new-user-onboarding/"
     YAHOO_REDIRECT_URI = "https://staging.bettle.co/home/"
     CHAT_MEDIA_BUCKET = "https://bettle-chat-media.s3.us-east-2.amazonaws.com/";
     COGNTIO_URL = "https://bettle-stage.auth.us-east-2.amazoncognito.com/login?response_type=token&client_id=1hsskla898cj4qoo3bikqc1n55&redirect_uri=https://staging.bettle.co/socialAuth"
}
else if (process.env.REACT_APP_ENV == "production"){
    console.log("inside prod")
    BASE_URL = "https://api.bettle.co"
    GATEWAY_URL = "https://api.bettle.co"
    BASE_FE_URL = "https://bettle.co"
    // BASE_URL = "https://bettle.co"
    IMAGE_BASE_URL = "https://bettle.co/images/";
     NFL_S3 = "https://s3.amazonaws.com/dreamteam-nfl-players/";
     CHAT_MEDIA_BUCKET = "https://bettle-chat-media.s3.us-east-2.amazonaws.com/";
     NODE_SERVER = "https://node.bettle.co"
     CHAT_SERVER = "https://chat.bettle.co"
     IMAGES_S3 = "https://s3.us-east-2.amazonaws.com/dreamteam-images/";
     PUSHER_APP_KEY = "0cc1c526ea2b110d7162"
     PUSHER_CLUSTER = "us2"
     GIPHY_KEY = "pHT91gZ8mFclb0j0aDudtLhvcMBsxpLD"
     YAHOO_REDIRECT_URI_NEW_USER = "https://bettle.co/new-user-onboarding/"
     YAHOO_REDIRECT_URI = "https://bettle.co/home/"
     COGNTIO_URL = "https://bettlev2.auth.us-east-2.amazoncognito.com/login?response_type=token&client_id=1uiuljjj05jb087i9f39de880l&redirect_uri=https://bettle.co/socialAuth"
}

console.log(PUSHER_APP_KEY)

