import React, { Component } from "react";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Row, Card, Button,  ListGroup, ListGroupItem, UncontrolledAlert, Input } from "reactstrap";
import { loginESPN, getRoster, saveRoster, autoLogin, triggerESPNImport, sendEspnCode, loginIntoEspn } from "../../actions/Import/api"
import profile from '../../assets/images/profile.png';
import { NFL_S3 } from "../../config/app";
import { setInForage, TOAST } from "../../helpers/common-methods";
import espn from '../../assets/images/nfl/espn.svg';
import styled from "styled-components"
import ImportProgress from "../../components/LeagueImport/ImportProgress";
import football from "../../assets/images/football.png"
import baseball from "../../assets/images/baseball.png"
import { LoaderCenter } from "../../components/Loader";
import ErrorText from "../../components/ErrorText";
import { InviteMembers } from "../../components/LeagueImport/InviteMembers";
// import { Colors } from "../../helpers/colors";
import { STYLES } from "../../helpers/stylesHelper";
import store from "../../store";
import { ColorContext } from '../../context/ColorProvider';

const Li = styled.li`
    list-style-type: decimal;
    margin: .5rem 0;
`

class LineupImportIndex extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            container: 0,
            email: "",
            password: "",
            resp: [],
            teamId: "",
            teamName: "",
            players: [],
            errors: [],
            sport: this.props.sport || null,
            importSuccess: false,
            response: {},
            selectedLeague: null,
            showToast: false,
            step: "import",
            selectedLeagueId: null,
            recoveryData: null,
            code: null,
            apiProcessing: false
        }
    }
    static contextType = ColorContext;

    componentDidMount(){
        // this.autoLogin()
        if(this.props.sport){
            this.handleSport(this.props.sport)
        }
    }

    componentDidUpdate(prevProps, prevState){
        // alert(this.props.sport)
        // if(!prevState.sport && this.state.sport){
        //     // alert("O MY GOD, WOWWW!", this.state.sport)
        //     this.handleSport(this.state.sport)
        // }
    }

    triggerImport(league){
        this.setState({ selectedLeagueId: league.id})
        if(league.league_type == "private"){
            this.setState({ commishInvite: true })
        }else{
            // Call Import API here
            this.props.triggerESPNImport({ lg_key: league.id, name: league.name, sport: this.state.sport }).then((resp) => {
                this.setState({container: 6})
           }).catch(async (err) => {
            if(err == "IMPORTED"){
                // NAVIGATE TO CLAIM FLOW
                await setInForage("claimTeam", {
                    lgId: league.id,
                    platform: "fantrax",
                    lgName: decodeURIComponent(league.name),
                    sbId: null,
                    leagueId: league.leagueId,
                    grudgeId: null
                })
                this.props.history.push("/newClaimTeam?fromImport=true")
            }else{
                TOAST.error(err)
            }
           })
        }
        this.setState({selectedLeague: league})
    }

    updateContainer(index){
        this.setState({ container: index })
    }

    handleInputChange(e) {
        this.setState({
          [e.target.name]: e.target.value
        });
      }

      handleSubmit = () => {
          this.setState({errors: {}})
          if(this.state.email == null || this.state.email == ""){
            this.setState({errors: {
                email: "Email is required"
            }})
        }else{
            let data = {}
            data["email"] = this.state.email
          //   data["password"] = this.state.password
            this.setState({ apiProcessing: true })
            store.dispatch(sendEspnCode(data)).then((resp) => {
                this.setState({ apiProcessing: false, recoveryData: resp, container: 7})
            }).catch((err) => {
              this.setState({ errors: {emailNotFound: 'Looks like an account with this email/username does not exist on ESPN.'}, apiProcessing: false })
            })
        }
      }

      autoLogin(sport = this.state.sport){
        this.setState({ loading: true })
        this.props.autoLogin({ platform: "espn", sport: sport }).then(( resp ) => {
            this.setState({ loading: false, resp: resp, container: 3})
        }).catch((err) => {
            this.setState({ loading: false, container: 2})
        })
      }

      handleSport(sport){
          this.setState({ sport: sport, container: 1})
          this.autoLogin(sport)
      }

    updateStep = (step) => {
        this.setState({step: step})
    }

    fetchESPNLeagues = () => {
        this.setState({ apiProcessing: true })
        if(this.state.code == null || this.state.code == ""){
            this.setState({errors: {
                code: "Code is required"
            }})
        }else{
            store.dispatch(loginIntoEspn({
                code: this.state.code,
                sessionId: this.state.recoveryData["sessionId"]
            })).then(() => {
                window.location.href = "/new-user-onboarding/?platform=espn&sport="+this.state.sport
            }).catch(() => {
                this.setState({
                    apiProcessing: false,
                    errors: {
                        exception: "Code is Invalid, please try again"
                    }
                })
            })
        }
    }

    render() {
        const Colors = this.context;
        return (
            <div className="d-flex flex-column flex1">
                <div className="p-md-2 p-2 d-flex flex-column flex1">
                    {this.state.loading ? (<LoaderCenter />) : (
                        this.state.error ? (<ErrorText msg={this.state.error} />) : (
                            <div>

                                {/* <ul className="progressbar mb-4">
                                    <li className={this.state.step == "import" ? "active" : ""}>Import League</li>
                                    <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center"}} >
                                        <div style={{borderTop: "1px solid #7a7a7a", width: "100%"}} ></div>
                                    </div>
                                    <li className={this.state.step == "invite" ? "active" : ""} >Invite League</li>
                                    <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center"}} >
                                        <div style={{borderTop: "1px solid #7a7a7a", width: "100%"}} ></div>
                                    </div>
                                    <li className={this.state.step == "download" ? "active" : ""} >Download App</li>
                                </ul> */}

                                { this.state.errors.exception != undefined && <UncontrolledAlert color="danger">
                                    {this.state.errors.exception}
                                </UncontrolledAlert>}
                                    {(() => {
                                    switch (this.state.container) {
                                        case 0:
                                            return (
                                                <div>
                                                    {/* <h2 className="greenText text-center bold" >Import League</h2> */}
                                                    <h2 className="center mb-4 mt-3 bold" >Select Sport</h2>
                                                    <Col md="6" className="col-centered" >
                                                        <Card>
                                                            <Row>
                                                                <Col className="center p-4 pointer" onClick={() => this.handleSport("NFL")}>
                                                                    <img src={football} width="64" height="64" />
                                                                    <h3 style={{marginTop: "14px", fontWeight: "bold"}} >NFL</h3>
                                                                </Col>

                                                                <Col className="center pointer p-4" onClick={() => this.handleSport("MLB")} >
                                                                    <img src={baseball} width="64" height="64" />
                                                                    <h3 style={{marginTop: "14px", fontWeight: "bold"}}>MLB</h3>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                </div>
                                            )
                                        case 1:
                                            return (
                                                <div>
                                                    <h2 style={{ marginBottom: "50px" }} className="center" >Import your season-long team </h2>
                                                    <Col sm="6" className="col-centered" >
                                                        <h5 style={{ marginBottom: "20px", textAlign: "left" }}  >What platform do you use ?</h5>
                                                        <Card body>
                                                            <Row style={{ padding: "10px" }} >
                                                                <Col onClick={() => this.autoLogin() }  className="center pointer" style={{ padding: "5px", border: "1px solid lightgrey", margin: "5px", background: "rgb(250, 250, 250)" }}  >
                                                                    ESPN
                                                                </Col>

                                                                <Col onClick={() => { this.props.history.push("/import/y?sport="+this.state.sport) }} className="center pointer" style={{ padding: "5px", border: "1px solid lightgrey", margin: "5px", background: "rgb(250, 250, 250)" }} >
                                                                    Yahoo
                                                                </Col>

                                                                <Col onClick={() => { this.props.history.push("/import/cbs") }}  className="center pointer" style={{ padding: "5px", border: "1px solid lightgrey", margin: "5px", background: "rgb(250, 250, 250)" }} >
                                                                    CBS
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                </div>
                                            )
                                        case 2:
                                            return (
                                                <>
                                                {/* <Col className="text-left d-none d-md-block" >
                                                    {this.props?.for != "newUser" && <h3 className="bold mb-3 greenText" >Import ESPN League</h3>}
                                                    <p  >Importing an ESPN fantasy league via our website requires the use of the <b>Bettle Chrome Extension</b>.</p>
                                                    <div className="mt-4 mb-2" >
                                                        <Button style={STYLES.BUTTON_PRIMARY_TRANSPARENT} >
                                                        <a target="_blank" className="greenText" href="https://chrome.google.com/webstore/detail/bettle-espn-league-import/fdaboplfklloipnhkjlmpefamkhkihih?hl=en" >Install Chrome Extension</a></Button>
                                                    </div>

                                                    <div className="py-3" >
                                                        <div>
                                                            <p className="bold mb-3" >Steps to import your ESPN league with Bettle Chrome Extension:</p>
                                                                <Li  >Install and activate the <a target="_blank" className="greenText" href="https://chrome.google.com/webstore/detail/bettle-espn-league-import/fdaboplfklloipnhkjlmpefamkhkihih?hl=en" ><u>Bettle Chrome Extension</u></a>. After installing the extension you should see the Bettle Chrome Extension active to the right of the Chrome URL bar.</Li>
                                                                <Li >Login to ESPN via the Bettle Chrome Extension.</Li>
                                                                <Li >Click ‘Sync ESPN Leagues!’.</Li>
                                                                <Li >Click prompt to view leagues or refresh page.</Li>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className="text-left d-block d-md-none" >
                                                    <div className="py-3" >
                                                        <p className="bold mb-2" >Fire up your Computer</p>
                                                        <p>Due to reasons out of our control, ESPN import is only available on desktop devices right now. Hop on your computer and login at <span className="greenText" >Bettle.co</span> to begin the import process</p>
                                                        <div className="text-center mt-4" >
                                                            <Button onClick={() => {
                                                                window.location.reload()
                                                            }} style={STYLES.BUTTON_PRIMARY_TRANSPARENT} >Take me Back</Button>
                                                        </div>
                                                    </div>
                                                </Col> */}
                                                <Col className={"text-center py-3"} >
                                                    <Input placeholder="ESPN Email or Username" onChange={(e) => {this.setState({email: e.target.value})}} />
                                                    {this.state.errors?.email && <ErrorText msg={this.state.errors.email} />}
                                                    {this.state.errors?.emailNotFound && <ErrorText msg={this.state.errors.emailNotFound} />}
                                                    <Button disabled={this.state.apiProcessing} onClick={this.handleSubmit} className="mt-4" style={STYLES.BUTTON_PRIMARY_TRANSPARENT} >Submit</Button>
                                                </Col>
                                                </>
                                            )
                                        case 3: 
                                            return (
                                                <div className="d-flex flex-column flex1"  >
                                                    {/* <p className="float-right" ><b>ESPN Team: </b>{this.state.resp.team}</p> */}
                                                    {this.state.resp.length > 0 ? (
                                                        <div className="d-flex flex-column flex1">
                                                            { this.props?.for != 'newUser' && <h3 style={{ marginBottom: "20px", fontWeight: "bold" }} className="greenText" >Which league you would like to import:</h3> }
                                                            {/* <ListGroup>
                                                                {this.state.resp.map((league) => {
                                                                    return (
                                                                        <ListGroupItem style={{padding: "10px", color: "white"}} tag="button" onClick={() => this.triggerImport(league)} action>
                                                                            <div className="d-flex align-items-center" >
                                                                                <img className="importImg" src={espn} />
                                                                                <div>
                                                                                    <p className="bold m-0" >{league.name}</p>
                                                                                    <p className="m-0" >{league.team_name}</p>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </ListGroupItem>
                                                                    )
                                                                })}
                                                            </ListGroup> */}

                                                            <div className="d-flex flex-column">
                                                                {this.state.resp.map((league) => {
                                                                    return (
                                                                        <div className="text-left pointer my-1" style={{padding: "10px", borderRadius:10, color: this.state.selectedLeagueId == league.id ? "black": "white", backgroundColor:this.state.selectedLeagueId == league.id ? Colors.colorPrimary: '' }} tag="button" onClick={() => this.triggerImport(league)} action>
                                                                            <div className="d-flex align-items-center" >
                                                                                <img className="importImg" src={espn} />
                                                                                <div>
                                                                                    <p className="bold m-0" >{league.name}</p>
                                                                                    <p className="m-0" >{league.team_name}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="d-flex flex-column justify-content-center align-items-center" >
                                                            <h4 className="text-center" style={{lineHeight: "30px"}} >We couldn't find any league in your ESPN account, please join or create league and sync again.</h4>
                                                            <div>
                                                                <a className="d-block default_a mt-5" href="https://www.espn.in/ffl/" target="_blank" >Go to ESPN</a>
                                                            </div>
                                                        </div>
                                                        
                                                    )}
                                                    {/* <Button onClick={() => this.props.history.push("/home")} className="btn-pill default-btn mt-4" >Back</Button> */}
                                                </div>
                                            )
                                        case 4: 
                                            return (
                                                <div >
                                                    <h5 className="center mb-4 bold" >Would you like to import the team below?</h5>
                                                    <Row className="p-2" >
                                                        <Col sm="12" md="6" className="col-centered card" >
                                                        <Row>
                                                            {this.state.players.map((player) => {
                                                                return <Col xs="12"  className="d-flex flex-row align-items-center p-2" style={{borderBottom: "1px solid silver"}} >
                                                                        <div style={{width: "10%", verticalAlign: 'middle'}} className="mr-4" ><img style={{borderRadius: "50%"}} className="img-fluid rounded-circle" src={player.image !== null ? NFL_S3 + player.image : profile} /></div>
                                                                        <div style={{flex: 1}} >{player.player_name}</div>
                                                                        <div className="text-right" >{player.team} | {player.pos}</div>
                                                                </Col>
                                                                })}
                                                        </Row>
                                                        </Col>
                                                    </Row>
                                                    <div className="mt-4 center">
                                                        <Button className="btn-pill dark-btn" onClick={() => { this.setState({ container: 3 }) }  } style={{ marginRight: "50px", width: "100px" }} >Back</Button>
                                                        <Button className="btn-pill green-btn" onClick={() => this.saveRoster()}  style={{ width: "100px" }}  >Confirm</Button>
                                                    </div> 
                                                </div>
                                            )
                                        case 5:
                                            return (
                                               <InviteMembers updateStep={this.updateStep} {...this.props} lg={this.state.selectedLeague} platform={"espn"} />
                                            )
                                        case 6:
                                            return (
                                               <ImportProgress
                                                    handleSuccess={this.props?.handleSuccess}
                                                    showInvite={() => this.setState({container: 5, step: "invite"})}
                                                    platform={"espn"}
                                                    lg={this.state.selectedLeague}
                                                    sport={this.state.sport}
                                               />
                                            )
                                        case 7:
                                            return (
                                                <Col className={"text-center py-3"} >
                                                    <p>We have sent a code to your ESPN email</p>
                                                    <Input placeholder="Enter your ESPN Code" onChange={(e) => {this.setState({code: e.target.value})}} />
                                                    {this.state.errors?.code && <ErrorText msg={this.state.errors.code} />}
                                                    <Button disabled={this.state.apiProcessing} onClick={this.fetchESPNLeagues} className="mt-4" style={STYLES.BUTTON_PRIMARY_TRANSPARENT} >Fetch ESPN Leagues</Button>
                                                </Col>
                                            )
                                        default :
                                            return null
                                    }
                                    })()}
                            </div>
                        ))}
                </div>
               
                {/* <ToastContainer /> */}
            </div>
        )
    }
}

const mapDispatchToProps = {
    loginESPN,
    getRoster,
    saveRoster,
    autoLogin,
    triggerESPNImport
};
export default connect(null, mapDispatchToProps)(withRouter(LineupImportIndex));