import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import { mlbGameLogs } from '../../../../apis/SeasonLong/PlayerAPI/PlayerAPI';
import { LoaderCenter } from '../../../../components/Loader';
import { parseMlbHeaders } from '../../../../helpers/common-methods';
import { toastError } from '../../../../helpers/toasts';
import '../../../../modals/SeasonLong/PlayerCard/colors.css'


const GameLogsMLB = (props) => {

    const [logData, setLogData] = React.useState();
    const [loader, setLoader] = React.useState(true);
    const [headers, setHeaders] = useState([])
    useEffect(() => {
        mlbGameLogs("?playerId="+props.playerId+"&leagueId="+props.leagueId)
        .then(response => {
            setLogData(response.data)
            setHeaders(parseMlbHeaders(props.position, response.data.headers))
            setLoader(false);
        })
        .catch(({message}) => {
            toastError(message)
        }) 
    //   return () => {}
    }, [])

    return (
        <>
            {   
                loader ? 
                    <LoaderCenter />
                    :
                    // <div style={{overflow:'scroll'}}>
                    // {/* <div> */}
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>FPTS</th>
                                    {headers.map(header => {
                                        return (
                                            <th>{logData.statAbbr[header]}</th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                { logData.logs.map(log => {
                                    return(
                                        <tr>
                                            <td>{log.meta.date}</td>
                                            <td>{log.meta.points}</td>
                                            { headers.map(header => {
                                                return (
                                                    <td>{log.stats[header]}</td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    // </div>
                    }
        </>
    )
}

export default GameLogsMLB;