import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { dropPlayer } from '../../../../apis/SeasonLong/PlayerAPI/PlayerAPI';
import { toastError, toastSuccess } from '../../../../helpers/toasts';
import { ColorContext } from '../../../../context/ColorProvider'

class ModalPlayerDrop extends Component {
    constructor(props) {
        super(props);
        this.dropPlayer = this.dropPlayer.bind(this)
    }
    static contextType = ColorContext;

    dropPlayer() {
        dropPlayer({league_id: this.props.leagueId, player_id: this.props.playerId, is_commissioner: false})
            .then(response => {

                let data = { 
                        message_content: 'Player Dropped',
                        attachments: [{
                            template: 'PLAYER_DROPPED',
                            data: {
                                user: response.data.user,
                                playerName: response.data.playerName
                            }
                        }]
                    }
                this.props.toggle()
                if(response.data != undefined) {
                    // this.props.actionSuccess()
                    if (this.props.sendMessage != undefined) {
                        this.props.sendMessage(data)   
                    }

                    toastSuccess('Player: '+response.data.playerName+' dropped successfully')
                }
            })
            .catch(({message}) => {
                toastError(message)
                this.props.toggle()
            })
    }

    render() { 
        const Colors = this.context;
        return ( 
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
                <ModalBody className='text-center border-0'>
                    <h3>Are you sure you want to drop this player?</h3>
                    <p>This action cannot be undone</p>
                </ModalBody>
                <ModalFooter className='border-0'>
                    <Button className='my-1' style={{color: Colors.colorPrimary,backgroundColor: Colors.colorPrimaryTransparent,border: `1px solid ${Colors.colorPrimary}`}} onClick={this.props.toggle}>Cancel</Button>
                    <Button className='my-1' style={{color: Colors.colorDanger,backgroundColor: Colors.colorDangerTransparent,border: `1px solid ${Colors.colorDanger}`}} onClick={this.dropPlayer}>Drop</Button>
                </ModalFooter>
            </Modal>
         );
    }
}
 
export default ModalPlayerDrop;