import React, { useEffect, useState } from 'react'
// import { Colors } from '../../../../helpers/colors'
import Divider from '../../../subcomponents/Divider'
import { motion } from 'framer-motion/dist/framer-motion'
import { collapseHorizontal, collapseLineupVertical, collapseVertical, matchupCardBgColor } from '../../../../pages/NewUserOnboarding/animationValues'
import { closeIcon, matchupCompleteIcon, rematchIcon, scoreboardScheduleIcon } from '../../../../helpers/icons'
import { matchup, nflMatchup } from '../../../../apis/Matchmaker/ArenaScoreboard/ArenaScoreboard'
import Skeleton from 'react-loading-skeleton'
import { errorMessage, getStore, MATCH_MAKER_BATTING_POS, MATCH_MAKER_PITCHING_POS, mergeAndSum, NBA_POS, NFL_UNIQUE_POS, parseTotalStat } from '../../../../helpers/common-methods'
import { useColors } from '../../../../helpers/CustomHooks/useColors';
import { eachCatFormula } from '../../../../helpers/MLB/Points'
import { matchupPlayers } from '../../../../apis/Arena/MatchMakerApis'
import { playerTotalStats } from '../../../../helpers/Arena/ArenaHelper'
import ReactSelect from 'react-select'
import { STYLES } from '../../../../helpers/stylesHelper'
import MLBEachCatRow from '../../../SeasonLong/Scoreboard/MLB/MLBEachCatRow'
import MatchScheduleModal from '../../../../modals/Arena/MatchScheduleModal'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'

const SingleMatchupNba = (props) => {
  const Colors = useColors();
  const { match, rules, activeTab, sport, rematchStateCallback } = props

  const [collapsed, setCollapsed] = useState(true)
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [team1Total, setTeam1Total] = useState({})
  const [team2Total, setTeam2Total] = useState({})
  const [teamTotals, setTeamTotals] = useState({})
  const [selectedCats, setSelectedCats] = React.useState({})
  const [periodIndex, setPeriodIndex] = React.useState(0)
  const [statType, setStatType] = React.useState("daily")
  const [view, setView] = React.useState("summary")
  const [players1Data, setPlayers1Data] = React.useState({
    todayTotal: {},
    players: [],
  });
  const [players2Data, setPlayers2Data] = React.useState({
    todayTotal: {},
    players: [],
  });
  const [teamPoints, setTeamPoints] = useState(undefined)
  const [editLineupModal, setEditLineupModal] = useState(false)
  const [dailyPeriod, setDailyPeriod] = useState({ daily_period: 'total', date: "Today" })
  const [periodLoading, setPeriodLoading] = useState(false)
  const [currentPos, setCurrentPos] = useState("batters");
  const [viewMatchSchedule, setViewMatchSchedule] = useState(false);
  const rematchData = useSelector((state) => state.arenaReducer.rematchData);

  useEffect(() => {
    // let apiCallNeeded = false

    // if (!teamPoints) {
    //   setTeamPoints(generateBetTotals())
    // }
    // // call API only if activeTab is "matchups" and data is empty
    // if (view == "summary" && Object.keys(data).length == 0) {
    //   callApi()
    // }
    // if (view == 'team1' || view == 'team2') {
    //   apiCallNeeded = view == 'team1' ? players1Data.length == 0 : players2Data.length == 0
    // }

    // if (apiCallNeeded) {
    //   callPlayersApi()
    // }
    if ((view == "team1" && players1Data?.players?.length == 0) || (view == "team2" && players2Data?.players?.length == 0)) {
      callPlayersApi();
    }
  }, [view])

  useEffect(() => {
    if (dailyPeriod != 0) {
      callApi("total", "daily");
      // setPlayers1Data([])
      // setPlayers2Data([])
    }
  }, [])

  // THIS IS REALLY IMPORTANT FUNCTION
  const generateBetTotals = (dailyData = null) => {
    if (dailyData != null) {
      let team1Merged = {}
      let team2Merged = {}

      if (match.from.teamId == dailyData.teams[1].teamId) {
        team1Merged = mergeAndSum({}, dailyData.teams[1].currentStats, match.sport)
        team2Merged = mergeAndSum({}, dailyData.teams[0].currentStats, match.sport)
      }
      else {
        team1Merged = mergeAndSum({}, dailyData.teams[0].currentStats, match.sport)
        team2Merged = mergeAndSum({}, dailyData.teams[1].currentStats, match.sport)
      }

      return eachCatFormula(team1Merged, team2Merged, { mapping: dailyData.meta.rules });
    }
    else if (match.status == "ACCEPTED" || match.status == "COMPLETED") {
      // SUM STATS
      var team1Merged = mergeAndSum(match.from.totalStats, match.from.currentStats, match.sport)
      var team2Merged = mergeAndSum(match.to.totalStats, match.to.currentStats, match.sport)
      // GET TOTAL POINTS
      return eachCatFormula(team1Merged, team2Merged, { mapping: rules })
    }
  }

  const callApi = (st = statType, dailyPeriod = 'total') => {
    setPeriodLoading(true)
    var qs = ""
    if (st != "total") {
      var period = dailyPeriod
      qs = "?matchId=" + match.matchId + "&type=" + st + "&dailyPeriod=" + period
    } else {
      qs = "?matchId=" + match.matchId + "&type=" + st
    }
    matchup(qs).then((resp) => {
      setData(resp)

      if (resp.meta.sport == "MLB") {
        var obj = {}
        Object.values(resp.meta.rules).map((rule) => {
          if (obj[rule.type]) {
            obj[rule.type].push(rule.stat)
          } else {
            obj[rule.type] = []
            obj[rule.type].push(rule.stat)
          }
        })
        setSelectedCats(obj)
      } else if (resp.meta.sport == "NBA") {
        var statsArray = []
        Object.values(resp.meta.rules).map((rule) => {
          statsArray.push(rule.stat)
        })
        setSelectedCats(statsArray)
      }
      var team1Merged = getTeamTotalStats(resp, 0, st)
      var team2Merged = getTeamTotalStats(resp, 1, st)
      setTeam1Total(team1Merged)
      setTeam2Total(team2Merged)
      setTeamTotals(eachCatFormula(team1Merged, team2Merged, { mapping: resp.meta.rules }))

      if (st != "total") {
        setTeamPoints(generateBetTotals(resp))
      }
      else {
        setTeamPoints(generateBetTotals())
      }

      setPeriodLoading(false)
      setLoading(false)
    }).catch((err) => {
      console.log("err", err);
      errorMessage(err)
    })
  }

  const callPlayersApi = (st = statType, dp = null) => {
    setLoading(true)
    var params = {}
    if (st != "total") {
      let period = data?.meta?.totalPeriods[0].daily_period;
      if (dp !== null) {
        period = dp;
      };
      params = { matchId: match.matchId, type: st, dailyPeriod: period }
    } else {
      params = { matchId: match.matchId, type: st }
    }
    if (view == "team1") {
      params = { ...params, teamId: data.teams[0].teamId }
    } else {
      params = { ...params, teamId: data.teams[1].teamId }
    }

    matchupPlayers(params).then((resp) => {
      if (view == "team1") {
        setPlayers1Data(resp)
      } else {
        setPlayers2Data(resp)
      }
      setLoading(false)
    }).catch((err) => {
      errorMessage(err)
    })
  }

  const getTeamTotalStats = (resp, index, st) => {
    if (st == "total") {
      return mergeAndSum(resp.teams[index].totalStats, resp.teams[index].currentStats, resp.meta.sport)
    } else {
      return resp.teams[index].currentStats
    }
  }

  const handleView = (targetView) => {
    if (view != targetView) {
      setView(targetView)
    }
  }

  //   useEffect(() => {
  //     if(!collapsed && data == null) {
  //       callApi()
  //     }
  //   }, [collapsed])

  //   const callApi = () => {
  //     let qs = "?matchId=" + match.matchId
  //     nflMatchup(qs)
  //       .then((resp) => {
  //         setData(resp)
  //         setLoading(false)
  //       })
  //       .catch((e) => {
  //         setLoading(false)
  //       })
  //   }

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  const toggleEditLineupModal = () => {
    setEditLineupModal(!editLineupModal)
  }

  const handleDailyPeriod = (daily_period) => {
    // console.log(daily_period.daily_period)
    setDailyPeriod(daily_period)
    setStatType('daily')
    callPlayersApi("daily", daily_period.daily_period);
    // console.log("daily_period", daily_period);
  }

  // console.log({ statType, dailyPeriod });

  const handleTotalStats = () => {
    setDailyPeriod({ daily_period: 'total', date: "Today" });
    setStatType("total");
    callPlayersApi("total");
  };

  const rematchHandler = () => {
    const prepData = {
      proposalDrawer: true,
      myTeamId: match?.from.teamId,
      oppTeamId: match?.to.teamId,
      sport: match.sport,
      timeFrame: match.timeFrame,
      oppTeamImg: match?.to.teamImage,
      oppTeamRecord: match?.to.record,
      oppTeamName: match?.to.teamName,
      oppTeamLgId: match?.to.leagueId,
      matchId: match?.matchId,
    };

    getStore().dispatch({ type: "SET_REMATCH_DATA", payload: prepData });
    rematchStateCallback();
  };

  const parseScoreboard = () => {
    const { statAbbr } = data.meta

    return (
      <>
        <ReactSelect getOptionValue={option => option.daily_period} getOptionLabel={option => option.date} options={[{ daily_period: 'total', date: "Total" }, ...data.meta.totalPeriods]} value={dailyPeriod} onChange={handleDailyPeriod} className=" mx-1 text-white" styles={STYLES.REACT_SELECT_DARK} closeMenuOnSelect={true} isClearable={false} />
        {
          selectedCats.map((cat) => {
            return (
              <div className='w-100'>
                <div className='d-flex'>
                  {/* Team 1*/}
                  <div className='flex1 my-1'>
                    <div className='d-flex align-items-center justify-content-center'>
                      <h3 className='m-0 bold text-center font-roboto-condensed' style={{ fontSize: 24 }}>{parseTotalStat(cat, team1Total[cat])}</h3>
                    </div>
                  </div>
                  {/* !team 1*/}

                  {/* POS */}
                  <div className='align-self-center text-center my-1' style={{ width: 70 }}>
                    <h3 className='m-0 bold font-roboto-condensed' style={{ color: Colors.arenaLineupPlayerRow, fontSize: 24 }}>{statAbbr[cat]}</h3>
                  </div>
                  {/* !POS */}

                  {/* Team 2*/}
                  <div className='flex1 my-1'>
                    <div className='d-flex align-items-center justify-content-center'>
                      <h3 className='m-0 bold text-center font-roboto-condensed' style={{ fontSize: 24 }}>{parseTotalStat(cat, team2Total[cat])}</h3>
                    </div>
                  </div>
                  {/* !Team 2*/}
                </div>
              </div>
            )
          })
        }
      </>
    )
  }

  const parseLineup = (activeView = view) => {
    let activeTeamPlayers = {}
    if (activeView == "team1") {
      activeTeamPlayers = players1Data
    } else {
      activeTeamPlayers = players2Data
    }

    return (
      <div className='w-100'>

        {/* Header */}
        <div className='d-flex'>
          {selectedCats.map((cat) => {
            return (
              <div className="flex1 text-center bold" >{data.meta.statAbbr[cat]}</div>
            )
          })}
        </div>

        {/* Players List */}
        <div>
          {
            !loading
            &&
            Object.values(activeTeamPlayers).map((player) => {
              return (
                <>
                  <div className='my-2'>
                    <div className='d-flex align-items-center mt-2 mx-2'>
                      <img className='img-responsive rounded-circle mx-2' src={player.image} style={{ height: 50, width: 50, objectFit: 'cover' }} />
                      <div className='flex1'>
                        <p className='m-0'>{player.name} <span className='small' style={{ color: Colors.colorSecondary }}>{player.pos} | {player.team}</span><span className='pull-right bold small' style={{ color: Colors.colorDanger }}>+GTD</span></p>
                        <p className='m-0 small bold' style={{ color: Colors.colorSecondary }}>Q1 3:38, 25-20</p>
                      </div>
                    </div>
                    <div className='d-flex'>
                      {selectedCats.map((cat) => {
                        return (
                          <div className='flex1 text-center bold'>{parseTotalStat(cat, playerTotalStats(player, statType, sport)[cat])}</div>
                        )
                      })}
                    </div>
                  </div>
                  <Divider />
                </>
              )
            })
          }
        </div>
      </div>
    )
  }

  const showSkeleton = () => {
    return (
      <>
        <div className='d-flex flex1 mt-2 mx-3'>
          <div>
            <Skeleton className='rounded-circle m-0 p-0 mr-2' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor} height="30px" width="30px" />
          </div>

          <div className='flex1'>
            <Skeleton className='p-0' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor} />
            <Skeleton className='p-0' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor} />
          </div>

          <div style={{ width: 30, height: 40 }}>
            <Skeleton className='w-100 ml-3 p-0' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor} />
            <Skeleton className='w-100 ml-3 p-0' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor} />
          </div>
        </div>

        <div className='mt-2 mx-3' style={{ width: 40, height: 40 }}>
          <Skeleton className='d-inline-block h-100' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor} />
        </div>

        <div className='d-flex flex1 mt-2 mx-3'>
          <div>
            <Skeleton className='rounded-circle m-0 p-0 mr-2' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor} height="30px" width="30px" />
          </div>

          <div className='flex1'>
            <Skeleton className='p-0' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor} />
            <Skeleton className='p-0' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor} />
          </div>

          <div style={{ width: 30, height: 40 }}>
            <Skeleton className='w-100 ml-3 p-0' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor} />
            <Skeleton className='w-100 ml-3 p-0' baseColor={Colors.skeletonBaseColor} highlightColor={Colors.skeletonHighlightColor} />
          </div>
        </div>

      </>
    )
  }

  const btnVariants = {
    active: { color: Colors.textDark_v3, backgroundColor: Colors.colorPrimary },
    secondary: { color: Colors.colorDangerTransparent_v2 }
  }

  const renderMlbScoreboard = (activeView = view, match) => {
    if (view == "summary") {
      return <>
        <div className='d-flex justify-content-center align-items-center w-100 flex1 alex' style={{ fontSize: 16, fontWeight: 600, height: "64%", margin: "14px 0px 10px 0px" }}>
          <p className='m-0 w-100 bold text-center' style={{ color: Colors.colorDanger }}>{data?.meta.startDate}</p>
          <div className='m-0' style={{ width: "100%", height: 10, background: Colors.colorPrimaryTransparent }}>
            <div className='m-0' style={{ width: `${data?.meta.progress}%`, height: 10, background: Colors.colorDanger }}></div>
          </div>
          <p className='m-0 w-100 bold text-center' style={{ color: Colors.colorDanger }}>{data?.meta.endDate}</p>
        </div>
        <div className='w-100 '>
          <div>
            {selectedCats.hitting.map((stat, index) => {
              return <MLBEachCatRow statKey={stat} statCat={data?.meta.statAbbr[stat]} team1Total={team1Total[stat]} team2Total={team2Total[stat]} key={index} />
            })}
          </div>
          <div>
            {selectedCats.pitching.map((stat, index) => {
              return <MLBEachCatRow statKey={stat} statCat={data?.meta.statAbbr[stat]} team1Total={team1Total[stat]} team2Total={team2Total[stat]} key={index} />
            })}
          </div>
        </div>
      </>
    } else {
      var players = [];
      var positions = [];
      var key = "hitting";
      let activeTeamPlayers;
      let todayTotal;
      if (activeView == "team1") {
        activeTeamPlayers = JSON.parse(JSON.stringify(Object.values(players1Data?.players)));
        todayTotal = players1Data?.todayTotal;
      } else {
        activeTeamPlayers = JSON.parse(JSON.stringify(Object.values(players2Data?.players)));
        todayTotal = players2Data?.todayTotal;
      }

      if (currentPos == "batters") {
        positions = MATCH_MAKER_BATTING_POS
        key = "hitting"
      } else {
        positions = MATCH_MAKER_PITCHING_POS
        key = "pitching"
      }
      // diff players based on positions
      positions.map((mmPos) => {
        return activeTeamPlayers.map((player, index) => {
          if (player.pos == mmPos) {
            players.push(player)
            activeTeamPlayers.splice(index, 1)
          }
        })
      })

      return (
        <div className='w-100'>
          <div className='d-flex justify-content-between align-items-center px-2'>
            <div className='d-flex flex1 justify-content-center bold pb-1 pointer' style={{ color: statType == "today" && Colors.colorDanger, borderBottom: statType == "today" && `2px solid ${Colors.colorDanger}` }} onClick={() => setStatType("daily")}>
              <ReactSelect className="w-100" getOptionValue={option => option.daily_period} getOptionLabel={option => option.date !== "Today" ? moment(option.date).format("ddd, MMM D") : option.date} options={match.status !== "COMPLETED" ? [{daily_period: data?.meta?.totalPeriods[0].daily_period, date: "Today"}, ...data.meta.totalPeriods] : data.meta.totalPeriods} value={dailyPeriod} onChange={handleDailyPeriod} styles={statType == "total" ? STYLES.REACT_SELECT_DANGER : STYLES.REACT_SELECT_DANGER_CONDITIONAL} closeMenuOnSelect={true} isClearable={false} components={{ IndicatorSeparator: () => null }} />
            </div>
            <div className='d-flex flex1 justify-content-center bold pb-2 pointer' style={{ color: statType == "total" && Colors.colorDanger, borderBottom: statType == "total" && `2px solid ${Colors.colorDanger}` }} onClick={handleTotalStats}>Matchup Total</div>
          </div>
          <div className='d-flex justify-content-center align-items-center my-3 w-50' style={{ background: Colors.colorDangerTransparent, borderRadius: 24, margin: "0 auto" }}>
            <motion.h6 className='flex1 m-0 py-2 pointer bold text-center' style={{ zIndex: 1, borderRadius: 24 }} animate={currentPos == "batters" ? 'active' : 'secondary'} transition={{ duration: 0.5 }} variants={btnVariants} onClick={() => setCurrentPos("batters")}>Batters</motion.h6>
            <motion.h6 className='flex1 m-0 py-2 pointer bold text-center' style={{ zIndex: 1, borderRadius: 24 }} animate={currentPos == "pitchers" ? 'active' : 'secondary'} transition={{ duration: 0.5 }} variants={btnVariants} onClick={() => setCurrentPos("pitchers")}>Pitchers</motion.h6>
          </div>

          {/* Header */}
          <div className='d-flex'>
            {selectedCats[key].map((cat) => {
              return (
                <div className="flex1 text-center bold" style={{ color: Colors.colorDanger }}>{data.meta.statAbbr[cat]}</div>
              )
            })}
          </div>
          {statType == "daily" &&
            <div className='d-flex align-items-center mt-1' style={{ background: Colors.bgDark, height: "56px" }}>
              {selectedCats[key].map((cat) => {
                return (
                  <div className="flex1 text-center bold">{parseTotalStat(cat, todayTotal?.[cat])}</div>
                )
              })}
            </div>
          }
          {/* Players List */}
          <div>
            {
              !loading
              &&
              players?.map((player) => {
                return (
                  <div style={{ borderBottom: `1px solid ${Colors.arenaLineupPlayerRow}` }}>
                    <div className='my-2'>
                      <div className='d-flex align-items-center mt-2 mx-2'>
                        <img className='img-responsive rounded-circle mx-2' src={player.image} style={{ height: 40, width: 40, objectFit: 'cover' }} />
                          <p className='m-0'>{player.name} <span className='small' style={{ color: Colors.colorSecondary }}>{player.pos} | {player.team}</span><span className='pull-right bold pl-2 small pt-1' style={{ color: Colors.colorDanger }}>{player?.injuryStatus}</span></p>
                          {/* <p className='m-0 small bold' style={{ color: Colors.colorSecondary }}>Q1 3:38, 25-20</p> */}
                      </div>
                      <div className='d-flex'>
                        {selectedCats[key].map((cat) => {
                          return (
                            <div className='flex1 text-center bold'>{parseTotalStat(cat, playerTotalStats(player, statType, sport)[cat])}</div>
                          )
                        })}
                      </div>
                    </div>
                    <Divider />
                  </div>
                )
              })
            }
          </div>
        </div>
      )
    }
  };

  return (
    <motion.div
      className='p-2 my-2'
      style={{ backgroundColor: Colors.bgDark_v3, borderRadius: 10, overflow: 'hidden' }}
      animate={collapsed ? 'collapsed' : 'expanded'}
      transition={{ duration: 0.5 }}
      initial={false}
      variants={matchupCardBgColor}
    >

      {/* Matchup Head */}
      <div className='d-flex align-items-center'>
        <motion.div
          className='d-flex align-self-center'
          onClick={toggleCollapsed}
          style={{ cursor: 'pointer' }}
          animate={collapsed ? 'collapse' : 'expand'}
          transition={{ duration: 0.5 }}
          initial={false}
          variants={collapseHorizontal}
        >
          {closeIcon()}
        </motion.div>
        {data?.meta?.sport == "NBA" ?
          <div className='d-flex flex1 align-items-center justify-content-between px-3 py-2 bold' style={{ backgroundColor: Colors.colorPrimaryTransparent, borderRadius: 5 }}>
            <p className='m-0 small-font'>{activeTab == 'past' ? 'Completed' : match.startDate + ' - ' + match.endDate}</p>
            {match.isPaid && <p className='m-0 small-font'>{activeTab == 'live' ? "Possible Winnings $" : ""} <span className='bold'>{activeTab == 'live' ? match.atStake : match.result}</span></p>}
          </div>
          :
          <div className={`d-flex flex1 align-items-center justify-content-between bold ${activeTab !== "past" && "py-2 px-2"}`} style={{ backgroundColor: activeTab == "live" ? Colors.colorPrimaryTransparent : activeTab == "upcoming" ? Colors.arenaLineupPlayerRow : activeTab == "past" ? Colors.colorPrimaryTransparent_v2 : "", borderRadius: 5, borderTopLeftRadius: match.status == "COMPLETED" ? 20 : 5, borderBottomLeftRadius: match.status == "COMPLETED" ? 20 : 5 }}>
            {match.status == "COMPLETED" && 
              <div className='d-flex align-items-center m-0'>
                <p className='m-0 mt-1'>{matchupCompleteIcon()}</p>
                <p className='m-0 small-font pl-1'>{match.isPaid ? `$${match.atStake} on Stake` : "Free"}</p>
              </div>
            }
            <p className='m-0 small-font pr-2'>{activeTab == 'past' ? 'Completed' : match.duration}</p>
          </div>
        }
        {/* <div className='d-flex flex1 align-items-center justify-content-between px-3 py-2 bold' style={{ backgroundColor: Colors.darkGreen_v3, borderRadius: 5 }}>
          <p className='m-0 small-font'><span style={{ color: Colors.colorPrimary }}>${match.isPaid ? match.possibleWinnings.totalFee : '0'}</span> Winnings</p>
          <p className='m-0 small-font'>{activeTab == 'past' ? 'Completed' : match.startDate+' - '+ match.endDate}</p>
        </div> */}
      </div>

      {/* Matchup Body Collapsed */}
      <motion.div
        className='pointer'
        animate={collapsed ? 'expand' : 'collapse'}
        onClick={toggleCollapsed}
        transition={{ duration: 0.5 }}
        initial={false}
        variants={collapseVertical}
      >

        {/* Team 1 */}
        <div className='d-flex align-items-center py-2'>
          <div className='d-flex flex1 align-items-center'>
            <img className='roundImgMed mr-2' src={match.from.teamImage} />
            <div className='d-flex align-items-center'>
              <h4 className='m-0 big-font bold' style={{ color: sport == "MLB" && Colors.colorDanger }}>{match.from.teamName}</h4>
              {/* <div className='mx-1' style={{height:40, width:40, padding:10, borderRadius:'50%', backgroundColor: Colors.bgDark}}>{arenaLineupEditIcon()}</div> */}
            </div>
          </div>
          {activeTab == "past" ? teamPoints?.team1Wlt.w > teamPoints?.team2Wlt.w ? 
            <p className='d-flex align-items-center justify-content-center mx-3 mb-2' style={{ width: "32px", height: "32px", background: Colors.mlbPrimaryColor, borderRadius: 100, fontSize: 20, fontWeight: 600, color: Colors.colorDark }}>W</p>
            :
            <p className='d-flex align-items-center justify-content-center mx-3 mb-2' style={{ width: "32px", height: "32px", background: Colors.colorPrimary, borderRadius: 100, fontSize: 20, fontWeight: 600, color: Colors.colorDark }}>L</p>
          :""}
          <div className='d-flex flex-column align-items-end'>
            <h1 className='bold' style={{ color: sport == "MLB" && Colors.mlbPrimaryColor }}>{teamPoints?.team1Wlt.w}</h1>
            {/* PMR */}
            {/* <div className='d-flex align-items-center' style={{ minWidth: 200 }}>
              <p className='my-0 mx-2'>PMR</p>
              <div className='d-flex flex1 position-relative align-items-center justify-content-end' style={{ height: 5, backgroundColor: Colors.colorSecondary_v3 }}>
                <div className='podition-absolute' style={{ height: 10, backgroundColor: 'white', width: '30%', borderRadius: 5, zIndex: 1 }}></div>
              </div>
              <p className='my-0 mx-2'>{generatePoints({ mapping: rules }, match.from.players)}</p>
            </div> */}
            {/* !PMR */}

          </div>
        </div>
        {/* !Team 1 */}

        <Divider />

        {/* Team 2 */}
        <div className='d-flex align-items-center py-2'>
          <div className='d-flex flex1 align-items-center'>
            <img className='roundImgMed mr-2' src={match.to.teamImage} />
            <h4 className='m-0 big-font bold'>{match.to.teamName}</h4>
          </div>
          {/* {activeTab == "past" ? teamPoints?.team1Wlt.w < teamPoints?.team2Wlt.w ? 
            <p className='d-flex align-items-center justify-content-center mx-3 mb-2' style={{ width: "32px", height: "32px", background: Colors.mlbPrimaryColor, borderRadius: 100, fontSize: 20, fontWeight: 600, color: Colors.colorDark }}>W</p>
            :
            <p className='d-flex align-items-center justify-content-center mx-3 mb-2' style={{ width: "32px", height: "32px", background: Colors.colorPrimary, borderRadius: 100, fontSize: 20, fontWeight: 600, color: Colors.colorDark }}>L</p>
          : ""} */}
          <div className='d-flex flex-column align-items-end'>
            <h1 className='bold'>{teamPoints?.team2Wlt.w}</h1>

            {/* PMR */}
            {/* <div className='d-flex align-items-center' style={{ minWidth: 200 }}>
              <p className='my-0 mx-2'>PMR</p>
              <div className='d-flex flex1 position-relative align-items-center justify-content-end' style={{ height: 5, backgroundColor: Colors.colorSecondary_v3 }}>
                <div className='podition-absolute' style={{ height: 10, backgroundColor: 'white', width: '70%', borderRadius: 5, zIndex: 1 }}></div>
              </div>
              <p className='my-0 mx-2'>{generatePoints({ mapping: rules }, match.to.players)}</p>
            </div> */}
            {/* !PMR */}

          </div>
        </div>
        {/* !Team 2 */}

      </motion.div>
      <div className='d-flex justify-content-center align-items-center'>
        {activeTab == "past" &&
            <Button disabled={match?.matchId == rematchData?.matchId} className='w-75 py-2 mb-2 border-0' style={{ margin: "0 auto", background: Colors.mlbPrimaryColor, color: Colors.colorDark, borderRadius: 100 }} onClick={rematchHandler}>
              <div className='d-flex justify-content-center align-items-center'>
                <p className='m-0 mt-1 mx-2'>{rematchIcon()}</p>
                <p className='m-0' style={{ fontSize: 14, fontWeight: 600 }}>Rematch</p>
              </div>
            </Button>
          }
      </div>
      {/* !Matchup Body Collapsed */}

      {/* Matchup Expanded */}
      <motion.div
        animate={collapsed ? 'collapse' : 'expand'}
        transition={{ duration: 0.5 }}
        initial={false}
        variants={collapseLineupVertical}
      >

        <div className='d-flex flex-column pt-2'>

          {/* HEAD */}
          <div className='d-flex'>

            {/* Team 1 */}
            {view == 'team1' ?
              <div className='d-flex flex1' style={{ backgroundColor: Colors.arenaContentBg_v3 }}>
                <div className='d-flex flex1 flex-column align-items-center justify-content-center text-center pointer pt-2' onClick={() => handleView('team1')} style={{ backgroundColor: Colors.bgDark_v3, borderTopRightRadius: 10, borderTopLeftRadius: 10 }}>
                  <img className='roundImgMedNoOutline' src={match.from.teamImage} />
                  <div className='d-flex align-items-center py-3'>
                    <h4 className='m-0 bold' style={{ color: sport == "MLB" && Colors.colorDanger, fontFamily: "Alexandria", fontSize: 16, fontWeight: 600 }}>{match.from.teamName}</h4>
                    {/* <div className='mx-1' style={{height:40, width:40, padding:10, borderRadius:'50%', backgroundColor: Colors.bgDark}}>{arenaLineupEditIcon()}</div> */}
                  </div>
                  <h1 className='bold' style={{ color: sport == "MLB" && Colors.colorDanger, fontFamily: "Alexandria", fontSize: 40, fontWeight: 600 }}>{teamPoints?.team1Wlt.w}</h1>
                </div>
              </div>
              :
              <div className='d-flex flex1 flex-column align-items-center justify-content-center text-center pointer pt-2' onClick={() => handleView('team1')} style={{ backgroundColor: Colors.arenaContentBg_v3, borderBottomRightRadius: view == 'summary' ? 10 : 0 }}>
                <img className='roundImgMedNoOutline' src={match.from.teamImage} />
                <div className='d-flex align-items-center py-3'>
                  <h4 className='m-0 bold' style={{ color: sport == "MLB" && Colors.colorDanger, fontFamily: "Alexandria", fontSize: 16, fontWeight: 600 }}>{match.from.teamName}</h4>
                  {/* <div className='mx-1' style={{height:40, width:40, padding:10, borderRadius:'50%', backgroundColor: Colors.bgDark}}>{arenaLineupEditIcon()}</div> */}
                </div>
                <h1 className='bold' style={{ color: sport == "MLB" && Colors.colorDanger, fontFamily: "Alexandria", fontSize: 40, fontWeight: 600 }}>{teamPoints?.team1Wlt.w}</h1>
                {/* <p className='small' style={{ color: Colors.textSecondary_v3 }}>Projected</p> */}

                {/* PMR */}
                {/* <div className='d-flex align-items-center' style={{ minWidth: 200 }}>
                  <p className='my-0 mx-2'>PMR</p>
                  <div className='d-flex flex1 position-relative align-items-center justify-content-end' style={{ height: 5, backgroundColor: Colors.colorSecondary_v3 }}>
                    <div className='podition-absolute' style={{ height: 10, backgroundColor: 'white', width: '30%', borderRadius: 5, zIndex: 1 }}></div>
                  </div>
                  <p className='my-0 mx-2'>{data && generatePoints({ mapping: data.meta.rules }, data.teams[0].players)}</p>
                </div> */}
                {/* !PMR */}

                {/* Rounded Corner */}
                {/* <div className=" w-100 m-0" style={{backgroundColor:Colors.timerBg_v3, height:10}}>
                  <div className='h-100' style={{backgroundColor:Colors.arenaContentBg_v3, borderBottomRightRadius:10}}></div>
                </div> */}
              </div>
            }
            {/* !Team 1 */}
            <div>
              <p className='m-0 text-center my-2 pointer' onClick={() => setViewMatchSchedule(true)}>{scoreboardScheduleIcon()}</p>
              <div className='h-100' style={{ backgroundColor: view == "summary" && Colors.bgDark_v3 }}>
                {
                  view == 'summary' ?
                    <div className='d-flex pointer' onClick={() => handleView('summary')} style={{ backgroundColor: Colors.arenaContentBg_v3 }}>
                      <div style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, backgroundColor: Colors.bgDark_v3 }}>
                        <div style={{width:10, backgroundColor:Colors.timerBg_v3, borderBottomRightRadius:10}}></div>
                        <h1 className='p-4 bold roboto'>VS</h1>
                        <div style={{width:10, backgroundColor:Colors.timerBg_v3, borderBottomLeftRadius:10}}></div>
                      </div>
                    </div>
                    :
                    <div className='d-flex pointer' onClick={() => handleView('summary')} style={{ backgroundColor: Colors.bgDark_v3 }}>
                      <div style={{ borderBottomLeftRadius: view == 'team1' && 0, borderBottomRightRadius: view == 'team2' && 0, backgroundColor: Colors.arenaContentBg_v3 }}>
                        <h1 className='p-4 bold roboto'>VS</h1>
                      </div>
                    </div>
                }
              </div>
            </div>


            {/* Team 2 */}
            {
              view == 'team2' ?
                <div className='d-flex flex1' style={{ backgroundColor: Colors.arenaContentBg_v3 }}>
                  <div className='d-flex flex1 flex-column align-items-center justify-content-center text-center pointer pt-2' onClick={() => handleView('team2')} style={{ backgroundColor: Colors.bgDark_v3, borderTopRightRadius: 10, borderTopLeftRadius: 10 }}>
                    <img className='roundImgMedNoOutline' src={match.to.teamImage} />
                    <div className='d-flex align-items-center py-3'>
                      <h4 className='m-0 bold' style={{ fontFamily: "Alexandria", fontSize: 16, fontWeight: 600 }}>{match.to.teamName}</h4>
                    </div>
                    <h1 className='bold' style={{ fontFamily: "Alexandria", fontSize: 40, fontWeight: 600 }}>{teamPoints?.team2Wlt.w}</h1>
                  </div>
                </div>
                :
                <div className='d-flex flex1 flex-column align-items-center justify-content-center text-center pointer pt-2' onClick={() => handleView('team2')} style={{ backgroundColor: Colors.arenaContentBg_v3, borderBottomLeftRadius: view == 'summary' ? 10 : 0 }}>
                  <img className='roundImgMedNoOutline' src={match.to.teamImage} />
                  <div className='d-flex align-items-center py-3'>
                    <h4 className='m-0 bold' style={{ fontFamily: "Alexandria", fontSize: 16, fontWeight: 600 }}>{match.to.teamName}</h4>
                  </div>
                  <h1 className='bold' style={{ fontFamily: "Alexandria", fontSize: 40, fontWeight: 600 }}>{teamPoints?.team2Wlt.w}</h1>
                  {/* <p className='small' style={{ color: Colors.textSecondary_v3 }}>Projected</p> */}


                  {/* PMR */}
                  {/* <div className='d-flex align-items-center' style={{ minWidth: 200 }}>
                  <p className='my-0 mx-2'>PMR</p>
                  <div className='d-flex flex1 position-relative align-items-center justify-content-end' style={{ height: 5, backgroundColor: Colors.colorSecondary_v3 }}>
                    <div className='podition-absolute' style={{ height: 10, backgroundColor: 'white', width: '30%', borderRadius: 5, zIndex: 1 }}></div>
                  </div>
                  <p className='my-0 mx-2'>{data && generatePoints({ mapping: data.meta.rules }, data.teams[1].players)}</p>
                </div> */}
                  {/* !PMR */}

                  {/* Rounded Corner */}
                  {/* <div className=" w-100 m-0" style={{backgroundColor:Colors.timerBg_v3, height:10}}>
                  <div className='h-100' style={{backgroundColor:Colors.arenaContentBg_v3, borderBottomLeftRadius:10}}></div>
                </div> */}
                </div>
            }
            {/* !Team 2 */}

          </div>
          {/* !HEAD */}

          {/* BODY */}
          <div className='d-flex' style={{ backgroundColor: Colors.arenaContentBg_v3 }}>
            <div className='flex1' style={{ backgroundColor: Colors.bgDark_v3, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopRightRadius: view == 'team2' ? 0 : 10, borderTopLeftRadius: view == 'team1' ? 0 : 10 }}>
              {/* Lineup Player Row */}
              {
                loading || periodLoading ?
                  (
                    <div className='d-flex align-items-center m-2'>
                      {showSkeleton()}
                    </div>
                  )

                  :
                  (
                    <div className='d-flex flex-column align-items-center m-2'>
                      {
                        data?.meta?.sport == "NBA" ? view == 'summary' ?
                          parseScoreboard()
                          :
                          parseLineup()
                          :
                          renderMlbScoreboard(view, match)
                      }
                    </div>
                  )
              }
              {/* !Lineup Player Row */}
            </div>
          </div>
          {/* !BODY */}
        </div>

        {viewMatchSchedule && <MatchScheduleModal isOpen={viewMatchSchedule} toggle={() => setViewMatchSchedule(!viewMatchSchedule)} matchId={match?.matchId} sport={sport} />}

      </motion.div>
      {/* !Mathchup Expanded */}

      {/* Edit Lineup Modal Goes Here to perform lineup update operation */}
    </motion.div>
  )
}

export default SingleMatchupNba