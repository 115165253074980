import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Row, Card, Button,  ListGroup, ListGroupItem, UncontrolledAlert } from "reactstrap";
import { getYahooLeagues, getYahooRoster, getRoster, saveRoster, autoLogin, triggerYahooImport, claimTeam } from "../../actions/Import/api"
import profile from '../../assets/images/profile.png';
import { NFL_S3, YAHOO_REDIRECT_URI, YAHOO_REDIRECT_URI_NEW_USER } from "../../config/app";
import queryString from 'query-string';
import yahoo from '../../assets/images/nfl/yahoo.png';
import CommishInviteModal from "../../modals/CommishInviteModal";
import { errorMessage, setInForage, TOAST } from "../../helpers/common-methods";
import ImportProgress from "../../components/LeagueImport/ImportProgress";
import football from "../../assets/images/football.png"
import baseball from "../../assets/images/baseball.png"
import { LoaderCenter } from "../../components/Loader";
import ErrorText from "../../components/ErrorText";
import { InviteMembers } from "../../components/LeagueImport/InviteMembers";
import { STYLES } from "../../helpers/stylesHelper";

class YahooImport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            container: 0,
            data: [],
            leagueId: "",
            team_id: "",
            teamName: "",
            players: [],
            errors: [],
            sport: this.props.sport || null,
            response: {},
            importSuccess: false,
            commishInvite: false,
            selectedLeague: null,
            step: "import",
            selectedLeagueId: null,
            processing: false
        }
    }

    componentDidMount(){
        if(this.props.sport){
            this.handleSport(this.props.sport)
        }
    }

    fetchLeagues(sport = this.state.sport){
        let params = queryString.parse(this.props.location.search)
        if(params.code != undefined){
            this.setState({ loading: true})
            // send request to server get access token and fetch team
            this.props.getYahooLeagues({ code: params.code, sport: sport }).then((resp) => {
                 this.setState({ loading: false, data: resp, container: 2 })
            }).catch(() => {
                errorMessage("Something went wrong, please try again.")
            })
        }else{
             // auto login code
             this.setState({ loading: true, sport: sport })
             this.props.autoLogin({ platform: "yahoo", sport: sport }).then(( resp ) => {
                 this.setState({ loading: false, data: resp, container: 2})
             }).catch((err) => {
                var NEW_YAHOO_REDIRECT_URI = this.getYahooRedirectUri()
                let yahooAuthUrl = "https://api.login.yahoo.com/oauth2/request_auth?client_id=dj0yJmk9OWZJSmJKWnJHQVVpJmQ9WVdrOU1GVjRUWGR4TldjbWNHbzlNQS0tJnM9Y29uc3VtZXJzZWNyZXQmc3Y9MCZ4PWM2&response_type=code&language=en-us&redirect_uri="+NEW_YAHOO_REDIRECT_URI;
                 // DIRECT OPEN YAHOO AUTH PAGE
                window.open(yahooAuthUrl, "_self")
                //  this.setState({ loading: false, container: 1})
             })
        }
    }

    updateContainer(index){
        this.setState({ container: index })
    }

    triggerImport(league){
        this.setState({ selectedLeagueId: league.id, processing: true})
        if(league.league_type == "private"){
            this.setState({ commishInvite: true, processing: false })
        }else{
            // Call Import API here
            this.props.triggerYahooImport({ lg_key: league.key, name: league.name, sport: this.state.sport }).then((resp) => {
                // this.props.setShowToast(false)
                // this.props.setShowToast(true)
                this.setState({container: 5, processing: false})
           }).catch(async(err) => {
                if(err == "IMPORTED"){
                    // NAVIGATE TO CLAIM FLOW
                    await setInForage("claimTeam", {
                        lgId: league.id,
                        platform: "fantrax",
                        lgName: decodeURIComponent(league.name),
                        sbId: null,
                        leagueId: league.leagueId,
                        grudgeId: null
                    })
                    this.props.history.push("/newClaimTeam?fromImport=true")
                }else{
                    TOAST.error(err)
                }
            this.setState({processing: false})
           })
        }
        this.setState({selectedLeague: league})
    }

    getYahooRedirectUri(){
        let params = queryString.parse(this.props.location.search)
        var NEW_YAHOO_REDIRECT_URI = "";
        if (this.props?.for == "newUser") {
            NEW_YAHOO_REDIRECT_URI = YAHOO_REDIRECT_URI_NEW_USER
            NEW_YAHOO_REDIRECT_URI += "?platform=yahoo"
            NEW_YAHOO_REDIRECT_URI += "&state=sport="+this.state.sport 
        }
        else {
            var NEW_YAHOO_REDIRECT_URI = YAHOO_REDIRECT_URI+"?platform=yahoo&state=sport="+this.state.sport 
        }
        return NEW_YAHOO_REDIRECT_URI
    }

    updateStep = (step) => {
        this.setState({step: step})
    }


    handleSport(sport){
        this.setState({ sport: sport, container: 1})
        this.fetchLeagues(sport)
    }

    render() {
        return (
            <div>
                <div className="p-md-4 p-2">
                    {this.state.loading ? (<LoaderCenter />) : (
                        this.state.error ? (<ErrorText msg={this.state.error} />) : (
                            <div>
                                {/* <ul className="progressbar mb-4">
                                    <li className={this.state.step == "import" ? "active" : ""}>Import League</li>
                                    <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center"}} >
                                        <div style={{borderTop: "1px solid #7a7a7a", width: "100%"}} ></div>
                                    </div>
                                    <li className={this.state.step == "invite" ? "active" : ""} >Invite League</li>
                                    <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center"}} >
                                        <div style={{borderTop: "1px solid #7a7a7a", width: "100%"}} ></div>
                                    </div>
                                    <li className={this.state.step == "download" ? "active" : ""} >Download App</li>
                                </ul> */}

                                    { this.state.errors.exception != undefined && <UncontrolledAlert color="danger">
                                        {this.state.errors.exception}
                                    </UncontrolledAlert>}
                                    {(() => {
                                    switch (this.state.container) {
                                        case 0:
                                            return (
                                                <div>
                                                    <h2 className="center mb-4 mt-3 bold" >Select Sport</h2>
                                                    <Col md="6" className="col-centered" >
                                                        <Card>
                                                            <Row>
                                                                <Col className="center p-4 pointer" onClick={() => this.handleSport("NFL")}>
                                                                    <img src={football} width="64" height="64" />
                                                                    <h3 style={{marginTop: "14px", fontWeight: "bold"}} >NFL</h3>
                                                                </Col>

                                                                <Col className="center pointer p-4" onClick={() => this.handleSport("MLB")} >
                                                                    <img src={baseball} width="64" height="64" />
                                                                    <h3 style={{marginTop: "14px", fontWeight: "bold"}}>MLB</h3>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                </div>
                                            )
                                        case 1:
                                            return (
                                                <div className="center" >
                                                    {this.props?.for != 'newUser' && <h2 className="greenText bold" style={{ marginBottom: "20px" }} > Import Yahoo League</h2>}
                                                    <Col>
                                                        <p className="lead" style={{ marginBottom: "30px" }}  > You will need to login to Yahoo to import your league</p>
                                                        <Button  style={STYLES.BUTTON_PRIMARY_TRANSPARENT}  onClick={() => {window.open(yahooAuthUrl, '_blank')}} >Import League</Button>
                                                    </Col>
                                                </div>
                                            )
                                        case 2: 
                                            return (
                                                <Col>
                                                    {this.state.data.length > 0 ? (
                                                    <div>
                                                    {this.props?.for != 'newUser' && <h3 className="greenText" style={{ marginBottom: "20px", fontWeight: "bold" }} >Which league you would like to import:</h3>}
                                                    {/* <ListGroup>
                                                        {this.state.data.map((league) => {
                                                            return (
                                                                <ListGroupItem style={{color: "white"}} tag="button" onClick={() => this.triggerImport(league)} action>
                                                                    <div className="d-flex justify-content-between align-items-center" >
                                                                        <div className="d-flex align-items-center" >
                                                                            <img className="importImg" src={yahoo} />
                                                                            <div>
                                                                                <p className="bold m-0" >{league.name}</p>
                                                                                <p className="m-0" >{league.team_name}</p>
                                                                            </div>
                                                                        </div> */}
                                                                        {/* <div>
                                                                            <Button className="green-btn" >Start Import</Button>
                                                                        </div> */}
                                                                    {/* </div>
                                                                
                                                                </ListGroupItem>
                                                            )
                                                        })}
                                                    </ListGroup> */}

                                                    <div disabled={this.state.processing} style={{opacity: this.state.processing ? .5 : 1}} className="d-flex flex-column">
                                                        {this.state.data.map((league) => {
                                                            return (
                                                                <div  className="flex1 pointer" style={{color: "white"}} tag="button" onClick={() => this.triggerImport(league)} action>
                                                                    <div className="d-flex justify-content-between align-items-center" >
                                                                        <div className="d-flex align-items-center" >
                                                                            <img className="importImg" src={yahoo} />
                                                                            <div>
                                                                                <p className="bold m-0" >{league.name}</p>
                                                                                <p className="m-0" >{league.team_name}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    </div>) : (
                                                        <div className="d-flex flex-column justify-content-center align-items-center" >
                                                        <p className="text-center">We couldn't find any league in your Yahoo account, please join or create a league and refresh page.</p>
                                                        <div>
                                                            <Button style={STYLES.BUTTON_PRIMARY_TRANSPARENT} className="mt-2" onClick={() => {
                                                                window.open("https://sports.yahoo.com/fantasy/", "_blank")
                                                            }} >Go to Yahoo</Button>
                                                        </div>
                                                    </div>
                                                    )}
                                                </Col>
                                            )
                                        case 3: 
                                            return (
                                                <div >
                                                    <h5 className="center mb-4 bold" >Would you like to import the team below?</h5>
                                                    <Row className="p-2" >
                                                        <Col sm="12" md="6" className="col-centered card" >
                                                        <Row>
                                                            {this.state.players.map((player) => {
                                                                return <Col xs="12"  className="d-flex flex-row align-items-center p-2" style={{borderBottom: "1px solid silver"}} >
                                                                        <div style={{width: "10%", verticalAlign: 'middle'}} className="mr-4" ><img style={{borderRadius: "50%"}} className="img-fluid rounded-circle" src={player.image !== null ? NFL_S3 + player.image : profile} /></div>
                                                                        <div style={{flex: 1}} >{player.player_name}</div>
                                                                        <div className="text-right" >{player.team} | {player.pos}</div>
                                                                </Col>
                                                                })}
                                                        </Row>
                                                        </Col>
                                                    </Row>

                                                    <div className="mt-4 center">
                                                        <Button className="btn-pill dark-btn" onClick={() => { this.setState({ container: 2 }) }  } style={{ marginRight: "50px", width: "100px" }} >Back</Button>
                                                        <Button className="btn-pill green-btn" onClick={() => this.saveRoster()}  style={{ width: "100px" }}  >Confirm</Button>
                                                    </div>                                                    
                                                </div>
                                            )
                                        case 4:
                                            return (
                                               <InviteMembers updateStep={this.updateStep} {...this.props} lg={this.state.selectedLeague} platform={"yahoo"} />
                                            )
                                        case 5:
                                            return (
                                               <ImportProgress
                                                    handleSuccess={this.props?.handleSuccess}
                                                    showInvite={() => this.setState({container: 4, step: "invite"})}
                                                    platform={"yahoo"}
                                                    lg={this.state.selectedLeague}
                                                    sport={this.state.sport}
                                               />
                                            )
                                        default :
                                            return null
                                    }
                                    })()}
                            </div>
                        ))}
                </div>
                {/* <ToastContainer /> */}
                {this.state.commishInvite && <CommishInviteModal modal={this.state.commishInvite} 
                toggle={() => this.setState({ commishInvite: !this.state.commishInvite })} league={this.state.selectedLeague} />}
            </div>
        )
    }
}

const mapDispatchToProps = {
    getYahooLeagues,
    getYahooRoster,
    getRoster,
    saveRoster,
    autoLogin,
    triggerYahooImport,
    claimTeam
};
export default connect(null, mapDispatchToProps)(withRouter(YahooImport));