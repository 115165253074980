import React from "react";
// import { Colors } from "../../../helpers/colors";
import { IRStatuses, parseInjuryIcons, parsePlayerName } from "../../../helpers/common-methods";
import { StickyTable, Row, Cell } from "react-sticky-table";
// import { View, ScrollView, Pressable, TouchableOpacity, RefreshControl } from "react-native";
// import { Avatar,IndexPath,Select,SelectItem,p, useTheme, withStyles } from "@ui-kitten/components";
// import ProgressHeader from "../TeamProgress/ProgressHeader";
// import { wp } from "../shared/Responsiveness";
import { useColors } from '../../../helpers/CustomHooks/useColors';

const TeamStatsView = (props) => {
    const Colors = useColors();
    const {players, showPlayerModal} = props
    // const theme  = useTheme()

    const positions = () => {
        var positions = []
        players.non_be.map((player) => {
            if(!positions.includes(player.a_pos)){
                positions.push(player.a_pos)
            }
        })
        return positions
    }

    const dynamicColumns = (player) => {
        return <>
            {Object.values(player.stats).map((statVal) => {
            return <Cell style={{backgroundColor: Colors.bgDark}} >
                    <p>{statVal}</p>
                </Cell>
            })}
        </>
        }

    // THIS IS FOR OVERVIEW PLAYERS ROW
    const playerRow = (player, dynamic) => {
        return (
            <Row >
                <Cell style={{backgroundColor: Colors.bgDark}} >
                <div className='d-flex flex-row align-items-center' >
                  
                  <div>
                      <div className='d-flex' style={{border: "2px solid dimgrey", borderRadius: 40, width: 40, height: 40, alignItems: "center", justifyContent: "center"}} >
                          <p className='m-0' > {player.a_pos} </p>
                      </div>
                  </div>
                  <div className='ml-2 position-relative' style={{ overflow: 'hidden', maxWidth: '40px', maxHeight: '100%' }} >
                    <img className='img-responsive rounded-circle my-1' src={player.image} width='100%' height='40px' style={{ objectFit: 'cover' }} />
                    <div style={{position:'absolute', bottom:0, right:0}}>
                        {parseInjuryIcons(player.injuryStatus)}
                    </div>
                    </div>
                  <div className="ml-2 mt-2" style={{flex: 2, cursor: 'pointer'}} onClick={() => player.id && showPlayerModal(player)}>
                      <p className='bold m-0' style={{marginLeft: "2px" }} >
                      { player.is_empty == 1 ? "Empty" :player.full_name}
                      </p>
                      {IRStatuses().includes(player.status) && <p className="m-0">IR</p>}
                  </div>
              </div>  
                {/* </TouchableOpacity> */}
                </Cell>
                {dynamicColumns(player)}
            </Row>)
        }

  const prepareRoster = (pos) => {
        var roster = []
        // NON BENCH PLAYERS
        players.non_be != undefined && players.non_be.map((player, index) => {
            if(player.a_pos == pos){
                // normal case
                roster.push(
                    playerRow(player)
                )
            }
        })

        players.be != undefined && players.be.map((player, index) => {
            if(player.a_pos == pos){
                // normal case
                roster.push(
                    playerRow(player)
                )
            }
        })
    return roster
    }

   const dynamicHeader = (pos) => {
        var obj = {}
        players.non_be != undefined && players.non_be.map((player, index) => {
            if(player.a_pos == pos){
                obj = player.stats
            }
        })
        if(Object.values(obj).length == 0){
            players.be != undefined && players.be.map((player, index) => {
                if(player.a_pos == pos){
                    obj = player.stats
                }
            })
        }
        return <>
            <Cell style={{backgroundColor: Colors.bgLight, width:400}} >Player</Cell>
              {Object.keys(obj).map((statVal) => {
                return <Cell style={{backgroundColor: Colors.bgLight}} >
                    {statVal}
                  </Cell>
              })}
            </>
    }

  return (
    <div style={{overflow:'scroll'}}>
        {
        positions().map((pos) => {
            return (
                <StickyTable leftStickyColumnCount={1} borderWidth={'1px'} borderColor={Colors.darkBgBorder}>
                    <Row>
                        {dynamicHeader(pos)}
                    </Row>

                    {prepareRoster(pos).map((player, index) => {
                        return player
                    })}
                </StickyTable>
            )
      })
    }
    </div>
  );
};

export default TeamStatsView;
