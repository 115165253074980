import React, { useEffect, useState } from 'react'
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { mmAllNotifications } from '../../apis/Arena/MatchMakerApis'
import { LoaderCenter } from '../../components/Loader'
// import { Colors } from '../../helpers/colors'
import { arrowRightIcon, chevronRightIcon } from '../../helpers/icons'
import { toastError } from '../../helpers/toasts'
import { MatchNotificationModal } from './MatchNotificationModal'
import { useColors } from '../../helpers/CustomHooks/useColors';

const SeeAllNotificationsModal = (props) => {
  const Colors = useColors();
  const { sport, filter, modal, toggle, styles } = props
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [actionModal, setActionModal] = useState(false)
  const [actionData, setActionData] = useState(null)

  useEffect(() => {
    callApi()
  
  }, [])

  const callApi = () => {
    mmAllNotifications({ sport, filter })
      .then((resp) => {
        setData(resp);
        setLoading(false);
      })
      .catch((err) => {
        toastError('Something went wrong');
      })
  }

  const openActionModal = (matchData) => {
		setActionData(matchData)
		setActionModal(!actionModal)
	}
  
  return (
    <Modal isOpen={modal} backdropClassName={"darkerBackdrop"}>
      <ModalHeader className='border-0'>
        <i onClick={toggle} class="cui-circle-x icons pointer modalClose" />
      </ModalHeader>
        {
          loading 
          ? 
          <LoaderCenter/>
          :
          <ModalBody  className='p-0' style={{maxHeight: 'calc(100vh - 90px)', minHeight:'calc(100vh - 90px)', overflow:'auto'}}>
          <div className='d-flex flex-column flex1 position-relative' >
          {
				<div className="m-3">

          {/* NEED ACTIONS */}
					{data?.needActions?.length > 0 && (
          <div>
          <h1 className='text-center' style={{color:Colors.colorPrimary, fontFamily:'Fritch'}}>NEED ACTION</h1>
          {/* Card */}
          {data?.needActions?.map((match) => {
							return (
                <div onClick={() => openActionModal(match)} className="w-100 my-3 shadow pointer" style={{backgroundColor: Colors.bgLight, borderRadius: 10, border:`0.01em solid ${Colors.colorSecondaryTransparent}`}}>
                  <div className="d-flex align-items-center justify-content-between py-2 px-3">
										<div className='d-flex align-items-center' >
											<div
												className="px-2 primaryText bold"
												style={{
													background:

															match.propStatus == 'P' ? Colors.colorPrimary :
															Colors.colorWarning
												}}>
												{match.propStatus}
											</div>
											<div className="ml-2" >from <span className='bold' >{match.selectedLineup.teamName}</span></div>
										</div>
										<div style={{ width: 20, height: 20, aspectRatio: 1 }}>{arrowRightIcon()}</div>
									</div>
                </div>
              )
            }
          )}
          {/* !Card */}
          </div>
					)}
          {/* !NEED ACTIONS */}

          {/* COUNTERED */}
					{data?.countered?.length > 0 && (
              <div>
                <h1 className='text-center' style={{color:Colors.colorPrimary, fontFamily:'Fritch'}}>COUNTERS</h1>
                {/* Card */}
                {data?.countered?.map((pending) => 
                  {
                    return(
                      <div className="w-100 my-3 shadow" style={{backgroundColor: Colors.bgLight, borderRadius: 10, border:`0.01em solid ${Colors.colorSecondaryTransparent}`}}>
                        {
                          <div className="d-flex flex-column px-2 my-2" >
                              <p>to <span className="bold">{pending.oppData.teamName}</span></p>
                              {pending.isPaid && <>
                              <div className="d-flex justify-content-between mb-1">
                                <div>Individual Bet Amount</div>
                                <div>${pending.entryFee}</div>
                              </div>
                              <div className="d-flex justify-content-between mb-1">
                                <div>Odds Boost</div>
                                <div>${pending.possibleWins.oddBoost}</div>
                              </div>
                              <div className="d-flex justify-content-between mb-1">
                                <div>Total Pot Amount</div>
                                <div>${pending.possibleWins.totalFee}</div>
                              </div>
                              <div className="d-flex justify-content-between mb-1">
                                <div>Bettle Commissions</div>
                                <div>{'10%'}</div>
                              </div>
                              <div className="d-flex justify-content-between mb-1">
                                <div>Possible Winnings</div>
                                <div>${pending.possibleWins.possibleWin}</div>
                              </div>
                            </>}
                          </div> 
                        }
                      </div>
                    )
                  }
                )}
                {/* !Card */}
              </div>
					)}
          {/* !COUNTERED */}

          {/* PROPOSALS */}
          {
            data?.pending?.length > 0
            && 
            <div>
              <h1 className='text-center' style={{color:Colors.colorPrimary, fontFamily:'Fritch'}}>PROPOSALS</h1>
              {/* Card */}
              {data?.pending?.map((pending) => 
                {
                  return(
                    <div className="w-100 my-3 shadow" style={{backgroundColor: Colors.bgLight, borderRadius: 10, border:`0.01em solid ${Colors.colorSecondaryTransparent}`}}>
                      {
                        <div className="d-flex flex-column px-2 my-2" >
                            <p>to <span className="bold">{pending.oppData.teamName}</span></p>
                            {pending.isPaid ? (<>
                              <div className="d-flex justify-content-between mb-1">
                                <div>Individual Bet Amount</div>
                                <div>${pending.entryFee}</div>
                              </div>
                              <div className="d-flex justify-content-between mb-1">
                                <div>Odds Boost</div>
                                <div>${pending.possibleWins.oddBoost}</div>
                              </div>
                              <div className="d-flex justify-content-between mb-1">
                                <div>Total Pot Amount</div>
                                <div>${pending.possibleWins.totalFee}</div>
                              </div>
                              <div className="d-flex justify-content-between mb-1">
                                <div>Bettle Commissions</div>
                                <div>{'10%'}</div>
                              </div>
                              <div className="d-flex justify-content-between mb-1">
                                <div>Possible Winnings</div>
                                <div>${pending.possibleWins.possibleWin}</div>
                              </div>
                            </>) : (
                                <p>Free Match</p>
                            )}
                        </div> 
                      }
                    </div>
                  )
                }
              )}
              {/* !Card */}
            </div>
          }
          {/* !PROPOSALS */}
				</div>}
				{actionModal && <MatchNotificationModal
					modal={actionModal}
					toggle={() => setActionModal(!actionModal)}
					matchData={actionData}
					sport={sport}
				/>}
          </div>
        </ModalBody>
        }
    </Modal>
  )
}

export default SeeAllNotificationsModal