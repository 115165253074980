import React, { useState } from 'react'
import { Button, Input, InputGroup } from 'reactstrap';
import { appDownloadLink } from '../../apis/User/UserAPI';
import { useColors } from '../../helpers/CustomHooks/useColors';
import { actionComplete, closeIcon, downloadOnAppStore, getItOnGooglePlay } from '../../helpers/icons';
import ErrorText from '../ErrorText';

const DownloadOurApp = (props) => {

    const [visible, setVisible] = useState(false);
    const [step, setStep] = useState('default');
    const [ platform, setPlatform ] = useState();
    const [ error, setError ] = useState();
    const [phone, setPhone] = useState('+1');

    const Colors = useColors();
    
    const handleStoreSelection = (platform) => {
        setStep('phone');
        setPlatform(platform);
    }

    const handlePhone = (e) => {
        // If target value is number set phone else ignore
        if(!isNaN(e.target.value) || e.target.value[0] === '+' && e.target.value.length === 1){
            setPhone(e.target.value.trim());
        }
    }

    const handleSubmit = () => {
        // check if phone is valid american number
        let phoneIsValid = true;
        let phoneWithCountryCode = phone;

        if(phone[0] == '+' && phone.length === 12) {
            phoneIsValid = true;
            phoneWithCountryCode = phone;
        }
        else if(phone[0] == '1' && phone.length === 11) {
            phoneIsValid = true;
            phoneWithCountryCode = '+' + phone;
        }
        else if(!isNaN(phone) && phone.length === 10) {
            phoneIsValid = true;
            phoneWithCountryCode = '+1' + phone;
        }
        else {
            phoneIsValid = false;
        }
        
        if(phoneIsValid) {
            // send phone number to server
            appDownloadLink({phone: phoneWithCountryCode, platform}).then((resp) => {
                setStep('done');
            }).catch((err) => {
                setError(err.message);
            })
        } else {
            setError('Please enter a valid phone number');
        }
    }

    const handleVisibility = () => {
        setVisible(!visible);
    }

    return (
        visible ?
        null
        :
        <div>
            <div className='d-flex justify-content-end mx-2'>
                <p className='m-0 mr-1'>I already downloaded the app</p><span className='pointer' onClick={handleVisibility} >{closeIcon()}</span>
            </div>
            <div className='mx-2 mb-2' style={{borderRadius:10, backgroundColor: Colors.bgDark, boxShadow: `0px 2px 5px ${Colors.colorPrimaryDark}`}}>
                {/* CTA */}
                {
                    step === 'default' && (
                        <div className='d-flex flex-column justify-content-center p-2'>
                            <h1>Bettle on the go!</h1>
                            <p>
                                Download our app for optimal gameplay performance! It takes 30 seconds and you won't miss a beat.
                            </p>
                            <div className='d-flex w-100'>
                                <div className='flex1 pointer' onClick={() => handleStoreSelection('PlayStore')}>
                                    {getItOnGooglePlay()}
                                </div>

                                <div className='flex1 pointer' onClick={() => handleStoreSelection('AppStore')}>
                                    {downloadOnAppStore()}
                                </div>
                            </div>
                        </div>
                    )
                }
                
                {/* Mobile Number */}
                {
                    step === 'phone' && (
                        <div className='d-flex flex-column justify-content-center p-2'>
                            <h1>Download Link</h1>
                            <p>
                                Download our app for optimal gameplay performance! It takes 30 seconds and you won't miss a beat.
                            </p>

                            <p className='m-0'>
                                Please provide your mobile number to receieve your download link via text.
                            </p>
                            <div className='d-flex'>
                                <Input className='mr-2' value={phone} name='phone' type='text' placeholder="+1 (234) 456-7890" onChange={handlePhone} />
                                <Button className='border-0' style={{backgroundColor: Colors.bgDarker, color: '#fff'}} onClick={handleSubmit}>Submit</Button>
                            </div>
                        </div>
                    )
                }
                {/* Done */}
                {
                    step === 'done' && (
                        <div className='d-flex flex-column align-items-center justify-content-center p-2 text-center' style={{color: Colors.colorPrimary}}>
                            <div style={{width:100, height:100}}>
                                {actionComplete()}
                            </div>
                            <p>
                                The download link has been sent to the number provided.
                            </p>
                        </div>
                    )
                }
            {error && <ErrorText msg={error} />}
            </div>
        </div>
    )
}

export default DownloadOurApp