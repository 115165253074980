import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
// import { Colors } from '../../../helpers/colors'
import { mergeAndSum } from '../../../helpers/common-methods'
import { eachCatFormula } from '../../../helpers/MLB/Points'
import { useColors } from '../../../helpers/CustomHooks/useColors';

const EachCatBet = (props) => {
  const Colors = useColors();
  const handleNavigate = () => {

    props.history.push('/my-bets/h2h-matchup', { 
        league: props.league,
        id: bet.ch_id,
        team1Totals : teamPoints.team1Wlt.w+"-"+teamPoints.team1Wlt.l+"-"+teamPoints.team1Wlt.t,
        team2Totals: teamPoints.team2Wlt.w+"-"+teamPoints.team2Wlt.l+"-"+teamPoints.team2Wlt.t
    })
  }

  // THIS IS REALLY IMPORTANT FUNCTION
  const generateBetTotals = () => {
    // SUM STATS
    var team1Merged = mergeAndSum(bet.lineups.from_lineup.total, bet.lineups.from_lineup.current)
    var team2Merged = mergeAndSum(bet.lineups.to_lineup.total, bet.lineups.to_lineup.current)
    if(lg.sport == "MLB"){
        // GET TOTAL POINTS
        return eachCatFormula(team1Merged, team2Merged, {mapping: bet.scRules})
    }
    // else if(lg.sport == "NFL"){
    // }
  }
    
  const { bet, recent } = props
  const lg = props.league
  const [teamPoints, setTeamPoints] = useState(generateBetTotals())

  return (
        <tr style={{ borderBottom: `0.01em solid ${Colors.darkBgBorder}`, cursor:'pointer' }} onClick={handleNavigate} key={bet.ch_id}>
            <td className="align-middle bold">{/*Sr. Number Goes here*/}</td>
            <td className="align-middle">
                <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center my-1'>
                        <div className='rounded-circle mr-2' style={{width:40, height:40, border: `2px solid ${Colors.colorPrimary}`}}>
                            <img className="img-fluid m-auto p-1" src={bet.from.image}  />
                        </div>
                        <div>
                            <h5 className='p-0 m-0'>{bet.from.name} </h5>
                        </div>
                    </div>
                    
                    <div className='d-flex align-items-center my-1'>
                        <div className='rounded-circle mr-2' style={{width:40, height:40, border: `2px solid ${Colors.colorPrimary}`}}>
                            <img className="img-fluid m-auto p-1" src={bet.to.image}  />
                        </div>
                        <div>
                            <h5 className='p-0 m-0'>{bet.to.name} </h5>
                        </div>
                    </div>
                </div>
            </td>
            <td className="align-middle bold">
                <div className="my-3">
                    <h5 className="ml-1 bold">{teamPoints.team1Wlt.w+"-"+teamPoints.team1Wlt.l+"-"+teamPoints.team1Wlt.t}</h5>
                    <h5 className="ml-1 bold">{teamPoints.team2Wlt.w+"-"+teamPoints.team2Wlt.l+"-"+teamPoints.team2Wlt.t}</h5>
                </div>
            </td>
            <td className="align-middle bold">
                {bet.sidebet_type}
            </td>
            <td className="align-middle">
                {bet.sidebet_duration}
            </td>
            <td className="align-middle">
                <span style={{ color: Colors.colorPrimary }} className="bold">${bet.winner_gets}</span>
            </td>
            <td>

            </td>
          </tr>
  )
}

export default withRouter(EachCatBet)