import React, { useEffect } from 'react';
// import { Colors } from '../../helpers/colors';
import { motion } from "framer-motion/dist/framer-motion"
import { slideInOut } from './animationValues';
import YahooImport from '../LineupImport/YahooImport';
import LineupImportIndex from '../LineupImport/LineupImportIndex';
import SleeperImport from '../LineupImport/SleeperImport';
import FantraxImport from '../LineupImport/FantraxImport';

const LeagueImport = (props) => {
	const {step, updateStep, selectedPlatform, setLeagueId, successCallback, sport, Colors} = props
	// const sport = "NBA"
	const getPlatform = () => {
		if(selectedPlatform == "yahoo"){
			return  <YahooImport sport={sport} for="newUser" handleSuccess={successCallback} />
		}else if (selectedPlatform == "espn"){
			return <LineupImportIndex sport={sport} for="newUser" handleSuccess={successCallback} />
		}else if (selectedPlatform == "sleeper"){
			return <SleeperImport sport={sport} for="newUser" handleSuccess={successCallback} />
		}else if (selectedPlatform == "fantrax"){
			return <FantraxImport sport={sport} for="newUser" handleSuccess={successCallback} />
		}
	}

	return (
		<motion.div
			className="flex-column scrollHidden"
			animate={ step == "platformSelected" ? 'show' : step == "leagueImported" ? 'hideLeft' :'hideRight'}
			transition={{ duration: 0.5 }}
			style={{display: "flex"}}
			initial={false}
			variants={slideInOut}
			>
			{step == "platformSelected" && <div className='d-flex flex-column flex1 scrollHidden col-md-5 col-12 col-centered text-center' >
				<h1 className="alex bold" style={{ color: Colors.colorPrimary }}>
					Which League?
				</h1>
				<p className='lead' >Select the league you want to import.</p>
				<div className='d-flex flex-column flex1' style={{ backgroundColor: Colors.bgLight, borderRadius: 10, overflow: "auto"}}>
					{getPlatform()}
				</div>
			</div>}
		</motion.div>
	);
};

export default LeagueImport;
