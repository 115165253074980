import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import { nflGameLogs } from '../../../../apis/SeasonLong/PlayerAPI/PlayerAPI';
import { LoaderCenter } from '../../../../components/Loader';
import { toastError } from '../../../../helpers/toasts';

const GameLogsNFL = (props) => {

    const [logData, setLogData] = React.useState();
    const [loader, setLoader] = React.useState(true);

    useEffect(() => {
        nflGameLogs({player_id: props.playerId})
        .then(response => {
            setLogData(response.data)
            setLoader(false);
        })
        .catch(({message}) => {
            toastError(message)
        })
    },[])
  return (
    <>
    {   
        loader ? 
            <LoaderCenter />
        :
            <Table striped>
                <thead>
                    <tr>
                        <th></th>
                        {
                            logData.headers && logData.headers.main_header.map(header => {
                                return (
                                    header == 'Passing' || header == 'Rushing' || header == 'Turnovers' ?
                                    <th className='text-center' colSpan={3}>{header}</th>
                                    :
                                    <th className='text-center'>{header}</th>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <th>Week</th>
                    {
                        logData.headers && logData.headers.header.map(header => {
                            return (
                                <th>{header}</th>
                            )
                        })
                        
                    }
                    </tr>
                    {
                        logData && logData.stats.map(stat => {
                            return (
                            <tr>
                                {
                                    Object.values(stat).map(val => {
                                        return(
                                            <td className='text-center'>{val}</td>
                                        )
                                    })
                                }
                            </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        }
    </>
  )
}

export default GameLogsNFL