import { updateGeoLocation } from "../../apis/LocationAPI/LocationAPI";

export const fetchGeoLocation  = () => {
    return new Promise((resolve, reject) => {
        return navigator.geolocation.getCurrentPosition(async(position) => {
            await updateGeoLocation({
                geo: cloneObject(position),
                hasPermission: true
            })
            return resolve("ok")
        }, async(error) => {
            console.log(error)
            await updateGeoLocation({
                geo: null,
                hasPermission: false
            })
            console.log("We couldn't fetch user location")
            return resolve("failed")
        })
    })
}

const cloneObject = (obj) => {
    if (obj === null || !(obj instanceof Object)) {
        return obj;
    }
    var temp = (obj instanceof Array) ? [] : {};
    // ReSharper disable once MissingHasOwnPropertyInForeach
    for (var key in obj) {
        temp[key] = cloneObject(obj[key]);
    }
    return temp;
}