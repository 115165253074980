import React, { Component, useEffect, useState } from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Col, Row, Carousel, Button, Card, CardBody } from 'reactstrap';
import {
    AppBreadcrumb,
    AppFooter,
    AppHeader,
    AppSidebar,
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader,
    AppSidebarMinimizer,
    AppSidebarNav,
  } from '@coreui/react';
// import Sidebar from '../sidebar';
// import Footer from '../containers/DefaultLayout/Footer';
// import LandingHeader from '../containers/DefaultLayout/LandingHeader';
// import landingBg from "../assets/images/landingBg.png"
import feat1 from "../assets/images/feat1.png"
import feat2 from "../assets/images/feat2.png"
import feat3 from "../assets/images/feat3.png"
import feat4 from "../assets/images/feat4.png"
import feat5 from "../assets/images/feat5.png"
import feat6 from "../assets/images/feat6.png"
import leftFrame from "../assets/images/leftFrame.png"
import rightFrame from "../assets/images/rightFrame.png"
import builtScale from "../assets/images/builtScale.png"
import espn from "../assets/images/espn.png"
import yahoo from "../assets/images/yahoo.png"
import DocumentTitle from 'react-document-title';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';


const Landing2021 = (props) => {

    useEffect(() => {
    }, []);

    return(
        <DocumentTitle title={`Fantasy Football 2021 | Bettle`}>
        <div className="app" style={{fontFamily: "Poppins"}} >
        <div className="flex1">
            {/* <AppHeader className="landingPageHeader"> */}
            <Header />
            {/* </AppHeader> */}
            <div className="app-body landAppBody">
            {window.innerWidth <= 576 && <AppSidebar  display="xs" >
            <AppSidebarHeader />
            <AppSidebarForm />
            {/* <Sidebar {...props} /> */}
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>}
            <main className="main" >
                <div  >
                    <Row className="mt-xs-5" style={{width: "1200px", maxWidth: "100%", margin: "auto"}} >

                        <Col xs="12"className="d-flex d-md-none" >
                            {/* <img alt="Win Illustration" className="img-fluid" src={landingBg} /> */}
                        </Col>

                        <Col xs="12" md="5" className="pl-3 p-md-0" >

                            <div className="mt-0 mt-md-5" >
                                <h1 className="landHeading mb-1" >Level up your fantasy league.</h1>
                                
                                <div className="land_desc mt-4 mt-md-4" >
                                    {/* <p className="land_p mb-2" >Why should your Fantasy Football greatness be determined by one weekly matchup?</p> */}
                                    <p className="dFont mb-4" >Bettle is the only <b className="bold" >social first app</b> designed to seamlessly integrate with your season long fantasy league to offer a richer experience and new game types.</p>
                                    <div className="d-flex align-items-center " >
                                        <div style={{flex: 2}} >
                                            <p className="mb-1 mr-3 bold" >EASY IMPORT FROM:</p>
                                        </div>
                                        <div className="text-left flex1" ><img className="img-fluid" style={{width: "70%"}} src={espn} /></div>
                                        <div className="text-left flex1"><img className="img-fluid"  style={{width: "70%"}} src={yahoo} /></div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5" >
                                <Button onClick={() => props.history.push("/register")} className="green-btn bold bigButton"  >
                                    Get Started
                                </Button>
                            </div>
                        </Col>
                        <Col xs="12" md="7" className="d-none d-md-flex" >
                            {/* <img alt="Win Illustration" className="img-fluid" src={landingBg} /> */}
                        </Col>
                    </Row>
                    <div className="mt-5 p-3 p-md-5 darkText" style={{background: "#D7E2FF"}} >
                        <div  style={{width: "1200px", maxWidth: "100%", margin: "auto"}}>
                        <div className="text-center d-md-none d-block" >
                            <h1 className="bold landSubHeading" >
                                Features for every part of your fantasy experience.
                            </h1>
                        </div>
                        
                            <Row>
                                <Col xs="12" md="6" >
                                <Row className="mt-5" >
                                <Col xs="4 p-2 text-center" >
                                    <div><img src={feat1} /></div>
                                    <p className="mt-2 bold lead">Import</p>
                                </Col>
                                <Col xs="4 p-2 text-center" >
                                    <div><img src={feat2} /></div>
                                    <p className="mt-2 bold lead" >Chat</p>
                                </Col>
                                <Col xs="4 p-2 text-center" >
                                    <div><img src={feat3} /></div>
                                    <p className="mt-2 bold lead">Sidebets</p>
                                </Col>
                                <Col xs="4 p-2 text-center" >
                                    <div><img src={feat4} /></div>
                                    <p className="mt-2 bold lead">Commands</p>
                                </Col>
                                <Col xs="4 p-2 text-center" >
                                    <div><img src={feat5} /></div>
                                    <p className="mt-2 bold lead">News</p>
                                </Col>
                                <Col xs="4 p-2 text-center" >
                                    <div><img src={feat6} /></div>
                                    <p className="mt-2 bold lead">Gifs</p>
                                </Col>
                            </Row>
                                </Col>
                                <Col xs="12" md="6" className="d-md-flex d-none" >
                                    <div className="d-flex align-items-center justify-content-end" style={{height: "100%"}} >
                                        <h1 className="bold landSubHeading" style={{width: "80%"}} >
                                            Features for every part of your fantasy experience.
                                        </h1>
                                    </div>
                                    
                                </Col>
                            </Row>
                            
                            
                        </div>
                    </div>
                    <div className="darkText" style={{background: "#fff"}} >
                        <Row style={{width: "1200px", maxWidth: "100%", margin: "auto"}} >
                            <Col xs="12" md="6" >
                                <div className="p-md-5 p-0 mt-5" >
                                    <h1 className="bold landSubHeading" >Built for you.</h1>
                                    <p className="dFont" >Negotiate trades, talk smack, and stay up to speed on the latest news, all from an intuitive chat-based experience.</p>
                                </div>
                            </Col>
                            <Col xs="12" md="6"  >
                                <div className="mt-5 text-center mb-0 mb-md-4" >
                                    <img src={rightFrame} style={{height: "370px"}} className="img-fluid" />
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="d-flex align-items-center d-md-none pt-5 pb-5" style={{ background: "white"}} >
                        <div className="flex1 ml-3 mr-3" style={{border: "1px solid #DBDBDB"}} ></div>
                    </div>
                    <div className="darkText" style={{background: "#fff"}} >
                        <Row  style={{width: "1200px", maxWidth: "100%", margin: "auto"}} >
                            <Col xs="12" md="6" className="d-block d-md-none"  >
                                <div className="p-md-5 p-0" >
                                    <h1 className="bold landSubHeading" >Built to do more.</h1>
                                    <p className="dFont" >Think you have the best team in the league? Is CeeDee Lamb going to outscore Justin Jefferson this week? It’s time to put your money where your mouth is using <b className="bold" >Bettle Sidebets</b>.</p>
                                </div>
                            </Col>
                            <Col xs="12" md="6" className="mb-5" >
                                <div className="mt-5 text-center mb-0 mb-md-4">
                                    <img src={leftFrame} style={{height: "370px"}} className="img-fluid" />
                                </div>
                            </Col>
                            <Col xs="12" md="6" className="d-none d-md-flex" >
                                <div className="p-md-5 p-0 mt-5" >
                                    <h1 className="bold landSubHeading" >Built to do more.</h1>
                                    <p className="dFont" >Think you have the best team in the league? Is CeeDee Lamb going to outscore Justin Jefferson this week? It’s time to put your money where your mouth is using <b className="bold" >Bettle Sidebets</b>.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="darkText p-md-5 p-0" style={{background: "#BEF4E3"}} >
                        <Row style={{width: "1200px", maxWidth: "100%", margin: "auto"}}>
                            <Col xs="12" md="6" className="p-4" >
                                <div>
                                    <img  src={builtScale} className="img-fluid" />
                                </div>
                            </Col>
                            <Col xs="12" md="6"  >
                                <div className="d-flex flex-column justify-content-center pt-0 p-md-0 mb-5" style={{height: "100%"}} >
                                    <h1 className="bold landSubHeading" >For commissioners. By commissioners.</h1>
                                    <p className="dFont" >At Bettle, we know leagues come in all shapes and sizes. We designed Bettle with that in mind, with a product that can adapt and grow as your league evolves.</p>
                                    <div className="mt-3" >
                                        <Button onClick={() => props.history.push("/register")} className="green-btn bold bigButton"  >
                                            Import Your League
                                        </Button>
                                    </div>
                                </div>
                               
                            </Col>
                        </Row>
                    </div>
                </div>
            </main>
            </div>
            </div>
        <Footer />
      </div>
      </DocumentTitle>
    )
}

export default Landing2021;