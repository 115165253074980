import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactSelect from 'react-select';
import { Button } from 'reactstrap';
import { deleteMsgById } from '../../../../apis/Chat/ChatAPI';
import { acceptSidebet, cancelSidebet, fetchSidebetData, rejectSidebet } from '../../../../apis/Sidebet/SidebetAPI';
import { useColors } from '../../../../helpers/CustomHooks/useColors';
import { STYLES } from '../../../../helpers/stylesHelper';
import { toastError } from '../../../../helpers/toasts';
import { LoaderCenter } from '../../../Loader';
import { PrepareSidebetData } from './PrepareSidebetData';

const SidebetSent = (props) => {

    const Colors = useColors()

    const { data, template } = props.data.attachments[0]
    const [imReceiver, setImReceiver] = useState(false)
    const [imSender, setImSender] = useState(false)
    const [sidebetData, setSidebetData] = useState()
    const [myPlayers, setMyPlayers] = useState()
    const [mySelectedPlayers, setMySelectedPlayers] = useState()
    const [allowPlayerSelect, setAllowPlayerSelect] = useState(false)
    const [confirmation, setConfirmation] = useState(false)
    const [loading, setLoading] = useState(true)
    const [confirmationInProgress, setConformationInProgress] = useState(false)

    useEffect(() => {
        identifySender()

        if (data.openBet && template == 'SIDEBET_SENT') {
            var params = { leagueId: props.leagueId }
            fetchSidebetData(params)
                .then((response) => {
                    setSidebetData(response.data.players);
                    if (data.myBetType == 'player') {
                        setMyPlayers(response.data.players[props.auth.user.id].players)
                    }
                    setLoading(false)
                });

        }

    }, [])

    // Delete the sidebet as a sender
    const deleteSidebet = () => {
        let reqData = {
            ch_id: props.data.attachments[0].data.cId,
            mob: true
        }
        cancelSidebet(reqData)
        updateMessageData({ key: 'deleted', value: true })
    }

    // Reject a sidebet as a receiver
    const reject = () => {
        let reqData = {
            ch_id: props.data.attachments[0].data.cId,
            mob: true
        }
        setConformationInProgress(true);
        rejectSidebet(reqData)
            .then(response => {
                updateMessageData({ key: 'rejected', value: true })
            })
            .catch(({ message }) => {
                setConformationInProgress(false);
                toastError(message)
            })
    }

    // Allow user to accept the trade and choose between yes or no if they can acept the bet
    const handleConfirmation = () => {
        setConfirmation(true)
    }

    // Allow user to go back to Accept bet if user is on confirmation step
    const withdrawConfirmation = () => {
        setConfirmation(false)
    }

    // Accept sidebet as a receiver
    const accept = () => {
        let reqData = {
            cId: props.data.attachments[0].data.cId,
            league_id: props.leagueId,
            notifyByMsg: true
        }
        setConformationInProgress(true);
        if (data.openBet && data.myBetType == 'position') {
            reqData['pos'] = data.myBetPosition
        }
        else if (data.openBet && data.myBetType == 'player') {
            reqData["my_player_ids"] = preparePlayerIds(mySelectedPlayers)
        }
        acceptSidebet(reqData)
            .then(response => {
                updateMessageTemplate('SIDEBET_ACCEPTED', data.openBet)
            })
            .catch(({ message }) => {
                setConformationInProgress(false);
                toastError(message)
            })
    }

    const handleAllowPlayerSelect = () => {
        setAllowPlayerSelect(true)
    }

    const goBack = () => {
        setAllowPlayerSelect(false)
        setMySelectedPlayers(null)
    }

    const handleMyPlayersSelect = (player) => {
        setMySelectedPlayers(player)
    }

    const preparePlayerIds = (players) => {
        let IDs = players.map(value => { return value.playerId })
        return IDs
    }

    // Udpate message template to resend the message with new data
    const updateMessageTemplate = (template, rePrepareData = false) => {
        let msgData = props.data
        deleteMsgById(props.data.msg_id)
            .then(response => {
                msgData['attachments'][0]['template'] = template
                if (rePrepareData) {
                    msgData['attachments'][0]['data']['oppteamName'] = { teamName: sidebetData[props.auth.user.id].teamName }
                    msgData['attachments'][0]['data']['oppteamMeta'] = prepareMeta(sidebetData[props.auth.user.id])
                    if (data.myBetType == 'player') {
                        msgData['attachments'][0]['data']["theirBetPlayer"] = mySelectedPlayers
                    }
                }
                props.handleCommand(msgData)
            })
            .catch(({ message }) => {
                toastError(message)
            })
    }

    const prepareMeta = (obj) => {
        delete obj["players"]
        return obj
    }

    // Update message attachments to resend the message with new data
    const updateMessageData = (newKey) => {
        let data = props.data
        deleteMsgById(props.data.msg_id)
            .then(() => {
                data['attachments'][0]['data'][newKey.key] = newKey.value
                props.handleCommand(data)
            })
            .catch(({ message }) => {
                toastError(message)
            })
    }

    // Identify if the current user is either sender, receiver or none
    const identifySender = () => {
        let { senderId, receiverId, rejected, deleted } = props.data.attachments[0].data
        if (!rejected || !deleted) {
            if (props.auth.user.id == senderId) {
                setImSender(true)
            }
            else if (props.auth.user.id == receiverId) {
                setImReceiver(true)
            }
        }
    }

    // Prepare the sidebet headline indicating the status and Sender/Receiver of sidebet
    const prepareMessage = () => {

        let { senderId, receiverId, myteamName, oppteamName, deleted, rejected } = props.data.attachments[0].data
        let { template } = props.data.attachments[0];

        if (confirmation) {
            return (
                <p>Are you sue you want to accept this proposal? By clicking '"Yes" you will initiate your <span style={{ color: Colors.colorPrimary }}>{data.myBetPrice}</span></p>
            )
        }
        else if (template == 'SIDEBET_SENT') {
            if (props.auth.user && props.auth.user.id == senderId) {
                // I am sender
                return (
                    data.openBet ?
                        <p>You created an open bet:</p>
                        :
                        <p>You sent the following sidebet proposal to <span style={{ color: Colors.colorLink }}>@{oppteamName.teamName}</span></p>
                )
            }
            else if (props.auth.user && props.auth.user.id == receiverId) {
                // I am Receiver
                return (
                    <p><span style={{ color: Colors.colorLink }}>@{myteamName.teamName}</span> sent you a new sidebet proposal:</p>
                )
            }
            else {
                return (
                    data.openBet ?
                        <p><span style={{ color: Colors.colorLink }}>@{myteamName.teamName}</span> created an open bet:</p>
                        :
                        <p><span style={{ color: Colors.colorLink }}>@{myteamName.teamName}</span> sent the following sidebet proposal to <span style={{ color: Colors.colorLink }}>@{oppteamName.teamName}</span></p>
                )
            }
        }
        else if (template == 'SIDEBET_ACCEPTED') {
            return (
                <p>A sidebet has been started between <span style={{ color: Colors.colorLink }}>@{myteamName.teamName}</span> and <span style={{ color: Colors.colorLink }}>@{oppteamName.teamName}</span></p>
            )
        }
    }

    // Generate action buttons based on the current user
    const prepareActions = () => {
        let { template } = props.data.attachments[0]

        if (confirmation) {
            return (
                <>
                    {
                        confirmationInProgress ? 
                        <Button className='w-100 my-1 disabled' style={STYLES.BUTTON_SECONDARY_TRANSPARENT}> <i class="fa fa-refresh fa-spin"></i></Button>
                        :
                        <Button className='w-100 my-1' onClick={accept} style={STYLES.BUTTON_PRIMARY_TRANSPARENT}>Yes</Button>

                    }
                    <Button className='w-100 my-1' onClick={withdrawConfirmation} style={STYLES.BUTTON_DANGER_TRANSPARENT}>No</Button>
                </>
            )
        }
        else if (template == 'SIDEBET_ACCEPTED') {
            return (
                <Button className='w-100 my-1' onClick={() => props.history.push('/my-bets')} style={STYLES.BUTTON_PRIMARY_TRANSPARENT}>View Sidebets</Button>
            )
        }
        else if (data.openBet && !imSender && !data.deleted) {
            if (data.myBetType == 'player' && !allowPlayerSelect) {
                return (
                    <Button className='w-100 my-1' onClick={handleAllowPlayerSelect} style={STYLES.BUTTON_PRIMARY_TRANSPARENT}>Select Player and Accept</Button>
                )
            }
            else if (allowPlayerSelect) {
                return (
                    <>
                        {mySelectedPlayers && <Button className='w-100 my-1' onClick={handleConfirmation} style={STYLES.BUTTON_PRIMARY_TRANSPARENT}>Accept</Button>}
                        <Button className='w-100 my-1' onClick={goBack} style={STYLES.BUTTON_DANGER_TRANSPARENT}>Go Back</Button>
                    </>
                )
            }
            else if (data.myBetType != 'player') {
                return (
                    loading ? ''
                        :
                        <Button className='w-100 my-1' onClick={handleConfirmation} style={STYLES.BUTTON_PRIMARY_TRANSPARENT}>Accept</Button>
                )
            }

        }
        else if (!data.deleted && !data.rejected) {
            if (!imSender && !imReceiver) {
                return
            }
            else if (imSender) {
                return (
                    <>
                        <Button className='w-100 my-1' onClick={deleteSidebet} style={STYLES.BUTTON_DANGER_TRANSPARENT}>Cancel</Button>
                    </>
                )
            }
            else if (imReceiver) {
                return (
                    <>
                        <Button className='w-100 my-1' onClick={handleConfirmation} style={STYLES.BUTTON_PRIMARY_TRANSPARENT}>Accept</Button>

                        {
                        confirmationInProgress ? 
                        <Button className='w-100 my-1 disabled' style={STYLES.BUTTON_SECONDARY_TRANSPARENT}> <i class="fa fa-refresh fa-spin"></i></Button>
                        :
                        <Button className='w-100 my-1' onClick={reject} style={STYLES.BUTTON_DANGER_TRANSPARENT}>Reject</Button>

                        }
                    </>
                )
            }
        }
        else {
            if (data.deleted) {
                return (
                    <div className='w-100 p-1' style={{ border: `1px solid ${Colors.colorPrimary}`, borderRadius: 5 }}>Sidebet has been deleted by <span style={{ color: Colors.colorLink }}>@{data.myteamName.teamName}</span></div>
                )
            }
            else if (data.rejected) {
                return (
                    <div className='w-100 p-1' style={{ border: `1px solid ${Colors.colorPrimary}`, borderRadius: 5 }}>Sidebet has been rejected by <span style={{ color: Colors.colorLink }}>@{data.oppteamName.teamName}</span></div>
                )
            }
        }
    }

    return (
        <div className='d-flex'>
            <span className='mr-2' style={{ borderLeft: `5px solid ${Colors.darkBgBorder}`, borderRadius: 50 }}></span>
            <div>
                {prepareMessage()}
                <PrepareSidebetData data={data} myPlayers={mySelectedPlayers} />
                {

                    allowPlayerSelect && loading ? <LoaderCenter /> : confirmation ? '' : allowPlayerSelect && !loading ? <ReactSelect menuPortalTarget={document.body} getOptionValue={option => option.playerId} getOptionLabel={option => option.fullName} options={myPlayers} value={mySelectedPlayers} onChange={handleMyPlayersSelect} className="bg-transparent my-2 text-white" styles={STYLES.REACT_SELECT} closeMenuOnSelect={false} isMulti={true} isClearable={true} placeholder='Select Your Players' /> : ''
                }
                {prepareActions()}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({ auth: state.auth, leagueId: state.leagueJoin.leagueId })
export default connect(mapStateToProps, null)(withRouter(SidebetSent));