import React from 'react';
import { Card, CardBody } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { fullS3Path } from '../../../../helpers/Matchmaker/ArenaSettingsHelper';
import { useColors } from '../../../../helpers/CustomHooks/useColors';

function ExperienceBadges(props) {
  const Colors = useColors()
  return (
    <div className='d-flex flex-column flex1 scrollHidden'>
      {props.slug == "MatchMakerRules" ?
        <>
          <h5 className='bold' style={{ fontSize: 20 }}>Experience Badges</h5>
          <div className='ml-3'>
            <p>Experience Badges denote the skill level for players in the MatchMaker arena. Skill level is determined by matches entered and winnings. Here are our Experience Badges:</p>
            <div className='d-flex'>
              <img width='30px' height='30px' src={fullS3Path('ROOKIE.png')} alt="" />
              <p className='ml-1'><span className='bold'>Rookie: </span>Played in less than or equal to 9 matches OR has won less than or equal to <span style={{ color: Colors.colorPrimary }}>$499</span></p>
            </div>
            <div className='d-flex'>
              <img width='30px' height='30px' src={fullS3Path('PRO.png')} alt="" />
              <p className='ml-1'><span className='bold'>Pro: </span>Played in between 10 and 99 matches OR has won between <span style={{ color: Colors.colorPrimary }}>$500 and $999</span></p>
            </div>
            <div className='d-flex'>
              <img width='30px' height='30px' src={fullS3Path('ALL_STAR.png')} alt="" />
              <p className='ml-1'><span className='bold'>All Star: </span>Played in between 100 and 499 matches OR has won more than <span style={{ color: Colors.colorPrimary }}>$1,000</span></p>
            </div>
            <div className='d-flex'>
              <img width='30px' height='30px' src={fullS3Path('HALL_OF_FAME.png')} alt="" />
              <p className='ml-1'><span className='bold'>Hall Of Fame: </span>Played in more than 500 matches OR has won more than <span style={{ color: Colors.colorPrimary }}>$10,000</span></p>
            </div>
            <div className='d-flex'>
              <img width='30px' height='30px' src={fullS3Path('GOAT.png')} alt="" />
              <p className='ml-1'><span className='bold'>GOAT:</span>Has played in more than 1,000 matches AND has won more than $10,000 AND has MatchMaker winning <span style={{ color: Colors.colorPrimary }}>% over 65%</span></p>
            </div>
            <div>
              <p>Bettle will host matches for Rookies only. You will only see <span style={{ color: Colors.colorPrimary }}>experience-eligible</span> matches in your arena.</p>
            </div>
          </div>
        </>
        :
        <>
          <div className='d-flex justify-content-start align-items-center px-3 my-2' style={{ borderBottom: `1px solid ${Colors.darkBgBorder}`, cursor: 'pointer' }} onClick={() => props.setView('default')}>
            <h5><span> <i className='mr-2 fa fa-angle-left'></i></span>Experience Badges</h5>
          </div>
          <PerfectScrollbar>
            <Card className='border-0 mb-0 mx-3' style={{ background: Colors.bgLight }}>
              <CardBody>
                <h5 className='bold' style={{ fontSize: 20 }}>Experience Badges</h5>
                <p>Experience Badges denote the skill level for players in the MatchMaker arena. Skill level is determined by matches entered and winnings. Here are our Experience Badges:</p>
                <div className='d-flex'>
                  <img width='30px' height='30px' src={fullS3Path('ROOKIE.png')} alt="" />
                  <p className='ml-1'><span className='bold'>Rookie: </span>Played in less than or equal to 9 matches OR has won less than or equal to <span style={{ color: Colors.colorPrimary }}>$499</span></p>
                </div>
                <div className='d-flex'>
                  <img width='30px' height='30px' src={fullS3Path('PRO.png')} alt="" />
                  <p className='ml-1'><span className='bold'>Pro: </span>Played in between 10 and 99 matches OR has won between <span style={{ color: Colors.colorPrimary }}>$500 and $999</span></p>
                </div>
                <div className='d-flex'>
                  <img width='30px' height='30px' src={fullS3Path('ALL_STAR.png')} alt="" />
                  <p className='ml-1'><span className='bold'>All Star: </span>Played in between 100 and 499 matches OR has won more than <span style={{ color: Colors.colorPrimary }}>$1,000</span></p>
                </div>
                <div className='d-flex'>
                  <img width='30px' height='30px' src={fullS3Path('HALL_OF_FAME.png')} alt="" />
                  <p className='ml-1'><span className='bold'>Hall Of Fame: </span>Played in more than 500 matches OR has won more than <span style={{ color: Colors.colorPrimary }}>$10,000</span></p>
                </div>
                <div className='d-flex'>
                  <img width='30px' height='30px' src={fullS3Path('GOAT.png')} alt="" />
                  <p className='ml-1'><span className='bold'>GOAT:</span>Has played in more than 1,000 matches AND has won more than $10,000 AND has MatchMaker winning <span style={{ color: Colors.colorPrimary }}>% over 65%</span></p>
                </div>
                <div>
                  <p>Bettle will host matches for Rookies only. You will only see <span style={{ color: Colors.colorPrimary }}>experience-eligible</span> matches in your arena.</p>
                </div>
              </CardBody>
            </Card>
          </PerfectScrollbar>
        </>
      }
    </div>
  )
}

export default ExperienceBadges;