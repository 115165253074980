import { errorMessage } from '../../helpers/common-methods';

const ENDPOINTS = {
	USER_BALANCE: '/payments/balance',
	CHECK_INDENTITY: '/payments/userStatus',
	CREATE_SESSION: '/payments/createWebSession',
	WALLET: '/payments/wallet',
	TRANSACTIONS: '/payments/transactions',
	COUNTRIES: '/geographer/countries',
	STATES: '/geographer/states',
	UPLOAD_ID: '/payments/uploadID',
	D_CASHIER_CREATE_SESSION: '/payments/dcashier/createSession',
	D_CASHIER_COMPLETE_SESSION: '/payments/dcashier/deposit',
	WITHDRAW_FUNDS: '/payments/dcashier/withdraw',
	REGISTER_USER: '/payments/verifyUser'
};

export const userBalance = () => {
	return window.axios
		.get(ENDPOINTS.USER_BALANCE)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const verifyUserIdentity = () => {
	return window.axios
		.get(ENDPOINTS.CHECK_INDENTITY)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const createWebSession = () => {
	return window.axios
		.get(ENDPOINTS.CREATE_SESSION)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const fetchWallet = (params) => {
	return window.axios
		.get(ENDPOINTS.WALLET,{params})
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const fetchTransactions = (params) => {
	return window.axios
		.get(ENDPOINTS.TRANSACTIONS,{params})
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const fetchCountries = () => {
	return window.axios
		.get(ENDPOINTS.COUNTRIES)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const statesByCountry = (query) => {
	return window.axios
		.get(ENDPOINTS.STATES + query)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const dCashierCreateSession = (query) => {
	return window.axios
		.get(ENDPOINTS.D_CASHIER_CREATE_SESSION + query)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		})
};

export const gidxCompleteSession = (reqData) => {
	return window.axios
		.post(ENDPOINTS.D_CASHIER_COMPLETE_SESSION, reqData)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		});
};

export const registerUserGidx = (reqData) => {
	return window.axios
		.post(ENDPOINTS.REGISTER_USER, reqData)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		});
};

export const gidxWithdrawFunds = (reqData) => {
	return window.axios
		.post(ENDPOINTS.WITHDRAW_FUNDS, reqData)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			if (error.response) {
				return Promise.reject(error.response.data);
			} else if (error.request) {
				errorMessage(error.request._response);
			} else {
				errorMessage(error.message);
			}
		});
};

export const gidxUploadID = (reqData) => {
	return window.axios
		.post(ENDPOINTS.UPLOAD_ID, reqData)
		.then((response) => {
			return Promise.resolve(response.data.data);
		})
		.catch((error) => {
			return Promise.reject(error);
		});
};
