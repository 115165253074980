
export let DSTScore
export let SLScoreMapping

DSTScore = {
    // JUST STATS WITHOUT BONUS POINTS - WE CALC. BONUS POINTS UNDER YARDS AND PA
    st_kick_ret_yd: "kick_returns_yards",
    st_punt_ret_yd: "punt_returns_yards",
    st_sacks: "defense_sacks",
    st_tot_tackles: "defense_sp_tackles",
    st_int_ret_td: "int_returns_touchdowns",
    st_fumb_ret_td: "st_fumb_ret_td",
    st_kick_ret_td: "kick_returns_touchdowns",
    st_punt_ret_td: "punt_returns_touchdowns",
    st_block_punt: { statGroup: ["kick_returns_touchdowns", "punt_returns_touchdowns"]},
    st_pat: "defense_sp_blocks",
    st_interception: "defense_interceptions",
    st_fumb_rec: "defense_fumble_recoveries",
    // def_fumb_rec :"defense_fumble_recoveries",
    st_fumb_force: "defense_forced_fumbles",
    st_safety: "defense_safeties",
    st_stuff: "defense_tloss_yards",
    st_pass_def: "defense_passes_defended"
}

SLScoreMapping = {
    //passing
    pass_yd :  { stat : 'passing_yards'},
    pass_completion :  { stat :'passing_completions'},
    pass_incomplete :  { stat :'pass_incomplete'},
    pass_td :  { stat :'passing_touchdowns'},
    // don have these
    pass_40_td :  { sum: 40, stat :'passing_touchdowns'},
    pass_50_td :  { sum: 50, stat :'passing_touchdowns'},
    //
    pass_interception_thrown :   { stat :'passing_interceptions'},
    pass_2pt_conversion :  { stat :'2pt_pass_successes'},
    pass_pass_attempted :  { stat :'passing_attempts'},
    pass_sacked :  { stat :'passing_sacks'},
    pass_400_yd : { sum : 400, stat : 'passing_yards'}, // does it allow on greater than 400 ? 
    pass_300_399_yard : { le : 399, ge:300, stat : 'passing_yards'},
    //Rushing
    rush_yd :  { stat :'rushing_yards'},
    rush_td :  { stat :'rushing_touchdowns'},
    rush_40_td :  { sum: 40, stat :'rushing_touchdowns'},
    rush_50_td :  { sum: 50, stat :'rushing_touchdowns'},
    rush_2pt_conversion :  { stat :'2pt_rush_successes'},
    rush_attempt :  { stat :'rushing_attempts'},
    rush_100_199_yd : {le : 199, ge : 100, stat : "rushing_yards" },
    rush_200_yd : {sum : 200, stat : "rushing_yards"},

    //Receiving
    rec_yds :  { stat :'receiving_yards'},
    rec_reception :  { stat :'receiving_receptions'},
    rec_td_reception :  { stat :'receiving_touchdowns'},
    rec_40_td_rec :  { stat :'rec_40_td_rec'},
    rec_50_td_rec :  { stat :'rec_50_td_rec'},
    rec_2pt_conversion :  { stat :'2pt_receive_successes'},
    rec_target :  { stat :'receiving_targets'},
    rec_100_199_yd : {le : 199, ge : 100, stat : "receiving_yards" },
    rec_200_yd : { sum : 200, stat : "receiving_yards"},

    //misc
    misc_kick_ret :  { stat :'kick_returns_yards'},
    misc_punt_ret :  { stat :'punt_returns_yards'},
    misc_kick_ret_td :  { stat : 'kick_returns_touchdowns'},
    misc_punt_ret_td :  { stat :'punt_returns_touchdowns'},
    misc_fumb_rec_td :  { stat :'fumbles_own_rec_tds'},
    misc_fumb :  { stat :'fumbles_fumbles'},
    misc_fumb_lost :  { stat :'fumbles_lost_fumbles'},
    misc_team_win  :  { stat :'misc_team_win'},
    misc_team_loss :  { stat :'misc_team_loss'},
    misc_interception_ret_td :  { stat :'int_returns_touchdowns'},
    misc_fumb_ret_td :  { stat :'touchdowns_fumble_return'},
    misc_block_punt :  { stat :'punts_blocked'},
    misc_2pt_ret:  { stat :'misc_2pt_ret'},
    misc_1pt_safety:  { stat :'misc_1pt_safety'},

    //Kicking
    kick_pat : { stat : 'extra_points_made' } ,
    kick_pat_attempt : { stat : 'extra_points_attempts' } ,
    kick_pat_miss : { stat : 'extra_points_blocked' },
    kick_fg : { stat : 'field_goals_made' },
    kick_fg_attempt: { stat : 'field_goals_attempts' },
    kick_fg_missed: { stat : 'field_goals_missed' },
    // kick_fg_30_39: { stat : 'field_goals_made_39'},

    kick_fg_0_39: { statGroup : ['field_goals_made_19', 'field_goals_made_29', 'field_goals_made_39']},

    kick_fg_40_49: { stat : 'field_goals_made_49'},
    kick_fg_50:{ stat : 'field_goals_made_50'},
    kick_fg_attempt_0_39: { stat : 'field_goals_attempts_39'},
    kick_fg_attempt_40_49: {  stat : 'field_goals_attempts_49'},
    kick_fg_attempt_50: { stat : 'field_goals_attempts_50'},
    kick_fg_miss_0_39: {  ge : 0, le : 39, stat : 'field_goals_missed' },
    kick_fg_miss_40_49: {  ge : 40, le : 49, stat : 'field_goals_missed' },
    kick_fg_miss_50: {  sum : 0, stat : 'field_goals_missed' },


    // Defensive Player
    // redundant
    def_sacks: { stat : 'defense_sacks' },
    def_tot_sacks: { stat : 'defense_tackles' },

    def_block_punt: { stat : 'punts_blocked' },
    def_interception: { stat : 'defense_interceptions' },
    def_fumb_rec: { stat : 'defense_fumble_recoveries' },
    def_fumb_force: { stat : 'defense_forced_fumbles' },
    def_safety: { stat : 'defense_safeties' },
    def_assisted_tackles: { stat : 'defense_assists' },
    def_solo_tackles: { stat : 'defense_tackles' },
    def_stuff: { stat : 'def_stuff' },
    def_pass_def: { stat : 'defense_passes_defended' },


    //Special Teams
    st_kick_ret_yd: { stat : 'kick_returns_yards' },
    st_punt_ret_yd: { stat : 'punts_return_yards' },
    st_sacks: { stat : 'defense_sacks' },
    st_tot_tackles: { stat : 'defense_sp_tackles' },
    st_int_ret_td: { stat : 'int_returns_touchdowns' },
    st_fumb_ret_td: { stat : 'fumbles_own_rec_tds' },
    st_kick_ret_td: { stat : 'kick_returns_touchdowns' },
    st_punt_ret_td: { stat : 'punt_returns_touchdowns' },
    st_block_punt: { stat : 'punts_blocked' },
    // st_pat: { stat : 'extra_points_attempts' },
    st_interception: { stat : 'defense_interceptions' },
    st_fumb_rec: { stat : 'defense_sp_fumble_recoveries' },
    st_fumb_force: { stat : 'defense_sp_forced_fumbles' },
    st_safety: { stat : 'defense_safeties' },
    st_stuff: { stat : 'st_stuff' },
    st_pass_def: { stat : 'defense_passes_defended' },

    st_0_pts_allow: { stat : 'st_0_pts_allow' },
    st_1_6_pts_allow: { stat : 'st_1_6_pts_allow' },
    st_7_13_pts_allow: { stat : 'st_7_13_pts_allow' },
    st_14_19_pts_allow: { stat : 'st_14_19_pts_allow' },
    st_20_29_pts_allow: { stat : 'st_20_29_pts_allow' },
    st_30_39_pts_allow: { stat : 'st_30_39_pts_allow' },
    st_35_45_pts_allow: { stat : 'st_35_45_pts_allow' },
    st_46_pts_allow: { stat : 'st_46_pts_allow' },
    st_yds_allow: { stat : 'st_yds_allow' },
    st_less_100_yds_allow: { stat : 'st_less_100_yds_allow' },
    st_100_199_yds: { stat : 'st_100_199_yds' },
    st_200_299_yds_allow: { stat : 'st_200_299_yds_allow' },
    st_300_349_yds_allow: { stat : 'st_300_349_yds_allow' },
    st_350_399_yds_allow: { stat : 'st_350_399_yds_allow' },
    st_400_449_yds_allow: { stat : 'st_400_449_yds_allow' },
    st_450_499_yds_allow: { stat : 'st_450_499_yds_allow' },
    st_500_549_yds_allow: { stat : 'st_500_549_yds_allow' },
    st_550_yds_allow: { stat : 'st_550_yds_allow' },
    st_2pt_return: { stat : 'st_2pt_return' },
    st_1pt_safety: { stat : 'st_1pt_safety' },

}

export const prepareLiveDSTData = (e, team) => {
    let currentStats = null
    if(e["stats"]["t1"]["t"] == team){
        currentStats = { stats : { my: e["stats"]["t1"]["stats"] , opp: e["stats"]["t2"]["stats"] },
         pa: e["stats"]["t1"]["pa"], pya: e["stats"]["t1"]["pya"], rya: e["stats"]["t1"]["rya"], started: e["stats"]["t1"]["started"]
        }
    }else{
        currentStats = { stats : { my: e["stats"]["t2"]["stats"] , opp: e["stats"]["t1"]["stats"] },
        pa: e["stats"]["t2"]["pa"], pya: e["stats"]["t2"]["pya"], rya: e["stats"]["t2"]["rya"], started: e["stats"]["t2"]["started"]
     }
    }

    return currentStats
}