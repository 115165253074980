import React, {useState} from 'react'
import {useForm} from 'react-hook-form';
import LoadingOverlay from 'react-loading-overlay';
import {Alert, Button} from 'reactstrap';
import {updateLeagueSettings} from '../../../apis/SeasonLong/Settings/SettingsAPI';
// import {Colors} from '../../../helpers/colors';
import Header from './Header';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useColors } from '../../../helpers/CustomHooks/useColors';

const RosterSettings = ({options_data, steps_data, onBackHandler, leagueId}) => {
    const Colors = useColors();
    const [roster,
        setRoster] = useState(steps_data.roster)
    const [loader,
        setLoader] = useState(false)
    const [isValid,
        setIsValid] = useState(false)
    const [error,
        setError] = useState([])

    const {handleSubmit} = useForm()

    const handleIncrement = (key) => {
        var newRoster = {
            ...roster
        };
        newRoster[key] = newRoster[key] + 1;
        setRoster(newRoster);
    };

    const handleDecrement = (key) => {
        var newRoster = {
            ...roster
        };
        newRoster[key] = newRoster[key] - 1;
        setRoster(newRoster);
    };

    const calcRosterSpot = () => {
        var total = 0;
        Object
            .keys(roster)
            .map((key) => {
                total = total + roster[key];
            });
        return total;
    };

    const onSubmit = (data) => {

        console.log(data)
        setLoader(true)
        var reqData = {}
        reqData["leagueId"] = leagueId;
        reqData["type"] = "roster";
        reqData["input"] = roster;

        updateLeagueSettings(reqData).then(() => {
            setLoader(false)
            setIsValid(false)
        }).catch((err) => {
            setLoader(false)
            setError(err);
            setIsValid(true)
        })
    }

    return (
        <form className='d-flex flex-column flex1 scrollHidden' onSubmit={handleSubmit(onSubmit)}>
            <LoadingOverlay className='d-flex flex-column flex1 scrollHidden' active={loader} spinner text='Saving...' fadeSpeed={1000}>
                <Header onBackHandler={onBackHandler} name='Roster Settings'/>
                <PerfectScrollbar className="d-flex flex-column flex1 align-items-center">
                    <div className="text-center col-md-6 col-sm-12">
                        {isValid
                            ? <Alert
                                    toggle={function noRefCheck() {
                                    setIsValid(!isValid)
                                }}
                                    className='d-inline-block '
                                    color="danger">
                                    Oops! Something went wrong... Try again</Alert>
                            : null}
                        <label
                            className="mb-3 d-flex justify-content-center"
                            style={{
                            fontSize: 16
                        }}>
                            Roster Spot: {calcRosterSpot()}
                        </label>
                        {/* Single Item */}
                        {Object
                            .keys(steps_data.roster)
                            .map((key, index) => (
                                <div
                                    key={index}
                                    className="row p-3 px-0 mx-0 justify-content-between"
                                    style={{
                                    borderBottom: `1px solid ${Colors.bgDark}`
                                }}>
                                    <label
                                        style={{
                                        fontSize: 18,
                                        fontWeight: "bold"
                                    }}>
                                        {key}
                                    </label>

                                    <div className="">
                                        <div>
                                            <button
                                                type="button"
                                                class="btn btn-dark"
                                                onClick={() => handleDecrement(key)}
                                                style={{
                                                color: Colors.colorPrimary,
                                                fontWeight: "bold",
                                                border: `1px solid ${Colors.bgDark}`
                                            }}>
                                                -
                                            </button>
                                            <span
                                                class="badge mx-2"
                                                style={{
                                                fontSize: 15
                                            }}>
                                                {roster[key]}
                                            </span>
                                            <button
                                                type="button"
                                                class="btn btn-dark"
                                                onClick={() => handleIncrement(key)}
                                                style={{
                                                color: Colors.colorPrimary,
                                                fontWeight: "bold",
                                                border: `1px solid ${Colors.bgDark}`
                                            }}>
                                                +
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </PerfectScrollbar>
            </LoadingOverlay>
        </form>
    )
}

export default RosterSettings