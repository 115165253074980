import React from 'react';
import LoginCompV2 from '../../components/Auth/Login/LoginCompV2';
import Header from '../../components/Header/Header';
import { Colors } from '../../helpers/colors';
import PerfectScrollbar from 'react-perfect-scrollbar';

const LoginV2 = () => {
    return (
        <div className='d-flex flex-column' style={{height:'100vh'}}>
            <Header />
            <div className='flex1 d-flex justify-content-center' style={{overflow:'hidden', backgroundColor:Colors.colorDark}}>
                <PerfectScrollbar className='flex1 d-flex' style={{maxWidth: "600px", width: "100%"}}>
                    <LoginCompV2 />
                </PerfectScrollbar>
                {/* <div className='flex1 d-none d-sm-flex h-100 align-items-center justify-content-center'>
                    <div className='d-none d-sm-block'>
                        <AuthLandingWeb />
                    </div>
                </div> */}
            </div>
        </div>
    )
};

export default LoginV2